import styled from "styled-components";
export const TestResultsWrapper = styled.div `
  background: #fff;
  padding: 8px;
  // margin: 5px 0 10px 40px;

  .label {
    color: #7f8e9d;
  }
`;
