import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Text from "antd/lib/typography/Text";
import moment from "moment";
import { Button, Col, Row, Select } from "src/components/base";
import { HeaderTableWrapper } from "../HeaderTable/index.style";
const HeaderFilter = ({ children, onChangeFilter }) => (_jsxs(HeaderTableWrapper, { children: [_jsx("div", { style: { background: "#2e94f2" }, children: _jsxs(Row, { align: "middle", style: { padding: "0 13px", height: "auto", minHeight: 48 }, children: [_jsxs(Col, { span: 8, children: [_jsx("img", { src: "/assets/svg/ic-dash-satchel.svg", alt: "", style: { marginRight: 8 } }), _jsx(Text, { style: { fontSize: 16, fontWeight: 500, color: "#FFFFFF" }, children: "Satchel Inventory" })] }), _jsx(Col, { span: 8, children: _jsx(Text, { style: {
                                overflow: "hidden",
                                color: "#FFFFFF",
                                display: "flex",
                                justifyContent: "center",
                            }, children: moment().format("dddd DD MMMM YYYY HH:mm") }) }), _jsx(Col, { span: 8, children: _jsxs(Row, { justify: "space-between", align: "middle", children: [_jsxs("div", { children: [_jsx(Text, { style: {
                                                fontSize: 13,
                                                color: "#FFFFFF",
                                                marginRight: "10px",
                                            }, children: "Show" }), _jsx(Select, { defaultValue: "days", style: { width: 165 }, size: "small", options: [
                                                {
                                                    label: "Days",
                                                    value: "days",
                                                },
                                                {
                                                    label: "Weeks",
                                                    value: "weeks",
                                                },
                                                {
                                                    label: "Months",
                                                    value: "months",
                                                },
                                                {
                                                    label: "Years",
                                                    value: "years",
                                                },
                                            ], onChange: onChangeFilter })] }), _jsx(Button, { className: "icon-btn", size: "small", icon: _jsx("img", { src: "/assets/svg/ic-pin.svg" }) })] }) })] }) }), _jsx("div", { style: { background: "#fff" }, children: children })] }));
export default HeaderFilter;
