import styled from "styled-components";
export const PageWrapper = styled.div `
  min-height: 79vh;

  .support-title {
    font-size: 28px;
    font-weight: bold;
    color: #00004c;
  }
  .support-text {
    font-size: 17px;
    color: #4b5767;
  }
  .support-header {
    background-image: url("/assets/images/header-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    padding: 20px 81px 15px 46px;
  }
  .search-wrapper {
    display: flex;
    width: 80%;
    max-width: 700px;
    height: 48px;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 3px 6px #0000004d;
  }
  .search-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: #2f3337;
  }
  .search-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #4b5767;
    opacity: 1; /* Firefox */
    font-size: 16px;
  }
  .search-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #4b5767;
    font-size: 16px;
  }
  .search-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #4b5767;
    font-size: 16px;
  }
  .support-body {
    display: flex;
    justify-content: center;
    margin-top: -86px;
    flex-wrap: wrap;
    row-gap: 1em;
  }
  .support-card {
    width: 314px;
    height: 200px;
    border-radius: 4px;
    background: #ffffff;
    padding: 34px 10px 32px;
    text-align: center;
    margin-right: 26px;
    box-shadow: 0 3px 6px #00000042;
    cursor: pointer;
  }
  .support-terms {
    margin-top: 136px;
    margin-bottom: 15px;
  }
`;
export const HeaderBackground = styled.div `
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-image: url("/assets/images/header-background.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  width: 100%;
  height: 319px;
`;
