import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { CalendarView } from "../typing";
import { getListOnsiteJobs } from "./action";
export const initialState = {
    currentDate: new Date().toISOString(),
    selectedCollectionPoint: null,
    listOnsiteJobs: [],
    isExpandOnsiteView: false,
    currentView: CalendarView.Day,
};
const calendarSlice = createSlice({
    name: "calendar",
    initialState,
    reducers: {
        setResetStore() {
            return initialState;
        },
        setCurrentDate(state, action) {
            state.currentDate = action.payload;
        },
        setCollectionPoint(state, action) {
            state.selectedCollectionPoint = action.payload;
        },
        setIsExpandOnsiteView(state, action) {
            state.isExpandOnsiteView = action.payload;
        },
        setCurrentView(state, action) {
            state.currentView = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListOnsiteJobs.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.listOnsiteJobs = initialState.listOnsiteJobs;
                message.error("Failed to fetch onsite calendar results");
            }
            else {
                state.listOnsiteJobs = action.payload.data;
            }
        })
            .addCase(getListOnsiteJobs.rejected, () => {
            message.error("Failed to fetch onsite calendar results");
        });
    },
});
export const { setResetStore, setCurrentDate, setCollectionPoint, setIsExpandOnsiteView, setCurrentView, } = calendarSlice.actions;
export default calendarSlice.reducer;
