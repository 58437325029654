import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkEmailAndMobileIsExist, sendPasswordVerificationCode } from "src/api/user/userService";
import { Image, TextInput } from "src/components/base";
import { validateEmail } from "src/utils/helper";
import { BoxWrapper, ICLetterCustom, LeftOutlinedCustom, SABackBtn, SASendBtn, TypographyErrorText, TypographyText, TypographyTitle, } from "src/pages/ForgotPwdPage/index.style";
export const ForgotPwdForm = ({ onSendProp }) => {
    const [email, setEmail] = useState("");
    const [errorText, setErrorText] = useState("");
    const navigate = useNavigate();
    const onTypingEmail = (typingMail) => {
        setEmail(typingMail);
    };
    const onSend = async () => {
        if (!email) {
            setErrorText("Email is required.");
            return;
        }
        if (!validateEmail(email)) {
            setErrorText("Email is not valid.");
            return;
        }
        const data = await checkEmailAndMobileIsExist(email);
        if (data.status == "fail") {
            setErrorText(data.message);
        }
        else if (data.status == "success" && !data.error) {
            await sendPasswordVerificationCode(email, true);
            onSendProp({ email: email, mobile: data.data.mobile });
        }
        else if (data.status == "success" && data.error == "APE002") {
            await sendPasswordVerificationCode(email, false);
            onSendProp({ email: email });
        }
    };
    return (_jsxs(BoxWrapper, { children: [_jsx(Image, { src: "/assets/svg/ic-lock2.svg", preview: false }), _jsx(TypographyTitle, { children: "Forgot your password?" }), _jsx(TypographyText, { children: "Please enter your email and we'll send you a verification code to reset your password." }), _jsx(TextInput, { className: "email-input", leftIcon: _jsx(ICLetterCustom, { src: "/assets/svg/ic-letter.svg" }), value: email, onChange: onTypingEmail, placeholder: "Enter email", type: "email", preview: false }), _jsx(TypographyErrorText, { children: errorText }), _jsx(SASendBtn, { className: "send-button", name: "Send", onClick: onSend, loading: false }), _jsx(SABackBtn, { icon: _jsx(LeftOutlinedCustom, {}), name: "Back", onClick: () => navigate(-1) })] }));
};
