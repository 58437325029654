import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
export var ClientType;
(function (ClientType) {
    ClientType[ClientType["NON_CALL_OUT"] = 1] = "NON_CALL_OUT";
    ClientType[ClientType["CALL_OUT"] = 2] = "CALL_OUT";
})(ClientType || (ClientType = {}));
export const SwitchComponent = ({ form, name, disabled = false, initialValue, onToggle, }) => {
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        const currentValue = form.getFieldValue(name);
        setValue(currentValue || initialValue);
    }, [form, name, initialValue]);
    const toggleToNo = () => {
        if (!disabled && value !== ClientType.NON_CALL_OUT) {
            setValue(ClientType.NON_CALL_OUT);
            form.setFieldsValue({ [name]: ClientType.NON_CALL_OUT });
            if (onToggle) {
                onToggle(ClientType.NON_CALL_OUT);
            }
        }
    };
    const toggleToYes = () => {
        if (!disabled && value !== ClientType.CALL_OUT) {
            setValue(ClientType.CALL_OUT);
            form.setFieldsValue({ [name]: ClientType.CALL_OUT });
            if (onToggle) {
                onToggle(ClientType.CALL_OUT);
            }
        }
    };
    return (_jsx(SwitchWrapper, { children: _jsxs("div", { className: `switch ${disabled ? "disabled" : ""}`, children: [_jsx("div", { className: `option no ${value === ClientType.NON_CALL_OUT ? "selected" : ""}`, onClick: toggleToNo, children: "No" }), _jsx("div", { className: `option yes ${value === ClientType.CALL_OUT ? "selected" : ""}`, onClick: toggleToYes, children: "Yes" })] }) }));
};
const SwitchWrapper = styled.div `
  .switch {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    width: 10rem;
  }

  .option {
    flex: 1;
    padding: 5px;
    color: #000000;
    margin: 2px;
    text-align: center;
    transition:
      background-color 0.3s ease,
      padding 0.3s ease;
    background-color: #0000000a;
  }

  .option.no.selected,
  .option.yes.selected {
    padding: 7px;
    color: #ffffff;
    font-weight: 700;
    border-radius: 5px;
  }

  .option.no.selected {
    background-color: #fd0000;
  }

  .option.yes.selected {
    background-color: #156cc9;
  }

  .switch.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }

  .switch.disabled .option {
    background-color: #d9d9d9;
  }

  .switch.disabled .option.no.selected {
    background-color: #ff9999;
  }

  .switch.disabled .option.yes.selected {
    background-color: #99ccff;
  }
`;
