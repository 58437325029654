import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Typography from "@mui/material/Typography";
const LevelPermissionTable = ({ permission }) => (_jsx("div", { style: { display: "flex", flexDirection: "column", gap: "10px", margin: "20px" }, children: permission.map((item) => {
        const level2Items = item.children;
        return (_jsxs("div", { children: [_jsxs("div", { style: { display: "flex", gap: "10px", alignItems: "center" }, children: [_jsx("input", { type: "checkbox", checked: true, disabled: true, style: { height: "20px", width: "20px", accentColor: "#7F8E9D" } }), _jsx(Typography, { style: {
                                fontWeight: "bold",
                                backgroundColor: "#F7F8F8",
                                padding: "5px 40px",
                                width: "100%",
                            }, children: item.name })] }), level2Items?.map((level2Item) => {
                    const level3Items = level2Item.children;
                    return (_jsxs("div", { children: [_jsxs("div", { style: { display: "flex", gap: "10px", alignItems: "center", marginLeft: "50px" }, children: [_jsx("input", { type: "checkbox", checked: true, disabled: true, style: { height: "20px", width: "20px" } }), _jsx(Typography, { style: {
                                            borderBottom: "1px solid #EBEDF0",
                                            padding: "5px 40px",
                                            width: "100%",
                                        }, children: level2Item.name })] }), level3Items?.map((level3Item) => (_jsxs("div", { style: {
                                    display: "flex",
                                    gap: "10px",
                                    alignItems: "center",
                                    marginLeft: "100px",
                                }, children: [_jsx("input", { type: "checkbox", checked: true, disabled: true, style: { height: "20px", width: "20px" } }), _jsx(Typography, { style: {
                                            borderBottom: "1px dotted #EBEDF0",
                                            padding: "5px 40px",
                                            width: "100%",
                                        }, children: level3Item.name })] }, level3Item.id)))] }, level2Item.id));
                })] }, item.id));
    }) }));
export default LevelPermissionTable;
