/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { store } from "src/store";
export var ApiActionType;
(function (ApiActionType) {
    ApiActionType["ERROR"] = "ERROR";
    ApiActionType["FETCHING"] = "FETCHING";
    ApiActionType["SUCCESS"] = "SUCCESS";
    ApiActionType["RESET"] = "RESET";
})(ApiActionType || (ApiActionType = {}));
export const REDUX_ACTION_TYPE_PREFIX = "API_REDUX_CALL";
export const REDUCER_KEY = "API_REDUX_CALL";
const initState = {
    fetching: false,
    error: false,
    data: null,
};
const makeErrorType = (apiName) => `${REDUX_ACTION_TYPE_PREFIX}__${apiName.toUpperCase()}__${ApiActionType.ERROR}`;
const makeSuccessType = (apiName) => `${REDUX_ACTION_TYPE_PREFIX}__${apiName.toUpperCase()}__${ApiActionType.SUCCESS}`;
const makeFetchingType = (apiName) => `${REDUX_ACTION_TYPE_PREFIX}__${apiName.toUpperCase()}__${ApiActionType.FETCHING}`;
const headerMiddlewareHandler = async (headers, middlewares) => {
    let rsHeaders = { ...headers };
    const length = (middlewares || []).length;
    for (let i = 0; i < length; i++) {
        rsHeaders = await middlewares[i]({ ...rsHeaders }, store);
    }
    return rsHeaders;
};
const createActionCreator = (apiName, makeConfig) => async (...params) => {
    const config = makeConfig(...params);
    try {
        store.dispatch({
            type: makeFetchingType(apiName),
            meta: { apiName, type: ApiActionType.FETCHING, data: null },
        });
        const response = config.requestHandler
            ? await config.requestHandler(config)
            : await axios({
                method: config.method,
                url: config.endpoint,
                data: config.data,
                headers: await headerMiddlewareHandler(config.headers, config.headerMiddlewares),
            });
        store.dispatch({
            type: makeSuccessType(apiName),
            meta: { apiName, type: ApiActionType.SUCCESS, data: response.data },
        });
        return { success: true, data: response.data };
    }
    catch (e) {
        const message = e?.response?.data?.message || e.toString();
        store.dispatch({
            type: makeErrorType(apiName),
            meta: {
                apiName,
                type: ApiActionType.ERROR,
                data: null,
                errorData: { message },
            },
        });
        return { success: false, message };
    }
};
const createResetter = (apiName) => () => {
    store.dispatch({
        type: makeFetchingType(apiName),
        meta: { apiName, type: ApiActionType.RESET, data: null },
    });
};
export const makeFetchAction = (apiName, makeConfig) => ({
    actionCreator: createActionCreator(apiName, makeConfig),
    resetter: createResetter(apiName),
    fetchingSelector: (state) => state.oldReducers[REDUCER_KEY]?.[apiName]?.fetching,
    errorSelector: (state) => state.oldReducers[REDUCER_KEY]?.[apiName]?.error,
    dataSelector: (state) => state.oldReducers[REDUCER_KEY]?.[apiName]?.data,
    fullSelector: (state) => state.oldReducers[REDUCER_KEY]?.[apiName] || initState,
});
