import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SAButton, Image } from "src/components/base";
export const SelectButton = (props) => (_jsx(SAButton, { onClick: props.onClick, name: props.name, style: {
        width: "100%",
        height: "auto",
        textAlign: "left",
        background: "#156CC9",
        border: "none",
        color: "white",
        fontSize: "16px",
    }, className: props.className, icon: _jsx("div", { style: {
            position: "absolute",
            marginLeft: "120px",
            right: "0px",
        }, children: props.orderField == props.fieldName && (_jsx(Image, { src: props.isDescending == true
                ? "/assets/images/arrow-down-white-icon.png"
                : "/assets/images/arrow-up-white-icon.png", preview: false, height: 7, width: 14 })) }) }));
