import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input, message, Select, Typography } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import SADivider from "src/components/Form/SADivider";
import SAStatusButton from "src/components/Form/SAStatusButton";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { OnsiteJobAllocationStatus } from "../store/types";
import { DisplayOnsiteJobAllocationStatus } from "../typing";
const AssignCollectorTable = ({ availableCollectors, selectedCollectorIds, updateCollector, }) => {
    const selectedCollectors = [
        ...(availableCollectors?.filter((collector) => selectedCollectorIds.includes(collector.id)) ??
            []),
        ...selectedCollectorIds
            .filter((collectorId) => collectorId === "")
            .map(() => ({
            id: "",
            firstName: "",
            lastName: "",
            status: null,
        })),
    ];
    return (_jsxs(ContentContainer, { children: [_jsxs(Toolbar, { children: [_jsx(SAColumn, { md: 8, lg: 18, align: "flex-end", justify: "center", children: _jsx(Typography.Text, { children: "Number of Collectors" }) }), _jsx(SAColumn, { md: 8, lg: 3, children: _jsx(CustomInput, { value: selectedCollectorIds.length, disabled: true }) }), _jsx(SAColumn, { md: 8, lg: 3, children: _jsx(CustomButton, { type: "primary", onClick: () => {
                                if (selectedCollectorIds.length === 10) {
                                    message.error("Maximum number of allocated collectors is 10");
                                    return;
                                }
                                if (selectedCollectorIds.length && selectedCollectorIds.includes("")) {
                                    message.error("Please select a collector for the previous allocation");
                                    return;
                                }
                                const currentCollectors = [...selectedCollectorIds];
                                currentCollectors.push("");
                                updateCollector(currentCollectors);
                            }, children: "Add Collector" }) })] }), selectedCollectors.length > 0 && (_jsxs(React.Fragment, { children: [_jsxs(SARow, { children: [_jsx(SAColumn, { xs: 6, lg: 12, align: "flex-end", justify: "center", children: _jsx(HeadingTitle, { children: "Status" }) }), _jsx(SAColumn, { xs: 6, lg: 4, align: "flex-end", children: _jsx(HeadingTitle, { children: "Date" }) }), _jsx(SAColumn, { xs: 6, lg: 4, align: "flex-end", children: _jsx(HeadingTitle, { children: "Time" }) }), _jsx(SAColumn, { xs: 6, lg: 4, align: "flex-end", children: _jsx(HeadingTitle, { children: "Action" }) })] }), _jsx(SADivider, {})] })), selectedCollectors.map((selectedCollector, index) => {
                const currentCollectorData = availableCollectors?.find((collector) => collector.id === selectedCollector.id);
                const filteredCollectors = availableCollectors?.filter((availableCollector) => !selectedCollectorIds.includes(availableCollector.id)) ?? [];
                if (currentCollectorData) {
                    filteredCollectors.push(currentCollectorData);
                }
                const currentLabel = DisplayOnsiteJobAllocationStatus[selectedCollector.status ?? OnsiteJobAllocationStatus.Allocated];
                let currentDate = "";
                switch (selectedCollector.status) {
                    case OnsiteJobAllocationStatus.Allocated: {
                        currentDate = selectedCollector.allocatedAt ?? null;
                        break;
                    }
                    case OnsiteJobAllocationStatus.Accepted: {
                        currentDate = selectedCollector.acceptedAt ?? null;
                        break;
                    }
                    case OnsiteJobAllocationStatus.Rejected: {
                        currentDate = selectedCollector.rejectedAt ?? null;
                        break;
                    }
                }
                const displayedDate = currentDate ? moment(currentDate) : moment();
                return (_jsxs(React.Fragment, { children: [_jsxs(SARow, { children: [_jsx(SAColumn, { xs: 24, lg: 2, align: "flex-start", justify: "center", children: _jsxs(Typography.Title, { level: 5, children: ["Collector ", index + 1] }) }), _jsx(SAColumn, { xs: 24, lg: 6, align: "flex-end", justify: "center", children: _jsx(CustomSelect, { notFoundContent: "No data or a collection point has not selected yet", value: currentCollectorData?.id, onChange: (value) => {
                                            const currentId = value;
                                            const newCollectors = selectedCollectorIds.map((currentCollectorId, createdIndex) => {
                                                if (index === createdIndex)
                                                    return currentId;
                                                return currentCollectorId;
                                            });
                                            updateCollector(newCollectors);
                                        }, children: filteredCollectors.map((collector) => (_jsxs(Select.Option, { value: collector.id, children: [collector.firstName, " ", collector.lastName] }, collector.id))) }) }), _jsx(SAColumn, { xs: 6, lg: 4, align: "flex-end", justify: "center", children: _jsx(SAStatusButton, { color: currentLabel?.color, children: currentLabel?.label }) }), _jsx(SAColumn, { xs: 6, lg: 4, align: "flex-end", justify: "center", children: _jsx(Typography.Text, { children: displayedDate.format("DD MMM YYYY") }) }), _jsx(SAColumn, { xs: 6, lg: 4, align: "flex-end", justify: "center", children: _jsx(Typography.Text, { children: displayedDate.format("hh:ss A") }) }), _jsx(SAColumn, { xs: 6, lg: 4, align: "flex-end", justify: "center", children: _jsx(Button, { icon: _jsx(DeleteOutlined, {}), type: "text", onClick: () => {
                                            const newCollectors = selectedCollectorIds.filter((_, createdIndex) => index !== createdIndex);
                                            updateCollector(newCollectors);
                                        } }) })] }, selectedCollector.id), _jsx(SADivider, {})] }, selectedCollector.id));
            })] }));
};
export default AssignCollectorTable;
const ContentContainer = styled.div `
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const HeadingTitle = styled(Typography.Text) `
  color: #00b4d1;
  font-weight: 500;
  font-size: 1rem;
`;
const CustomSelect = styled(Select) `
  width: 100%;
`;
const CustomInput = styled(Input) `
  width: 100%;
  &:disabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    cursor: not-allowed !important;
  }
`;
const CustomButton = styled(Button) `
  width: 100%;
  background-color: #1a8cff;
  border: 0;
`;
const Toolbar = styled(SARow) `
  margin-bottom: 2rem;
`;
