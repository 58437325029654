import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined } from "@ant-design/icons";
import { useContext } from "react";
import ContentContext from "src/providers/content";
import { PopupBoxWrapper } from "./index.style";
const PopupBox = () => {
    const { popupBox, closePopupBox } = useContext(ContentContext);
    const props = popupBox.popupProps ?? [];
    return popupBox?.visible ? (_jsx(PopupBoxWrapper, { children: popupBox.component.map((component, index) => (_jsxs("div", { className: "popup-box", style: {
                marginLeft: 50 * index,
                marginTop: 50 * index,
                ...props[index]?.popupStyle,
            }, children: [component, popupBox.component.length - 1 === index && (_jsx("div", { className: "close-popup-box", onClick: () => {
                        closePopupBox();
                        if (popupBox.callback) {
                            popupBox.callback();
                        }
                    }, style: props[index] ? props[index]?.closeIconStyle : {}, children: _jsx(CloseOutlined, {}) }))] }, `popup-${index}`)
        // </Draggable>
        )) })) : null;
};
export default PopupBox;
