import { $get, $post } from "src/infra/http";
/** ******************** Patient Test Result Detail *********************/
export const getPatientData = async (appointmentId) => await $get(`/collection-manager/patient-records/get-patient-details/${appointmentId}`);
/** ******************** Onsite Donor List *********************/
export const getAllOnsiteDonorRecords = async () => await $get(`/collection-manager/onsite-records/`);
export const getListClients = async () => await $get(`/collection-manager/job-management/clients`);
/** ******************** Onsite Donor Test Records *********************/
export const getAllOnsiteDonorTestRecords = async (ids) => await $post("/collection-manager/onsite-records/donor-test-records/", ids);
/** ******************** Onsite Donor Test Result Detail *********************/
export const getOnsiteDonorData = async (onsiteAppointmentId) => await $get(`/collection-manager/onsite-records/donor-detail/${onsiteAppointmentId}`);
export const getOnsiteDonorTestData = async (onsiteAppointmentId) => await $get(`/collection-manager/onsite-records/donor-test-summary/${onsiteAppointmentId}`);
