const initialBreathalyserTestingDeviceDetails = {
    deviceName: "",
    collectionPointIds: [],
    isInUse: false,
    isReminderExpire: false,
    reminderAt: null,
    sponsor: "",
    manufacturer: "",
    fontImageUrl: "",
    sideImageUrls: [],
    instructionUrl: "",
    serialNumber: "",
    measurementUnit: "",
    calibrationAt: null,
    calibrationCertificateUrl: "",
    calibrationReportUrl: "",
    deviceManualUrl: "",
    certificateNumber: "",
    deviceCommissioningDate: null,
};
export { initialBreathalyserTestingDeviceDetails };
