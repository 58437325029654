import moment from "moment";
import { stringify } from "qs";
import { $get } from "src/infra/http";
export const getAllPatients = async (startDate, endDate) => {
    const newStartDate = moment(startDate).format("YYYY-MM-DD");
    const newEndDate = moment(endDate).format("YYYY-MM-DD");
    const params = {
        startAt: newStartDate,
        endAt: newEndDate,
    };
    const query = stringify(params);
    return await $get(`/collection-manager/patient-list/get-patients?${query}`);
};
