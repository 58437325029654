import styled from "styled-components";
export const Wrapper = styled.div `
  .input-wrapper {
    display: flex;
    justify-content: space-between;
    border: 2px solid #156cc9;
    border-radius: 4px;
    height: 48px;
  }
  .text-input {
    flex: 70%;
    outline: none;
    border: none;
    padding-left: 13px;
    color: #2a44ff;
  }
`;
