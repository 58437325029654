import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton } from "@mui/material";
import { Col, Row, Table } from "antd";
import moment from "moment/moment";
import { useState } from "react";
import styled from "styled-components";
import ICSWAP from "src/assets/svg/ic-cm-swap-button.svg";
import { Button, FormTitle, InputWithLabel } from "src/components/base";
const PatientDetails = ({ onSwapSection, patientData, onInputChange, onClearPatient, isNewForm, isExistedPatient, onUpdatePatient, onEditing, }) => {
    const [results, setResults] = useState([]);
    const [focusedField, setFocusField] = useState("");
    const [editMode, setEditMode] = useState(false);
    const handleInputChange = (event) => {
        const inputValue = event.value;
        const field = event.field;
        setFocusField(field);
        let filteredResults = [];
        if (field === "lastName") {
            filteredResults = patientData.filter((item) => item.lastName.toLowerCase().includes(inputValue.toLowerCase()));
        }
        else if (field === "firstName") {
            filteredResults = patientData.filter((item) => item.firstName.toString().includes(inputValue));
        }
        else if (field === "email") {
            filteredResults = patientData.filter((item) => item.email.toLowerCase().includes(inputValue.toLowerCase()));
        }
        else if (field === "mobile") {
            filteredResults = patientData?.filter((item) => item?.mobile?.toString()?.includes(inputValue));
        }
        setResults(filteredResults);
        if (inputValue === "") {
            setResults([]);
        }
    };
    const columns = [
        { title: "Last Name", dataIndex: "lastName", key: "lastName" },
        { title: "First Name", dataIndex: "firstName", key: "firstName" },
        {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
            render: (dob) => moment(dob, "DD/MM/YYYY").format("DD/MM/YYYY"),
        },
        { title: "Mobile", dataIndex: "mobile", key: "mobile" },
        { title: "Email", dataIndex: "email", key: "email" },
    ];
    const remainButtons = () => (_jsxs("div", { className: "touch-btn", style: { display: "flex", marginRight: "30px" }, children: [_jsx(Button, { className: "edit-btn", style: { height: "30px" }, icon: _jsx("img", { src: "/assets/svg/ic-edit3.svg", alt: "ic-edit" }), onClick: () => setEditMode(true), children: "Edit" }), _jsx(Button, { className: "clear-btn", style: { height: "30px" }, icon: _jsx("img", { src: "/assets/svg/ic-clear.svg", alt: "ic-clear" }), onClick: onClearPatient, disabled: !isNewForm, children: "Clear" })] }));
    const updateButtons = () => (_jsxs("div", { className: "update-btn", style: { display: "flex", marginRight: "30px" }, children: [_jsx(Button, { className: "cancel-btn", onClick: () => setEditMode(false), children: "Cancel" }), _jsx(Button, { className: "save-btn", onClick: () => {
                    onUpdatePatient();
                    setTimeout(() => {
                        setEditMode(false);
                    }, 1000);
                }, children: "Done" })] }));
    const tableTitle = () => (_jsxs("div", { style: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }, children: [_jsx("div", { style: {
                    textAlign: "center",
                    backgroundColor: "black",
                    color: "white",
                }, children: "Search Results" }), _jsx(Button, { onClick: () => setResults([]), children: "Close Table" })] }));
    return (_jsxs("div", { style: { width: "100%" }, children: [_jsx("div", { style: { display: "flex" }, children: _jsx(FormTitle, { title: "Patient Details", buttonGroupElements: _jsxs("div", { children: [isExistedPatient && !editMode && remainButtons(), isExistedPatient && editMode && updateButtons()] }), iconButton: _jsx(IconButton, { onClick: onSwapSection, children: _jsx(ICSWAP, {}) }) }) }), _jsxs("div", { className: "patient-details", children: [_jsxs(Row, { gutter: [20, 10], children: [_jsxs(Col, { span: 6, children: [_jsx(InputWithLabel, { required: true, label: "Last Name", field: "lastName", onInputChange: handleInputChange, inputProps: {
                                            disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                        }, rules: [{ required: true, whitespace: true }] }), focusedField === "lastName" && results.length > 0 && (_jsx(AutoCompleteWrapper, { children: _jsx(Table, { size: "large", title: tableTitle, 
                                            // Customize table style with className
                                            className: "custom-table", dataSource: results, columns: columns, rowKey: "id", 
                                            // rowSelection={rowSelection}
                                            onRow: (record) => ({
                                                onClick: () => {
                                                    setResults([]);
                                                    onInputChange(record);
                                                },
                                            }) }) }))] }), _jsxs(Col, { span: 6, children: [_jsx(InputWithLabel, { required: true, label: "First Name", field: "firstName", onInputChange: handleInputChange, inputProps: {
                                            disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                        }, rules: [{ required: true, whitespace: true }] }), focusedField === "firstName" && results.length > 0 && (_jsx(AutoCompleteWrapper, { children: _jsx(Table, { size: "large", title: tableTitle, 
                                            // Customize table style with className
                                            className: "custom-table", dataSource: results, columns: columns, rowKey: "id", 
                                            // rowSelection={rowSelection}
                                            onRow: (record) => ({
                                                onClick: () => {
                                                    setResults([]);
                                                    setEditMode(false);
                                                    onInputChange(record);
                                                },
                                            }) }) }))] }), _jsx(Col, { span: 6, children: _jsx(InputWithLabel, { required: true, label: "Date of Birth", field: "dob", inputProps: {
                                        type: "date",
                                        // value={moment(dob, "DD/MM/YYYY").format("DD/MM/YYYY")}
                                        format: "DD/MM/YYYY",
                                        suffixIcon: false,
                                        disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                    }, onInputChange: handleInputChange, rules: [{ required: true }] }) }), _jsx(Col, { span: 6, children: _jsx(InputWithLabel, { required: true, label: "Sex", field: "sex", inputProps: {
                                        type: "select",
                                        placeholder: "- Select -",
                                        disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                        options: [
                                            {
                                                label: "Male",
                                                value: "male",
                                                // tooltip: "Male",
                                            },
                                            {
                                                label: "Female",
                                                value: "female",
                                                // tooltip: "Female",
                                            },
                                            {
                                                label: "Other",
                                                value: "other",
                                                // tooltip: "Intersex/Indeterminate/Unspecified",
                                            },
                                        ],
                                    }, onInputChange: handleInputChange, rules: [{ required: true }] }) })] }), _jsxs(Row, { gutter: [20, 10], children: [_jsxs(Col, { span: 12, children: [_jsx(InputWithLabel, { required: true, label: "Email Address", field: "email", onInputChange: handleInputChange, inputProps: {
                                            disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                        }, rules: [{ required: true, whitespace: true }] }), focusedField === "email" && results.length > 0 && (_jsx(AutoCompleteWrapper, { children: _jsx(Table, { size: "large", title: tableTitle, 
                                            // Customize table style with className
                                            className: "custom-table", dataSource: results, columns: columns, rowKey: "id", 
                                            // rowSelection={rowSelection}
                                            onRow: (record) => ({
                                                onClick: () => {
                                                    setResults([]);
                                                    onInputChange(record);
                                                },
                                            }) }) }))] }), _jsxs(Col, { span: 6, children: [_jsx(InputWithLabel, { required: true, label: "Mobile Phone number", field: "mobile", onInputChange: handleInputChange, inputProps: {
                                            disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                        }, rules: [{ required: true, whitespace: true }] }), focusedField === "mobile" && results.length > 0 && (_jsx(AutoCompleteWrapper, { children: _jsx(Table, { size: "large", title: tableTitle, 
                                            // Customize table style with className
                                            className: "custom-table", dataSource: results, columns: columns, rowKey: "id", 
                                            // rowSelection={rowSelection}
                                            onRow: (record) => ({
                                                onClick: () => {
                                                    setResults([]);
                                                    onInputChange(record);
                                                },
                                            }) }) }))] }), _jsx(Col, { span: 6, children: _jsx(InputWithLabel, { label: "Landline", field: "landline", inputProps: {
                                        disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                    } }) })] }), _jsxs(Row, { gutter: [20, 10], children: [_jsx(Col, { span: 12, children: _jsx(InputWithLabel, { label: "Address 1 (Street address)", field: "address1", inputProps: {
                                        disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                    } }) }), _jsx(Col, { span: 12, children: _jsx(InputWithLabel, { label: "Address 2", field: "address2", inputProps: {
                                        disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                    } }) })] }), _jsxs(Row, { gutter: [20, 10], children: [_jsx(Col, { span: 12, children: _jsx(InputWithLabel, { label: "City/Suburb", field: "city", inputProps: {
                                        disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                    } }) }), _jsx(Col, { span: 6, children: _jsx(InputWithLabel, { label: "State", field: "state", inputProps: {
                                        disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                    } }) }), _jsx(Col, { span: 6, children: _jsx(InputWithLabel, { label: "Postcode", field: "postCode", inputProps: {
                                        disabled: isNewForm ? !editMode && isExistedPatient : !onEditing || !editMode,
                                    } }) })] })] })] }));
};
export default PatientDetails;
const AutoCompleteWrapper = styled.div `
  //display: none;
  position: absolute;
  border: 1px solid black;
  background-color: #fff;
  max-height: 200px;
  z-index: 9999;
  border-radius: 10px;
`;
