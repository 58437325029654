import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image } from "antd";
import React from "react";
import FormSignIn from "./FormSignIn";
import { LoginPageWrapper, SignInWrapper } from "./index.style";
const LoginPage = () => (_jsxs(LoginPageWrapper, { children: [_jsx("div", { style: { padding: "30px 50px", height: "15%", width: "100%" }, children: _jsx(Image, { src: "/assets/images/logo-text-login.png", alt: "logo and text", preview: false, height: "100%" }) }), _jsx("div", { style: {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            }, children: _jsx(SignInWrapper, { children: _jsx(FormSignIn, {}) }) })] }));
export default LoginPage;
