import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCollectionOrganizationDetails } from "src/api/collection/collectionService";
import PageLayoutWithNoTab from "src/components/layouts/PageLayout/PageLayoutWithNoTab";
import MainLayout from "src/components/layouts/MainLayout";
import { CollectionOrganisationForm } from "./components/CollectionOrganisationForm";
import { LaboratoryFormWrapper } from "./components/LaboratoryFormWrapper";
import { PrincipalAndPracticeWrapper } from "./components/PrincipalAndPracticeWrapper";
import { CollectionOrganisationPageWrapper } from "./index.style";
export const CollectionOrganisationPage = () => {
    const [organizationDetail, setOrganizationDetail] = useState({});
    const location = useLocation();
    const [adminInfo, setAdminInfo] = useState([]);
    const getData = async () => {
        const result = await getCollectionOrganizationDetails();
        setOrganizationDetail(result);
        setAdminInfo(result.adminInfo ?? []);
    };
    useEffect(() => {
        getData();
    }, []);
    return (_jsx(MainLayout, { children: _jsx(PageLayoutWithNoTab, { location: location.pathname, heading: "Collection Organization", children: _jsxs(CollectionOrganisationPageWrapper, { children: [_jsx(CollectionOrganisationForm, { collectionOrganisation: organizationDetail.collectionOrganizationDetail, collectionPoints: organizationDetail.collectionPoints ?? [] }), _jsx(LaboratoryFormWrapper, { laboratoryData: organizationDetail.laboratories }), _jsx(PrincipalAndPracticeWrapper, { adminInfo: adminInfo, setAdminInfo: setAdminInfo })] }) }) }));
};
