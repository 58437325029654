import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Breadcrumb, Tabs } from "antd";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
export const PageLayoutWithBreadcrumb = ({ children, routes, tabLevel = 2, }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const currentLocation = location.pathname.split("/");
    const paths = currentLocation.filter(Boolean).map((path) => `/${path}`);
    const handleChangeTab = (activeKey) => {
        const currentPaths = currentLocation.filter(Boolean);
        const minLevel = Math.min(...routes.map((route) => route.level));
        const prefixRoutes = [];
        const newPaths = currentPaths.reduce((accumulatedPaths, path, index) => {
            const currentLevel = index + minLevel;
            if (index < minLevel) {
                const unavailableRoute = routes.find((route) => route.level === currentLevel && route.path === `/${path}`);
                if (!unavailableRoute) {
                    prefixRoutes.push(path);
                }
            }
            const matchingRoute = routes.find((route) => route.level === currentLevel && route.path === `/${path}` && route.level !== tabLevel);
            if (matchingRoute) {
                accumulatedPaths.push(path);
            }
            return accumulatedPaths;
        }, []);
        const newRoute = routes.find((route) => route.level === tabLevel && route.path === activeKey);
        if (newRoute) {
            newPaths.push(newRoute.path.slice(1));
        }
        const finalPaths = [...new Set([...prefixRoutes, ...newPaths])];
        navigate(`/${finalPaths.join("/")}`);
    };
    return (_jsxs(PageLayoutWithBreadcrumbWrapper, { children: [_jsx(Header, { children: _jsx(CustomBreadcrumb, { separator: ">", children: paths.map((path, index) => {
                        const currentRoute = routes.find((route) => route.level === index + 1 && path === route.path);
                        const fullPath = paths.slice(0, index + 1).join("");
                        if (!currentRoute)
                            return;
                        if (index === paths.length - 1) {
                            return (_jsx(ActiveBreadcrumb, { children: _jsx(Link, { to: fullPath, children: currentRoute.name }) }, path));
                        }
                        else {
                            return (_jsx(Breadcrumb.Item, { children: _jsx(Link, { to: fullPath, children: currentRoute.name }) }, path));
                        }
                    }) }) }), _jsx(CustomTabs, { onChange: handleChangeTab, type: "card", activeKey: routes.find((route) => route.level === tabLevel && location.pathname.includes(route.path))
                    ?.path ?? currentLocation[-1], tabPosition: "top", children: routes
                    .filter((route) => route.level === tabLevel)
                    .map((route) => {
                    const active = location.pathname.includes(route.path);
                    return (_jsx(CustomTabPane, { tab: route.name, disabled: route.disabled, children: active ? children : null }, route.path));
                }) })] }));
};
const ActiveBreadcrumb = styled(Breadcrumb.Item) `
  color: white;
  border-bottom: 0.2rem solid #01b4d2;
  font-weight: bold;
`;
const CustomBreadcrumb = styled(Breadcrumb) `
  padding-inline: 2.5rem;
  padding-top: 0.5rem;
  color: white;

  a {
    color: white !important;
  }

  .ant-breadcrumb-separator {
    color: white;
  }
`;
const CustomTabPane = styled(Tabs.TabPane) `
  height: 100%;
`;
const CustomTabs = styled(Tabs) `
  margin-top: -2.5rem;
  height: 100%;

  .ant-tabs-content-holder {
    padding: 2rem;
  }
  .ant-tabs-nav-wrap {
    justify-content: flex-start;
    margin-left: 2.5rem;
    margin-top: 0.7rem;
  }

  .ant-tabs-nav-list {
    column-gap: 0.625rem;
  }

  .ant-tabs-nav {
    border: none;
    margin: 0 0;
  }

  .ant-tabs-tab {
    color: white;
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    background-color: rgb(1, 180, 210) !important;
    min-width: 10rem !important;
    border: none !important;
    text-align: center !important;
    padding: 0.25rem !important;
    padding-inline: 1rem !important;
  }

  .ant-tabs-tab-active {
    background-color: white !important;
    box-shadow: 2px -2px 4px 0px #00000012;
  }

  .ant-tabs-tab-active:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 7%;
    width: 86%;
    height: 0.125rem;
    background-color: #fdba00;
  }

  .ant-tabs-content {
    display: block;
  }
`;
const PageLayoutWithBreadcrumbWrapper = styled.div `
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
`;
const Header = styled.div `
  height: 5rem;
  background-color: #2c7796;
  width: 100%;
`;
