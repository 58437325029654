import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { LeftOutlined, CloseOutlined } from "@ant-design/icons";
import PinInput from "react-pin-input";
import { Divider, Typography } from "antd";
import { Image } from "src/components/base";
import { verifyVerificationCode } from "src/api/user/userService";
import { BoxWrapper, CloseNoticeBoxBtn, CustomLink, CustomSpace, NoticeBox, SABackBtn, TypographyErrorText, TypographyNoticeTitle, TypographyResendCode, TypographyResendCodeCover, TypographyText, TypographyTitle, VerifyCodeFormWrapper, WhiteTypography, } from "src/pages/ForgotPwdPage/index.style";
export const VerifyCodeForm = (props) => {
    const { email, getCodeType, phone } = props;
    const [errorText, setErrorText] = useState("");
    const [isShowNoticeBox, setIsShowNoticeBox] = useState(false);
    const resendCode = async () => {
        setIsShowNoticeBox(true);
    };
    const onCloseNoticeBox = () => {
        setIsShowNoticeBox(false);
    };
    const onComplete = async (value) => {
        const data = await verifyVerificationCode(email, value);
        if (data.status == "success") {
            if (data.data.customToken) {
                sessionStorage.setItem("customToken", data.data.customToken);
            }
            props.onVerifySuccess();
        }
        else if (data.message == "wrong code") {
            setErrorText("That’s an invalid code. Try again.");
        }
        else {
            setErrorText(data.message);
        }
    };
    return (_jsxs(VerifyCodeFormWrapper, { children: [isShowNoticeBox && (_jsxs(NoticeBox, { children: [_jsx(Image, { src: "/assets/svg/ic-info.svg" }), _jsxs(CustomSpace, { children: [_jsx(TypographyNoticeTitle, { children: "We have sent you another code." }), _jsxs(WhiteTypography, { children: ["Allow a few minutes for the message to arrive.\u00A0", _jsx(CustomLink, { href: "https://www.sampleassist.com", target: "_blank", rel: "noreferrer", children: "Get help" })] })] }), _jsx(CloseNoticeBoxBtn, { shape: "circle", icon: _jsx(CloseOutlined, { style: { fontSize: "10px", color: "white" } }), onClick: onCloseNoticeBox })] })), _jsxs(BoxWrapper, { children: [_jsx(Image, { src: "/assets/svg/ic-letter2.svg", preview: false }), _jsx(TypographyTitle, { children: "Verify your code" }), _jsx(TypographyText, { children: "Enter the code we just sent to" }), _jsx(TypographyText, { children: getCodeType == "email" ? email : `- ${phone.substring(phone.length - 2)}` }), _jsx(PinInput, { length: 6, initialValue: "", 
                        // onChange={(value, index) => {}}
                        type: "numeric", inputMode: "number", style: {
                            textAlign: "left",
                            marginTop: "42px",
                            display: "flex",
                            justifyContent: "space-between",
                        }, inputStyle: {
                            border: "2px solid #ADB3BC",
                            borderRadius: "4px",
                            width: "40px",
                            height: "50px",
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: "#505153",
                        }, onComplete: (value) => onComplete(value), autoSelect: true, regexCriteria: /^[ A-Za-z0-9_@./#&+-]*$/ }), _jsx(TypographyErrorText, { children: errorText }), _jsx(TypographyResendCodeCover, { children: _jsx(TypographyResendCode, { onClick: resendCode, children: "Resend Code" }) }), _jsx(Divider, {}), _jsx("div", { style: { textAlign: "left" }, children: phone != "" && (_jsx(Typography.Link, { onClick: props.getCode, style: { color: "#1A8CFF", fontSize: "14px" }, children: "Get your code another way" })) }), _jsx(SABackBtn, { icon: _jsx(LeftOutlined, { style: {
                                color: "#2F3337",
                                fontWeight: "bold",
                            } }), name: "Back", onClick: () => props.onBack() })] })] }));
};
