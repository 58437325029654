import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { Button, DatePicker, IconButton, Row } from "src/components/base";
const Calender = ({ selectStartDate, selectEndDate }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment());
    const handleTodayClick = () => {
        const today = moment();
        setSelectedDate(today);
        selectStartDate(today);
        selectEndDate(today);
    };
    const openCalendar = () => {
        setIsOpen(!isOpen);
    };
    const onPickDate = (date) => {
        setIsOpen(false);
        setSelectedDate(moment(date));
        selectStartDate(moment(date));
        selectEndDate(moment(date));
        ``;
    };
    const goNextDate = () => {
        setSelectedDate(moment(selectedDate).add(1, "days"));
        selectStartDate(moment(selectedDate).add(1, "days"));
        selectEndDate(moment(selectedDate).add(1, "days"));
    };
    const goBackDate = () => {
        setSelectedDate(moment(selectedDate).add(-1, "days"));
        selectStartDate(moment(selectedDate).add(-1, "days"));
        selectEndDate(moment(selectedDate).add(-1, "days"));
    };
    return (_jsxs(_Fragment, { children: [_jsx(CalenderWrapper, { children: _jsxs(Row, { align: "middle", style: { padding: "5px 5px 5px 15px" }, children: [_jsx("div", { children: _jsx(DatePicker, { id: "date-picker", open: isOpen, onOpenChange: (open) => setIsOpen(open), className: "date-picker", onChange: onPickDate }) }), _jsx("div", { children: _jsx(IconButton, { icon: "/assets/svg/ic-calendar.svg", style: { width: "25px", height: "25px" }, onClick: openCalendar, htmlFor: "date-picker" }) }), _jsx("div", { style: { marginLeft: "5px" }, children: _jsx(IconButton, { icon: "/assets/svg/ic-back.svg", style: { width: "28px", height: "28px" }, onClick: goBackDate }) }), _jsx("div", { style: {
                                fontSize: "16px",
                                color: "#0047B3",
                                marginTop: "3px",
                                marginLeft: "3px",
                            }, children: moment(selectedDate).format("dddd, DD MMMM YYYY") }), _jsx("div", { style: { marginLeft: "3px" }, children: _jsx(IconButton, { icon: "/assets/svg/ic-forth.svg", onClick: goNextDate, style: { width: "28px", height: "28px" } }) })] }) }), _jsx(TodayButton, { onClick: handleTodayClick, isSelectedToday: selectedDate.date(), children: "Today" })] }));
};
export default Calender;
const CalenderWrapper = styled.div `
  border: 1px solid #adb3bc;
  border-radius: 5px;
`;
const TodayButton = styled(Button) `
  border-radius: 4px;
  background: ${(props) => props.isSelectedToday === moment().date() ? "#1A8CFF" : "white"} !important;
  color: ${(props) => (props.isSelectedToday === moment().date() ? "white" : "#1A576F")} !important;
  border: 1px solid #adb3bc !important;
  height: 2.5rem !important;
`;
