import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
const InformationBox = ({ title, content }) => (_jsxs(InformationBoxContainer, { children: [_jsx(TitleText, { children: title }), _jsx(ContentText, { children: content ?? "" })] }));
const InformationBoxContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;
const TitleText = styled.p `
  color: #4b5767;
  font-size: 0.688rem;
  font-weight: 400;
`;
const ContentText = styled.p `
  color: #000000;
  font-size: 0.875rem;
  font-weight: 400;
`;
export default InformationBox;
