import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, TextField, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { message } from "antd";
import { InputWithLabel, Row, TreeSelect } from "src/components/base";
import { auMobilePattern, emailPattern } from "src/patterns/patterns";
const InviteCollectorPage = ({ options, OnSubmitInvitation }) => {
    const [inviteForm, setInviteForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        collectionPointIds: [],
    });
    const onUpdateCollectionPoint = (data) => {
        setInviteForm((prevState) => ({ ...prevState, collectionPointIds: data.value }));
    };
    // TODO: validate input fields
    return (_jsxs("div", { style: { backgroundColor: "white", width: "100%" }, children: [_jsxs("div", { style: { display: "flex", justifyContent: "space-between", margin: "10px 50px" }, children: [_jsxs("div", { style: { display: "flex", alignItems: "center", gap: "15px" }, children: [_jsx("img", { src: "/assets/svg/ic-collectors.svg", alt: "ic-collectors" }), _jsx(Typography, { children: "Invite Collector" })] }), _jsx(Button, { variant: "contained", size: "small", onClick: () => {
                            let mobile = inviteForm.mobile;
                            if (!auMobilePattern.test(mobile)) {
                                message.error("Please enter a valid phone number");
                                return;
                            }
                            if (!emailPattern.test(inviteForm.email)) {
                                message.error("Please enter a valid email");
                                return;
                            }
                            if (!inviteForm.collectionPointIds.length) {
                                message.error("Please select a collection point");
                                return;
                            }
                            if (mobile.startsWith("0")) {
                                mobile = mobile.substring(1);
                            }
                            if (!mobile.startsWith("+61")) {
                                mobile = "+61" + mobile;
                            }
                            inviteForm.mobile = mobile;
                            OnSubmitInvitation(inviteForm);
                        }, style: { textTransform: "none", backgroundColor: "#1A8CFF" }, children: "Send Invitation" })] }), _jsx(Divider, {}), _jsxs("div", { style: {
                    margin: "5% 8%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "10px",
                }, children: [_jsxs(Row, { style: { justifyContent: "space-between" }, children: [_jsxs("div", { style: { display: "flex", flexDirection: "column" }, children: [_jsx("label", { children: "First Name" }), _jsx(TextField, { hiddenLabel: true, size: "small", onChange: (event) => setInviteForm({ ...inviteForm, firstName: event.target.value }) })] }), _jsxs("div", { style: { display: "flex", flexDirection: "column" }, children: [_jsx("label", { children: "Last Name" }), _jsx(TextField, { hiddenLabel: true, size: "small", onChange: (event) => setInviteForm({ ...inviteForm, lastName: event.target.value }) })] })] }), _jsxs(Row, { style: { justifyContent: "space-between", rowGap: "10px" }, children: [_jsxs("div", { style: { display: "flex", flexDirection: "column" }, children: [_jsx("label", { children: "Email" }), _jsx(TextField, { style: { width: "280px" }, hiddenLabel: true, size: "small", onChange: (event) => setInviteForm({ ...inviteForm, email: event.target.value }) })] }), _jsxs("div", { style: { display: "flex", flexDirection: "column" }, children: [_jsx("label", { children: "Mobile Number" }), _jsx(TextField, { hiddenLabel: true, size: "small", InputProps: {
                                            startAdornment: _jsx("span", { style: { opacity: "0.3", marginRight: "5px" }, children: "+61" }),
                                        }, style: { width: "195px" }, onChange: (event) => setInviteForm({ ...inviteForm, mobile: event.target.value }) })] })] }), _jsx("label", { children: "Collection Point (Work Place)" }), _jsx(InputWithLabel, { theme: "3", field: "collectionPointIds", onInputChange: onUpdateCollectionPoint, inputProps: {
                            type: "treeselect",
                            options: options,
                            treeCheckable: true,
                            showCheckedStrategy: TreeSelect.SHOW_CHILD,
                            treeLine: {},
                            treeDefaultExpandAll: true,
                        } })] })] }));
};
export default InviteCollectorPage;
