import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Button, CheckBox, Col, Image, InputWithLabel, Row, Text } from "src/components/base";
import { MESSAGE_BOX_TYPE, SAColourScheme } from "src/constants";
import ContentContext from "src/providers/content";
export const CollectionPointForm = ({ index, form, collectionPoint, onInputChange, editCollectionCentre, deleteCollectionPoint, }) => {
    const { openMessageBox } = useContext(ContentContext);
    const [isCheck, setIsCheck] = useState(false);
    const onCheck = () => {
        const check = !isCheck;
        const formInstance = form;
        const formValue = formInstance.getFieldsValue();
        const collectionList = formValue.collectionCentre.listCollectionPoint;
        const { phoneNumber, timezone, email, address1, address2, suburb, state, postcode } = formInstance.getFieldValue("collectionCentre");
        if (check) {
            collectionList[index] = {
                collectionPointName: suburb,
                phoneNumber,
                timezone,
                email,
                address1,
                address2,
                city: suburb,
                state,
                postcode,
                isSameCentre: check,
            };
            formInstance.setFieldsValue({
                ...formValue,
                collectionCentre: {
                    ...formValue.collectionCentre,
                    collectionList,
                },
            });
        }
        else {
            collectionList[index] = {
                ...collectionList[index],
                isSameCentre: check,
            };
            formInstance.setFieldsValue({
                ...formValue,
                collectionCentre: {
                    ...formValue.collectionCentre,
                    collectionList,
                },
            });
        }
        setIsCheck(check);
    };
    useEffect(() => {
        if (collectionPoint.isSameCentre) {
            setIsCheck(true);
        }
    }, [collectionPoint]);
    return (_jsx(Col, { xs: 24, lg: 12, children: _jsxs("div", { className: "form-centre-point-info", children: [_jsxs("div", { className: "form-centre-point-info-title", children: [_jsx(Text, { style: {
                                color: SAColourScheme.WHITE + "B3",
                                fontSize: 15,
                                marginLeft: 4,
                            }, name: `Service Office (Clinic) ${index + 1}` }), _jsxs("div", { className: "collection-point-name form-box-title", children: [_jsx(InputWithLabel, { className: "text-bold", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "collectionPointName"], onInputChange: onInputChange, inputProps: {
                                        disabled: !editCollectionCentre,
                                    }, rules: [{ required: true }] }), _jsx("div", { className: "collection-point-name title-action", children: editCollectionCentre && (_jsx(Button, { onClick: () => {
                                            openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `Please confirm to delete ${collectionPoint.collectionPointName ?? ""}?`, () => {
                                                deleteCollectionPoint(index, collectionPoint);
                                            });
                                        }, children: _jsx(Image, { src: "/assets/svg/ic-form-delete.svg", preview: false }) })) })] })] }), editCollectionCentre && (_jsxs(Row, { className: "check-box-row", justify: "end", align: "middle", children: [_jsx(Text, { className: "input-label", name: "Same as Collection Centre" }), _jsx(CheckBox, { onChange: onCheck, isCheck: isCheck })] })), _jsxs(Row, { justify: "space-around", children: [_jsx(Col, { span: 13, children: _jsx(InputWithLabel, { label: "Email Address", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "email"], onInputChange: onInputChange, inputProps: {
                                    disabled: !editCollectionCentre,
                                }, rules: [{ required: true }] }) }), _jsx(Col, { span: 10, children: _jsx(InputWithLabel, { label: "Phone Number", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "phoneNumber"], onInputChange: onInputChange, inputProps: {
                                    disabled: !editCollectionCentre,
                                }, rules: [{ required: true }] }) })] }), _jsxs(Row, { justify: "space-around", children: [_jsx(Col, { span: 13, children: _jsx(InputWithLabel, { label: "Address Line 1 (Street Address)", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "address1"], onInputChange: onInputChange, inputProps: {
                                    disabled: !editCollectionCentre,
                                }, rules: [{ required: true }] }) }), _jsx(Col, { span: 10, children: _jsx(InputWithLabel, { label: "Address Line 2 (Optional)", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "address2"], onInputChange: onInputChange, inputProps: {
                                    disabled: !editCollectionCentre,
                                }, rules: [{ required: false }] }) })] }), _jsxs(Row, { gutter: [10, 0], justify: "space-around", children: [_jsx(Col, { span: 13, children: _jsx(InputWithLabel, { label: "City / Suburb", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "suburb"], onInputChange: onInputChange, inputProps: {
                                    disabled: !editCollectionCentre,
                                }, rules: [{ required: true }] }) }), _jsx(Col, { span: 5, children: _jsx(InputWithLabel, { label: "State", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "state"], onInputChange: onInputChange, inputProps: {
                                    disabled: !editCollectionCentre,
                                }, rules: [{ required: true }] }) }), _jsx(Col, { span: 5, children: _jsx(InputWithLabel, { label: "Postcode", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "postcode"], onInputChange: onInputChange, inputProps: {
                                    disabled: !editCollectionCentre,
                                }, rules: [{ required: true }] }) })] }), _jsxs(Row, { gutter: [10, 0], justify: "space-around", align: "middle", style: { marginLeft: "0px", marginRight: "0px" }, children: [_jsx(Col, { span: 1, children: _jsx("img", { src: "/assets/svg/icon-time.svg" }) }), _jsx(Col, { span: 4, children: _jsx("b", { style: {
                                    color: "#00004C",
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                }, children: "Time Zone" }) }), _jsx(Col, { span: 18, children: _jsx(InputWithLabel, { field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "timezone"], inputProps: {
                                    disabled: true,
                                }, onInputChange: onInputChange }) })] }), _jsx(InputWithLabel, { label: "ID", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "id"], onInputChange: onInputChange, inputProps: {
                        disabled: !editCollectionCentre,
                        hidden: true,
                    } }), _jsx(InputWithLabel, { label: "UUID", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "uuid"], onInputChange: onInputChange, inputProps: {
                        disabled: !editCollectionCentre,
                        hidden: true,
                    } }), _jsx(InputWithLabel, { label: "Collection Centre UUID", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "collectionCentreUUID"], onInputChange: onInputChange, inputProps: {
                        disabled: !editCollectionCentre,
                        hidden: true,
                    } }), _jsx(InputWithLabel, { label: "Is Same Collection Centre", field: "collectionCentre", fieldKey: ["listCollectionPoint", index, "isSameCentre"], onInputChange: onInputChange, inputProps: {
                        disabled: !editCollectionCentre,
                        hidden: true,
                    } })] }) }, index));
};
