import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image } from "antd";
import { useState } from "react";
import { SearchBar } from "../components";
import { Wrapper } from "./index.style";
export const MainPage = (props) => {
    const [searchText, setSearchText] = useState("");
    const onTyping = (text) => {
        setSearchText(text);
        props.onSearchText(text);
    };
    const onCardItem = (type) => {
        props.onClickItem(type);
    };
    return (_jsxs(Wrapper, { children: [_jsxs("div", { className: "support-header", children: [_jsx("div", { className: "support-title", style: { color: "#ffffffcc" }, children: "Support" }), _jsx("div", { style: {
                            color: "#FFFFFF",
                            fontSize: "38px",
                            fontWeight: "500",
                            textAlign: "center",
                            marginTop: "30px",
                        }, children: "How can we help you?" }), _jsx("div", { style: { marginTop: "26px" }, children: _jsx(SearchBar, { value: searchText, onChange: onTyping, onClear: () => {
                                setSearchText("");
                            }, onEnter: props.onEnterSearchText }) })] }), _jsxs("div", { className: "support-body", children: [_jsxs("div", { className: "support-card", onClick: () => onCardItem("faq"), children: [_jsx("div", { children: _jsx(Image, { src: "/assets/images/FAQs.png", preview: false, width: 80, height: 51.79 }) }), _jsx("div", { className: "support-title", children: "FAQs" }), _jsx("div", { className: "support-text", children: "You can find some of the most Frequently Asked Questions." })] }), _jsxs("div", { className: "support-card", style: { position: "relative", cursor: "not-allowed" }, children: [_jsx("div", { style: {
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                                    zIndex: 1,
                                } }), _jsx("div", { children: _jsx(Image, { src: "/assets/images/training.png", preview: false, width: 70, height: 55.36 }) }), _jsx("div", { className: "support-title", children: "Training" }), _jsx("div", { className: "support-text", children: "Online Training Resources" })] }), _jsxs("div", { className: "support-card", onClick: () => onCardItem("contact"), children: [_jsx("div", { children: _jsx(Image, { src: "/assets/images/contact-us.png", preview: false, width: 52.72, height: 55 }) }), _jsx("div", { className: "support-title", children: "Contact Us" }), _jsx("div", { className: "support-text", children: "Can\u2019t find what you need?" })] })] })] }));
};
