import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { Button, Col, Image, Row, Text } from "src/components/base";
import { SAColourScheme, fontWeight } from "src/constants";
const contactInfo = [
    { icon: "/assets/svg/ic-phone.svg", text: "+61 2 8006 8111" },
    { icon: "/assets/svg/ic-mail.svg", text: "contact@sampleassist.com" },
    {
        icon: "/assets/svg/ic-location.svg",
        text: "iAccelerate Suite 9, Building 239, Innovation Campus, Squires Way, North Wollongong NSW 2500",
    },
];
export const Footer = () => (_jsx(FooterWrapper, { children: _jsxs(StyledRow, { gutter: [20, 10], children: [_jsxs(StyledCol, { xs: 24, xl: 8, children: [_jsx(Image, { width: 297, src: "/assets/images/logo-text-login.png", preview: false }), _jsx(LogoText, { name: "Pioneers in Digital Health" })] }), _jsx(StyledCol, { xs: 24, md: 12, xl: 8, children: contactInfo.map((item, idx) => (_jsxs(ContactInfoRow, { align: "middle", children: [_jsx(ContactIcon, { children: _jsx(Image, { src: item.icon, preview: false, height: 24, width: 24 }) }), _jsx(ContactText, { name: item.text })] }, idx))) }), _jsxs(StyledCol, { xs: 24, md: 12, xl: 8, children: [_jsx(TermInfoRow, { justify: "start", children: _jsx(TermText, { name: "Sample Assist Pty Ltd\nABN 35 651 932 937" }) }), _jsx(Button, { type: "link", href: "https://www.sampleassist.com/sampleassist-terms-conditions", children: _jsx(TermText, { name: "Terms and Conditions" }) }), _jsx(Button, { type: "link", href: "https://www.sampleassist.com/sampleassist-privacy-policy", children: _jsx(TermText, { name: "Privacy Policy" }) }), _jsx(TermInfoRow, { justify: "end", children: _jsx(TermText, { name: `© Copyright ${new Date().getFullYear()}` }) })] })] }) }));
const FooterWrapper = styled.div `
  background-color: #00004c;
  height: auto;
  min-height: 10rem;
  display: flex;
  padding: 1rem;
  margin-top: auto !important;
  width: 100%;
`;
const StyledRow = styled(Row) `
  display: flex;
  flex: 1;
`;
const StyledCol = styled(Col) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LogoText = styled(Text) `
  white-space: pre-wrap;
  color: ${SAColourScheme.PLASMA} !important;
  font-size: 16px;
  margin-top: 3px;
  margin-left: 1.8rem;
`;
const ContactInfoRow = styled(Row) `
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const ContactIcon = styled.div `
  display: flex;
  flex: 0.4;
  justify-content: flex-end;
`;
const ContactText = styled(Text) `
  display: flex;
  flex: 0.6;
  justify-content: flex-start;
  color: ${SAColourScheme.WHITE} !important;
  font-size: 15px;
  font-weight: ${fontWeight.REGULAR};
  margin-left: 18px;
`;
const TermInfoRow = styled(Row) `
  width: 100%;
  flex-direction: column;
  color: ${SAColourScheme.WHITE} !important;
`;
const TermText = styled(Text) `
  white-space: pre-wrap;
  text-align: center;
  color: ${SAColourScheme.WHITE} !important;
  font-size: 14px;
  font-weight: ${fontWeight.LIGHT};

  a {
    color: ${SAColourScheme.MID_BLUE} !important;
  }
`;
