import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getDrugClassesLeftRowData, getDrugClassesMiddleRowData, getDrugClassesRightRowData, } from "src/api/dashboard/dashboardService";
import { Divider, Spin } from "src/components/base";
import { LeftRowData, LeftRowTitle, MiddleRowData, RightRowData, } from "src/pages/DashboardPage/components/Common";
import HeaderTable from "src/pages/DashboardPage/components/HeaderTable";
import LeftData from "src/pages/DashboardPage/components/LeftData";
import MiddleData from "src/pages/DashboardPage/components/MiddleData";
import RightData from "src/pages/DashboardPage/components/RightData";
import { TestsProcessedWrapper } from "src/pages/DashboardPage/components/TestsProcessed/index.style";
export const DrugClassesInvalid = ({ collectionPoints, resultType }) => {
    const [location, setLocation] = useState("All");
    const [selectYear, setSelectYear] = useState(`${moment().year()}`);
    const [startIndex, setStartIndex] = useState(0);
    const [isMonthView, setIsMonthView] = useState(true);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [leftDrugClassesData, setLeftDrugClassesData] = useState({});
    const [middleDrugClassesData, setMiddleDrugClassesData] = useState({});
    const [rightDrugClassesData, setRightDrugClassesData] = useState({});
    const getLeftRowData = async () => {
        const startDate = moment().format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        const result = await getDrugClassesLeftRowData(location, startDate, endDate, "invalid");
        if (result.status === "success") {
            setLeftDrugClassesData(result?.data);
        }
    };
    const getMiddleRowData = async () => {
        const result = await getDrugClassesMiddleRowData(location, isMonthView, selectYear, "invalid");
        if (result.status === "success") {
            setMiddleDrugClassesData(result?.data);
        }
    };
    const getRightRowData = async () => {
        const startDate = moment(fromDate).format("YYYY-MM-DD");
        const endDate = moment(toDate).format("YYYY-MM-DD");
        const result = await getDrugClassesRightRowData(location, startDate, endDate, "invalid");
        if (result.status === "success") {
            setRightDrugClassesData(result?.data);
        }
    };
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            Promise.all([getLeftRowData(), getMiddleRowData(), getRightRowData()]).then(() => setIsLoading(false));
        }
    }, [isVisible, location]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getRightRowData().then(() => setIsLoading(false));
        }
    }, [fromDate, toDate]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getMiddleRowData().then(() => setIsLoading(false));
        }
    }, [isMonthView, selectYear]);
    return (_jsx(TestsProcessedWrapper, { children: _jsxs(HeaderTable, { title: `Drug Classes - ${resultType} Results`, collectionPoints: collectionPoints, onChangeLocation: setLocation, isVisible: isVisible, setIsVisible: setIsVisible, icon: "ic-test-result.svg", children: [_jsx("div", { style: { position: "relative" }, children: isLoading && (_jsx(Spin, { style: {
                            position: "absolute",
                            top: "50%",
                            left: "40vw",
                        } })) }), _jsxs("div", { style: { opacity: isLoading ? 0.3 : 1, display: "flex" }, children: [_jsxs(LeftData, { title: "Results", drugClasses: true, heading: "INVALID", children: [_jsx(LeftRowData, { title: "AMP", data: leftDrugClassesData["amp"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "BZO", data: leftDrugClassesData["bzo"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "COC", data: leftDrugClassesData["coc"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "MET", data: leftDrugClassesData["met"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "OPI", data: leftDrugClassesData["opi"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "THC", data: leftDrugClassesData["thc"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "OXY", data: leftDrugClassesData["oxy"] || [0, 0, 0, 0] }), _jsx(Divider, {}), _jsx(LeftRowTitle, { title: "Synthetic", highlight: true }), _jsx(LeftRowData, { title: "Cannabinoid", data: leftDrugClassesData["syntheticCannabinoid"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "Cathinone", data: leftDrugClassesData["syntheticCathinone"] || [0, 0, 0, 0] })] }), _jsx(MiddleData, { onSelectYear: setSelectYear, onMonthView: setIsMonthView, startIndex: setStartIndex, children: _jsx(React.Fragment, { children: Object.keys(middleDrugClassesData).map((month, monthIndex) => (_jsxs("div", { children: [_jsx(MiddleRowData, { startIndex: startIndex, data: Object.keys(middleDrugClassesData[month]).map((drugClass) => middleDrugClassesData[month][drugClass]) }, `${month}`), (monthIndex + 1) % 7 === 0 && ( // Check if it's the 7th row
                                        _jsxs(React.Fragment, { children: [_jsx(Divider, {}), _jsx("div", { style: { height: 33 } })] }))] }, month))) }) }), _jsxs(RightData, { onFromDate: setFromDate, onToDate: setToDate, children: [_jsx(RightRowData, { value: rightDrugClassesData["amp"] || 0 }), _jsx(RightRowData, { value: rightDrugClassesData["bzo"] || 0 }), _jsx(RightRowData, { value: rightDrugClassesData["coc"] || 0 }), _jsx(RightRowData, { value: rightDrugClassesData["met"] || 0 }), _jsx(RightRowData, { value: rightDrugClassesData["opi"] || 0 }), _jsx(RightRowData, { value: rightDrugClassesData["thc"] || 0 }), _jsx(RightRowData, { value: rightDrugClassesData["oxy"] || 0 }), _jsx(Divider, {}), _jsx("div", { style: { height: 33 } }), _jsx(RightRowData, { value: rightDrugClassesData["syntheticCannabinoid"] || 0 }), _jsx(RightRowData, { value: rightDrugClassesData["syntheticCathinone"] || 0 })] })] })] }) }));
};
