import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Col, Row, Text } from "src/components/base";
import { SAColourScheme } from "src/styles";
import { getDate, getTestDuration } from "src/utils/helper";
import { TEST_RESULT } from "../../../DonorRecord/types";
import { HairDrugTest } from "./components/HairDrugTest";
import { OralFluidDrugScreen } from "./components/OralFluidDrugScreen";
import { OralFluidDrugTest } from "./components/OralFluidDrugTest";
import { RequestedTestModule } from "./components/RequestedTestModule";
import { UrineDrugScreen } from "./components/UrineDrugScreen";
import { UrineDrugTest } from "./components/UrineDrugTest";
export const DrugScreen = ({ drugData }) => {
    const medicalSamples = drugData?.onsiteMedicalSamples;
    const testStartTime = drugData && getDate(drugData?.medicalTestStartAt);
    const testEndTime = drugData && getDate(drugData?.medicalTestEndAt);
    const testDuration = testStartTime && testEndTime && getTestDuration(testStartTime, testEndTime);
    return (_jsxs(React.Fragment, { children: [_jsx(TitleDiv, { children: "Drug Screen" }), medicalSamples &&
                medicalSamples.length > 0 &&
                medicalSamples
                    .sort((a, b) => a.sampleOrderIndex - b.sampleOrderIndex)
                    .map((medicalSample, index) => (_jsxs(PageWrapper, { children: [_jsxs(HeaderWrapper, { children: [_jsxs(Row, { align: "middle", children: [_jsx(StyledText, { name: "Test Sample" }), _jsx(SampleNumber, { children: medicalSample.sampleOrderIndex })] }), _jsxs(Row, { align: "middle", children: [_jsx(StyledText, { name: "Sample Ref." }), _jsx(StyledText, { name: medicalSample?.referenceNumber })] })] }), medicalSample.testType.name === "Urine Drug Screen" && (_jsx(UrineDrugScreen, { medicalSample: medicalSample })), medicalSample.testType.name === "Oral Fluid Drug Screen" && (_jsx(OralFluidDrugScreen, { medicalSample: medicalSample })), medicalSample.testType.name === "Urine Drug Test (Lab) " && (_jsx(UrineDrugTest, { medicalSample: medicalSample })), medicalSample.testType.name === "Oral Fluid Drug Test (Lab)" && (_jsx(OralFluidDrugTest, { medicalSample: medicalSample })), medicalSample.testType.name === "Hair Drug Test  (Lab)" && (_jsx(HairDrugTest, { medicalSample: medicalSample }))] }, index))), medicalSamples &&
                medicalSamples.length > 0 &&
                medicalSamples
                    .sort((a, b) => a.sampleOrderIndex - b.sampleOrderIndex)
                    .map((medicalSample) => Object.keys(medicalSample.labTestingRequest).length > 0 ? (_jsxs(PageWrapper, { children: [_jsxs(HeaderWrapperRequestedTest, { children: [_jsx(Row, { align: "middle", children: _jsx(StyledText, { name: "Requested Laboratory Test" }) }), _jsxs(Row, { align: "middle", children: [_jsx(StyledText, { name: "Sample Ref." }), _jsx(StyledText, { name: medicalSample?.referenceNumber })] })] }), _jsx(RequestedTestModule, { labTestingRequest: medicalSample.labTestingRequest })] }, medicalSample.sampleOrderIndex)) : null), _jsxs(TestResultFooter, { className: "no-padding", children: [_jsx(Col, { xs: 12, lg: 6, className: "footer-title", children: "Drug Screen Result" }), _jsx(Col, { xs: 12, lg: 9, className: "footer-text", children: `${TEST_RESULT[drugData.testResult]}` }), _jsx(Col, { xs: 12, lg: 9, className: "footer-text" })] }), _jsxs(StyledRow, { children: [_jsx(StyledLabelCol, { xs: 24, lg: 6, children: _jsx(Text, { name: "Drug Test Time", className: "authority-info" }) }), _jsxs(StyledValueCol, { xs: 24, lg: 6, children: [_jsx(Text, { name: "Duration", className: "authority-title" }), _jsx(Text, { name: testDuration, className: "authority-info" })] }), _jsxs(StyledValueCol, { xs: 12, lg: 6, children: [_jsx(Text, { name: "Start Time", className: "authority-title" }), _jsx(Text, { name: moment(getDate(drugData?.medicalTestStartAt)).format("hh:mm a"), className: "authority-info" })] }), _jsxs(StyledValueCol, { xs: 12, lg: 6, children: [_jsx(Text, { name: "End Time", className: "authority-title" }), _jsx(Text, { name: moment(getDate(drugData?.medicalTestEndAt)).format("hh:mm a"), className: "authority-info" })] })] })] }));
};
const TitleDiv = styled.div `
  background: #00004c;
  color: ${SAColourScheme.WHITE};
  padding: 1em 2em;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 5px;
`;
const HeaderWrapper = styled.div `
  background: #01b4d2;
  color: #1a576f;
  font-size: 1rem;
  font-weight: 700;
  min-height: 3rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HeaderWrapperRequestedTest = styled.div `
  background: #156cc9;
  color: #1a576f;
  font-size: 1rem;
  font-weight: 700;
  min-height: 3rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const PageWrapper = styled.div `
  border: 1px solid #01b4d2;
  border-radius: 5px;
  margin-top: 1rem;
`;
const SampleNumber = styled.div `
  color: #01b4d2;
  font-size: 1rem;
  display: flex;
  align-items: center;
  background-color: ${SAColourScheme.WHITE};
  padding: 0.2rem 0.7rem 0.1rem 0.7rem;
  border-radius: 5px;
`;
const StyledText = styled(Text) `
  color: ${SAColourScheme.WHITE} !important;
  font-size: 1rem;
  font-weight: 400;
  margin-right: 1.5rem;
`;
const TestResultFooter = styled(Row) `
  border: 1px solid #156cc9;
  margin: 1rem 0;
  border-radius: 5px;

  .footer-title {
    padding: 10px;
    background: #156cc9;
    font-weight: 700;
    color: ${SAColourScheme.WHITE};
  }

  .footer-text {
    padding: 10px;
    border-left: 1px solid #adb3bc;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;
const StyledRow = styled(Row) `
  margin-bottom: 1rem;
  border: 1px solid #01b4d2;
  border-radius: 1px;

  .authority-title {
    font-size: 1em;
    color: #7f8e9d !important;
  }

  .authority-info {
    font-size: 1em;
    color: #2f3337 !important;
  }
`;
const StyledLabelCol = styled(Col) `
  background-color: #e4f6fb;
  padding: 1em;
`;
const StyledValueCol = styled(Col) `
  padding: 1em;
  display: flex;
  gap: 1em;
`;
