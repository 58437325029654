import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { changePassword, getUserInformation, updateUserAvatar, updateUserDetail, verifyPassword, verifyPasswordDeleteAccount, } from "./action";
export const initialState = {
    userDetail: {
        title: "",
        firstName: "",
        lastName: "",
        dob: "",
        sex: "",
        email: "",
        mobile: "",
        uid: "",
        saId: "",
        avatarUrl: "",
        role: "User Role",
    },
    customTokenId: "",
};
export const userPageDetailSlice = createSlice({
    name: "userPageDetailSlice",
    initialState,
    reducers: {
        setUserAvatar(state, action) {
            state.userDetail.avatarUrl = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserInformation.fulfilled, (state, action) => {
            if (action.payload.status === "success" && action.payload.data) {
                state.userDetail = action.payload.data;
            }
        })
            .addCase(updateUserDetail.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Update user detail failed");
            }
            else {
                message.success("Update user detail successfully");
            }
        })
            .addCase(updateUserDetail.rejected, () => {
            message.error("Update user detail failed");
        })
            .addCase(updateUserAvatar.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Update user avatar detail failed");
            }
            else {
                message.success("Update user avatar detail successfully");
                state.userDetail.avatarUrl = action.payload.data;
            }
        })
            .addCase(updateUserAvatar.rejected, () => {
            message.error("Update user avatar detail failed");
        })
            .addCase(verifyPassword.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("The password you entered is incorrect. \r Please try again.");
            }
            else {
                state.customTokenId = action.payload.data.customTokenId;
            }
        })
            .addCase(verifyPassword.rejected, () => {
            message.error("The password you entered is incorrect. \r Please try again.");
        })
            .addCase(verifyPasswordDeleteAccount.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("The password you entered is incorrect. \r Please try again.");
            }
            else {
                state.customTokenId = action.payload.data.customTokenId;
            }
        })
            .addCase(verifyPasswordDeleteAccount.rejected, () => {
            message.error("The password you entered is incorrect. \r Please try again.");
        })
            .addCase(changePassword.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Change password failed. \r Please try again.");
            }
            else {
                state.customTokenId = "";
            }
        })
            .addCase(changePassword.rejected, () => {
            message.error("Change password failed");
        });
    },
});
export const { setUserAvatar } = userPageDetailSlice.actions;
export default userPageDetailSlice.reducer;
