import styled from "styled-components";
export const Wrapper = styled.div `
  .question-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #e4f6fb;
    height: 40px;
  }
  .question-icon-before {
    width: 15px;
    height: 100%;
    background: #b0c8e2;
    margin-left: 0px;
  }
  .question-icon-after {
    flex: auto;
    text-align: right;
    margin-right: 20px;
  }
  .question-text {
    font-size: 18px;
    font-weight: 500;
    color: #2f3337;
    margin-left: 10px;
  }
  .question-frame {
    margin: 6.5px 0;
  }
  .faq-subject {
    margin-top: 17.5px;
    margin-bottom: 17.5px;
  }
`;
