import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import { LeftOutlined, CloseOutlined } from "@ant-design/icons";
import PinInput from "react-pin-input";
import { Button, Divider, message, Space, Typography } from "antd";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { FooterForm } from "src/pages/TwoFactorAuthPage/FooterForm";
import { supportingColours } from "src/constants";
import { SAButton } from "src/components/base";
import ProfileGreen from "src/assets/svg/ic_profile_green.svg";
import InformationWhite from "src/assets/svg/ic_information_white.svg";
import { sendCodeVerify, verifyCode2FA } from "src/pages/TwoFactorAuthPage/store/action";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch } from "src/store/hooks";
import { getAppInformation } from "src/store/app/action";
export const EnterCodeForm = (props) => {
    const { account, onBack } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [errorText, setErrorText] = useState("");
    const [isShowNoticeBox, setIsShowNoticeBox] = useState(false);
    const resendCode = async () => {
        dispatch(setIsLoading(true));
        setIsShowNoticeBox(true);
        const data = await sendCodeVerify();
        dispatch(setIsLoading(false));
        if (data.error) {
            message.error("Failed to send code");
        }
        else {
            message.success("Code sent successfully");
        }
    };
    const onCloseNoticeBox = () => {
        setIsShowNoticeBox(false);
    };
    const onComplete = async (value) => {
        const data = await verifyCode2FA(value);
        if (data.error) {
            setErrorText("Invalid code");
        }
        else {
            dispatch(getAppInformation());
            const params = new URLSearchParams(location.search);
            const prevLink = params.get("u");
            navigate(prevLink || "/");
        }
    };
    return (_jsx(VerifyCodeFormWrapper, { children: _jsxs(BoxWrapper, { children: [_jsxs(TopBox, { children: ["Hi, ", account.userName] }), _jsxs(InnerBoxWrapper, { children: [_jsx(AlignBox, { children: _jsxs(EmailBox, { children: [_jsx(IconContainer, { children: _jsx(StyledChatIcon, {}) }), account.email] }) }), _jsx(TypographyText, { children: "Enter the code we just sent to your email" }), _jsx(PinInput, { length: 6, initialValue: "", type: "numeric", inputMode: "number", style: {
                                textAlign: "left",
                                marginTop: "42px",
                                display: "flex",
                                justifyContent: "space-between",
                            }, inputStyle: {
                                border: "2px solid #ADB3BC",
                                borderRadius: "4px",
                                width: "40px",
                                height: "50px",
                                fontSize: "24px",
                                fontWeight: "bold",
                                color: "#505153",
                            }, onComplete: (value) => onComplete(value), autoSelect: true, regexCriteria: /^[ A-Za-z0-9_@./#&+-]*$/ }), _jsx(TypographyErrorText, { children: errorText }), _jsx(TypographyResendCodeCover, { children: _jsx(TypographyResendCode, { onClick: resendCode, children: "Resend Code" }) }), _jsx(Divider, {}), isShowNoticeBox && (_jsxs(NoticeBox, { children: [_jsx(InformationIcon, {}), _jsxs(CustomSpace, { children: [_jsx(TypographyNoticeTitle, { children: "We have sent you another code." }), _jsx(WhiteTypography, { children: "Allow a few minutes for the message to arrive." })] }), _jsx(CloseNoticeBoxBtn, { shape: "circle", icon: _jsx(CloseOutlined, { style: { fontSize: "10px", color: "white" } }), onClick: onCloseNoticeBox })] })), _jsx(SABackBtn, { icon: _jsx(LeftOutlined, { style: {
                                    color: "#2F3337",
                                    fontWeight: "bold",
                                } }), name: "Back", onClick: () => onBack() }), _jsx(FooterForm, {})] })] }) }));
};
const TopBox = styled.div `
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid ${supportingColours.BOX_GRAY};
  font-size: 24px;
  padding: 30px 30px;
`;
const EmailBox = styled.div `
  min-width: 100px;
  height: 40px;
  border: 1px solid #d3d3d3; /* 얇은 회색 테두리 */
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 15px;
  padding-left: 3px;
  padding-right: 10px;
`;
const AlignBox = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const VerifyCodeFormWrapper = styled.div `
  width: 100%;
`;
const NoticeBox = styled.div `
  background: #1a8cff;
  height: 65px;
  border-radius: 4px 4px 0 0;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;
const CloseNoticeBoxBtn = styled(Button) `
  background: #ffffff40;
  border: none;
  width: 26px;
  min-width: 26px;
  height: 26px;
`;
const BoxWrapper = styled.div `
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px #ffffff29;
  width: 30rem;
  text-align: center;
`;
const InnerBoxWrapper = styled.div `
  box-shadow: 0px 3px 6px #ffffff29;
  width: 30rem;
  padding: 10% 10%;
  text-align: center;
`;
const TypographyText = styled(Typography) `
  font-size: 18px;
  color: #00004c;
  margin-bottom: 1rem;
  text-align: center;
`;
const TypographyErrorText = styled(Typography) `
  font-size: 14px;
  color: #fd0000;
  margin-top: 3px;
  text-align: left;
`;
const TypographyResendCodeCover = styled.div `
  text-align: right;
  margin-top: 32px;
`;
const TypographyResendCode = styled(Typography.Link) `
  color: #4b5767;
  font-size: 15px;
  font-weight: 400;
`;
const TypographyNoticeTitle = styled(Typography) `
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-align: left;
`;
const CustomSpace = styled(Space) `
  display: flex;
  flex-direction: column;
  gap: 0px !important;
  align-items: start;
`;
const WhiteTypography = styled(Typography) `
  color: white;
`;
const SABackBtn = styled(SAButton) `
  margin-top: 27px;
  border: none;
  color: #4b5767 !important;
  font-size: 18px;
  background-color: transparent !important;
  padding: 1rem;
`;
const IconContainer = styled.div `
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 3px;
`;
const StyledChatIcon = styled(ProfileGreen) `
  width: 32px;
  height: 32px;
`;
const InformationIcon = styled(InformationWhite) `
  object-fit: contain;
  margin: 6px 6px;
`;
