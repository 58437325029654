var TestType;
(function (TestType) {
    TestType[TestType["UrineDrugTest"] = 1] = "UrineDrugTest";
    TestType[TestType["BreathAlcoholTest"] = 2] = "BreathAlcoholTest";
    TestType[TestType["OralFluidDrugTest"] = 3] = "OralFluidDrugTest";
    TestType[TestType["RapidOralFluidDrugScreen"] = 4] = "RapidOralFluidDrugScreen";
    TestType[TestType["HairDrugTest"] = 5] = "HairDrugTest";
    TestType[TestType["RapidUrineDrugScreen"] = 6] = "RapidUrineDrugScreen";
})(TestType || (TestType = {}));
var OnsiteJobAllocationStatus;
(function (OnsiteJobAllocationStatus) {
    OnsiteJobAllocationStatus[OnsiteJobAllocationStatus["Allocated"] = 1] = "Allocated";
    OnsiteJobAllocationStatus[OnsiteJobAllocationStatus["Accepted"] = 2] = "Accepted";
    OnsiteJobAllocationStatus[OnsiteJobAllocationStatus["Rejected"] = 3] = "Rejected";
})(OnsiteJobAllocationStatus || (OnsiteJobAllocationStatus = {}));
var OnsiteJobType;
(function (OnsiteJobType) {
    OnsiteJobType[OnsiteJobType["Normal"] = 1] = "Normal";
    OnsiteJobType[OnsiteJobType["Callout"] = 2] = "Callout";
})(OnsiteJobType || (OnsiteJobType = {}));
var OnsiteJobStatus;
(function (OnsiteJobStatus) {
    OnsiteJobStatus[OnsiteJobStatus["Draft"] = 0] = "Draft";
    OnsiteJobStatus[OnsiteJobStatus["Planned"] = 1] = "Planned";
    OnsiteJobStatus[OnsiteJobStatus["InProgress"] = 2] = "InProgress";
    OnsiteJobStatus[OnsiteJobStatus["Completed"] = 3] = "Completed";
    OnsiteJobStatus[OnsiteJobStatus["Archived"] = 4] = "Archived";
    OnsiteJobStatus[OnsiteJobStatus["Cancelled"] = 5] = "Cancelled";
})(OnsiteJobStatus || (OnsiteJobStatus = {}));
var OnsiteJobServiceType;
(function (OnsiteJobServiceType) {
    OnsiteJobServiceType[OnsiteJobServiceType["RandomTesting"] = 1] = "RandomTesting";
    OnsiteJobServiceType[OnsiteJobServiceType["IncidentTesting"] = 3] = "IncidentTesting";
    OnsiteJobServiceType[OnsiteJobServiceType["PreEmployment"] = 4] = "PreEmployment";
    OnsiteJobServiceType[OnsiteJobServiceType["ReturnToWork"] = 5] = "ReturnToWork";
})(OnsiteJobServiceType || (OnsiteJobServiceType = {}));
var OnsiteClientType;
(function (OnsiteClientType) {
    OnsiteClientType[OnsiteClientType["Normal"] = 1] = "Normal";
    OnsiteClientType[OnsiteClientType["Callout"] = 2] = "Callout";
})(OnsiteClientType || (OnsiteClientType = {}));
var OnsiteClientLocationStatus;
(function (OnsiteClientLocationStatus) {
    OnsiteClientLocationStatus[OnsiteClientLocationStatus["active"] = 1] = "active";
    OnsiteClientLocationStatus[OnsiteClientLocationStatus["archive"] = 2] = "archive";
})(OnsiteClientLocationStatus || (OnsiteClientLocationStatus = {}));
var CombinationTestType;
(function (CombinationTestType) {
    CombinationTestType[CombinationTestType["AlcoholOnly"] = 1] = "AlcoholOnly";
    CombinationTestType[CombinationTestType["AlcoholDrug"] = 2] = "AlcoholDrug";
    CombinationTestType[CombinationTestType["DrugOnly"] = 3] = "DrugOnly";
})(CombinationTestType || (CombinationTestType = {}));
var ScreeningProcessType;
(function (ScreeningProcessType) {
    ScreeningProcessType[ScreeningProcessType["PrimaryTest"] = 1] = "PrimaryTest";
    ScreeningProcessType[ScreeningProcessType["SecondaryTest"] = 2] = "SecondaryTest";
    ScreeningProcessType[ScreeningProcessType["TertiaryTest"] = 3] = "TertiaryTest";
})(ScreeningProcessType || (ScreeningProcessType = {}));
var OnsiteJobClientStatus;
(function (OnsiteJobClientStatus) {
    OnsiteJobClientStatus[OnsiteJobClientStatus["InHouse"] = 1] = "InHouse";
    OnsiteJobClientStatus[OnsiteJobClientStatus["MobileClinic"] = 2] = "MobileClinic";
})(OnsiteJobClientStatus || (OnsiteJobClientStatus = {}));
const RequestOnsiteJobInitialData = {
    type: null,
    status: OnsiteJobStatus.Planned,
    clientStatus: OnsiteJobClientStatus.InHouse,
    serviceType: null,
    onsiteClientId: "",
    collectionPointId: "",
    onsiteClientLocationId: "",
    onsiteClientRepresentativeId: null,
    collectorIds: [],
    startAt: null,
    duration: null,
    noOfDonors: 0,
    generalInstruction: "",
    additionalTestPreference: "",
    specialInstructionTestPreference: "",
    combinationTestType: null,
    screeningProcessPreferences: [],
    alcoholPreferences: [],
};
const RequestOnsiteJobCallOutInitialData = {
    type: null,
    status: OnsiteJobStatus.Planned,
    clientStatus: OnsiteJobClientStatus.InHouse,
    serviceType: null,
    onsiteClientId: "",
    collectionPointId: "",
    onsiteClientLocationId: "",
    collectorIds: [],
    startAt: null,
    duration: null,
    noOfDonors: 0,
    generalInstruction: "",
};
const ResponseClientDefaultInitialData = {
    onsiteClient: {
        id: "",
        reference: "",
        type: OnsiteClientType.Normal,
        generalInstruction: "",
        donorSelectionProcess: "",
        screeningTestingProcess: "",
        testConfirmationInstruction: "",
        globalSiteAccessInstruction: "",
        additionalTestPreference: "",
        specialInstructionTestPreference: "",
        combinationTestType: CombinationTestType.AlcoholDrug,
        screeningProcessPreferences: [],
        alcoholPreferences: [],
    },
    onsiteClientLocations: [],
    clientRepresentatives: [],
};
const initialRequestExistedOnsiteJobData = {
    job: {
        id: "",
        type: null,
        status: OnsiteJobStatus.Planned,
        clientStatus: OnsiteJobClientStatus.InHouse,
        serviceType: OnsiteJobServiceType.IncidentTesting,
        onsiteClientId: "",
        collectionPointId: "",
        combinationTestType: CombinationTestType.DrugOnly,
        onsiteClientLocationId: "",
        onsiteClientRepresentativeId: null,
        generalInstruction: "",
        additionalTestPreference: "",
        specialInstructionTestPreference: "",
        startAt: null,
        endAt: null,
        duration: 0,
        reference: "",
        noOfDonors: 0,
        collectorIds: [],
        allocatedCollectors: [],
        alcoholPreferences: [],
        screeningProcessPreferences: [],
    },
    collectionPoint: {
        name: "",
        id: "",
        testingDevices: [],
        collectors: [],
    },
    onsiteClient: {
        id: "",
        name: "",
        reference: "",
        generalInstruction: "",
        donorSelectionProcess: "",
        screeningTestingProcess: "",
        testConfirmationInstruction: "",
        globalSiteAccessInstruction: "",
        additionalTestPreference: "",
        specialInstructionTestPreference: "",
        type: OnsiteClientType.Normal,
    },
    onsiteClientLocations: [],
    clientRepresentatives: [],
};
export { CombinationTestType, initialRequestExistedOnsiteJobData, OnsiteClientLocationStatus, OnsiteClientType, OnsiteJobAllocationStatus, OnsiteJobClientStatus, OnsiteJobServiceType, OnsiteJobStatus, OnsiteJobType, RequestOnsiteJobCallOutInitialData, RequestOnsiteJobInitialData, ResponseClientDefaultInitialData, ScreeningProcessType, TestType, };
