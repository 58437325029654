import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Col, Dropdown, Image, Menu, Select, Space } from "antd";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Text from "antd/lib/typography/Text";
import { MESSAGE_BOX_TYPE, SAColourScheme, mainAppColours } from "src/constants";
import ContentContext from "src/providers/content";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
import { SearchInput } from "src/components/base";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { OnsiteClientType } from "src/pages/JobManagement/store/types";
import { setIsLoading } from "src/store/app/slice";
import { PaginatedDataTable } from "src/components/commonElement/PaginatedDataTable";
import { listClientTypes, selectIsArchiveTableVisible, selectPagingMetaData, selectSearchString, } from "../NewClient/components/ClientDetails/store/selector";
import { setClientName, setSearchString, setSelectedClientType, } from "../NewClient/components/ClientDetails/store/slice";
import { archiveClient, getListArchivedClients, getListClients, } from "../NewClient/components/ClientDetails/store/action";
export const ClientData = ({ clientsData }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const clientType = useAppSelector(listClientTypes);
    const pagingMetaData = useAppSelector(selectPagingMetaData);
    const searchString = useAppSelector(selectSearchString);
    const tableVisible = useAppSelector(selectIsArchiveTableVisible);
    const { openMessageBox } = useContext(ContentContext);
    const columns = [
        {
            title: "Client Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (_jsx("a", { onClick: () => {
                    navigate(`/client-management/client/${record.id}/client-details`);
                }, children: text })),
        },
        {
            title: "Contact",
            dataIndex: "contact",
            key: "contact",
            sorter: (a, b) => a.contact.localeCompare(b.contact),
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
            sorter: (a, b) => a.mobile.localeCompare(b.mobile),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: "Client Reference",
            dataIndex: "clientReference",
            key: "clientReference",
            sorter: (a, b) => a.clientReference.localeCompare(b.clientReference),
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Call Out",
            dataIndex: "type",
            key: "type",
            sorter: (a, b) => a.type - b.type,
            render: (text) => _jsx(Space, { children: text === OnsiteClientType.Normal ? "No" : "Yes" }),
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(Menu, { style: {
                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                        width: "150px",
                    }, children: [_jsx(Menu.Item, { onClick: () => {
                                navigate(`/client-management/client/${record.id}/client-details`);
                            }, children: "View/Update" }, "1"), _jsx(Menu.Item, { style: {
                                borderTop: "1px solid #ADB3BC",
                                width: "80%",
                                margin: "0 auto",
                            }, onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `Client with Reference No. ${record.clientReference} will be archived.`, async () => {
                                    dispatch(setIsLoading(true));
                                    await dispatch(archiveClient(record.id));
                                    await dispatch(getListClients({}));
                                    if (tableVisible) {
                                        await dispatch(getListArchivedClients({}));
                                    }
                                    dispatch(setIsLoading(false));
                                });
                            }, children: _jsx("span", { style: { display: "flex", justifyContent: "flex-end" }, children: "Archive" }) }, "2")] }), trigger: ["click"], children: _jsx("div", { className: "menu-options", children: _jsx(ICMenuOptions, {}) }) })),
        },
    ];
    useEffect(() => {
        const handler = setTimeout(() => {
            dispatch(setIsLoading(true));
            const promises = [dispatch(getListClients({ newPage: 1, size: 10 }))];
            if (tableVisible) {
                promises.push(dispatch(getListArchivedClients({ newPage: 1, size: 10 })));
            }
            Promise.all(promises).finally(() => {
                dispatch(setIsLoading(false));
            });
        }, 1000);
        return () => {
            clearTimeout(handler);
        };
    }, [searchString]);
    return (_jsxs(React.Fragment, { children: [_jsxs(HeaderFilterWrapper, { children: [_jsxs(Col, { span: 10, children: [_jsx(Image, { src: "/assets/svg/ic-client.svg", height: 30, width: 30, preview: false }), _jsx("span", { className: "header-text", children: "Clients" })] }), _jsxs(Col, { span: 14, className: "show-select", children: [_jsxs(_Fragment, { children: [_jsx(Text, { className: "text", children: "Show" }), _jsx(Select, { className: "type-select", defaultValue: 0, size: "small", options: clientType, onChange: async (value) => {
                                            dispatch(setIsLoading(true));
                                            dispatch(setSelectedClientType(value));
                                            await dispatch(getListClients({}));
                                            if (tableVisible) {
                                                await dispatch(getListArchivedClients({}));
                                            }
                                            dispatch(setIsLoading(false));
                                        } })] }), _jsx(SearchInput, { onChange: (e) => {
                                    dispatch(setIsLoading(true));
                                    dispatch(setSearchString(e.target.value));
                                } }), _jsx(Button, { className: "add-new", children: _jsxs("a", { color: "text.primary", onClick: () => {
                                        dispatch(setClientName("[New Client]"));
                                        navigate("/client-management/add-new");
                                    }, children: [_jsx("span", { className: "add-icon", children: _jsx(Image, { src: "/assets/svg/add-icon.svg", preview: false }) }), _jsx("span", { children: "Add New" })] }, "1") })] })] }), _jsx(PaginatedDataTable, { columns: columns, dataSource: clientsData, pagingMetaData: pagingMetaData, onTableChange: async (newPage, size) => {
                    dispatch(setIsLoading(true));
                    await dispatch(getListClients({ newPage, size }));
                    dispatch(setIsLoading(false));
                } })] }));
};
export const TableWrapper = styled.div `
  z-index: 10;
  .menu-options {
    cursor: pointer;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: ${SAColourScheme.PLASSMA10};
      border-bottom: 1px solid #adb3bc;
    }
  }

  .options-dropdown {
    border: 1px solid ${mainAppColours.MID_BLUE};
  }

  .menu-options:hover {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${SAColourScheme.PLASMA};
  }

  .client-data-table {
    border: 1px solid #d9d9d9;
  }
`;
export const HeaderFilterWrapper = styled.div `
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  align-items: center;

  .header-text {
    margin-left: 10px;
    font-size: 16px;
    color: #000000;
  }

  .add-new {
    border: 1px solid ${mainAppColours.MID_BLUE};
    border-radius: 4px;
    color: #1a8cff;

    .add-icon {
      margin-right: 5px;
    }
  }

  .show-select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
  .text {
    font-size: 14;
    color: #4b5767;
  }

  .type-select {
    width: 10rem;
  }
  .ant-select-selector {
    height: 32px !important;
  }

  .ant-select-selection-item {
    line-height: 32px !important;
  }

  .search-input {
    height: 32px !important;

    .ant-input {
      font-size: 14px !important;
    }

    .ant-image-img {
      height: 15px !important;
      width: 15px !important;
      margin-top: -5px !important;
    }
  }
`;
