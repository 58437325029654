import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Image, Row } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Text } from "src/components/base";
import { SAColourScheme } from "src/styles";
import { convertMinutesToHoursAndMinutes, formatLatitudeLongitude } from "src/utils/helper";
export const RecordCard = ({ record }) => (_jsxs(StyledRow, { gutter: [55, 10], children: [_jsxs(Col, { span: 12, children: [_jsx(Text, { name: moment(new Date(record?.clockOn?.clockOnAt)).format("ddd DD MMM YYYY") }), _jsxs(CardDiv, { children: [_jsxs(HeaderDiv, { children: [_jsxs(Row, { children: [_jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-location.svg", preview: false, height: 23, className: "logo-1" }), _jsx(Text, { name: record.clientName + " -", className: "text-dark" }), _jsx(Text, { name: record.serviceLocation, className: "text-dark" })] }), _jsxs(Row, { children: [_jsx(Text, { name: "Total", className: "text-light" }), _jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-time-icon.svg", preview: false, height: 22, className: "logo-2" }), _jsx(Text, { name: convertMinutesToHoursAndMinutes(record?.total), className: "text-dark" })] })] }), _jsxs(ContentDiv, { children: [_jsxs(Row, { children: [_jsx(Text, { name: "Clock In" }), _jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-time-icon.svg", preview: false, height: 20, className: "logo-2" }), _jsx(Text, { className: "text-dark", name: moment(new Date(record?.clockOn?.clockOnAt)).format("hh:mm a") })] }), _jsxs(Row, { children: [_jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-pin.svg", preview: false, height: 20, className: "logo-1" }), _jsx(Text, { className: "text-light", name: (record?.clockOn?.latitude
                                                ? formatLatitudeLongitude(record?.clockOn?.latitude)
                                                : "") +
                                                ", " +
                                                (record?.clockOn?.longitude
                                                    ? formatLatitudeLongitude(record?.clockOn?.longitude)
                                                    : "") })] })] }), _jsxs(ContentDiv, { children: [_jsxs(Row, { children: [_jsx(Text, { name: "Clock Out" }), _jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-time-icon.svg", preview: false, height: 20, className: "logo-2" }), _jsx(Text, { className: "text-dark", name: record?.clockOff?.clockOffAt
                                                ? moment(new Date(record?.clockOff?.clockOffAt)).format("hh:mm a")
                                                : "Did not clock off" })] }), _jsxs(Row, { children: [_jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-pin.svg", preview: false, height: 20, className: "logo-1" }), _jsx(Text, { className: "text-light", name: (record?.clockOff?.latitude
                                                ? formatLatitudeLongitude(record?.clockOff?.latitude)
                                                : "") +
                                                ", " +
                                                (record?.clockOff?.longitude
                                                    ? formatLatitudeLongitude(record?.clockOff?.longitude)
                                                    : "") })] })] })] })] }), _jsxs(Col, { span: 12, children: [_jsx(Text, { name: "Job Notes", className: "text-light" }), _jsxs(NotesDiv, { children: [record?.clockOff?.note ? (_jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-notes-blue.svg", preview: false, height: 22 })) : (_jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-notes-white.svg", preview: false, height: 22 })), _jsx(Text, { className: "notes-text", name: record?.clockOff?.note ?? "" })] })] })] }));
const StyledRow = styled(Row) `
  margin: 1rem 0;

  .ant-typography {
    color: #7f8e9d !important;
    font-weight: 700;
    font-size: 14px !important;
  }

  .text-dark {
    color: ${SAColourScheme.BLACK} !important;
    font-weight: 400;
  }

  .text-light {
    color: #7f8e9d !important;
    font-weight: 400;
  }
`;
const CardDiv = styled.div `
  border: 1px solid #adb3bc;
  min-height: 9.3em;
  background: ${SAColourScheme.WHITE};

  .logo-1 {
    padding-right: 10px;
  }

  .logo-2 {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
const HeaderDiv = styled.div `
  background: ${SAColourScheme.PLASSMA10};
  padding: 0.6rem 1rem;
  border-bottom: 1px solid #adb3bc;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
`;
const ContentDiv = styled.div `
  padding: 0.6rem 1rem;
  border-bottom: 1px solid #adb3bc;
  display: flex;
  justify-content: space-between;

  .red-text {
    font-weight: 400;
    color: #fd0000 !important;
  }
  &:last-child {
    border-bottom: none !important;
  }
`;
const NotesDiv = styled.div `
  border: 1px solid #adb3bc;
  height: 9.3em;
  background: ${SAColourScheme.WHITE};
  padding: 10px;
  overflow-y: auto;

  .ant-image-img {
    padding-right: 10px;
  }
  .notes-text {
    font-weight: 400;
  }
`;
