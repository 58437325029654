import styled from "styled-components";
export const TitleWrapper = styled.div `
  .content-title {
    height: auto;
    background: #e4f6fb;
  }
  span.ant-typography.body-title {
    font-size: 28px;
    font-weight: bold;
  }
`;
