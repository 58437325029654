const selectTestingDevices = (state) => {
    const { listTestingDevices, selectedCollectionPointId } = state.deviceManagementPage.breathalyserPage;
    if (!selectedCollectionPointId)
        return listTestingDevices;
    return listTestingDevices.filter((device) => device.collectionPointIds.includes(selectedCollectionPointId));
};
const selectArchivedTestingDevices = (state) => {
    const { listArchivedTestingDevices, selectedCollectionPointId } = state.deviceManagementPage.breathalyserPage;
    if (!selectedCollectionPointId)
        return listArchivedTestingDevices;
    return listArchivedTestingDevices.filter((device) => device.collectionPointIds.includes(selectedCollectionPointId));
};
const selectListCollectionPoints = (state) => {
    const { collectionPoints } = state.authentication.app;
    const respondedCollectionPoints = collectionPoints.map((collectionPoint) => ({
        name: collectionPoint.id,
        value: collectionPoint.collectionPointName,
    }));
    return [
        {
            name: "",
            value: "All",
        },
        ...respondedCollectionPoints,
    ];
};
const selectCollectionPoint = (state) => {
    const { collectionPoints } = state.authentication.app;
    const { selectedCollectionPointId } = state.deviceManagementPage.breathalyserPage;
    const collectionPoint = collectionPoints.find((point) => point.id === selectedCollectionPointId);
    if (collectionPoint)
        return {
            name: collectionPoint.id,
            value: collectionPoint.collectionPointName,
        };
    return { name: "", value: "All" };
};
const selectTestingDeviceDetail = (state) => state.deviceManagementPage.breathalyserPage.testingDeviceDetails;
export { selectTestingDevices, selectListCollectionPoints, selectCollectionPoint, selectTestingDeviceDetail, selectArchivedTestingDevices, };
