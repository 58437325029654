import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image } from "antd";
import Text from "antd/lib/typography/Text";
import { SAColourScheme } from "src/constants";
import { FormBoxWrapper } from "./index.style";
export const FormBox = ({ title, textColor, titleStyle, backgroundColor, containerStyle, icon, iconBorderColor, iconStyle, children, titleComponent, }) => {
    const renderTitle = () => {
        if (title && typeof title === "string") {
            return (_jsxs("div", { className: "form-title", style: { ...titleStyle }, children: [_jsx(Text, { style: { color: textColor ?? SAColourScheme.WHITE }, children: title }), titleComponent] }));
        }
        else if (title && typeof title === "object") {
            return title;
        }
        else {
            return;
        }
    };
    return (_jsxs(FormBoxWrapper, { style: {
            backgroundColor: backgroundColor ?? SAColourScheme.MID_BLUE,
            ...containerStyle,
        }, children: [icon && (_jsx("div", { className: "form-icon", style: {
                    borderColor: iconBorderColor ?? backgroundColor ?? SAColourScheme.MID_BLUE,
                    ...iconStyle,
                }, children: _jsx(Image, { src: icon, preview: false }) })), renderTitle(), children] }));
};
