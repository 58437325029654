import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { Button, Col, Form, FormTitle, Image, InputWithLabel, Loading, Row, Text, } from "src/components/base";
import { APPOINTMENT_STATUS_NUMBER, MESSAGE_BOX_TYPE, SAColourScheme, supportingColours, } from "src/constants";
import { CalendarPage } from "src/pages/CalendarPage";
import ContentContext from "src/providers/content";
import IC_SWAP_BUTTON from "src/assets/svg/ic-cm-swap-button.svg";
import { useAppSelector } from "src/store/hooks";
import { selectTestTypes } from "src/store/app/selector";
import { AppointmentDetailWrapper } from "./AppointmentDetails.style";
import { CustomSelect, DurationSelect, RequestTest } from "./InputComponents/index";
export const AppointmentDetails = ({ collectionPoints, requestTestCategory, appointmentStatus, duration, onInputChange, isLoading, isEdit, onEditing, enableEdit, restoreAppointment, cancelAppointment, updateAppointment, testTypeUUIDList, isNewForm, onResendEmail, onSwapSection, }) => {
    const testTypes = useAppSelector(selectTestTypes);
    const [requestedTestType, setRequestedTestType] = useState([]);
    const getData = async () => {
        setRequestedTestType(testTypes);
    };
    useEffect(() => {
        getData();
    }, []);
    const { openMessageBox, openPopupBox } = useContext(ContentContext);
    const renderButtons = () => {
        if (isNewForm) {
            return (_jsx(Col, { id: "center", span: 6, children: _jsxs(Button, { className: "submit-button", type: "primary", htmlType: "submit", style: { textAlign: isLoading ? "center" : "right" }, disabled: isLoading, children: [isLoading ? (_jsx(Loading, { iconProps: { style: { color: "#FFFFFF" } } })) : (_jsx(Image, { src: "/assets/svg/ic-appointment-white.svg", preview: false })), !isLoading && `+ Create Appointment`] }) }));
        }
        else {
            return (_jsxs(Col, { span: 6, style: { alignSelf: "end" }, children: [_jsx("div", { className: "row-button", children: !onEditing && (_jsxs(Button, { className: "cancel-button", style: { backgroundColor: SAColourScheme.RED }, type: "primary", onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT, "Please confirm to cancel this appointment?", () => {
                                    cancelAppointment();
                                });
                            }, disabled: appointmentStatus === APPOINTMENT_STATUS_NUMBER.CANCELED, children: [appointmentStatus === APPOINTMENT_STATUS_NUMBER.CANCELED && "Cancelled", appointmentStatus !== APPOINTMENT_STATUS_NUMBER.CANCELED && "Cancel Appointment"] })) }), !onEditing && (_jsx("div", { className: "row-button", children: _jsx(Button, { className: "resend-button", style: { backgroundColor: "#01B4D2" }, type: "primary", icon: _jsx("img", { src: "/assets/svg/ic-letter3.svg", alt: "ic-letter" }), onClick: onResendEmail, disabled: appointmentStatus === APPOINTMENT_STATUS_NUMBER.CANCELED, children: "Resend Email" }) })), _jsx("div", { className: "row-button", children: appointmentStatus === APPOINTMENT_STATUS_NUMBER.CANCELED ? (_jsx(Button, { className: "update-button", style: {
                                backgroundColor: supportingColours.TEXT_LIGHT_GRAY,
                            }, type: "primary", onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT, "Please confirm to restore this appointment?", () => {
                                    restoreAppointment();
                                });
                            }, children: "Restore Appointment" })) : (_jsx(Button, { className: "update-button", type: "primary", onClick: () => {
                                if (onEditing) {
                                    enableEdit(false);
                                    updateAppointment();
                                }
                                else {
                                    enableEdit(true);
                                }
                            }, children: !onEditing ? "Edit Appointment" : "Save Appointment" })) })] }));
        }
    };
    return (_jsxs(AppointmentDetailWrapper, { children: [_jsx(FormTitle, { title: "Appointment Details", iconButton: _jsx(IconButton, { onClick: onSwapSection, children: _jsx(IC_SWAP_BUTTON, {}) }) }), _jsxs("div", { className: "appointment-details", children: [_jsxs(Row, { children: [_jsx(Col, { className: "column-form", span: 9, children: _jsx(InputWithLabel, { label: "Collection Point Location", field: "collectionPointUUID", inputProps: {
                                        type: "select",
                                        options: collectionPoints?.flat().map((item) => ({
                                            label: item.collectionPointName,
                                            value: item.id,
                                        })),
                                        disabled: isEdit && !onEditing,
                                    }, onInputChange: onInputChange, rules: [{ required: true }] }) }), _jsx(Col, { className: "column-form", span: 9, children: _jsx(InputWithLabel, { label: "Date", field: "date", inputProps: {
                                        type: "date",
                                        format: "DD MMM YYYY",
                                        placeholder: "DD/MM/YYYY",
                                        disabled: isEdit && !onEditing,
                                        disabledDate: (current) => current < moment().startOf("D"),
                                    }, onInputChange: onInputChange, rules: [{ required: true }], rightComponent: 
                                    // eslint-disable-next-line react/jsx-wrap-multilines
                                    _jsx(Button, { style: {
                                            padding: 6,
                                            border: "none",
                                            height: 44,
                                            borderRadius: 4,
                                            marginLeft: 6,
                                        }, onClick: () => {
                                            openPopupBox(_jsx(CalendarPage, { isPopup: true }));
                                        }, children: _jsx(Image, { src: "assets/svg/ic-calendar.svg", preview: false, width: 30, height: 30 }) }) }) }), _jsx(Col, { span: 6, children: _jsxs(Row, { children: [_jsx(Col, { className: "column-form", span: 14, children: _jsx(InputWithLabel, { label: "Time", field: "time", inputProps: {
                                                    type: "time",
                                                    disabled: isEdit && !onEditing,
                                                    minuteStep: 5,
                                                    onSelect: onInputChange,
                                                    placeholder: "HH:MM",
                                                }, onInputChange: onInputChange, rules: [{ required: true }] }) }), _jsx(Col, { span: 10, children: _jsxs("div", { className: "item-group", children: [_jsx(Text, { className: "item-title", name: "Duration" }), _jsx(Form.Item, { rules: [{ required: true }], name: "duration", children: _jsx(DurationSelect, { value: duration, className: "item-input", field: "duration", onInputChange: onInputChange, disabled: isEdit && !onEditing }) }, "duration")] }) })] }) })] }), _jsxs(Row, { children: [_jsxs(Col, { span: 18, children: [_jsxs(Row, { children: [_jsx(Col, { className: "column-form", span: 12, children: _jsxs("div", { className: "item-group", children: [_jsx(Text, { className: "item-title", name: "Requested Test" }), _jsx(Form.Item, { name: "parentTestDataList", rules: [{ required: true }], children: _jsx(RequestTest, { requestedTestType: requestedTestType, testTypeUUIDList: testTypeUUIDList, className: "item-input", field: "parentTestDataList", onInputChange: onInputChange, disabled: isEdit && !onEditing }) })] }) }), _jsx(Col, { className: "column-form", span: 12, children: _jsxs("div", { className: "item-group", children: [_jsx(Text, { className: "item-title", name: "Test Category" }), _jsx(Form.Item, { name: "testCategory", rules: [{ required: true }], children: _jsx(CustomSelect, { className: "item-input", options: requestTestCategory
                                                                    ?.filter((item) => item.isValid)
                                                                    .map((item) => ({
                                                                    label: item.name,
                                                                    value: item.id,
                                                                })), field: "testCategory", onInputChange: onInputChange, disabled: isEdit && !onEditing }) })] }) })] }), _jsx(Row, { children: _jsx(Col, { className: "column-form", span: 24, children: _jsx(InputWithLabel, { label: "Note", field: "notes", onInputChange: onInputChange, inputProps: {
                                                    disabled: isEdit && !onEditing,
                                                } }) }) })] }), renderButtons()] })] })] }));
};
