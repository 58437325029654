import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { archiveClientLocation, createClientLocationDetails, deleteClientLocationContact, getListArchivedClientLocations, getListClientLocations, getLocationDetail, restoreClientLocation, updateClientLocationDetails, } from "./action";
export const initialState = {
    listClientLocations: [],
    listArchivedClientLocations: [],
    locationDetails: null,
    contactDetails: [],
    isEdit: false,
    validationFail: false,
    isAddNew: false,
    isListPage: true,
    locationId: null,
    contactDetailsDefaultData: [],
};
const ClientLocationsSlice = createSlice({
    name: "ClientLocationsSlice",
    initialState,
    reducers: {
        setDefaultContacts(state) {
            state.contactDetails = state.contactDetailsDefaultData;
        },
        setContacts(state, action) {
            state.contactDetails = action.payload;
        },
        setIsAddNew(state, action) {
            state.isAddNew = action.payload;
        },
        setIsListPage(state, action) {
            state.isListPage = action.payload;
        },
        setIsEdit(state, action) {
            state.isEdit = action.payload;
        },
        setValidationFail(state, action) {
            state.validationFail = action.payload;
        },
        setLocationId(state, action) {
            state.locationId = action.payload;
        },
        setResetStore() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListClientLocations.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.listClientLocations = initialState.listClientLocations;
                message.error("Failed to fetch list of client locations");
            }
            else {
                state.listClientLocations = action.payload.data;
            }
        })
            .addCase(getListClientLocations.rejected, () => {
            message.error("Failed to fetch list of client locations");
        })
            .addCase(getListArchivedClientLocations.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch list of archived client locations");
            }
            else {
                state.listArchivedClientLocations = action.payload.data;
            }
        })
            .addCase(getListArchivedClientLocations.rejected, () => {
            message.error("Failed to fetch list of archived client locations");
        })
            .addCase(archiveClientLocation.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error(action.payload.message);
            }
            else {
                message.success("Location archived successfully");
            }
        })
            .addCase(archiveClientLocation.rejected, () => {
            message.error("Failed to archive the location");
        })
            .addCase(restoreClientLocation.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to restore the location");
            }
            else {
                message.success("Location restored successfully");
            }
        })
            .addCase(restoreClientLocation.rejected, () => {
            message.error("Failed to restore the location");
        })
            .addCase(getLocationDetail.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.locationDetails = initialState.locationDetails;
                state.contactDetails = initialState.contactDetails;
                message.error("Failed to fetch location details");
            }
            else {
                state.locationDetails = action.payload.data.locationData;
                state.contactDetails = action.payload.data.contactsData;
            }
        })
            .addCase(getLocationDetail.rejected, () => {
            message.error("Failed to fetch location details");
        })
            .addCase(deleteClientLocationContact.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error(action.payload.message);
            }
            else {
                message.success("Contact deleted successfully");
            }
        })
            .addCase(deleteClientLocationContact.rejected, () => {
            message.error("Failed to delete the contact");
        })
            .addCase(createClientLocationDetails.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to create location");
            }
            else {
                message.success("Location created successfully");
            }
        })
            .addCase(createClientLocationDetails.rejected, () => {
            message.error("Failed to create location");
        })
            .addCase(updateClientLocationDetails.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error(action.payload.message);
            }
            else {
                message.success("Location updated successfully");
            }
        })
            .addCase(updateClientLocationDetails.rejected, () => {
            message.error("Failed to update location");
        });
    },
});
export const { setContacts, setIsEdit, setIsListPage, setIsAddNew, setValidationFail, setLocationId, setDefaultContacts, setResetStore, } = ClientLocationsSlice.actions;
export default ClientLocationsSlice.reducer;
