import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import moment from "moment";
import { getListOnsiteApprovals } from "./action";
export const initialState = {
    onsiteApprovals: [],
    selectedToday: true,
    selectedDate: moment(),
};
const onsiteApprovalsSlice = createSlice({
    name: "onsiteApprovals",
    initialState,
    reducers: {
        setSelectedToday(state, action) {
            state.selectedToday = action.payload;
        },
        setSelectedDate(state, action) {
            state.selectedDate = action.payload;
        },
        setResetStore() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListOnsiteApprovals.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.onsiteApprovals = initialState.onsiteApprovals;
                message.error("Failed to fetch list of onsite approval records");
            }
            else {
                state.onsiteApprovals = action.payload.data;
            }
        })
            .addCase(getListOnsiteApprovals.rejected, () => {
            message.error("Failed to fetch list of onsite approval records");
        });
    },
});
export const { setSelectedToday, setResetStore, setSelectedDate } = onsiteApprovalsSlice.actions;
export default onsiteApprovalsSlice.reducer;
