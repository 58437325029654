import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Image, Loading, SAButton, SelectButton, Table, Text } from "src/components/base";
import { useSettings } from "src/providers/SettingsContextProvider";
import { getDate } from "src/utils/helper";
import { Test_Type, Test_Type_Label } from "./types";
const { Option } = Select;
export const BodyRecords = (props) => {
    const { displayColumns, searchString, patientTestListData, startDate, endDate, isLoading } = props;
    const [lastSelectField, setLastSelectField] = useState("");
    const [orderField, setOrderField] = useState("");
    const [isDescending, setIsDescending] = useState(false);
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const { settings } = useSettings();
    const [rowIndex, setRowIndex] = useState();
    const navigate = useNavigate();
    const itemRender = (current, type, originalElement) => {
        if (type === "prev") {
            return (_jsx("div", { children: _jsx(Image, { src: "/assets/images/double-arrow-back-box-gray-icon.png", preview: false, height: 10, width: 10, className: "prev-icon" }) }));
        }
        if (type === "next") {
            return (_jsx("div", { children: _jsx(Image, { src: "/assets/images/double-arrow-forth-box-gray-icon.png", preview: false, height: 10, width: 10, className: "next-icon" }) }));
        }
        return originalElement;
    };
    const [pagination, setPagination] = useState({
        defaultPageSize: 10,
        pageSize: 10,
        total: data.length,
        itemRender: itemRender,
        pageSizeOptions: [10, 15, 20],
    });
    const [pageSize, setPageSize] = useState(10);
    const select = (curPageSize) => {
        setPagination({ ...pagination, pageSize: curPageSize });
        setPageSize(curPageSize);
    };
    const onSelectRow = (record, index) => {
        setRowIndex(index);
    };
    const sorting = (field) => {
        let fieldName = field;
        if (field == "sex") {
            fieldName = "gender";
        }
        setLastSelectField(fieldName);
        if (fieldName !== lastSelectField) {
            setIsDescending(false);
        }
        else {
            setIsDescending(!isDescending);
        }
        setOrderField(fieldName);
    };
    const columns = [
        {
            title: "No.",
            dataIndex: "key",
            key: "key",
            width: 75,
            className: "number-column",
            render: (_, __, index) => _jsx("span", { children: index + 1 }),
        },
        {
            title: () => (_jsx(SelectButton, { onClick: () => sorting("date"), name: "Date", orderField: orderField, fieldName: "date", isDescending: isDescending })),
            dataIndex: "date",
            key: "date",
            className: displayColumns.includes("date") ? "date-column" : "date-column-none",
        },
        {
            title: () => (_jsx(SelectButton, { onClick: () => sorting("time"), name: "Time", orderField: orderField, fieldName: "time", isDescending: isDescending })),
            dataIndex: "time",
            key: "time",
            className: displayColumns.includes("time") ? "time-column" : "time-column-none",
        },
        {
            title: () => (_jsx(SelectButton, { onClick: () => sorting("test"), name: "Test", orderField: orderField, fieldName: "test", isDescending: isDescending })),
            dataIndex: "testName",
            key: "testName",
            className: displayColumns.includes("test") ? "test-column" : "test-column-none",
            render: (testName) => _jsx(StyledSAButton, { name: testName }),
        },
        {
            title: () => (_jsx(SelectButton, { onClick: () => sorting("refNo"), name: "Reference No", orderField: orderField, fieldName: "refNo", isDescending: isDescending })),
            dataIndex: "refNo",
            key: "refNo",
            className: displayColumns.includes("refNo") ? "ref-no-column" : "ref-no-column-none",
        },
        {
            title: () => (_jsx(SelectButton, { name: "Requesting Authority", orderField: orderField, fieldName: "requestingAuthorityName", isDescending: isDescending })),
            dataIndex: "requestingAuthorityName",
            key: "requestingAuthorityName",
            className: displayColumns.includes("reqAuth") ? "ref-no-column" : "ref-no-column-none",
        },
        {
            title: () => (_jsx(SelectButton, { name: "Service Office", orderField: orderField, fieldName: "serviceOffice", isDescending: isDescending })),
            dataIndex: "serviceOffice",
            key: "serviceOffice",
            className: displayColumns.includes("serviceOffice")
                ? "collection-point-column"
                : "collection-point-column-none",
        },
        {
            title: () => (_jsx(SelectButton, { name: "Collector", orderField: orderField, fieldName: "collector", isDescending: isDescending })),
            dataIndex: "collector",
            key: "collector",
            className: displayColumns.includes("collector")
                ? "collector-column"
                : "collector-column-none",
        },
        {
            title: "Record",
            dataIndex: "record",
            key: "record",
            className: "record-column",
            render: (_, record) => (_jsx(ViewRecordButton, { onClick: () => {
                    navigate("/patient-records/on-site/donor-test-record/test-details-summary", {
                        state: record.appointmentId,
                    });
                }, children: _jsx(Image, { src: "/assets/images/view-record-icon.png", preview: false, width: 25.44, height: 29 }) })),
        },
    ];
    useEffect(() => {
        if (patientTestListData && patientTestListData.length) {
            getData(patientTestListData);
        }
        else {
            setData([]);
            setOriginalData([]);
        }
    }, [patientTestListData, startDate, endDate]);
    function getTimezone(serviceOfficeId) {
        const collectionPoint = settings.collectionPointSetting.find((cp) => cp.collectionPointId === serviceOfficeId);
        return collectionPoint?.timezone;
    }
    function getTestDescription(allMedicalSampleInfo) {
        // Extract all test types
        const testTypes = allMedicalSampleInfo.map((sample) => sample.testType);
        // Check if both test types 1 and 2 are present
        const hasTestType1 = testTypes.includes(Test_Type.DRUG_TEST);
        const hasTestType2 = testTypes.includes(Test_Type.ALCOHOL_TEST);
        // Determine the test description based on the test types
        if (hasTestType1 && hasTestType2) {
            return Test_Type_Label.DRUG_AND_ALCOHOL_TEST;
        }
        else if (hasTestType1) {
            return Test_Type_Label.DRUG_TEST;
        }
        else if (hasTestType2) {
            return Test_Type_Label.ALCOHOL_TEST;
        }
        else {
            return "Not Found";
        }
    }
    const getData = async (apmtList) => {
        const tempList = [];
        for (const element of apmtList) {
            const index = apmtList.indexOf(element);
            const dateObj = getDate(element.appointmentStartAt);
            // Retrieve timezone for the collection point
            const timezone = getTimezone(element.collectionPointId);
            // Format date and time based on timezone
            const date = moment
                .tz(dateObj, timezone ? timezone : "")
                .format(settings.organizationSetting.dateFormat);
            const time = moment
                .tz(dateObj, timezone ? timezone : "")
                .format(settings.organizationSetting.timeFormat);
            const obj = {
                key: index,
                number: (index + 1).toString(),
                dateObj: dateObj,
                date: date,
                time: time,
                testType: element.testType,
                testName: getTestDescription(element.allMedicalSampleInfo),
                requestingAuthorityName: element.requestingAuthorityName,
                serviceOffice: element.serviceOffice,
                serviceOfficeId: element.serviceOfficeId,
                refNo: element.appointmentReference,
                collector: element.collector,
                appointmentId: element.onsiteAppointmentId,
            };
            tempList.push(obj);
        }
        setOriginalData(tempList);
        const startD = startDate === null ? new Date("01/01/1970") : startDate.startOf("day").toDate();
        const endD = endDate === null ? new Date("12/31/2999") : endDate.endOf("day").toDate();
        const filteredAppointments = tempList.filter((item) => {
            const itemDate = new Date(item.dateObj);
            return itemDate >= startD && itemDate <= endD;
        });
        setData(filteredAppointments);
    };
    useEffect(() => {
        if (Object.keys(data).length !== 0 && Object.keys(originalData).length !== 0) {
            const newSortData = data.sort((a, b) => {
                const firstField = a?.[orderField]?.toLowerCase() || "";
                const secondField = b?.[orderField]?.toLowerCase() || "";
                if (isDescending) {
                    return firstField > secondField ? -1 : 1;
                }
                else {
                    return firstField < secondField ? -1 : 1;
                }
            });
            setData(newSortData.map((obj, index) => ({
                ...obj,
                number: (index + 1),
                key: obj.key,
            })));
        }
    }, [orderField, isDescending]);
    useEffect(() => {
        if (Object.keys(originalData).length !== 0) {
            const newFilterData = originalData.filter((obj) => obj?.test?.toUpperCase().includes(searchString.toUpperCase()) ||
                obj?.refNo?.toUpperCase().includes(searchString.toUpperCase()) ||
                obj?.collectionPoint?.toUpperCase().includes(searchString.toUpperCase()) ||
                obj?.collector?.toUpperCase().includes(searchString.toUpperCase()));
            setData(newFilterData.map((obj, index) => ({
                ...obj,
                number: (index + 1),
                key: obj.key,
            })));
        }
    }, [searchString]);
    return (_jsxs(PageWrapper, { children: [_jsx(Table, { columns: columns, dataSource: data, size: "middle", pagination: pagination, onRow: (record, rowIdx) => ({
                    onClick: () => onSelectRow(record, rowIdx),
                }), rowClassName: (_, index) => (rowIndex === index ? "select" : "") }), isLoading && (_jsxs("div", { style: { position: "absolute", top: 100, right: "45%" }, children: [_jsx(Loading, { iconProps: { style: { fontSize: 48, color: "Blue" } } }), _jsx(Text, { name: " Fetching details..." })] })), data.length > 0 && (_jsxs(Row, { style: { position: "absolute", marginTop: "-50px", marginLeft: "30px" }, children: [_jsx(StyledText, { name: "Show" }), _jsxs(Select, { className: "styled-select", value: pageSize, onSelect: select, suffixIcon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false }), children: [_jsx(Option, { value: 10, children: "10" }), _jsx(Option, { value: 15, children: "15" }), _jsx(Option, { value: 20, children: "20" })] })] }))] }));
};
const PageWrapper = styled.div `
  border-top: 1px solid #ffffffb3;
  overflow: auto;
  position: relative;

  .ant-table table {
    width: 100% !important;
  }

  th.ant-table-cell {
    background: #32a1a1;
    color: white;
    font-size: 16px;
    font-weight: normal;

    .ant-btn {
      background: #32a1a1 !important;
    }
  }
  th.ant-table-cell::before {
    background-color: #01b4d2 !important;
  }
  .ant-table-container {
    width: 100% !important;
    overflow: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 15px solid #32a1a1;
  }
  .date-column-none {
    display: none;
  }
  .time-column-none {
    display: none;
  }
  .test-column-none {
    display: none;
  }
  .ref-no-column-none {
    display: none;
  }
  .collection-point-column-none {
    display: none;
  }
  .collector-column-none {
    display: none;
  }
  th.ant-table-cell.number-column {
    text-align: center;
  }
  td.ant-table-cell {
    padding: 9px 8px !important;
    font-size: 16px;
    font-weight: normal;
    color: #1a576f;
  }
  td.ant-table-cell.number-column {
    background: #c9eef8;
    text-align: center;
  }
  td.ant-table-cell-row-hover {
    background: #01b4d2 !important;
    color: white;
  }
  td.ant-table-cell.number-column.ant-table-cell-row-hover {
    background: rgba(1, 180, 210, 0.6) !important;
    color: #1a576f;
  }
  tr.ant-table-row.ant-table-row-level-0 {
    height: 50px;
  }
  tr.ant-table-row.ant-table-row-level-0:nth-child(even) {
    background: #f7fcfd;
  }
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  td.ant-table-cell.request-column {
    padding-left: 20px !important;
  }
  .ant-table-pagination.ant-pagination {
    margin-right: 30px;
  }
  .ant-pagination.mini .ant-pagination-prev {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }
  .ant-pagination.mini .ant-pagination-next {
    border: 1px solid #ebedf0;
    margin-left: 14px !important;
    height: 28px;
    width: 28px;
  }
  li.ant-pagination-item {
    border: 1px solid #ebedf0 !important;
    margin-left: 14px !important;
    width: 28px !important;
    height: 28px !important;
  }
  li.ant-pagination-item a {
    color: #367893;
    font-size: 16px;
    padding-top: 3px;
  }
  li.ant-pagination-item.ant-pagination-item-active {
    background: #adb3bc;
  }
  li.ant-pagination-item.ant-pagination-item-active a {
    color: white;
  }
  td.ant-table-cell.view-column {
    text-align: center;
  }
  .ant-table-cell.ant-table-column-has-sorters:hover {
    background: #0047b3;
  }
  span.ant-table-column-sorter.ant-table-column-sorter-full {
    display: none;
  }
  tr.ant-table-row.ant-table-row-level-0.select > td.ant-table-cell {
    background: #1a8cff !important;
    color: white;
    font-weight: bold;
  }
  tr.ant-table-row.ant-table-row-level-0.select > td.ant-table-cell-row-hover {
    background: #1a8cff !important;
    color: white;
  }

  .styled-select {
    border: 1px solid #ebedf0;
    border-radius: 2px;
    width: 70px;
    height: 28px;
    font-size: 16px;
    color: #367893 !important;
  }

  .ant-select-arrow .ant-image-img {
    margin-top: 3px;
    width: 14px !important;
  }
`;
const StyledSAButton = styled(SAButton) `
  height: 29px;
  width: 100%;
  font-size: 16px;
  color: #000000 !important;
  background: #ffffff;
  border: 1px solid #adb3bc;
`;
const ViewRecordButton = styled(Button) `
  padding: 0px;
  border: none;
  background: none;

  &:hover {
    background: none !important;
  }
`;
const StyledText = styled(Text) `
  font-size: 16px;
  color: #367893 !important;
  margin-right: 7px;
  margin-top: 4px;
`;
