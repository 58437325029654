import { $get, $post, $put } from "src/infra/http";
export const createAppointment = async (requestBody) => {
    const response = await $post(`/collection-manager/appointment/create`, requestBody);
    return response.data;
};
export const cancelAppointmentService = async (appointmentId) => {
    const response = await $get(`/collection-manager/appointment/cancel/${appointmentId}`);
    return response?.data;
};
export const restoreAppointmentService = async (appointmentId) => {
    const response = await $get(`/collection-manager/appointment/restore/${appointmentId}`);
    return response?.data;
};
export const resendEmailAppointmentService = async (appointmentId) => {
    const response = await $get(`/collection-manager/appointment/resend-email/${appointmentId}`);
    return response?.data;
};
export const updateAppointmentService = async (appointmentId, bodyUpdate) => {
    const response = await $put(`/collection-manager/appointment/update/${appointmentId}`, bodyUpdate);
    return response?.data;
};
export const getAppointmentInfoById = async (appointmentId) => {
    const response = await $get(`/collection-manager/appointment/${appointmentId}`);
    return response.data.data;
};
