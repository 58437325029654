import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import moment from "moment";
import { Spin } from "src/components/base";
import { LeftRowData, MiddleRowData, RightRowData, RowTotal, } from "src/pages/DashboardPage/components/Common";
import HeaderTable from "src/pages/DashboardPage/components/HeaderTable";
import LeftData from "src/pages/DashboardPage/components/LeftData";
import MiddleData from "src/pages/DashboardPage/components/MiddleData";
import RightData from "src/pages/DashboardPage/components/RightData";
import { TestsProcessedWrapper } from "src/pages/DashboardPage/components/TestsProcessed/index.style";
import { getHairResultsLeftRowData, getHairResultsMiddleRowData, getHairResultsRightRowData, } from "src/api/dashboard/dashboardService";
export const HairDrugTestResults = ({ collectionPoints }) => {
    const [location, setLocation] = useState("All");
    const [selectYear, setSelectYear] = useState(`${moment().year()}`);
    const [startIndex, setStartIndex] = useState(0);
    const [isMonthView, setIsMonthView] = useState(true);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    // Left Data
    const [leftData, setLeftData] = useState({});
    // Middle Data
    const [middleData, setMiddleData] = useState({});
    // Right Data
    const [rightData, setRightData] = useState({});
    const getLeftRowData = async () => {
        const startDate = moment().format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        const result = await getHairResultsLeftRowData(location, startDate, endDate);
        if (result.status === "success") {
            setLeftData(result?.data);
        }
    };
    const getMiddleRowData = async () => {
        const result = await getHairResultsMiddleRowData(location, isMonthView, selectYear);
        if (result.status === "success") {
            setMiddleData(result?.data);
        }
    };
    const getRightRowData = async () => {
        const startDate = moment(fromDate).format("YYYY-MM-DD");
        const endDate = moment(toDate).format("YYYY-MM-DD");
        const result = await getHairResultsRightRowData(location, startDate, endDate);
        if (result.status === "success") {
            setRightData(result?.data);
        }
    };
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            Promise.all([getLeftRowData(), getMiddleRowData(), getRightRowData()]).then(() => setIsLoading(false));
        }
    }, [isVisible, location]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getRightRowData().then(() => setIsLoading(false));
        }
    }, [fromDate, toDate]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getMiddleRowData().then(() => setIsLoading(false));
        }
    }, [isMonthView, selectYear]);
    return (_jsx(TestsProcessedWrapper, { children: _jsxs(HeaderTable, { title: "Hair Drug Test (Laboratory Test)", collectionPoints: collectionPoints, onChangeLocation: setLocation, isVisible: isVisible, setIsVisible: setIsVisible, icon: "ic-test-result.svg", children: [_jsx("div", { style: { position: "relative" }, children: isLoading && (_jsx(Spin, { style: {
                            position: "absolute",
                            top: "50%",
                            left: "40vw",
                        } })) }), _jsxs("div", { style: { opacity: isLoading ? 0.3 : 1, display: "flex" }, children: [_jsxs(LeftData, { title: "Tests", children: [_jsx(LeftRowData, { title: "Sent to Lab (RFT)", data: leftData["sentToLab"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "Refusal", data: leftData["refusal"] || [0, 0, 0, 0] }), _jsx(RowTotal, { style: { marginTop: 7 }, title: "Total", data: Array.from({ length: (leftData.sentToLab || []).length }, (_, index) => (leftData.sentToLab ? leftData.sentToLab[index] : 0) +
                                        (leftData.refusal ? leftData.refusal[index] : 0)) })] }), _jsxs(MiddleData, { onSelectYear: setSelectYear, onMonthView: setIsMonthView, startIndex: setStartIndex, children: [Object.entries(middleData)?.map(([testName, monthlyCounts]) => (_jsx(MiddleRowData, { startIndex: startIndex, data: Object.values(monthlyCounts) }, testName))), _jsx(MiddleRowData, { startIndex: startIndex, cellStyle: { background: "#FAFAFA" }, style: { marginTop: 7 }, data: Array.from({ length: 12 }, (_, monthIndex) => Object.values(middleData)?.reduce((acc, monthlyCounts) => acc + monthlyCounts[Object.keys(monthlyCounts)[monthIndex]], 0)) })] }), _jsxs(RightData, { onFromDate: setFromDate, onToDate: setToDate, children: [_jsx(RightRowData, { value: rightData["sentToLab"] || 0 }), _jsx(RightRowData, { value: rightData["refusal"] || 0 }), _jsx(RightRowData, { cellStyle: { background: "#FAFAFA" }, style: { marginTop: 7 }, value: Object.values(rightData)?.reduce((acc, value) => acc + (value || 0), 0) })] })] })] }) }));
};
