import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image } from "antd";
import React from "react";
import styled from "styled-components";
import { Text } from "src/components/base";
import { SAColourScheme } from "src/styles";
export const DonorReportNotes = ({ donorResultReportNote }) => (_jsxs(PageWrapper, { children: [_jsx(Image, { src: "/assets/svg/ic-donor-report.svg" }), _jsx(Text, { name: "Donor Report Notes", className: "header" }), _jsx(NotesText, { children: donorResultReportNote })] }));
const PageWrapper = styled.div `
  margin-top: 1rem;
  background: #f7f8f8;
  padding: 1rem;
  border-radius: 5px;

  .header {
    margin-left: 1rem;
    color: #00004c !important;
  }
`;
const NotesText = styled.div `
  background: ${SAColourScheme.WHITE};
  padding: 1rem;
  margin-top: 1rem;
  border: 1px solid #adb3bc;
  border-radius: 5px;
  min-height: 3.5rem;
`;
