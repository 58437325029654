import styled from "styled-components";
export const PopupBoxWrapper = styled.div `
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding-top: 5vh;
  z-index: 50;

  .popup-box {
    width: 70%;
    display: flex;
    position: absolute;
    max-height: 90%;
    // cursor: move;
    flex-direction: column;
    align-items: stretch;
    z-index: 99;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    overflow-y: auto !important;
  }

  .close-popup-box {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;

    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: #00000021;
    font-size: 12px;
  }
`;
