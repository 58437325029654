const SAMPLE_TYPE = {
    URINE_DRUG_TEST: 1,
    BREATH_ALCOHOL_TEST: 2,
    ORAL_FLUID_DRUG_TEST: 3,
    RAPID_ORAL_FLUID_DRUG_SCREEN: 4,
    HAIR_DRUG_TEST: 5,
    RAPID_URINE_DRUG_SCREEN: 6,
};
const SAMPLE_TYPE_DISPLAY_ORDER = {
    [SAMPLE_TYPE.URINE_DRUG_TEST]: 3,
    [SAMPLE_TYPE.BREATH_ALCOHOL_TEST]: 6,
    [SAMPLE_TYPE.ORAL_FLUID_DRUG_TEST]: 4,
    [SAMPLE_TYPE.RAPID_ORAL_FLUID_DRUG_SCREEN]: 2,
    [SAMPLE_TYPE.HAIR_DRUG_TEST]: 5,
    [SAMPLE_TYPE.RAPID_URINE_DRUG_SCREEN]: 1,
};
var AppointmentMainTypeName;
(function (AppointmentMainTypeName) {
    AppointmentMainTypeName["AlcoholOnly"] = "Alcohol Only";
    AppointmentMainTypeName["DrugOnly"] = "Drug Only";
    AppointmentMainTypeName["AlcoholAndDrug"] = "Alcohol & Drug";
})(AppointmentMainTypeName || (AppointmentMainTypeName = {}));
export { SAMPLE_TYPE, SAMPLE_TYPE_DISPLAY_ORDER, AppointmentMainTypeName };
