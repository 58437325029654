import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from "react";
import { SAColourScheme } from "src/constants";
import ContentContext from "src/providers/content";
import Loading from "../Loading";
import { LoadingScreenWrapper } from "./index.style";
export const LoadingScreen = () => {
    const { loadingScreen } = useContext(ContentContext);
    return (_jsx(_Fragment, { children: loadingScreen.visible && (_jsx(LoadingScreenWrapper, { children: _jsx(Loading, { iconProps: {
                    style: { fontSize: 40, color: SAColourScheme.WHITE },
                } }) })) }));
};
