/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { deleteCmUser, getAllUserRoles, getListUsers, inviteCmUser, reInviteCmUser, restoreCmUser, suspendCmUser, updateUserRolesOrTitle, } from "./action";
export const initialState = {
    roles: [],
    listUsers: [],
};
export const cmUserPageSlice = createSlice({
    name: "cmUserPageSlice",
    initialState,
    reducers: {
        setRoles(state, action) {
            state.roles = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUserRoles.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.roles = initialState.roles;
            }
            else {
                state.roles = action.payload.data;
            }
        })
            .addCase(getListUsers.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.roles = initialState.roles;
            }
            else {
                state.listUsers = action.payload.data;
            }
        })
            .addCase(inviteCmUser.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Invite user failed!");
            }
            else {
                message.success("Invite user success!");
            }
        })
            .addCase(inviteCmUser.rejected, () => {
            message.error("Invite user failed!");
        })
            .addCase(reInviteCmUser.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Invite user failed!");
            }
            else {
                message.success("Invite user success!");
            }
        })
            .addCase(reInviteCmUser.rejected, () => {
            message.error("Invite user failed!");
        })
            .addCase(suspendCmUser.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Suspend user failed!");
            }
            else {
                message.success("Suspend user success!");
            }
        })
            .addCase(suspendCmUser.rejected, () => {
            message.error("Suspend user failed!");
        })
            .addCase(deleteCmUser.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Delete user failed!");
            }
            else {
                message.success("Delete user success!");
            }
        })
            .addCase(deleteCmUser.rejected, () => {
            message.error("Delete user failed!");
        })
            .addCase(restoreCmUser.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Restore user failed!");
            }
            else {
                message.success("Restore user success!");
            }
        })
            .addCase(restoreCmUser.rejected, () => {
            message.error("Restore user failed!");
        })
            .addCase(updateUserRolesOrTitle.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Update user failed!");
            }
            else {
                message.success("Update user success!");
            }
        })
            .addCase(updateUserRolesOrTitle.rejected, () => {
            message.error("Update user failed!");
        });
    },
});
export const { setRoles } = cmUserPageSlice.actions;
export default cmUserPageSlice.reducer;
