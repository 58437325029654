import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from "@mui/material";
import { Col, Form, Image, Radio, Row, Switch, TimePicker } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "src/components/base";
import { SAColourScheme } from "src/constants";
export const Reporting = ({ clientName }) => {
    const [form] = Form.useForm();
    const [radioGroupDisabled, setRadioGroupDisabled] = useState(true);
    const [radioGroupSelected, setRadioGroupSelected] = useState(false);
    const [timerDisabled, setTimerDisabled] = useState(true);
    const handleSwitchChange = (checked) => {
        setRadioGroupDisabled(!checked);
        setRadioGroupSelected(false);
        setTimerDisabled(!checked);
        form.setFieldsValue({
            isBatchReportSelected: undefined,
            timeRunBatch: undefined,
        });
        form.setFields([
            {
                name: "timeRunBatch",
                errors: [],
            },
            {
                name: "isBatchReportSelected",
                errors: [],
            },
        ]);
    };
    return (_jsx(FormWrapper, { children: _jsx(Form, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 54 }, children: _jsxs(NewJobWrapper, { children: [_jsx(Row, { gutter: [50, 10], children: _jsxs(Col, { span: 24, children: [_jsx(Image, { src: "/assets/svg/ic-cm-collection-organisation.svg", height: 30, width: 30, preview: false }), _jsx("span", { className: "header-text", children: clientName })] }) }), _jsx(Divider, { color: SAColourScheme.PLASMA, className: "divider" }), _jsxs(BackgroundWrapper, { children: [_jsxs(Row, { justify: "space-between", align: "middle", className: "row-background", children: [_jsxs(Col, { span: 24, children: [_jsx(Image, { src: "/assets/svg/ic-reporting-on-site.svg", height: 30, width: 30, preview: false }), _jsx("span", { className: "header-text", children: "In-Clinic Batch Results Reporting" })] }), _jsx(Col, { span: 24, children: _jsxs(Row, { className: "row-text", children: [_jsx(Col, { span: 2, children: _jsx(Form.Item, { label: "", name: "isBatchReport", valuePropName: "checked", children: _jsx(Switch, { style: {
                                                                backgroundColor: radioGroupDisabled ? "#C5C7D1" : "#1A8CFF",
                                                            }, onChange: handleSwitchChange, disabled: true }) }) }), _jsxs(Col, { span: 22, className: "in-clinic-text", children: [_jsx(Text, { name: "This setting enables/disables the Batch Results Report functionality for In-Clinic Collection only." }), _jsx(Text, { name: "You will find the Batch Results Report in the Collection Report menu for both In-Clinic and On-Site Collection." })] })] }) })] }), _jsx(Form.Item, { name: "isBatchReportSelected", rules: [
                                    {
                                        required: form.getFieldValue("isBatchReport"),
                                        message: (_jsx("div", { children: "To use the Batch Results Report Function, please select one of the provided options." })),
                                    },
                                ], children: _jsxs(Radio.Group, { disabled: radioGroupDisabled, className: "radio-group", children: [_jsxs(RadioWrapper, { children: [_jsx(Radio, { value: "manual", name: "manual", onClick: () => {
                                                        setRadioGroupSelected(false);
                                                        setTimerDisabled(true);
                                                        form.setFieldsValue({
                                                            isSendAfterEndTest: true,
                                                            timeRunBatch: undefined,
                                                        });
                                                        form.setFields([
                                                            {
                                                                name: "timeRunBatch",
                                                                errors: [],
                                                            },
                                                        ]);
                                                    }, children: "Send by Collector at the end of job" }), _jsx(Text, { className: "radio-text", name: "This option will allow collector to send the Batch Results Report at the end of work." })] }), _jsxs(RadioWrapper, { children: [_jsxs("div", { style: { display: "flex", alignItems: "center" }, children: [_jsx(Radio, { style: { marginTop: "5px" }, value: "auto", name: "auto", onClick: () => {
                                                                setRadioGroupSelected(true);
                                                                setTimerDisabled(false);
                                                            }, children: "Set Time for Auto-send" }), _jsx(Form.Item, { name: "timeRunBatch", rules: [
                                                                {
                                                                    required: radioGroupSelected === true,
                                                                    message: "Please select time for auto-send",
                                                                    type: "object",
                                                                },
                                                            ], children: _jsx(TimePicker, { format: "HH:mm", showNow: false, placeholder: "", disabled: timerDisabled, minuteStep: 30 }) })] }), _jsx(Text, { className: "radio-text", name: "This option will send the Batch Results Report automatically at the set time." })] })] }) })] }), _jsx(BackgroundWrapper, { children: _jsx(Row, { justify: "space-between", align: "middle", className: "row-background", children: _jsxs(Col, { span: 24, children: [_jsx(Image, { src: "/assets/svg/ic-reporting-on-site.svg", height: 30, width: 30, preview: false }), _jsx("span", { className: "header-text", children: "On-Site Batch Results Reporting" }), _jsx(Row, { className: "row-text", children: "On-Site collection batch results reporting will be handled by a collector at the end of the job." })] }) }) })] }) }) }));
};
export const FormWrapper = styled.div `
  .ant-radio-disabled + span {
    color: ${SAColourScheme.BLACK} !important;
  }
`;
export const RepresentativeWrapper = styled.div ``;
export const BackgroundWrapper = styled.div `
  background: #f7f8f8;
`;
export const RadioWrapper = styled.div `
  background: #ffffff;
  padding: 20px;
  margin: 0px 25px 20px 25px;
`;
export const NewJobWrapper = styled.div `
  .header-text {
    font-size: 14px;
    font-weight: 700;
    margin-left: 10px;
  }

  .divider {
    border-bottom-width: 2px;
    margin: 10px 0px;
  }

  .row-background {
    border-radius: 4px;
    padding: 20px;
    margin: 20px 0px;
  }

  .buttons {
    margin-top: 50px;
  }

  .cancel-button {
    background: ${SAColourScheme.WHITE};
    color: #fd0000;
    border: 1px solid #adb3bc;
    border-radius: 5px;
    width: 100%;
  }

  .ant-btn.save-button {
    border-radius: 5px;
    width: 100%;
    margin-left: 10px;
    color: ${SAColourScheme.WHITE};
  }

  .ant-btn.save-button.enabled {
    border: 1px solid #1a8cff;
    background: #1a8cff;
  }

  .ant-btn.save-button.disabled {
    border: 1px solid white;
    background: #c5c7d1;
  }

  .row-text {
    margin: 20px 0 0 40px;
  }

  .ant-typography {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .in-clinic-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .radio-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .radio-text {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
  }
  .ant-form label {
    font-size: 16px;
  }

  .ant-form-item {
    margin-bottom: 0px !important;
  }
`;
