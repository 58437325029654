import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import { useEffect, useState } from "react";
import { getTransportHistoryData } from "src/api/dashboard/dashboardService";
import { Button, Col, Row } from "src/components/base";
import { HeaderWrapper } from "./index.style";
import { SatchelsTable } from "./SatchelTable";
export const SatchelHistory = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [satchelData, setSatchelData] = useState({});
    const getTransportSatchelHistoryData = async () => {
        setIsLoading(true);
        const result = await getTransportHistoryData();
        if (result.status === "success") {
            setSatchelData(result?.data);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getTransportSatchelHistoryData();
    }, []);
    return (_jsxs("div", { children: [_jsx(HeaderWrapper, { children: _jsx("div", { style: {
                        background: "#2e94f2",
                        marginTop: "30px",
                        borderRadius: "5px",
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                    }, children: _jsxs(Row, { align: "middle", style: { padding: "0 13px", height: "auto", minHeight: 48 }, children: [_jsxs(Col, { span: 11, children: [_jsx("img", { src: "/assets/svg/ic-dash-satchel.svg", alt: "", style: { marginRight: 8 } }), _jsx(Text, { style: { fontSize: 16, fontWeight: 500, color: "#FFFFFF" }, children: "Satchel History" })] }), _jsx(Col, { span: 11, children: _jsx(Text, { style: {
                                        overflow: "hidden",
                                        color: "#FFFFFF",
                                        display: "flex",
                                        justifyContent: "center",
                                    }, children: "dddd DD MMMM YYYY HH:mm" }) }), _jsx(Col, { span: 2, children: _jsxs(Row, { justify: "space-between", align: "middle", children: [_jsx(Button, { className: "icon-btn", size: "small", 
                                            //Removed Source as part of the removing Print movement '/assets/svg/ic-print4.svg'
                                            icon: _jsx("img", { src: "" }) }), _jsx(Button, { className: "icon-btn", size: "small", icon: _jsx("img", { src: "/assets/svg/ic-pin.svg" }) })] }) })] }) }) }), _jsx("div", { style: { padding: "15px" }, children: _jsx(Text, { style: { fontSize: "16px" }, children: "Organisation\u2019s Transport Satchels" }) }), _jsx("div", { style: { borderTop: "1px solid #ADB3BC" } }), _jsx("div", { style: { position: "relative" }, children: isLoading && (_jsx(Spin, { style: {
                        position: "absolute",
                        top: "90px",
                        left: "40vw",
                    } })) }), _jsx("div", { style: { opacity: isLoading ? 0.3 : 1 }, children: _jsxs("div", { style: { marginTop: "20px", padding: "20px", background: "white" }, children: [_jsxs(Row, { gutter: [10, 10], children: [_jsxs(Col, { span: 6, style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "0.5px solid #00B4D1",
                                    }, children: [_jsx(Text, { style: { fontSize: "14px", color: "#7F8E9D" }, children: "In Service" }), _jsx(Text, { style: { fontSize: "16px", fontWeight: "700" }, children: satchelData?.inServiceCount ? satchelData?.inServiceCount : "-" })] }), _jsxs(Col, { span: 6, style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "0.5px solid #00B4D1",
                                    }, children: [_jsx(Text, { style: {
                                                fontSize: "14px",
                                                color: "#7F8E9D",
                                                marginLeft: "5px",
                                            }, children: "Out of Service" }), _jsx(Text, { style: {
                                                fontSize: "16px",
                                                fontWeight: "700",
                                                marginLeft: "5px",
                                            }, children: satchelData?.outOfServiceCount ? satchelData?.outOfServiceCount : "-" })] }), _jsxs(Col, { span: 6, style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "0.5px solid #00B4D1",
                                    }, children: [_jsx(Text, { style: {
                                                fontSize: "14px",
                                                color: "#7F8E9D",
                                                marginLeft: "5px",
                                            }, children: "In Stock" }), _jsx(Text, { style: {
                                                fontSize: "16px",
                                                fontWeight: "700",
                                                marginLeft: "5px",
                                            }, children: satchelData?.inStockCount ? satchelData?.inStockCount : "-" })] }), _jsxs(Col, { span: 6, style: { display: "flex", flexDirection: "column" }, children: [_jsx(Text, { style: {
                                                fontSize: "14px",
                                                color: "#7F8E9D",
                                                marginLeft: "5px",
                                            }, children: "Total" }), _jsx(Text, { style: {
                                                fontSize: "16px",
                                                fontWeight: "700",
                                                marginLeft: "5px",
                                            }, children: satchelData?.totalCount ? satchelData?.totalCount : "-" })] })] }), _jsx("div", { style: { borderBottom: "0.5px solid #00B4D1", margin: "10px 0px" } }), _jsxs(Row, { gutter: [10, 10], children: [_jsxs(Col, { span: 6, style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "0.5px solid #00B4D1",
                                    }, children: [_jsx(Text, { style: { fontSize: "14px", color: "#7F8E9D" }, children: "Newest" }), _jsx(Text, { style: { fontSize: "16px", fontWeight: "700" }, children: satchelData?.newestSatchelDate
                                                ? moment(satchelData?.newestSatchelDate).format("Do MMMM YYYY")
                                                : "-" })] }), _jsxs(Col, { span: 6, style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "0.5px solid #00B4D1",
                                    }, children: [_jsx(Text, { style: {
                                                fontSize: "14px",
                                                color: "#7F8E9D",
                                                marginLeft: "5px",
                                            }, children: "Oldest" }), _jsx(Text, { style: {
                                                fontSize: "16px",
                                                fontWeight: "700",
                                                marginLeft: "5px",
                                            }, children: satchelData?.oldestSatchelDate
                                                ? moment(satchelData?.oldestSatchelDate).format("Do MMMM YYYY")
                                                : "-" })] }), _jsxs(Col, { span: 6, style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRight: "0.5px solid #00B4D1",
                                    }, children: [_jsx(Text, { style: {
                                                fontSize: "14px",
                                                color: "#7F8E9D",
                                                marginLeft: "5px",
                                            }, children: "Average Service Days" }), _jsx(Text, { style: {
                                                fontSize: "16px",
                                                fontWeight: "700",
                                                marginLeft: "5px",
                                            }, children: satchelData?.averageServiceDays ? satchelData?.averageServiceDays : "-" })] }), _jsxs(Col, { span: 6, style: { display: "flex", flexDirection: "column" }, children: [_jsx(Text, { style: {
                                                fontSize: "14px",
                                                color: "#7F8E9D",
                                                marginLeft: "5px",
                                            }, children: "Uses" }), _jsx(Text, { style: {
                                                fontSize: "16px",
                                                fontWeight: "700",
                                                marginLeft: "5px",
                                            }, children: "Not Available" })] })] })] }) }), _jsx(SatchelsTable, {})] }));
};
