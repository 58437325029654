import { createSlice } from "@reduxjs/toolkit";
import { getLegal } from "src/pages/Legal/store/action";
export const initialState = {
    legal: {},
    loading: false,
    error: null,
};
const LegalSlice = createSlice({
    name: "legal",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLegal.fulfilled, (state, action) => {
            state.legal = action.payload.legalData;
            state.loading = false;
        })
            .addCase(getLegal.rejected, (state) => {
            state.loading = false;
        })
            .addCase(getLegal.pending, (state) => {
            state.loading = true;
        });
    },
});
export default LegalSlice.reducer;
