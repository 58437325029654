import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import styled from "styled-components";
import WaveSurfer from "wavesurfer.js";
export const AudioPlayerVisualizer = ({ src, customIcons, progressJumpStep, autoPlay, autoPlayAfterSrcChange, }) => {
    const waveSurferRef = useRef(null);
    const audioPlayerRef = useRef(null);
    useEffect(() => {
        if (waveSurferRef.current) {
            waveSurferRef.current.destroy();
        }
        const waveSurfer = WaveSurfer.create({
            container: "#waveform",
            waveColor: "#716f7f",
            progressColor: "#156cc9",
            barHeight: 2,
        });
        waveSurfer.load(src);
        waveSurferRef.current = waveSurfer;
        return () => {
            waveSurfer.destroy();
        };
    }, [src]);
    const handlePlay = () => {
        if (waveSurferRef.current) {
            waveSurferRef.current.play();
        }
    };
    const handlePause = () => {
        if (waveSurferRef.current) {
            waveSurferRef.current.pause();
        }
    };
    return (_jsxs(React.Fragment, { children: [_jsx(WaveFormDiv, { id: "waveform" }), _jsx(AudioPlayer, { ref: audioPlayerRef, src: src, customIcons: customIcons, progressJumpStep: progressJumpStep, autoPlay: autoPlay, autoPlayAfterSrcChange: autoPlayAfterSrcChange, onPlay: handlePlay, onPause: handlePause })] }));
};
const WaveFormDiv = styled.div `
  min-height: 100px;
  margin-bottom: 15px;
  background-color: #e6f7fa !important;
  border-radius: 5px;
  padding: 10px;
`;
