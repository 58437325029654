import { OnsiteJobServiceType } from "./store/types";
var CalendarView;
(function (CalendarView) {
    CalendarView["Day"] = "day";
    CalendarView["Week"] = "week";
    CalendarView["Month"] = "month";
})(CalendarView || (CalendarView = {}));
const CalendarViewLabelList = [
    { value: CalendarView.Day, label: "Day" },
    { value: CalendarView.Week, label: "Week" },
    { value: CalendarView.Month, label: "Month" },
];
const OnsiteJobServiceTypeDetail = {
    [OnsiteJobServiceType.RandomTesting]: "Random Testing",
    [OnsiteJobServiceType.BlanketTesting]: "Blanket Testing",
    [OnsiteJobServiceType.IncidentTesting]: "Incident Testing",
    [OnsiteJobServiceType.PreEmployment]: "Pre-employment",
    [OnsiteJobServiceType.ReturnToWork]: "Return to work",
};
export { CalendarView, CalendarViewLabelList, OnsiteJobServiceTypeDetail };
