import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image, Row, Select, Table } from "antd";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { SAColourScheme, mainAppColours } from "src/styles";
import { Text } from "src/components/base";
export const DataTable = ({ dataSource, columns, showPagination = true, tableSize = "large", }) => {
    const [size, setSize] = useState(10);
    const [dataLength, setDataLength] = useState(0);
    useEffect(() => {
        setDataLength(dataSource.length);
    }, [dataSource]);
    const itemRender = (current, type, originalElement) => {
        if (type === "prev") {
            return (_jsx(PrevIcon, { src: "/assets/images/double-arrow-back-box-gray-icon.png", preview: false, className: "prev-icon" }));
        }
        if (type === "next") {
            return (_jsx(NextIcon, { src: "/assets/images/double-arrow-forth-box-gray-icon.png", preview: false, className: "next-icon" }));
        }
        return originalElement;
    };
    const [pagination, setPagination] = useState({
        defaultPageSize: 5,
        pageSize: 10,
        total: dataLength,
        itemRender: itemRender,
        pageSizeOptions: [10, 15, 20],
    });
    const select = (pageSize) => {
        setPagination({ ...pagination, pageSize: pageSize });
        setSize(pageSize);
    };
    return (_jsxs(React.Fragment, { children: [_jsx(TableWrapper, { children: _jsx(Table, { columns: columns, dataSource: dataSource, scroll: { x: 900 }, pagination: showPagination && pagination, rowKey: (record) => record.id, size: tableSize }) }), dataSource.length > 0 && showPagination && (_jsxs(StyledRow, { justify: "start", align: "middle", children: [_jsx(Text, { name: "Show" }), _jsxs(Select, { value: size, onSelect: select, suffixIcon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false }), children: [_jsx(Select.Option, { value: 10, children: "10" }), _jsx(Select.Option, { value: 15, children: "15" }), _jsx(Select.Option, { value: 20, children: "20" })] })] }))] }));
};
const TableWrapper = styled.div `
  z-index: 10;

  .ant-table {
    border: 1px solid #d9d9d9;
  }

  .menu-options {
    cursor: pointer;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: ${SAColourScheme.PLASSMA10};
      border-bottom: 1px solid #adb3bc;
    }
  }

  .options-dropdown {
    border: 1px solid ${mainAppColours.MID_BLUE};
  }

  .menu-options:hover {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${SAColourScheme.PLASSMA};
  }
  .ant-pagination.mini .ant-pagination-prev {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }

  li.ant-pagination-item {
    border: 1px solid #ebedf0 !important;
  }
  li.ant-pagination-item a {
    color: #367893;
    font-size: 16px;
  }
  li.ant-pagination-item.ant-pagination-item-active {
    background: #adb3bc;
  }
  li.ant-pagination-item.ant-pagination-item-active a {
    color: white;
  }

  .ant-pagination-options {
    display: none !important;
  }
`;
const StyledRow = styled(Row) `
  margin-top: -50px;
  margin-left: 10px;
  display: flex !important;
  gap: 10px;

  .ant-typography {
    font-size: 1rem;
    color: #367893 !important;
  }
  .ant-select {
    max-width: 4.5em;
  }
  .ant-image-img {
    height: 8px;
    width: 14px;
  }
`;
const PrevIcon = styled(Image) `
  height: 10px;
  width: 10px;
`;
const NextIcon = styled(Image) `
  height: 10px;
  width: 10px;
`;
