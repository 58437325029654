import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Column } from "@ant-design/plots";
import { dashboard_getColorByName } from "src/utils/helper";
export default function ColumnChart(props) {
    const data = props.dataColumnChart ?? [];
    const selectedColorScheme = props.selectedColorScheme;
    const config = {
        data,
        isGroup: true,
        xField: "name",
        yField: "value",
        seriesField: "type",
        yAxis: {
            visible: false,
        },
        padding: 30,
        legend: false,
        color: [
            dashboard_getColorByName(selectedColorScheme, 6),
            dashboard_getColorByName(selectedColorScheme, 5),
            dashboard_getColorByName(selectedColorScheme, 4),
            dashboard_getColorByName(selectedColorScheme, 3),
            dashboard_getColorByName(selectedColorScheme, 2),
        ],
        label: {
            position: "top",
            // 'top', 'middle', 'bottom'
            color: "gray",
            layout: [
                {
                    type: "interval-hide-overlap",
                },
                {
                    type: "adjust-color",
                },
            ],
        },
    };
    return (
    // @ts-expect-error : should be refactored and removed this comment
    _jsx(Column, { ...config }));
}
