import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input, Tooltip, Image } from "antd";
import { SearchInputWrapper } from "./index.style";
export const SearchInput = ({ style, onChange, ...props }) => (_jsx(SearchInputWrapper, { children: _jsx(Input, { className: "search-input", style: {
            width: "349px",
            height: "36px",
            borderRadius: "5px",
            ...style,
        }, placeholder: "Search", suffix: _jsx(Tooltip, { title: "", children: _jsx(Image, { src: "/assets/images/search-icon-text-mid-gray.png", height: 20, width: 20, preview: false }) }), onChange: onChange, ...props }) }));
