import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "src/components/base";
import { faqDataListCM, faqDataListPA, faqDataListCA, faqDataListRTOMedical, } from "src/api/faqs/faqsService";
import { extractStringHtmlContent } from "src/utils/helper";
import { SearchBar } from "../components";
import { SearchResults } from "./SearchResults";
import { FaqContent } from "./FaqContent";
import { Wrapper } from "./index.style";
export const FAQs = (props) => {
    const faqListCM = useSelector(faqDataListCM.dataSelector);
    const faqListPA = useSelector(faqDataListPA.dataSelector);
    const faqListCA = useSelector(faqDataListCA.dataSelector);
    const faqListRTOMedical = useSelector(faqDataListRTOMedical.dataSelector);
    const [searchText, setSearchText] = useState(props.searchText);
    const [rawDataList, setRawDataList] = useState();
    const [searchResultList, setSearchResultList] = useState();
    useEffect(() => {
        if (faqListCM && faqListPA && faqListCA && faqListRTOMedical) {
            const addingRawDataList = [];
            [faqListCM, faqListPA, faqListCA, faqListRTOMedical].forEach((list) => {
                list.forEach((e) => {
                    addingRawDataList.push(...e.faqList);
                });
            });
            setRawDataList(rawDataList);
            doSearchingDirectly(rawDataList);
        }
    }, [faqListCM, faqListPA, faqListCA, faqListRTOMedical]);
    const onTyping = (value) => {
        setSearchText(value);
        if (!value.length) {
            setSearchResultList(null);
        }
    };
    const doSearching = () => {
        // Searching
        if (rawDataList && rawDataList.length) {
            const results = rawDataList.filter((e) => {
                const htmlContent = extractStringHtmlContent(e.htmlContent);
                return (e?.title?.toUpperCase().includes(searchText.toUpperCase()) ||
                    htmlContent.toUpperCase().includes(searchText.toUpperCase()));
            });
            if (searchText.length) {
                setSearchResultList(results);
            }
            else {
                setSearchResultList(null);
            }
        }
    };
    const doSearchingDirectly = (searchingRawDataList) => {
        // Searching
        if (searchingRawDataList && searchingRawDataList.length) {
            const results = searchingRawDataList.filter((e) => {
                const htmlContent = extractStringHtmlContent(e.htmlContent);
                return (e?.title?.toUpperCase().includes(searchText.toUpperCase()) ||
                    htmlContent.toUpperCase().includes(searchText.toUpperCase()));
            });
            if (searchText.length) {
                setSearchResultList(results);
            }
            else {
                setSearchResultList(null);
            }
        }
    };
    return (_jsxs(Wrapper, { children: [_jsxs("div", { className: "faq-header", children: [_jsxs("div", { style: { display: "flex" }, children: [_jsx("div", { style: { marginTop: "7px" }, children: _jsx(IconButton, { icon: "/assets/svg/ic-arrow-back.svg", onClick: props.onBack }) }), _jsx("div", { className: "faq-title", style: { paddingBottom: "7px" }, children: "FAQs" })] }), _jsx("div", { className: "faq-title", style: {
                            color: "#00004C",
                            marginTop: "23.5px",
                            textAlign: "center",
                        }, children: "Frequently Asked Questions" }), _jsx("div", { style: { marginTop: "15px" }, children: _jsx(SearchBar, { value: searchText, onChange: onTyping, onClear: () => {
                                setSearchText("");
                                setSearchResultList(null);
                            }, onEnter: doSearching }) })] }), searchResultList && (_jsx(SearchResults, { style: { marginTop: "-31px" }, searchResultList: searchResultList })), _jsx(FaqContent, { style: searchResultList ? { marginTop: "31px" } : { marginTop: "-31px" }, faqListCM: faqListCM, faqListPA: faqListPA, faqListCA: faqListCA, faqListRTOMedical: faqListRTOMedical })] }));
};
