/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { $delete, $get, $put } from "src/infra/http";
const getListClientRepresentative = createAsyncThunk("getListClientRepresentative", async (onsiteClientId) => await $get(`/collection-manager/client-management/representative/${onsiteClientId}`));
const getListLocations = createAsyncThunk("getListLocations", async (onsiteClientId) => await $get(`/collection-manager/client-management/location/${onsiteClientId}`));
const updateAuthorisedRep = createAsyncThunk("updateAuthorisedRep", async ({ onsiteClientId, data }, _) => {
    const response = await $put(`/collection-manager/client-management/representative/update/${onsiteClientId}`, data);
    return response;
});
const deleteClientRepresentative = createAsyncThunk("deleteClientRepresentative", async (representativeId) => await $delete(`/collection-manager/client-management/representative/${representativeId}`));
const deleteClientRepresentativeFile = createAsyncThunk("deleteClientRepresentativeFile", async (representativeFileId) => await $delete(`/collection-manager/client-management/representative/file/${representativeFileId}`));
export { getListClientRepresentative, getListLocations, updateAuthorisedRep, deleteClientRepresentative, deleteClientRepresentativeFile, };
