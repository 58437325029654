import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Divider, Row } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Text } from "src/components/base";
import { SAColourScheme } from "src/styles";
import { SAMPLE_RESULT } from "../../../../DonorRecord/types";
export const UrineDrugTest = ({ medicalSample }) => (_jsxs(React.Fragment, { children: [_jsxs(ResultDetails, { children: [_jsxs(Row, { children: [_jsx(ResultDetailsCol, { span: 8, children: _jsx(Text, { name: "Test Name", className: "details-title" }) }), _jsx(ResultDetailsCol, { span: 16, children: _jsx(Text, { name: medicalSample?.testType?.name, className: "details-value" }) })] }), _jsxs(Row, { children: [_jsx(ResultDetailsCol, { span: 8, children: _jsx(Text, { name: "Specimen Type", className: "details-title" }) }), _jsx(ResultDetailsCol, { span: 16, children: _jsx(Text, { name: medicalSample.specimenType, className: "details-value" }) })] }), _jsxs(Row, { children: [_jsx(ResultDetailsCol, { span: 8, children: _jsx(Text, { name: "Date", className: "details-title" }) }), _jsx(ResultDetailsCol, { span: 16, children: _jsx(Text, { name: moment(new Date(medicalSample?.startAt)).format("DD/MM/YYYY"), className: "details-value" }) })] }), _jsxs(Row, { children: [_jsx(ResultDetailsCol, { span: 8, children: _jsx(Text, { name: "Start Time", className: "details-title" }) }), _jsx(ResultDetailsCol, { span: 16, children: _jsx(Text, { name: moment(new Date(medicalSample?.startAt)).format("hh:mm a"), className: "details-value" }) })] }), _jsxs(Row, { children: [_jsx(ResultDetailsCol, { span: 8, children: _jsx(Text, { name: "End Time", className: "details-title" }) }), _jsx(ResultDetailsCol, { span: 16, children: _jsx(Text, { name: moment(new Date(medicalSample?.endAt)).format("hh:mm a"), className: "details-value" }) })] }), _jsx(Divider, {})] }), _jsxs(ResultFooter, { children: [_jsxs(Col, { span: 5, className: "footer-title", children: ["Sample ", medicalSample?.sampleOrderIndex, " Result"] }), _jsx(Col, { xs: 12, lg: 9, className: "footer-text", children: `${SAMPLE_RESULT[medicalSample.status]}` })] })] }));
const ResultDetails = styled.div `
  background-color: #f7f8f8;
  margin: 1rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;

  .details-title {
    color: #4c859f !important;
    font-weight: 700;
  }

  .details-value {
    color: #00004c !important;
  }

  .ant-divider-horizontal {
    margin: 8px 0;
    border-top: 1px solid ${SAColourScheme.PLASSMA} !important;
  }
`;
const ResultDetailsCol = styled(Col) `
  padding-top: 0.5rem;
`;
const ResultFooter = styled(Row) `
  border: 1px solid #adb3bc;
  margin: 1rem;
  border-radius: 5px;

  .footer-title {
    padding: 10px;
    background: #1c97ab;
    font-weight: 700;
    color: ${SAColourScheme.WHITE};
  }

  .footer-text {
    padding: 10px;
    border-left: 1px solid #adb3bc;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;
