import styled from "styled-components";
export const Wrapper = styled.div `
  .faq-elm-header {
    display: flex;
  }

  .faq-elm-title {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }
  .faq-elm-icon-after {
    flex: auto;
    text-align: right;
    margin-right: 20px;
  }
  .faq-answer-text {
    margin-bottom: 5px;
  }
  .faq-anwser-content {
    margin-top: 5px;
    margin-left: 35px;
    margin-right: 65px;
    font-size: 15px;
    color: #2f3337;
  }
  table.faq-answer-table {
    width: 100%;
    margin-bottom: 5px;
  }
  tr.faq-answer-table-header {
    background: gray;
  }
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
`;
