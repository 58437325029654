import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image } from "antd";
import { sanitize } from "dompurify";
import { Collapsible } from "src/components/dataView";
import { Wrapper } from "./index.style";
export const FaqElement = (props) => {
    const { title = "", htmlContent = "" } = props;
    const header = (question) => (_jsxs("div", { className: "faq-elm-header", children: [_jsx("div", { className: "faq-elm-icon-beforce", children: _jsx(Image, { src: "/assets/svg/ic-chat.svg", preview: false }) }), _jsx("div", { className: "faq-elm-title", style: { marginLeft: "12.3px" }, children: question }), _jsx("div", { className: "faq-elm-icon-after", children: _jsx(Image, { src: "/assets/svg/ic-plus.svg", preview: false }) })] }));
    const activeHeader = (question) => (_jsxs("div", { className: "faq-elm-header", children: [_jsx("div", { className: "faq-elm-icon-beforce", children: _jsx(Image, { src: "/assets/svg/ic-chat.svg", preview: false }) }), _jsx("div", { className: "faq-elm-title", style: { marginLeft: "12.3px" }, children: question }), _jsx("div", { className: "faq-elm-icon-after", children: _jsx(Image, { src: "/assets/svg/ic-minus.svg", preview: false }) })] }));
    const renderHTMLAnswer = (htmlString) => (_jsx("div", { className: "faq-anwser-content", dangerouslySetInnerHTML: { __html: sanitize(htmlString) } }));
    return (_jsxs(Wrapper, { className: props.className, children: [_jsx(Collapsible, { header: header(title), activeHeader: activeHeader(title), children: renderHTMLAnswer(htmlContent) }), _jsx("div", { style: { borderBottom: "1px solid #ADB3BC", marginTop: "11px" } })] }));
};
