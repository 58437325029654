import styled from "styled-components";
export const PageWrapper = styled.div `
  font-family:
    Roboto,
    -apple-system;
  .tick-box {
    background: #ffffff;
    border-radius: 2px;
    width: 18.5px;
    height: 18.5px;
    text-align: center;
  }
`;
