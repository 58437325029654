import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { selectIsLoading } from "src/store/app/selector";
import { LocationData } from "./LocationData";
import { LocationArchive } from "./LocationArchive";
import { NewLocation } from "./NewLocation";
import { createClientLocationDetails, getListArchivedClientLocations, getListClientLocations, } from "./store/action";
import { selectArchivedClientLocationsList, selectClientLocationsList, selectIsAddNew, selectIsListPage, selectLocationId, } from "./store/selector";
import { LocationDetails } from "./LocationDetails";
export const ClientLocations = () => {
    const locationsData = useAppSelector(selectClientLocationsList);
    const archivedLocationsData = useAppSelector(selectArchivedClientLocationsList);
    const addNew = useAppSelector(selectIsAddNew);
    const listPage = useAppSelector(selectIsListPage);
    const locationId = useAppSelector(selectLocationId);
    const isLoading = useAppSelector(selectIsLoading);
    const dispatch = useAppDispatch();
    const { clientId } = useParams();
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            if (clientId) {
                await dispatch(getListClientLocations(clientId));
                await dispatch(getListArchivedClientLocations(clientId));
            }
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [location, listPage]);
    const cloneLocationRef = async (record) => {
        if (clientId) {
            const { clientContacts, ...locationDetails } = record;
            const locationData = {
                siteName: locationDetails.siteName + " - Copy",
                address1: locationDetails.address1,
                address2: locationDetails.address2,
                suburb: locationDetails.suburb,
                state: locationDetails.state,
                onsiteClientId: locationDetails.onsiteClientId,
                postCode: locationDetails.postCode,
                country: locationDetails.country,
                siteAccessInstructions: locationDetails.siteAccessInstructions,
            };
            const contactsData = clientContacts.map((contact) => ({
                name: contact.name,
                mobile: contact.mobile,
            }));
            const completeData = { locationData, contactsData };
            dispatch(setIsLoading(true));
            await dispatch(createClientLocationDetails(completeData));
            await dispatch(getListClientLocations(clientId));
            dispatch(setIsLoading(false));
        }
    };
    return (_jsxs(React.Fragment, { children: [isLoading && _jsx(OverlayLoading, {}), listPage ? (_jsxs(React.Fragment, { children: [_jsx(LocationData, { locationsData: locationsData, cloneLocationRef: cloneLocationRef, clientId: clientId }), _jsx(LocationArchive, { archivedLocationsData: archivedLocationsData, clientId: clientId })] })) : addNew ? (_jsx(NewLocation, { clientId: clientId })) : (_jsx(LocationDetails, { clientId: clientId, locationId: locationId }))] }));
};
