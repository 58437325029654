import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import moment from "moment";
import { getCollectorWorkRecord, getListClockOnClockOff, getListCollectors } from "./action";
export const initialState = {
    clockOnClockOffData: [],
    listClientsData: [],
    listCollectorsData: [],
    selectedClient: { id: "", name: "All" },
    selectedCollector: null,
    selectedToday: true,
    selectedDate: moment(),
    workRecordStartDate: null,
    workRecordEndDate: null,
    workRecordData: { wordRecords: [], totalHours: 0 },
};
const clockOnClockOffSlice = createSlice({
    name: "clockOnClockOff",
    initialState,
    reducers: {
        setCurrentClient(state, action) {
            state.selectedClient = action.payload;
        },
        setCurrentCollector(state, action) {
            state.selectedCollector = action.payload;
        },
        setSelectedToday(state, action) {
            state.selectedToday = action.payload;
        },
        setSelectedDate(state, action) {
            state.selectedDate = action.payload;
        },
        setWorkRecordStartDate(state, action) {
            state.workRecordStartDate = action.payload;
        },
        setWorkRecordEndDate(state, action) {
            state.workRecordEndDate = action.payload;
        },
        setResetStore() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListClockOnClockOff.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.clockOnClockOffData = initialState.clockOnClockOffData;
                message.error("Failed to fetch clock-on clock-off records");
            }
            else {
                state.clockOnClockOffData = action.payload.data.workRecord;
                state.listClientsData = [{ id: "", name: "All" }, ...action.payload.data.clients];
            }
        })
            .addCase(getListClockOnClockOff.rejected, () => {
            message.error("Failed to fetch clock-on clock-off records");
        })
            .addCase(getListCollectors.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.listCollectorsData = initialState.listCollectorsData;
                message.error("Failed to fetch collectors");
            }
            else {
                state.listCollectorsData = action.payload.data;
            }
        })
            .addCase(getListCollectors.rejected, () => {
            message.error("Failed to fetch collectors");
        })
            .addCase(getCollectorWorkRecord.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.workRecordData = initialState.workRecordData;
                message.error("Failed to fetch collector records");
            }
            else {
                state.workRecordData = action.payload.data;
            }
        })
            .addCase(getCollectorWorkRecord.rejected, () => {
            message.error("Failed to fetch collector records");
        });
    },
});
export const { setSelectedToday, setResetStore, setSelectedDate, setCurrentClient, setCurrentCollector, setWorkRecordStartDate, setWorkRecordEndDate, } = clockOnClockOffSlice.actions;
export default clockOnClockOffSlice.reducer;
