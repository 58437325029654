import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { Tag, Input, Image, Button } from "antd";
export const TagGroup = ({ onTagsChange, formDisabled, initialTags = [], }) => {
    const [tags, setTags] = useState(initialTags);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState("");
    useEffect(() => {
        onTagsChange(tags);
    }, [tags, onTagsChange]);
    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }
        setInputVisible(false);
        setInputValue("");
    };
    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        setTags(newTags);
    };
    return (_jsxs("div", { children: [_jsx("span", { style: { marginRight: "10px", fontWeight: "600" }, children: "Tags" }), _jsx(Button, { onClick: showInput, disabled: formDisabled, style: { border: "none", background: "white" }, children: _jsx(Image, { src: "/assets/svg/add-icon.svg", preview: false }) }), _jsxs("div", { style: {
                    border: "1px solid #D9D9D9",
                    borderRadius: "4px",
                    height: "100px",
                    padding: "2px 8px 8px 8px",
                    overflowY: "scroll",
                    background: formDisabled ? "rgba(0, 0, 0, 0.05)" : "white",
                }, children: [_jsx("span", { style: { fontSize: "14px", fontWeight: "500" }, children: "Tag Description" }), _jsxs("div", { children: [tags.map((tag) => (_jsx(Tag, { closable: !formDisabled, onClose: () => handleClose(tag), style: { marginTop: "8px" }, children: _jsx("span", { style: { fontSize: "14px", fontWeight: "500" }, children: tag }) }, tag))), inputVisible && (_jsx(Input, { disabled: formDisabled, type: "text", size: "middle", style: { width: 78, marginBottom: "8px" }, value: inputValue, onChange: handleInputChange, onBlur: handleInputConfirm, onPressEnter: handleInputConfirm }))] })] })] }));
};
