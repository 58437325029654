import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import PageLayoutWithNoTab from "src/components/layouts/PageLayout/PageLayoutWithNoTab";
const PlatformManagementPage = () => {
    const navigate = useNavigate();
    const containerStyle = {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "5%",
        margin: "3% 5%",
        backgroundColor: "white",
        padding: "34px 11px 11px",
    };
    const menu = [
        {
            name: "Welcome Assist",
            logo: "/assets/svg/platform-settings/welcome-assist.svg",
            link: "/platform-management/welcome-assist/kiosk-management",
            desc: "Management",
        },
        {
            name: "Work Assist",
            logo: "/assets/svg/platform-settings/work-assist.svg",
            link: "/platform-management/work-assist",
            desc: "Management",
        },
        {
            name: "Collect Assist",
            logo: "/assets/svg/platform-settings/collect-assist.svg",
            link: "/platform-management/collect-assist/collectors",
            desc: "Management",
        },
        {
            name: "Secure Assist",
            logo: "/assets/svg/platform-settings/secure-assist.svg",
            link: "/platform-management/secure-assist",
            desc: "Management",
        },
        {
            name: "Lab Assist",
            logo: "/assets/svg/platform-settings/lab-assist.svg",
            link: "/platform-management/lab-assist",
            desc: "Management",
        },
        {
            name: "Report Assist",
            logo: "/assets/svg/platform-settings/report-assist.svg",
            link: "/platform-management/report-assist",
            desc: "Management",
        },
    ];
    return (_jsx(PageLayoutWithNoTab, { location: "/platform-management", heading: "Platform Management", children: _jsx("div", { style: { backgroundColor: "white", minHeight: "79vh" }, children: _jsx("div", { style: containerStyle, children: menu.map((item) => (_jsx("div", { style: { backgroundColor: "#F7F8F8", position: "relative", cursor: "pointer" }, onClick: () => navigate(item.link), children: _jsxs("div", { style: { padding: "20px", textAlign: "left" }, children: [_jsx("img", { src: item.logo, alt: `${item.name}-logo`, style: { marginBottom: "20px" } }), _jsx(Typography, { fontStyle: "Roboto", fontWeight: 600, variant: "h6", children: item.name }), _jsx(Typography, { style: { marginTop: "10px" }, children: item.desc })] }) }, `collect-assist-${item.name}`))) }) }) }));
};
export default PlatformManagementPage;
