import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image } from "antd";
import { Collapsible } from "src/components/dataView";
import { FaqElement } from "../../../../components";
import { Wrapper } from "./index.style";
export const Subject = (props) => {
    const { faqList } = props;
    const renderHeader = (name) => (_jsxs("div", { className: "question-header", children: [_jsx("div", { className: "question-icon-before" }), _jsx("div", { className: "question-text", children: name }), _jsx("div", { className: "question-icon-after", children: _jsx(Image, { src: "/assets/svg/ic-plus.svg", preview: false }) })] }));
    const renderActiveHeader = (name) => (_jsxs("div", { className: "question-header", children: [_jsx("div", { className: "question-icon-before" }), _jsx("div", { className: "question-text", children: name }), _jsx("div", { className: "question-icon-after", children: _jsx(Image, { src: "/assets/svg/ic-minus.svg", preview: false }) })] }));
    const renderFaqElement = () => {
        const elm = faqList
            .sort((a, b) => (a.index < b.index ? -1 : 1))
            .map((e, index) => (_jsx(FaqElement, { className: "faq-element", title: e.title, htmlContent: e.htmlContent }, index)));
        return elm;
    };
    return (_jsx(Wrapper, { children: _jsx(Collapsible, { className: "question-frame", header: renderHeader(props.title), activeHeader: renderActiveHeader(props.title), children: _jsx("div", { className: "faq-subject", children: renderFaqElement() }) }) }));
};
