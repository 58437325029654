import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Space, Switch, Table } from "antd";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import DocumentIcon from "src/assets/svg/device-management/document.svg";
import { selectCollectionPoints } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getTestingDevices } from "../store/action";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
import { updateInUseTestingDevice } from "../../store/action";
import DeviceActionsDropdown from "./DeviceActionsDropdown";
export const DeviceListView = ({ testingDevices }) => {
    const navigate = useNavigate();
    const collectionPoints = useAppSelector(selectCollectionPoints);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const testTypeIdParam = searchParams.get("testTypeId");
    const loadUpdatedData = async () => {
        if (searchParams.get("isArchiveShown") === "true") {
            await dispatch(getTestingDevices({
                testTypeId: String(testTypeIdParam),
                isArchived: true,
            }));
        }
        await dispatch(getTestingDevices({
            testTypeId: String(testTypeIdParam),
            isArchived: false,
        }));
    };
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Device Name",
            dataIndex: "deviceName",
            key: "deviceName",
            sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
            sortDirections: ["ascend", "descend"],
            render: (text, record) => (_jsx(StyledLink, { onClick: () => navigate(`/testing-devices/breathalyser/update?id=${record.id}`), children: text })),
        },
        {
            title: "Serial Number",
            dataIndex: "serialNumber",
            key: "serialNumber",
        },
        {
            title: "Certificate No.",
            dataIndex: "certificateNumber",
            key: "certificateNumber",
        },
        {
            title: "Calibration Due",
            dataIndex: "calibrationAt",
            key: "calibrationAt",
            render: (calibrationAt) => (_jsx(Space, { children: moment(calibrationAt).format("DD MMM YYYY hh:mm A") })),
        },
        {
            title: "Location",
            dataIndex: "collectionPointIds",
            key: "usageLocation",
            render: (collectionPointIds) => (_jsx(Space, { children: collectionPointIds.length > 1
                    ? "Multiple Locations"
                    : collectionPoints.find((point) => point.id === collectionPointIds[0])
                        ?.collectionPointName })),
        },
        {
            title: "In Use",
            dataIndex: "isInUse",
            key: "isInUse",
            render: (isInUse, record) => (_jsx(Switch, { checked: isInUse, onChange: async () => {
                    await dispatch(updateInUseTestingDevice({ isInUse: !record.isInUse, id: record.id }));
                    await loadUpdatedData();
                } })),
        },
        {
            title: "Certificate",
            dataIndex: "calibrationCertificateUrl",
            key: "calibrationCertificateUrl",
            render: (calibrationCertificateUrl) => (_jsx(ContentButton, { type: "link", href: calibrationCertificateUrl, target: "_blank", children: _jsx(CustomDocumentIcon, { "$isAvailableUrl": !!calibrationCertificateUrl }) })),
        },
        {
            title: "Report",
            dataIndex: "calibrationReportUrl",
            key: "calibrationReportUrl",
            render: (calibrationReportUrl) => (_jsx(ContentButton, { type: "link", href: calibrationReportUrl, target: "_blank", children: _jsx(CustomDocumentIcon, { "$isAvailableUrl": !!calibrationReportUrl }) })),
        },
        {
            title: "",
            key: "id",
            width: 55,
            render: (record) => (_jsx(DeviceActionsDropdown, { id: record.id, icon: _jsx(ICMenuOptions, {}), isArchived: record.isArchived, reload: async () => {
                    await loadUpdatedData();
                } })),
        },
    ];
    return (_jsx(Table, { columns: columns, dataSource: testingDevices, pagination: false, scroll: { x: 900, y: 650 }, rowKey: "id" }));
};
const ContentButton = styled(Button) `
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
`;
const CustomDocumentIcon = styled(DocumentIcon) `
  color: ${({ $isAvailableUrl }) => ($isAvailableUrl ? "#1A8CFF" : "#EDEEF0")};
`;
const StyledLink = styled.a `
  color: #2a44ff;
  text-decoration: underline;
`;
