import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import CreateCallOutJob from "./CreateNewJob/CallOutJob";
import CreateNormalJob from "./CreateNewJob/NormalJob";
import { OnSiteApprovals } from "./OnSiteApprovals";
import { OnSiteJobs } from "./OnSiteJobs";
import UpdateCallOutJob from "./UpdateJob/CallOutJob";
import UpdateNormalJob from "./UpdateJob/NormalJob";
const breadcrumbs = [
    {
        path: "/job-management",
        name: "Job Management",
        level: 1,
    },
    {
        path: "/onsite-jobs",
        name: "On-Site Jobs",
        level: 2,
    },
    {
        path: "/approval",
        name: "On-Site Approvals",
        level: 2,
    },
];
const JobManagementPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === "/job-management") {
            navigate("/job-management/onsite-jobs");
        }
    }, [location]);
    return (_jsx(MainLayout, { children: _jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(OnSiteJobs, {}) }), _jsx(Route, { path: "/onsite-jobs", element: _jsx(OnSiteJobs, {}) }), _jsx(Route, { path: "/onsite-jobs/create-normal", element: _jsx(CreateNormalJob, {}) }), _jsx(Route, { path: "/onsite-jobs/create-call-out", element: _jsx(CreateCallOutJob, {}) }), _jsx(Route, { path: "/approval", element: _jsx(OnSiteApprovals, {}) }), _jsx(Route, { path: "/onsite-jobs/:onsiteJobId/normal", element: _jsx(UpdateNormalJob, {}) }), _jsx(Route, { path: "/onsite-jobs/:onsiteJobId/call-out", element: _jsx(UpdateCallOutJob, {}) })] }) }) }));
};
export default JobManagementPage;
