import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { Attachment } from "../components/Attachment";
export const columnConfig = [
    {
        title: "Audience",
        dataIndex: "audience",
        key: "audience",
        width: 90,
        className: "cell-center",
    },
    {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 110,
    },
    {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        width: 130,
    },
    {
        title: "Category",
        dataIndex: "category",
        key: "category",
        width: 235,
    },
    {
        title: "Date",
        dataIndex: "timeSent",
        key: "timeSent",
        // eslint-disable-next-line arrow-body-style
        render: (timeSent) => {
            const { _seconds: seconds, _nanoseconds: nanoseconds } = timeSent;
            const date = new Date(seconds * 1000 + nanoseconds / 1e6);
            return moment(date).format("DD/MM/YYYY");
        },
        width: 155,
    },
    {
        title: "Time",
        dataIndex: "timeSent",
        key: "timeSent",
        // eslint-disable-next-line arrow-body-style
        render: (timeSent) => {
            const { _seconds: seconds, _nanoseconds: nanoseconds } = timeSent;
            const date = new Date(seconds * 1000 + nanoseconds / 1e6);
            return moment(date).format("HH:mm");
        },
        width: 155,
    },
    {
        title: "Attachment",
        dataIndex: "attachments",
        key: "attachments",
        // eslint-disable-next-line arrow-body-style
        render: (fileUrl, record) => {
            return fileUrl ? (_jsx("div", { style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }, children: _jsx(Attachment, { content: record }) })) : ("");
        },
        width: 110,
    },
    {
        title: "Send by",
        dataIndex: "sendBy",
        key: "sendBy",
        width: 85,
    },
    {
        title: "Sender",
        dataIndex: "sender",
        key: "sender",
        width: 130,
    },
];
