import { mainAppColours, SAColourScheme, supportingColours } from "./color";
const theme = {
    name: "default",
    colors: {
        primary: mainAppColours.BLUE,
        secondary: mainAppColours.MID_BLUE,
        disabled: supportingColours.BOX_GRAY,
        error: SAColourScheme.PLASSMA,
        background: SAColourScheme.WHITE,
        grey: {
            background: supportingColours.BACKGROUND_GRAY,
            box: supportingColours.BOX_GRAY,
            boxLight: supportingColours.BOX_LIGHT,
            textLight: supportingColours.TEXT_LIGHT_GRAY,
            textMid: supportingColours.TEXT_MID_GRAY,
            textDark: supportingColours.TEXT_DARK_GRAY,
        },
    },
    fonts: "Roboto, -apple-system, sans-serif",
    fontSizes: {
        small: "1em",
        medium: "2em",
        large: "3em",
    },
    responsive: {
        mobileMaxWidth: 767,
    },
};
export default theme;
