import styled from "styled-components";
export const HeaderWrapper = styled.div `
  .button-selection > .ant-select > .ant-select-selector {
    border: 1px solid #adb3bc;
    border-radius: 4px;
    height: 30px !important;
    width: 200px;
    color: #2f3337;
    background: white !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-size: 13px;
    color: #2f3337;
    font-weight: 700;
    margin: 0;
  }

  .button-selection > .ant-select > .ant-select-selector .ant-select-arrow {
    color: #2f3337 !important;
  }
`;
