import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "antd";
import styled from "styled-components";
import DocumentIcon from "src/assets/svg/device-management/document.svg";
const AttachmentBox = ({ title, contentUrl }) => (_jsxs(AttachmentBoxContainer, { children: [_jsx(TitleText, { children: title }), _jsx(ContentButton, { type: "link", href: contentUrl, target: "_blank", children: _jsx(DocumentIcon, { color: contentUrl ? "#1A8CFF" : "#EDEEF0" }) })] }));
const AttachmentBoxContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;
const TitleText = styled.p `
  color: #4b5767;
  font-size: 0.688rem;
  font-weight: 400;
`;
const ContentButton = styled(Button) `
  color: #000000;
  font-size: 0.875rem;
  font-weight: 400;
`;
export default AttachmentBox;
