/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import moment from "moment";
import { $get } from "src/infra/http";
const getListOnsiteApprovals = createAsyncThunk("getListOnsiteApprovals", async (_, thunkApi) => {
    const state = thunkApi.getState().onsiteApprovalsPage;
    const date = moment(state.selectedDate).format("YYYY-MM-DD");
    const queryString = stringify({
        date,
    });
    return await $get(`/collection-manager/job-management/approvals?${queryString}`);
});
export { getListOnsiteApprovals };
