import styled from "styled-components";
import { fontWeight, mainAppColours, SAColourScheme } from "src/constants";
export const HomeCalendarWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${SAColourScheme.MID_BLUE};
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0 3px;
  }

  .calendar-title {
    color: #ffffff !important;
    font-size: 18px;
    font-weight: ${fontWeight.BOLD};

    margin: 6px 0;
  }
  .today-button {
    height: 22px;
    margin-right: 5px;
    font-size: 12px;
  }

  .calendar-year {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    background-color: #ffffff;
    padding-bottom: 12px;

    .ant-typography {
      font-size: 15px;
      font-weight: ${fontWeight.BOLD};
      color: ${SAColourScheme.NAVY};
      margin: 0 10px;
    }
  }

  .ant-picker-calendar-mini {
    border-radius: 0px;
  }

  .ant-picker-panel {
    border: 0;

    .ant-picker-body {
      padding: 0 20px;
    }
  }

  .ant-picker-calendar-mini .ant-picker-content {
    height: unset;
  }

  .ant-picker-content th,
  .ant-picker-content td {
    width: 16px;
    height: 16px;
    font-weight: ${fontWeight.BOLD};
    font-size: 11px;
    color: ${SAColourScheme.BLACK};
  }

  .ant-picker-content th {
    &:first-child {
      color: ${SAColourScheme.RED};
    }
  }

  .ant-picker-content tr {
    height: 24px;
  }

  .ant-picker-content td {
    &.ant-picker-cell {
      padding: 0;
    }

    &.ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: ${mainAppColours.MID_BLUE};
      border-radius: 4px;
    }

    &.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: ${mainAppColours.MID_BLUE};
      border-radius: 4px;
    }

    :first-child.ant-picker-cell-in-view {
      color: ${SAColourScheme.RED};
    }

    &:not(.ant-picker-cell-in-view) {
      pointer-events: none;
    }
  }
`;
export const CalendarHeaderWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px;

  .current-month {
    color: ${mainAppColours.BLUE} !important;
    font-size: 16px;
    font-weight: ${fontWeight.BOLD};

    text-align: center;

    margin: 0 38px;
    min-width: 80px;
  }

  .change-button {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${mainAppColours.LIGHT_BLUE_2};

    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 2px;
    border: 0;
  }
`;
