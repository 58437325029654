import styled from "styled-components";
export const PatientListWrapper = styled.div `
  margin-top: 10px;
  border: 1px solid #707070;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 20px;
  height: 96%;
  .ant-table-wrapper {
    height: 100%;
  }
  .ant-spin-nested-loading {
    height: 100%;
  }
  .ant-spin-container {
    height: 98%;
  }
  .ant-table.ant-table-middle {
    height: 94%;
  }
  .ant-table-container {
    height: 100%;
  }
  .ant-table-content {
    height: 100%;
  }

  .ant-table table {
    width: 100% !important;
    height: ${({ isEmpty }) => (isEmpty ? "100%" : "auto")};
  }

  th.ant-table-cell {
    background: #0047b3;
    color: white;
    font-size: 16px;
    font-weight: normal;
  }
  th.ant-table-cell::before {
    background-color: #01b4d2 !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  .ant-table-container {
    border-radius: 8px;
    border-bottom: 15px solid #0047b3;
    width: 100% !important;
    overflow: auto;
  }
  .number-column-none {
    display: none;
  }
  .date-column-none {
    display: none;
  }
  .time-column-none {
    display: none;
  }
  .last-name-column-none {
    display: none;
  }
  .first-name-column-none {
    display: none;
  }
  .dob-column-none {
    display: none;
  }
  .age-column-none {
    display: none;
  }
  .sex-column-none {
    display: none;
  }
  .request-column-none {
    display: none;
  }
  th.ant-table-cell.number-column {
    text-align: center;
  }
  td.ant-table-cell {
    font-size: 16px;
    font-weight: normal;
    color: #1a576f;
  }
  td.ant-table-cell.number-column {
    background: #c9eef8;
    text-align: center;
  }
  td.ant-table-cell-row-hover {
    background: #01b4d2 !important;
    color: white;
  }
  td.ant-table-cell.number-column.ant-table-cell-row-hover {
    background: rgba(1, 180, 210, 0.6) !important;
    color: #1a576f;
  }
  tr.ant-table-row.ant-table-row-level-0 {
    height: 50px;
  }
  tr.ant-table-row.ant-table-row-level-0:nth-child(even) {
    background: #f7fcfd;
  }
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  td.ant-table-cell.request-column {
    padding-left: 20px !important;
  }
  .ant-table-pagination.ant-pagination {
    margin: 8px;
  }
  .ant-pagination.mini .ant-pagination-prev {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }
  .ant-pagination.mini .ant-pagination-next {
    border: 1px solid #ebedf0;
    margin-left: 14px !important;
    height: 28px;
    width: 28px;
  }

  li.ant-pagination-item {
    border: 1px solid #ebedf0 !important;
    margin-left: 14px !important;
    width: 28px !important;
    height: 28px !important;
  }
  li.ant-pagination-item a {
    color: #367893;
    font-size: 16px;
    padding-top: 3px;
  }
  li.ant-pagination-item.ant-pagination-item-active {
    background: #adb3bc;
  }
  li.ant-pagination-item.ant-pagination-item-active a {
    color: white;
  }
  td.ant-table-cell.view-column {
    text-align: center;
  }
  .ant-table-cell.ant-table-column-has-sorters:hover {
    background: #0047b3;
  }
  span.ant-table-column-sorter.ant-table-column-sorter-full {
    display: none;
  }
`;
