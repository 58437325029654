import styled from "styled-components";
export const PageWrapper = styled.div `
  .collapsible .content-parent {
    height: 0px;
    overflow: hidden;
    transition: height ease 0.3s;
  }
  .toggle {
    cursor: pointer;
  }
`;
