/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { $delete, $get, $post } from "src/infra/http";
export const getCollectionCentreById = createAsyncThunk("getCollectionCentreById", async (id) => await $get(`/collection-manager/collection-centre/${id}`));
export const getCollectionPointByCentreId = createAsyncThunk("getCollectionPointByCentreId", async (collectionOrganizationId) => await $get(`/collection-manager/collection-point/${collectionOrganizationId}`));
export const getCollectionOrganization = createAsyncThunk("getCollectionOrganization", async (collectionOrganizationId) => await $get(`/collection-manager/collection-organization/${collectionOrganizationId}`));
export const getCollectionOrganizationDetails = createAsyncThunk("getCollectionOrganizationDetails", async () => await $get(`/collection-manager/collection-organization/detail`));
export const UpdateOrganizationDetails = createAsyncThunk("UpdateOrganizationDetails", async ({ organizationDetail, addedCollectionPoint, existedCollectionPoint }) => await $post(`/collection-manager/collection-organization/update`, {
    organizationDetail,
    newCollectionPoints: addedCollectionPoint,
    oldCollectionPoints: existedCollectionPoint,
}));
export const UpdateLaboratoryDetails = createAsyncThunk("UpdateLaboratoryDetails", async ({ addedLaboratories, existedLaboratories }) => await $post(`/collection-manager/collection-organization/laboratory`, {
    newLaboratories: addedLaboratories,
    oldLaboratories: existedLaboratories,
}));
export const deleteOrganizationCollectionPoint = createAsyncThunk("deleteOrganizationCollectionPoint", async ({ collectionPointId }) => await $delete(`/collection-manager/collection-organization/collection-point/delete/${collectionPointId}`));
export const deleteLab = createAsyncThunk("deleteLab", async ({ labId }) => await $delete(`/collection-manager/collection-organization/laboratory/delete/${labId}`));
export const getCollectionCentreByUUID = createAsyncThunk("getCollectionCentreByUUID", async (collectionCentreUUID) => await $get(`/collection-organization/${collectionCentreUUID}`));
