import styled from "styled-components";
import { fontWeight, SAColourScheme } from "src/constants";
export const FormBoxWrapper = styled.div `
  background-color: ${SAColourScheme.MID_BLUE};
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  padding-top: 0px;
  margin-top: 40px;
  position: relative;

  .form-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #c9eef8;
    border: 4px solid ${SAColourScheme.MID_BLUE};
    top: -29px;
    left: 16px;
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-title {
    display: flex;
    font-size: 15px;
    font-weight: ${fontWeight.MEDIUM};
    margin-left: 90px;
    align-items: flex-end;
    min-height: 60px;
    margin-right: 10px;
    flex-wrap: wrap;
    overflow: hidden;

    .ant-typography {
      color: ${SAColourScheme.WHITE};
      padding-bottom: 10px;
    }

    .ant-input,
    .ant-select-selector,
    .ant-picker,
    .ant-select-selection-search {
      height: 40px !important;
      text-align: center;
      font-size: 24px;
      font-weight: ${fontWeight.BOLD};
    }

    .ant-input[disabled] {
      background-color: transparent;
      color: ${SAColourScheme.WHITE};
    }
  }
`;
