import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, ListItemText, MenuItem, TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Image } from "src/components/base";
import LevelPermissionTable from "src/pages/UsersPage/components/LevelPermissionTable";
import ValueWithLabel from "src/pages/UsersPage/components/ValueWithLabel";
import { getPermissionDetail, updateUserRolesOrTitle } from "src/pages/UsersPage/store/action";
import { useAppDispatch } from "src/store/hooks";
const ViewUserDetails = ({ userDetails, userRoles }) => {
    const dispatch = useAppDispatch();
    const [selectedTitle, setSelectedTitle] = useState("");
    const [selectRole, setSelectedRole] = useState("");
    const [rolePermission, setRolePermission] = useState([]);
    const [customUserTitle, setCustomUserTitle] = useState([]);
    const [open, setOpen] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const handleInputKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent form submission
            if (event.target.value.trim() !== "") {
                setCustomUserTitle([...customUserTitle, event.target.value]);
            }
        }
    };
    const handleTextFieldClick = (event) => {
        event.stopPropagation(); // Prevent click event from propagating to the Select component
    };
    const userTitle = [
        "Reception",
        "General Manager",
        "Practice Manager",
        "Clinician",
        "Doctor",
        "Medical Director",
        "Principal",
    ];
    useEffect(() => {
        setSelectedTitle(userDetails?.adminInfo?.title);
        setSelectedRole(userDetails?.adminInfo?.userRoleId);
        if (!userTitle.includes(userDetails?.adminInfo?.title)) {
            setCustomUserTitle([userDetails?.adminInfo?.title]);
        }
    }, [userDetails]);
    useEffect(() => {
        handleGetPermissionByRole(selectRole);
    }, [selectRole]);
    const handleGetPermissionByRole = async (roleId) => {
        if (roleId) {
            const result = await dispatch(getPermissionDetail(roleId));
            // @ts-expect-error: remove this later
            setRolePermission(result.payload.data);
        }
    };
    const handleUpdatePermission = async (title, userRoleId) => {
        await dispatch(updateUserRolesOrTitle({
            adminId: userDetails.adminInfo.adminId,
            userRoleId: userRoleId,
            userTitle: title,
        }));
        setIsChanged(false);
    };
    return (_jsxs("div", { style: { margin: "2% 5%", display: "flex", gap: "5px" }, children: [_jsxs("div", { style: {
                    width: "35%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#F7F8F8",
                    alignItems: "center",
                    gap: "20px",
                }, children: [_jsx(Typography, { style: {
                            backgroundColor: "#D9D9D9",
                            width: "100%",
                            textAlign: "center",
                            padding: "10px",
                            fontWeight: "bold",
                        }, children: "User Details" }), _jsx(Image, { src: "/assets/svg/ic-user-avatar-placeholder.svg", width: "60%", style: {
                            border: "1px solid #ADB3BC",
                            borderRadius: 5,
                        }, preview: false }), _jsxs("div", { style: {
                            width: "80%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            marginBottom: "20px",
                        }, children: [_jsxs(Typography, { variant: "h6", style: {
                                    backgroundColor: "#01B4D2",
                                    color: "white",
                                    width: "100%",
                                    textAlign: "center",
                                    padding: "5px 10px",
                                }, children: [userDetails?.userData?.firstName || "", " ", userDetails?.userData?.lastName || ""] }), _jsx(ValueWithLabel, { label: "Title", value: userDetails?.adminInfo?.title || "" }), _jsx(ValueWithLabel, { label: "First Name", value: userDetails?.userData?.firstName || "" }), _jsx(ValueWithLabel, { label: "Last Name", value: userDetails?.userData?.lastName || "" }), _jsx(ValueWithLabel, { label: "Date of Birth", value: userDetails?.userData?.dob || "Not Available" }), _jsx(ValueWithLabel, { label: "Mobile Number", value: userDetails?.userData?.mobile || "" }), _jsx(ValueWithLabel, { label: "Email", value: userDetails?.userData?.email || "" }), _jsx(Button, { startIcon: _jsx(DeleteIcon, {}), variant: "outlined", style: {
                                    textTransform: "none",
                                    width: "60%",
                                    color: "#FD0000",
                                    borderColor: "#FD0000",
                                    alignSelf: "center",
                                }, children: "Delete user" })] })] }), _jsxs("div", { style: { width: "65%" }, children: [_jsxs("div", { style: {
                            width: "100%",
                            height: "15%",
                            flexWrap: "wrap",
                            backgroundColor: "#D9D9D9",
                            padding: "20px 50px",
                            display: "flex",
                            justifyContent: "space-between",
                        }, children: [_jsxs("div", { style: {
                                    width: "60%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                }, children: [_jsxs("div", { style: { display: "flex", alignItems: "center" }, children: [_jsx("label", { style: { inlineSize: "-webkit-fill-available" }, children: "User Title" }), _jsxs(Select, { size: "small", style: { backgroundColor: "#fff", height: "30px" }, fullWidth: true, value: selectedTitle || "", onOpen: () => setOpen(true), onClose: () => setOpen(false), onChange: (event) => {
                                                    setIsChanged(true);
                                                    setSelectedTitle(event.target.value);
                                                }, children: [userTitle.map((role) => (_jsx(MenuItem, { style: { backgroundColor: "#fff" }, value: role, children: _jsx(ListItemText, { primary: role }) }, role))), _jsx(Divider, {}), customUserTitle.map((title, index) => (_jsxs(MenuItem, { style: {
                                                            backgroundColor: "#fff",
                                                            color: "#01B4D2",
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }, value: title, children: [_jsx(ListItemText, { primary: title }), open && (_jsx(IconButton, { onClick: () => {
                                                                    const updated = customUserTitle.filter((item) => item !== title);
                                                                    setCustomUserTitle(updated);
                                                                }, children: _jsx(CancelIcon, { style: { color: "#FBCECE" } }) }))] }, `${title}${index}`))), _jsx(TextField, { size: "small", style: { padding: "10px" }, onKeyPress: handleInputKeyPress, placeholder: "Add new option", fullWidth: true, onClick: handleTextFieldClick, autoFocus: true })] })] }), _jsxs("div", { style: { display: "flex", alignItems: "center" }, children: [_jsx("label", { style: { inlineSize: "-webkit-fill-available" }, children: "User Role (Permission)" }), _jsx(Select, { size: "small", fullWidth: true, style: { height: "30px", backgroundColor: "white" }, value: userRoles.find((role) => role.id === selectRole)?.name || "", onChange: (event) => {
                                                    const roleId = userRoles.find((role) => role.name === event.target.value).id;
                                                    setIsChanged(true);
                                                    setSelectedRole(roleId);
                                                    handleGetPermissionByRole(roleId);
                                                }, children: userRoles.map((role) => (_jsx(MenuItem, { style: { backgroundColor: "#fff" }, value: role.name, children: _jsx(ListItemText, { primary: role.name }) }, role.id))) })] })] }), _jsxs("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    justifyContent: "flex-end",
                                    alignSelf: "end",
                                }, children: [_jsx(Button, { variant: "contained", disabled: !isChanged, style: isChanged
                                            ? {
                                                textTransform: "none",
                                                color: "white",
                                                backgroundColor: "#1A8CFF",
                                                inlineSize: "max-content",
                                            }
                                            : { textTransform: "none" }, onClick: () => {
                                            handleUpdatePermission(selectedTitle, selectRole);
                                        }, children: "Save changes" }), _jsx(Button, { variant: "contained", disabled: !isChanged, style: isChanged
                                            ? {
                                                textTransform: "none",
                                                color: "#FD0000",
                                                backgroundColor: "white",
                                                border: "#ADB3BC 1px",
                                            }
                                            : { textTransform: "none" }, onClick: () => {
                                            setSelectedTitle(userDetails?.adminInfo?.title);
                                            setSelectedRole(userDetails?.adminInfo?.userRoleId);
                                            setIsChanged(false);
                                        }, children: "Cancel" })] })] }), _jsx("div", { style: { borderBottom: "1px solid #ADB3BC", height: "85%", overflow: "auto" }, children: _jsx(LevelPermissionTable, { permission: rolePermission || [] }) })] })] }));
};
export default ViewUserDetails;
