import styled from "styled-components";
import { supportingColours } from "src/constants";
export const DurationSelectWrapper = styled.div `
  .ant-input {
    border-radius: 4px;
    overflow: hidden;
    height: 44px !important;

    font-size: 17px;
    color: ${supportingColours.TEXT_DARK_GRAY};

    border: 0 !important;

    &:focus {
      box-shadow: none;
    }
  }

  .ant-select {
    .ant-select-selector {
      border-radius: 4px;
      border: 0;
      box-shadow: none;
      height: 44px !important;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        font-size: 17px;
      }

      .ant-select-selection-placeholder {
        color: ${supportingColours.TEXT_LIGHT_GRAY};
      }

      .ant-select-selection-item {
        color: ${supportingColours.TEXT_DARK_GRAY};
      }
    }
  }

  .ant-picker {
    height: 44px !important;
    border-radius: 4px;
    border: 0;

    .ant-picker-input input {
      font-size: 17px;
      color: ${supportingColours.TEXT_DARK_GRAY};

      ::placeholder {
        color: ${supportingColours.TEXT_LIGHT_GRAY};
      }
    }
  }

  .ant-select-selection-search .ant-select-selection-search-input {
    font-size: 17px;
    color: ${supportingColours.TEXT_DARK_GRAY};

    ::placeholder {
      color: ${supportingColours.TEXT_LIGHT_GRAY};
    }
  }
`;
export const OptionsDropdownWrapper = styled.div `
  margin: -4px 0;
  padding: 6px 0px;
  border-radius: 4px;
  border: 1px solid ${supportingColours.BOX_GRAY};

  .ant-select-item-option-content {
    font-size: 16px;
    color: ${supportingColours.TEXT_DARK_GRAY};
  }
`;
export const InputWrapper = styled.div `
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
