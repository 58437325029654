import { createGlobalStyle } from "styled-components";
import theme from "./theme";
const GlobalStyles = createGlobalStyle `

  body {
    background-color: ${theme.colors.background};
    font-family: ${theme.fonts};
  }
  div.ant-typography,
  span.ant-typography,
  h1.ant-typography,
  h2.ant-typography,
  h3.ant-typography,
  h4.ant-typography,
  h5.ant-typography,
  .ant-typography p ,
  .ant-typography span ,
  .ant-typography h1,
  .ant-typography h2,
  .ant-typography h3,
  .ant-typography h4,
  .ant-typography h5, 
  p{
    margin-bottom: 0;
  }
  .ant-input,
  .ant-picker-input,
  input,
  .ant-select {
    width: 100%;
  }
`;
export default GlobalStyles;
