import { jsx as _jsx } from "react/jsx-runtime";
import { Col } from "antd";
import PropTypes from "prop-types";
import React from "react";
const SAColumn = ({ align = "center", justify = "flex-start", direction = "column", span = 24, children, ...rest }) => (_jsx(Col, { style: {
        display: "flex",
        alignItems: align,
        justifyContent: justify,
        flexDirection: direction,
    }, span: span, ...rest, children: children }));
SAColumn.propTypes = {
    align: PropTypes.oneOf(["flex-start", "flex-end", "center", "baseline", "stretch"]),
    justify: PropTypes.oneOf(["flex-start", "flex-end", "center", "space-between", "space-around"]),
    direction: PropTypes.oneOf(["row", "row-reverse", "column", "column-reverse"]),
    children: PropTypes.node.isRequired,
};
export default SAColumn;
