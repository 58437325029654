import styled from "styled-components";
export const PageWrapper = styled.div `
  .ant-table-container {
    border-radius: 8px;
    border-bottom: 15px solid #0047b3;
    border-left: 1px solid #0047b3;
    border-right: 1px solid #0047b3;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination.mini .ant-pagination-prev {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }
  li.ant-pagination-item.ant-pagination-item-active {
    background: #adb3bc;
  }
  .ant-pagination.mini .ant-pagination-item {
    min-width: 24px;
    height: 24px;
    margin: 0;
  }
  li.ant-pagination-item {
    border: 1px solid #ebedf0 !important;
    margin-left: 14px !important;
    width: 28px !important;
    height: 28px !important;
  }
  .ant-pagination.mini .ant-pagination-next {
    border: 1px solid #ebedf0;
    margin-left: 14px !important;
    height: 28px;
    width: 28px;
  }
  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px;
  }
  li.ant-pagination-item.ant-pagination-item-active a {
    color: white;
  }
  li.ant-pagination-item a {
    color: #367893;
    font-size: 15px;
    padding-top: 3px;
  }
  .ant-pagination-item a {
    padding: 0px;
  }
  .ant-pagination.mini .ant-pagination-prev {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }
  .ant-pagination.mini .ant-pagination-next {
    border: 1px solid #ebedf0;
    margin-left: 14px !important;
    height: 28px;
    width: 28px;
  }
  .ant-table-pagination.ant-pagination {
    margin-bottom: 0px;
    padding: 0 55px;
  }
`;
