import { createSlice } from "@reduxjs/toolkit";
import { createAppointment, getAppointmentById, getAppointmentFromTime } from "./action";
const inClinicAppointmentsSlice = createSlice({
    name: "appointment",
    initialState: {
        isLoading: false,
        error: null,
        appointment: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAppointmentFromTime.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getAppointmentFromTime.fulfilled, (state) => {
            state.isLoading = false;
            state.error = null;
        });
        builder.addCase(createAppointment.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(createAppointment.fulfilled, (state) => {
            state.isLoading = false;
            state.error = null;
        });
        builder.addCase(getAppointmentById.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getAppointmentById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = null;
            state.appointment = action.payload.data;
        });
    },
});
export default inClinicAppointmentsSlice.reducer;
