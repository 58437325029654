import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { DatePicker } from "antd";
import { RightDataWrapper } from "src/pages/DashboardPage/components/RightData/index.style";
const RightData = ({ onFromDate, onToDate, children }) => {
    const [fromDate, setFromDate] = useState(null);
    // Disable dates function
    const disabledDate = (current) => current && current < fromDate;
    return (_jsxs(RightDataWrapper, { children: [_jsxs("div", { className: "right-data-header", children: [_jsx(DatePicker, { size: "small", placeholder: "From", name: "date_from", format: "DD/MM/YYYY", onChange: (date) => {
                            onFromDate(date);
                            setFromDate(date);
                        } }), _jsx(DatePicker, { style: { marginTop: 10 }, size: "small", placeholder: "To", format: "DD/MM/YYYY", name: "date_to", onChange: (date) => {
                            onToDate(date);
                        }, disabledDate: disabledDate })] }), _jsx("div", { style: { padding: "15px 21px 21px 11px" }, children: children })] }));
};
export default RightData;
