import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import { Wrapper } from "./index.style";
export const Message = (props) => {
    const { isOpen } = props;
    const { elmParentId } = props;
    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                addModal();
            }, 100);
        }
    }, [isOpen]);
    const addModal = () => {
        const para = document.getElementById(elmParentId);
        para?.setAttribute("style", `z-index: 1001`);
    };
    return isOpen ? (_jsx(Wrapper, { id: elmParentId, children: _jsx("div", { className: "popup-content", children: isOpen && props.children }) })) : null;
};
