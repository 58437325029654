/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { $delete, $get, $post, $put } from "src/infra/http";
const getListClientLocations = createAsyncThunk("getListClientLocations", async (onsiteClientId) => await $get(`/collection-manager/client-management/location/${onsiteClientId}`));
const getListArchivedClientLocations = createAsyncThunk("getListArchivedClientLocations", async (onsiteClientId) => await $get(`/collection-manager/client-management/location/archived/${onsiteClientId}`));
const archiveClientLocation = createAsyncThunk("archiveClientLocation", async (locationId) => await $get(`/collection-manager/client-management/location/archiveLocation/${locationId}`));
const restoreClientLocation = createAsyncThunk("restoreClientLocation", async (locationId) => await $get(`/collection-manager/client-management/location/restoreLocation/${locationId}`));
const getLocationDetail = createAsyncThunk("getLocationDetail", async (id) => await $get(`/collection-manager/client-management/location/detail/${id}`));
const deleteClientLocationContact = createAsyncThunk("deleteClientLocationContact", async (contactId) => await $delete(`/collection-manager/client-management/location/contact/${contactId}`));
const createClientLocationDetails = createAsyncThunk("createClientLocationDetails", async (data) => await $post("/collection-manager/client-management/location/create", data));
const updateClientLocationDetails = createAsyncThunk("updateClientLocationDetails", async (data) => await $put("/collection-manager/client-management/location/update", data));
export { getListClientLocations, getListArchivedClientLocations, archiveClientLocation, restoreClientLocation, getLocationDetail, deleteClientLocationContact, createClientLocationDetails, updateClientLocationDetails, };
