import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { createNote, deleteNote, getListLocations, getListNotes, getNoteHistory, updateNote, } from "./action";
export const initialState = {
    listNotes: [],
    listClientLocationsData: [],
    selectedLocation: { id: "", siteName: "All" },
    selectedNote: null,
    noteHistory: [],
    createNoteFlag: true,
};
const NotesSlice = createSlice({
    name: "notes",
    initialState,
    reducers: {
        setCurrentLocation(state, action) {
            state.selectedLocation = action.payload;
        },
        setSelectedNote(state, action) {
            state.selectedNote = action.payload;
        },
        setCreateNoteFlag(state, action) {
            state.selectedNote = null;
            state.createNoteFlag = action.payload;
        },
        setResetStore() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListNotes.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.listNotes = initialState.listNotes;
                state.selectedNote = null;
                state.createNoteFlag = true;
                message.error("Failed to fetch list of client notes");
            }
            else {
                state.createNoteFlag = true;
                state.selectedNote = null;
                state.listNotes = action.payload.data;
            }
        })
            .addCase(getListNotes.rejected, (state) => {
            state.createNoteFlag = true;
            state.selectedNote = null;
            message.error("Failed to fetch list of client notes");
        })
            .addCase(getListLocations.fulfilled, (state, action) => {
            if (action.payload.data) {
                state.listClientLocationsData = [{ id: "", siteName: "All" }, ...action.payload.data];
            }
        })
            .addCase(getListLocations.rejected, (state) => {
            message.error("Failed to fetch collection points");
            state.listClientLocationsData = [];
        })
            .addCase(createNote.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to create note");
            }
            else {
                message.success("Note created successfully");
            }
        })
            .addCase(createNote.rejected, () => {
            message.error("Failed to create note");
        })
            .addCase(updateNote.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to update note");
            }
            else {
                message.success("Note updated successfully");
            }
        })
            .addCase(updateNote.rejected, () => {
            message.error("Failed to update note");
        })
            .addCase(deleteNote.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to delete note");
            }
            else {
                message.success("Note deleted successfully");
            }
        })
            .addCase(deleteNote.rejected, () => {
            message.error("Failed to delete note");
        })
            .addCase(getNoteHistory.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.noteHistory = initialState.noteHistory;
                message.error("Failed to fetch history data");
            }
            else {
                state.noteHistory = action.payload.data;
            }
        })
            .addCase(getNoteHistory.rejected, () => {
            message.error("Failed to fetch history data");
        });
    },
});
export const { setResetStore, setCurrentLocation, setSelectedNote, setCreateNoteFlag } = NotesSlice.actions;
export default NotesSlice.reducer;
