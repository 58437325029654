import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { PageWrapper } from "./index.style";
export const CheckBox = (props) => {
    const { iconWidth, iconHeight, iconColor } = props;
    const isChecked = props.isCheck;
    const [isCheck, setIsCheck] = useState(false);
    const onCheck = () => {
        if (props.disabled) {
            return;
        }
        setIsCheck(!isCheck);
        if (props.onChange) {
            props.onChange(!isCheck);
        }
    };
    useEffect(() => {
        setIsCheck(isChecked);
    }, [isChecked]);
    return (_jsx(PageWrapper, { className: props.className, children: _jsx("div", { className: `tick-box${props.disabled && " disabled"}`, style: {
                ...props.style,
            }, onClick: onCheck, children: isCheck && (_jsx("svg", { style: { ...props.iconStyle }, xmlns: "http://www.w3.org/2000/svg", width: iconWidth || "10.681", height: iconHeight || "10.562", viewBox: "0 0 10.681 10.562", children: _jsx("path", { id: "Vector", d: "M9.424,9.462,0,3.764,4.542,0", transform: "translate(0.578 9.812) rotate(-90)", fill: "none", stroke: iconColor || "#156cc9", strokeLinejoin: "round", strokeWidth: "1.5" }) })) }) }));
};
