/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from "react";
const ContentContext = createContext({
    messageBox: null,
    openMessageBox: (type, message, callback = null) => { },
    closeMessageBox: () => { },
    popupBox: null,
    openPopupBox: (popup, callback = null, popupProps = null) => { },
    closePopupBox: (index) => { },
    loadingScreen: null,
    openLoadingScreen: () => { },
    closeLoadingScreen: () => { },
    bodyPopupBox: null,
    openBodyPopupBox: (popup, props, callback = null) => { },
    closeBodyPopupBox: () => { },
    openToast: (message, options) => { },
});
export default ContentContext;
