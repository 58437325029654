export const mainAppColours = {
    BLUE: "#0047b3",
    BLUE_2: "#01B4D2",
    MID_BLUE: "#1a8cff",
    LIGHT_BLUE: "#9ec2ff",
    LIGHT_BLUE_2: "#EAFAFF",
};
export const SAColourScheme = {
    NAVY: "#00004c",
    GRAY_BLUE: "#4b5767",
    PLASSMA: "#01b4d2",
    PLASSMA10: "#01b4d210",
    MID_BLUE: "#156cc9",
    RED: "#E62129",
    BLACK: "#332C2B",
    WHITE: "#ffffff",
    LIGHT_BLUE: "#C9EEF8",
    ODD_ROW: "#F7FCFD",
};
export const supportingColours = {
    BACKGROUND_GRAY: "#EEF5F6",
    BOX_GRAY: "#ADB3BC",
    BOX_LIGHT: "#EBEDF0",
    TEXT_LIGHT_GRAY: "#7F8E9D",
    TEXT_MID_GRAY: "#1A576F",
    TEXT_DARK_GRAY: "#2f3337",
};
