import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Calendar } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import enGB from "antd/lib/calendar/locale/en_GB"; // Import the locale object
import { Image, Row, Text } from "src/components/base";
import { SAColourScheme } from "src/constants";
import { CalendarHeaderWrapper, HomeCalendarWrapper } from "./style";
export const HomeCalendar = ({ onDateChange, currentDate, calendarProps, }) => {
    const [selectedMonth, setSelectedMonth] = useState(currentDate.month());
    const [selectedYear, setSelectedYear] = useState(currentDate.year());
    const headerRender = ({ value, onChange, }) => {
        const current = value.clone();
        const currentMonth = moment(value).format("MMMM");
        const handleClick = (increase) => {
            const newValue = value.clone();
            newValue.month(current.month() + (increase ? 1 : -1));
            onChange(newValue);
        };
        return (_jsxs(CalendarHeaderWrapper, { children: [_jsx(Button, { className: "change-button", onClick: () => handleClick(false), children: _jsx(Image, { src: "/assets/svg/ic-arrow-left.svg", preview: false }) }), _jsx(Text, { className: "current-month", name: currentMonth }), _jsx(Button, { className: "change-button", onClick: () => handleClick(true), children: _jsx(Image, { src: "/assets/svg/ic-arrow-right.svg", preview: false }) })] }));
    };
    const dateCellRender = (value) => {
        const date = moment(value).format("DD");
        return value.month() === selectedMonth ? (_jsx("div", { className: "ant-picker-cell-inner", children: date })) : null;
    };
    const handleTodayButtonClick = () => {
        const today = moment();
        setSelectedMonth(today.month());
        setSelectedYear(today.year());
        onDateChange(today);
    };
    useEffect(() => {
        setSelectedMonth(currentDate.month());
        setSelectedYear(currentDate.year());
    }, [currentDate]);
    return (_jsxs(HomeCalendarWrapper, { children: [_jsxs("div", { style: {
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                    gap: "15px",
                    height: "5rem",
                    flexDirection: "column",
                    position: "relative",
                }, children: [_jsx(Text, { className: "calendar-title", name: "Calendar" }), _jsxs(Button, { className: "today-button", onClick: handleTodayButtonClick, style: {
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            background: "rgba(255, 255, 255, 0.2)",
                            color: "#FFFFFF",
                            borderRadius: "13px",
                            border: "none",
                            padding: "0.8rem",
                            bottom: "0.5rem",
                            right: "0.5rem",
                        }, children: [_jsx(Image, { src: "/assets/svg/today-icon.svg", style: { width: 12, marginRight: 5 }, preview: false }), "Today"] })] }), _jsx(Calendar, { ...calendarProps, locale: {
                    ...enGB,
                    lang: { ...enGB.lang, shortWeekDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] },
                }, fullscreen: false, headerRender: headerRender, dateFullCellRender: dateCellRender, value: currentDate, onChange: (date) => {
                    if (date) {
                        setSelectedMonth(date.month());
                        setSelectedYear(date.year());
                        onDateChange(date);
                    }
                } }), _jsxs(Row, { className: "calendar-year", style: { width: "100%" }, children: [_jsx("div", { className: "dot", style: { background: SAColourScheme.PLASMA } }), _jsx("div", { className: "dot", style: { background: SAColourScheme.MID_BLUE } }), _jsx("div", { className: "dot", style: { background: SAColourScheme.NAVY } }), _jsx(Text, { name: `${selectedYear}` }), _jsx("div", { className: "dot", style: { background: SAColourScheme.NAVY } }), _jsx("div", { className: "dot", style: { background: SAColourScheme.MID_BLUE } }), _jsx("div", { className: "dot", style: { background: SAColourScheme.PLASMA } })] })] }));
};
