import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Breadcrumbs, Card, Divider, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, message, TextInput } from "src/components/base";
import { UserAction } from "src/pages/UserProfilePage";
import { changePassword } from "src/pages/UserProfilePage/store/action";
import { getCustomTokenId } from "src/pages/UserProfilePage/store/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { passwordStrengthLevel } from "src/utils/helper";
const PasswordUpdatePage = ({ onStatusUpdate }) => {
    const dispatch = useAppDispatch();
    const customTokenId = useAppSelector(getCustomTokenId);
    const [isShowNewPwd, setIsShowNewPwd] = useState(false);
    const [newPwd, setNewPwd] = useState("");
    const [secondPwd, setSecondPwd] = useState("");
    const [isShowSecondPwd, setIsShowSecondPwd] = useState(false);
    const [pwdLevel, setPwdLevel] = useState("");
    const [errorText, setErrorText] = useState("");
    const navigate = useNavigate();
    const strengthColor = (level) => {
        if (level == "Weak")
            return "#FD0000";
        else if (level == "Medium")
            return "#007AFF";
        else if (level == "Strong")
            return "#35B700";
    };
    const passwordStrength = () => (_jsxs("div", { className: "sub-text", style: { color: "black", marginTop: "3px", fontSize: "14px", marginBottom: "16px" }, children: ["Password strength:\u00A0", _jsx("span", { style: { color: strengthColor(pwdLevel) }, children: pwdLevel })] }));
    const handleBreadCrumbClick = () => {
        onStatusUpdate(UserAction.MAIN_PAGE);
    };
    const handleUpdatePassword = async () => {
        if (!customTokenId) {
            message.error("Custom token id is missing");
            return;
        }
        const result = await dispatch(changePassword({ customTokenId, password: newPwd }));
        // @ts-expect-error: remove this later
        if (result?.payload?.status == "success") {
            message.success("Password updated successfully");
            navigate("/");
        }
    };
    const onTypingNewPwd = (value) => {
        setNewPwd(value);
        setPwdLevel(passwordStrengthLevel(value));
    };
    const onTypingSecondPwd = (value) => {
        setSecondPwd(value);
        if (newPwd !== value) {
            setErrorText("Password does not match");
        }
        else {
            setErrorText("");
        }
    };
    const breadcrumbs = [
        _jsx(Link, { underline: "hover", color: "inherit", onClick: handleBreadCrumbClick, children: "User Profile" }, "1"),
        _jsx(Typography, { color: "text.primary", children: "Password" }, "2"),
    ];
    return (_jsxs("div", { style: {
            display: "flex",
            flexDirection: "column",
            gridTemplateColumns: "60% 40%",
            gap: "5vh",
            backgroundColor: "#F5F6F8",
            padding: "2% 6%",
            height: "100%",
        }, children: [_jsx(Breadcrumbs, { separator: _jsx(NavigateNextIcon, { fontSize: "small" }), "aria-label": "breadcrumb", children: breadcrumbs }), _jsxs(Card, { style: { backgroundColor: "white" }, children: [_jsxs("div", { style: {
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            height: "60px",
                            alignItems: "center",
                            gap: "15px",
                            padding: "2%",
                        }, children: [_jsx(NavigateBeforeIcon, {}), _jsx(Typography, { variant: "h5", children: "Password" })] }), _jsx(Divider, {}), _jsxs("div", { style: { padding: "6%", display: "flex", flexDirection: "column", gap: "10px" }, children: [_jsx(Typography, { children: "Choose a strong password and please don\u2019t reuse it for other accounts." }), _jsx(Typography, { children: "Changing your password will automatically sign you our of all devices." }), _jsxs(Card, { variant: "outlined", style: {
                                    padding: "8%",
                                    color: "#156CC9",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }, children: [_jsx("label", { children: "New Password" }), _jsx(TextInput, { rightIcon: _jsx("img", { src: "/assets/svg/ic-look.svg", style: { marginTop: "-3px", marginRight: "10px" }, onClick: () => setIsShowNewPwd(!isShowNewPwd) }), value: newPwd, onChange: onTypingNewPwd, placeholder: "Enter new password", type: isShowNewPwd ? "text" : "password" }), passwordStrength(), _jsx("label", { children: "Confirm New Password" }), _jsx(TextInput, { className: "pwd-input", style: { marginTop: "3px" }, rightIcon: _jsx("img", { src: "/assets/svg/ic-look.svg", style: { marginTop: "-3px", marginRight: "10px" }, onClick: () => setIsShowSecondPwd(!isShowSecondPwd) }), value: secondPwd, onChange: onTypingSecondPwd, placeholder: "Re-type password", type: isShowSecondPwd ? "text" : "password" }), _jsx("div", { className: "error-text", style: { marginTop: "3px", textAlign: "left", color: "#FD0000" }, children: errorText }), _jsx(Button, { onClick: handleUpdatePassword, style: {
                                            alignSelf: "flex-end",
                                            color: "white",
                                            background: "#1A8CFF",
                                            borderRadius: "8px",
                                            border: "2px solid #FFFFFF",
                                        }, children: "Change Password" })] })] })] })] }));
};
export default PasswordUpdatePage;
