import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Image } from "antd";
import { useState } from "react";
import { TextInput } from "src/components/base";
const ConfirmToDeletePopUp = ({ handleDeleteAccount, email, closePopupBox, }) => {
    const [confirmedEmail, setConfirmedEmail] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const onTypingConfirmedEmail = (value) => {
        setConfirmedEmail(value);
    };
    const onHandleDeleteRequest = () => {
        if (confirmedEmail !== email) {
            setErrorMsg("Email does not match.");
        }
        else {
            setErrorMsg("");
            closePopupBox();
            handleDeleteAccount(confirmedEmail);
        }
    };
    return (_jsxs("div", { style: {
            backgroundColor: "#FFFFFF",
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignContent: "center",
            flexWrap: "wrap",
            gap: "4vh",
            paddingBottom: "10%",
        }, children: [_jsx(Typography, { variant: "h5", style: {
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#FD0000",
                    color: "white",
                    padding: "10px",
                }, children: "Confirm to delete" }), _jsxs("div", { style: {
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    // flexWrap: 'nowrap',
                    gap: "1vh",
                    alignItems: "center",
                }, children: [_jsx(Image, { src: "/assets/svg/ic-user-to-delete.svg", preview: false }), _jsx(Typography, { children: "Your data cannot be recovered once deleted." }), _jsx(Typography, { children: "We will send you an email to complete the process." }), _jsxs("div", { style: { margin: "5%" }, children: [_jsx("p", { style: { color: "#2A44FF" }, children: "Email of the account you wish to delete" }), _jsx(TextInput, { value: confirmedEmail, placeholder: "Email address", type: "text", onChange: onTypingConfirmedEmail }), _jsx("p", { style: { color: "#FD0000" }, children: errorMsg })] }), _jsx(Button, { onClick: onHandleDeleteRequest, variant: "contained", style: {
                            textTransform: "none",
                            color: "white",
                            background: "#FD0000",
                            marginTop: "20px",
                        }, children: "Confirm" })] })] }));
};
export default ConfirmToDeletePopUp;
