import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Space, Table } from "antd";
import { FooterTableWrapper } from "./index.style";
// Few fields modified based on the device type
export const DeviceByLocationTable = ({ locationData }) => {
    const columns = [
        {
            title: "Current Available Stock",
            dataIndex: "type",
            key: "type",
            sorter: (a, b) => {
                if (a.key === locationData.length - 1 || b.key === locationData.length - 1) {
                    return 0; // Don't perform sorting for the last row
                }
                return a.type.localeCompare(b.type);
            },
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Locations",
            dataIndex: "locations",
            key: "locations",
            sorter: (a, b) => {
                if (a.key === locationData.length - 1 || b.key === locationData.length - 1) {
                    return 0; // Don't perform sorting for the last row
                }
                return a.locations - b.locations; // Compare numeric values
            },
            render: (text) => _jsxs("span", { children: [text, " Locations"] }),
        },
        {
            title: "Number",
            dataIndex: "number",
            key: "number",
            sorter: (a, b) => {
                if (a.key === locationData.length - 1 || b.key === locationData.length - 1) {
                    return 0; // Don't perform sorting for the last row
                }
                return a.number - b.number; // Compare numeric values
            },
            render: (text) => _jsx("span", { children: text }),
        },
    ];
    return (_jsxs(FooterTableWrapper, { children: [_jsx("div", { style: { fontSize: "16px", padding: "10px 15px" }, children: _jsx("span", { children: "Summary" }) }), _jsx("div", { children: _jsx(Table, { columns: columns, dataSource: locationData?.length > 0
                        ? locationData.map((item, index) => ({ ...item, key: index }))
                        : [], pagination: false, scroll: { x: 900, y: 650 }, style: {
                        border: "1px solid #D9D9D9",
                        overflow: "hidden",
                    }, rowClassName: (record, index) => (index === locationData.length - 1 ? "last-row" : "") }) })] }));
};
