import { getApplicationInformation } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { cancelAppointment, createAppointment, getAppointmentById, resendEmailAppointment, restoreAppointment, updateAppointment, } from "./store/action";
export const useCommonDetails = () => {
    const appDetail = useAppSelector(getApplicationInformation);
    return {
        collectionOrganization: appDetail.collectionOrganization,
        collectionPoints: appDetail.collectionPoints,
        user: appDetail.user,
        testTypes: appDetail.testTypes,
        testCategories: appDetail.testCategories,
    };
};
export const useCreateAppointment = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.inclinicAppointment.isLoading);
    return {
        createAppointment: (body) => dispatch(createAppointment(body)),
        isLoadingCreatingAppointment: isLoading,
    };
};
export const useGetAppointmentById = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.inclinicAppointment.isLoading);
    const appointment = useAppSelector((state) => state.inclinicAppointment.appointment);
    return {
        getAppointmentById: (appointmentId) => dispatch(getAppointmentById(appointmentId)),
        isLoadingGettingAppointment: isLoading,
        appointment,
    };
};
export const useCancelAppointment = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.inclinicAppointment.isLoading);
    return {
        cancelAppointment: (appointmentId) => dispatch(cancelAppointment(appointmentId)),
        isLoadingCancellingAppointment: isLoading,
    };
};
export const useResendEmailAppointment = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.inclinicAppointment.isLoading);
    return {
        resendEmailAppointment: (appointmentId) => dispatch(resendEmailAppointment(appointmentId)),
        isLoadingResendingEmail: isLoading,
    };
};
export const useRestoreAppointment = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.inclinicAppointment.isLoading);
    return {
        restoreAppointment: (appointmentId) => dispatch(restoreAppointment(appointmentId)),
        isLoadingRestoring: isLoading,
    };
};
export const useUpdateAppointment = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.inclinicAppointment.isLoading);
    return {
        updateAppointmentById: (appointmentId, body) => dispatch(updateAppointment({ appointmentId, body })),
        isLoadingUpdating: isLoading,
    };
};
