import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { Divider } from "antd";
import PropTypes from "prop-types";
const SADivider = ({ color, ...rest }) => (_jsx(StyledDivider, { color: color, ...rest }));
SADivider.propTypes = {
    color: PropTypes.string,
};
export default SADivider;
const StyledDivider = styled(Divider) `
  border: 0.11rem solid ${(props) => props.color || "rgb(1, 180, 210)"};
  margin-block: 0.5rem;
`;
