import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Col, Image, Row, Typography } from "src/components/base";
import { SAColourScheme } from "src/constants";
import { DropDownWrapper } from "../../index.style";
import { LocationInventoryTable } from "./LocationInventoryTable";
const { Text } = Typography;
export const LocationInventory = ({ inventoryData }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    return (_jsx(_Fragment, { children: _jsxs(DropDownWrapper, { children: [_jsx("div", { style: { marginBottom: "10px", marginTop: "25px", cursor: "pointer" }, onClick: handleToggleCollapse, children: _jsxs(Row, { gutter: [20, 10], style: {
                            background: `${SAColourScheme.PLASMA}`,
                            padding: "10px",
                            alignItems: "center",
                            borderTopLeftRadius: "10px",
                            borderTopRightRadius: "10px",
                        }, children: [_jsx(Col, { span: 1, children: _jsx("div", { style: {
                                        marginRight: "8px",
                                        transition: "transform 0.3s ease",
                                        color: "#FFFFFF",
                                    }, children: isCollapsed ? _jsx(RightOutlined, {}) : _jsx(DownOutlined, {}) }) }), _jsx(Col, { span: 1, children: _jsx(Image, { src: "/assets/svg/inventory/ic-device-type.svg", preview: false }) }), _jsx(Col, { xs: 18, md: 21, children: _jsx(Text, { style: { color: `${SAColourScheme.WHITE}`, fontSize: "16px" }, children: "Device Inventory by Location" }) })] }) }), _jsx("div", { className: `collapsible-div ${isCollapsed ? "collapsed" : ""}`, children: inventoryData.map((item, index) => (_jsx(LocationInventoryTable, { inventoryData: [item], index: (index + 1).toString().padStart(2, "0") }, index))) })] }) }));
};
