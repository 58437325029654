import styled from "styled-components";
export const PageWrapper = styled.div `
  font-family:
    Roboto,
    -apple-system;

  .id-image {
    background: #e4f6fb;
    text-align: center;
    position: relative;
    border-radius: 4px;
  }

  .id-image > div.ant-image {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
