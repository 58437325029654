import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Dropdown, Image, Menu, Space, Table } from "antd";
import { useContext, useState } from "react";
import { MESSAGE_BOX_TYPE, SAColourScheme, mainAppColours } from "src/constants";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
import ContentContext from "src/providers/content";
export const Archieve = ({ data, supplierRestore, supplierDelete }) => {
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Supplier Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Account Manager",
            dataIndex: "accountManager",
            key: "accountManager",
            sorter: (a, b) => a.accountManager.localeCompare(b.accountManager),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Contact Mobile",
            dataIndex: "contactMobile",
            key: "contactMobile",
            sorter: (a, b) => a.contactMobile.localeCompare(b.contactMobile),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(_Fragment, { children: text }),
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            sorter: (a, b) => a.address.localeCompare(b.address),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Contract",
            dataIndex: "contractFileUrl",
            key: "contractFileUrl",
            render: (record) => (_jsx(Dropdown, { overlay: getMenu(), trigger: ["click"], children: _jsx("div", { style: {
                        padding: "0px",
                        border: "none",
                        background: "none",
                    }, children: record !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) }) })),
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(Menu, { style: {
                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                        width: "150px",
                    }, children: [_jsx(Menu.Item, { onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT_BLUE, `${record.name} - Supplier will be restored.`, () => {
                                    supplierRestore(record.id);
                                });
                            }, children: "Restore" }, "1"), _jsx(Menu.Item, { style: {
                                borderTop: "1px solid #ADB3BC",
                                width: "80%",
                                margin: "0 auto",
                            }, onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${record.name} - will be removed permanently?`, () => {
                                    supplierDelete(record.id);
                                });
                            }, children: _jsx("span", { style: { display: "flex", justifyContent: "flex-end" }, children: "Delete" }) }, "2")] }), trigger: ["click"], children: _jsx("div", { className: "menu-options", children: _jsx(ICMenuOptions, {}) }) })),
        },
    ];
    const [tableVisible, setTableVisible] = useState(false);
    const { openMessageBox } = useContext(ContentContext);
    const getMenu = () => (_jsx(Menu, { style: { padding: "10px 10px" }, children: _jsx(Menu.Item, { style: {
                border: "1px solid #ADB3BC",
                borderRadius: "4px",
                padding: "4px 8px",
                margin: "10px 15px",
            }, children: "View File" }, "view") }));
    const handleButtonClick = () => {
        setTableVisible(!tableVisible);
    };
    return (_jsxs("div", { style: { marginTop: 25 }, children: [_jsxs(Button, { onClick: handleButtonClick, style: {
                    border: `1px solid #D9D9D9`,
                    borderRadius: "4px",
                    marginBottom: 10,
                    background: `${SAColourScheme.PLASSMA10}`,
                }, children: [_jsx("span", { style: { color: `${mainAppColours.MID_BLUE}` }, children: tableVisible ? "Hide Supplier Archive" : "Show Supplier Archive" }), _jsx("span", { style: { marginLeft: 12 }, children: tableVisible ? (_jsx(Image, { src: "/assets/svg/minus-icon.svg", preview: false })) : (_jsx(Image, { src: "/assets/svg/ic-history-add.svg", preview: false })) })] }), _jsx("div", { style: {
                    borderBottom: "2px solid #01B4D2",
                    marginBottom: "20px",
                } }), tableVisible && _jsx("span", { style: { fontSize: "16px", color: "#000000" }, children: "Supplier History" }), _jsx(Table, { columns: columns, dataSource: data.length > 0 ? data.map((item) => ({ ...item, key: item.id })) : [], pagination: false, scroll: { x: 900, y: 650 }, style: {
                    border: "1px solid #D9D9D9",
                    visibility: tableVisible ? "visible" : "hidden",
                    height: tableVisible ? "100%" : "0",
                    overflow: "hidden",
                    marginTop: "10px",
                }, footer: () => "" })] }));
};
