import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import moment from "moment";
import { archiveJob, cancelJob, createDraftOnsiteJob, createOnsiteJob, getClientDetail, getClientDetailInitial, getCollectionPointDefaultData, getJobDetail, getListActiveJobs, getListArchiveJobs, getListClients, restoreJob, updateDraftOnsiteJob, updateOnsiteJob, } from "./action";
import { initialRequestExistedOnsiteJobData, OnsiteJobStatus, RequestOnsiteJobInitialData, ResponseClientDefaultInitialData, } from "./types";
export const initialState = {
    startDate: null,
    endDate: null,
    listActiveJobs: [],
    listArchiveJobs: [],
    listClients: [],
    listCollectionPointDefaultData: [],
    listCollectors: [],
    isArchived: false,
    selectedClientDetails: ResponseClientDefaultInitialData,
    jobSetupDetails: {
        data: RequestOnsiteJobInitialData,
        status: false,
        error: undefined,
    },
    existedJobData: initialRequestExistedOnsiteJobData,
    selectedCollectionPoint: { id: "", collectionPointName: "All" },
    selectedClient: { id: "", name: "All" },
    selectedOnSiteJobId: "",
    selectedStatusType: [
        OnsiteJobStatus.Draft,
        OnsiteJobStatus.Planned,
        OnsiteJobStatus.InProgress,
        OnsiteJobStatus.Completed,
        OnsiteJobStatus.Cancelled,
    ],
    listStatusTypes: [
        {
            label: "Draft",
            value: OnsiteJobStatus.Draft,
        },
        {
            label: "Planned",
            value: OnsiteJobStatus.Planned,
        },
        {
            label: "In Progress",
            value: OnsiteJobStatus.InProgress,
        },
        {
            label: "Completed",
            value: OnsiteJobStatus.Completed,
        },
        {
            label: "Cancelled",
            value: OnsiteJobStatus.Cancelled,
        },
    ],
    pagingMetaData: null,
    pagingMetaDataArchive: null,
};
const jobManagementSlice = createSlice({
    name: "jobManagement",
    initialState,
    reducers: {
        setStartDate(state, action) {
            state.startDate = action.payload;
        },
        setEndDate(state, action) {
            state.endDate = action.payload;
        },
        setOnsiteJobId(state, action) {
            state.selectedOnSiteJobId = action.payload;
        },
        setIsArchiveStatus(state, action) {
            state.isArchived = action.payload;
        },
        setJobSetupDetails(state, action) {
            state.jobSetupDetails.data = action.payload;
        },
        setCurrentCollectionPoint(state, action) {
            state.listActiveJobs = initialState.listActiveJobs;
            state.listArchiveJobs = initialState.listArchiveJobs;
            state.selectedCollectionPoint = action.payload;
        },
        setDropdownSelectedClient(state, action) {
            state.selectedClient = action.payload;
        },
        setSelectedStatusTypes(state, action) {
            state.selectedStatusType = action.payload;
        },
        setResetStore() {
            return initialState;
        },
        setResetJobDetail(state) {
            state.jobSetupDetails = initialState.jobSetupDetails;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListActiveJobs.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.listActiveJobs = initialState.listActiveJobs;
                message.error("Failed to fetch list of jobs");
            }
            else {
                state.pagingMetaData = action.payload.pagingMeta;
                state.listActiveJobs = action.payload.data;
            }
        })
            .addCase(getListActiveJobs.rejected, () => {
            message.error("Failed to fetch list of active jobs");
        })
            .addCase(getListArchiveJobs.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch list of archive jobs");
                state.listArchiveJobs = initialState.listArchiveJobs;
            }
            else {
                state.pagingMetaDataArchive = action.payload.pagingMeta;
                state.listArchiveJobs = action.payload.data;
            }
        })
            .addCase(getListArchiveJobs.rejected, () => {
            message.error("Failed to fetch list of archive jobs");
        })
            .addCase(getCollectionPointDefaultData.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch list of collection point default data");
                state.listCollectionPointDefaultData = initialState.listCollectionPointDefaultData;
            }
            else {
                state.listCollectionPointDefaultData = action.payload.data;
            }
        })
            .addCase(getCollectionPointDefaultData.rejected, () => {
            message.error("Failed to fetch list of collection point default data");
        })
            .addCase(getListClients.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch list of collection points");
                state.listClients = initialState.listClients;
            }
            else {
                state.listClients = action.payload.data;
            }
        })
            .addCase(getListClients.rejected, () => {
            message.error("Failed to fetch list of client");
        })
            .addCase(getClientDetail.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch client detail");
                state.selectedClientDetails = ResponseClientDefaultInitialData;
            }
            else {
                state.selectedClientDetails = action.payload.data;
            }
        })
            .addCase(getClientDetail.rejected, (state) => {
            message.error("Failed to fetch client detail");
            state.selectedClientDetails = ResponseClientDefaultInitialData;
        })
            .addCase(getClientDetailInitial.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch client detail");
                state.selectedClientDetails = ResponseClientDefaultInitialData;
            }
            else {
                state.selectedClientDetails = action.payload.data;
            }
        })
            .addCase(getClientDetailInitial.rejected, () => {
            message.error("Failed to fetch client detail");
        })
            .addCase(createOnsiteJob.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to create a new job");
                state.jobSetupDetails.status = false;
                state.jobSetupDetails.error = action.payload.message;
            }
            else {
                message.success("Job created successfully");
                state.jobSetupDetails.status = true;
                state.jobSetupDetails.error = undefined;
                state.selectedClientDetails = ResponseClientDefaultInitialData;
                state.jobSetupDetails.data = RequestOnsiteJobInitialData;
                state.selectedOnSiteJobId = action.payload.data;
            }
        })
            .addCase(createOnsiteJob.rejected, (state, action) => {
            message.error("Failed to create a new job");
            state.jobSetupDetails.status = true;
            state.jobSetupDetails.error = action.payload;
        })
            .addCase(createDraftOnsiteJob.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to save draft");
                state.jobSetupDetails.status = false;
                state.jobSetupDetails.error = action.payload.message;
            }
            else {
                message.success("Draft saved successfully");
                state.jobSetupDetails.status = true;
                state.jobSetupDetails.error = undefined;
                state.selectedClientDetails = ResponseClientDefaultInitialData;
                state.jobSetupDetails.data = RequestOnsiteJobInitialData;
                state.selectedOnSiteJobId = action.payload.data;
            }
        })
            .addCase(createDraftOnsiteJob.rejected, (state, action) => {
            message.error("ailed to save draft");
            state.jobSetupDetails.status = true;
            state.jobSetupDetails.error = action.payload;
        })
            .addCase(updateOnsiteJob.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to update a new job");
                state.jobSetupDetails.status = false;
                state.jobSetupDetails.error = action.payload.message;
            }
            else {
                message.success("Job updated successfully");
                state.jobSetupDetails.status = true;
                state.jobSetupDetails.error = undefined;
            }
        })
            .addCase(updateOnsiteJob.rejected, (state, action) => {
            message.error("Failed to update a new job");
            state.jobSetupDetails.status = false;
            state.jobSetupDetails.error = action.payload;
        })
            .addCase(updateDraftOnsiteJob.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to update the job draft");
                state.jobSetupDetails.status = false;
                state.jobSetupDetails.error = action.payload.message;
            }
            else {
                message.success("Job draft updated successfully");
                state.jobSetupDetails.status = true;
                state.jobSetupDetails.error = undefined;
            }
        })
            .addCase(updateDraftOnsiteJob.rejected, (state, action) => {
            message.error("Failed to update the job draft");
            state.jobSetupDetails.status = false;
            state.jobSetupDetails.error = action.payload;
        })
            .addCase(cancelJob.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to cancel the job");
            }
            else {
                message.success("Job cancelled successfully");
            }
        })
            .addCase(cancelJob.rejected, () => {
            message.error("Failed to cancel the job");
        })
            .addCase(restoreJob.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to restore the job");
            }
            else {
                message.success("Job restored successfully");
            }
        })
            .addCase(restoreJob.rejected, () => {
            message.error("Failed to restore the job");
        })
            .addCase(archiveJob.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to delete the job");
            }
            else {
                message.success("Job deleted successfully");
                state.listActiveJobs = state.listActiveJobs.filter((job) => job.onsiteJobId !== action.payload.data.onsiteJobId);
            }
        })
            .addCase(archiveJob.rejected, () => {
            message.error("Failed to delete the job");
        })
            .addCase(getJobDetail.pending, (state) => {
            state.existedJobData = initialRequestExistedOnsiteJobData;
            state.jobSetupDetails.data = RequestOnsiteJobInitialData;
        })
            .addCase(getJobDetail.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("The detail of this job does not found");
            }
            else {
                const responseData = action.payload.data;
                const startAt = responseData.job.startAt ? moment(responseData.job.startAt) : null;
                const duration = responseData.job?.duration
                    ? moment()
                        .startOf("day")
                        .add(responseData.job?.duration, "minutes")
                    : null;
                state.existedJobData = responseData;
                state.jobSetupDetails.data = {
                    ...responseData.job,
                    startAt,
                    duration,
                    collectorIds: responseData.job.allocatedCollectors.map((collector) => collector.collectorId),
                };
            }
        })
            .addCase(getJobDetail.rejected, () => {
            message.error("The information of this job is not found");
        });
    },
});
export const { setStartDate, setEndDate, setIsArchiveStatus, setJobSetupDetails, setCurrentCollectionPoint, setDropdownSelectedClient, setResetStore, setOnsiteJobId, setResetJobDetail, setSelectedStatusTypes, } = jobManagementSlice.actions;
export default jobManagementSlice.reducer;
