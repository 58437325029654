var UploadPrefix;
(function (UploadPrefix) {
    UploadPrefix["Avatar"] = "avatar";
    UploadPrefix["Image"] = "img";
    UploadPrefix["Background"] = "background";
    UploadPrefix["Document"] = "doc";
    UploadPrefix["UrineDevice"] = "urine-device";
    UploadPrefix["OralScreenDevice"] = "oral-screen-device";
    UploadPrefix["OralCollectionDevice"] = "oral-collection-device";
    UploadPrefix["BreathDevice"] = "breath-alcohol-device";
    UploadPrefix["Contract"] = "client-contracts";
    UploadPrefix["RepresentativeFiles"] = "representative-files";
    UploadPrefix["AuthorisedAuthoritySignatures"] = "authorised-authority-signatures";
})(UploadPrefix || (UploadPrefix = {}));
export { UploadPrefix };
