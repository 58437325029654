import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import MainLayout from "src/components/layouts/MainLayout";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import ComingSoonPage from "../PageErrors/ComingSoonPage";
import { OnSiteRecordsPage } from "./OnSiteRecordsPage";
import { DonorRecordPage } from "./OnSiteRecordsPage/components/DonorRecord";
import { OnSiteDonorResultDetail } from "./OnSiteRecordsPage/components/OnSiteDonorResultDetail";
const breadcrumbs = [
    {
        path: "/patient-records",
        name: "Donor Records",
        level: 1,
    },
    {
        path: "/in-clinic",
        name: "In-Clinic Donor Records",
        level: 2,
    },
    {
        path: "/on-site",
        name: "On-Site Donor Records",
        level: 2,
    },
];
const PatientRecordsRoutes = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === "/patient-records") {
            navigate("/patient-records/in-clinic");
        }
    }, [location]);
    return (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/*", element: _jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/in-clinic", element: _jsx(ComingSoonPage, {}) }), _jsx(Route, { path: "/on-site", element: _jsx(OnSiteRecordsPage, {}) })] }) }) }), _jsx(Route, { path: "/on-site/donor-test-record", element: _jsx(DonorRecordPage, {}) }), _jsx(Route, { path: "/on-site/donor-test-record/test-details-summary", element: _jsx(OnSiteDonorResultDetail, {}) })] }) }));
};
export default PatientRecordsRoutes;
