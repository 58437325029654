import styled from "styled-components";
export const Wrapper = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #0000004d;
`;
