import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "styled-components";
import OnSiteRecords from "/public/assets/svg/ic-onsite-records.svg";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { Text, SearchInput } from "src/components/base";
export const HeaderFilter = ({ onSearchString, clientData, setSelectedClient, }) => {
    const [clientList, setCollectorsList] = useState([]);
    useEffect(() => {
        if (clientData && clientData.length) {
            setCollectorsList(clientData.map((item) => ({
                label: item.name,
                value: item.id,
            })));
        }
    }, [clientData]);
    return (_jsxs(HeaderDiv, { children: [_jsxs(TitleDiv, { children: [_jsx(OnSiteRecords, {}), _jsx(Text, { name: "On-Site Donor Records" })] }), _jsxs(SearchDiv, { children: [_jsx(Text, { name: "Client", className: "text" }), _jsx(Select, { className: "type-select", defaultValue: "All", size: "small", options: [
                            {
                                label: "All",
                                value: "All",
                            },
                            ...clientList,
                        ], onChange: (value) => setSelectedClient(value) }), _jsx(SearchInput, { onChange: (e) => onSearchString(e.target.value) })] })] }));
};
const HeaderDiv = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const TitleDiv = styled.div `
  display: flex;
  align-items: center;
  gap: 1rem;

  .ant-typography {
    font-size: 1rem;
    font-weight: 700;
    color: #1a576f !important;
  }
`;
const SearchDiv = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  .ant-typography {
    font-size: 1rem;
    color: #1a576f !important;
  }

  .show-select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }

  .type-select {
    width: 10rem;
  }
  .ant-select-selector {
    height: 35px !important;
  }

  .ant-select-selection-item {
    line-height: 32px !important;
  }

  .search-input {
    height: 35px !important;

    .ant-input {
      font-size: 1rem !important;
    }

    .ant-image-img {
      height: 18px !important;
      width: 18px !important;
      margin-top: -5px !important;
    }
  }
`;
