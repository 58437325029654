import styled from "styled-components";
export const Wrapper = styled.div `
  .contact-us-header {
    background: #e4f6fb;
    height: 270px;
    padding: 10px 46px 0 46px;
  }
  .contact-us-title {
    font-size: 24px;
    font-weight: bold;
    color: #2f3337cc;
    margin-left: 15px;
  }
  .got-question {
    height: 50px;
    width: 90%;
    max-width: 700px;
    border-radius: 4px;
    background: #ffffff;
    margin-top: 17px;
    box-shadow: 0 3px 6px #0000003d;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    color: #00004c;
    padding: 3px;
    margin-left: auto;
    margin-right: auto;
  }
  .title {
    font-size: 16px;
    color: #2f3337;
  }
`;
