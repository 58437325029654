// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FmV3cLli6WHVugaOHcEM {\n  /* display: flex;\n    justify-content: space-between; */\n  flex-direction: column;\n  row-gap: 20px;\n  padding: 15px;\n}\n\n.pV01SZkRhHaEfFPKOHX1 {\n  display: flex;\n  flex-direction: row;\n  row-gap: 20px;\n}\n\n.I55Ddb6ICi2QnEnn4BaE {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */\n  z-index: 1000; /* Adjust the z-index to make sure it covers the entire page */\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DashboardPage/TestProcessedChart.module.css"],"names":[],"mappings":"AAAA;EACE;qCACmC;EACnC,sBAAsB;EACtB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,2BAA2B;EACjE,aAAa,EAAE,8DAA8D;AAC/E","sourcesContent":[".daily-summary {\n  /* display: flex;\n    justify-content: space-between; */\n  flex-direction: column;\n  row-gap: 20px;\n  padding: 15px;\n}\n\n.filter {\n  display: flex;\n  flex-direction: row;\n  row-gap: 20px;\n}\n\n.overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */\n  z-index: 1000; /* Adjust the z-index to make sure it covers the entire page */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"daily-summary": "FmV3cLli6WHVugaOHcEM",
	"filter": "pV01SZkRhHaEfFPKOHX1",
	"overlay": "I55Ddb6ICi2QnEnn4BaE"
};
export default ___CSS_LOADER_EXPORT___;
