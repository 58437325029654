import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FaqElement } from "../../components";
import { Wrapper } from "./index.style";
export const SearchResults = (props) => {
    const { searchResultList } = props;
    const renderFAQs = () => {
        if (searchResultList && searchResultList.length) {
            const elm = searchResultList.map((element, index) => (_jsx(FaqElement, { className: "faq-element", title: element.title, htmlContent: element.htmlContent }, index)));
            return elm;
        }
        else {
            return null;
        }
    };
    return (_jsxs(Wrapper, { style: { ...props.style }, className: props.className, children: [_jsx("div", { className: "sr-title", children: "Search results" }), _jsx("div", { style: { borderBottom: "2px solid #ADB3BC" } }), _jsx("div", { className: "sr-body", children: renderFAQs() })] }));
};
