import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from "lodash";
import moment from "moment";
import momentTz from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cancelAppointmentService, createAppointment, getAppointmentInfoById, resendEmailAppointmentService, restoreAppointmentService, updateAppointmentService, } from "src/api/appointment/appointmentService";
import { getCollectionOrganizationDetails } from "src/api/collection/collectionService";
import { getPatientRecords } from "src/api/patient/patientService";
import { getPatientWebsByCenterId } from "src/api/user/userService";
import { Form } from "src/components/base";
import { DEFAULT_TIMEZONE, MESSAGE_BOX_TYPE } from "src/constants";
import ContentContext from "src/providers/content";
import { useSettings } from "src/providers/SettingsContextProvider";
import { AppointmentDetails } from "./components/AppointmentDetails";
import PatientDetails from "./components/PatientDetails";
import { AppointmentPageWrapper } from "./index.style";
const validateMessage = {
    required: "is required",
    whitespace: "cannot be empty",
};
export const AppointmentCreation = ({ isPopup, event, submitCallback }) => {
    const [form] = Form.useForm();
    const { settings } = useSettings();
    const isEdit = !!event;
    const isNewForm = !event;
    const { openMessageBox, closePopupBox, openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const [patientInfo, setPatientInfo] = useState();
    const [onEditing, setOnEditing] = useState(false);
    const [requestTest, setRequestTest] = useState();
    const [isLoading, setIsLoading] = useState(false);
    // const requestTestCategory = useSelector(getAppointmentsTestTypes.dataSelector);
    const requestTestCategory = [];
    const patientWebs = useSelector(getPatientWebsByCenterId.dataSelector);
    const [isExistedPatient, setIsExistedPatient] = useState(false);
    const [isSectionSwap, setIsSectionSwap] = useState(false);
    const [dataSuggestPatient, setDataSuggestPatient] = useState([]);
    const [collectionPoints, setCollectionPoints] = useState([]);
    useEffect(() => {
        getData();
        if (event) {
            initFormDataOnEdit();
        }
    }, []);
    const getData = async () => {
        openLoadingScreen();
        const patientData = await getPatientRecords();
        if (patientData.status === "success") {
            setDataSuggestPatient(patientData.data);
            closeLoadingScreen();
        }
        const collectionData = await getCollectionOrganizationDetails();
        if (collectionData.status === "success") {
            setCollectionPoints(collectionData.data.collectionPoints);
        }
    };
    function getTimezone(collectionPointId) {
        const collectionPoint = settings.collectionPointSetting.find((cp) => cp.collectionPointId === collectionPointId);
        return collectionPoint?.timezone;
    }
    const handleSwapSection = () => {
        setIsSectionSwap(!isSectionSwap);
    };
    const handleSelectRowRecord = (record) => {
        // Do something with the clicked row data
        form.setFieldsValue({
            lastName: record.lastName,
            firstName: record.firstName,
            dob: record.dob && moment(record.dob),
            sex: record.sex,
            email: record.email,
            mobile: record.mobile,
            landline: record.landline,
            address1: record.address1,
            address2: record.address2,
            city: record.suburb,
            state: record.state,
            postcode: record.postcode,
        });
        setIsExistedPatient(true);
    };
    const onInputChange = (key) => async ({ field, value }) => {
        if (key === "appointmentInfo") {
            if (field === "time") {
                form.setFieldsValue({
                    [field]: value ? moment(value, "HH:mm") : null,
                });
            }
            else if (field === "parentTestDataList") {
                form.setFieldsValue({ parentTestDataList: null });
                form.setFieldsValue({ parentTestDataList: value });
            }
            else {
                form.setFieldsValue({ [field]: value });
            }
        }
        if (key === "patientInfo") {
            if (field === "email") {
                const patientWeb = patientWebs?.filter((item) => item.email === value.toLowerCase())[0];
                if (patientWeb) {
                    setIsExistedPatient(true);
                    setPatientInfo({ ...patientWeb });
                    form.setFieldsValue({
                        ...patientWeb,
                        dob: patientWeb?.dob && moment(patientWeb.dob?.toDate()),
                        patientWebId: patientWeb.id,
                    });
                    return;
                }
                else {
                    setIsExistedPatient(false);
                    setPatientInfo({ email: value });
                    return;
                }
            }
            setPatientInfo({ ...patientInfo, [field]: value });
            form.setFieldsValue({ [field]: value });
            if (field == "lastName" || field === "state") {
                setPatientInfo({ ...patientInfo, [field]: value.toUpperCase() });
                form.setFieldsValue({ [field]: value.toUpperCase() });
            }
        }
    };
    const buildDataAppointment = (formData) => {
        const timezone = getTimezone(formData.collectionPointUUID) || DEFAULT_TIMEZONE;
        const datePart = formData.date.format("YYYY-MM-DD");
        const timePart = formData.time.format("HH:mm:ss");
        const combinedMoment = momentTz(`${datePart} ${timePart}`, "YYYY-MM-DD HH:mm:ss", timezone)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
        return {
            appointmentDetail: {
                collectionPointId: formData.collectionPointUUID,
                startAt: combinedMoment,
                durationMinutes: Number(formData.duration.replace("m", "")),
                testTypeIds: formData.parentTestDataList.map((testType) => testType.uuid),
                testCategoryId: formData.testCategory,
                note: formData.notes,
            },
            patientDetail: {
                lastName: formData.lastName,
                firstName: formData.firstName,
                dob: formData.dob.format("YYYY-MM-DD").toString(),
                sex: formData.sex,
                email: formData.email,
                mobile: formData.mobile,
                landline: formData.landline,
                address1: formData.address1,
                address2: formData.address2,
                suburb: formData.city,
                state: formData.state,
                postcode: formData.postCode,
            },
        };
    };
    const onFinish = async () => {
        setIsLoading(true);
        const formData = form.getFieldsValue();
        const appointmentBody = buildDataAppointment(formData);
        const response = await createAppointment(appointmentBody);
        if (response.status == "success") {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG, "New Appointment has been created and added to the Patient List.");
            setPatientInfo(null);
            setIsExistedPatient(false);
            form.resetFields();
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG, response.message);
        }
        setIsLoading(false);
    };
    const cancelAppointment = async () => {
        setIsLoading(true);
        const { resource } = event;
        const appointmentId = resource.id;
        const responseCancel = await cancelAppointmentService(appointmentId);
        const msgCancelAppointment = responseCancel.status === "success"
            ? "The Appointment has been cancelled"
            : "The Appointment has been cancelled failed";
        setIsLoading(false);
        openMessageBox(MESSAGE_BOX_TYPE.DIALOG, msgCancelAppointment, submitCallback);
        if (isPopup) {
            closePopupBox();
        }
    };
    const restoreAppointment = async () => {
        setIsLoading(true);
        const { resource } = event;
        const appointmentId = resource.id;
        const responseCancel = await restoreAppointmentService(appointmentId);
        const msgAppointment = responseCancel.status === "success"
            ? "The Appointment has been restored successfully"
            : "Restored appointment failed";
        setIsLoading(false);
        openMessageBox(MESSAGE_BOX_TYPE.DIALOG, msgAppointment, submitCallback);
        if (isPopup) {
            closePopupBox();
        }
    };
    const onResendEmail = async () => {
        setIsLoading(true);
        const { resource } = event;
        const appointmentId = resource.id;
        const responseCancel = await resendEmailAppointmentService(appointmentId);
        const msgCancelAppointment = responseCancel.status === "success" ? "Send successfully" : "Send failed";
        setIsLoading(false);
        openMessageBox(MESSAGE_BOX_TYPE.DIALOG, msgCancelAppointment, submitCallback);
        if (isPopup) {
            closePopupBox();
        }
    };
    const editAppointment = async () => {
        setIsLoading(true);
        const { resource } = event;
        const appointmentId = resource.id;
        const formData = form.getFieldsValue();
        const appointmentBody = buildDataAppointment(formData);
        const responseUpdate = await updateAppointmentService(appointmentId, appointmentBody);
        const msgCancelAppointment = responseUpdate.status === "success"
            ? "The Appointment has been changed successfully"
            : "The Appointment has been changed failed";
        setIsLoading(false);
        openMessageBox(MESSAGE_BOX_TYPE.DIALOG, msgCancelAppointment, submitCallback);
        if (isPopup) {
            closePopupBox();
        }
    };
    const initFormDataOnEdit = async () => {
        if (event) {
            const dataAppointment = await getAppointmentInfoById(event.resource.id);
            if (isEmpty(dataAppointment)) {
                return;
            }
            const { appointmentDetail, patientDetail } = dataAppointment;
            const timezone = getTimezone(appointmentDetail.collectionPointId) || DEFAULT_TIMEZONE;
            const appointmentData = {
                collectionPointUUID: appointmentDetail.collectionPointId,
                date: moment(appointmentDetail.startAt).tz(timezone),
                time: moment(appointmentDetail.startAt).tz(timezone),
                duration: appointmentDetail.durationMinutes + "m",
                parentTestUUID: appointmentDetail.testCategoryId,
                notes: appointmentDetail.note,
                testCategory: event.resource.testCategoryId,
                lastName: patientDetail.lastName,
                firstName: patientDetail.firstName,
                dob: moment(patientDetail.dob),
                sex: patientDetail.sex,
                email: patientDetail.email,
                mobile: patientDetail.mobile,
                landline: patientDetail.landline,
                address1: patientDetail.address1,
                address2: patientDetail.address2,
                city: patientDetail.suburb,
                state: patientDetail.state,
                postCode: patientDetail.postcode,
            };
            form.setFieldsValue({ ...appointmentData });
            setRequestTest(dataAppointment.appointmentDetail.testTypeIds);
        }
    };
    const onClearPatient = () => {
        const formData = form.getFieldsValue();
        const emptyPatient = {
            firstName: "",
            lastName: "",
            dob: null,
            sex: null,
            email: "",
            mobile: "",
            landline: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            postCode: "",
        };
        setIsExistedPatient(false);
        form.setFieldsValue({ ...formData, ...emptyPatient });
    };
    const onUpdatePatient = async () => {
        openLoadingScreen();
        closeLoadingScreen();
        openMessageBox(MESSAGE_BOX_TYPE.PROMPT_BLUE, "The patient’s details will be updated once you create the appointment");
    };
    return (_jsx("div", { style: {
            padding: isPopup ? 0 : "10px 33px 33px",
            width: "100%",
            background: "white",
        }, children: _jsx(AppointmentPageWrapper, { style: {
                marginTop: isPopup ? 0 : 15,
                boxShadow: isPopup ? "0px 3px 6px rgba(0, 0, 0, 0.16)" : "none",
            }, children: _jsx(Form, { initialValues: {}, form: form, style: { width: "100%" }, onFinish: onFinish, validateMessages: validateMessage, children: isSectionSwap ? (_jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "20px" }, children: [_jsx(AppointmentDetails, { collectionPoints: collectionPoints, requestTestCategory: requestTestCategory, onInputChange: onInputChange("appointmentInfo"), isLoading: isLoading, isEdit: isEdit, onEditing: onEditing, enableEdit: (value) => {
                                setOnEditing(value);
                                setIsExistedPatient(true);
                            }, appointmentStatus: event?.resource?.status, previousStatus: event?.resource?.previousStatus, cancelAppointment: cancelAppointment, restoreAppointment: restoreAppointment, updateAppointment: editAppointment, testTypeUUIDList: requestTest, isNewForm: isNewForm, onResendEmail: onResendEmail, onSwapSection: handleSwapSection }), _jsx(PatientDetails, { onSwapSection: handleSwapSection, patientData: dataSuggestPatient, onInputChange: handleSelectRowRecord, isNewForm: isNewForm, onClearPatient: onClearPatient, isExistedPatient: isExistedPatient, onUpdatePatient: onUpdatePatient, onEditing: onEditing })] })) : (_jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "20px" }, children: [_jsx(PatientDetails, { onSwapSection: handleSwapSection, patientData: dataSuggestPatient, onInputChange: handleSelectRowRecord, isNewForm: isNewForm, onClearPatient: onClearPatient, isExistedPatient: isExistedPatient, onUpdatePatient: onUpdatePatient, onEditing: onEditing }), _jsx(AppointmentDetails, { collectionPoints: collectionPoints, requestTestCategory: requestTestCategory, onInputChange: onInputChange("appointmentInfo"), isLoading: isLoading, isEdit: isEdit, onEditing: onEditing, enableEdit: (value) => {
                                setOnEditing(value);
                                setIsExistedPatient(true);
                            }, appointmentStatus: event?.resource?.status, previousStatus: event?.resource?.previousStatus, cancelAppointment: cancelAppointment, restoreAppointment: restoreAppointment, testTypeUUIDList: requestTest, updateAppointment: editAppointment, requestedTest: requestTest, isNewForm: isNewForm, onResendEmail: onResendEmail, onSwapSection: handleSwapSection })] })) }) }) }));
};
