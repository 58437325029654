import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
const SAStatusButton = ({ color, children, ...rest }) => (_jsx(StatusButton, { color: color, ...rest, disabled: true, children: children }));
SAStatusButton.propTypes = {
    color: PropTypes.string,
    children: PropTypes.node,
};
const StatusButton = styled(Button) `
  border-radius: 1rem;
  background-color: ${(props) => props.color ?? "#6C757D"};
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
  border: 0;
  min-width: 100px;

  &:hover {
    background-color: ${(props) => props.color ?? "#5a6268"} !important;
    color: #fff !important;
  }
  &:disabled {
    background-color: ${(props) => props.color ?? "#6C757D"};
    color: #fff;
    opacity: 1;
    cursor: pointer;
  }
`;
export default SAStatusButton;
