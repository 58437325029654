import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image } from "antd";
import { useState } from "react";
import { GetCodeForm } from "src/pages/ForgotPwdPage/GetCodeForm";
import { UpdatePwdForm } from "src/pages/ForgotPwdPage/UpdatePwdForm";
import { SuccessForm } from "src/pages/ForgotPwdPage/SuccessForm";
import { VerifyCodeForm } from "src/pages/ForgotPwdPage/VerifyCodeForm";
import { ForgotPwdForm } from "src/pages/ForgotPwdPage/ForgotPwdForm";
import { FormWrapper, ImageCover, PageWrapper } from "./index.style";
const ForgotPwdPage = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [getCodeType, setGetCodeType] = useState("email");
    const onSend = (data) => {
        setEmail(data.email);
        data.mobile && setPhone(data.mobile);
        setStep(2);
    };
    const onGetCodeOtherWay = (type) => {
        setGetCodeType(type);
        setStep(2);
    };
    return (_jsxs(PageWrapper, { children: [_jsx(ImageCover, { children: step == 1 && (_jsx(Image, { src: "/assets/images/ic-header-forgot-password.svg", alt: "logo and text", preview: false, height: "100%" })) }), _jsxs(FormWrapper, { children: [step == 1 && _jsx(ForgotPwdForm, { onSendProp: onSend }), step == 2 && (_jsx(VerifyCodeForm, { id: "", email: email, phone: phone, getCode: () => setStep(3), onVerifySuccess: () => {
                            setStep(4);
                        }, getCodeType: getCodeType, onBack: () => setStep(1) })), step == 3 && (_jsx(GetCodeForm, { email: email, phone: phone, onGetCode: onGetCodeOtherWay, onBack: () => setStep(2) })), step == 4 && _jsx(UpdatePwdForm, { id: "", onUpdateSuccess: () => setStep(5) }), step == 5 && _jsx(SuccessForm, {})] })] }));
};
export default ForgotPwdPage;
