import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InfoCircleFilled } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { message } from "antd";
import { deleteLab, UpdateLaboratoryDetails } from "src/api/collection/collectionService";
import { Button, Form, FormBox, Image, Row, Text } from "src/components/base";
import { mainAppColours, MESSAGE_BOX_TYPE, SAColourScheme, validateMessage } from "src/constants";
import ContentContext from "src/providers/content";
import { throwHttpRequestError } from "src/infra/http";
import { InputWrapper } from "../index.style";
import { LaboratoryForm } from "./LaboratoryForm";
export const LaboratoryFormWrapper = (props) => {
    const [form] = Form.useForm();
    const [laboratories, setLaboratories] = useState([]);
    const [deletedLaboratory, setDeletedLaboratory] = useState([]);
    const [reloadComponent, setReloadComponent] = useState(false);
    const [isNewLab, setIsNewLab] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const { laboratoryData } = props;
    const { openMessageBox } = useContext(ContentContext);
    const handleFormInputChange = (index, updatedLab) => {
        setLaboratories((prevLaboratories) => {
            const updatedLaboratories = [...prevLaboratories];
            updatedLaboratories[index] = updatedLab;
            return updatedLaboratories;
        });
    };
    const getData = async () => {
        if (laboratoryData && laboratoryData.length > 0) {
            setIsNewLab(false);
            setLaboratories(!laboratoryData.length ? [{}] : laboratoryData);
        }
        else {
            setIsNewLab(true);
        }
    };
    useEffect(() => {
        getData();
    }, [laboratoryData, reloadComponent]);
    const addLaboratory = () => {
        setLaboratories((prev) => [...prev, {}]);
    };
    const deleteLaboratory = (index, laboratory) => {
        setDeletedLaboratory((prevDeletedLab) => [...prevDeletedLab, laboratory]);
        setLaboratories((prev) => {
            let newListLaboratory = prev.filter((_, i) => i !== index);
            if (!newListLaboratory.length) {
                newListLaboratory = [{}];
            }
            return newListLaboratory;
        });
    };
    const saveLaboratory = async () => {
        setIsEdit(false);
        const processCollectionPoint = async () => {
            const updatedLaboratories = [...laboratories];
            const deletedLabIds = deletedLaboratory.map((lab) => lab.id);
            const addedLaboratories = updatedLaboratories.filter((lab) => !deletedLabIds.includes(lab.id) && !lab.id);
            const existedLaboratories = updatedLaboratories.filter((lab) => lab.id && !deletedLabIds.includes(lab.id));
            // Additional logic for processing laboratories, if needed
            if (deletedLaboratory.length > 0) {
                const deleteRequests = deletedLaboratory.map(async (lab) => {
                    try {
                        lab.id && (await deleteLab(lab.id));
                    }
                    catch (error) {
                        throwHttpRequestError(error);
                    }
                });
                try {
                    await Promise.all(deleteRequests);
                }
                catch (error) {
                    message.error("One or more delete requests failed");
                }
            }
            return {
                addedLaboratories,
                existedLaboratories,
            };
        };
        const { addedLaboratories, existedLaboratories } = await processCollectionPoint();
        const result = await UpdateLaboratoryDetails(addedLaboratories, existedLaboratories);
        if (result) {
            message.success("Saved successfully");
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG, "Something went wrong. Please try again");
        }
    };
    const onCancelEdit = () => {
        setIsEdit(false);
        setReloadComponent(true);
    };
    return (_jsx(_Fragment, { children: _jsx(Form, { form: form, validateMessages: validateMessage, children: _jsx(InputWrapper, { children: _jsxs(FormBox, { title: "Laboratory", backgroundColor: SAColourScheme.NAVY, icon: "/assets/svg/ic-form-laboratory.svg", titleComponent: _jsx("div", { className: "form-box-title", children: _jsx("div", { className: "title-action", children: !isEdit ? (_jsxs(Button, { onClick: () => {
                                    setIsEdit(true);
                                }, disabled: isNewLab, children: [_jsx(Image, { src: "/assets/svg/ic-form-edit.svg", style: { width: 20, marginRight: 8 }, preview: false }), "Edit"] })) : (_jsxs(_Fragment, { children: [_jsx(Button, { className: "btn-cancel", onClick: () => {
                                            onCancelEdit();
                                            // if (!laboratory.id) {
                                            //   deleteLaboratory(index);
                                            // } else {
                                            //   form.resetFields();
                                            //   form.setFieldsValue({ ...laboratory });
                                            // }
                                        }, children: "Cancel" }), _jsx(Button, { className: "btn-save", onClick: () => {
                                            form.validateFields().then(() => {
                                                saveLaboratory();
                                            });
                                        }, children: "Save" })] })) }) }), children: [_jsx(Row, { style: {
                                marginTop: 10,
                                borderTop: "0.5px solid #FFFFFF",
                            } }), isNewLab && (_jsx(_Fragment, { children: _jsx("div", { className: "form-lab-info", style: {
                                    backgroundColor: isEdit ? SAColourScheme.WHITE : "#e4f6fb",
                                    height: "400px",
                                }, children: _jsxs("div", { className: "start-panel", children: [_jsx(InfoCircleFilled, { style: {
                                                fontSize: 52,
                                                color: mainAppColours.BLUE,
                                                marginBottom: 30,
                                            } }), _jsx(Text, { className: "start-text", name: "Please complete Laboratory details." }), _jsx(Button, { className: "start-button", onClick: () => {
                                                setIsNewLab(false);
                                                setIsEdit(true);
                                                addLaboratory();
                                            }, children: "Start" })] }) }) })), laboratories?.map((laboratory, index) => (
                        // eslint-disable-next-line react/jsx-key
                        _jsx(LaboratoryForm, { id: laboratory.id, index: index, form: form, laboratory: laboratory, onInputChange: handleFormInputChange, isEdit: isEdit, totalLaboratory: laboratories.length, setLaboratories: setLaboratories, 
                            // addLaboratory={addLaboratory}
                            // saveLaboratory={saveLaboratory}
                            deleteLaboratory: deleteLaboratory }, index))), isEdit && (_jsx(Row, { justify: "end", align: "bottom", children: _jsx(Button, { className: "add-collection-point", 
                                // style={{ opacity: isLast && laboratory.id ? 1 : 0.5 }}
                                onClick: () => {
                                    addLaboratory();
                                }, children: "Add Laboratory" }) }))] }) }) }) }));
};
