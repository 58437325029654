import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Image } from "antd";
import React from "react";
import styled from "styled-components";
import SADivider from "src/components/Form/SADivider";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
export const DeviceCardContainer = ({ image, Fallback, children, }) => (_jsxs(ContainerWrapper, { gutter: [24, 24], children: [_jsx(ImageContainer, { span: 6, children: image !== "" ? _jsx(CustomImage, { src: image }) : _jsx(Fallback, {}) }), _jsx(InformationContainer, { span: 18, children: React.Children.map(children, (child, index) => (_jsxs(React.Fragment, { children: [child, index === children.length - 1 ? _jsx(_Fragment, {}) : _jsx(SADivider, { color: "#ADB3BC" })] }))) })] }));
const ContainerWrapper = styled(SARow) `
  width: 100%;
  height: 20rem;
  background-color: #f7f8f8;
  border-top: 0.5rem solid #ebedf0;
  padding: 1rem;
`;
const ImageContainer = styled(SAColumn) `
  background-color: #ebedf0;
  display: flex;
  justify-content: center !important;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;

  padding: 0 !important;

  .ant-image {
    width: 100%;
    height: 100%;
  }
`;
const CustomImage = styled(Image) `
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0.25rem;
`;
const InformationContainer = styled(SAColumn) `
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: center;
`;
