import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { Image, List, Modal } from "src/components/base";
import { mainAppColours, supportingColours } from "src/constants";
import "./ShowModal.css";
const ShowModal = (props) => {
    const data = [
        {
            title: "Select all",
            style: {
                fontWeight: "bold",
            },
            checkBoxStyle: {},
            columnName: "all",
        },
        {
            title: "Date",
            columnName: "date",
            checkBoxStyle: {},
        },
        {
            title: "Time",
            columnName: "time",
            checkBoxStyle: {},
        },
        {
            title: "Last Name",
            columnName: "lastName",
            checkBoxStyle: {},
        },
        {
            title: "First Name",
            columnName: "firstName",
            checkBoxStyle: {},
        },
        {
            title: "Date of Birth",
            columnName: "dob",
            checkBoxStyle: {},
        },
        {
            title: "Age",
            columnName: "age",
            checkBoxStyle: {},
        },
        {
            title: "Sex",
            columnName: "sex",
            checkBoxStyle: {},
        },
        {
            title: "Requested Test",
            columnName: "requestTest",
            checkBoxStyle: {},
        },
        {
            title: "RTO",
            columnName: "rto",
            checkBoxStyle: {},
        },
        {
            title: "View Booking",
            checkBoxStyle: {
                width: "11px",
                height: "11px",
                background: supportingColours.TEXT_MID_GRAY,
            },
            default: true,
            columnName: "view",
        },
    ];
    const checkBoxStyle = {
        width: "11px",
        height: "11px",
        background: mainAppColours.MID_BLUE,
    };
    const [displayAll, setDisplayAll] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState({
        all: true,
        date: true,
        time: true,
        firstName: true,
        lastName: true,
        dob: true,
        age: true,
        sex: true,
        rto: true,
        requestTest: true,
    });
    const selectItem = (item) => {
        if (item.columnName == "all") {
            setDisplayAll(!displayAll);
            setSelectedColumn({
                all: !displayAll,
                date: !displayAll,
                time: !displayAll,
                firstName: !displayAll,
                lastName: !displayAll,
                dob: !displayAll,
                age: !displayAll,
                sex: !displayAll,
                rto: !displayAll,
                requestTest: !displayAll,
            });
        }
        else {
            const currentValue = selectedColumn[item.columnName];
            setDisplayAll(false);
            setSelectedColumn({ ...selectedColumn, [item.columnName]: !currentValue, all: false });
        }
    };
    const displayColumns = (col) => {
        const columns = [];
        for (const [key, value] of Object.entries(col)) {
            if (value == true) {
                columns.push(key);
            }
        }
        return columns;
    };
    return (_jsx(Modal, { className: "select-collumn-modal", visible: props.isModalVisible, onCancel: () => props.handleCancel(displayColumns(selectedColumn)), footer: null, width: 222, style: {
            marginTop: "135px",
            marginRight: "280px",
        }, closeIcon: _jsx(Image, { src: "/assets/images/close-icon-text-mid-gray.png", preview: false, height: 10, width: 10 }), children: _jsx(List, { className: "select-item-list", itemLayout: "horizontal", dataSource: data, split: false, renderItem: (item) => (_jsxs(List.Item, { children: [_jsxs("div", { style: {
                            width: "17px",
                            height: "17px",
                            border: "0.5px solid #ADB3BC",
                            padding: "2.5px",
                            marginRight: "10px",
                            background: item.default ? "#ADB3BC" : "none",
                        }, onClick: () => selectItem(item), children: [!item.default && (_jsx("div", { style: selectedColumn[item.columnName] == true
                                    ? checkBoxStyle
                                    : {} })), item.default && _jsx("div", { style: item.checkBoxStyle })] }), _jsx("div", { style: item.style, children: item.title })] })) }) }));
};
export default ShowModal;
