import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import ComingSoonPage from "../PageErrors/ComingSoonPage";
const breadcrumbs = [
    {
        path: "/accounting",
        name: "Accounting",
        level: 1,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        level: 2,
    },
    {
        path: "/licensing",
        name: "Licensing",
        level: 2,
    },
    {
        path: "/it-services",
        name: "IT Services",
        level: 2,
    },
    {
        path: "/invoices",
        name: "Invoices",
        level: 2,
    },
    {
        path: "/payment",
        name: "Payment",
        level: 2,
    },
];
const AccountingPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === "/accounting") {
            navigate("/accounting/dashboard");
        }
    }, [location]);
    return (_jsx(MainLayout, { children: _jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/dashboard", element: _jsx(ComingSoonPage, {}) }), _jsx(Route, { path: "/licensing", element: _jsx(ComingSoonPage, {}) }), _jsx(Route, { path: "/it-services", element: _jsx(ComingSoonPage, {}) }), _jsx(Route, { path: "/invoices", element: _jsx(ComingSoonPage, {}) }), _jsx(Route, { path: "/payment", element: _jsx(ComingSoonPage, {}) })] }) }) }));
};
export default AccountingPage;
