import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import BreathalyserDevicePage from "./Breathalyser";
import OralCollectionDevicePage from "./OralFluidCollection";
import OralScreenDevicePage from "./OralFluidDrugScreen";
import UrineScreenDevicePage from "./UrineDrugScreen";
const TestingDeviceRoute = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === "/testing-devices") {
            navigate("/testing-devices/urine-screen");
        }
    }, [location]);
    return (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/urine-screen/*", element: _jsx(UrineScreenDevicePage, {}) }), _jsx(Route, { path: "/oral-screen/*", element: _jsx(OralScreenDevicePage, {}) }), _jsx(Route, { path: "/oral-collection/*", element: _jsx(OralCollectionDevicePage, {}) }), _jsx(Route, { path: "/breathalyser/*", element: _jsx(BreathalyserDevicePage, {}) })] }) }));
};
export default TestingDeviceRoute;
export const TestingDeviceBreadcrumbs = [
    {
        path: "/testing-devices",
        name: "Device Management",
        level: 1,
    },
    {
        path: "/urine-screen",
        name: "Urine Drug Screen Devices",
        level: 2,
    },
    {
        path: "/oral-screen",
        name: "Oral Fluid Drug Screen Devices",
        level: 2,
    },
    {
        path: "/oral-collection",
        name: "Oral Fluid Collection Devices",
        level: 2,
    },
    {
        path: "/breathalyser",
        name: "Breathalysers",
        level: 2,
    },
];
