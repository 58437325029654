import styled from "styled-components";
import { SAColourScheme, supportingColours } from "src/constants";
export const InputWithLabelWrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &.hidden {
    display: none;
  }

  &.theme-1 {
    .ant-input,
    .ant-select-selector,
    .ant-picker {
      border: 1px solid #adb3bc !important;
    }
  }

  &.theme-2 {
    .ant-input,
    .ant-select-selector,
    .ant-picker {
      border: 1px solid #1a8cff !important;
    }

    .ant-input[disabled],
    .ant-picker.ant-picker-disabled,
    .ant-select-disabled .ant-select-selector,
    .ant-select-multiple.ant-select-disabled .ant-select-selector {
      background: ${SAColourScheme.PLASSMA10};
      border: 0 !important;
    }
  }

  &.theme-3 {
    .ant-input,
    .ant-select-selector,
    .ant-picker {
      border: 1px solid #d9d9d9 !important;
      background: ${SAColourScheme.WHITE} !important;
    }

    .ant-input[disabled],
    .ant-picker.ant-picker-disabled,
    .ant-select-disabled .ant-select-selector,
    .ant-select-multiple.ant-select-disabled .ant-select-selector {
      border: 0 !important;
      background: #f5f5f5 !important;
    }
    .ant-select-selection-item div {
      color: ${supportingColours.TEXT_DARK_GRAY};
    }

    .ant-select {
      .ant-select-selector {
        background-color: ${SAColourScheme.WHITE} !important;
        border-radius: 4px;
        border: 0;
        box-shadow: none;
        height: 36px !important;

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
          font-size: 14px;
        }

        .ant-select-selection-placeholder {
          color: ${supportingColours.TEXT_LIGHT_GRAY};
        }

        .ant-select-selection-item {
          color: ${supportingColours.TEXT_DARK_GRAY};
        }
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0px;

    .ant-select,
    .ant-picker {
      width: 100%;
    }

    .ant-form-item-explain-error {
      color: red;
      font-size: 12px;
      text-align: right;
    }
  }

  .ant-typography {
    font-size: 16px;
    margin-left: 4px;
  }

  .required {
    color: red !important;
  }

  .ant-input {
    border-radius: 4px;
    overflow: hidden;
    height: 44px !important;

    font-size: 17px;
    color: ${supportingColours.TEXT_DARK_GRAY};

    border: 0 !important;

    &:focus {
      box-shadow: none;
    }
  }

  .selector-white-background {
    background-color: white;
  }

  .ant-select {
    .ant-select-selector {
      background-color: #e4f6fb !important;
      border-radius: 4px;
      border: 0;
      box-shadow: none;
      height: 44px !important;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        font-size: 17px;
      }

      .ant-select-selection-placeholder {
        color: ${supportingColours.TEXT_LIGHT_GRAY};
      }

      .ant-select-selection-item {
        color: ${supportingColours.TEXT_DARK_GRAY};
      }
    }
  }

  .ant-picker {
    height: 44px !important;
    border-radius: 4px;
    border: 0;

    .ant-picker-input input {
      font-size: 17px;
      color: ${supportingColours.TEXT_DARK_GRAY};

      ::placeholder {
        color: ${supportingColours.TEXT_LIGHT_GRAY};
      }
    }
  }

  .ant-select-selection-search .ant-select-selection-search-input {
    font-size: 17px;
    color: ${supportingColours.TEXT_DARK_GRAY};

    ::placeholder {
      color: ${supportingColours.TEXT_LIGHT_GRAY};
    }
  }
`;
export const OptionsDropdownWrapper = styled.div `
  margin: -4px 0;
  padding: 6px 0px;
  border-radius: 4px;
  border: 1px solid ${supportingColours.BOX_GRAY};

  .ant-select-item-option-content {
    font-size: 16px;
    color: ${supportingColours.TEXT_DARK_GRAY};
  }
`;
