const selectClientLocationsList = (state) => state.clientManagementPage.clientLocationsPage.listClientLocations;
const selectArchivedClientLocationsList = (state) => state.clientManagementPage.clientLocationsPage.listArchivedClientLocations;
const selectLocationDetails = (state) => {
    const locationDetails = state.clientManagementPage.clientLocationsPage.locationDetails;
    return {
        id: locationDetails?.id,
        siteName: locationDetails?.siteName,
        onsiteClientId: locationDetails?.onsiteClientId,
        country: locationDetails?.country,
        address1: locationDetails?.address1,
        address2: locationDetails?.address2,
        suburb: locationDetails?.suburb,
        state: locationDetails?.state,
        postCode: locationDetails?.postCode,
        siteAccessInstructions: locationDetails?.siteAccessInstructions,
    };
};
const selectContactList = (state) => {
    const contacts = state.clientManagementPage.clientLocationsPage.contactDetails;
    return contacts.map((contact, index) => ({
        ...contact,
        index: index + 1,
    }));
};
const selectIsAddNew = (state) => state.clientManagementPage.clientLocationsPage.isAddNew;
const selectIsListPage = (state) => state.clientManagementPage.clientLocationsPage.isListPage;
const selectIsEdit = (state) => state.clientManagementPage.clientLocationsPage.isEdit;
const selectValidationFail = (state) => state.clientManagementPage.clientLocationsPage.validationFail;
const selectLocationId = (state) => state.clientManagementPage.clientLocationsPage.locationId;
export { selectClientLocationsList, selectArchivedClientLocationsList, selectLocationDetails, selectContactList, selectIsAddNew, selectIsListPage, selectIsEdit, selectValidationFail, selectLocationId, };
