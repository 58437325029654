import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DatePicker, Image, Select, TreeSelect, Typography } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectCollectionPoints, selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { InputWithLabel } from "src/components/base";
import { getDate } from "src/utils/helper";
import { listStatusTypes, selectClientJobHistoryList, selectCurrentCollectionPoint, selectedEndDate, selectedStartDate, selectedStatusTypes, } from "./store/selector";
import { getListClientJobHistory } from "./store/action";
import { JobHistoryTable } from "./jobHistoryTable";
import { setCurrentCollectionPoint, setEndDate, setSelectedStatusTypes, setStartDate, } from "./store/slice";
export const JobHistory = () => {
    const dispatch = useAppDispatch();
    const completedJobs = useAppSelector(selectClientJobHistoryList);
    const isLoading = useAppSelector(selectIsLoading);
    const startDate = useAppSelector(selectedStartDate);
    const endDate = useAppSelector(selectedEndDate);
    const selectedCollectionPoint = useAppSelector(selectCurrentCollectionPoint);
    const selectedStatus = useAppSelector(selectedStatusTypes);
    const statusTypes = useAppSelector(listStatusTypes);
    const collectionPoints = useAppSelector(selectCollectionPoints);
    const [filteredData, setFilteredData] = useState(completedJobs);
    const { clientId } = useParams();
    const handleStatusChange = (data) => {
        dispatch(setSelectedStatusTypes(data.value));
    };
    const handleStartDateChange = (date) => {
        dispatch(setStartDate(date));
    };
    const handleEndDateChange = (date) => {
        dispatch(setEndDate(date));
    };
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            if (clientId) {
                await dispatch(getListClientJobHistory(clientId));
            }
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [location]);
    useEffect(() => {
        const filtered = completedJobs.filter((job) => {
            const isWithinDateRange = (!job.startAt ||
                !startDate ||
                moment(getDate(job.startAt)).isSameOrAfter(moment(startDate).startOf("day"))) &&
                (!job.startAt ||
                    !endDate ||
                    moment(getDate(job.startAt)).isSameOrBefore(moment(endDate).endOf("day")));
            const isLocationMatch = !selectedCollectionPoint?.value || job.collectionPointId === selectedCollectionPoint.value;
            const isStatusMatch = !selectedStatus.length || selectedStatus.includes(job.status);
            return isWithinDateRange && isLocationMatch && isStatusMatch;
        });
        setFilteredData(filtered);
    }, [completedJobs, startDate, endDate, selectedStatus, selectedCollectionPoint]);
    return (_jsxs(React.Fragment, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(JobFilter, { children: [_jsxs(LocationList, { children: [_jsx(Image, { src: "/assets/svg/ic-job-history.svg", height: 30, width: 30, preview: false }), _jsx(Typography.Text, { children: "On-Site Job History" })] }), _jsxs(LocationList, { children: [_jsx(Typography.Text, { children: "Service Office" }), _jsx(CustomSelect, { value: selectedCollectionPoint.name, options: [
                                    { value: "", label: "All" },
                                    ...collectionPoints.map((cp) => ({
                                        value: cp.id,
                                        label: cp.collectionPointName,
                                    })),
                                ], onChange: (_, option) => {
                                    const selectedValue = option;
                                    dispatch(setIsLoading(true));
                                    dispatch(setCurrentCollectionPoint({
                                        id: selectedValue.value,
                                        collectionPointName: selectedValue.label,
                                    }));
                                    dispatch(setIsLoading(false));
                                } })] }), _jsxs(StyledDiv, { children: [_jsx(Typography.Text, { children: "Date" }), _jsx(DatePicker, { suffixIcon: null, value: startDate, onChange: handleStartDateChange, placeholder: "Starts" }), _jsx(DatePicker, { suffixIcon: null, value: endDate, onChange: handleEndDateChange, placeholder: "Ends" })] }), _jsxs(StyledDiv, { children: [_jsx(Typography.Text, { children: "Status" }), _jsx(InputWithLabel, { theme: "3", value: selectedStatus, onInputChange: handleStatusChange, inputProps: {
                                    type: "treeselect",
                                    options: [
                                        {
                                            title: "All",
                                            value: "all",
                                            children: statusTypes.map((item) => ({
                                                title: item.label,
                                                value: item.value,
                                            })),
                                        },
                                    ],
                                    treeCheckable: true,
                                    showCheckedStrategy: TreeSelect.SHOW_CHILD,
                                    treeLine: {},
                                    treeDefaultExpandAll: true,
                                    placeholder: "All",
                                } })] })] }), _jsx(MainJobBoardWrapper, { children: _jsx(JobHistoryTable, { filteredClientsData: filteredData }) })] }));
};
export const HeaderFilterWrapper = styled.div `
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  align-items: center;

  .status-dropdown {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .status-select {
    width: 250px;
  }

  .header-text {
    margin-left: 10px;
    font-size: 16px;
    color: #000000;
  }

  .status-dropdown {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
  .text {
    font-size: 14px;
    color: #4b5767;
  }

  .ant-select .ant-select-selector {
    height: 25px !important;
    background-color: white !important;
    border: 1px solid #d9d9d9 !important;
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    font-size: 15px !important;
  }
`;
const MainJobBoardWrapper = styled.div `
  width: 100%;
`;
const JobFilter = styled.div `
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.5rem;

  & > *:nth-child(1),
  & > *:nth-child(2) {
    justify-self: flex-start;
  }

  & > *:nth-child(3),
  & > *:nth-child(4) {
    justify-self: flex-end;
  }
`;
const LocationList = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
`;
const CustomSelect = styled(Select) `
  width: 15rem !important;
`;
const StyledDiv = styled.div `
  display: flex;
  gap: 10px;
  align-items: center;

  .ant-select-selector {
    margin-top: 8px !important;
    width: 15rem !important;
    height: 1.5rem !important;
  }
`;
