import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, IconButton, ListItemText, MenuItem, TextField, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import Select from "@mui/material/Select";
import CancelIcon from "@mui/icons-material/Cancel";
import { Row } from "src/components/base";
const InviteUserPopup = ({ userRoles, onSend }) => {
    const [inviteForm, setInviteForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        title: "",
        userRoleId: "",
    });
    const [customUserTitle, setCustomUserTitle] = useState([]);
    const [open, setOpen] = useState(false);
    const onSubmitInvitation = (curForm) => {
        onSend(curForm);
    };
    const userTitle = [
        "Reception",
        "General Manager",
        "Practice Manager",
        "Clinician",
        "Doctor",
        "Medical Director",
        "Principal",
    ];
    const handleInputKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent form submission
            if (event.target.value.trim() !== "") {
                setCustomUserTitle([...customUserTitle, event.target.value]);
            }
        }
    };
    const handleTextFieldClick = (event) => {
        event.stopPropagation(); // Prevent click event from propagating to the Select component
    };
    return (_jsxs("div", { style: {
            backgroundColor: "white",
            width: "100%",
            padding: "20px",
            border: "1px solid #1A8CFF",
            borderRadius: "10px",
        }, children: [_jsxs("div", { style: { display: "flex", justifyContent: "space-between", margin: "10px 50px" }, children: [_jsxs("div", { style: { display: "flex", alignItems: "center", gap: "15px" }, children: [_jsx("img", { src: "/assets/svg/ic-cm-user.svg", alt: "ic-collectors" }), _jsx(Typography, { children: "Invite User" })] }), _jsx(Button, { variant: "contained", size: "small", onClick: () => onSubmitInvitation(inviteForm), style: { textTransform: "none", backgroundColor: "#1A8CFF" }, children: "Send Invitation" })] }), _jsx(Divider, { style: { margin: "0px 50px" } }), _jsxs("div", { style: {
                    margin: "5% 8%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "10px",
                }, children: [_jsxs(Row, { style: { justifyContent: "space-between" }, children: [_jsxs("div", { style: { display: "flex", flexDirection: "column", width: "45%" }, children: [_jsx("label", { children: "First Name" }), _jsx(TextField, { hiddenLabel: true, size: "small", onChange: (event) => setInviteForm({ ...inviteForm, firstName: event.target.value }) })] }), _jsxs("div", { style: { display: "flex", flexDirection: "column", width: "45%" }, children: [_jsx("label", { children: "Last Name" }), _jsx(TextField, { hiddenLabel: true, size: "small", onChange: (event) => setInviteForm({ ...inviteForm, lastName: event.target.value }) })] })] }), _jsxs(Row, { style: { justifyContent: "space-between", rowGap: "10px" }, children: [_jsxs("div", { style: { display: "flex", flexDirection: "column", width: "45%" }, children: [_jsx("label", { children: "Email" }), _jsx(TextField, { hiddenLabel: true, size: "small", onChange: (event) => setInviteForm({ ...inviteForm, email: event.target.value }) })] }), _jsxs("div", { style: { display: "flex", flexDirection: "column", width: "45%" }, children: [_jsx("label", { children: "Mobile Number" }), _jsx(TextField, { hiddenLabel: true, size: "small", InputProps: {
                                            startAdornment: _jsx("span", { style: { opacity: "0.3", marginRight: "5px" }, children: "+61" }),
                                        }, onChange: (event) => setInviteForm({ ...inviteForm, mobile: `+61${event.target.value}` }) })] })] }), _jsxs(Row, { style: { justifyContent: "space-between" }, children: [_jsxs("div", { style: { display: "flex", flexDirection: "column", width: "45%" }, children: [_jsx("label", { children: "User Title" }), _jsxs(Select, { size: "small", fullWidth: true, value: inviteForm.title, onOpen: () => setOpen(true), onClose: () => setOpen(false), onChange: (event) => {
                                            setInviteForm({ ...inviteForm, title: event.target.value });
                                        }, children: [userTitle.map((role) => (_jsx(MenuItem, { style: { backgroundColor: "#fff" }, value: role, children: _jsx(ListItemText, { style: { padding: "0px 10px" }, primary: role }) }, role))), _jsx(Divider, {}), customUserTitle.map((title) => (_jsxs(MenuItem, { style: {
                                                    backgroundColor: "#fff",
                                                    color: "#01B4D2",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }, value: title, children: [_jsx(ListItemText, { style: { padding: "0px 10px" }, primary: title }), open && (_jsx(IconButton, { onClick: () => {
                                                            const updated = customUserTitle.filter((item) => item !== title);
                                                            setCustomUserTitle(updated);
                                                        }, children: _jsx(CancelIcon, { style: { color: "#FBCECE" } }) }))] }, title)
                                            // </div>
                                            )), _jsx(TextField, { size: "small", style: { padding: "10px" }, onKeyPress: handleInputKeyPress, placeholder: "Add new option", fullWidth: true, onClick: handleTextFieldClick, autoFocus: true })] })] }), _jsxs("div", { style: { display: "flex", flexDirection: "column", width: "45%" }, children: [_jsx("label", { children: "User Role (Permission)" }), _jsx(Select, { size: "small", fullWidth: true, value: userRoles.find((role) => role.id === inviteForm.userRoleId)?.name || "", onChange: (event) => {
                                            const roleId = userRoles.find((role) => role.name === event.target.value).id;
                                            setInviteForm({ ...inviteForm, userRoleId: roleId });
                                        }, children: userRoles.map((role) => (_jsx(MenuItem, { style: { backgroundColor: "#fff" }, value: role.name, children: _jsx(ListItemText, { primary: role.name }) }, role.id))) })] })] })] })] }));
};
export default InviteUserPopup;
