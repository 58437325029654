import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as echarts from "echarts";
import { useEffect, useState } from "react";
import { dashboard_getColorByName } from "src/utils/helper";
export const DailyPieChart = ({ countTestType, selectedColorScheme }) => {
    const [testType, setTestType] = useState([]);
    useEffect(() => {
        const testData = countTestType || {};
        if (testData) {
            const testTypeData = Object.entries(testData).map(([name, value], index) => ({
                name: name.trim(),
                itemStyle: { color: dashboard_getColorByName(selectedColorScheme, index + 1) },
                value: value,
            }));
            testType.forEach((test) => {
                const testCount = countTestType[test.name];
                if (testCount !== undefined) {
                    test.value = testCount;
                }
                else {
                    test.value = 0;
                }
            });
            setTestType(testTypeData);
        }
    }, [countTestType, selectedColorScheme]);
    const totalNumber = testType.reduce((total, item) => total + item.value, 0);
    useEffect(() => {
        const resizeChart = () => {
            // eslint-disable-next-line import/namespace
            const myChart = echarts.getInstanceByDom(document.getElementById("daily-pie"));
            if (myChart) {
                myChart.resize();
            }
        };
        // eslint-disable-next-line import/namespace
        const myChart = echarts.init(document.getElementById("daily-pie"));
        window.addEventListener("resize", resizeChart);
        let option;
        // eslint-disable-next-line prefer-const
        option = {
            tooltip: {
                trigger: "item",
            },
            legend: null,
            series: [
                {
                    type: "pie",
                    radius: ["45%", "70%"],
                    avoidLabelOverlap: true,
                    label: {
                        show: false,
                        position: "center",
                    },
                    labelLine: {
                        show: false,
                    },
                    data: testType, // Use testType state here
                },
            ],
            graphic: [
                {
                    type: "group",
                    left: "center",
                    top: "center",
                    children: [
                        {
                            type: "text",
                            z: 100,
                            left: "center",
                            top: 30,
                            style: {
                                text: totalNumber,
                                textAlign: "center",
                                fontSize: 36,
                                color: "#444444",
                            },
                        },
                        {
                            type: "text",
                            z: 100,
                            left: "center",
                            top: 80,
                            style: {
                                text: "Total",
                                textAlign: "center",
                                fontSize: 12,
                                color: "#444444",
                            },
                        },
                    ],
                },
            ],
        };
        option && myChart.setOption(option);
        return () => {
            window.removeEventListener("resize", resizeChart);
            myChart.dispose();
        };
    }, [testType]);
    return (_jsx("div", { id: "daily-pie", style: {
            width: "100%",
            height: "300px",
            display: "flex",
            justifyContent: "center",
        } }));
};
