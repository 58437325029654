import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { useEffect, useState } from "react";
import { Button } from "src/components/base";
import { MonthYearWrapper } from "./index.style";
const currentYear = moment().year();
const lastTenYear = currentYear - 9;
const YearSelector = ({ startIndex }) => {
    const [startYear, setStartYear] = useState(lastTenYear);
    useEffect(() => {
        startIndex && startIndex(startYear - lastTenYear);
    }, [startYear]);
    const handlePrevClick = () => {
        if (startYear > lastTenYear) {
            setStartYear((prevYear) => prevYear - 1);
        }
    };
    const handleNextClick = () => {
        if (startYear <= currentYear - 6) {
            setStartYear((prevYear) => prevYear + 1);
        }
    };
    return (_jsxs(MonthYearWrapper, { children: [_jsx(Button, { size: "small", onClick: handlePrevClick, icon: _jsx("img", { src: "/assets/svg/ic-prev.svg" }) }), Array.from([1, 2, 3, 4, 5, 6]).map((_, index) => (_jsx(Button, { style: { background: "#71b9f8", border: "#71b9f8" }, children: startYear + index }, index))), _jsx(Button, { size: "small", onClick: handleNextClick, icon: _jsx("img", { src: "/assets/svg/ic-next.svg" }) })] }));
};
export default YearSelector;
