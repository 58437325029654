import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import ManagerSetting from "./ManagerSetting";
import CalendarSetting from "./CalendarSetting";
import GeofenceSetting from "./GeofenceSetting";
import CommunicationSetting from "./CommunicationSetting";
import LabelSetting from "./LabelSetting";
const PlatformSettingPage = () => (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "", element: _jsx(ManagerSetting, {}) }), _jsx(Route, { path: "/calendar", element: _jsx(CalendarSetting, {}) }), _jsx(Route, { path: "/geofence", element: _jsx(GeofenceSetting, {}) }), _jsx(Route, { path: "/communication", element: _jsx(CommunicationSetting, {}) }), _jsx(Route, { path: "/label", element: _jsx(LabelSetting, {}) })] }) }));
export default PlatformSettingPage;
