import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { getAllOnsiteDonorTestRecords } from "src/pages/PatientRecordsPage/OnSiteRecordsPage/store/action";
import { HeaderFilter } from "../../../components/HeaderFilter";
import { BodyRecords } from "./BodyRecords";
export const TestRecordsTable = ({ donorInfo, setContactInfo, }) => {
    const [displayColumns, setDisplayColumns] = useState([
        "date",
        "time",
        "test",
        "refNo",
        "reqAuth",
        "serviceOffice",
        "collector",
    ]);
    const [startDate, setStartDate] = useState(null);
    const [patientTestListData, setPatientTestListData] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const selectColumn = (curDisplayColumns) => {
        setDisplayColumns(curDisplayColumns);
    };
    const getData = async () => {
        setIsLoading(true);
        const ids = donorInfo.id;
        const idsToSend = (Array.isArray(ids) ? ids : [ids]).filter((id) => id !== undefined);
        const result = await getAllOnsiteDonorTestRecords(idsToSend);
        if (result.status === "success") {
            setPatientTestListData(result.data);
            let email = "";
            let mobile = "";
            // Iterate through the array to find the first non-empty email and mobile
            for (let i = 0; i < result.data.length; i++) {
                const record = result.data[i];
                if (record.email && record.email.trim() !== "") {
                    email = record.email.trim();
                    break;
                }
            }
            for (let i = 0; i < result.data.length; i++) {
                const record = result.data[i];
                if (record.mobile && record.mobile.trim() !== "") {
                    mobile = record.mobile.trim();
                    break;
                }
            }
            // Now set the contact info with the found values
            setContactInfo({ email: email, mobile: mobile });
        }
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
    }, [startDate, endDate]);
    return (_jsxs(PageWrapper, { children: [_jsx(HeaderFilter, { name: "Onsite Test Records", selectColumn: selectColumn, selectStartDate: setStartDate, selectEndDate: setEndDate, startDate: startDate, onSearchString: setSearchString }), _jsx(BodyRecords, { displayColumns: displayColumns, searchString: searchString, patientTestListData: patientTestListData, startDate: startDate, endDate: endDate, isLoading: isLoading })] }));
};
const PageWrapper = styled.div `
  margin: 2rem 5rem;
`;
