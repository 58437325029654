import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Button, Col, Form, Image, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";
import { getAllCountryMapping } from "src/utils/helper";
import { InputWithLabel } from "src/components/base";
import { SAColourScheme, clientType } from "src/constants";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { emailPattern, mobilePattern, postcodePattern, preprocessFields, } from "src/patterns/patterns";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { selectIsLoading } from "src/store/app/selector";
import { ClientType, SwitchComponent } from "../CustomSwitch";
import { createClientDetails } from "./store/action";
import { setClientName } from "./store/slice";
import ContractUpload from "./ContractUpload";
export const AddNewClient = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const isLoading = useAppSelector(selectIsLoading);
    const [type, setType] = useState(ClientType.NON_CALL_OUT);
    const dispatch = useAppDispatch();
    const handleToggle = (value) => {
        setType(value);
    };
    const saveClientDetails = async () => {
        form.validateFields().then(async (values) => {
            const newValues = {
                ...values,
                position: values.position ? values.position : "",
                address2: values.address2 ? values.address2 : "",
                generalInstruction: values.generalInstruction ? values.generalInstruction : "",
                type: values.type ? values.type : ClientType.NON_CALL_OUT,
                id: undefined,
            };
            dispatch(setIsLoading(true));
            const result = await dispatch(createClientDetails(newValues));
            const payload = result.payload;
            if (payload.status === "success") {
                dispatch(setIsLoading(false));
                navigate(`/client-management/client/${payload?.data?.id}/client-details`);
            }
        });
    };
    return (_jsxs(FormWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsx(Form, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 54 }, initialValues: {
                    country: Object.entries(getAllCountryMapping()).find(([code]) => code === "AU")?.[1] ?? "",
                }, onValuesChange: (changedValues, allValues) => {
                    if ("name" in changedValues) {
                        dispatch(setClientName(allValues.name));
                    }
                }, children: _jsxs(NewJobWrapper, { children: [_jsx(Row, { justify: "space-between", className: "edit-button-row", children: _jsxs(Col, { onClick: () => navigate("/client-management"), className: "back-button-col", children: [_jsx("span", { children: _jsx(Image, { src: "/assets/svg/back-icon.svg", preview: false }) }), _jsx("span", { className: "header-text", children: "Back to Client list" })] }) }), _jsx(Row, { gutter: [50, 10], children: _jsx(Col, { span: 24, children: _jsx("span", { className: "header-text", children: "Client" }) }) }), _jsx(Divider, { color: SAColourScheme.PLASMA, className: "divider" }), _jsxs(Row, { gutter: [50, 10], children: [_jsx(Col, { xs: 24, md: 14, children: _jsx(Form.Item, { label: "Client Name", name: "name", rules: [{ required: true, message: "Please input client name" }], children: _jsx(Input, {}) }) }), _jsx(Col, { xs: 24, md: 10, children: _jsx(Form.Item, { label: "Client Reference", name: "clientReference", children: _jsx(Input, { disabled: true }) }) })] }), _jsx(Row, { gutter: [50, 10], children: _jsx(Col, { span: 24, children: _jsx("span", { className: "header-text", children: "Client Contact" }) }) }), _jsx(Divider, { color: SAColourScheme.PLASMA, className: "divider" }), _jsxs(Row, { gutter: [50, 10], children: [_jsx(Col, { xs: 24, md: 14, children: _jsx(Form.Item, { label: "Name", name: "contact", rules: [{ required: true, message: "Please input contact name" }], children: _jsx(Input, {}) }) }), _jsx(Col, { xs: 24, md: 10, children: _jsx(Form.Item, { label: "Mobile", name: "mobile", getValueFromEvent: preprocessFields, rules: [
                                            { required: true, message: "Please input mobile" },
                                            {
                                                pattern: mobilePattern,
                                                message: "Please enter a valid mobile",
                                            },
                                        ], children: _jsx(Input, {}) }) })] }), _jsxs(Row, { gutter: [50, 10], children: [_jsx(Col, { xs: 24, md: 14, children: _jsx(Form.Item, { label: "Position", name: "position", children: _jsx(Input, {}) }) }), _jsx(Col, { xs: 24, md: 10, children: _jsx(Form.Item, { label: "Email", name: "email", rules: [
                                            { required: true, message: "Please input email" },
                                            {
                                                pattern: emailPattern,
                                                message: "Please enter a valid email address",
                                            },
                                        ], children: _jsx(Input, {}) }) })] }), _jsx(Row, { gutter: [50, 10], children: _jsxs(Col, { span: 24, className: "main-address-header", children: [_jsx("span", { className: "header-text", children: "Main Address" }), _jsx(Form.Item, { label: "", name: "country", rules: [{ required: true, message: "Please select country" }], children: _jsx(InputWithLabel, { theme: "3", field: "country", inputProps: {
                                                type: "select",
                                                options: Object.entries(getAllCountryMapping()).map(([_, name]) => ({
                                                    label: name,
                                                    value: name,
                                                })),
                                            } }) })] }) }), _jsx(Divider, { color: SAColourScheme.PLASMA, className: "divider" }), _jsxs(Row, { gutter: [50, 10], children: [_jsx(Col, { xs: 24, md: 12, children: _jsx(Form.Item, { label: "Address Line 1", name: "address1", rules: [{ required: true, message: "Please input address line 1" }], children: _jsx(Input, {}) }) }), _jsx(Col, { xs: 24, md: 12, children: _jsx(Form.Item, { label: "Address Line 2", name: "address2", children: _jsx(Input, {}) }) })] }), _jsxs(Row, { gutter: [50, 10], children: [_jsx(Col, { xs: 24, md: 12, children: _jsx(Form.Item, { label: "City/Suburb", name: "suburb", rules: [{ required: true, message: "Please input suburb" }], children: _jsx(Input, {}) }) }), _jsx(Col, { xs: 24, md: 7, children: _jsx(Form.Item, { label: "State", name: "state", rules: [{ required: true, message: "Please input state" }], children: _jsx(Input, {}) }) }), _jsx(Col, { xs: 24, md: 5, children: _jsx(Form.Item, { label: "Postcode", name: "postCode", getValueFromEvent: preprocessFields, rules: [
                                            { required: true, message: "Please input post code" },
                                            {
                                                pattern: postcodePattern,
                                                message: "Please enter a valid post code",
                                            },
                                        ], children: _jsx(Input, {}) }) })] }), _jsx(Row, { children: _jsx(Col, { span: 24, children: _jsx("span", { className: "header-text", children: "Call Out Client" }) }) }), _jsx(Divider, { color: SAColourScheme.PLASMA, className: "divider" }), _jsxs(Row, { gutter: [55, 10], children: [_jsx(Col, { span: 24, className: "call-out-switch", children: _jsx(Form.Item, { label: "", name: "type", children: _jsx(SwitchComponent, { form: form, name: "type", initialValue: ClientType.NON_CALL_OUT, onToggle: handleToggle }) }) }), type === clientType.CALL_OUT && (_jsx(Col, { span: 24, children: _jsx(Form.Item, { label: "General Call Out Instructions", name: "generalInstruction", children: _jsx(TextArea, { className: "text-area" }) }) }))] }), _jsx(Row, { children: _jsx(Col, { span: 24, children: _jsx("span", { className: "header-text", children: "Contract" }) }) }), _jsx(Divider, { color: SAColourScheme.PLASMA, className: "divider" }), _jsx(ContractUpload, { isEdit: true, isAddNew: true }), _jsxs(Row, { gutter: [25, 10], justify: "end", className: "buttons", children: [_jsx(Col, { xs: 24, md: 4, children: _jsx(Button, { className: "cancel-button", onClick: () => navigate(-1), children: _jsx("span", { children: "Cancel" }) }) }), _jsx(Col, { xs: 24, md: 4, children: _jsx(Button, { className: `save-button enabled`, onClick: () => {
                                            form.validateFields().then(() => {
                                                saveClientDetails();
                                            });
                                        }, children: "Create" }) })] })] }) })] }));
};
export const FormWrapper = styled.div `
  .ant-form legend {
    border: 0px !important;
  }
`;
export const NewJobWrapper = styled.div `
  .edit-img {
    width: 18px;
    margin-right: 8px;
  }

  .back-button-col:hover {
    cursor: pointer !important;
  }

  .edit-button-row {
    margin-bottom: 15px;
  }

  .header-text {
    font-size: 14px;
    font-weight: 700;
  }
  .header-text-2 {
    font-size: 14px;
    margin-right: 3px;
  }

  .upload-contract-row {
    align-items: baseline;
    padding-top: 10px;
  }

  .upload-icon:hover {
    cursor: pointer;
  }

  .upload-file {
    margin-left: 5px;
  }

  .divider {
    border-bottom-width: 2px;
    margin: 10px 0px;
  }

  .ant-radio-inner {
    width: 22px;
    height: 22px;
  }

  .ant-input {
    border-radius: 4px;
  }
  .ant-form-item-label {
    padding: 0 0;
  }

  .main-address-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-form-item {
      margin-bottom: 0px !important;
    }

    .ant-form-item {
      width: 200px !important;
    }
  }

  .call-out-switch {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .text-area {
    min-height: 120px;
  }

  .buttons {
    margin-top: 50px;
  }

  .cancel-button {
    background: ${SAColourScheme.WHITE};
    color: #fd0000;
    border: 1px solid #adb3bc;
    border-radius: 5px;
    width: 100%;
  }

  .ant-btn.save-button {
    border-radius: 5px;
    width: 100%;
    margin-left: 10px;
    color: ${SAColourScheme.WHITE};
  }

  .ant-btn.save-button.enabled {
    border: 1px solid #1a8cff;
    background: #1a8cff;
  }

  .ant-btn.save-button.disabled {
    border: 1px solid white;
    background: #c5c7d1;
  }

  .triangle-right {
    width: 0;
    height: 35px;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-left: 18px solid #61b1f8;
    margin-right: 18px;
  }

  .ant-select .ant-select-selector {
    height: 35px !important;
    background-color: white !important;
    border: 1px solid #d9d9d9 !important;
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    font-size: 15px !important;
    color: #adb3bc !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #edeef0 !important;
  }
`;
