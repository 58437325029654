import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { omit } from "lodash";
import React from "react";
import styled from "styled-components";
import { Col, Image, Row, Text } from "src/components/base";
import { SAColourScheme } from "src/styles";
export const RequestedTestModule = ({ labTestingRequest }) => {
    const propertiesToOmit = [
        "onsiteMedicalSampleId",
        "syntheticCannabinoid",
        "syntheticCathinone",
        "id",
        "testPeriod",
        "analysis",
    ];
    const requestedLabTests = omit(labTestingRequest, propertiesToOmit);
    return (_jsx(ComponentWrapper, { children: _jsxs(Row, { gutter: [50, 10], children: [_jsxs(Col, { xs: 24, lg: 14, style: { borderRight: "1px solid #ADB3BC" }, children: [_jsx(Row, { children: _jsxs(StyledCol, { span: 24, children: [_jsx(Text, { name: "Oral Fluid Drug Test", className: "details-title" }), _jsxs(Col, { children: [_jsx(Image, { src: "/assets/svg/ic-present.svg", preview: false }), _jsx(Text, { name: "= Requested for laboratory test", className: "details-title" })] })] }) }), _jsx(Row, { children: _jsx(Col, { span: 24, children: _jsx(Text, { name: "(AS/NZS 4308)", className: "details-text" }) }) }), _jsx(Row, { children: _jsx(Col, { span: 24, children: _jsx(StyledRowLabTests, { children: Object.entries(requestedLabTests).map(([key, value]) => (_jsxs(React.Fragment, { children: [_jsx(Text, { name: `* ${key.toUpperCase()}`, className: "details-title" }), _jsx(ImageBackground, { children: value && _jsx(Image, { src: "/assets/svg/ic-present.svg", preview: false }) })] }, key))) }) }) })] }), _jsxs(Col, { xs: 24, lg: 10, children: [_jsx(Row, { children: _jsxs(StyledColSynthetic, { span: 24, children: [_jsx(Text, { name: `if Requested`, className: "details-title" }), _jsx(BoldText, { name: ` Non-Standard`, className: "details-title" }), _jsx(Text, { name: ` or *`, className: "details-title" }), _jsx(BoldText, { name: `Synthetic Substance`, className: "details-title" })] }) }), _jsx(Row, { children: _jsx(Col, { span: 24, children: _jsxs(StyledRow, { children: [_jsx(Text, { name: `* Cannabinoid`, className: "details-title" }), _jsx(ImageBackground, { children: labTestingRequest.syntheticCannabinoid && (_jsx(Image, { src: "/assets/svg/ic-present.svg", preview: false })) }), _jsx(Text, { name: `* Cathinone`, className: "details-title" }), _jsx(ImageBackground, { children: labTestingRequest.syntheticCathinone && (_jsx(Image, { src: "/assets/svg/ic-present.svg", preview: false })) })] }) }) })] })] }) }));
};
const ComponentWrapper = styled.div `
  background-color: #f7f8f8;
  margin: 1rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;

  .details-title {
    font-size: 13px !important;
    color: #2f3337 !important;
  }

  .details-text {
    font-size: 12px !important;
    color: #2f3337 !important;
  }

  .details-value {
    color: #00004c !important;
  }

  .ant-divider-horizontal {
    margin: 8px 0;
    border-top: 1px solid ${SAColourScheme.PLASSMA} !important;
  }
`;
const StyledCol = styled(Col) `
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${SAColourScheme.PLASSMA} !important;
`;
const StyledColSynthetic = styled(Col) `
  border-bottom: 1px solid ${SAColourScheme.PLASSMA} !important;
`;
const BoldText = styled(Text) `
  font-weight: 700;
`;
const StyledRow = styled(Row) `
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
`;
const StyledRowLabTests = styled(Row) `
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
`;
const ImageBackground = styled.div `
  background: ${SAColourScheme.WHITE};
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
`;
