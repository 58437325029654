import styled from "styled-components";
export const PinInputStyled = styled.div `
  .pincode-input-text {
    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
`;
