import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Image, TextInput } from "src/components/base";
import { isPasswordStrong } from "src/utils/helper";
import { resetPassword } from "src/api/user/userService";
import { BoxWrapper, CheckBoxCover, SAButtonCustom, SASendBtn, TypographyErrorText, TypographySubText, TypographySubTextSpan, TypographyTitle, } from "src/pages/ForgotPwdPage/index.style";
export const UpdatePwdForm = (props) => {
    const timezone = "Australia/Sydney";
    const [newPwd, setNewPwd] = useState("");
    const [isShowNewPwd, setIsShowNewPwd] = useState(false);
    const [secondPwd, setSecondPwd] = useState("");
    const [isShowSecondPwd, setIsShowSecondPwd] = useState(false);
    const [isSignOutAllDevice, setIsSignOutAllDevice] = useState(true);
    const [errorText, setErrorText] = useState("");
    const [isPwdStrong, setIsPwdStrong] = useState(false);
    const [loading, setLoading] = useState(false);
    const passwordStrength = () => (_jsxs(TypographySubText, { children: ["Password strength:\u00A0", _jsx(TypographySubTextSpan, { children: isPwdStrong ? "Strong" : "" })] }));
    const onTypingNewPwd = (value) => {
        setNewPwd(value);
        if (isPasswordStrong(value)) {
            setIsPwdStrong(true);
        }
        else {
            setIsPwdStrong(false);
        }
    };
    const onTypingSecondPwd = (value) => {
        setSecondPwd(value);
        if (newPwd !== value) {
            setErrorText("Password does not match");
        }
        else {
            setErrorText("");
        }
    };
    const onUpdatePwd = async () => {
        setLoading(true);
        // Check condition
        if (newPwd.length <= 0) {
            setErrorText("Please enter your new password");
            setLoading(false);
            return;
        }
        if (newPwd !== secondPwd) {
            setErrorText("Password does not match");
            return;
        }
        if (!isPasswordStrong(newPwd)) {
            setIsPwdStrong(false);
            setErrorText("Password has to be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character.");
            setLoading(false);
            return;
        }
        else {
            setErrorText("");
        }
        const data = await resetPassword(newPwd, timezone, isSignOutAllDevice);
        if (data.status == "success") {
            props.onUpdateSuccess();
        }
        else {
            setErrorText("Something went wrong!");
        }
        setLoading(false);
    };
    return (_jsxs(BoxWrapper, { children: [_jsx(Image, { src: "/assets/svg/ic-lock2.svg", preview: false }), _jsx(TypographyTitle, { children: "Update your password" }), _jsx(TextInput, { className: "pwd-input", style: { marginTop: "37px" }, rightIcon: _jsx(Image, { src: "/assets/svg/ic-look.svg", style: { marginTop: "-3px", marginRight: "10px" }, onClick: () => setIsShowNewPwd(!isShowNewPwd) }), value: newPwd, onChange: onTypingNewPwd, placeholder: "Enter new password", type: isShowNewPwd ? "text" : "password" }), passwordStrength(), _jsx(TextInput, { className: "pwd-input", style: { marginTop: "3px" }, rightIcon: _jsx(Image, { src: "/assets/svg/ic-look.svg", style: { marginTop: "-3px", marginRight: "10px" }, onClick: () => setIsShowSecondPwd(!isShowSecondPwd) }), value: secondPwd, onChange: onTypingSecondPwd, placeholder: "Re-type password", type: isShowSecondPwd ? "text" : "password" }), _jsx(TypographyErrorText, { children: errorText }), _jsxs(CheckBoxCover, { children: [_jsx(SAButtonCustom, { icon: _jsx(CheckOutlined, { style: {
                                fontSize: "9.69px",
                                color: isSignOutAllDevice ? "#1A8CFF" : "#FFFFFF",
                            } }), onClick: () => {
                            setIsSignOutAllDevice(!isSignOutAllDevice);
                        } }), _jsx(TypographySubText, { children: "Sign out of all active logins" })] }), _jsx(SASendBtn, { name: "Update Password", onClick: onUpdatePwd, loading: loading })] }));
};
