import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, Dropdown, List, Menu, Popover, Space, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { uuidv4 } from "@antv/xflow-core";
import CloseButtonIcon from "src/assets/svg/job-management/close-button-icon.svg";
import ViewCollectorIcon from "src/assets/svg/job-management/view-collector-icon.svg";
import { PaginatedDataTable } from "src/components/commonElement/PaginatedDataTable";
import SAStatusButton from "src/components/Form/SAStatusButton";
import { mainAppColours } from "src/constants";
import { archiveJob } from "src/pages/JobManagement/store/action";
import { getApplicationInformation } from "src/store/app/selector";
import { TimeFormat } from "src/store/app/types";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { OnsiteJobAllocationStatus, OnsiteJobStatus, OnsiteJobType, } from "../store/types";
import { DisplayedJobStatuses, DisplayOnsiteJobAllocationStatus, OnsiteJobServiceTypeName, } from "../typing";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
const JobTable = ({ jobData, pagingData, onTableChange }) => {
    const parentLocation = useLocation();
    const [open, setOpen] = useState(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const appInformation = useAppSelector(getApplicationInformation);
    const closePopover = () => {
        setOpen(null);
    };
    const handleOpenPopoverChange = (openId) => {
        setOpen(openId);
    };
    const columns = [
        {
            title: "Date",
            dataIndex: "startAt",
            key: "startAt",
            sorter: true,
            render: (_, record) => (_jsx(Space, { children: record.startAt &&
                    moment(record.startAt).format(`DD MMM YYYY, ${appInformation?.collectionOrganization.timeFormat === TimeFormat.TwelveHour
                        ? "hh:mm A"
                        : "HH:mm"}`) })),
        },
        {
            title: "Client",
            dataIndex: "clientName",
            key: "clientName",
            sorter: true,
        },
        {
            title: "Authorised Rep",
            dataIndex: "clientRepresentative",
            key: "clientRepresentative",
            sorter: true,
        },
        {
            title: "Location",
            dataIndex: "location",
            key: "location",
            sorter: true,
        },
        {
            title: "Service",
            dataIndex: "serviceType",
            key: "serviceType",
            sorter: true,
            render: (_, record) => (_jsx(Space, { children: OnsiteJobServiceTypeName[record.serviceType] })),
        },
        {
            title: "Call Out Job",
            dataIndex: "type",
            key: "type",
            align: "center",
            sorter: true,
            render: (type) => _jsx(Space, { children: type === OnsiteJobType.Callout ? "Call Out" : "" }),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            sorter: true,
            render: (_, record) => {
                const status = DisplayedJobStatuses[record.status];
                return _jsx(SAStatusButton, { color: status?.color, children: status?.label });
            },
        },
        {
            title: "Collector",
            dataIndex: "collectors",
            key: "collectors",
            align: "center",
            render: (_, record) => (_jsx(CustomPopover, { content: _jsx(CardContent, { title: _jsxs(CardHeader, { children: [_jsx(Typography.Title, { level: 5, children: "Collectors" }), _jsx(Button, { type: "text", icon: _jsx(CloseButtonIcon, {}), onClick: closePopover })] }), children: _jsx(List, { itemLayout: "horizontal", dataSource: record.collectors, renderItem: (collector, index) => {
                            const status = DisplayOnsiteJobAllocationStatus[collector.status];
                            return (_jsxs(List.Item, { children: [_jsx(List.Item.Meta, { title: _jsxs(CollectorNameText, { status: collector.status, children: [collector.firstName, " ", collector.lastName] }) }), _jsx(SAStatusButton, { color: status?.color, children: status?.label })] }, index));
                        } }) }), trigger: "click", placement: "bottomRight", open: open === record.onsiteJobId, onOpenChange: () => handleOpenPopoverChange(record.onsiteJobId), children: _jsx(Button, { type: "text", icon: _jsx(ViewCollectorIcon, {}) }) })),
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(StyledMenu, { children: [_jsx(StyledMenuItem, { onClick: () => {
                                navigate(`${parentLocation.pathname}/${record.onsiteJobId}/${record.type === OnsiteJobType.Normal ? "normal" : "call-out"}`);
                            }, children: record.status === OnsiteJobStatus.Planned ||
                                record.status === OnsiteJobStatus.Draft
                                ? "Manage Job"
                                : "View Details" }, "ManageJob"), record.status === OnsiteJobStatus.Cancelled ||
                            (record.status === OnsiteJobStatus.Draft && (_jsx(StyledMenuItem, { onClick: () => {
                                    record.status !== OnsiteJobStatus.Archived &&
                                        dispatch(archiveJob(record.onsiteJobId));
                                }, children: _jsx(ArchiveSpan, { children: "Delete" }) }, "Delete")))] }), trigger: ["click"], children: _jsx(ICMenuOptions, {}) })),
        },
    ];
    return (_jsx(PaginatedDataTable, { columns: columns, dataSource: jobData.map((job) => ({ ...job, id: uuidv4() })), pagingMetaData: pagingData, onTableChange: onTableChange, rowKey: "id" }));
};
export default JobTable;
const CustomPopover = styled(Popover) `
  div.ant-popover-inner-content {
    padding: 0 !important;
    border: 0.1rem solid #1a8cff !important;
  }
`;
const CardContent = styled(Card) `
  width: 20rem;
  .ant-card-head {
    border-bottom: 0.2rem solid #01b4d2;
  }
`;
const CardHeader = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    color: #00b4d1;
  }
`;
const CollectorNameText = styled(Typography.Text) `
  color: ${(props) => props.status === OnsiteJobAllocationStatus.Rejected ? "#FD0000" : "#000000"};
`;
const StyledMenu = styled(Menu) `
  border: 1px solid ${mainAppColours.MID_BLUE};
  width: 150px;
`;
const StyledMenuItem = styled(Menu.Item) `
  &:not(:first-child) {
    border-top: 1px solid #adb3bc;
    width: 80%;
    margin: 0 auto;
  }
`;
const ArchiveSpan = styled.span `
  display: flex;
  justify-content: flex-end;
`;
