import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectLegal } from "src/pages/Legal/store/selector";
import { getLegal } from "src/pages/Legal/store/action";
import { LEGAL, LEGAL_APP } from "src/constants";
export const useGetLegal = () => {
    const { legalAppType, legalType } = useParams();
    const legalHeaderParam = LEGAL_APP[legalAppType];
    const legalTypeHeaderParam = LEGAL[legalType];
    const legal = useAppSelector(selectLegal);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getLegal({ legalType, legalAppType }));
    }, [legalType, legalAppType, dispatch]);
    const shouldDisplayHeader = () => {
        switch (legalAppType) {
            case LEGAL_APP["collect-assist-onsite"].module:
                return false;
            default: {
                return true;
            }
        }
    };
    const isValidUrl = !!(legalAppType && legalHeaderParam && legalType && legalTypeHeaderParam);
    return {
        legalHeaderParam,
        legalTypeHeaderParam,
        legalData: legal.content,
        displayHeader: shouldDisplayHeader(),
        isValidUrl,
    };
};
