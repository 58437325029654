import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getListInvalidResult, getTestTypesData } from "../store/action";
import { selectCurrentTestType, selectListInvalidResults, selectTestTypes, selectedEndDate, selectedStartDate, selectedToday, } from "../store/selector";
import { HeaderFilter } from "./HeaderFilter";
import { RecordsTable } from "./RecordsTable";
export const InvalidReport = () => {
    const dispatch = useAppDispatch();
    const invalidResultData = useAppSelector(selectListInvalidResults);
    const isLoading = useAppSelector(selectIsLoading);
    const testTypes = useAppSelector(selectTestTypes);
    const selectedTestType = useAppSelector(selectCurrentTestType);
    const isSelectedToday = useAppSelector(selectedToday);
    const startDate = useAppSelector(selectedStartDate);
    const endDate = useAppSelector(selectedEndDate);
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getTestTypesData());
            await dispatch(getListInvalidResult());
            dispatch(setIsLoading(false));
        };
        loadData();
    }, []);
    return (_jsxs(React.Fragment, { children: [isLoading && _jsx(OverlayLoading, {}), _jsx(HeaderFilter, { testTypes: testTypes, selectedTestType: selectedTestType, isSelectedToday: isSelectedToday, startDate: startDate, endDate: endDate }), _jsx(RecordsTable, { invalidResultData: invalidResultData, selectedTestType: selectedTestType, isSelectedToday: isSelectedToday, startDate: startDate, endDate: endDate })] }));
};
