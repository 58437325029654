import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from "react";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import { Input, Select, DatePicker, TimePicker, AutoComplete, Form, Tooltip, TreeSelect, } from "src/components/base";
import { InputWithLabelWrapper, OptionsDropdownWrapper } from "./index.style";
const { Option } = Select;
const defaultDateFormat = "DD/MM/YYYY";
const defaultTimeFormat = "HH:mm";
const InputWithLabel = ({ label, field, fieldKey, value, required, inputProps, onInputChange = () => { }, rules, rightComponent, className, theme, onSelect = () => { }, }) => {
    const inputRef = useRef(null);
    const [autocompleteOptions, setAutocompleteOptions] = useState(null);
    const renderInput = () => {
        if (inputProps?.type === "select" && inputProps?.options) {
            return (_jsx(Select, { placeholder: inputProps?.placeholder || "- Select -", mode: inputProps?.mode, dropdownRender: (originElement) => (_jsx(OptionsDropdownWrapper, { children: originElement })), maxTagCount: "responsive", value: value, onChange: (val) => {
                    onInputChange && onInputChange({ field, value: val });
                }, disabled: inputProps?.disabled, allowClear: inputProps?.allowClear || false, onClear: inputProps?.onClear, children: inputProps?.options?.map((item, index) => (_jsx(Option, { value: item.value, children: item.tooltip ? (_jsx(Tooltip, { title: item.tooltip, color: "white", overlayInnerStyle: {
                            color: "black",
                            fontSize: 11,
                            border: "1px solid #707070",
                            minHeight: 17,
                            padding: 0,
                        }, children: _jsx("div", { style: { width: "100%" }, children: item.label }) })) : (_jsx("div", { style: { width: "100%" }, children: item.label })) }, `${item.value}-${index}`))) }));
        }
        if (inputProps?.type === "treeselect" && inputProps?.options) {
            return (_jsx(TreeSelect, { ...inputProps, placeholder: inputProps?.placeholder || "- Select -", treeData: inputProps?.options, maxTagCount: "responsive", value: value, onChange: (val) => {
                    onInputChange({ field, value: val });
                }, disabled: inputProps?.disabled }));
        }
        if (inputProps?.type === "date") {
            let placeholder = defaultDateFormat;
            if (inputProps?.placeholder !== undefined) {
                placeholder = inputProps?.placeholder;
            }
            if (inputProps?.format !== undefined) {
                placeholder = inputProps?.format;
            }
            return (_jsx(DatePicker, { ref: inputRef, ...inputProps, placeholder: placeholder, format: inputProps?.format || defaultDateFormat, value: value, onChange: (val) => {
                    onInputChange &&
                        onInputChange({
                            field,
                            value: val,
                        });
                }, onKeyDown: (event) => {
                    const { keyCode, target } = event;
                    if (keyCode === 13) {
                        const val = moment(target?.value, "DDMMYYYY");
                        onInputChange &&
                            onInputChange({
                                field,
                                value: val,
                            });
                        inputRef?.current?.blur();
                    }
                }, disabled: inputProps?.disabled }));
        }
        if (inputProps?.type === "time") {
            return (_jsx(TimePicker, { ref: inputRef, popupClassName: inputProps?.hideConfirm ? "hide-confirm" : "", dropdownClassName: inputProps?.hideConfirm ? "hide-confirm" : "", placeholder: inputProps?.placeholder || inputProps?.format || defaultTimeFormat, format: inputProps?.format || defaultTimeFormat, value: value, onChange: (val) => {
                    onInputChange &&
                        onInputChange({
                            field,
                            value: val,
                        });
                }, onKeyDown: (event) => {
                    if (onInputChange && event.keyCode === 13) {
                        onInputChange({
                            field,
                            value: moment(event.target.value, inputProps?.format || defaultTimeFormat),
                        });
                        inputRef?.current?.blur();
                    }
                }, disabled: inputProps?.disabled, minuteStep: inputProps?.minuteStep, onSelect: (val) => {
                    inputProps?.onSelect &&
                        inputProps?.onSelect({
                            field,
                            value: val,
                        });
                } }));
        }
        if (inputProps?.type === "autocomplete") {
            return (_jsx(AutoComplete, { options: autocompleteOptions, placeholder: inputProps?.placeholder, value: value, onChange: (val) => {
                    onInputChange && onInputChange({ field, value: val });
                }, onSelect: (val) => {
                    onInputChange && onInputChange({ field, value: val });
                    onSelect && onSelect({ field, value });
                }, onSearch: (val) => {
                    if (val.length > 0) {
                        setAutocompleteOptions(inputProps?.options.filter((item) => {
                            if (item.value.toLowerCase().includes(val.toLowerCase())) {
                                return item;
                            }
                        }));
                    }
                    else {
                        setAutocompleteOptions([]);
                    }
                }, onFocus: () => {
                    if (inputProps.showAllValues) {
                        setAutocompleteOptions(inputProps.options);
                    }
                }, disabled: inputProps?.disabled }));
        }
        return (_jsx(Input, { ...inputProps, value: value, onChange: ({ target: { value: val } }) => {
                onInputChange && onInputChange({ field, value: val });
            } }));
    };
    return (_jsxs(InputWithLabelWrapper, { className: `${className ?? ""} ${theme ? "theme-" + theme : ""}${inputProps && inputProps.hidden ? " hidden" : ""}`, children: [_jsxs(Text, { className: "input-label", children: [label, " ", required && _jsx(Text, { className: "required", children: "*" })] }), rightComponent ? (_jsxs("div", { style: { display: "flex" }, children: [_jsx(Form.Item, { name: !fieldKey ? field : [field, ...fieldKey], rules: rules, hidden: inputProps?.hidden, children: renderInput() }), rightComponent] })) : (_jsx(Form.Item, { name: !fieldKey ? field : [field, ...fieldKey], rules: rules, hidden: inputProps?.hidden, children: renderInput() }))] }));
};
export default InputWithLabel;
