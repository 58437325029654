/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { $get, $post, $put } from "src/infra/http";
const getAllUserRoles = createAsyncThunk("getAllUserRoles", async () => await $get("/collection-manager/cm-user/roles"));
const getListUsers = createAsyncThunk("getListUsers", async () => await $get("/collection-manager/cm-user/list"));
const inviteCmUser = createAsyncThunk("inviteUser", async (data) => await $post(`/collection-manager/cm-user/invite`, data));
const updateUserRolesOrTitle = createAsyncThunk("updateUserRolesOrTitle", async (data) => {
    const { adminId, ...rest } = data;
    return await $put(`/collection-manager/cm-user/${adminId}`, rest);
});
const reInviteCmUser = createAsyncThunk("reInviteCmUser", async (userId) => await $get(`/collection-manager/cm-user/re-invite/${userId}`));
const suspendCmUser = createAsyncThunk("suspendCmUser", async (userId) => await $get(`/collection-manager/cm-user/suspend/${userId}`));
const deleteCmUser = createAsyncThunk("deleteCmUser", async (userId) => await $get(`/collection-manager/cm-user/delete/${userId}`));
const restoreCmUser = createAsyncThunk("restoreCmUser", async (userId) => await $get(`/collection-manager/cm-user/restore/${userId}`));
const getDetailCmUser = createAsyncThunk("getDetailCmUser", async (userId) => await $get(`/collection-manager/cm-user/${userId}`));
const getPermissionDetail = createAsyncThunk("getDetailCmUser", async (roleId) => await $get(`/collection-manager/cm-user/role-detail/${roleId}`));
export { getAllUserRoles, getListUsers, inviteCmUser, reInviteCmUser, suspendCmUser, deleteCmUser, restoreCmUser, getDetailCmUser, getPermissionDetail, updateUserRolesOrTitle, };
