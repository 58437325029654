import { createSlice } from "@reduxjs/toolkit";
import { getAppInformation } from "./action";
import { appInitialState } from "./types";
export const initialState = {
    app: appInitialState,
    isLoading: false,
};
const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAppInformation.fulfilled, (state, action) => {
            const data = action.payload.data;
            if (data) {
                state.app = data;
            }
            else {
                state.app = appInitialState;
            }
        })
            .addCase(getAppInformation.rejected, (state) => {
            state.app = appInitialState;
        });
    },
});
export const { setIsLoading } = appSlice.actions;
export default appSlice.reducer;
