import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControlLabel, Switch } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getCollectionManagerSetting, updateCommunicationSetting, } from "src/api/settings/settingService";
import ContentContext from "src/providers/content";
const CommunicationSetting = () => {
    const navigate = useNavigate();
    const [isSendTextMessage, setIsSendTextMessage] = useState(false);
    const [isSendEmail, setIsSendEmail] = useState(false);
    const [isSendNotification, setIsSendNotification] = useState(false);
    const { openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const handleCommunicationUpdate = async (event) => {
        let sms = isSendTextMessage;
        let email = isSendEmail;
        let notification = isSendNotification;
        if (event.target.value === "SMS") {
            sms = event.target.checked;
            setIsSendTextMessage(!isSendTextMessage);
        }
        else if (event.target.value === "Email") {
            email = event.target.checked;
            setIsSendEmail(!isSendEmail);
        }
        else if (event.target.value === "App") {
            notification = event.target.checked;
            setIsSendNotification(!isSendNotification);
        }
        const body = {
            isSendNotification: notification,
            isSendEmail: email,
            isSendTextMessage: sms,
        };
        await updateCommunicationSetting(body);
    };
    useEffect(() => {
        getSettingData();
    }, []);
    const getSettingData = async () => {
        openLoadingScreen();
        const result = await getCollectionManagerSetting();
        setIsSendTextMessage(result.organizationSetting.isSendTextMessage);
        setIsSendEmail(result.organizationSetting.isSendEmail);
        setIsSendNotification(result.organizationSetting.isSendNotification);
        closeLoadingScreen();
    };
    return (_jsxs(ContentWrapper, { children: [_jsxs("div", { style: { display: "flex", gap: "10px", margin: "10px", alignItems: "center" }, children: [_jsx(Button, { onClick: () => navigate(-1), startIcon: _jsx("img", { src: "/assets/svg/ic-arrow-left.svg", alt: "left-arrow" }) }), _jsx(Typography, { variant: "h6", children: "Communication Settings" })] }), _jsx(Divider, { style: { width: "100%" } }), _jsxs("div", { style: { marginLeft: "10%", display: "flex", flexDirection: "column", gap: "40px" }, children: [_jsx("p", { children: "Out Bound Notification Settings" }), _jsx(Card, { variant: "outlined", style: { width: "60%", borderRadius: "10px" }, children: _jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "30px", margin: "15% 20%" }, children: [_jsx(FormControlLabel, { value: "SMS", control: _jsx(Switch, { color: "primary", checked: isSendTextMessage, onChange: handleCommunicationUpdate }), label: "Text Messages (SMS)", labelPlacement: "start", style: { borderBottom: "0.5px solid #01B4D2", justifyContent: "space-between" } }), _jsx(FormControlLabel, { value: "Email", control: _jsx(Switch, { color: "primary", checked: isSendEmail, onChange: handleCommunicationUpdate }), label: "Email Notifications", labelPlacement: "start", style: { borderBottom: "0.5px solid #01B4D2", justifyContent: "space-between" } }), _jsx(FormControlLabel, { value: "App", control: _jsx(Switch, { color: "primary", checked: isSendNotification, onChange: handleCommunicationUpdate }), label: "App Notifications", labelPlacement: "start", style: { borderBottom: "0.5px solid #01B4D2", justifyContent: "space-between" } })] }) })] })] }));
};
export default CommunicationSetting;
const ContentWrapper = styled.div `
  background-color: #fafafa;
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
