import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PauseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import styled from "styled-components";
import { IconButton, Image, Text } from "src/components/base";
import { AudioPlayerVisualizer } from "./AudioPlayerVisualizer";
export const AudioPopover = ({ audioData }) => {
    const [selectedAudio, setSeletectedAudio] = useState(audioData[0]?.url);
    const handleSelectAudio = (audioUrl) => {
        setSeletectedAudio(audioUrl);
    };
    return (_jsxs(ComponentWrapper, { children: [_jsx(AudioPlayerVisualizer, { src: selectedAudio, customIcons: {
                    rewind: _jsx(Image, { src: "/assets/svg/ic-rewind.svg", preview: false, height: 30 }),
                    forward: _jsx(Image, { src: "/assets/svg/ic-forward.svg", preview: false, height: 30 }),
                    play: _jsx(Image, { src: "/assets/svg/ic-play-audio.svg", preview: false, height: 30 }),
                    pause: _jsx(PauseOutlined, {}),
                }, progressJumpStep: 10000, autoPlay: false, autoPlayAfterSrcChange: false }), _jsx(AudioListWrapper, { children: audioData.length > 0 &&
                    audioData.map((audio, index) => (_jsxs(AudioWrapperDiv, { isSelected: selectedAudio === audio.url, onClick: () => handleSelectAudio(audio.url), children: [_jsx(IconButton, { icon: selectedAudio === audio.url
                                    ? "/assets/svg/audio-icon-white.svg"
                                    : "/assets/svg/audio-icon-grey.svg" }), _jsx(Text, { name: index + 1 })] }, index))) })] }));
};
const AudioListWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;
const AudioWrapperDiv = styled.div `
  background-color: ${(props) => (props.isSelected ? "#156cc9" : "#EBEDF0")};
  border-radius: 10px;
  width: 3.5rem;
  padding: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;

  .ant-image-img {
    height: 20px;
  }
  .ant-typography {
    color: ${(props) => (props.isSelected ? "#ffffff" : "#7F8E9D") + "!important"};
    font-size: 1rem;
  }
`;
const ComponentWrapper = styled.div `
  .rhap_container {
    box-shadow: none !important;
    padding: 0px;
    margin-bottom: 1rem;
  }

  .anticon {
    color: #000000 !important;
    margin-top: 2px;
  }
  .rhap_progress-indicator {
    background: #0a84ff;
  }
  .rhap_controls-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d1d5db;
    padding: 10px;
    margin-top: 1rem;
  }

  .rhap_additional-controls,
  .rhap_volume-controls {
    display: none !important;
  }

  .rhap_progress-filled {
    background-color: #156cc9;
  }
`;
