import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Dropdown, Image, Menu } from "antd";
import styled from "styled-components";
import { FileMenuOption, UploadFileType } from "../typing";
const FileOptionsDropdown = ({ src, Fallback, size = "medium", type, onMenuItemClick = () => { }, }) => {
    let height;
    switch (size) {
        case "small":
            height = "9.6rem";
            break;
        case "medium":
            height = "14rem";
            break;
        case "large":
            height = "20.7rem";
            break;
        default:
            height = "14rem";
    }
    const menuItems = [
        {
            key: FileMenuOption.Upload,
            label: (_jsx(CustomMenuItem, { onClick: () => onMenuItemClick(FileMenuOption.Upload), children: "Upload file" })),
        },
        {
            key: FileMenuOption.View,
            label: (_jsx(CustomMenuItem, { onClick: () => onMenuItemClick(FileMenuOption.View), children: "View file" })),
        },
        {
            key: FileMenuOption.Delete,
            label: (_jsx(CustomMenuItem, { onClick: () => onMenuItemClick(FileMenuOption.Delete), children: "Delete file" })),
        },
    ];
    return (_jsx(Dropdown, { overlay: _jsx(StyledMenu, { items: menuItems }), placement: "bottom", trigger: ["click"], children: _jsx(ImageContainer, { height: height, onClick: (e) => e.preventDefault(), children: src && type === UploadFileType.Image ? (_jsx(FullImage, { src: src, preview: false })) : (_jsx(Fallback, {})) }) }));
};
export default FileOptionsDropdown;
const CustomMenuItem = styled.div `
  padding: 0.5rem 1rem;
  color: #333;
  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  border-radius: 0.25rem;
  border: 1px solid #ddd;
`;
const StyledMenu = styled(Menu) `
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  padding: 1rem 0;
  width: fit-content;
  margin: auto;
`;
const ImageContainer = styled.div `
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 8rem;
  border-radius: 0.25rem;
  height: ${({ height }) => height};
  overflow: hidden;
  cursor: pointer;

  .ant-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const FullImage = styled(Image) `
  width: 100%;
  height: 100%;
  padding: 1rem;
  object-fit: contain;
  object-position: center;
  border: none;
`;
