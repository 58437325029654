const selectPagingMetaData = (state) => state.clientManagementPage.clientDetailsPage.pagingMetaData;
const selectPagingMetaDataArchive = (state) => state.clientManagementPage.clientDetailsPage.pagingMetaDataArchive;
const selectSearchString = (state) => state.clientManagementPage.clientDetailsPage.searchString;
const selectClientList = (state) => state.clientManagementPage.clientDetailsPage.listClientDetails;
const selectArchivedClientList = (state) => state.clientManagementPage.clientDetailsPage.listArchivedClientDetails;
const selectClientDetails = (state) => {
    const clientDetails = state.clientManagementPage.clientDetailsPage.clientDetails;
    return {
        name: clientDetails?.name,
        id: clientDetails?.id,
        clientReference: clientDetails?.referenceNumber,
        contact: clientDetails?.contact,
        mobile: clientDetails?.mobile,
        position: clientDetails?.position,
        email: clientDetails?.email,
        country: clientDetails?.country,
        address1: clientDetails?.address1,
        address2: clientDetails?.address2,
        suburb: clientDetails?.suburb,
        state: clientDetails?.state,
        postCode: clientDetails?.postCode,
        type: clientDetails?.type,
        generalInstruction: clientDetails?.generalInstruction,
    };
};
const selectIsArchiveTableVisible = (state) => state.clientManagementPage.clientDetailsPage.archiveTableVisible;
const selectIsArchiveContractsTableVisible = (state) => state.clientManagementPage.clientDetailsPage.archiveContractsTableVisible;
const selectIsEdit = (state) => state.clientManagementPage.clientDetailsPage.isEdit;
const listClientTypes = (state) => state.clientManagementPage.clientDetailsPage.listClientType;
const selectedClientType = (state) => state.clientManagementPage.clientDetailsPage.selectedClientType;
const selectClientName = (state) => state.clientManagementPage.clientDetailsPage.clientName;
const selectClientContracts = (state) => state.clientManagementPage.clientDetailsPage.clientContracts;
export { selectSearchString, selectPagingMetaData, selectPagingMetaDataArchive, selectClientList, selectArchivedClientList, selectClientDetails, selectIsArchiveTableVisible, selectIsArchiveContractsTableVisible, selectIsEdit, listClientTypes, selectedClientType, selectClientName, selectClientContracts, };
