import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Image, Select } from "antd";
import Text from "antd/lib/typography/Text";
import styled from "styled-components";
import { mainAppColours } from "src/styles";
import { useAppDispatch } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { setCreateNoteFlag, setCurrentLocation } from "./store/slice";
export const NotesHeader = ({ clientName, locationsData, selectedLocation, }) => {
    const dispatch = useAppDispatch();
    return (_jsxs(PageWrapper, { children: [_jsx(ClientNameWrapper, { children: clientName }), _jsxs(FilterWrapper, { children: [_jsxs(RowWrapper, { children: [_jsx(Text, { className: "text", children: "Location" }), _jsx(Select, { className: "status-select", size: "small", options: locationsData, value: selectedLocation.value, onChange: (_, option) => {
                                    const selectedValue = option;
                                    dispatch(setIsLoading(true));
                                    dispatch(setCurrentLocation({ id: selectedValue.value, siteName: selectedValue.label }));
                                    setTimeout(() => {
                                        dispatch(setIsLoading(false));
                                    }, 1000);
                                } })] }), _jsxs(Button, { className: "add-new", onClick: () => dispatch(setCreateNoteFlag(true)), children: [_jsx(ImageCreate, { src: "/assets/svg/ic-create.svg", preview: false }), _jsx("span", { children: "Create" })] })] })] }));
};
const PageWrapper = styled.div `
  border-bottom: 2px solid #d3d6db;
`;
const ClientNameWrapper = styled.div `
  background: #cbf5fc;
  color: #000000;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
`;
const FilterWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 100% !important;
  gap: 1rem;

  .status-select {
    max-width: 200px !important;
  }

  .add-new {
    border: 1px solid ${mainAppColours.MID_BLUE};
    border-radius: 15px;
    color: #1a8cff;
  }

  .ant-image-img {
    margin-bottom: 3px;
  }

  //properties for screen size of md
  @media screen and (max-width: 996px) {
    display: flex;
    flex-direction: column;

    .ant-btn {
      width: 100% !important;
    }
  }
`;
const RowWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;
const ImageCreate = styled(Image) `
  height: 20px;
  width: 20px;
  margin-right: 0.5rem;
`;
