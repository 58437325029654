import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, ButtonSelection, DatePicker, Image, Row, SearchInput, Text, } from "src/components/base";
import { supportingColours } from "src/constants";
import { useAppSelector } from "src/store/hooks";
import { getCollectionPointInformation } from "src/store/app/selector";
import { HeaderFilterWrapper } from "./HeaderFilterWrapper.style";
import ShowModal from "./ShowModal";
import Calender from "./Calender";
export const HeaderFilter = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [valueList, setValueList] = useState([]);
    const collectionPointsData = useAppSelector(getCollectionPointInformation);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = (displayColumns) => {
        props.selectColumn(displayColumns);
        setIsModalVisible(false);
    };
    const onSearchString = (searchString) => {
        const delayTimer = setTimeout(() => {
            props.onSearchString(searchString);
        }, 1000);
        clearTimeout(delayTimer);
    };
    useEffect(() => {
        if (collectionPointsData && collectionPointsData.length) {
            const list = collectionPointsData.map((collectionPoint) => ({
                value: collectionPoint.id,
                label: collectionPoint.collectionPointName,
            }));
            setValueList([{ value: "All", label: "All" }, ...list]);
        }
    }, [collectionPointsData]);
    return (_jsxs(HeaderFilterWrapper, { children: [_jsxs(Row, { justify: "space-between", children: [_jsxs("div", { style: {
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-end",
                            gap: "1rem",
                        }, children: [_jsx(Calender, { selectStartDate: props.selectStartDate, selectEndDate: props.selectEndDate }), _jsxs("div", { style: { display: "flex", flexDirection: "column" }, children: [_jsx("div", { style: { textAlign: "start" }, children: _jsx(Text, { name: "Collection Point", style: { fontSize: "16px" } }) }), _jsx("div", { style: { width: "250px" }, children: _jsx(ButtonSelection, { className: "button-selection", valueList: valueList, defaultValue: "All", onChange: props.selectCollectionPoint }) })] })] }), _jsxs("div", { style: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-end",
                            gap: "5px",
                        }, children: [_jsxs(Row, { justify: "end", align: "middle", style: { gap: "1rem" }, children: [_jsx(Text, { name: "Start Date", style: {
                                            color: supportingColours.TEXT_MID_GRAY,
                                            fontSize: "15px",
                                        } }), _jsx(DatePicker, { className: "start-date", defaultValue: moment(), format: "DD/MM/YYYY", onChange: props.selectStartDate, clearIcon: null, value: props.startDate }), _jsx(Text, { name: "End Date", style: {
                                            color: supportingColours.TEXT_MID_GRAY,
                                            fontSize: "15px",
                                        } }), _jsx(DatePicker, { className: "end-date", defaultValue: moment(), format: "DD/MM/YYYY", onChange: props.selectEndDate, clearIcon: null, disabledDate: (current) => current && current < props.startDate, value: props.endDate })] }), _jsxs(Row, { style: { display: "flex", gap: "15px" }, children: [_jsx(Button, { onClick: showModal, className: "select-button", icon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false, height: 7, width: 14 }), children: "Show" }), _jsx(SearchInput, { onChange: (event) => onSearchString(event.target.value) })] })] })] }), _jsx(ShowModal, { isModalVisible: isModalVisible, handleCancel: handleCancel })] }));
};
