import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch } from "antd";
import React from "react";
import styled from "styled-components";
const SASwitch = ({ uncheckedLabel = "", checkedLabel = "", ...props }) => (_jsxs(Container, { children: [_jsx(TextLabel, { children: uncheckedLabel }), _jsx(Switch, { ...props }), _jsx(TextLabel, { children: checkedLabel })] }));
export default SASwitch;
const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: fit-content;
`;
const TextLabel = styled.p `
  font-size: 0.875rem;
`;
