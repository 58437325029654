import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { IconButton } from "src/components/base";
import { HtmlAttachment } from "../components/HtmlAttachment";
export const columnConfig = [
    {
        title: "Code",
        dataIndex: "code",
        key: "code",
        width: 80,
    },
    {
        title: "Area",
        dataIndex: "area",
        key: "area",
        width: 100,
    },
    {
        title: "Audience",
        dataIndex: "audience",
        key: "audience",
        width: 50,
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: 150,
    },
    {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 60,
        render: (type) => type.charAt(0).toUpperCase() + type.slice(1),
    },
    {
        title: "Template",
        dataIndex: "templateHtml",
        key: "templateHtml",
        width: 60,
        render: (templateHtml) => templateHtml ? (_jsx("div", { style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }, children: _jsx(HtmlAttachment, { templateHtml: templateHtml }) })) : (""),
    },
    {
        title: "Uploaded",
        dataIndex: "uploaded",
        key: "uploaded",
        render: (uploaded) => {
            const { _seconds: seconds, _nanoseconds: nanoseconds } = uploaded;
            const date = new Date(seconds * 1000 + nanoseconds / 1e6);
            return moment(date).format("DD/MM/YYYY");
        },
        width: 70,
    },
    {
        title: "Approval",
        dataIndex: "approval",
        key: "approval",
        render: () => (_jsx("div", { style: { textAlign: "center" }, children: _jsx(IconButton, { icon: "/assets/svg/ic-approve.svg" }) })),
        width: 50,
    },
    {
        title: "Approved",
        dataIndex: "approved",
        key: "approved",
        render: (approved) => {
            const { _seconds: seconds, _nanoseconds: nanoseconds } = approved;
            const date = new Date(seconds * 1000 + nanoseconds / 1e6);
            return moment(date).format("DD/MM/YYYY");
        },
        width: 70,
    },
];
