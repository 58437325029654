import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { Button, Text } from "src/components/base";
import { fontWeight, mainAppColours } from "src/constants";
import { SAColourScheme } from "src/styles";
import GotoSettingIcon from "src/assets/svg/home/go-to-setting.svg";
export const GoToSettingPopup = ({ handleNavigate }) => {
    const [isLoading] = useState(false);
    return (_jsxs(SetUpPinWrapper, { children: [_jsx("div", { className: "header", children: _jsx(GotoSettingIcon, { className: "icon" }) }), _jsx("div", { className: "set-up-title", style: { marginBottom: "20px" }, children: _jsx(Text, { className: "title", name: "Collection Organisation Registration" }) }), _jsx(Text, { className: "alert", name: "Please complete setup for Collection Organisation" }), _jsx(Button, { className: "save-button", disabled: isLoading, onClick: handleNavigate, children: "Go to setup" })] }));
};
const SetUpPinWrapper = styled.div `
  width: 100%;
  background-color: ${SAColourScheme.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    width: 100%;
    height: 60px;
    background-color: ${mainAppColours.MID_BLUE};
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .icon {
    position: absolute;
    top: 50px; /* Adjusts overlap with header */
    transform: translateY(-50%);
  }

  .set-up-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title.ant-typography {
    margin-top: 30px;
    font-size: 18px;
    font-weight: ${fontWeight.BOLD};
    color: ${mainAppColours.BLUE} !important;
    text-align: center;
  }

  .alert.ant-typography {
    font-size: 14px;
    font-weight: ${fontWeight.REGULAR};
    color: #000000 !important;
    margin: 13px 0;
    text-align: center;
  }

  .save-button {
    background-color: ${mainAppColours.MID_BLUE};
    border-radius: 8px;
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 40px;

    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: ${fontWeight.MEDIUM};
    }
  }

  .hint-text {
    color: ${mainAppColours.MID_BLUE} !important;
    font-size: 12px;
    margin-top: 15px;
  }
`;
