import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Wrapper } from "./index.style";
export const TextInput = (props) => {
    const { placeholder = "", type = "text", value = "", leftIcon, rightIcon, style = {} } = props;
    const onChange = (val) => {
        if (props.onChange) {
            props.onChange(val);
        }
    };
    const renderLeftIcon = () => _jsx("div", { style: { margin: "auto" }, children: leftIcon });
    const renderRightIcon = () => _jsx("div", { style: { margin: "auto" }, children: rightIcon });
    return (_jsx(Wrapper, { className: props.className, style: style, children: _jsxs("div", { className: "input-wrapper", children: [leftIcon && renderLeftIcon(), _jsx("input", { onChange: ({ target: { value: val } }) => onChange(val), className: "text-input", placeholder: placeholder, type: type, value: value }), rightIcon && renderRightIcon()] }) }));
};
