import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { updateLocale } from "moment";
import React, { useRef } from "react";
import { Provider } from "react-redux";
import "src/styles/global.less";
import { ThemeProvider } from "styled-components";
import defaultTheme from "src/themes/defaultTheme";
import { LoadingScreen, MessageBox } from "./components/base";
import BodyPopupBox from "./components/base/BodyPopupBox";
import PopupBox from "./components/base/PopupBox";
import SAToast from "./components/commonElement/SAToast";
import "./index.css";
import { ContentContextProvider } from "./providers/ContentContextProvider";
import { SettingsContextProvider } from "./providers/SettingsContextProvider";
import AppRouter from "./router";
import { store } from "./store";
import GlobalStyles from "./styles/globalStyles";
updateLocale("en", {
    weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
});
const App = (props) => {
    const toastRef = useRef(null);
    return (_jsxs(ThemeProvider, { theme: defaultTheme, children: [_jsx(GlobalStyles, {}), _jsx(Provider, { store: store, ...props, children: _jsx(SettingsContextProvider, { children: _jsxs(ContentContextProvider, { children: [_jsx(AppRouter, {}), _jsx(MessageBox, {}), _jsx(PopupBox, {}), _jsx(LoadingScreen, {}), _jsx(BodyPopupBox, {}), _jsx(SAToast, { ref: toastRef })] }) }) })] }));
};
export default App;
