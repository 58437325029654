import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Text } from "src/components/base";
import { fontWeight } from "src/constants";
import { CustomSelectWrapper, OptionsDropdownWrapper } from "./CustomSelect.style";
const { Option } = Select;
export const CustomSelect = (props) => {
    const selectRef = useRef(null);
    const { field } = props;
    const [items, setItems] = useState([]);
    const [value, setValue] = useState();
    useEffect(() => {
        if (props.options && props.options.length) {
            setItems(props.options);
        }
    }, [props.options]);
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    return (_jsx(CustomSelectWrapper, { className: props.className, children: _jsx(Select, { ref: selectRef, placeholder: "- Select -", value: value, onChange: (val) => {
                props.onInputChange({ field, value: val });
            }, dropdownRender: (menu) => (_jsx(_Fragment, { children: _jsxs(OptionsDropdownWrapper, { children: [items
                            .filter((item) => item.pin)
                            .map((item) => (_jsxs("div", { className: "ant-select-item ant-select-item-option pinned-option", onClick: () => {
                                props.onInputChange({ field, value: item.value });
                                selectRef.current.blur();
                            }, children: [_jsx("div", { style: {
                                        width: 6,
                                        height: 6,
                                        borderRadius: "50%",
                                        backgroundColor: "#01B4D2",
                                        marginRight: 7,
                                    } }), _jsx(Text, { className: "ant-select-item-option-content", style: {
                                        color: "#2f3337 !important",
                                        fontWeight: value === item.value ? fontWeight.BOLD : fontWeight.REGULAR,
                                    }, name: item.label })] }, `pin-${item.value}`))), _jsx(Divider, { style: { margin: "8px 0" } }), menu, _jsx(Divider, { style: { margin: "8px 0" } })] }) })), children: items.map((item) => (_jsx(Option, { value: item.value, children: item.label }, item.value))) }) }));
};
