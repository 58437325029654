import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Image } from "src/components/base";
const NotFoundPage = () => {
    const goBack = () => {
        window.history.back();
    };
    return (_jsxs(PageWrapper, { children: [_jsx("div", { className: "logo", children: _jsx(Image, { src: "/assets/images/logo-text-white.png", preview: false }) }), _jsx("div", { style: {
                    backgroundImage: 'url("/assets/images/gradient-bg.png")',
                    display: "flex",
                    flex: 1,
                    maxWidth: "64%",
                    alignItems: "flex-end",
                    backgroundSize: "contain",
                }, children: _jsx(Image, { style: {
                        width: "100%",
                    }, wrapperStyle: {
                        width: "100%",
                    }, src: "/assets/images/404-background.svg", preview: false }) }), _jsxs("div", { style: {
                    display: "flex",
                    flexDirection: "column",
                    background: "white",
                    height: "100vh",
                    textAlign: "center",
                    width: "36%",
                    maxWidth: "36%",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 100px",
                }, children: [_jsx("div", { style: {
                            fontSize: "80px",
                            color: "#4B5767",
                            marginBottom: "40px",
                        }, children: "SORRY" }), _jsx("div", { style: {
                            fontSize: "18px",
                            color: "#4B5767",
                        }, children: "Sometimes in life you just can't find what you are looking for." }), _jsx("div", { style: {
                            fontSize: "18px",
                            color: "#4B5767",
                        }, children: "It's not your fault, but this seems to be one of those moments." }), _jsx("div", { style: {
                            marginTop: "150px",
                        }, children: _jsx(Image, { src: "/assets/images/404-cat-error.png", preview: false, height: 205, width: 230 }) }), _jsx("div", { style: {
                            marginTop: "150px",
                        }, children: _jsx(Button, { onClick: goBack, style: { boxShadow: "none", "&:hover": { boxShadow: "none" } }, children: _jsx("img", { src: "/assets/images/404-go-back.png", alt: "404-go-back" }) }) })] })] }));
};
export default NotFoundPage;
const PageWrapper = styled.div `
  background-color: #cccccc;
  background-size: cover;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;

  .logo {
    position: absolute;
    top: 45px;
    left: 60px;
    width: 240px;
    z-index: 99;
  }
`;
