import styled from "styled-components";
export const HeaderTableWrapper = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 35px;
  border-radius: 5px;
  border: 1px solid #3687fa;

  .icon-btn {
    border: none;
    background: none;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
`;
