import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Image } from "antd";
const IconButton = (props) => (_jsx(Button, { style: {
        padding: "0px",
        border: "none",
        background: "none",
        width: "auto",
        height: "auto",
        ...props.style,
    }, disabled: props.disabled, icon: _jsx(Image, { src: props.icon, preview: false, width: props.iconWidth, height: props.iconHeight }), onClick: props.onClick }));
export default IconButton;
