import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Form, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { SAColourScheme } from "src/styles";
import { createNote, getListNotes } from "./store/action";
export const CreateNotePage = ({ clientId, locationsData }) => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const [form] = Form.useForm();
    const createClientNote = async () => {
        const values = await form.validateFields();
        const payload = {
            ...values,
            onsiteClientId: clientId,
        };
        dispatch(setIsLoading(true));
        await dispatch(createNote(payload));
        if (clientId) {
            await dispatch(getListNotes(clientId));
        }
        form.resetFields();
        dispatch(setIsLoading(false));
    };
    return (_jsxs(NotesContentWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(Form, { form: form, children: [_jsxs(Row, { gutter: [50, 10], children: [_jsx(Col, { span: 24, children: _jsx(Form.Item, { label: "Location", name: "onsiteClientLocationId", rules: [{ required: true, message: "Please select location" }], children: _jsx(Select, { placeholder: "--Select--", className: "status-select", size: "small", options: locationsData?.filter((location) => location.value !== "") }) }) }), _jsx(Col, { span: 24, children: _jsx(Form.Item, { label: "Subject", name: "subject", rules: [{ required: true, message: "Please input subject" }], children: _jsx(Input, {}) }) }), _jsx(Col, { span: 24, children: _jsx(Form.Item, { label: "", name: "noteText", children: _jsx(TextAreaWrapper, { placeholder: "Notes" }) }) })] }), _jsx(Row, { justify: "end", children: _jsx(SaveButton, { onClick: () => {
                                form.validateFields().then(() => {
                                    createClientNote();
                                });
                            }, children: "Save" }) })] })] }));
};
const NotesContentWrapper = styled.div `
  padding: 2rem 4rem;

  .ant-select-selection-placeholder {
    color: ${SAColourScheme.BLACK} !important;
  }

  .ant-input,
  .ant-input::placeholder {
    color: ${SAColourScheme.BLACK} !important;
  }
`;
const TextAreaWrapper = styled(TextArea) `
  height: 18rem !important;
`;
const SaveButton = styled(Button) `
  background: #1a8cff;
  color: ${SAColourScheme.WHITE};
  border: 1px solid #1a8cff;
  border-radius: 5px;
  border: 1px solid #1a8cff;
  min-width: 8rem;

  &:hover {
    background: #1a8cff;
    color: ${SAColourScheme.WHITE};
  }

  &:disabled {
    border: 1px solid white;
    background: #c5c7d1;
    color: #ffffff;
  }
`;
