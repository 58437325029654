import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import LegalHeader from "src/pages/Legal/components/LegalHeader";
import { useGetLegal } from "src/pages/Legal/hooks";
const LegalPage = () => {
    const { legalTypeHeaderParam, legalData, displayHeader, legalHeaderParam, isValidUrl } = useGetLegal();
    const navigate = useNavigate();
    if (!isValidUrl) {
        navigate("/404");
    }
    return (_jsxs(PageWrapper, { children: [displayHeader ? (_jsx(LegalHeader, { appNameSrc: legalHeaderParam?.appNameSrc, iconAlt: legalHeaderParam?.iconAlt, iconSrc: legalHeaderParam?.iconSrc, title: legalTypeHeaderParam.title })) : (_jsx(_Fragment, { children: " " })), _jsx(ContentCover, { children: legalData ? (_jsx("div", { dangerouslySetInnerHTML: { __html: legalData } })) : (_jsx("p", { children: "Loading terms and conditions..." })) })] }));
};
export default LegalPage;
const PageWrapper = styled.div ``;
const ContentCover = styled.div `
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;

  @media (min-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
  }

  @media (min-width: 1024px) {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 5rem;
  }
`;
