import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "styled-components";
import { Col, Divider, Row, Text } from "src/components/base";
import { SAColourScheme } from "src/styles";
const Header = (props) => (_jsx(Row, { children: _jsx(Col, { flex: 1, children: _jsx(HeaderWrapper, { children: props.name }) }) }));
const HeaderWrapper = styled.div `
  background: #e4f6fb;
  color: #1a576f;
  font-size: 1rem;
  font-weight: 700;
  height: 3rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
`;
export const ReqAuthority = ({ donorDetailsData }) => (_jsxs(PageWrapper, { children: [_jsx(Header, { name: "Requesting Authority" }), _jsx(Row, { children: _jsxs(StyledCol, { span: 24, children: [_jsxs(Row, { children: [_jsx(Col, { span: 8, children: _jsx(Text, { name: "Name of Requesting Authority", className: "authority-title" }) }), _jsx(StyledValueCol, { span: 16, children: _jsx(Text, { name: donorDetailsData.requestingAuthorityName, className: "authority-info" }) })] }), _jsx(Divider, {}), _jsxs(Row, { children: [_jsx(Col, { span: 8, children: _jsx(Text, { name: "Authorised Representative", className: "authority-title" }) }), _jsx(StyledValueCol, { span: 16, children: _jsx(Text, { name: donorDetailsData.authorisedRepresentative, className: "authority-info" }) })] }), _jsx(Divider, {}), _jsxs(Row, { children: [_jsx(Col, { span: 8, children: _jsx(Text, { name: "Site Location", className: "authority-title" }) }), _jsx(StyledValueCol, { span: 16, children: _jsx(Text, { name: donorDetailsData.siteLocation, className: "authority-info" }) })] })] }) })] }));
const PageWrapper = styled.div `
  border: 2px solid #e4f6fb;
  margin-bottom: 2em;
  border-radius: 5px;

  .ant-divider-horizontal {
    margin: 1em 0;
    border-top: 1px solid ${SAColourScheme.PLASSMA} !important;
  }

  .authority-title {
    font-size: 1em;
    color: #7f8e9d !important;
  }

  .authority-info {
    font-size: 1em;
    color: #2f3337 !important;
  }
`;
const StyledCol = styled(Col) `
  padding: 1em 2em 1em 2em;
`;
const StyledValueCol = styled(Col) `
  padding: 0 1em;
`;
