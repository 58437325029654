import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Space, Table } from "antd";
import { getDateFormat } from "src/utils/helper";
import { useSettings } from "src/providers/SettingsContextProvider";
// Few fields modified based on the device type
export const InUseDevicesTable = ({ deviceData }) => {
    const { settings } = useSettings();
    const name = deviceData?.typeName;
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Device Name",
            dataIndex: "deviceName",
            key: "deviceName",
            sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Lot No/Serial Number",
            dataIndex: "lotSerialNumber",
            key: "lotSerialNumber",
            sorter: (a, b) => a.lotNumber.localeCompare(b.lotNumber),
            render: (text, record) => {
                const typeName = record?.typeName || "";
                const lotSerialNumber = typeName === "Breathalysers" ? record.serialNumber : record.lotNumber;
                return _jsx("span", { children: lotSerialNumber });
            },
        },
        {
            title: "Expiry Date/Calibration Due Date",
            dataIndex: "expiryCalibrationDate",
            key: "expiryCalibrationDate",
            sorter: (a, b) => {
                const dateA = name === "Breathalysers" ? new Date(a.calibrationAt) : new Date(a.expireAt);
                const dateB = name === "Breathalysers" ? new Date(b.calibrationAt) : new Date(a.expireAt);
                return dateA.getTime() - dateB.getTime();
            },
            render: (text, record) => {
                const typeName = record?.typeName || "";
                const expiryCalibrationDate = typeName === "Breathalysers" ? record.calibrationAt : record.expireAt;
                let formattedDate = "";
                if (expiryCalibrationDate !== "") {
                    formattedDate = getDateFormat(new Date(expiryCalibrationDate), settings);
                }
                return (_jsx(Space, { children: new Date(expiryCalibrationDate) < new Date() ? (_jsx("div", { style: {
                            width: "90px",
                            height: "25px",
                            background: "#FD0000",
                            fontSize: "13px",
                            color: "#FFFFFF",
                            paddingTop: "2px",
                            borderRadius: "15px",
                            textAlign: "center",
                        }, children: typeName === "Breathalysers" ? "Over Due" : "Expired" })) : (formattedDate) }));
            },
        },
        {
            title: "Type",
            dataIndex: "typeName",
            key: "typeName",
            sorter: (a, b) => a.typeName.localeCompare(b.typeName),
        },
    ];
    return (_jsx("div", { children: _jsx(Table, { columns: columns, dataSource: deviceData?.length > 0
                ? deviceData.map((item, index) => ({
                    ...item,
                    key: index,
                }))
                : [], pagination: false, scroll: { x: 900, y: 650 }, style: {
                border: "1px solid #D9D9D9",
                overflow: "hidden",
            } }) }));
};
