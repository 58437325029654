/* eslint-disable @typescript-eslint/no-explicit-any */
import { countries } from "countries-list";
import moment, { duration, isMoment } from "moment";
import momentTimezone from "moment-timezone";
import { SAColourScheme, supportingColours } from "src/constants";
import { TimeFormat } from "src/store/app/types";
export const JOB_STATUS_COLOR = {
    PLANNED: "#1A8CFF",
    InProgress: "#5CD22B",
    Completed: "#005277",
    Archived: "#6C757D",
};
var OnsiteJobStatus;
(function (OnsiteJobStatus) {
    OnsiteJobStatus[OnsiteJobStatus["Planned"] = 1] = "Planned";
    OnsiteJobStatus[OnsiteJobStatus["InProgress"] = 2] = "InProgress";
    OnsiteJobStatus[OnsiteJobStatus["Completed"] = 3] = "Completed";
    OnsiteJobStatus[OnsiteJobStatus["Archived"] = 4] = "Archived";
})(OnsiteJobStatus || (OnsiteJobStatus = {}));
var jobStatus;
(function (jobStatus) {
    jobStatus["PLANNED"] = "Planned";
    jobStatus["IN_PROGRESS"] = "In Progress";
    jobStatus["COMPLETE"] = "Complete";
    jobStatus["ARCHIVED"] = "Archived";
})(jobStatus || (jobStatus = {}));
export function getStatus(jobStatusNumber) {
    if (jobStatusNumber === OnsiteJobStatus.Planned) {
        return {
            status: jobStatus.PLANNED,
            backgroundColor: JOB_STATUS_COLOR.PLANNED,
        };
    }
    if (jobStatusNumber === OnsiteJobStatus.InProgress) {
        return {
            status: jobStatus.IN_PROGRESS,
            backgroundColor: JOB_STATUS_COLOR.InProgress,
        };
    }
    if (jobStatusNumber === OnsiteJobStatus.Completed) {
        return {
            status: jobStatus.COMPLETE,
            backgroundColor: JOB_STATUS_COLOR.Completed,
        };
    }
    return {
        status: jobStatus.ARCHIVED,
        backgroundColor: JOB_STATUS_COLOR.Archived,
    };
}
export const getDateFormat = (timestamp, settings) => {
    const dateFormat = settings.organizationSetting.dateFormat;
    switch (dateFormat) {
        case "DD/MM/YYYY":
            return timestamp.toLocaleDateString("en-GB");
        case "MM/DD/YYYY":
            return timestamp.toLocaleDateString("en-US");
        case "YYYY/MM/DD":
            return timestamp.toLocaleDateString("en-CA");
        default:
            return timestamp.toLocaleDateString("en-AU");
    }
};
export const getDate = (date) => {
    const { _seconds: dobSeconds, _nanoseconds: dobNanoseconds } = date;
    return new Date(dobSeconds * 1000 + dobNanoseconds / 1e6);
};
export const getAge = (birthDate) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};
export const getTestTypeColor = (testType) => {
    switch (testType.trim()) {
        case "Urine Drug Screen":
            return "#1BCBF2";
        case "Oral Fluid Drug Screen":
            return "#71DF7F";
        case "Urine Drug Test":
            return "#1BCBF2";
        case "Oral Fluid Drug Test":
            return "#BADC7C";
        case "Hair Drug Test":
            return "#BF700F";
        case "Breath Alcohol Screen":
            return "#018BA6";
        case "Drug & Alcohol Test":
            return "#B6E7F0";
        default:
            return supportingColours.BOX_GRAY;
    }
};
export const getRequestTestColor = (testType) => {
    switch (testType) {
        case "Rapid Urine Drug Screen":
            return {
                background: "#146EA6",
                border: SAColourScheme.WHITE,
            };
        case "Rapid Oral Fluid Drug Screen":
            return {
                background: "#71DF7F",
                border: SAColourScheme.WHITE,
            };
        case "Urine Drug Test":
            return {
                background: "#1BCBF2",
                border: SAColourScheme.WHITE,
            };
        case "Oral Fluid Drug Test":
            return {
                background: "#BADC7C",
                border: SAColourScheme.WHITE,
            };
        case "Hair Drug Test":
            return {
                background: "#BF700F",
                border: SAColourScheme.WHITE,
            };
        case "Breath Alcohol Test":
            return {
                background: "#018BA6",
                border: SAColourScheme.WHITE,
            };
        case "Drug & Alcohol Test":
            return {
                background: "#B6E7F0",
                border: SAColourScheme.WHITE,
            };
        case "Cancelled":
            return {
                background: "#FD0000",
                border: SAColourScheme.WHITE,
            };
        case "Completed":
            return {
                background: "#FFFFFF",
                border: supportingColours.BOX_GRAY,
            };
        default:
            return {
                background: supportingColours.BOX_GRAY,
                border: "",
            };
    }
};
const color_pastel = ["#FAC196", "#BFBDCB", "#D0F0C0", "#01B4D2", "#D3C2BA", "#FD0000", "#5CD22B"];
const color_default = ["#00004C", "#146EA6", "#71DF7F", "#1BCBF2", "#BADC7C", "#BF700F", "#018BA6"];
const color_ocean = ["#001E64", "#1F5890", "#0091D5", "#A5D8DD", "#BBEDF5", "#FBB03B", "#7E909A"];
const color_forest = ["#4A8550", "#6AB187", "#B3C100", "#CED2CC", "#53CCBD", "#71838D", "#0071D2"];
export const dashboard_getColorByName = (colorScheme, index) => {
    switch (colorScheme) {
        case "pastel":
            return color_pastel[index];
        case "ocean":
            return color_ocean[index];
        case "forest":
            return color_forest[index];
        default:
            return color_default[index];
    }
};
export const isAtLeast1LetterLowerCase = (string) => /.*[a-z]/.test(string);
export const isAtLeast1LetterUpperCase = (string) => /.*[A-Z]/.test(string);
export const isAtLeast1Digit = (string) => /.*[0-9]/.test(string);
export const isAtLeast8Charactors = (string) => /.{8,}/.test(string);
export const isAtLeast1SpecialCharactors = (string) => /[^A-Za-z0-9]/.test(string);
export const isPasswordStrong = (password) => {
    const condition1 = isAtLeast1LetterLowerCase(password);
    const condition2 = isAtLeast1LetterUpperCase(password);
    const condition3 = isAtLeast1Digit(password);
    const condition4 = isAtLeast8Charactors(password);
    const condition5 = isAtLeast1SpecialCharactors(password);
    return condition1 && condition2 && condition3 && condition4 && condition5;
};
export const passwordStrengthLevel = (password) => {
    if (isAtLeast1LetterLowerCase(password) &&
        isAtLeast1LetterUpperCase(password) &&
        isAtLeast1Digit(password) &&
        isAtLeast8Charactors(password) &&
        isAtLeast1SpecialCharactors(password)) {
        if (password.length <= 10) {
            return "Medium";
        }
        else {
            return "Strong";
        }
    }
    else
        return "Weak";
};
export const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};
export const extractStringHtmlContent = (stringHtml) => {
    const span = document.createElement("span");
    span.innerHTML = stringHtml;
    return span.textContent || span.innerText;
};
export const firebaseTimeToMiliseconds = (firebaseTime) => new Date(firebaseTime.seconds * 1000 + firebaseTime.nanoseconds / 1000000).getTime();
export const countByKey = (arr, prop) => arr.reduce((prev, curr) => {
    const updatedPrev = { ...prev };
    const key = curr[prop];
    updatedPrev[key] = (updatedPrev[key] || 0) + 1;
    return updatedPrev;
}, {});
export const getCurrentDay = () => {
    const currentMonthIndex = moment().date();
    if (currentMonthIndex <= 9) {
        return `0${currentMonthIndex}`;
    }
    else {
        return `${currentMonthIndex}`;
    }
};
export const getCurrentMonth = () => {
    const currentMonthIndex = moment().month();
    if (currentMonthIndex < 9) {
        return `0${currentMonthIndex + 1}`;
    }
    else {
        return `${currentMonthIndex + 1}`;
    }
};
export const getMonthList = () => [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
];
export const MonthListABC = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "NOV",
    "OCT",
    "DEC",
];
export const getDayListByYearMonth = (year, month) => {
    const days = moment(`${year}-${month}`, "YYYY-MM").daysInMonth(); // 29
    const dayList = [...Array(days)].map((item, index) => {
        if (index < 9) {
            return `0${index + 1}`;
        }
        else {
            return `${index + 1}`;
        }
    });
    return dayList;
};
export const getAllTimezones = () => {
    const allTimezones = momentTimezone.tz.names();
    const timeZonesFormatted = allTimezones.map((tz) => {
        const formattedOffset = momentTimezone
            .utc()
            .utcOffset(momentTimezone.tz(tz).utcOffset())
            .format("Z");
        return `(GMT ${formattedOffset}) ${tz}`;
    });
    return timeZonesFormatted.sort();
};
export const getAllTimezonesMapping = () => {
    const allTimezones = momentTimezone.tz.names();
    const timezoneObject = {};
    allTimezones.forEach((timezone) => {
        const formattedOffset = momentTimezone
            .utc()
            .utcOffset(momentTimezone.tz(timezone).utcOffset())
            .format("Z");
        timezoneObject[timezone] = `(GMT ${formattedOffset}) ${timezone}`;
    });
    return timezoneObject;
};
export const getAllCountryMapping = () => {
    const codesAndNames = {};
    for (const countryCode in countries) {
        // @ts-expect-error : should be refactored and removed this comment
        codesAndNames[countryCode] = countries[countryCode].name;
    }
    return codesAndNames;
};
export const calculateLastPeriod = (dateStr) => {
    const oneDay = 1000 * 60 * 60 * 24;
    const oneHour = 1000 * 60 * 60;
    const dateObj = new Date(dateStr).getTime();
    const today = new Date().getTime();
    const differenceMs = Math.abs(today - dateObj);
    if (differenceMs / oneDay < 1) {
        if (Math.round(differenceMs / oneHour) === 1) {
            return `${Math.round(differenceMs / oneHour)} hour ago`;
        }
        else {
            return `${Math.round(differenceMs / oneHour)} hours ago`;
        }
    }
    else {
        if (Math.round(differenceMs / oneDay) === 1) {
            return `${Math.round(differenceMs / oneDay)} day ago`;
        }
        else {
            return `${Math.round(differenceMs / oneDay)} days ago`;
        }
    }
};
export const calculateHoursAndMinutes = (totalHours) => {
    const hours = Math.floor(totalHours / 60);
    const minutes = totalHours % 60;
    return `${hours}h ${minutes}m`;
};
export const getMondayAndSunday = (today) => {
    const startOfWeek = moment(today).startOf("week");
    const endOfWeek = moment(today).endOf("week");
    const mondayString = startOfWeek.format("YYYY-MM-DD");
    const sundayString = endOfWeek.format("YYYY-MM-DD");
    return { monday: mondayString, sunday: sundayString };
};
export const convertTo12HourFormat = (timeString) => {
    if (!timeString || typeof timeString === "number")
        return "";
    let [hours, minutes] = timeString.toString().split(":").map(Number);
    let period = "am";
    let convertedHours = hours;
    hours = hours ?? 0;
    minutes = minutes ?? 0;
    if (hours >= 12) {
        period = "pm";
        if (hours > 12) {
            convertedHours = hours - 12;
        }
    }
    if (convertedHours === 0) {
        convertedHours = 12;
    }
    return `${convertedHours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${period}`;
};
export const convertTo24HourFormat = (timeString) => {
    const [time, period] = (timeString.toString() ?? "").split(" ");
    let [hours, minutes] = time.split(":").map(Number);
    hours = hours ?? 0;
    minutes = minutes ?? 0;
    let convertedHours = hours;
    if (period.toLowerCase() === "pm" && hours < 12) {
        convertedHours += 12;
    }
    else if (period.toLowerCase() === "am" && hours === 12) {
        convertedHours = 0;
    }
    return `${convertedHours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};
export const secondDurationToTimeDefault = (currentDurration) => {
    const defaultTime = duration(currentDurration, "seconds");
    return moment({
        minutes: defaultTime.minutes(),
        seconds: defaultTime.seconds(),
    });
};
export const convertPathToObject = (path) => {
    const segments = path.split("/").filter((segment) => segment);
    const result = [];
    let accumulatedPath = "";
    for (const segment of segments) {
        accumulatedPath += `/${segment}`;
        const name = segment
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        result.push({
            path: accumulatedPath,
            label: name,
        });
    }
    return result;
};
export const formatToTwoDigits = (num) => num.toString().padStart(2, "0");
export const calculateDuration = (durationSec) => {
    const hours = Math.floor(durationSec / 3600);
    const minutes = Math.floor((durationSec % 3600) / 60);
    const seconds = Math.floor(durationSec % 60);
    return `${hours.toString().padStart(2, "0")}h ${minutes.toString().padStart(2, "0")}m ${seconds.toString().padStart(2, "0")}s`;
};
export const getTestDuration = (start, end) => {
    const durationMs = end.getTime() - start.getTime();
    const durationSec = Math.floor(durationMs / 1000);
    return calculateDuration(durationSec);
};
export const convertMinutesToHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${String(hours).padStart(2, "0")}h ${String(minutes).padStart(2, "0")}m`;
};
export const formatLatitudeLongitude = (value) => parseFloat(value).toFixed(5);
export const timeToMinuteDuration = (date) => {
    let newDuration = 0;
    if (isMoment(date)) {
        newDuration = date.hour() * 60 + date.minute();
    }
    return newDuration;
};
export const formatClockTime = (date) => date ? moment(date).format("DD/MM/YYYY, hh:mm a") : "";
export const formatTime = (timeFormat, date) => date ? moment(date).format(timeFormat === TimeFormat.TwelveHour ? "hh:mm a" : "HH:mm") : "";
export const getBase64File = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});
export const formatABN = (abn) => {
    const abnStr = abn.toString();
    if (abnStr.length !== 11 || !/^\d{11}$/.test(abnStr)) {
        return "Invalid abn number";
    }
    return `${abnStr.slice(0, 2)} ${abnStr.slice(2, 5)} ${abnStr.slice(5, 8)} ${abnStr.slice(8)}`;
};
