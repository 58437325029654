import styled from "styled-components";
export const Wrapper = styled.div `
  padding: 20px 50px 28px;
  width: 85%;
  margin: 0px auto;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 3px 6px #0000003d;

  .sr-title {
    font-size: 16px;
    font-weight: 500;
    color: #6a6e71;
    margin-left: 24px;
  }
  .sr-body {
    margin-left: 24px;
  }
  .question-header {
    display: flex;
    cursor: pointer;
  }
  .question-icon-after {
    flex: auto;
    text-align: right;
  }
`;
