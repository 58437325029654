import moment from "moment";
import { stringify } from "qs";
import { $get, throwHttpRequestError } from "src/infra/http";
export const getProcessTestsCount = async (type, startDate, endDate) => {
    const params = {
        type,
        startAt: moment(startDate).format("YYYY-MM-DD"),
        endAt: moment(endDate).format("YYYY-MM-DD"),
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/retrieveTests?${queryString}`);
};
export const getProcessPatientsCount = async (startDate, endDate) => {
    const params = {
        startAt: moment(startDate).format("YYYY-MM-DD"),
        endAt: moment(endDate).format("YYYY-MM-DD"),
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/retrieveTestsBySex?${queryString}`);
};
export const getTestType = async () => await $get(`/collection-manager/dashboard/retrieveTestTypes`);
export const getTestProcessedLeftRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    try {
        return await $get(`/collection-manager/dashboard/modules/testProcessed/leftRowData?${queryString}`);
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestProcessedMiddleRowData = async (collectionPoint, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/testProcessed/middleRowData ?${queryString}`);
};
export const getTestProcessedRightRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/testProcessed/rightRowData?${queryString}`);
};
export const getTransportHistoryData = async () => await $get(`/collection-manager/dashboard/modules/satchelHistory`);
export const getTransportInventoryData = async () => await $get(`/collection-manager/dashboard/modules/satchelInventory`);
export const getDashboardDevicesData = async () => await $get(`/collection-manager/dashboard/modules/testingDevices`);
export const getGlobalDevicesData = async () => await $get(`/collection-manager/dashboard/modules/testingDevices/globalData`);
export const getLocationWiseData = async () => await $get(`/collection-manager/dashboard/modules/testingDevices/locationData`);
export const getHairResultsLeftRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/hairDrugResults/leftRowData?${queryString}`);
};
export const getHairResultsMiddleRowData = async (collectionPoint, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/hairDrugResults/middleRowData?${queryString}`);
};
export const getHairResultsRightRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    try {
        return await $get(`/collection-manager/dashboard/modules/hairDrugResults/rightRowData?${queryString}`);
    }
    catch (error) {
        throwHttpRequestError(error);
    }
};
export const getTestCategoriesLeftRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/testCategories/leftRowData?${queryString}`);
};
export const getTestCategoriesMiddleRowData = async (collectionPoint, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/testCategories/middleRowData?${queryString}`);
};
export const getTestCategoriesRightRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/testCategories/rightRowData?${queryString}`);
};
export const getCollectorPerformanceLeftRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/collectorPerformance/leftRowData?${queryString}`);
};
export const getCollectorPerformanceMiddleRowData = async (collectionPoint, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/collectorPerformance/middleRowData?${queryString}`);
};
export const getCollectorPerformanceRightRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/collectorPerformance/rightRowData?${queryString}`);
};
export const getCollectors = async () => await $get(`/collection-manager/dashboard/retrieveCollectors`);
// export const getTestCategoriesLeftRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/testCategories/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getTestCategoriesMiddleRowData = async (
//   collectionPoint: string,
//   isMonthView: boolean,
//   selectYear: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     isMonthView,
//     selectYear,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/testCategories/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getTestCategoriesRightRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/testCategories/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getCollectorPerformanceLeftRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/collectorPerformance/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getCollectorPerformanceMiddleRowData = async (
//   collectionPoint: string,
//   isMonthView: boolean,
//   selectYear: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     isMonthView,
//     selectYear,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/collectorPerformance/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getCollectorPerformanceRightRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/collectorPerformance/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getCollectors = async () => {
//   try {
//     return await $get(`/collection-manager/dashboard/retrieveCollectors`, {
//       headers: { idToken, "Content-Type": "application/json" },
//     });
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
export const getCollectorRankingsLeftRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/collectorRankings/leftRowData?${queryString}`);
};
export const getCollectorRankingsMiddleRowData = async (collectionPoint, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/collectorRankings/middleRowData?${queryString}`);
};
export const getCollectorRankingsRightRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/collectorRankings/rightRowData?${queryString}`);
};
export const getPatientCommunicationLeftRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/patientCommunication/leftRowData?${queryString}`);
};
export const getPatientCommunicationMiddleRowData = async (collectionPoint, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/patientCommunication/middleRowData?${queryString}`);
};
export const getPatientCommunicationRightRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/patientCommunication/rightRowData?${queryString}`);
};
export const getOralFluidLeftRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/oralFluid/leftRowData?${queryString}`);
};
// export const getCollectorRankingsLeftRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/collectorRankings/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getCollectorRankingsMiddleRowData = async (
//   collectionPoint: string,
//   isMonthView: boolean,
//   selectYear: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     isMonthView,
//     selectYear,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/collectorRankings/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getCollectorRankingsRightRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/collectorRankings/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getPatientCommunicationLeftRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/patientCommunication/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getPatientCommunicationMiddleRowData = async (
//   collectionPoint: string,
//   isMonthView: boolean,
//   selectYear: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     isMonthView,
//     selectYear,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/patientCommunication/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getPatientCommunicationRightRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/patientCommunication/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getOralFluidLeftRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/oralFluid/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
export const getOralFluidMiddleRowData = async (collectionPoint, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/oralFluid/middleRowData?${queryString}`);
};
export const getOralFluidRightRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/oralFluid/rightRowData?${queryString}`);
};
export const getUrineDrugLeftRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/urineDrug/leftRowData?${queryString}`);
};
export const getUrineDrugMiddleRowData = async (collectionPoint, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/urineDrug/middleRowData?${queryString}`);
};
export const getUrineDrugRightRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/urineDrug/rightRowData?${queryString}`);
};
export const getTestTimeLeftRowData = async (collectionPoint, collector, startAt, endAt) => {
    const params = {
        collectionPoint,
        collector,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/testTime/leftRowData?${queryString}`);
};
export const getTestTimeMiddleRowData = async (collectionPoint, collector, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        collector,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/testTime/middleRowData?${queryString}`);
};
// export const getOralFluidMiddleRowData = async (
//   collectionPoint: string,
//   isMonthView: boolean,
//   selectYear: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     isMonthView,
//     selectYear,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/oralFluid/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getOralFluidRightRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/oralFluid/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getUrineDrugLeftRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/urineDrug/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getUrineDrugMiddleRowData = async (
//   collectionPoint: string,
//   isMonthView: boolean,
//   selectYear: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     isMonthView,
//     selectYear,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/urineDrug/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getUrineDrugRightRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/urineDrug/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getTestTimeLeftRowData = async (
//   collectionPoint: string,
//   collector: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     collector,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/testTime/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getTestTimeMiddleRowData = async (
//   collectionPoint: string,
//   collector: string,
//   isMonthView: boolean,
//   selectYear: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     collector,
//     isMonthView,
//     selectYear,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/testTime/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
export const getTestTimeRightRowData = async (collectionPoint, collector, startAt, endAt) => {
    const params = {
        collectionPoint,
        collector,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/testTime/rightRowData?${queryString}`);
};
export const getPatientTimeLeftRowData = async (collectionPoint, collector, startAt, endAt) => {
    const params = {
        collectionPoint,
        collector,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/patientTime/leftRowData?${queryString}`);
};
export const getPatientTimeMiddleRowData = async (collectionPoint, collector, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        collector,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/patientTime/middleRowData?${queryString}`);
};
export const getPatientTimeRightRowData = async (collectionPoint, collector, startAt, endAt) => {
    const params = {
        collector,
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/patientTime/rightRowData?${queryString}`);
};
export const getRapidUrineLeftRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/rapidUrine/leftRowData?${queryString}`);
};
// export const getTestTimeRightRowData = async (
//   collectionPoint: string,
//   collector: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     collector,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/testTime/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getPatientTimeLeftRowData = async (
//   collectionPoint: string,
//   collector: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     collector,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/patientTime/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getPatientTimeMiddleRowData = async (
//   collectionPoint: string,
//   collector: string,
//   isMonthView: boolean,
//   selectYear: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     collector,
//     isMonthView,
//     selectYear,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/patientTime/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getPatientTimeRightRowData = async (
//   collectionPoint: string,
//   collector: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collector,
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/patientTime/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getRapidUrineLeftRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/rapidUrine/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
export const getRapidUrineMiddleRowData = async (collectionPoint, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/rapidUrine/middleRowData?${queryString}`);
};
export const getRapidUrineRightRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/rapidUrine/rightRowData?${queryString}`);
};
export const getRapidOralLeftRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/rapidOral/leftRowData?${queryString}`);
};
export const getRapidOralMiddleRowData = async (collectionPoint, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/rapidOral/middleRowData?${queryString}`);
};
export const getRapidOralRightRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/rapidOral/rightRowData?${queryString}`);
};
export const getBreathAlcoholLeftRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/breathAlcohol/leftRowData?${queryString}`);
};
export const getBreathAlcoholMiddleRowData = async (collectionPoint, isMonthView, selectYear) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/breathAlcohol/middleRowData?${queryString}`);
};
export const getBreathAlcoholRightRowData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/breathAlcohol/rightRowData?${queryString}`);
};
export const getDrugClassesLeftRowData = async (collectionPoint, startAt, endAt, resultType) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
        resultType,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/drugClasses/leftRowData?${queryString}`);
};
export const getDrugClassesMiddleRowData = async (collectionPoint, isMonthView, selectYear, resultType) => {
    const params = {
        collectionPoint,
        isMonthView,
        selectYear,
        resultType,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/drugClasses/middleRowData?${queryString}`);
};
export const getDrugClassesRightRowData = async (collectionPoint, startAt, endAt, resultType) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
        resultType,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/modules/drugClasses/rightRowData?${queryString}`);
};
export const getTestResultsData = async (collectionPoint, startAt, endAt) => {
    const params = {
        collectionPoint,
        startAt,
        endAt,
    };
    const queryString = stringify(params);
    return await $get(`/collection-manager/dashboard/retrieveTestResults?${queryString}`);
};
//
// export const getRapidUrineMiddleRowData = async (
//   collectionPoint: string,
//   isMonthView: boolean,
//   selectYear: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     isMonthView,
//     selectYear,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/rapidUrine/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getRapidUrineRightRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/rapidUrine/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getRapidOralLeftRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/rapidOral/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getRapidOralMiddleRowData = async (
//   collectionPoint: string,
//   isMonthView: boolean,
//   selectYear: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     isMonthView,
//     selectYear,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/rapidOral/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getRapidOralRightRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/rapidOral/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getBreathAlcoholLeftRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/breathAlcohol/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getBreathAlcoholMiddleRowData = async (
//   collectionPoint: string,
//   isMonthView: boolean,
//   selectYear: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     isMonthView,
//     selectYear,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/breathAlcohol/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getBreathAlcoholRightRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/breathAlcohol/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getDrugClassesLeftRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   resultType: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//     resultType,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/drugClasses/leftRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getDrugClassesMiddleRowData = async (
//   collectionPoint: string,
//   isMonthView: boolean,
//   selectYear: string,
//   resultType: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     isMonthView,
//     selectYear,
//     resultType,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/drugClasses/middleRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getDrugClassesRightRowData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   resultType: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//     resultType,
//   };
//
//   try {
//     return await $get(
//       `/collection-manager/dashboard/modules/drugClasses/rightRowData`,
//       {
//         params,
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getTestResultsData = async (
//   collectionPoint: string,
//   startAt: string,
//   endAt: string,
//   ,
// ) => {
//   const params = {
//     collectionPoint,
//     startAt,
//     endAt,
//   };
//
//   try {
//     return await $get(`/collection-manager/dashboard/retrieveTestResults`, {
//       params,
//       headers: { idToken, "Content-Type": "application/json" },
//     });
//
//
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
