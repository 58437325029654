const selectPagingMetaData = (state) => state.clientManagementPage.clientJobHistoryPage.pagingMetaData;
const selectClientJobHistoryList = (state) => state.clientManagementPage.clientJobHistoryPage.listJobHistory;
const selectedStartDate = (state) => state.clientManagementPage.clientJobHistoryPage.startDate;
const selectedEndDate = (state) => state.clientManagementPage.clientJobHistoryPage.endDate;
const listStatusTypes = (state) => state.clientManagementPage.clientJobHistoryPage.listStatusTypes;
const selectedStatusTypes = (state) => state.clientManagementPage.clientJobHistoryPage.selectedStatusType;
const selectCurrentCollectionPoint = (state) => {
    const currentCollectionPoint = state.clientManagementPage.clientJobHistoryPage.selectedCollectionPoint;
    return {
        name: currentCollectionPoint.collectionPointName,
        value: currentCollectionPoint.id,
    };
};
export { selectPagingMetaData, selectClientJobHistoryList, listStatusTypes, selectedStatusTypes, selectedStartDate, selectedEndDate, selectCurrentCollectionPoint, };
