import { useEffect } from "react";
const TAB_COUNT_KEY = "tab_browser";
const useAllTabsClosed = () => {
    const incrementTabCount = () => {
        const currentCount = parseInt(localStorage.getItem(TAB_COUNT_KEY) || "0", 10);
        localStorage.setItem(TAB_COUNT_KEY, (currentCount + 1).toString());
    };
    useEffect(() => {
        const decrementTabCount = () => {
            const currentCount = parseInt(localStorage.getItem(TAB_COUNT_KEY) || "1", 10) - 1;
            if (currentCount <= 0) {
                localStorage.removeItem(TAB_COUNT_KEY);
            }
            else {
                localStorage.setItem(TAB_COUNT_KEY, currentCount.toString());
            }
        };
        incrementTabCount();
        window.addEventListener("beforeunload", decrementTabCount);
        return () => {
            decrementTabCount();
            window.removeEventListener("beforeunload", decrementTabCount);
        };
    }, []);
};
export default useAllTabsClosed;
