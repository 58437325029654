import styled from "styled-components";
import { SAColourScheme } from "src/constants";
export const MessageBoxWrapper = styled.div `
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding-top: 30vh;
  z-index: 51;

  .message-box {
    width: 40%;
    background-color: ${SAColourScheme.WHITE};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: fixed;

    padding: 20px 40px;
    border: 4px solid #5cd22b;
    border-radius: 16px;

    .ant-btn {
      border-radius: 8px;
    }
  }
`;
