import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { SiderMenu } from "../SiderMenu";
const MainLayout = ({ children }) => (_jsx(MainLayoutWrapper, { children: _jsx(SiderMenu, { children: children }) }));
export default MainLayout;
const MainLayoutWrapper = styled.div `
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1;
  justify-content: center;
`;
