import { useLocation, useNavigate } from "react-router-dom";
const useExtendPath = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const appendPath = (path) => {
        if (!path)
            return;
        const currentPath = location.pathname;
        const cleanCurrentPath = currentPath.endsWith("/") ? currentPath.slice(0, -1) : currentPath;
        const cleanPath = path.startsWith("/") ? path.slice(1) : path;
        navigate(`${cleanCurrentPath}/${cleanPath}`);
    };
    return appendPath;
};
export default useExtendPath;
