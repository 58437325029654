import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable arrow-body-style */
import Text from "antd/lib/typography/Text";
import React from "react";
import PinInput from "react-pin-input";
import { SAColourScheme } from "src/constants";
import { PinInputStyled } from "./index.style";
export const PinInputWrapper = (props) => {
    return (_jsx(PinInputStyled, { children: _jsxs("div", { style: { display: "flex", flexDirection: "column" }, children: [props.title && _jsx(Text, { style: { ...props.titleStyle }, children: props.title }), _jsx(PinInput, { length: 6, initialValue: "", type: "numeric", inputMode: "number", autoSelect: true, regexCriteria: /^[ A-Za-z0-9_@./#&+-]*$/, onComplete: (value) => {
                        props.onComplete(value);
                    }, style: { ...props.style }, inputStyle: {
                        color: `${SAColourScheme.BLACK}`,
                        backgroundColor: `${SAColourScheme.WHITE}`,
                        width: props.inputSize ? `${props.inputSize}px` : "33px",
                        height: props.inputSize ? `${props.inputSize}px` : "33px",
                        borderRadius: "4px",
                        border: `none`,
                        boxShadow: `inset 0px 2px 3px rgba(0, 0, 0, 0.38)`,
                        ...props.inputStyle,
                    }, inputFocusStyle: {
                        boxShadow: `inset 0px 2px 3px rgba(0, 0, 0, 0.38)`,
                        ...props.inputFocusStyle,
                    } })] }) }));
};
