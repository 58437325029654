import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageLayoutWithTabAndBack } from "src/components/layouts/PageLayout/PageLayoutWithTabAndBack";
import CollectorBasicProfile from "./BasicProfile";
import CollectorCredentialsPage from "./CollectorCredential";
const CollectorInformationPageRoutes = () => {
    const routes = [
        {
            path: `/profile`,
            name: "Basic Profile",
        },
        {
            path: `/credential`,
            name: "Collector Credential",
        },
    ];
    return (_jsx(PageLayoutWithTabAndBack, { routes: routes, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/:collectorId/profile", element: _jsx(CollectorBasicProfile, {}) }), _jsx(Route, { path: "/:collectorId/credential", element: _jsx(CollectorCredentialsPage, {}) })] }) }));
};
export default CollectorInformationPageRoutes;
