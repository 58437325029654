import { makeFetchAction } from "src/utils/apiRedux";
import { $get, $post } from "src/infra/http";
export const faqDataListCM = makeFetchAction("faqDataListCM", (faqDataList = []) => ({
    requestHandler: async () => ({ data: { data: faqDataList } }),
}));
export const faqDataListPA = makeFetchAction("faqDataListPA", (faqDataList = []) => ({
    requestHandler: async () => ({ data: { data: faqDataList } }),
}));
export const faqDataListCA = makeFetchAction("faqDataListCA", (faqDataList = []) => ({
    requestHandler: async () => ({ data: { data: faqDataList } }),
}));
export const faqDataListRTOMedical = makeFetchAction("faqDataListRTOMedical", (faqDataList = []) => ({
    requestHandler: async () => ({ data: { data: faqDataList } }),
}));
export const getFAQList = async () => {
    const response = await $get(`/collection-manager/FAQ/faq`);
    return response.data;
};
export const getQuestionList = async () => {
    const response = await $get(`/collection-manager/FAQ/questions`);
    return response.data;
};
export const createSupportQuestion = async (formData) => {
    const response = await $post(`/collection-manager/FAQ/faq-support-create`, formData);
    return response.data;
};
