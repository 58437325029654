export const TEST_COLOR = {
    "Urine Drug Screen": "#01B4D2",
    "Oral Fluid Drug Screen": "#5CD22B",
};
var CollectionReportHistoryStatus;
(function (CollectionReportHistoryStatus) {
    CollectionReportHistoryStatus[CollectionReportHistoryStatus["Nodata"] = 0] = "Nodata";
    CollectionReportHistoryStatus[CollectionReportHistoryStatus["Creating"] = 1] = "Creating";
    CollectionReportHistoryStatus[CollectionReportHistoryStatus["Pending"] = 2] = "Pending";
    CollectionReportHistoryStatus[CollectionReportHistoryStatus["Success"] = 3] = "Success";
    CollectionReportHistoryStatus[CollectionReportHistoryStatus["Error"] = 4] = "Error";
})(CollectionReportHistoryStatus || (CollectionReportHistoryStatus = {}));
const initialOnsiteCollectionReportDetail = {
    client: {
        name: "",
        location: "",
        jobReference: "",
    },
    authorisedRepresentative: {
        name: "",
        mobile: "",
        email: "",
    },
    siteContact: {
        name: "",
        mobile: "",
        email: "",
    },
    collectionReportHistory: {
        sentAt: "",
        numberDonors: "",
        collectorName: "",
        status: CollectionReportHistoryStatus.Nodata,
        path: "",
    },
    donors: [],
};
export { CollectionReportHistoryStatus, initialOnsiteCollectionReportDetail };
