import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { getTransportInventoryData } from "src/api/dashboard/dashboardService";
import { SAColourScheme } from "src/constants";
import ColInput from "../ColInput";
import { LeftRowData, RowTotal } from "../Common";
import HeaderFilter from "./HeaderFilter";
import LeftData from "./LeftData";
import RightData from "./RightData";
import { SatchelHistory } from "./SatchelHistory";
export const DashboardSatchels = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [summaryData, setSummaryData] = useState({});
    const [historyData, setHistoryData] = useState({});
    const [updatedHistoryData, setUpdatedHistoryData] = useState({});
    const [costsData, setCostsData] = useState({});
    const [filter, setFilter] = useState("days");
    useEffect(() => {
        function transformHistoryData(filterData, history) {
            if (!history || Object?.keys(history).length === 0) {
                return {};
            }
            const { Newest, Oldest, Average } = history;
            const averageDays = parseFloat(Average[0]);
            switch (filterData) {
                case "weeks":
                    return {
                        Newest: [(Newest[0] / 7).toFixed(2), Newest[1]],
                        Oldest: [(Oldest[0] / 7).toFixed(2), Oldest[1]],
                        Average: [(averageDays / 7).toFixed(2), Average[1]],
                    };
                case "months":
                    return {
                        Newest: [(Newest[0] / 30).toFixed(2), Newest[1]],
                        Oldest: [(Oldest[0] / 30).toFixed(2), Oldest[1]],
                        Average: [(averageDays / 30).toFixed(2), Average[1]],
                    };
                case "years":
                    return {
                        Newest: [(Newest[0] / 365).toFixed(2), Newest[1]],
                        Oldest: [(Oldest[0] / 365).toFixed(2), Oldest[1]],
                        Average: [(averageDays / 365).toFixed(2), Average[1]],
                    };
                default:
                    return history;
            }
        }
        const transformedHistoryData = transformHistoryData(filter, historyData);
        setUpdatedHistoryData(transformedHistoryData);
    }, [filter]);
    // Usage
    const getTransportSatchelInventoryData = async () => {
        setIsLoading(true);
        const result = await getTransportInventoryData();
        if (result.status === "success") {
            setSummaryData(result?.data?.summary);
            setHistoryData(result?.data?.history);
            setUpdatedHistoryData(result?.data?.history);
            setCostsData(result?.data?.costs);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getTransportSatchelInventoryData();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(HeaderFilter, { onChangeFilter: setFilter, children: [_jsx("div", { style: { position: "relative" }, children: isLoading && (_jsx(Spin, { style: {
                                position: "absolute",
                                top: "150px",
                                left: "40vw",
                            } })) }), _jsxs("div", { style: { opacity: isLoading ? 0.3 : 1, display: "flex" }, children: [_jsxs(LeftData, { children: [summaryData &&
                                        Object?.entries(summaryData)
                                            .filter(([key]) => key !== "total")
                                            .map(([key, value]) => _jsx(LeftRowData, { title: key, data: value }, key)), _jsx(RowTotal, { style: { marginTop: 7 }, title: "Total", data: summaryData?.total })] }), _jsx(RightData, { children: updatedHistoryData &&
                                    Object?.entries(updatedHistoryData).map(([key, value]) => (_jsx(LeftRowData, { title: key, data: value, name: "history" }, key))) })] })] }), _jsx("div", { style: {
                    background: `${SAColourScheme.PLASSMA10}`,
                    height: "55px",
                    padding: "15px 20px",
                    border: "1px solid #1A8CFF",
                    borderTop: "none",
                }, children: _jsx("p", { style: { fontWeight: "700", fontSize: "15px" }, children: "Satchel Cost Metric" }) }), _jsx("div", { style: {
                    padding: "15px 20px",
                    border: "1px solid #1A8CFF",
                    borderTop: "none",
                }, children: _jsxs(Row, { children: [_jsx(Col, { xs: 24, lg: 8, children: _jsxs("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }, children: [_jsx("div", { style: {
                                            width: "100%",
                                            maxWidth: "250px",
                                            background: "#156CC9",
                                            padding: "4px 10px",
                                            borderRadius: "5px",
                                        }, children: _jsx("span", { style: {
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                color: "white",
                                            }, children: "Average Satchel Cost" }) }), _jsx(ColInput, { style: { background: "#FAFAFA", marginLeft: " 20px" }, className: "col-input", span: 4, value: "$ " + costsData?.averageSatchelCost && costsData?.averageSatchelCost })] }) }), _jsx(Col, { xs: 24, lg: 8, children: _jsxs("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }, children: [_jsx("div", { style: {
                                            width: "100%",
                                            maxWidth: "250px",
                                            background: "#156CC9",
                                            padding: "4px 10px",
                                            borderRadius: "5px",
                                        }, children: _jsx("span", { style: {
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                color: "white",
                                            }, children: "Average cost per transport" }) }), _jsx(ColInput, { style: { background: "#FAFAFA", marginLeft: " 20px" }, className: "col-input", span: 4, value: "$ " + costsData?.averageTransportCost && costsData?.averageTransportCost })] }) }), _jsx(Col, { xs: 24, lg: 8, children: _jsxs("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }, children: [_jsx("div", { style: {
                                            width: "100%",
                                            maxWidth: "250px",
                                            background: "#156CC9",
                                            padding: "4px 10px",
                                            borderRadius: "5px",
                                        }, children: _jsx("span", { style: {
                                                fontSize: "14px",
                                                fontWeight: "700",
                                                color: "white",
                                            }, children: "Difference" }) }), _jsx(ColInput, { style: { background: "#FAFAFA", marginLeft: " 20px" }, className: "col-input", span: 4, value: "$ " + costsData?.difference && costsData?.difference }), costsData?.difference > 0 ? (_jsx(Button, { className: "icon-btn", style: {
                                            border: "none",
                                            marginLeft: " 20px",
                                            background: "transparent",
                                            cursor: "default",
                                        }, size: "small", icon: _jsx("img", { src: "/assets/svg/ic-difference-up.svg" }) })) : (_jsx(Button, { className: "icon-btn", style: {
                                            border: "none",
                                            marginLeft: " 20px",
                                            background: "transparent",
                                            cursor: "default",
                                        }, size: "small", icon: _jsx("img", { src: "/assets/svg/ic-difference-down.svg" }) }))] }) })] }) }), _jsx(SatchelHistory, {})] }));
};
