import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { displayedTestTypes } from "../../../typing";
export const TestSummaryBox = ({ data }) => (_jsxs(TestSummaryBoxWrapper, { children: [_jsxs(RowData, { children: [_jsx(HeaderBox, { children: _jsx(HeaderBoxTitle, { children: "Booked" }) }), _jsx(HeaderBox, { children: _jsx(HeaderBoxTitle, { children: "Processed" }) })] }), _jsxs(RowData, { children: [_jsx(TestBox, { color: "", children: _jsx(TotalHeadingTitle, { children: "Total Alcohol Tests" }) }), _jsx(ValueBox, { children: data.bookedTests.totalTests.alcohol }), _jsx(ValueBox, { children: data.processedTests.totalTests.alcohol })] }), _jsxs(RowData, { children: [_jsx(TestBox, { color: "", children: _jsx(TotalHeadingTitle, { children: "Total Drug Tests" }) }), _jsx(ValueBox, { children: data.bookedTests.totalTests.drug }), _jsx(ValueBox, { children: data.processedTests.totalTests.drug })] }), _jsx(CustomDivider, {}), displayedTestTypes.map((testType) => (_jsxs(RowData, { children: [_jsx(TestBox, { color: testType.color, children: _jsx(Typography.Text, { children: testType.label }) }), _jsx(ValueBox, { children: data.bookedTests.testType[testType.key]?.totalTests ?? 0 }), _jsx(ValueBox, { children: data.processedTests.testType[testType.key]?.totalTests ?? 0 })] }, testType.key)))] }));
const TestSummaryBoxWrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
`;
const RowData = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const CustomDivider = styled(Divider) `
  border: 0.1rem dashed rgba(255, 255, 255, 0.3);
  margin: 0;
`;
const TestBox = styled.div `
  width: 100%;
  border-radius: 0.125rem;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-inline: 0.5rem;
  padding: 0.2rem 0.5rem;
  min-width: 10rem;
  justify-content: ${(props) => (props.color ? "flex-start" : "flex-end")};
`;
const HeaderBox = styled.div `
  text-align: center;
  min-width: 4rem;
`;
const ValueBox = styled.div `
  min-width: 3rem;
  border-radius: 0.125rem;
  background-color: rgb(255, 255, 255, 0.1);
  text-align: center;
  color: #ffffff;
  margin-inline: 0.5rem;
  padding: 0.2rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TotalHeadingTitle = styled.p `
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0;
`;
const HeaderBoxTitle = styled.p `
  font-size: 0.675rem;
  color: rgba(255, 255, 255, 0.3) !important;
  margin: 0;
`;
