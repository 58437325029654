import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Typography } from "src/components/base";
import { RowDataWrapper } from "./index.style";
const { Text } = Typography;
export const LeftRowTitle = ({ title, title2, highlight }) => (_jsx(RowDataWrapper, { children: _jsxs("div", { style: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
        }, children: [_jsxs(Col, { span: 8, style: { display: "flex" }, children: [_jsx("div", { className: "arrow", style: {
                            background: highlight ? "#156CC9" : "#2E94F2",
                            padding: "0px 9px",
                        }, children: _jsx(Text, { children: title }) }), _jsx("div", { className: "arrow-end", style: {
                            borderLeft: highlight ? "15px solid #156CC9" : "15px solid #2E94F2",
                        } })] }), _jsx(Col, { span: 16, style: { textAlign: "end" }, children: _jsx(Text, { style: { marginRight: "10px", fontSize: 13, color: "#444444" }, children: title2 }) })] }) }));
