import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Space, Table } from "antd";
import { FooterTableWrapper } from "./index.style";
// Few fields modified based on the device type
export const GlobalViewTable = ({ organisationName, globalData }) => {
    const columns = [
        {
            title: "Current Available Stock",
            dataIndex: "type",
            key: "type",
            sorter: (a, b) => {
                if (a.key === globalData.length - 1 || b.key === globalData.length - 1) {
                    return 0; // Don't perform sorting for the last row
                }
                return a.type.localeCompare(b.type);
            },
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Test Kits",
            dataIndex: "testKits",
            key: "testKits",
            sorter: (a, b) => {
                if (a.key === globalData.length - 1 || b.key === globalData.length - 1) {
                    return 0; // Don't perform sorting for the last row
                }
                return a.testKits - b.testKits; // Compare numeric values
            },
            render: (text) => _jsx("span", { children: text }),
        },
        {
            title: "Average Unit Value",
            dataIndex: "unitsValue",
            key: "unitsValue",
            sorter: (a, b) => {
                if (a.key === globalData.length - 1 || b.key === globalData.length - 1) {
                    return 0; // Don't perform sorting for the last row
                }
                return a.unitsValue - b.unitsValue; // Compare numeric values
            },
            render: (text) => _jsxs("span", { children: ["$ ", text] }),
        },
        {
            title: "Total",
            dataIndex: "totalValue",
            key: "totalValue",
            sorter: (a, b) => {
                if (a.key === globalData.length - 1 || b.key === globalData.length - 1) {
                    return 0; // Don't perform sorting for the last row
                }
                return a.totalValue - b.totalValue; // Compare numeric values
            },
            render: (text) => _jsxs("span", { children: ["$ ", text] }),
        },
    ];
    return (_jsxs(FooterTableWrapper, { children: [_jsx("div", { style: { fontSize: "16px", padding: "10px 15px" }, children: _jsx("span", { children: organisationName }) }), _jsx("div", { children: _jsx(Table, { columns: columns, dataSource: globalData?.length > 0
                        ? globalData.map((item, index) => ({ ...item, key: index }))
                        : [], pagination: false, scroll: { x: 900, y: 650 }, style: {
                        border: "1px solid #D9D9D9",
                        overflow: "hidden",
                    }, rowClassName: (record, index) => (index === globalData.length - 1 ? "last-row" : "") }) })] }));
};
