import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import { NewClient } from "./components/NewClient";
import { AddNewClient } from "./components/NewClient/components/ClientDetails/NewClient";
import { ClientManagement } from ".";
const breadcrumbs = [
    {
        path: "/client-management",
        name: `Client Management`,
        level: 1,
    },
    {
        path: `/add-new`,
        name: "New Client",
        level: 2,
    },
    {
        path: `/client/:clientId/authorised-respresentatives`,
        name: "Authorised Rep",
        level: 2,
        disabled: true,
    },
    {
        path: `/client/:clientId/client-specifications`,
        name: "Client Specifications",
        level: 2,
        disabled: true,
    },
    {
        path: `/client/:clientId/client-locations`,
        name: "Client Locations",
        level: 2,
        disabled: true,
    },
    {
        path: `/client/:clientId/job-history`,
        name: "Job History",
        level: 2,
        disabled: true,
    },
    {
        path: `/client/:clientId/notes`,
        name: "Notes",
        level: 2,
        disabled: true,
    },
    {
        path: `/client/:clientId/reporting`,
        name: "Reporting",
        level: 2,
        disabled: true,
    },
];
const ClientManagementRoutes = () => (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(ClientManagement, {}) }), _jsx(Route, { path: "/client/:clientId/*", element: _jsx(NewClient, {}) }), _jsx(Route, { path: "/add-new", element: _jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsx(AddNewClient, {}) }) })] }) }));
export default ClientManagementRoutes;
