import styled from "styled-components";
export const Wrapper = styled.div `
  .faq-header {
    background: #e4f6fb;
    height: 266px;
    padding: 10px 46px 0 46px;
  }
  .faq-title {
    font-size: 28px;
    font-weight: bold;
    color: #2f3337cc;
    margin-left: 15px;
  }
  .faq-body {
    margin-top: 31px;
    border-radius: 4px;
    background: #ffffff;
    height: 360px;
    box-shadow: 0 3px 6px #0000003d;
  }
  .question-text {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }
  .answer-text {
    font-size: 15px;
    color: #2f3337;
  }
  .question-frame {
    margin: 12px 0;
  }
  .faq-element {
    margin-left: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;
