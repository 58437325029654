import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getCollectionReportDetail, getListInvalidResult, getListOnsiteCollectionReports, getTestTypesData, } from "./action";
import { initialOnsiteCollectionReportDetail, } from "./types";
export const initialState = {
    invalidResults: [],
    listTestTypeData: [],
    selectedTestType: { id: "", name: "All" },
    selectedToday: false,
    startDate: null,
    endDate: null,
    listOnsiteCollectionReports: [],
    currentOnsiteClientSetUpId: null,
    onsiteCollectionReportDetail: initialOnsiteCollectionReportDetail,
    onsiteCollectionReportUrl: "",
    currentDate: new Date().toISOString(),
    selectedCollectionPoint: { id: "", collectionPointName: "Show All" },
};
const collectionReportsSlice = createSlice({
    name: "collectionReports",
    initialState,
    reducers: {
        setCurrentTestType(state, action) {
            state.selectedTestType = action.payload;
        },
        setSelectedToday(state) {
            state.selectedToday = !state.selectedToday;
        },
        setStartDate(state, action) {
            state.startDate = action.payload;
        },
        setEndDate(state, action) {
            state.endDate = action.payload;
        },
        setResetStore() {
            return initialState;
        },
        setCurrentDate(state, action) {
            state.currentDate = action.payload;
        },
        setCurrentOnsiteClientSetUpId(state, action) {
            state.currentOnsiteClientSetUpId = action.payload;
        },
        setCollectionPoint(state, action) {
            state.selectedCollectionPoint = action.payload;
        },
        setOnsiteCollectionReportDetail(state, action) {
            state.onsiteCollectionReportDetail = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListInvalidResult.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.invalidResults = initialState.invalidResults;
                message.error("Failed to fetch list of invalid results reports");
            }
            else {
                state.invalidResults = action.payload.data;
            }
        })
            .addCase(getListInvalidResult.rejected, () => {
            message.error("Failed to fetch list of invalid results reports");
        })
            .addCase(getTestTypesData.fulfilled, (state, action) => {
            state.listTestTypeData = [{ id: "", name: "All" }, ...action.payload.data];
        })
            .addCase(getTestTypesData.rejected, (state) => {
            message.error("Failed to fetch collection points");
            state.listTestTypeData = [];
        })
            .addCase(getListOnsiteCollectionReports.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.listOnsiteCollectionReports = initialState.listOnsiteCollectionReports;
                message.error("Failed to fetch onsite collection reports");
            }
            else {
                state.listOnsiteCollectionReports = action.payload.data;
            }
        })
            .addCase(getListOnsiteCollectionReports.rejected, (state) => {
            message.error("Failed to fetch onsite collection reports");
            state.listOnsiteCollectionReports = [];
        })
            .addCase(getCollectionReportDetail.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.onsiteCollectionReportDetail = initialOnsiteCollectionReportDetail;
                message.error("Failed to fetch detail of the collection report");
            }
            else {
                state.onsiteCollectionReportDetail = action.payload.data;
            }
        })
            .addCase(getCollectionReportDetail.rejected, (state) => {
            message.error("Failed to fetch detail of the collection report");
            state.onsiteCollectionReportDetail = initialOnsiteCollectionReportDetail;
        });
    },
});
export const { setCurrentTestType, setSelectedToday, setResetStore, setStartDate, setEndDate, setCurrentOnsiteClientSetUpId, setCurrentDate, setCollectionPoint, setOnsiteCollectionReportDetail, } = collectionReportsSlice.actions;
export default collectionReportsSlice.reducer;
