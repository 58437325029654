import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "antd";
import React from "react";
import styled from "styled-components";
import CallOutIcon from "src/assets/svg/home/call-out-icon.svg";
import { useAppSelector } from "src/store/hooks";
import { getOnSiteCallOutSummary, getOnSiteJobSummary, getOnSiteTestSummary, } from "src/pages/HomePage/store/selector";
import { COLORS } from "../../typing";
import { TestSummaryBox } from "./components/TestSummaryBox";
import { CustomBarChart } from "./components/CustomBarChart";
const DefaultCallOutDisplayList = 3;
export const OnsiteDashboard = () => {
    const callOutSummary = useAppSelector(getOnSiteCallOutSummary);
    const testSummary = useAppSelector(getOnSiteTestSummary);
    const jobSummary = useAppSelector(getOnSiteJobSummary);
    const data = [
        { name: "Jobs", value: jobSummary?.jobs ?? 0 },
        { name: "Collectors", value: jobSummary?.collectors ?? 0 },
        { name: "Donors", value: jobSummary?.donors ?? 0 },
        { name: "Sites", value: jobSummary?.sites ?? 0 },
    ];
    return (_jsxs(OnsiteDashboardContainer, { children: [_jsx(TestSummaryBoxWrapper, { children: _jsx(TestSummaryBox, { data: testSummary }) }), _jsxs(JobSummaryBoxWrapper, { children: [_jsxs(TotalJobBox, { children: [_jsx(CustomTitle, { children: "Total Jobs" }), _jsx(CustomTitle, { children: jobSummary?.jobs })] }), _jsx(CustomBarChart, { data: data })] }), _jsxs(CallOutWrapper, { children: [_jsxs(TotalJobBox, { children: [_jsx(CustomTitle, { children: "Call Outs" }), _jsx(CustomTitle, { children: callOutSummary?.length })] }), _jsxs(ListClients, { children: [callOutSummary?.map((item, index) => (_jsxs(ClientDataBox, { index: index % callOutSummary.length, children: [_jsx(Typography.Title, { level: 5, children: item.clientName }), _jsx(Typography.Text, { children: item.collectors.length === 1
                                            ? item.collectors
                                            : `${item.collectors[0] + " and " + (item.collectors.length - 1) + " more"}` })] }, index))), callOutSummary &&
                                callOutSummary.length < DefaultCallOutDisplayList &&
                                Array.from({
                                    length: !callOutSummary.length
                                        ? DefaultCallOutDisplayList
                                        : callOutSummary.length - 1,
                                }, (_, i) => i + 1).map((_, index) => (_jsx(ClientDataBox, { index: null, children: _jsx(CallOutIcon, {}) }, index)))] })] })] }));
};
const OnsiteDashboardContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 1em;
`;
const TestSummaryBoxWrapper = styled.div `
  width: 50%;
  height: 100%;
`;
const JobSummaryBoxWrapper = styled.div `
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const CustomTitle = styled(Typography.Text) `
  color: #ffffff !important;
  margin: 0;
  font-weight: 700;
  font-size: 1.25rem;
`;
const CallOutWrapper = styled.div `
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const TotalJobBox = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  padding-inline: 2rem;
  border-radius: 0.125rem;
  background-color: rgb(255, 255, 255, 0.1);
  height: 25%;
`;
const ListClients = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ClientDataBox = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.index !== null ? "flex-start" : "center")};
  padding: 1rem;
  margin-block: 0.5rem;
  gap: 0.5rem;
  background-color: ${(props) => props.index !== null ? COLORS[props.index] : "rgb(255, 255, 255, 0.1)"};
  text-align: left;
  border-radius: 0.5rem;
  height: 30%;

  h5,
  span {
    overflow: hidden;
    white-space: nowrap;
    color: #fff !important;
  }
`;
