import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useState } from "react";
import ChatIcon from "src/assets/svg/ic-chat.svg";
import LetterIcon from "src/assets/svg/ic-letter.svg";
import { Button, Divider } from "src/components/base";
import { BoxWrapper, SABackBtn, TypographyErrorText, TypographyText, TypographyTitle, } from "src/pages/ForgotPwdPage/index.style";
export const GetCodeForm = (props) => {
    const { email = "", phone = "" } = props;
    const [errorText, setErrorText] = useState("");
    const getCode = async (type) => {
        if (type == "phone" && !phone) {
            setErrorText("Your phone number is not found");
            return;
        }
    };
    const renderWay = (type) => (_jsxs("div", { style: {
            display: "flex",
            justifyContent: "space-between",
        }, children: [type == "email" && _jsx(LetterIcon, { className: "img-color" }), type == "phone" && _jsx(ChatIcon, { className: "img-color" }), _jsxs("div", { style: { textAlign: "left", flex: "60%", marginLeft: "24px" }, children: [_jsx("div", { className: "text", style: { color: "#4B5767" }, children: type == "email" ? "Email code to" : "Text code to number ending in" }), _jsx("div", { className: "text", style: { color: "#4B5767", fontWeight: "bold" }, children: type == "email" ? email : `- ${phone.substring(phone.length - 2)}` }), type == "phone" && (_jsx("div", { className: "sub-text", children: "Our texts are free, but some service providers may apply usage charges in certain cases." }))] }), _jsx(Button, { style: {
                    border: "none",
                    width: "26px",
                    minWidth: "26px",
                    height: "26px",
                }, icon: _jsx(RightOutlined, { style: {
                        color: "#7F8E9D",
                        fontWeight: "bold",
                    } }), onClick: () => getCode(type) })] }));
    return (_jsxs(BoxWrapper, { children: [_jsx(TypographyTitle, { children: "Get your code another way" }), _jsx(TypographyText, { children: "Select a way to receive the verification code" }), renderWay("email"), _jsx(Divider, {}), renderWay("phone"), _jsx(TypographyErrorText, { children: errorText }), _jsx(SABackBtn, { icon: _jsx(LeftOutlined, { style: {
                        color: "#2F3337",
                        fontWeight: "bold",
                    } }), name: "Back", onClick: () => props.onBack() })] }));
};
