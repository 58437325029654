import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from "react";
import { Button, Row, Typography } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import ContentContext from "src/providers/content";
import { MESSAGE_BOX_TYPE, supportingColours } from "src/constants";
import { MessageBoxWrapper } from "./index.style";
const { Text } = Typography;
const MessageBox = () => {
    const { messageBox, closeMessageBox } = useContext(ContentContext);
    const boxColor = () => {
        switch (messageBox.type) {
            case MESSAGE_BOX_TYPE.DIALOG:
                return "#5CD22B";
                break;
            case MESSAGE_BOX_TYPE.PROMPT_GREEN:
                return "#5CD22B";
                break;
            case MESSAGE_BOX_TYPE.PROMPT_BLUE:
                return "#1A8CFF";
                break;
            case MESSAGE_BOX_TYPE.DIALOG_ERROR:
            case MESSAGE_BOX_TYPE.PROMPT:
                return "#FF4D4D";
                break;
        }
    };
    return messageBox?.visible ? (_jsx(MessageBoxWrapper, { children: _jsxs("div", { className: "message-box", style: { borderColor: boxColor() }, children: [_jsx(InfoCircleFilled, { style: { fontSize: 54, color: boxColor(), marginBottom: 14 } }), _jsx(Text, { style: {
                        fontSize: 20,
                        color: supportingColours.TEXT_DARK_GRAY,
                        textAlign: "center",
                        marginBottom: 24,
                    }, children: messageBox.message }), _jsxs(Row, { style: { justifyContent: "flex-end", width: "100%" }, children: [_jsx(Button, { onClick: () => {
                                closeMessageBox();
                                if (messageBox.type === MESSAGE_BOX_TYPE.DIALOG && messageBox.callback) {
                                    messageBox.callback();
                                }
                            }, children: "Close" }), [
                            MESSAGE_BOX_TYPE.PROMPT,
                            MESSAGE_BOX_TYPE.PROMPT_BLUE,
                            MESSAGE_BOX_TYPE.PROMPT_GREEN,
                        ].includes(messageBox.type) && (_jsx(Button, { style: { marginLeft: 8, color: boxColor() }, onClick: () => {
                                closeMessageBox();
                                if ([
                                    MESSAGE_BOX_TYPE.PROMPT,
                                    MESSAGE_BOX_TYPE.PROMPT_BLUE,
                                    MESSAGE_BOX_TYPE.PROMPT_GREEN,
                                ].includes(messageBox.type) &&
                                    messageBox.callback) {
                                    messageBox.callback();
                                }
                            }, children: "Confirm" }))] })] }) })) : null;
};
export default MessageBox;
