import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { selectIsLoading } from "src/store/app/selector";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { NotesHeader } from "./NotesHeader";
import { NotesList } from "./NotesList";
import { NotesContent } from "./NotesContent";
import { selectCurrentLocation, selectLocations, selectNoteFlag, selectNotesList, selectedNote, } from "./store/selector";
import { getListLocations, getListNotes } from "./store/action";
import { CreateNotePage } from "./CreateNote";
import { setCreateNoteFlag, setSelectedNote } from "./store/slice";
export const Notes = ({ clientName }) => {
    const dispatch = useAppDispatch();
    const notesData = useAppSelector(selectNotesList);
    const locationsData = useAppSelector(selectLocations);
    const selectedLocation = useAppSelector(selectCurrentLocation);
    const selectedNoteDetail = useAppSelector(selectedNote);
    const isLoading = useAppSelector(selectIsLoading);
    const createNoteFlag = useAppSelector(selectNoteFlag);
    const { clientId } = useParams();
    let filteredData = [];
    if (selectedLocation.value === "") {
        filteredData = notesData;
    }
    else {
        filteredData = notesData.filter((item) => item.onsiteClientLocationId == selectedLocation.value);
    }
    useEffect(() => {
        const loadData = async (id) => {
            dispatch(setIsLoading(true));
            await dispatch(getListNotes(id));
            await dispatch(getListLocations(id));
            dispatch(setIsLoading(false));
        };
        if (clientId) {
            loadData(clientId);
        }
    }, [location]);
    // function to select the note and view details
    const handleClick = (id) => {
        const selectItem = filteredData.find((item) => item.id === id);
        if (selectItem) {
            dispatch(dispatch(setCreateNoteFlag(false)));
            dispatch(setSelectedNote(selectItem));
        }
    };
    return (_jsxs(React.Fragment, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(RowWrapper, { children: [_jsxs(Col, { span: 9, children: [_jsx(NotesHeader, { clientName: clientName, locationsData: locationsData, selectedLocation: selectedLocation }), _jsx(NotesList, { notesData: filteredData, handleClick: handleClick, selectedNoteDetail: selectedNoteDetail, locationsData: locationsData })] }), _jsx(ColWrapper, { span: 15, children: createNoteFlag ? (_jsx(CreateNotePage, { clientId: clientId, locationsData: locationsData })) : (_jsx(NotesContent, { selectedNoteDetail: selectedNoteDetail, clientId: clientId, locationsData: locationsData })) })] })] }));
};
const RowWrapper = styled(Row) `
  min-height: 74vh;
  margin: -1rem !important;
`;
const ColWrapper = styled(Col) `
  border-left: 1px solid #01b4d2;
`;
