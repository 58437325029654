import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Row, Col, Text } from "src/components/base";
import { SAColourScheme } from "src/styles";
export const DonorInfo = ({ donorDetailsData }) => (_jsx(PageWrapper, { children: _jsxs(Row, { children: [_jsxs(CellInfo, { span: 6, children: [_jsx(Text, { className: "cell-title", name: "Last Name" }), _jsx(Text, { className: "cell-body-name", name: donorDetailsData?.lastName.toUpperCase() })] }), _jsxs(CellInfo, { span: 6, children: [_jsx(Text, { className: "cell-title", name: "First Name" }), _jsx(Text, { className: "cell-body-name", name: donorDetailsData?.firstName })] }), _jsxs(CellInfo, { span: 4, children: [_jsx(Text, { className: "cell-title", name: "Date of Birth" }), _jsx(Text, { className: "cell-body", name: donorDetailsData?.dob })] }), _jsxs(CellInfo, { span: 4, children: [_jsx(Text, { className: "cell-title", name: "Age" }), _jsx(Text, { className: "cell-body", name: donorDetailsData?.age })] }), _jsxs(CellInfo, { span: 4, className: "with-no-right-border", children: [_jsx(Text, { className: "cell-title", name: "Sex" }), _jsx(Text, { className: "cell-body", name: donorDetailsData?.sex?.charAt(0).toUpperCase() +
                            donorDetailsData?.sex?.slice(1).toLowerCase() })] })] }) }));
const PageWrapper = styled.div `
  background: #e4f6fb;
  margin-bottom: 2em;
  padding: 1em 2em;
  border-radius: 5px;

  .ant-divider-horizontal {
    margin: 1em 0;
    border-top: 1px solid ${SAColourScheme.PLASSMA} !important;
  }
`;
const CellInfo = styled(Col) `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-right: 1px solid ${SAColourScheme.PLASSMA} !important;
  padding-left: 1em;

  &.with-no-right-border {
    border-right: none !important;
  }

  .cell-title {
    font-size: 1rem;
    color: #7f8e9d !important;
  }

  .cell-body-name {
    font-weight: 700;
    font-size: 1rem;
    color: #2f3337 !important;
  }

  .cell-body {
    font-size: 1rem;
    color: #2f3337 !important;
  }
`;
