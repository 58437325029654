import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import { useAppSelector } from "src/store/hooks";
import { AuthorisedRep } from "./components/AuthorisedRep";
import { ClientDetails } from "./components/ClientDetails";
import { selectClientName } from "./components/ClientDetails/store/selector";
import { ClientLocations } from "./components/ClientLocations";
import { ClientSpecifications } from "./components/ClientSpecifications";
import { JobHistory } from "./components/JobHistory";
import { Notes } from "./components/Notes";
import { Reporting } from "./components/Reporting";
export const NewClient = () => {
    const [disabled, setDisabled] = useState(false);
    const location = useLocation();
    const clientName = useAppSelector(selectClientName);
    const { clientId } = useParams();
    useEffect(() => {
        if (location.pathname === "/client-management/client/add-new-client") {
            console.error("location", location.pathname);
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [location]);
    const breadcrumbs = [
        {
            path: "/client-management",
            name: `Client Management > ${clientName}`,
            level: 1,
        },
        {
            path: `/client/${clientId}/client-details`,
            name: "Client Details",
            level: 2,
        },
        {
            path: `/client/${clientId}/authorised-respresentatives`,
            name: "Authorised Rep",
            level: 2,
            disabled: disabled,
        },
        {
            path: `/client/${clientId}/client-specifications`,
            name: "Client Specifications",
            level: 2,
            disabled: disabled,
        },
        {
            path: `/client/${clientId}/client-locations`,
            name: "Client Locations",
            level: 2,
            disabled: disabled,
        },
        {
            path: `/client/${clientId}/job-history`,
            name: "Job History",
            level: 2,
            disabled: disabled,
        },
        {
            path: `/client/${clientId}/notes`,
            name: "Notes",
            level: 2,
            disabled: disabled,
        },
        {
            path: `/client/${clientId}/reporting`,
            name: "Reporting",
            level: 2,
            disabled: disabled,
        },
    ];
    return (_jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/client-details", element: _jsx(ClientDetails, {}) }), _jsx(Route, { path: "/authorised-respresentatives", element: _jsx(AuthorisedRep, {}) }), _jsx(Route, { path: "/client-specifications", element: _jsx(ClientSpecifications, {}) }), _jsx(Route, { path: "/client-locations", element: _jsx(ClientLocations, {}) }), _jsx(Route, { path: "/job-history", element: _jsx(JobHistory, {}) }), _jsx(Route, { path: "/notes", element: _jsx(Notes, { clientName: clientName }) }), _jsx(Route, { path: "/reporting", element: _jsx(Reporting, { clientName: clientName }) })] }) }));
};
