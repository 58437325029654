import moment from "moment";
const selectListTestTypeData = (state) => state.collectionReportsPage.listTestTypeData;
const selectListInvalidResults = (state) => state.collectionReportsPage.invalidResults;
const selectCurrentTestType = (state) => {
    const currentTestType = state.collectionReportsPage.selectedTestType;
    return {
        name: currentTestType.name,
        value: currentTestType.id,
    };
};
const selectTestTypes = (state) => {
    const testTypes = state.collectionReportsPage.listTestTypeData;
    return testTypes.map((test) => ({
        name: test.id,
        value: test.name,
    }));
};
const selectedToday = (state) => state.collectionReportsPage.selectedToday;
const selectedStartDate = (state) => state.collectionReportsPage.startDate;
const selectedEndDate = (state) => state.collectionReportsPage.endDate;
const selectCurrentDate = (state) => moment(state.collectionReportsPage.currentDate).toISOString();
const selectListOnsiteCollectionReports = (state) => state.collectionReportsPage.listOnsiteCollectionReports;
const selectCollectionReportDetail = (state) => state.collectionReportsPage.onsiteCollectionReportDetail;
const selectCurrentClientSetUpId = (state) => state.collectionReportsPage.currentOnsiteClientSetUpId;
const selectListDonors = (state) => {
    const listDonors = state.collectionReportsPage.onsiteCollectionReportDetail.donors ?? [];
    return listDonors.map((donor, index) => ({
        index,
        firstName: donor.firstName,
        lastName: donor.lastName,
        email: donor.email,
        mobile: donor.mobile,
        uploadedAt: donor.uploadedAt ? moment(donor.uploadedAt).format("hh:mm A") : "",
        collectorName: donor.collectorName,
    }));
};
const selectCollectionPoint = (state) => {
    const { selectedCollectionPoint } = state.collectionReportsPage;
    return {
        name: selectedCollectionPoint.collectionPointName,
        value: selectedCollectionPoint.id,
    };
};
const selectListCollectionPoints = (state) => {
    const { collectionPoints } = state.authentication.app;
    const respondedCollectionPoints = collectionPoints.map((collectionPoint) => ({
        name: collectionPoint.id,
        value: collectionPoint.collectionPointName,
    }));
    return [{ name: "", value: "Show All" }, ...respondedCollectionPoints];
};
export { selectCollectionPoint, selectCollectionReportDetail, selectCurrentClientSetUpId, selectCurrentDate, selectCurrentTestType, selectedEndDate, selectedStartDate, selectedToday, selectListCollectionPoints, selectListDonors, selectListInvalidResults, selectListOnsiteCollectionReports, selectListTestTypeData, selectTestTypes, };
