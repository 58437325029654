import styled from "styled-components";
export const LoadingScreenWrapper = styled.div `
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 9999;

  .ant-spin.ant-spin-spinning {
    margin-top: 50vh;
  }
`;
