import { jsx as _jsx } from "react/jsx-runtime";
import { Space, Spin } from "antd";
import React from "react";
import styled from "styled-components";
export const OverlayLoading = () => (_jsx(LoadingWrapper, { children: _jsx(LoadingBox, { children: _jsx(Spin, {}) }) }));
const LoadingBox = styled(Space) `
  background-color: inherit;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;
const LoadingWrapper = styled(Space) `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;
