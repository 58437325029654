import { SAMPLE_TYPE } from "./store/types";
const COLORS = ["#C4E1FF", "#86C2FF", "#1A8CFF"];
var ColorSchemeForTests;
(function (ColorSchemeForTests) {
    ColorSchemeForTests["TotalAlcoholTests"] = "";
    ColorSchemeForTests["TotalDrugTests"] = "transparent";
    ColorSchemeForTests["BAS"] = "#018ba6";
    ColorSchemeForTests["UDS"] = "#146EA6";
    ColorSchemeForTests["ODS"] = "#71DF7F";
    ColorSchemeForTests["UDT"] = "#1BCBF2";
    ColorSchemeForTests["ODT"] = "#BADC7C";
    ColorSchemeForTests["HDT"] = "#BF700F";
})(ColorSchemeForTests || (ColorSchemeForTests = {}));
const displayedTestTypes = [
    {
        key: SAMPLE_TYPE.BREATH_ALCOHOL_TEST,
        label: "Breath Alcohol Screen",
        color: ColorSchemeForTests.BAS,
    },
    {
        key: SAMPLE_TYPE.RAPID_URINE_DRUG_SCREEN,
        label: "Urine Drug Screen",
        color: ColorSchemeForTests.UDS,
    },
    {
        key: SAMPLE_TYPE.RAPID_ORAL_FLUID_DRUG_SCREEN,
        label: "Oral Fluid Drug Screen",
        color: ColorSchemeForTests.ODS,
    },
    { key: SAMPLE_TYPE.URINE_DRUG_TEST, label: "Urine Collection", color: ColorSchemeForTests.UDT },
    {
        key: SAMPLE_TYPE.ORAL_FLUID_DRUG_TEST,
        label: "Oral Fluid Collection",
        color: ColorSchemeForTests.ODT,
    },
    { key: SAMPLE_TYPE.HAIR_DRUG_TEST, label: "Hair Collection", color: ColorSchemeForTests.HDT },
];
export { COLORS, ColorSchemeForTests, displayedTestTypes };
