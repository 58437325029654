import styled from "styled-components";
export const Wrapper = styled.div `
  .span.ant-select-selection-item {
    font-size: 18px;
    font-weight: 500;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: unset;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: unset;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: white;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-size: 18px;
    font-weight: 500;
    margin-top: 3px;
  }
  span.ant-select-arrow {
    top: 30%;
    right: 20px;
  }
`;
