import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from "moment";
import React from "react";
import { Calendar, momentLocalizer, } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { mainAppColours, SAColourScheme, supportingColours } from "src/styles";
import { CalendarView } from "../typing";
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
const CustomEvent = ({ event }) => {
    const navigate = useNavigate();
    return (_jsxs(EventContainer, { allDay: event.allDay, onClick: () => navigate(`/job-management/onsite-jobs/${event.id}/normal`), children: [!event.allDay && _jsx(SideBar, { children: "On Site" }), _jsx(EventDetails, { children: event.title })] }));
};
const eventPropGetter = () => {
    const newStyle = {
        backgroundColor: "#ffffff",
        borderColor: "transparent",
        color: `${supportingColours.TEXT_DARK_GRAY}`,
        borderRadius: "0.25rem",
    };
    return {
        className: "",
        style: newStyle,
    };
};
const CustomDayCalendar = ({ events, currentDate, header, ...props }) => {
    const firstEvent = events.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())[0]?.start ?? new Date();
    return (_jsxs(DayCalendarWrapper, { children: [_jsx(MainHeader, { children: header }), _jsx(DateBar, { children: moment(currentDate).format("dddd, DD MMMM YYYY") }), _jsx(CustomDnDCalendar, { view: CalendarView.Day, onView: () => void 0, date: currentDate, onNavigate: () => void 0, events: events, localizer: localizer, defaultDate: firstEvent, scrollToTime: firstEvent, selectable: true, showAllEvents: true, timeslots: 12, step: 5, formats: {
                    eventTimeRangeFormat: () => "",
                }, components: {
                    toolbar: () => _jsx(_Fragment, {}),
                    timeGutterHeader: () => (_jsx(TimeGutterHeader, { children: _jsx(TimeGutterHeaderContent, { children: "Onsite" }) })),
                    dateCellWrapper: (prop) => _jsx(DateCellWrapper, { children: prop.children }),
                    timeSlotWrapper: (prop) => _jsx(TimeSlotWrapper, { children: prop.children }),
                    eventContainerWrapper: (prop) => (_jsx(EventContainerWrapper, { children: prop.children })),
                    event: CustomEvent,
                }, eventPropGetter: eventPropGetter, ...props })] }));
};
export default CustomDayCalendar;
const DayCalendarWrapper = styled.div `
  width: 100%;
  .rbc-events-container .rbc-background-event {
    background-color: #f5e2e2;
  }

  .rbc-events-container .rbc-background-event .rbc-event-content {
    color: #01b4d2;
    font-weight: bold;
  }

  .rbc-time-header.rbc-overflowing {
    border-right: none;
  }
  .rbc-time-header-content {
    border-left: none;
    border-right: none;
    margin-right: 0.5rem;
  }

  .rbc-timeslot-group {
    min-height: 10rem;
  }

  .rbc-background-event {
    background-color: #e5e5e5;
    border-color: transparent;
    border-radius: unset;
  }

  .rbc-time-content > * + * > *,
  .rbc-timeslot-group,
  .rbc-day-slot .rbc-time-slot {
    border: 0;
  }

  .rbc-time-content,
  .rbc-time-header-content {
    .rbc-today {
      background-color: transparent;
      color: ${mainAppColours.MID_BLUE};
    }

    .rbc-current-time-indicator {
      display: none;
    }
  }

  .rbc-time-view {
    border: none;
  }

  .rbc-time-view .rbc-allday-cell {
    max-height: 10rem;
    overflow: auto;
  }
`;
const CustomDnDCalendar = styled(DnDCalendar) `
  height: 70vh;
  border-bottom: 0.063rem solid #bfbdcb;
`;
const MainHeader = styled.div `
  background-color: ${mainAppColours.BLUE};
  font-size: 1rem;
  color: ${SAColourScheme.WHITE};
  border-radius: 0.2rem;
  text-align: center;
  padding: 0.2rem 0;
`;
const DateBar = styled.div `
  display: flex;
  align-items: center;
  padding: 0.1rem;
  font-size: 0.875rem;
  justify-content: center;
  font-weight: 500;
  color: #1a576f;

  .rbc-time-header {
    border-top: 0;
  }
`;
const TimeGutterHeader = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  border-right: none;
`;
const TimeGutterHeaderContent = styled.p `
  color: #35b700;
  font-weight: bold;
`;
const TimeSlotWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: white;
  color: #7f8e9d;
`;
const DateCellWrapper = styled.div `
  display: flex;
  flex-direction: column;
  background-color: #e4f6fb;
  align-items: flex-end;
  padding: 0;
  margin: 0 5px;
`;
const EventContainerWrapper = styled.div `
  background-color: ${SAColourScheme.WHITE};
  color: ${supportingColours.TEXT_DARK_GRAY};
`;
const EventContainer = styled.div `
  display: flex;
  background-color: ${({ allDay }) => (allDay ? "#CBF5FC" : "#ebf3ed")};
  color: #000;
  border-radius: 0.25rem;
  height: 100%;
`;
const SideBar = styled.div `
  background-color: #01b4d2;
  color: white;
  padding: 0.5rem;
  text-align: center;
  font-weight: bold;
  word-wrap: break-word;
  white-space: normal;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
`;
const EventDetails = styled.div `
  padding: 0.5rem;
  word-wrap: break-word;
  white-space: normal;
`;
