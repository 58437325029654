import { TestTypeRecordIndex } from "./types";
const getUserData = (state) => state.authentication.app.user;
const getApplicationInformation = (state) => state.authentication.app;
const getCollectionOrganisationInformation = (state) => state.authentication.app.collectionOrganization;
const selectIsLoading = (state) => state.authentication.isLoading;
const selectCollectionPoints = (state) => state.authentication.app.collectionPoints;
const selectTestTypes = (state) => state.authentication.app.testTypes;
const selectDrugTestTypes = (state) => state.authentication.app.testTypes.filter((testType) => testType.recordIndex !== TestTypeRecordIndex.BreathAlcoholTest);
const selectTestTypeByRecordIndex = (state) => {
    const { testTypes } = state.authentication.app;
    const listTypes = {};
    testTypes.forEach((testType) => {
        listTypes[testType.recordIndex] = testType.id;
    });
    return listTypes;
};
const selectCollectionOrganization = (state) => state.authentication.app.collectionOrganization;
const getCollectionPointInformation = (state) => state.authentication.app.collectionPoints;
const getRequestedTestTypeInformation = (state) => state.authentication.app.testTypes;
export { getUserData, getApplicationInformation, selectIsLoading, selectCollectionPoints, selectTestTypes, selectDrugTestTypes, getCollectionOrganisationInformation, selectTestTypeByRecordIndex, selectCollectionOrganization, getCollectionPointInformation, getRequestedTestTypeInformation, };
