import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { getLocationWiseData } from "src/api/dashboard/dashboardService";
import { HeaderFile } from "./HeaderFile";
import { DeviceByLocationTable } from "./Tables/DeviceByLocationTable";
export const DeviceByLocation = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const getLocationViewData = async () => {
        setIsLoading(true);
        try {
            const result = await getLocationWiseData();
            if (result.status === "success") {
                setLocationData(result.data);
            }
            else {
                setLocationData([]);
            }
            setIsLoading(false);
        }
        catch (error) {
            console.error("Error in getDeviceTypeData:", error);
        }
    };
    useEffect(() => {
        getLocationViewData();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(HeaderFile, { name: "Device Inventory by Location", icon: "ic-test.svg" }), _jsx("div", { style: { position: "relative" }, children: isLoading && (_jsx(Spin, { style: {
                        position: "absolute",
                        top: "100px",
                        left: "40vw",
                    } })) }), _jsx("div", { style: { opacity: isLoading ? 0.3 : 1 }, children: _jsx(DeviceByLocationTable, { locationData: locationData }) })] }));
};
