import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { OnsiteJobStatus } from "src/pages/JobManagement/store/types";
import { getListClientJobHistory } from "./action";
export const initialState = {
    listJobHistory: [],
    startDate: null,
    endDate: null,
    selectedCollectionPoint: { id: "", collectionPointName: "All" },
    selectedStatusType: [OnsiteJobStatus.Completed, OnsiteJobStatus.Cancelled],
    listStatusTypes: [
        {
            label: "Completed",
            value: OnsiteJobStatus.Completed,
        },
        {
            label: "Cancelled",
            value: OnsiteJobStatus.Cancelled,
        },
    ],
    pagingMetaData: null,
};
const ClientJobHistorySlice = createSlice({
    name: "ClientJobHistory",
    initialState,
    reducers: {
        setStartDate(state, action) {
            state.startDate = action.payload;
        },
        setEndDate(state, action) {
            state.endDate = action.payload;
        },
        setSelectedStatusTypes(state, action) {
            state.selectedStatusType = action.payload;
        },
        setCurrentCollectionPoint(state, action) {
            state.selectedCollectionPoint = action.payload;
        },
        setResetStore() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListClientJobHistory.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.listJobHistory = initialState.listJobHistory;
                message.error("Failed to fetch job history");
            }
            else {
                state.pagingMetaData = action.payload.pagingMeta;
                state.listJobHistory = action.payload.data;
            }
        })
            .addCase(getListClientJobHistory.rejected, () => {
            message.error("Failed to fetch job history");
        });
    },
});
export const { setSelectedStatusTypes, setStartDate, setEndDate, setCurrentCollectionPoint, setResetStore, } = ClientJobHistorySlice.actions;
export default ClientJobHistorySlice.reducer;
