import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { Col, Row, Text } from "src/components/base";
import { fontWeight, supportingColours } from "src/constants";
export const FooterForm = () => (_jsxs(StyledCol, { children: [_jsx(CenterRow, { children: _jsx(TermInfoRow, { children: _jsx(TermText, { name: "ABN 35 651 932 937" }) }) }), _jsxs(CenterRow, { children: [_jsx(TermText, { name: `© Copyright ${new Date().getFullYear()}  |  ` }), _jsx(TermLink, { href: "https://www.sampleassist.com/sampleassist-terms-conditions", children: "Terms and Conditions" }), "|", _jsx(TermLink, { href: "https://www.sampleassist.com/sampleassist-privacy-policy", children: "Privacy Policy" })] })] }));
const StyledCol = styled(Col) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vh;
  margin-top: 10px;
  margin-bottom: 20px;
  color: ${supportingColours.TEXT_LIGHT_GRAY};
`;
const CenterRow = styled(Row) `
  display: flex;
`;
const TermInfoRow = styled(Row) `
  width: 100%;
`;
const TermText = styled(Text) `
  text-align: center;
  color: ${supportingColours.TEXT_LIGHT_GRAY} !important;
  // font-weight: ${fontWeight.LIGHT};

  a {
    color: #80868b; !important
  }
`;
const TermLink = styled.a `
  text-decoration: none;
  color: ${supportingColours.TEXT_LIGHT_GRAY};
  &:hover {
    text-decoration: underline;
  }
`;
