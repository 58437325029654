import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Button, Divider, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import { MESSAGE_BOX_TYPE, mainAppColours } from "src/constants";
import PageLayoutWithNoTab from "src/components/layouts/PageLayout/PageLayoutWithNoTab";
import ContentContext from "src/providers/content";
import { getCollectionOrganisationInformation } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { calculateLastPeriod } from "src/utils/helper";
import { deleteCmUser, getAllUserRoles, getDetailCmUser, getListUsers, inviteCmUser, reInviteCmUser, restoreCmUser, suspendCmUser, } from "src/pages/UsersPage/store/action";
import { selectCMUsers, selectListRole } from "src/pages/UsersPage/store/selector";
import InviteUserPopup from "src/pages/UsersPage/InviteUserPopup";
import ViewUserDetails from "src/pages/UsersPage/ViewUserDetails";
import MainLayout from "src/components/layouts/MainLayout";
const userStatusMapping = {
    1: { status: "Invited", color: "#1A8CFF" },
    2: { status: "Onboarded", color: "#5CD22B" },
    3: { status: "Suspended", color: "#FD0000" },
    4: { status: "Deleted", color: "grey" },
};
const headerRowStyle = {
    backgroundColor: "#E6F7FA",
    borderTop: "1px solid #ADB3BC",
    borderBottom: "2px solid #ADB3BC",
};
export const UsersPage = () => {
    const { closePopupBox, openPopupBox, openMessageBox } = useContext(ContentContext);
    const collectionOrganisation = useAppSelector(getCollectionOrganisationInformation);
    const allCmUser = useAppSelector(selectCMUsers);
    const availableUserRole = useAppSelector(selectListRole);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useAppDispatch();
    const [isUserTable, setIsUserTable] = useState(true);
    const [isUserUpdated, setIsUserUpdated] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getListUsers());
            await dispatch(getAllUserRoles());
            dispatch(setIsLoading(false));
        };
        loadData();
    }, []);
    const handleSubmitInvitation = async (formData) => {
        await dispatch(inviteCmUser(formData));
        await dispatch(getListUsers());
        closePopupBox();
    };
    const handleDeleteUser = async (userId, firstName, lastName) => {
        handleMenuClose(userId);
        openMessageBox(MESSAGE_BOX_TYPE.PROMPT, _jsxs("div", { children: [_jsx(Typography, { variant: "h4", children: "Are you sure?" }), _jsxs(Typography, { children: [_jsxs("b", { children: [firstName, " ", lastName] }), " ", "will be deleted and no longer have access to ", collectionOrganisation.collectionName, "."] })] }), async () => {
            await dispatch(deleteCmUser(userId));
            await dispatch(getListUsers());
        });
    };
    const handleSuspendUser = async (userId, firstName, lastName) => {
        handleMenuClose(userId);
        openMessageBox(MESSAGE_BOX_TYPE.PROMPT, _jsxs("div", { children: [_jsx(Typography, { variant: "h4", children: "Are you sure?" }), _jsxs(Typography, { children: [_jsxs("b", { children: [firstName, " ", lastName] }), "'s access to SA Clinics will be suspended."] })] }), async () => {
            await dispatch(suspendCmUser(userId));
            await dispatch(getListUsers());
        });
    };
    const handleRestoreUser = async (userId) => {
        handleMenuClose(userId);
        await dispatch(restoreCmUser(userId));
        await dispatch(getListUsers());
    };
    const handleReInviteUser = async (userId) => {
        handleMenuClose(userId);
        await dispatch(reInviteCmUser(userId));
    };
    const handleViewUserDetails = async (userId) => {
        handleMenuClose(userId);
        const result = await dispatch(getDetailCmUser(userId));
        // @ts-expect-error: remove this later
        setUserDetails(result?.payload.data);
        setIsUserTable(false);
    };
    const handleMenuOpen = (id, event) => {
        setAnchorEl((prevAnchorEl) => ({
            ...prevAnchorEl,
            [id]: event.currentTarget,
        }));
    };
    const handleMenuClose = (id) => {
        setAnchorEl((prevAnchorEl) => ({
            ...prevAnchorEl,
            [id]: null,
        }));
    };
    return (_jsx(MainLayout, { children: _jsxs(React.Fragment, { children: [!isUserTable && (_jsxs("div", { style: {
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#E6F7FA",
                        padding: "0 100px 0 10px",
                    }, children: [_jsxs("div", { style: { display: "flex", alignItems: "center" }, children: [_jsx(IconButton, { onClick: () => {
                                        setIsUserTable(true);
                                        setIsUserUpdated(!isUserUpdated);
                                    }, children: _jsx(NavigateBeforeIcon, { style: { color: "#01B4D2" } }) }), _jsx(Typography, { style: { color: "#01B4D2" }, children: "Go Back" })] }), _jsx("div", { style: {
                                backgroundColor: "white",
                                padding: "10px",
                                borderRadius: "5px",
                            }, children: _jsxs("span", { style: {
                                    color: "#1A576F",
                                    borderBottom: "3px #FDBA00 solid",
                                    fontWeight: "bolder",
                                    padding: "10px 50px",
                                }, children: [userDetails?.userData?.firstName || "", " ", userDetails?.userData?.lastName || ""] }) })] })), isUserTable ? (_jsx(PageLayoutWithNoTab, { location: location.pathname, heading: "Users", children: _jsxs("div", { style: {
                            margin: "2rem",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                        }, children: [_jsxs("div", { style: {
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-between",
                                }, children: [_jsxs("div", { style: { display: "flex", gap: "15px", alignItems: "center" }, children: [_jsx("img", { style: { height: "30px" }, src: "/assets/svg/ic-cm-user.svg", alt: "ic-cm-user" }), _jsx(Typography, { children: "Collection Manager Users" })] }), _jsx("div", { style: {
                                            display: "flex",
                                            gap: "10px",
                                            flexWrap: "wrap",
                                            alignItems: "center",
                                        }, children: _jsx(Button, { startIcon: _jsx(AccountCircleIcon, { color: "primary" }), variant: "outlined", size: "small", onClick: () => openPopupBox(_jsx(InviteUserPopup, { userRoles: availableUserRole, onSend: handleSubmitInvitation }), null, {
                                                popupStyle: {
                                                    width: "40%",
                                                    borderRadius: "16px",
                                                    border: `2px solid ${mainAppColours.MID_BLUE}`,
                                                },
                                                // closeIcon: <Image src="/assets/svg/ic-close.svg" preview={false} />,
                                            }), style: {
                                                textTransform: "none",
                                                padding: "10px",
                                                fontWeight: "bolder",
                                                color: "#1A8CFF",
                                                border: "#1A8CFF solid 3px",
                                                width: "120px",
                                                marginLeft: "20px",
                                            }, children: "Invite" }) })] }), _jsx(TableContainer, { style: {
                                    maxHeight: "500px",
                                    width: "100%",
                                    backgroundColor: "white",
                                }, children: _jsxs(Table, { stickyHeader: true, children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "No." }) }), _jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "User" }) }), _jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "Email" }) }), _jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "User Title" }) }), _jsxs(TableCell, { style: headerRowStyle, children: [_jsx("b", { children: "User Role" }), _jsx("span", { children: "(Permissions)" })] }), _jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "Status" }) }), _jsx(TableCell, { style: headerRowStyle, children: _jsx("b", { children: "Last Active" }) }), _jsx(TableCell, { style: headerRowStyle })] }) }), _jsx(TableBody, { children: allCmUser.map((user, index) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: index + 1 }), _jsxs(TableCell, { children: [user.firstName, " ", user.lastName] }), _jsx(TableCell, { children: user.email }), _jsx(TableCell, { children: user.title }), _jsx(TableCell, { children: user.userRole }), _jsx(TableCell, { children: _jsx(Chip, { style: {
                                                                backgroundColor: userStatusMapping[user.status].color,
                                                                color: "white",
                                                                width: "100px",
                                                            }, label: userStatusMapping[user.status].status }) }), _jsx(TableCell, { children: user.lastActive ? calculateLastPeriod(user.lastActive) : "-" }), _jsxs(TableCell, { children: [_jsx(IconButton, { onClick: (event) => {
                                                                    handleMenuOpen(user.adminId, event);
                                                                }, children: _jsx(MoreHorizIcon, {}) }), anchorEl && (_jsx(Menu, { anchorEl: anchorEl[user.adminId], open: Boolean(anchorEl[user.adminId]), onClose: () => handleMenuClose(user.adminId), children: _jsxs("div", { style: {
                                                                        width: "200px",
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                    }, children: [_jsx(MenuItem, { onClick: () => handleViewUserDetails(user.adminId), children: "View Details" }), _jsx(MenuItem, { disabled: user.role === "Service Owner", onClick: () => handleViewUserDetails(user.adminId), children: "Change Permissions" }), user.userStatus === 2 && (_jsx(MenuItem, { disabled: user.userRole === "Service Owner", onClick: () => handleSuspendUser(user.adminId, user.firstName, user.lastName), children: "Suspend" })), user.status === 3 && (_jsx(MenuItem, { onClick: () => handleRestoreUser(user.adminId), children: "Restore" })), user.status === 1 && (_jsx(MenuItem, { onClick: () => handleReInviteUser(user.adminId), children: "Resend Invite" })), _jsx(Divider, { style: { margin: "5px 20px" } }), _jsx(MenuItem, { disabled: user.userRole === "Service Owner", style: { alignSelf: "flex-end" }, onClick: () => handleDeleteUser(user.adminId, user.firstName, user.lastName), children: "Delete" })] }) }))] })] }, user.adminId))) })] }) })] }) })) : (_jsx(ViewUserDetails, { userDetails: userDetails, userRoles: availableUserRole }))] }) }));
};
