var AuthorityProviderType;
(function (AuthorityProviderType) {
    AuthorityProviderType[AuthorityProviderType["ProviderNumber"] = 1] = "ProviderNumber";
    AuthorityProviderType[AuthorityProviderType["ABN"] = 2] = "ABN";
})(AuthorityProviderType || (AuthorityProviderType = {}));
const initialAuthorityInformation = {
    providerNumber: "",
    title: "",
    isCreatedPin: false,
    collectionOrganizationId: "",
    firstName: "",
    lastName: "",
    signatureUrl: "",
    honorific: "",
    abn: "",
    displayProviderType: AuthorityProviderType.ProviderNumber,
};
export { AuthorityProviderType, initialAuthorityInformation };
