import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Checkbox, Divider, Input, Select, TimePicker } from "antd";
import { sortBy } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import styled from "styled-components";
import DurationIcon from "src/assets/svg/job-management/duration-icon.svg";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { getApplicationInformation, selectDrugTestTypes } from "src/store/app/selector";
import { TimeFormat } from "src/store/app/types";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { SAColourScheme } from "src/styles";
import { selectCreatingJobDetails, selectCurrentCollectionPointDefaultData, } from "../store/selector";
import { setJobSetupDetails } from "../store/slice";
import { CombinationTestType, ScreeningProcessType } from "../store/types";
import { DrugTest, TestCombination } from "../typing";
import { getCollectionPointDefaultData } from "../store/action";
import FormItem from "./FormItem";
import Heading from "./Heading";
import "./styles/index.css";
const TestDevices = ({ disabled }) => {
    const dispatch = useAppDispatch();
    const collectionPointTestingDevices = useAppSelector(selectCurrentCollectionPointDefaultData)?.testingDevices;
    const drugTestTypes = useAppSelector(selectDrugTestTypes);
    const jobSetupDetails = useAppSelector(selectCreatingJobDetails).data;
    const appInformation = useAppSelector(getApplicationInformation);
    const { combinationTestType, screeningProcessPreferences, alcoholPreferences } = jobSetupDetails;
    const [primaryTest, secondaryTest, tertiaryTest] = sortBy(screeningProcessPreferences, "type", "asc");
    const updateScreeningProcessPreference = (recordIndex, type) => {
        if (recordIndex === null) {
            const newPreferences = screeningProcessPreferences.map((preference) => {
                if (preference.type === type) {
                    return {
                        type: type,
                        testTypeId: null,
                        recordIndex: null,
                        deviceId: null,
                    };
                }
                return preference;
            });
            dispatch(setJobSetupDetails({
                ...jobSetupDetails,
                screeningProcessPreferences: newPreferences,
            }));
        }
        else {
            const currentType = drugTestTypes.find((testType) => testType.recordIndex === recordIndex);
            const newPreferences = screeningProcessPreferences.map((preference) => {
                if (preference.type === type && currentType) {
                    return {
                        type: type,
                        testTypeId: currentType.id,
                        recordIndex: +currentType.recordIndex,
                        deviceId: null,
                    };
                }
                return preference;
            });
            dispatch(setJobSetupDetails({
                ...jobSetupDetails,
                screeningProcessPreferences: newPreferences,
            }));
        }
    };
    const updateTestDevicePreference = (type, deviceId) => {
        const newPreferences = screeningProcessPreferences.map((preference) => {
            if (preference.type === type) {
                return {
                    ...preference,
                    deviceId,
                };
            }
            return preference;
        });
        dispatch(setJobSetupDetails({
            ...jobSetupDetails,
            screeningProcessPreferences: newPreferences,
        }));
    };
    useEffect(() => {
        const loadData = () => {
            dispatch(getCollectionPointDefaultData());
        };
        loadData();
    }, []);
    return (_jsxs(React.Fragment, { children: [_jsx(SARow, { gutter: [0, 0], children: _jsx(SAColumn, { children: _jsx(HighlightContainer, { onChange: (checkedValue) => {
                            if (checkedValue.length) {
                                const latestChecked = checkedValue[checkedValue.length - 1];
                                dispatch(setJobSetupDetails({
                                    ...jobSetupDetails,
                                    combinationTestType: Number(latestChecked),
                                }));
                            }
                        }, value: [Number(combinationTestType) ?? 3], disabled: disabled, children: Object.entries(TestCombination).map(([key, name], index) => (_jsx(Checkbox, { value: +key, children: name }, index))) }) }) }), screeningProcessPreferences.length > 0 ? (_jsxs(TestDiv, { children: [(Number(combinationTestType) === CombinationTestType.AlcoholOnly ||
                        Number(combinationTestType) === CombinationTestType.AlcoholDrug) && (_jsx(SARow, { children: Array.from({ length: 4 }).map((_, index) => (_jsxs(SARow, { children: [_jsx(SAColumn, { md: 24, lg: 8, children: _jsx(CustomFormItem, { label: !index ? "Breath Alcohol Test Limits" : "", name: `alcoholPreferences[${index}].categoryName`, children: _jsx(StyledInput, { addonBefore: _jsx(NumberTag, { children: index + 1 }), placeholder: "Category Name", onChange: (e) => {
                                                const { value } = e.target;
                                                const newPreferences = [...(alcoholPreferences ?? [])];
                                                newPreferences[index] = {
                                                    ...newPreferences[index],
                                                    categoryName: value,
                                                };
                                                dispatch(setJobSetupDetails({
                                                    ...jobSetupDetails,
                                                    alcoholPreferences: newPreferences,
                                                }));
                                            } }) }) }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsx(CustomFormItem, { label: !index ? "Limit (g/210L)" : "", name: `alcoholPreferences[${index}].limit`, children: _jsx(Input, { type: "number", step: 0.001, onChange: (e) => {
                                                const { value } = e.target;
                                                const newPreferences = [...(alcoholPreferences ?? [])];
                                                newPreferences[index] = {
                                                    ...newPreferences[index],
                                                    limit: value,
                                                };
                                                dispatch(setJobSetupDetails({
                                                    ...jobSetupDetails,
                                                    alcoholPreferences: newPreferences,
                                                }));
                                            } }) }) }), _jsx(SAColumn, { md: 24, lg: 8, align: "flex-end", children: _jsx(SitTimeoutFormItem, { label: !index ? "Sit Out Time (minutes)" : "", name: `alcoholPreferences[${index}].sitOutTime`, children: _jsx(CustomTimePicker, { prevIcon: _jsx(DurationIcon, {}), format: "mm [min] ss [sec]", use12Hours: appInformation.collectionOrganization.timeFormat === TimeFormat.TwelveHour, onChange: (time) => {
                                                const currentTime = moment(time?.toISOString());
                                                const newPreferences = [...(alcoholPreferences ?? [])];
                                                const minutes = currentTime.minutes();
                                                const seconds = currentTime.seconds();
                                                newPreferences[index] = {
                                                    ...newPreferences[index],
                                                    sitOutTime: minutes * 60 + seconds,
                                                };
                                                dispatch(setJobSetupDetails({
                                                    ...jobSetupDetails,
                                                    alcoholPreferences: newPreferences,
                                                }));
                                            }, showNow: false, placeholder: "mm:ss", popupClassName: "timepicker-background", addon: () => (_jsxs(ExtraHeader, { children: [_jsx(HeaderTitleTimePicker, { children: "Mins" }), _jsx(HeaderTitleTimePicker, { children: "Sec" })] })) }) }) })] }, index))) })), Number(combinationTestType) === CombinationTestType.AlcoholDrug && (_jsx(DottedDivider, { dashed: true })), (Number(combinationTestType) === CombinationTestType.AlcoholDrug ||
                        Number(combinationTestType) === CombinationTestType.DrugOnly) && (_jsxs(React.Fragment, { children: [_jsxs(SARow, { children: [_jsx(SAColumn, { md: 24, lg: 12, children: _jsxs(CustomFormItem, { label: "Primary Drug Test", children: [_jsx(NumberTag, { children: "1" }), _jsxs(Select, { placeholder: "Select Primary Drug Test", value: primaryTest.recordIndex, onChange: (recordIndex) => updateScreeningProcessPreference(recordIndex, ScreeningProcessType.PrimaryTest), children: [_jsx(Select.Option, { value: null, children: "-- Select --" }, 0), drugTestTypes
                                                            .sort((a, b) => a.index - b.index)
                                                            .map((testType) => (_jsx(Select.Option, { value: testType.recordIndex, children: DrugTest[testType.recordIndex] }, testType.id)))] })] }) }), _jsx(SAColumn, { md: 24, lg: 12, children: _jsx(CustomFormItem, { label: " ", children: _jsxs(Select, { placeholder: "Select preferred device", notFoundContent: "No data or a collection point has not selected yet", value: primaryTest.deviceId, onChange: (deviceId) => updateTestDevicePreference(ScreeningProcessType.PrimaryTest, deviceId), children: [_jsx(Select.Option, { value: null, children: "-- Select --" }, 0), collectionPointTestingDevices
                                                        ?.filter((device) => device.recordIndex === primaryTest.recordIndex)
                                                        .map((device) => (_jsxs(Select.Option, { value: device.id, children: [device.name, " ", device.lotNumber] }, device.id)))] }) }) })] }), _jsxs(SARow, { children: [_jsx(SAColumn, { md: 24, lg: 12, children: _jsxs(CustomFormItem, { label: "Secondary Drug Test", children: [_jsx(NumberTag, { children: "2" }), _jsxs(Select, { placeholder: "Select Secondary Drug Test", value: secondaryTest.recordIndex, onChange: (recordIndex) => updateScreeningProcessPreference(recordIndex, ScreeningProcessType.SecondaryTest), children: [_jsx(Select.Option, { value: null, children: "-- Select --" }, 0), drugTestTypes
                                                            .sort((a, b) => a.index - b.index)
                                                            .map((testType) => (_jsx(Select.Option, { value: testType.recordIndex, children: testType.name }, testType.id)))] })] }) }), _jsx(SAColumn, { md: 24, lg: 12, children: _jsx(CustomFormItem, { label: " ", children: _jsxs(Select, { placeholder: "Select preferred device", notFoundContent: "No data or a collection point has not selected yet", value: secondaryTest.deviceId, onChange: (deviceId) => updateTestDevicePreference(ScreeningProcessType.SecondaryTest, deviceId), children: [_jsx(Select.Option, { value: null, children: "-- Select --" }, 0), collectionPointTestingDevices
                                                        ?.filter((device) => device.recordIndex === secondaryTest.recordIndex)
                                                        .map((device) => (_jsxs(Select.Option, { value: device.id, children: [device.name, " ", device.lotNumber] }, device.id)))] }) }) })] }), _jsxs(SARow, { children: [_jsx(SAColumn, { md: 24, lg: 12, children: _jsxs(CustomFormItem, { label: "Tertiary Drug Test", children: [_jsx(NumberTag, { children: "3" }), _jsxs(Select, { placeholder: "Select Tertiary Drug Test", value: tertiaryTest.recordIndex, onChange: (recordIndex) => updateScreeningProcessPreference(recordIndex, ScreeningProcessType.TertiaryTest), children: [_jsx(Select.Option, { value: null, children: "-- Select --" }, 0), drugTestTypes
                                                            .sort((a, b) => a.index - b.index)
                                                            .map((testType) => (_jsx(Select.Option, { value: testType.recordIndex, children: DrugTest[testType.recordIndex] }, testType.id)))] })] }) }), _jsx(SAColumn, { md: 24, lg: 12, children: _jsx(FormItem, { label: " ", children: _jsxs(Select, { placeholder: "Select preferred device", notFoundContent: "No data or a collection point has not selected yet", value: tertiaryTest.deviceId, onChange: (deviceId) => updateTestDevicePreference(ScreeningProcessType.TertiaryTest, deviceId), children: [_jsx(Select.Option, { value: null, children: "-- Select --" }, 0), collectionPointTestingDevices
                                                        ?.filter((device) => device.recordIndex === tertiaryTest.recordIndex)
                                                        .map((device) => (_jsxs(Select.Option, { value: device.id, children: [device.name, " ", device.lotNumber] }, device.id)))] }) }) })] })] }))] })) : (_jsx(_Fragment, {})), _jsx(Heading, { startTitle: "Additional Test (s)" }), _jsxs(SARow, { children: [_jsx(SAColumn, { md: 24, lg: 12, children: _jsx(FormItem, { name: "additionalTestPreference", children: _jsx(StyledInputTextArea, { placeholder: "Test(s)", value: jobSetupDetails.additionalTestPreference, onChange: (e) => {
                                    dispatch(setJobSetupDetails({
                                        ...jobSetupDetails,
                                        additionalTestPreference: e.target.value,
                                    }));
                                } }) }) }), _jsx(SAColumn, { md: 24, lg: 12, children: _jsx(FormItem, { name: "specialInstructionTestPreference", children: _jsx(StyledInputTextArea, { placeholder: "Additional Test Instructions", value: jobSetupDetails.specialInstructionTestPreference, onChange: (e) => {
                                    dispatch(setJobSetupDetails({
                                        ...jobSetupDetails,
                                        specialInstructionTestPreference: e.target.value,
                                    }));
                                } }) }) })] })] }));
};
export default TestDevices;
const StyledInput = styled(Input) `
  width: 100%;
  &:disabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    cursor: not-allowed !important;
  }

  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }
  .ant-input-group-addon {
    padding: 0;
    background-color: #ffffff;
    border: 0;
  }
`;
const CustomFormItem = styled(FormItem) `
  display: flex;
  flex-direction: row;
  width: 100%;

  div {
    width: 100%;
    display: flex;
  }
`;
const NumberTag = styled(Button) `
  border: 0.5px solid #156cc9;
  color: #00b4d1;
  background-color: #fff;
  margin-right: 1rem;
`;
const CustomTimePicker = styled(TimePicker) `
  width: 100%;

  .custom-time-picker .timepicker-background .ant-picker-panel {
    padding-top: 1.5rem;
    background-color: red;
  }
`;
const HighlightContainer = styled(Checkbox.Group) `
  padding: 1rem 2rem;
  background-color: #e6f7fa;
  border-radius: 1.2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .ant-checkbox-inner {
    height: 20px;
    width: 20px;

    &::after {
      width: 6px;
      height: 12px;
    }
  }
`;
const StyledInputTextArea = styled(Input.TextArea) `
  width: 100%;
  &:disabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    cursor: not-allowed !important;
  }
  margin-block: 1rem;
`;
const SitTimeoutFormItem = styled(CustomFormItem) `
  top: 0px;
  left: 0px;
  width: 100%;
`;
const ExtraHeader = styled.div `
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1.5715;
  position: absolute;
  z-index: 1060;
  background-color: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  border-bottom: 1px solid #f0f0f0;
`;
const HeaderTitleTimePicker = styled.p `
  width: 50%;
  font-size: 14px;
  text-align: center;
`;
const DottedDivider = styled(Divider) `
  margin: 0 0 1rem;
  border-color: ${SAColourScheme.PLASSMA} !important;
`;
const TestDiv = styled.div `
  padding: 0 3rem;
`;
