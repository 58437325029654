import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "antd";
import React from "react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import styled from "styled-components";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { COLORS } from "../../../typing";
const CustomLabel = ({ viewBox, totalValue = 0 }) => {
    const cx = viewBox?.cx;
    const cy = viewBox?.cy;
    const totalValueString = totalValue.toString();
    const characterCount = totalValueString.length;
    const xOffset = characterCount * 10;
    return (_jsxs(React.Fragment, { children: [_jsx("text", { x: cx - xOffset, y: cy - 5, children: _jsx("tspan", { style: {
                        fontWeight: 700,
                        fontSize: "2.5em",
                        fill: "rgba(255, 255, 255)",
                    }, children: totalValue }) }), _jsx("text", { x: cx - 25, y: cy + 35, children: _jsx("tspan", { style: {
                        fontSize: "1.5em",
                        fill: "rgba(255, 255, 255)",
                    }, children: "Total" }) })] }));
};
export const CustomPieChart = ({ data }) => {
    const { width, height } = useWindowDimensions();
    const totalValue = data.reduce((acc, curr) => acc + curr.value, 0);
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (_jsxs(TooltipWrapper, { children: [_jsx(Typography.Text, { children: payload[0].nameKey }), _jsx(Typography.Text, { children: !totalValue ? 0 : payload[0].value.toString() })] }));
        }
        return null;
    };
    const calculateRadius = (currentWidth, currentHeight) => {
        const referenceWidth = 2600;
        const referenceHeight = 1300;
        const referenceOuterRadius = 160;
        const widthScaleFactor = currentWidth / referenceWidth;
        const heightScaleFactor = currentHeight / referenceHeight;
        const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);
        const adjustedScaleFactor = Math.max(scaleFactor, 0.6);
        const outerRadius = Math.round(referenceOuterRadius * adjustedScaleFactor);
        const innerRadius = Math.max(outerRadius - 30, 0);
        return { innerRadius, outerRadius };
    };
    return (_jsx(ResponsiveContainer, { children: _jsxs(PieChart, { children: [_jsxs(Pie, { data: !totalValue ? data.map((item) => ({ ...item, value: 1 })) : data, innerRadius: calculateRadius(width, height).innerRadius, outerRadius: calculateRadius(width, height).outerRadius, dataKey: "value", nameKey: "name", paddingAngle: 0, startAngle: 90, endAngle: -270, children: [data.map((entry, index) => (_jsx(Cell, { fill: COLORS[index % data.length], stroke: COLORS[index % data.length], cursor: "pointer" }, `cell-${index + 1}`))), _jsx(Label, { content: (props) => _jsx(CustomLabel, { totalValue: totalValue, viewBox: props.viewBox }), position: "center" })] }), _jsx(Tooltip, { content: ({ active, payload, label }) => (_jsx(CustomTooltip, { active: active, payload: payload?.map((entry, index) => ({
                            value: entry.value,
                            dataKey: entry.dataKey,
                            nameKey: entry.name,
                            fill: COLORS[index % data.length],
                        })), label: label })) })] }) }));
};
const TooltipWrapper = styled.div `
  background-color: #ffffff;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 12rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;

  span {
    color: #000000 !important;
  }
`;
