import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
const PageLayoutWithNoTab = ({ children, location, heading, }) => {
    const navigate = useNavigate();
    return (_jsxs(PageLayoutWithNoTabWrapper, { children: [_jsx(Header, { children: _jsx(HeadingWrapper, { children: _jsx(ActiveBreadcrumb, { onClick: () => navigate(location), children: heading }) }) }), _jsx(Content, { children: children })] }));
};
export default PageLayoutWithNoTab;
const HeadingWrapper = styled.div `
  padding-inline: 2.5rem;
  padding-top: 0.5rem;
`;
const ActiveBreadcrumb = styled.p `
  color: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border-bottom: 0.2rem solid #01b4d2;
  width: fit-content;
`;
const PageLayoutWithNoTabWrapper = styled.div `
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
`;
const Header = styled.div `
  height: 5rem;
  background-color: #2c7796;
  width: 100%;
`;
