/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { deleteLab, deleteOrganizationCollectionPoint, getCollectionCentreById, getCollectionCentreByUUID, getCollectionOrganization, getCollectionOrganizationDetails, getCollectionPointByCentreId, UpdateLaboratoryDetails, UpdateOrganizationDetails, } from "./action";
export const initialState = {
    currentCollector: null,
    currentCollectionCentre: null,
    currentCollectionPoint: null,
    currentCollectionOrganization: null,
    collectionOrganizationDetails: null,
    updatedOrganizationDetails: null,
    updatedLaboratoryDetails: null,
    collectionPoints: [],
    laboratories: [],
    collectionCentre: null,
};
const collectionOrganizationSlice = createSlice({
    name: "collectionOrganization",
    initialState,
    reducers: {
        setCurrentCollector(state, action) {
            state.currentCollector = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCollectionCentreById.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch collection centre information");
            }
            else {
                state.currentCollectionCentre = action.payload.data;
            }
        })
            .addCase(getCollectionCentreById.rejected, () => {
            message.error("Failed to fetch collection centre information");
        })
            .addCase(getCollectionPointByCentreId.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch collection point information");
            }
            else {
                state.currentCollectionPoint = action.payload.data;
            }
        })
            .addCase(getCollectionPointByCentreId.rejected, () => {
            message.error("Failed to fetch collection point information");
        })
            .addCase(getCollectionOrganization.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch collection organization information");
            }
            else {
                state.currentCollectionOrganization = action.payload.data;
            }
        })
            .addCase(getCollectionOrganization.rejected, () => {
            message.error("Failed to fetch collection organization information");
        })
            .addCase(getCollectionOrganizationDetails.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch collection organization details");
            }
            else {
                state.collectionOrganizationDetails = action.payload.data;
            }
        })
            .addCase(getCollectionOrganizationDetails.rejected, () => {
            message.error("Failed to fetch collection organization details");
        })
            .addCase(UpdateOrganizationDetails.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Failed to update organization details");
            }
            else {
                message.success("Organization details updated successfully");
                state.updatedOrganizationDetails = action.payload.data;
            }
        })
            .addCase(UpdateOrganizationDetails.rejected, () => {
            message.error("Failed to update organization details");
        })
            .addCase(UpdateLaboratoryDetails.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Failed to update laboratory details");
            }
            else {
                message.success("Laboratory details updated successfully");
                state.updatedLaboratoryDetails = action.payload.data;
            }
        })
            .addCase(UpdateLaboratoryDetails.rejected, () => {
            message.error("Failed to update laboratory details");
        })
            .addCase(deleteOrganizationCollectionPoint.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Failed to delete collection point");
            }
            else {
                message.success("Collection point deleted successfully");
                state.collectionPoints = state.collectionPoints.filter((point) => point.id !== action.meta.arg.collectionPointId);
            }
        })
            .addCase(deleteOrganizationCollectionPoint.rejected, () => {
            message.error("Failed to delete collection point");
        })
            .addCase(deleteLab.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error("Failed to delete laboratory");
            }
            else {
                message.success("Laboratory deleted successfully");
                state.laboratories = state.laboratories.filter((lab) => lab.id !== action.meta.arg.labId);
            }
        })
            .addCase(deleteLab.rejected, () => {
            message.error("Failed to delete laboratory");
        })
            .addCase(getCollectionCentreByUUID.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch collection centre by UUID");
            }
            else {
                state.collectionCentre = action.payload.data;
            }
        })
            .addCase(getCollectionCentreByUUID.rejected, () => {
            message.error("Failed to fetch collection centre by UUID");
        });
    },
});
export default collectionOrganizationSlice.reducer;
