import styled from "styled-components";
const FlexEndContainer = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`;
const FlexStartContainer = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`;
const CenterContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`;
const SpaceBetweenContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`;
export { FlexEndContainer, FlexStartContainer, CenterContainer, SpaceBetweenContainer };
