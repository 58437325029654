import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Divider, Image, Space, Spin, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SAColourScheme, mainAppColours } from "src/styles";
import { Text } from "src/components/base";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { getApplicationInformation, selectIsLoading } from "src/store/app/selector";
import { formatTime } from "src/utils/helper";
import { selectNoteHistory } from "./store/selector";
import { getNoteHistory } from "./store/action";
export const NoteLogHistory = ({ selectedItem }) => {
    const [tableVisible, setTableVisible] = useState(false);
    const dispatch = useAppDispatch();
    const historyData = useAppSelector(selectNoteHistory);
    const isLoading = useAppSelector(selectIsLoading);
    const appInformation = useAppSelector(getApplicationInformation);
    const handleButtonClick = () => {
        setTableVisible(!tableVisible);
    };
    const columns = [
        {
            title: "Updated By",
            dataIndex: "updatedBy",
            key: "updatedBy",
        },
        {
            title: "Date",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (text, _) => {
                const date = moment(text).format("DD/MM/YYYY");
                return _jsx(Space, { children: date });
            },
        },
        {
            title: "Time",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (text, _) => (_jsx(Space, { children: formatTime(appInformation?.collectionOrganization.timeFormat, text) })),
        },
    ];
    useEffect(() => {
        const loadData = async (noteId) => {
            dispatch(setIsLoading(true));
            await dispatch(getNoteHistory(noteId));
            dispatch(setIsLoading(false));
        };
        if (selectedItem) {
            loadData(selectedItem?.id);
        }
    }, [selectedItem]);
    return (_jsxs(React.Fragment, { children: [_jsxs(Button, { onClick: handleButtonClick, style: {
                    border: `1px solid #D9D9D9`,
                    borderRadius: "4px",
                    marginBottom: 10,
                    background: `${SAColourScheme.PLASSMA10}`,
                }, children: [_jsx("span", { style: { color: `${mainAppColours.MID_BLUE}` }, children: "Change Log" }), _jsx("span", { style: { marginLeft: 12 }, children: tableVisible ? (_jsx(Image, { src: "/assets/svg/minus-icon.svg", preview: false })) : (_jsx(Image, { src: "/assets/svg/ic-history-add.svg", preview: false })) })] }), _jsx(CustomDivider, {}), _jsx(LoadingDiv, { children: tableVisible && isLoading && (_jsxs(LoadingWrapper, { children: [_jsx(Spin, {}), _jsx(Text, { name: "Fetching data..." })] })) }), _jsx(StyledTableWrapper, { tableVisible: tableVisible, children: !isLoading && (_jsx(Table, { columns: columns, dataSource: historyData.length > 0
                        ? historyData.map((item) => ({ ...item, key: item.id }))
                        : [], pagination: false, scroll: { x: 700, y: 550 } })) })] }));
};
const StyledTableWrapper = styled.div `
  .ant-table-wrapper {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    overflow: hidden;
    visibility: ${({ tableVisible }) => (tableVisible ? "visible" : "hidden")};
    height: ${({ tableVisible }) => (tableVisible ? "100%" : "0")};
  }
`;
const CustomDivider = styled(Divider) `
  border: 1px solid #01b4d2;
  margin-top: 0;
`;
const LoadingDiv = styled.div `
  position: relative;
`;
const LoadingWrapper = styled.div `
  display: flex;
  justify-content: center;
  gap: 1em;
`;
