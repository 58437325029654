import { createAsyncThunk } from "@reduxjs/toolkit";
import { $get, $post, $put } from "src/infra/http";
export const getAuthorityInformation = createAsyncThunk("getAuthorityInformation", async () => await $get(`/collection-manager/authority/`));
export const updateAuthorityInformation = createAsyncThunk("updateAuthorityInformation", async (data) => await $post(`/collection-manager/authority/`, { ...data }));
export const verifyPin = createAsyncThunk("authority/verifyPin", async ({ pin }) => await $post(`/collection-manager/authority/verify-pin/`, {
    pin,
}));
export const updateSignature = createAsyncThunk("authority/updateSignature", async ({ signature }) => await $put(`/collection-manager/authority/update-signature/`, {
    signature,
}));
export const updatePin = createAsyncThunk("authority/updatePin", async ({ oldPin, newPin }) => await $put(`/collection-manager/authority/update-pin/`, {
    oldPin,
    newPin,
}));
export const createPin = createAsyncThunk("authority/createPin", async ({ newPin }) => await $post(`/collection-manager/authority/create-pin/`, {
    pin: newPin,
}));
