import styled from "styled-components";
export const HeaderFilterWrapper = styled.div `
  margin-top: 1rem;

  .start-date span.ant-picker-suffix,
  .end-date span.ant-picker-suffix {
    display: none;
  }

  .ant-select {
    width: 100%;
  }
  .ant-picker.start-date,
  .ant-picker.end-date {
    width: 98px;
    height: 34px;
    padding-left: 7px;
    border-radius: 5px;
    padding-top: 3px;
  }
  button.ant-btn.ant-btn-default.print-button {
    height: 36px;
    width: 93px;
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
  }
  button.ant-btn.ant-btn-default.print-button span {
    color: #367893;
    font-size: 16px;
    padding-left: 12px;
  }
  button.ant-btn.ant-btn-default.select-button {
    height: 36px;
    width: 110px;
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .select-button .ant-image {
    position: absolute;
    margin-left: 70px;
    margin-top: 2px;
  }
  button.ant-btn.ant-btn-default.select-button span {
    color: #367893;
    font-size: 16px;
    padding-right: 35px;
  }
  .button-selection > .ant-select > .ant-select-selector {
    border: 2px solid #adb3bc;
    border-radius: 5px;
    height: 42px !important;
    width: 100%;
    color: white;
    background: #1a8cff;
  }

  .ant-select-arrow {
    margin-top: 0;
  }
`;
