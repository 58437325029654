import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Calendar } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Text, Image, Row } from "src/components/base";
import { SAColourScheme } from "src/constants";
import { CalendarHeaderWrapper, HomeCalendarWrapper } from "./HomeCalendar.style";
export const HomeCalendar = ({ onDateChange, currentDate, calendarProps }) => {
    const [selectedMonth, setSelectedMonth] = useState(moment().month());
    const [selectedYear, setSelectedYear] = useState(moment().year());
    const headerRender = ({ value, onChange }) => {
        const current = value.clone();
        const currentMonth = moment(value).format("MMMM");
        const handleClick = (increase) => {
            const newValue = value.clone();
            newValue.month(current.month() + (increase ? 1 : -1));
            onChange(newValue);
        };
        return (_jsxs(CalendarHeaderWrapper, { children: [_jsx(Button, { className: "change-button", onClick: () => {
                        handleClick(false);
                    }, children: _jsx(Image, { src: "/assets/svg/ic-arrow-left.svg", preview: false }) }), _jsx(Text, { className: "current-month", name: currentMonth }), _jsx(Button, { className: "change-button", onClick: () => {
                        handleClick(true);
                    }, children: _jsx(Image, { src: "/assets/svg/ic-arrow-right.svg", preview: false }) })] }));
    };
    const dateCellRender = (value) => {
        const date = moment(value).format("DD");
        if (value) {
            if (value.month() === selectedMonth) {
                return _jsx("div", { className: "ant-picker-cell-inner", children: date });
            }
            else {
                return _jsx(_Fragment, {});
            }
        }
    };
    const handleTodayButtonClick = () => {
        const today = moment();
        setSelectedMonth(today.month());
        setSelectedYear(today.year());
        onDateChange(today);
    };
    useEffect(() => { }, [selectedMonth]);
    return (_jsxs(HomeCalendarWrapper, { children: [_jsxs("div", { style: {
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                    gap: "15px",
                    height: "55px",
                }, children: [_jsx(Text, { className: "calendar-title", name: "Calendar" }), _jsxs(Button, { className: "today-button", onClick: handleTodayButtonClick, style: {
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            background: "rgba(255, 255, 255, 0.5)",
                            color: "#FFFFFF",
                            borderRadius: "13px",
                            border: "none",
                            padding: "7px",
                            right: "20px",
                            top: "22px",
                        }, children: [_jsx(Image, { src: "/assets/svg/today-icon.svg", style: { width: 12, marginRight: 5 }, preview: false }), "Today"] })] }), _jsx(Calendar, { ...calendarProps, fullscreen: false, headerRender: headerRender, dateFullCellRender: dateCellRender, value: currentDate, onChange: (date) => {
                    setSelectedMonth(date.month());
                    setSelectedYear(date.year());
                    onDateChange(date);
                } }), _jsxs(Row, { className: "calendar-year", style: { width: "100%" }, children: [_jsx("div", { className: "dot", style: { background: SAColourScheme.PLASMA } }), _jsx("div", { className: "dot", style: { background: SAColourScheme.MID_BLUE } }), _jsx("div", { className: "dot", style: { background: SAColourScheme.NAVY } }), _jsx(Text, { name: String(selectedYear) }), _jsx("div", { className: "dot", style: { background: SAColourScheme.NAVY } }), _jsx("div", { className: "dot", style: { background: SAColourScheme.MID_BLUE } }), _jsx("div", { className: "dot", style: { background: SAColourScheme.PLASMA } })] })] }));
};
