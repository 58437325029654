import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Divider, Dropdown, Image, Menu, Space } from "antd";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MESSAGE_BOX_TYPE, SAColourScheme, clientType, mainAppColours } from "src/constants";
import ContentContext from "src/providers/content";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { PaginatedDataTable } from "src/components/commonElement/PaginatedDataTable";
import { selectArchivedClientList, selectIsArchiveTableVisible, selectPagingMetaDataArchive, } from "../NewClient/components/ClientDetails/store/selector";
import { setArchiveTableVisible } from "../NewClient/components/ClientDetails/store/slice";
import { getListArchivedClients, getListClients, restoreClient, } from "../NewClient/components/ClientDetails/store/action";
export const ArchiveData = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { openMessageBox } = useContext(ContentContext);
    const tableVisible = useAppSelector(selectIsArchiveTableVisible);
    const archivedClientsData = useAppSelector(selectArchivedClientList);
    const pagingMetaDataArchive = useAppSelector(selectPagingMetaDataArchive);
    const columns = [
        {
            title: "Client Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Contact",
            dataIndex: "contact",
            key: "contact",
            sorter: (a, b) => a.contact.localeCompare(b.contact),
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
            sorter: (a, b) => a.mobile.localeCompare(b.mobile),
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Client Reference",
            dataIndex: "clientReference",
            key: "clientReference",
            sorter: (a, b) => a.clientReference.localeCompare(b.clientReference),
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Call Out",
            dataIndex: "type",
            key: "type",
            sorter: (a, b) => a.type - b.type,
            render: (text) => _jsx(Space, { children: text === clientType.NORMAL ? "No" : "Yes" }),
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(Menu, { style: {
                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                        width: "150px",
                    }, children: [_jsxs(Menu.Item, { children: [_jsx("a", { color: "text.primary", onClick: () => {
                                        navigate(`/client-management/client/${record.id}/client-details`);
                                    } }, "1"), "View/Update"] }, "1"), _jsx(Menu.Item, { style: {
                                borderTop: "1px solid #ADB3BC",
                                width: "80%",
                                margin: "0 auto",
                            }, onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT_BLUE, `Client with Reference No. ${record.clientReference} will be restored and available.`, async () => {
                                    dispatch(setIsLoading(true));
                                    await dispatch(restoreClient(record.id));
                                    await dispatch(getListClients({}));
                                    await dispatch(getListArchivedClients({}));
                                    dispatch(setIsLoading(false));
                                });
                            }, children: _jsx("span", { style: { display: "flex", justifyContent: "flex-end" }, children: "Restore" }) }, "2")] }), trigger: ["click"], children: _jsx("div", { className: "menu-options", children: _jsx(ICMenuOptions, {}) }) })),
        },
    ];
    useEffect(() => {
        if (tableVisible) {
            dispatch(setIsLoading(true));
            dispatch(getListArchivedClients({}));
            dispatch(setIsLoading(false));
        }
    }, [tableVisible]);
    return (_jsxs(TableWrapper, { children: [_jsxs(Button, { onClick: () => {
                    dispatch(setArchiveTableVisible(!tableVisible));
                }, className: "client-archive", children: [_jsx("span", { children: "Client Archive" }), _jsx("span", { className: "icon", children: tableVisible ? (_jsx(Image, { src: "/assets/svg/minus-icon.svg", preview: false })) : (_jsx(Image, { src: "/assets/svg/ic-history-add.svg", preview: false })) })] }), _jsx(CustomDivider, {}), tableVisible && (_jsx(PaginatedDataTable, { columns: columns, dataSource: archivedClientsData, pagingMetaData: pagingMetaDataArchive, onTableChange: async (newPage, size) => {
                    dispatch(setIsLoading(true));
                    await dispatch(getListArchivedClients({ newPage, size }));
                    dispatch(setIsLoading(false));
                } }))] }));
};
export const TableWrapper = styled.div `
  margin-top: 30px;
  z-index: 10;

  .client-archive {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 10px;
    background: ${SAColourScheme.PLASSMA10};
    color: ${mainAppColours.MID_BLUE};

    .icon {
      margin-left: 12px;
    }
  }

  .archive-table {
    margin-top: 10px;
    border: 1px solid #d9d9d9;
    overflow: hidden;
  }

  .archive-table.show {
    visibility: visible;
    height: 100%;
  }

  .archive-table.hidden {
    visibility: hidden;
    height: 0;
  }

  .menu-options {
    cursor: pointer;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: ${SAColourScheme.PLASSMA10};
      border-bottom: 1px solid #adb3bc;
    }
  }

  .options-dropdown {
    border: 1px solid ${mainAppColours.MID_BLUE};
  }

  .menu-options:hover {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${SAColourScheme.PLASMA};
  }

  .client-data-table {
    border: 1px solid #d9d9d9;
  }
`;
const CustomDivider = styled(Divider) `
  border: 2px solid ${SAColourScheme.PLASMA};
  margin: 0 0 0.5rem;
`;
