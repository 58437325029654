import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Divider, Row } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Text } from "src/components/base";
import { SAColourScheme } from "src/styles";
import { calculateDuration, getDate, getTestDuration } from "src/utils/helper";
export const PatientTime = ({ donorTestDetailsData }) => {
    const patientStartTime = donorTestDetailsData && getDate(donorTestDetailsData?.appointmentStartAt);
    const patientEndTime = donorTestDetailsData && getDate(donorTestDetailsData?.appointmentEndAt);
    const patientDuration = patientStartTime && patientStartTime && getTestDuration(patientStartTime, patientEndTime);
    // Variables to track the total duration, earliest startAt, and latest endAt
    let totalDurationSeconds = 0;
    let allTestStartAt = null;
    let allTestEndAt = null;
    // Iterate through the medical tests
    donorTestDetailsData?.validSummaries?.forEach((medicalTest) => {
        const startAt = getDate(medicalTest.medicalTestStartAt);
        const endAt = getDate(medicalTest.medicalTestEndAt);
        // Calculate duration in milliseconds
        const durationMillis = endAt.getTime() - startAt.getTime();
        // Convert milliseconds to seconds and accumulate
        totalDurationSeconds += durationMillis / 1000;
        // Determine the earliest startAt and latest endAt
        if (allTestStartAt === null || startAt < allTestStartAt) {
            allTestStartAt = startAt;
        }
        if (allTestEndAt === null || endAt > allTestEndAt) {
            allTestEndAt = endAt;
        }
    });
    return (_jsxs(_Fragment, { children: [_jsxs(StyledTestRow, { children: [_jsx(Col, { span: 12, className: "test-summary-text", children: _jsx(Text, { name: "TEST SUMMARY" }) }), _jsx(StyledTestCol, { span: 12, children: _jsx(StyledButtonText, { type: "text", disabled: true, children: "On-Site Test" }) })] }), _jsx(PageWrapper, { children: _jsxs(StyledRow, { children: [_jsx(StyledLabelCol, { xs: 24, lg: 6, children: _jsx(Text, { name: "Patient Time", className: "authority-info" }) }), _jsxs(StyledValueCol, { xs: 24, lg: 6, children: [_jsx(Text, { name: "Duration", className: "authority-title" }), _jsx(Text, { name: patientDuration, className: "authority-info" })] }), _jsxs(StyledValueCol, { xs: 12, lg: 6, children: [_jsx(Text, { name: "Start Time", className: "authority-title" }), _jsx(Text, { name: moment(patientStartTime).format("hh:mm a"), className: "authority-info" })] }), _jsxs(StyledValueCol, { xs: 12, lg: 6, children: [_jsx(Text, { name: "End Time", className: "authority-title" }), _jsx(Text, { name: moment(patientEndTime).format("hh:mm a"), className: "authority-info" })] }), _jsx(Divider, {}), _jsx(StyledLabelCol, { xs: 24, lg: 6, children: _jsx(Text, { name: "Total Test Time", className: "authority-info" }) }), _jsxs(StyledValueCol, { xs: 24, lg: 6, children: [_jsx(Text, { name: "Duration", className: "authority-title" }), _jsx(Text, { name: calculateDuration(totalDurationSeconds), className: "authority-info" })] }), _jsxs(StyledValueCol, { xs: 12, lg: 6, children: [_jsx(Text, { name: "Start Time", className: "authority-title" }), _jsx(Text, { name: moment(allTestStartAt).format("hh:mm a"), className: "authority-info" })] }), _jsxs(StyledValueCol, { xs: 12, lg: 6, children: [_jsx(Text, { name: "End Time", className: "authority-title" }), _jsx(Text, { name: moment(allTestEndAt).format("hh:mm a"), className: "authority-info" })] })] }) })] }));
};
const StyledButtonText = styled(Button) `
  background: #56acb1 !important;
  color: ${SAColourScheme.WHITE} !important;
  border-radius: 15px;
  width: 100%;
  max-width: 15rem;
  cursor: default !important;
`;
const StyledTestRow = styled(Row) `
  padding: 50px 0 10px 0;
  border-bottom: 4px solid #00004c;

  .test-summary-text {
    display: flex;
    align-items: center;
    color: #00004c;
    font-weight: 700;
    font-size: 1rem;
    padding-left: 1rem;
  }
`;
const StyledTestCol = styled(Col) `
  display: flex;
  justify-content: flex-end;
`;
const PageWrapper = styled.div `
  margin: 2em 0 2em 0;

  .ant-divider-horizontal {
    margin: 0;
    border-top: 1px solid ${SAColourScheme.PLASSMA} !important;
  }
`;
const StyledRow = styled(Row) `
  border: 1px solid #01b4d2;
  border-radius: 5px;

  .authority-title {
    font-size: 1em;
    color: #7f8e9d !important;
  }

  .authority-info {
    font-size: 1em;
    color: #2f3337 !important;
  }
`;
const StyledLabelCol = styled(Col) `
  background-color: #e4f6fb;
  padding: 1em;
`;
const StyledValueCol = styled(Col) `
  padding: 1em;
  display: flex;
  gap: 1em;
`;
