import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Divider, Dropdown, Image, Menu, Space } from "antd";
import { useContext, useState } from "react";
import styled from "styled-components";
import { MESSAGE_BOX_TYPE, mainAppColours } from "src/constants";
import ContentContext from "src/providers/content";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
import { DataTable } from "src/components/commonElement/DataTable";
import { useAppDispatch } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { SAColourScheme } from "src/styles";
import { getListArchivedClientLocations, getListClientLocations, restoreClientLocation, } from "./store/action";
import { setIsAddNew, setIsListPage, setLocationId } from "./store/slice";
export const LocationArchive = ({ archivedLocationsData, clientId, }) => {
    const { openMessageBox } = useContext(ContentContext);
    const [tableVisible, setTableVisible] = useState(false);
    const dispatch = useAppDispatch();
    const handleButtonClick = () => {
        setTableVisible(!tableVisible);
    };
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, __, index) => index + 1,
        },
        {
            title: "Site Name",
            dataIndex: "siteName",
            key: "siteName",
            sorter: (a, b) => a.siteName.localeCompare(b.siteName),
        },
        {
            title: "Site Contact",
            dataIndex: "contact",
            key: "contact",
            render: (_, record) => {
                const { clientContacts } = record;
                if (!clientContacts || clientContacts.length === 0) {
                    return _jsx(Space, { children: "No contacts" });
                }
                if (clientContacts.length === 1) {
                    return (_jsx(Space, { children: _jsx("b", { children: clientContacts[0].name }) }));
                }
                return (_jsxs(Space, { children: [_jsx("b", { children: clientContacts[0].name }), " + ", clientContacts.length - 1, "more"] }));
            },
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
            render: (_, record) => {
                const { clientContacts } = record;
                if (!clientContacts || clientContacts.length === 0) {
                    return _jsx(Space, { children: "No contacts" });
                }
                if (clientContacts.length === 1) {
                    return (_jsx(Space, { children: _jsx("b", { children: clientContacts[0].mobile }) }));
                }
                return (_jsxs(Space, { children: [_jsx("b", { children: clientContacts[0].mobile }), " + ", clientContacts.length - 1, "more"] }));
            },
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            sorter: (a, b) => a.address1.localeCompare(b.address2),
            render: (_, record) => (_jsx(Space, { children: record.address1 + (record.address2 ? ", " + record.address2 : "") })),
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(Menu, { style: {
                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                        width: "150px",
                    }, children: [_jsx(Menu.Item, { onClick: () => {
                                dispatch(setIsListPage(false));
                                dispatch(setIsAddNew(false));
                                dispatch(setLocationId(record.id));
                            }, children: "View/Update" }, "1"), _jsx(Menu.Item, { style: {
                                borderTop: "1px solid #ADB3BC",
                                width: "80%",
                                margin: "0 auto",
                            }, onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT_BLUE, `Client Location - ${record.siteName} will be restored and available.`, async () => {
                                    dispatch(setIsLoading(true));
                                    await dispatch(restoreClientLocation(record.id));
                                    if (clientId) {
                                        await dispatch(getListClientLocations(clientId));
                                        await dispatch(getListArchivedClientLocations(clientId));
                                    }
                                    dispatch(setIsLoading(false));
                                });
                            }, children: _jsx("span", { style: { display: "flex", justifyContent: "flex-end" }, children: "Restore" }) }, "2")] }), trigger: ["click"], children: _jsx("div", { className: "menu-options", children: _jsx(ICMenuOptions, {}) }) })),
        },
    ];
    return (_jsxs(TableWrapper, { children: [_jsxs(Button, { onClick: handleButtonClick, className: "client-archive", children: [_jsx("span", { children: "Location Archive" }), _jsx("span", { className: "icon", children: tableVisible ? (_jsx(Image, { src: "/assets/svg/minus-icon.svg", preview: false })) : (_jsx(Image, { src: "/assets/svg/ic-history-add.svg", preview: false })) })] }), _jsx(CustomDivider, {}), tableVisible && (_jsx(DataTable, { dataSource: archivedLocationsData?.length > 0
                    ? archivedLocationsData.map((item) => ({
                        ...item,
                        key: item.id,
                    }))
                    : [], columns: columns }))] }));
};
export const TableWrapper = styled.div `
  margin-top: 30px;
  z-index: 10;

  .client-archive {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 10px;
    background: ${SAColourScheme.PLASSMA10};
    color: ${mainAppColours.MID_BLUE};

    .icon {
      margin-left: 12px;
    }
  }

  .archive-table {
    margin-top: 10px;
    border: 1px solid #d9d9d9;
    overflow: hidden;
  }

  .archive-table.show {
    visibility: visible;
    height: 100%;
  }

  .archive-table.hidden {
    visibility: hidden;
    height: 0;
  }

  .menu-options {
    cursor: pointer;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: ${SAColourScheme.PLASSMA10};
      border-bottom: 1px solid #adb3bc;
    }
  }

  .options-dropdown {
    border: 1px solid ${mainAppColours.MID_BLUE};
  }

  .menu-options:hover {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${SAColourScheme.PLASSMA};
  }

  .client-data-table {
    border: 1px solid #d9d9d9;
  }
`;
const CustomDivider = styled(Divider) `
  border: 1px solid ${SAColourScheme.PLASSMA};
  margin-top: 5px;
`;
