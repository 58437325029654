import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import Text from "antd/lib/typography/Text";
import { useEffect, useState } from "react";
import { Button, Col, Row, Select } from "src/components/base";
import { HeaderTableWrapper } from "./index.style";
const HeaderTable = ({ children, title, onChangeLocation, collectionPoints, isVisible, setIsVisible, icon, }) => {
    const [locationList, setLocationList] = useState([]);
    useEffect(() => {
        if (collectionPoints && collectionPoints.length) {
            setLocationList(collectionPoints.map((item) => ({
                label: item.name,
                value: item.name,
            })));
        }
    }, [collectionPoints]);
    return (_jsxs(HeaderTableWrapper, { children: [_jsx("div", { style: { background: "#2e94f2", cursor: "pointer" }, children: _jsxs(Row, { align: "middle", style: { padding: "0 13px", height: 48 }, children: [_jsxs(Col, { span: 16, onClick: () => setIsVisible(!isVisible), children: [_jsx("img", { src: `/assets/svg/${icon}`, alt: "", style: { marginRight: 10 } }), _jsx(Text, { style: { fontSize: 16, fontWeight: 500, color: "#FFFFFF" }, children: title }), isVisible ? (_jsx(CaretUpFilled, { style: { color: "white", marginLeft: 10 } })) : (_jsx(CaretDownFilled, { style: { color: "white", marginLeft: 10 } }))] }), _jsx(Col, { span: 8, children: _jsxs(Row, { justify: "space-between", align: "middle", children: [_jsxs("div", { children: [_jsx(Text, { style: { fontSize: 13, color: "#FFFFFF", marginRight: 10 }, children: "Location" }), _jsx(Select, { defaultValue: "All", style: { width: 235 }, size: "small", options: [
                                                    {
                                                        label: "All",
                                                        value: "All",
                                                    },
                                                    ...locationList,
                                                ], onChange: onChangeLocation })] }), _jsx(Button, { className: "icon-btn", size: "small", icon: _jsx("img", { src: "/assets/svg/ic-pin.svg" }) })] }) })] }) }), isVisible && (_jsx("div", { style: { background: "#fff", display: "flex", flexDirection: "row" }, children: children }))] }));
};
export default HeaderTable;
