import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
export const Help = ({ onClose }) => {
    const overlayStyle = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.6)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
    };
    const contentStyle = {
        background: "white",
        padding: "20px",
        maxWidth: "500px",
        textAlign: "left",
        position: "relative", // Add position relative for absolute positioning of the close icon
    };
    const closeIconStyle = {
        position: "absolute",
        top: "10px",
        right: "10px",
        cursor: "pointer",
    };
    return (_jsx("div", { style: overlayStyle, children: _jsxs("div", { style: contentStyle, children: [_jsx("div", { style: closeIconStyle, onClick: onClose, children: _jsx(CloseCircleOutlined, { style: { fontSize: 20, color: "#1890ff" } }) }), _jsx("p", { style: { fontSize: "16px", fontWeight: "700", color: "#1A8CFF" }, children: "How does this work?" }), _jsxs("ol", { children: [_jsx("li", { children: "Update form details." }), _jsx("li", { children: "Click \u2018Scan\u2019 for secure connection to the mobile device." }), _jsx("li", { children: "Scan the barcode with your device." }), _jsx("li", { children: "Click \u2018Save & Next\u2019 to retain details for the next barcode." }), _jsx("li", { children: "Scan the next barcode." }), _jsx("li", { children: "Click \u2018Scan\u2019 to re-scan if needed." }), _jsx("li", { children: "Click \u2018Save & Finish\u2019 when all barcodes have been scanned." })] })] }) }));
};
