import styled from "styled-components";
export const HeaderWrapper = styled.div `
  border-radius: 5px;

  .icon-btn {
    border: none;
    background: none;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
`;
