import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import styled from "styled-components";
import { Row, Col, Text, Button, Image, SearchInput, DatePicker } from "src/components/base";
import ShowModal from "src/pages/PatientRecordsPage/components/ShowModal";
export const HeaderFilter = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = (displayColumns) => {
        props.selectColumn(displayColumns);
        setIsModalVisible(false);
    };
    const onSearchString = (searchString) => {
        props.onSearchString(searchString);
    };
    return (_jsxs(PageWrapper, { children: [_jsxs(Row, { style: { padding: "20px" }, justify: "end", children: [_jsxs(Col, { children: [_jsx(Text, { name: "Start Date", style: {
                                    color: "white",
                                    fontSize: "15px",
                                    marginRight: "10px",
                                } }), _jsx(DatePicker, { className: "start-date", format: "DD/MM/YYYY", onChange: props.selectStartDate })] }), _jsxs(Col, { style: { marginLeft: "20px" }, children: [_jsx(Text, { name: "End Date", style: {
                                    color: "white",
                                    fontSize: "15px",
                                    marginRight: "10px",
                                } }), _jsx(DatePicker, { className: "end-date", format: "DD/MM/YYYY", onChange: props.selectEndDate, disabledDate: (current) => current && current < props.startDate })] })] }), _jsxs(Row, { gutter: [20, 10], style: { padding: "0px 20px 10px" }, justify: "end", children: [_jsx(Col, { xs: 24, lg: 12, children: _jsx(Text, { name: props.name, style: {
                                fontSize: "22px",
                                fontWeight: "bold",
                                color: "white",
                            } }) }), _jsx(Col, { xs: 24, lg: 12, children: _jsx(Row, { gutter: [10, 10], justify: "end", children: _jsxs(Col, { xs: 12, lg: 12, style: { display: "flex", justifyContent: "flex-end", alignItems: "center" }, children: [_jsx(Button, { className: "print-button", icon: _jsx(Image, { src: "/assets/svg/ic-print-donor-records.svg", preview: false, height: 24, width: 24 }), onClick: props.onPrintRecord, loading: props.loadingPrint }), _jsx(Button, { onClick: showModal, className: "select-button", icon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false, height: 7, width: 14 }), children: "Show" }), _jsx(SearchInput, { onChange: (e) => onSearchString(e.target.value) })] }) }) })] }), _jsx(ShowModal, { isModalVisible: isModalVisible, handleCancel: handleCancel })] }));
};
const PageWrapper = styled.div `
  background: #32a1a1;
  border-radius: 8px 8px 0px 0px;

  .start-date span.ant-picker-suffix,
  .end-date span.ant-picker-suffix {
    display: none;
  }
  .ant-picker.start-date,
  .ant-picker.end-date {
    padding-left: 7px;
    border-radius: 8px;
  }
  button.ant-btn.ant-btn-default.print-button {
    background: none;
    border: none;
    margin-right: 10px;
  }
  button.ant-btn.ant-btn-default.print-button span {
    color: #367893;
    font-size: 16px;
    padding-left: 12px;
  }
  button.ant-btn.ant-btn-default.select-button {
    height: 35px;
    border-radius: 8px;
    margin-right: 10px;
  }
  .select-button .ant-image {
    position: absolute;
    margin-left: 70px;
    margin-top: 2px;
  }
  button.ant-btn.ant-btn-default.select-button span {
    color: #367893;
    font-size: 16px;
    padding-right: 35px;
  }
  .ant-picker-input > input {
    color: black;
  }
`;
