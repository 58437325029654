import moment from "moment";
const selectCurrentDate = (state) => moment(state.calendarPage.currentDate).toISOString();
const selectCollectionPoint = (state) => {
    const { selectedCollectionPoint } = state.calendarPage;
    return {
        name: selectedCollectionPoint?.collectionPointName,
        value: selectedCollectionPoint?.id,
    };
};
const selectListCollectionPoints = (state) => {
    const { collectionPoints } = state.authentication.app;
    const respondedCollectionPoints = collectionPoints.map((collectionPoint) => ({
        name: collectionPoint.id,
        value: collectionPoint.collectionPointName,
    }));
    return respondedCollectionPoints;
};
const selectListOnsiteJobs = (state) => state.calendarPage.listOnsiteJobs;
const selectIsExpandOnsiteView = (state) => state.calendarPage.isExpandOnsiteView;
const selectCurrentView = (state) => state.calendarPage.currentView;
export { selectCollectionPoint, selectCurrentDate, selectListCollectionPoints, selectListOnsiteJobs, selectIsExpandOnsiteView, selectCurrentView, };
