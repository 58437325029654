import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { useEffect, useState } from "react";
import { Checkbox, Col, Select } from "src/components/base";
import MonthSelector from "src/pages/DashboardPage/components/MiddleData/MonthSelector";
import YearSelector from "src/pages/DashboardPage/components/MiddleData/YearSelector";
import { MiddleDataWrapper } from "./index.style";
const MiddleData = ({ onSelectYear, startIndex, children, onMonthView }) => {
    const [selectYear, setSelectYear] = useState(`${moment().year()}`);
    const [yearList, setYearList] = useState([]);
    const [checkedMonth, setCheckedMonth] = useState(true);
    const [checkedYear, setCheckedYear] = useState(false);
    useEffect(() => {
        if (yearList.length == 0) {
            const currentYear = moment().year();
            const curYearList = [];
            for (let index = 0; index < 10; index++) {
                curYearList.push(`${currentYear - index}`);
            }
            setYearList(curYearList);
        }
    }, []);
    const handleSelectYear = (year) => {
        setSelectYear(year);
        onSelectYear(year);
    };
    return (_jsxs(MiddleDataWrapper, { children: [_jsxs("div", { className: "middle-data-header", children: [_jsxs("div", { className: "tool-bar", children: [_jsx(Col, { span: 6, style: { fontSize: 12, color: "#00004C" }, children: "Options" }), _jsxs(Col, { span: 6, style: { fontSize: 13, color: "#00004C" }, children: [_jsx(Checkbox, { onClick: ({ target: { checked } }) => {
                                            setCheckedYear(checked);
                                            setCheckedMonth(!checked);
                                            onMonthView(!checked);
                                        }, checked: checkedYear }), "Year View"] }), _jsxs(Col, { span: 6, style: { textAlign: "end", fontSize: 13, color: "#00004C" }, children: [_jsx(Checkbox, { onClick: ({ target: { checked } }) => {
                                            setCheckedYear(!checked);
                                            setCheckedMonth(checked);
                                            onMonthView(checked);
                                        }, checked: checkedMonth }), "Month View"] }), _jsx(Col, { span: 6, style: { textAlign: "end" }, children: _jsx(Select, { style: { width: 94, fontSize: 13, color: "#000000" }, disabled: !checkedMonth, size: "small", defaultValue: selectYear, options: yearList?.map((year) => ({
                                        label: year,
                                        value: year,
                                    })), onChange: handleSelectYear }) })] }), _jsx("div", { className: "cal-bar", children: checkedMonth ? (_jsx(MonthSelector, { startIndex: startIndex })) : (_jsx(YearSelector, { startIndex: startIndex })) })] }), _jsx("div", { style: { padding: "15px 21px 21px 11px" }, children: children })] }));
};
export default MiddleData;
