import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { message } from "antd";
import { getCollectionManagerSetting, updateGeoSetting } from "src/api/settings/settingService";
import MapView from "src/pages/PlatformSettingsPage/component/MapView";
import ContentContext from "src/providers/content";
var viewType;
(function (viewType) {
    viewType[viewType["LIST"] = 0] = "LIST";
    viewType[viewType["MAP"] = 1] = "MAP";
})(viewType || (viewType = {}));
const GeofenceSetting = () => {
    const navigate = useNavigate();
    const [isEdited, setIsEdited] = useState(false);
    const [view, setView] = useState(viewType.LIST);
    const { openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const [collectionPointsSetting, setCollectionPointsSetting] = useState([]);
    const [selectedCollectionPoint, setSelectedCollectionPoint] = useState();
    useEffect(() => {
        getSettingData();
    }, []);
    const getSettingData = async () => {
        openLoadingScreen();
        const result = await getCollectionManagerSetting();
        let indexCnt = 0;
        const resultWithIndex = result.collectionPointSetting.map((obj) => {
            indexCnt++;
            return { ...obj, index: indexCnt };
        });
        setCollectionPointsSetting(resultWithIndex);
        setSelectedCollectionPoint({
            collectionPointId: resultWithIndex[0].collectionPointId,
            collectionPointName: resultWithIndex[0].collectionPointName,
        });
        closeLoadingScreen();
    };
    const handleUpdateRadius = (collectionPointId, newValue) => {
        setCollectionPointsSetting((prevState) => prevState.map((point) => point.collectionPointId === collectionPointId
            ? {
                ...point,
                geofencingMeter: newValue,
            }
            : point));
    };
    const changeSelectedPoint = (event, newValue) => {
        setSelectedCollectionPoint({
            collectionPointId: collectionPointsSetting.find((item) => item.collectionPointName === newValue)?.collectionPointId,
            collectionPointName: collectionPointsSetting.find((item) => item.collectionPointName === newValue)?.collectionPointName,
        });
    };
    const handleSaveChanges = async () => {
        openLoadingScreen();
        const body = {
            collectionPointSetting: collectionPointsSetting.map(({ id, collectionPointId, geofencingMeter, latitude, longitude }) => ({
                id,
                collectionPointId,
                geofencingMeter,
                latitude,
                longitude,
            })),
        };
        await updateGeoSetting(body);
        setIsEdited(false);
        message.success("Saved successfully");
        closeLoadingScreen();
    };
    return (_jsxs(ContentWrapper, { children: [_jsx("div", { style: { display: "flex", justifyContent: "space-between" }, children: _jsxs("div", { style: {
                        display: "flex",
                        gap: "10px",
                        margin: "10px",
                        alignItems: "center",
                    }, children: [_jsx(Button, { onClick: () => navigate(-1), startIcon: _jsx("img", { src: "/assets/svg/ic-arrow-left.svg" }) }), _jsx(Typography, { variant: "h6", children: "Geofence" })] }) }), _jsx(Divider, { style: { width: "100%" } }), _jsxs("p", { style: { margin: "30px" }, children: [_jsx("b", { children: "Geofencing" }), " is a location-based technology that creates virtual boundaries, triggering actions when a device enters or exits a specified geographic area. To access a particular location (Collection Point), a", _jsx("b", { children: "collector" }), " will be required to ", _jsx("b", { children: "Clock in" }), " and", _jsx("b", { children: "Clock out" }), " in accordance with the boundaries set below. Once clocked in the collector will be able to access the Patient List and be able to perform collections."] }), _jsxs("div", { style: { display: "flex", gap: "5%" }, children: [_jsx(Button, { variant: "contained", style: {
                            textTransform: "none",
                            color: view === viewType.LIST ? "white" : "#4B5767",
                            backgroundColor: view === viewType.LIST ? "#1A8CFF" : "#EBEDF0",
                            borderRadius: "5px",
                            width: "200px",
                        }, onClick: () => setView(viewType.LIST), children: "List View" }), _jsx(Button, { variant: "contained", style: {
                            textTransform: "none",
                            color: view === viewType.MAP ? "white" : "#4B5767",
                            backgroundColor: view === viewType.MAP ? "#1A8CFF" : "#EBEDF0",
                            borderRadius: "5px",
                            width: "200px",
                        }, onClick: () => setView(viewType.MAP), children: "Map View" })] }), view === viewType.LIST ? (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "contained", style: {
                            textTransform: "none",
                            alignSelf: "flex-end",
                            color: "white",
                            backgroundColor: isEdited ? "#1A8CFF" : "",
                            borderRadius: "8px",
                        }, disabled: !isEdited, onClick: handleSaveChanges, children: "Save Changes" }), _jsx(TableContainer, { style: {
                            height: "500px",
                            width: "100%",
                            backgroundColor: "white",
                        }, children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { style: {
                                            backgroundColor: "#EBEDF0",
                                            borderTop: "1px solid #ADB3BC",
                                            borderBottom: "2px solid #ADB3BC",
                                        }, children: [_jsx(TableCell, {}), _jsx(TableCell, { children: _jsxs("div", { style: { display: "flex", gap: "20px" }, children: [_jsx("img", { src: "/assets/svg/ic-location-dark.svg" }), _jsx("b", { children: "Collection Point(s)" })] }) }), _jsx(TableCell, { children: _jsx("b", { children: "Address" }) }), _jsx(TableCell, { children: _jsx("b", { children: "Geo Coordinates" }) }), _jsx(TableCell, { children: _jsx("b", { children: "Radius (approx.)" }) })] }) }), _jsx(TableBody, { children: collectionPointsSetting.map((cp) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: cp.index }), _jsx(TableCell, { children: _jsxs("div", { style: { display: "flex", gap: "20px" }, children: [_jsx("img", { src: "/assets/svg/ic-location-dark.svg" }), cp.collectionPointName] }) }), _jsx(TableCell, { children: cp.address }), _jsxs(TableCell, { children: [cp.latitude, ", ", cp.longitude] }), _jsx(TableCell, { children: _jsxs("div", { style: {
                                                        display: "flex",
                                                        gap: "10px",
                                                        alignItems: "center",
                                                    }, children: [_jsx(TextField, { size: "small", type: "number", InputLabelProps: {
                                                                shrink: true,
                                                            }, value: cp.geofencingMeter, style: { width: "100px" }, onChange: (event) => {
                                                                setIsEdited(true);
                                                                handleUpdateRadius(cp.collectionPointId, event.target.value);
                                                            } }), "meters"] }) })] }, cp.collectionPointId))) })] }) })] })) : (_jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "20px" }, children: [_jsxs("div", { style: {
                            display: "flex",
                            gap: "10px",
                            alignSelf: "flex-end",
                            alignItems: "center",
                        }, children: [_jsx(Typography, { children: "Collection Point" }), _jsx(Autocomplete, { size: "small", style: {
                                    width: "250px",
                                    backgroundColor: "white",
                                }, value: selectedCollectionPoint?.collectionPointName, onChange: changeSelectedPoint, options: collectionPointsSetting.map((cp) => cp.collectionPointName), renderInput: (params) => (_jsx(TextField, { ...params, InputProps: {
                                        ...params.InputProps,
                                        startAdornment: (_jsx("img", { style: { margin: "0 10px" }, src: "/assets/svg/ic-location-dark.svg", alt: "location-pin" })),
                                    } })) })] }), _jsx(MapView, { collectionPointsSetting: collectionPointsSetting, selectedCollectionPoint: selectedCollectionPoint, onUpdateRadius: handleUpdateRadius, onSaveChanges: handleSaveChanges })] }))] }));
};
export default GeofenceSetting;
const ContentWrapper = styled.div `
  background-color: #fafafa;
  padding: 2rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
