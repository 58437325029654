import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Typography from "@mui/material/Typography";
import { BarChart } from "@mui/x-charts/BarChart";
import { Button, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { getProcessTestsCount } from "src/api/dashboard/dashboardService";
import { dashboard_getColorByName } from "src/utils/helper";
import "./TestProcessedChart.module.css";
const TestProcessedChart = ({ collectionPoints, selectedColorScheme }) => {
    const [location, setLocation] = useState("All");
    const [view, setView] = useState("daily");
    const [isLoading, setIsLoading] = useState(true);
    const [counter, setCounter] = useState(0);
    const [data, setData] = useState([]);
    const [message, setMessage] = useState("Loading Data...");
    const [startDate, setStartDate] = useState(moment(new Date().setDate(new Date().getDate() - 4)).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(undefined);
    const [dataset, setDataset] = useState([]);
    const generateColors = (colorScheme, numberOfSeries) => Array.from({ length: numberOfSeries }, (_, index) => dashboard_getColorByName(colorScheme, index));
    const numberOfSeries = dataset.length > 0 ? Object.keys(dataset[0]).filter((key) => key !== "date").length : 0;
    const colors = generateColors(selectedColorScheme, numberOfSeries);
    const handleNavigation = (type) => {
        setCounter((prevCounter) => {
            let newCounter;
            if (type === "forward") {
                newCounter = prevCounter - 1;
            }
            else {
                newCounter = prevCounter + 1;
            }
            return Math.min(Math.max(newCounter, -4), 5);
        });
    };
    const calculateDateRange = (viewType, curCounter) => {
        const today = new Date();
        const newEndDate = moment(today).format("YYYY-MM-DD");
        let newStartDate = "";
        if (viewType === "daily") {
            const startDateObj = new Date();
            startDateObj.setDate(today.getDate() - (4 + curCounter));
            newStartDate = moment(startDateObj).format("YYYY-MM-DD");
        }
        else if (viewType === "weekly") {
            const startDateObj = new Date();
            startDateObj.setDate(today.getDate() - 7 * (4 + curCounter));
            newStartDate = moment(startDateObj).format("YYYY-MM-DD");
        }
        else if (viewType === "monthly") {
            const startDateObj = new Date();
            startDateObj.setMonth(today.getMonth() - (4 + curCounter));
            startDateObj.setDate(1);
            newStartDate = moment(startDateObj).format("YYYY-MM-DD");
        }
        else if (viewType === "yearly") {
            const startDateObj = new Date();
            startDateObj.setFullYear(today.getFullYear() - (4 + curCounter));
            startDateObj.setMonth(0);
            startDateObj.setDate(1);
            newStartDate = moment(startDateObj).format("YYYY-MM-DD");
        }
        return { newStartDate, newEndDate };
    };
    const onChangeView = (event) => {
        const viewType = typeof event === "string" ? event : event.target.value;
        setView(viewType);
        setCounter(0);
    };
    const getData = async (viewType, newStartDate, newEndDate) => {
        setMessage("Loading Data...");
        setIsLoading(true);
        const result = await getProcessTestsCount(viewType, newStartDate, newEndDate);
        if (result.status === "success") {
            setData(result.data);
        }
        else {
            setMessage("Failed to load data");
            setData([]);
        }
        setIsLoading(false);
    };
    const chartSetting = {
        height: 350,
        sx: {
            [`.MuiChartsLegend-series text`]: {
                fontSize: "12px !important",
                fontWeight: "500 !important",
            },
            [`.MuiChartsLegend-mark`]: {
                width: "18px !important",
                height: "18px !important",
            },
        },
    };
    useEffect(() => {
        const transformData = (curData, curLocation) => {
            const transformedData = [];
            for (const date in data) {
                // eslint-disable-next-line no-prototype-builtins
                if (curData.hasOwnProperty(date)) {
                    const locationData = curData[date][curLocation] || {};
                    const testNames = Object.keys(locationData).length > 0
                        ? Object.keys(locationData)
                        : Object.keys(curData[date]["All"]);
                    const transformedEntry = { date };
                    testNames.forEach((test) => {
                        transformedEntry[test] = locationData[test] || 0;
                    });
                    transformedData.push(transformedEntry);
                }
            }
            return transformedData;
        };
        const aggregateByMonth = (curData) => {
            const monthNames = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
            const aggregatedData = {};
            curData.forEach((entry) => {
                const month = monthNames[new Date(entry.date.split(" - ")[0]).getMonth()];
                const year = new Date(entry.date.split(" - ")[0]).getFullYear() % 100;
                if (!aggregatedData[month]) {
                    aggregatedData[month] = { date: month + " " + year };
                }
                Object.keys(entry).forEach((key) => {
                    if (key !== "date") {
                        if (!aggregatedData[month][key]) {
                            aggregatedData[month][key] = 0;
                        }
                        aggregatedData[month][key] += entry[key];
                    }
                });
            });
            return Object.values(aggregatedData);
        };
        const newDataset = transformData(data, location);
        if (view === "monthly") {
            setDataset(aggregateByMonth(newDataset));
        }
        else {
            setDataset(newDataset);
        }
    }, [data, location, view]);
    useEffect(() => {
        const { newStartDate, newEndDate } = calculateDateRange(view, counter);
        setStartDate(startDate);
        setEndDate(endDate);
        getData(view, newStartDate, newEndDate);
    }, [location, view, counter]);
    return (_jsx("div", { children: _jsxs("div", { className: "layout", children: [_jsxs("div", { style: {
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        padding: "0px 50px",
                        position: "relative",
                    }, children: [_jsx(Typography, { className: "header", children: "Tests Processed" }), _jsxs("div", { style: { display: "flex", flexWrap: "wrap" }, children: [_jsxs("div", { style: {
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "10px",
                                        alignItems: "center",
                                        marginRight: "20px",
                                    }, children: [_jsx("label", { children: "Location" }), _jsxs("select", { defaultValue: location, onChange: (event) => setLocation(event.target.value), style: {
                                                minWidth: "200px",
                                                width: "100%",
                                                height: "25px",
                                                justifyContent: "center",
                                                borderRadius: "5px",
                                                border: "1px solid #ADB3BC",
                                                textAlign: "center",
                                            }, children: [_jsx("option", { value: "All", children: "All" }), Object.values(collectionPoints)
                                                    .filter((cp) => cp.name !== "All")
                                                    .map((cp) => (_jsx("option", { value: cp.name, children: cp.name }, `option-${cp.name}`)))] })] }), _jsxs("div", { style: {
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "10px",
                                        alignItems: "center",
                                    }, children: [_jsx("label", { children: "Show" }), _jsxs("select", { defaultValue: view, onChange: onChangeView, style: {
                                                width: "100%",
                                                minWidth: "150px",
                                                height: "25px",
                                                marginRight: "50px",
                                                justifyContent: "center",
                                                borderRadius: "5px",
                                                border: "1px solid #ADB3BC",
                                                textAlign: "center",
                                            }, children: [_jsx("option", { value: "daily", children: "Daily" }), _jsx("option", { value: "weekly", children: "Weekly" }), _jsx("option", { value: "monthly", children: "Monthly" }), _jsx("option", { value: "yearly", children: "Yearly" })] })] }), _jsxs("div", { style: { display: "flex", alignItems: "center" }, children: [_jsx(Button, { style: { padding: "0px 8px", height: "25px", width: "25px", fontWeight: 700 }, onClick: () => handleNavigation("backward"), disabled: counter === 5, children: "-" }), _jsx(Button, { style: { padding: "0px 8px", height: "25px", width: "25px", fontWeight: 700 }, onClick: () => handleNavigation("forward"), disabled: counter === -4, children: "+" })] })] })] }), isLoading ? (_jsx("div", { style: { height: 350, display: "flex", justifyContent: "center", alignItems: "center" }, children: _jsxs("div", { style: { position: "relative" }, children: [message !== "Loading Data..." ? (_jsxs(_Fragment, { children: [message, _jsx(Button, { onClick: () => getData(view, startDate, endDate || ""), children: "Refresh" })] })) : (_jsx(_Fragment, { children: message })), isLoading && (_jsx(Spin, { style: {
                                    position: "absolute",
                                    top: "0px",
                                    left: "-30px",
                                } }))] }) })) : (dataset.length > 0 && (_jsx(BarChart, { margin: {
                        top: 80,
                    }, dataset: dataset.length > 0 ? dataset : [], xAxis: [{ scaleType: "band", dataKey: "date" }], colors: colors, series: Object.keys(dataset[0])
                        .filter((key) => key !== "date")
                        .map((key) => ({ dataKey: key, label: key, name: key })), ...chartSetting })))] }) }));
};
export default TestProcessedChart;
