import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Image, Table, Text } from "src/components/base";
import { APPOINTMENT_STATUS_DB } from "src/constants";
import { AppointmentCreation } from "src/pages/InClinicAppointmentsPage/CreateAppointmentPopup";
import ContentContext from "src/providers/content";
import { useSettings } from "src/providers/SettingsContextProvider";
import { getRequestTestColor } from "src/utils/helper";
import { PatientListWrapper } from "./index.style";
const { Option } = Select;
const Index = (props) => {
    const { patientListData, collectionPoint } = props;
    const { displayColumns, searchString } = props;
    const { openPopupBox } = useContext(ContentContext);
    const columns = [
        {
            title: "No.",
            dataIndex: "number",
            key: "number",
            width: 58,
            className: "number-column",
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            className: displayColumns.includes("date") ? "date-column" : "date-column-none",
            sorter: true,
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
            className: displayColumns.includes("time") ? "time-column" : "time-column-none",
            sorter: true,
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
            className: displayColumns.includes("lastName") ? "last-name-column" : "last-name-column-none",
            sorter: true,
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
            className: displayColumns.includes("firstName")
                ? "first-name-column"
                : "first-name-column-none",
            sorter: true,
        },
        {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
            className: displayColumns.includes("dob") ? "dob-column" : "dob-column-none",
            sorter: true,
        },
        {
            title: "Age",
            dataIndex: "age",
            key: "age",
            className: displayColumns.includes("age") ? "age-column" : "age-column-none",
            sorter: true,
        },
        {
            title: "Sex",
            dataIndex: "sex",
            key: "sex",
            className: displayColumns.includes("sex") ? "sex-column" : "sex-column-none",
            sorter: true,
        },
        {
            title: "Collection Point",
            dataIndex: "collectionPoint",
            key: "collectionPoint",
            className: "collection-point-column",
            sorter: true,
        },
        {
            title: "Requested Test",
            dataIndex: "requestTest",
            key: "requestTest",
            width: 250,
            render: (data) => (_jsx("div", { style: {
                    background: data.background,
                    border: `1px solid ${data.border ?? "white"}`,
                    borderRadius: "4px",
                    height: "29px",
                    width: "100%",
                    minWidth: "150px",
                    textAlign: "center",
                    overflow: "hidden",
                }, children: _jsx(Text, { name: data.text, style: {
                        color: data.text == "Cancelled" ? "white" : "black",
                    } }) })),
            className: displayColumns.includes("requestTest") ? "request-column" : "request-column-none",
            sorter: true,
        },
        {
            title: "View Booking",
            dataIndex: "view",
            key: "view",
            render: (data, record) => (_jsx(Button, { style: {
                    padding: "0px",
                    border: "none",
                    background: "none",
                }, onClick: () => {
                    openPopupBox(_jsx(AppointmentCreation, { isPopup: true, event: record, submitCallback: () => {
                            props.reload(true);
                        } }));
                }, disabled: true, children: _jsx(Image, { src: "/assets/images/detail-patient-blue-icon.png", preview: false, width: 30, height: 30 }) })),
            className: "view-column",
        },
    ];
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const { settings } = useSettings();
    useEffect(() => {
        if (patientListData && patientListData.length) {
            const tempList = patientListData.map((element, index) => {
                let requestTestColor = getRequestTestColor((element.medicalTests.length === 2
                    ? "Drug & Alcohol Test"
                    : element.medicalTests[0].testTypeName || "#FFFFF").trim());
                let testText = element.medicalTests.length === 2
                    ? "Drug & Alcohol Test"
                    : element.medicalTests[0]?.testTypeName;
                const status = element.status;
                let testType = element.testType;
                if (status == APPOINTMENT_STATUS_DB.CANCELED) {
                    testType = "Cancelled";
                    requestTestColor = getRequestTestColor(testType);
                    testText = testType;
                }
                if (status === APPOINTMENT_STATUS_DB.COMPLETED) {
                    requestTestColor = getRequestTestColor("Complete");
                }
                function getTimezone(collectionPointId) {
                    const point = settings.collectionPointSetting.find((cp) => cp.collectionPointId === collectionPointId);
                    return point?.timezone;
                }
                // Retrieve timezone for the collection point
                const timezone = getTimezone(element.collectionPointId);
                // Format date and time based on timezone
                const date = moment
                    .tz(element.startAt, timezone ? timezone : "")
                    .format(settings.organizationSetting.dateFormat);
                const time = moment
                    .tz(element.startAt, timezone ? timezone : "")
                    .format(settings.organizationSetting.timeFormat);
                const obj = {
                    key: index,
                    number: (index + 1),
                    date: date,
                    time: time,
                    lastName: element.patient.lastName,
                    firstName: element.patient.firstName,
                    dob: element.patient.dob,
                    age: "",
                    sex: element.patient.sex,
                    collectionPoint: element.collectionPoint.collectionPointName,
                    collectionPointId: element.collectionPointId,
                    requestTest: {
                        text: testText,
                        background: requestTestColor.background,
                        border: requestTestColor.border,
                    },
                    requestTestText: testType,
                    resource: element,
                    patientId: element.patientId,
                    combinedTestId: element?.combinedTestId,
                    testTypeUUID: element.testTypeUUID,
                    patientWebId: element?.patientWebId,
                };
                return obj;
            });
            getExtrasData(tempList);
        }
        else {
            setOriginalData([]);
            setData([]);
        }
    }, [patientListData]);
    const getExtrasData = async (tempList) => {
        // const arrayOfPatientIds = sliceArrayToMultiple(patientIds, 10);
        const list = [];
        if (tempList) {
            const customList = tempList.map((element) => {
                let formattedDobDate;
                let age;
                if (element.dob) {
                    formattedDobDate = moment(element.dob, "DD/MM/YYYY");
                    const currentDate = moment();
                    age = currentDate.diff(formattedDobDate, "years");
                }
                let sex = "X";
                if (element.sex == "male") {
                    sex = "M";
                }
                if (element.sex == "female") {
                    sex = "F";
                }
                return {
                    ...element,
                    dob: formattedDobDate?.format("DD/MM/YYYY"),
                    age: age,
                    sex: sex,
                };
            });
            list.push(...customList);
        }
        setOriginalData(list);
        setData(list);
    };
    const itemRender = (current, type, originalElement) => {
        if (type === "prev") {
            return (_jsx("div", { children: _jsx(Image, { src: "/assets/images/double-arrow-back-box-gray-icon.png", preview: false, height: 10, width: 10, className: "prev-icon" }) }));
        }
        if (type === "next") {
            return (_jsx("div", { children: _jsx(Image, { src: "/assets/images/double-arrow-forth-box-gray-icon.png", preview: false, height: 10, width: 10, className: "next-icon" }) }));
        }
        return originalElement;
    };
    const [pagination, setPagination] = useState({
        defaultPageSize: 10,
        pageSize: 10,
        total: data.length,
        itemRender: itemRender,
        pageSizeOptions: [10, 15, 20],
    });
    const [pageSize, setPageSize] = useState(10);
    const select = (size) => {
        setPagination({ ...pagination, pageSize: size });
        setPageSize(size);
    };
    const [orderField, setOrderField] = useState("");
    const [isDescending, setIsDescending] = useState(false);
    const handleTableChange = (sorter) => {
        let fieldName = sorter.field;
        if (sorter.field == "requestTest") {
            fieldName = "requestTestText";
        }
        setOrderField(fieldName);
        setIsDescending(sorter.order === "descend");
    };
    useEffect(() => {
        const newSortData = data.sort((a, b) => {
            const firstField = a?.[orderField]?.toString().toLowerCase() || "";
            const secondField = b?.[orderField]?.toString().toLowerCase() || "";
            let firstFieldValue = firstField;
            let secondFieldValue = secondField;
            if (orderField === "age") {
                firstFieldValue = Number(firstField);
                secondFieldValue = Number(secondField);
            }
            else if (orderField === "dob") {
                const getYear = (dob) => {
                    const dobParts = dob.split("/");
                    return dobParts.length === 3 ? Number(dobParts[2]) : 0;
                };
                firstFieldValue = getYear(firstField);
                secondFieldValue = getYear(secondField);
            }
            if (isDescending) {
                return firstFieldValue > secondFieldValue ? -1 : 1;
            }
            else {
                return firstFieldValue < secondFieldValue ? -1 : 1;
            }
            return 0;
        });
        setData(newSortData.map((obj, index) => ({
            ...obj,
            number: (index + 1),
            key: obj.key,
        })));
    }, [orderField, isDescending]);
    useEffect(() => {
        const newFilterData = originalData.filter((obj) => obj?.firstName?.toUpperCase().includes(searchString.toUpperCase()) ||
            obj?.lastName?.toUpperCase().includes(searchString.toUpperCase()) ||
            obj?.requestTestText?.toUpperCase().includes(searchString.toUpperCase()) ||
            obj?.collectionPoint?.toUpperCase().includes(searchString.toUpperCase()));
        setData(newFilterData.map((obj, index) => ({
            ...obj,
            number: (index + 1),
            key: obj.key,
        })));
    }, [searchString]);
    const filteredData = data?.filter((item) => collectionPoint == "All" || item.collectionPointId == collectionPoint);
    return (_jsxs(PatientListWrapper, { isEmpty: data?.length === 0, children: [_jsx(Table, { columns: columns, dataSource: filteredData, size: "middle", pagination: pagination, onChange: handleTableChange }), filteredData.length > 0 && (_jsxs(PageNumberSelectWrapper, { children: [_jsx(Text, { name: "Show", style: {
                            fontSize: "16px",
                            color: "#367893",
                            marginRight: "7px",
                        } }), _jsxs(Select, { value: pageSize, onSelect: select, style: {
                            border: "1px solid #EBEDF0",
                            borderRadius: "2px",
                            width: "70px",
                            height: "28px",
                            fontSize: "16px",
                            color: "#367893",
                        }, suffixIcon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false }), children: [_jsx(Option, { value: 10, children: "10" }), _jsx(Option, { value: 15, children: "15" }), _jsx(Option, { value: 20, children: "20" })] })] }))] }));
};
export default Index;
const PageNumberSelectWrapper = styled.div `
  position: absolute;
  margin-top: -2.3rem;

  .ant-select-arrow {
    height: 15px !important;
    width: 15px !important;
  }
`;
