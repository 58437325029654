import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import styled from "styled-components";
import { Col, Image, Row, Text } from "src/components/base";
import { SAColourScheme } from "src/styles";
export const DonorDetails = ({ donorInfo, contactInfo }) => {
    const renderTextInfoBox = (data) => {
        if (data.type == "date") {
            return (_jsxs("div", { className: "info-element", children: [_jsx(Text, { className: "sub-title", name: data.title }), _jsx("div", { className: "text-box", style: { height: "40px" }, children: _jsx(Text, { className: "text-info", name: moment(data.value, "DD/MM/YYYY").format("DD MMM YYYY") }) })] }));
        }
        else {
            return (_jsxs("div", { className: `info-element ${data.className}`, style: { display: "flex", flexDirection: "column", ...data.style }, children: [_jsx(Text, { className: "sub-title", name: data.title }), _jsx("div", { className: "text-box", style: { height: "40px" }, children: _jsx(Text, { className: "text-info", name: data.value }) })] }));
        }
    };
    return (_jsxs(PageWrapper, { children: [_jsx(TitleDiv, { children: _jsx(Text, { name: "Donor Details", className: "title" }) }), _jsxs(StyledRow, { children: [_jsx(StyledCol, { xs: 24, xl: 5, className: "image-col", children: _jsx(Image, { src: "/assets/images/empty-avatar.png", preview: false, className: "donor-picture" }) }), _jsxs(StyledCol, { xs: 24, xl: 10, className: "with-border", children: [_jsx(Row, { gutter: [10, 0], children: _jsx(Col, { flex: "100%", children: renderTextInfoBox({
                                        title: "Last Name",
                                        key: "lastName",
                                        value: donorInfo?.lastName,
                                        className: "last-name",
                                    }) }) }), _jsx(Row, { gutter: [10, 0], children: _jsx(Col, { flex: "100%", children: renderTextInfoBox({
                                        title: "First Name",
                                        key: "firstName",
                                        value: donorInfo?.firstName,
                                        className: "first-name",
                                    }) }) }), _jsx(Row, { gutter: [10, 0], children: _jsx(Col, { flex: "100%", children: renderTextInfoBox({
                                        title: "Date Of Birth",
                                        key: "dob",
                                        value: moment(donorInfo?.dob, "DD/MM/YYYY").format("DD/MM/YYYY"),
                                        type: "date",
                                    }) }) }), _jsxs(Row, { gutter: [10, 0], children: [_jsx(Col, { flex: "50%", children: renderTextInfoBox({
                                            title: "Age",
                                            value: moment().diff(moment(donorInfo?.dob, "DD/MM/YYYY"), "years"),
                                            disabled: true,
                                        }) }), _jsx(Col, { flex: "50%", children: renderTextInfoBox({
                                            title: "Sex",
                                            key: "sex",
                                            value: donorInfo?.sex,
                                            type: "select",
                                        }) })] })] }), _jsxs(StyledCol, { xs: 24, xl: 9, children: [_jsxs(Row, { gutter: [10, 0], children: [_jsx(Col, { flex: "50%", children: renderTextInfoBox({
                                            key: "mobile",
                                            title: "Mobile Number",
                                            value: contactInfo?.mobile,
                                        }) }), _jsx(Col, { flex: "50%", children: renderTextInfoBox({
                                            key: "landline",
                                            title: "Landline",
                                            value: donorInfo?.landline,
                                        }) })] }), renderTextInfoBox({
                                title: "Email Address",
                                key: "email",
                                value: contactInfo?.email,
                            }), _jsxs(Row, { gutter: [10, 0], children: [_jsx(Col, { flex: "50%", children: renderTextInfoBox({
                                            title: "Address Line 1",
                                            key: "address1",
                                            value: donorInfo?.address1,
                                        }) }), _jsx(Col, { flex: "50%", children: renderTextInfoBox({
                                            title: "Address Line 2",
                                            key: "address2",
                                            value: donorInfo?.address2,
                                        }) })] }), _jsxs(Row, { gutter: [10, 0], children: [_jsx(Col, { flex: "50%", children: renderTextInfoBox({
                                            title: "City/Suburb",
                                            key: "city",
                                            value: donorInfo?.city,
                                        }) }), _jsx(Col, { flex: "25%", children: renderTextInfoBox({
                                            title: "State",
                                            key: "state",
                                            value: donorInfo?.state,
                                        }) }), _jsx(Col, { flex: "25%", children: renderTextInfoBox({
                                            title: "Postcode",
                                            key: "postCode",
                                            value: donorInfo?.postcode,
                                        }) })] })] })] })] }));
};
const PageWrapper = styled.div `
  background: #01b4d2;
  border: 1px solid #01b4d2;
  border-radius: 8px;
  color: white;
  margin: 2rem 5rem;

  .info-element {
    margin: 5px 0;
  }
  span.ant-typography.sub-title {
    color: #ffffffbf !important;
    font-size: 16px;
  }
  .text-box {
    background: #e4f6fb;
    border-radius: 4px;
    padding: 5px 11px;
    line-height: 30px;
  }
  span.ant-typography.text-info {
    color: black !important;
    font-size: 18px;
  }
`;
const TitleDiv = styled.div `
  display: flex;
  align-items: center;
  padding: 1rem 2rem 0.5rem;
  border-bottom: 1px solid ${SAColourScheme.WHITE};

  .title {
    color: ${SAColourScheme.WHITE} !important;
    font-size: 1.2rem;
    font-weight: 700;
  }
`;
const StyledRow = styled(Row) `
  padding: 1rem;
`;
const StyledCol = styled(Col) `
  padding: 1rem;

  .donor-picture {
    width: 220px;
    height: 300px;
    object-fit: contain;
  }

  &.image-col {
    display: flex;
    justify-content: center;
  }
`;
