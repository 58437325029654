import styled from "styled-components";
export const Wrapper = styled.div `
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 3px 6px #0000003d;
  width: 90%;
  max-width: 900px;
  padding: 40px 50px 26px;
  margin-left: auto;
  margin-right: auto;

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #2f3337;
    margin-bottom: 0px;
    margin-top: 25px;
  }
  .ct-input {
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: 1px solid #e1e2e8;
    background: #ffffff;
    outline: none;
    font-size: 16px;
    color: #000000;
    padding: 0 20px;
  }
  .ct-textarea {
    width: 100%;
    height: 140px;
    border-radius: 4px;
    border: 1px solid #e1e2e8;
    color: #000000;
    outline: none;
    padding: 10px 20px;
  }
  .ct-upload-file {
    display: flex;
    border-radius: 4px;
    border: 1px solid #e1e2e8;
    height: 40px;
    padding: 0 9px;
    align-items: center;
  }
  .placeholder-upload {
    margin-left: 20px;
    font-size: 15px;
    color: #7f8e9d;
  }
  .ct-file-name {
    margin-left: 20px;
    font-size: 15px;
    color: #000000;
  }
  .ct-send-btn {
    background: #1a8cff !important;
    border-radius: 4px !important;
    color: #ffffff !important;
    height: 34px;
    width: 165px;
    font-size: 16px;
    font-weight: 500;
  }
`;
