import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Space, Table } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import DocumentIcon from "src/assets/svg/device-management/document.svg";
import { DeviceManagerView } from "../../typing";
export const CalibrationHistory = () => {
    const { view } = useParams();
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Device Name",
            dataIndex: "deviceName",
            key: "deviceName",
            sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Serial Number",
            dataIndex: "serialNumber",
            key: "serialNumber",
        },
        {
            title: "Certificate No.",
            dataIndex: "certificateNumber",
            key: "certificateNumber",
        },
        {
            title: "Calibration Due",
            dataIndex: "calibrationAt",
            key: "calibrationAt",
            render: (calibrationAt) => (_jsx(Space, { children: moment(calibrationAt).format("DD MMM YYYY hh:mm A") })),
        },
        {
            title: "Certificate",
            dataIndex: "calibrationCertificateUrl",
            key: "calibrationCertificateUrl",
            render: (calibrationCertificateUrl) => (_jsx(ContentButton, { type: "link", href: calibrationCertificateUrl, target: "_blank", children: _jsx(CustomDocumentIcon, { "$isAvailableUrl": !!calibrationCertificateUrl }) })),
        },
        {
            title: "Report",
            dataIndex: "calibrationReportUrl",
            key: "calibrationReportUrl",
            render: (calibrationReportUrl) => (_jsx(ContentButton, { type: "link", href: calibrationReportUrl, target: "_blank", children: _jsx(CustomDocumentIcon, { "$isAvailableUrl": !!calibrationReportUrl }) })),
        },
    ];
    return (_jsxs(HistoryContainer, { isDisplay: view !== DeviceManagerView.Create, children: [_jsx(HistoryTitleBox, { children: "Calibration History" }), _jsx(Table, { columns: columns, dataSource: [], pagination: false, scroll: { x: 900, y: 650 }, rowKey: "id" })] }));
};
const HistoryContainer = styled.div `
  display: ${({ isDisplay }) => (isDisplay ? "flex" : "none")};
  width: 100%;
  padding: 1rem 0.5rem;
  flex-direction: column;

  .ant-table-thead > tr > th {
    background-color: #dce7ec;
    border-bottom: 0.025rem solid #adb3bc;
    border-top: 0.025rem solid #adb3bc;
  }
`;
const HistoryTitleBox = styled.div `
  background-color: #83abbb;
  color: #ffffff;
  margin-left: auto;
  padding: 0.5rem 1rem;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
`;
const ContentButton = styled(Button) `
  color: #000000;
  font-size: 1rem;
  font-weight: 400;
`;
const CustomDocumentIcon = styled(DocumentIcon) `
  color: ${({ $isAvailableUrl }) => ($isAvailableUrl ? "#1A8CFF" : "#EDEEF0")};
`;
