import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Input, InputNumber, Modal, Row, Space, Table, Typography, } from "antd";
import { useContext, useEffect, useState } from "react";
import { createInventory, updateInventory } from "src/api/inventory/inventoryService";
import { MESSAGE_BOX_TYPE } from "src/constants";
import ContentContext from "src/providers/content";
import ICCancel from "/public/assets/svg/inventory/ic-cancel.svg";
import ICEdit from "/public/assets/svg/inventory/ic-edit.svg";
import ICSave from "/public/assets/svg/inventory/ic-save.svg";
const { Text } = Typography;
const MINIMUM_STOCK_URINE = "Urine";
const MINIMUM_STOCK_ORAL = "Oral";
export const LocationInventoryTable = ({ inventoryData, index }) => {
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const [data, setData] = useState(inventoryData);
    const [transformedData, setTransformedData] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const calculateTotalValues = (unitValue, unitPrice) => ({
        unitValue: String(unitValue),
        unitPrice: String(unitPrice),
        totalValue: String(unitValue * unitPrice),
    });
    const transformData = (curData) => curData.flatMap((item) => {
        const unitsUrine = parseFloat(item.unitsUrine);
        const unitsOral = parseFloat(item.unitsOral);
        const urineUnitPrice = parseFloat(item.urineUnitPrice);
        const oralUnitPrice = parseFloat(item.oralUnitPrice);
        return [
            {
                ...item,
                key: `${item.id}-${MINIMUM_STOCK_URINE}`,
                inventory: MINIMUM_STOCK_URINE,
                ...calculateTotalValues(unitsUrine, urineUnitPrice),
            },
            {
                ...item,
                key: `${item.id}-${MINIMUM_STOCK_ORAL}`,
                inventory: MINIMUM_STOCK_ORAL,
                ...calculateTotalValues(unitsOral, oralUnitPrice),
            },
        ];
    });
    const handleInputChange = (value1, value2, dataIndex) => {
        const parsedValue1 = parseFloat(value1);
        const parsedValue2 = parseFloat(value2);
        // If the input is not a valid number, set it to 0
        if (!isNaN(parsedValue1) && !isNaN(parsedValue2)) {
            const updatedTransformedData = transformedData.map((item) => {
                if (item.inventory === dataIndex) {
                    return {
                        ...item,
                        ...calculateTotalValues(parsedValue1, parsedValue2),
                    };
                }
                return item;
            });
            setTransformedData(updatedTransformedData);
        }
        else if (isNaN(parsedValue1)) {
            // If the input is not a valid number, set it to 0
            const updatedTransformedData = transformedData.map((item) => {
                if (item.inventory === dataIndex) {
                    return {
                        ...item,
                        ...calculateTotalValues(0, parsedValue2),
                    };
                }
                return item;
            });
            setTransformedData(updatedTransformedData);
        }
        else if (isNaN(parsedValue2)) {
            // If the input is not a valid number, set it to 0
            const updatedTransformedData = transformedData.map((item) => {
                if (item.inventory === dataIndex) {
                    return {
                        ...item,
                        ...calculateTotalValues(parsedValue1, 0),
                    };
                }
                return item;
            });
            setTransformedData(updatedTransformedData);
        }
    };
    const handleUserDefinedNameChange = (e) => {
        const updatedTransformedData = transformedData.map((item) => ({
            ...item,
            userDefinedName: e.target.value,
        }));
        // Update the state with the new transformed data
        setTransformedData(updatedTransformedData);
    };
    const handleSaveClick = async () => {
        const combinedData = transformedData.reduce((acc, item) => {
            const existingItemIndex = acc.findIndex((existingItem) => existingItem.id === item.id);
            if (existingItemIndex !== -1) {
                if (item.inventory === MINIMUM_STOCK_URINE) {
                    acc[existingItemIndex].unitsUrine = String(item.unitValue);
                    acc[existingItemIndex].urineUnitPrice = String(item.unitPrice);
                }
                else if (item.inventory === MINIMUM_STOCK_ORAL) {
                    acc[existingItemIndex].unitsOral = String(item.unitValue);
                    acc[existingItemIndex].oralUnitPrice = String(item.unitPrice);
                }
            }
            else {
                const newItem = {
                    id: String(item.id),
                    collectionPointId: String(item.collectionPointId),
                    contactName: String(item.contactName),
                    contactMobile: String(item.contactMobile),
                    email: String(item.email),
                    address: String(item.address),
                    stockOralMonthly: String(item.stockOralMonthly),
                    stockUrineMonthly: String(item.stockUrineMonthly),
                    unitsOral: item.inventory === MINIMUM_STOCK_ORAL ? String(item.unitValue) : "",
                    unitsUrine: item.inventory === MINIMUM_STOCK_URINE ? String(item.unitValue) : "",
                    oralUnitPrice: item.inventory === MINIMUM_STOCK_ORAL ? String(item.unitPrice) : "",
                    urineUnitPrice: item.inventory === MINIMUM_STOCK_URINE ? String(item.unitPrice) : "",
                    userDefinedName: String(item.userDefinedName),
                };
                acc.push(newItem);
            }
            return acc;
        }, []);
        openLoadingScreen();
        try {
            // Iterate over each item in combinedData
            for (const combinedItem of combinedData) {
                if (combinedItem.id === "") {
                    // If the item doesn't have an id, it's a new inventory item
                    const response = await createInventory(combinedItem);
                    if (response.status !== "success") {
                        throw new Error("Failed to create inventory");
                    }
                }
                else {
                    // If the item has an id, it's an existing inventory item
                    const response = await updateInventory(combinedItem.id, combinedItem);
                    if (response.status !== "success") {
                        throw new Error("Failed to update inventory");
                    }
                }
            }
            openToast("Saved successfully");
            setEditMode(false);
        }
        catch (error) {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again.");
        }
        finally {
            closeLoadingScreen();
        }
    };
    const handleEditClick = () => {
        setEditMode(!editMode);
    };
    const handleCancelClick = () => {
        setEditMode(false);
        setData(inventoryData);
    };
    const columns = [
        {
            title: "Inventory",
            dataIndex: "inventory",
            key: "inventory",
            sorter: (a, b) => a.inventory.localeCompare(b.inventory),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Units",
            dataIndex: "unitValue",
            key: "unitValue",
            sorter: (a, b) => a.unitValue.localeCompare(b.unitValue),
            sortDirections: ["ascend", "descend"],
            render: (text, record) => (_jsx(_Fragment, { children: editMode ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => {
                            handleInputChange(e.target.value, record.unitPrice, record.inventory);
                        } }) })) : (_jsx(Space, { children: text })) })),
        },
        {
            title: "Unit Price",
            dataIndex: "unitPrice",
            key: "unitPrice",
            sorter: (a, b) => a.unitPrice.localeCompare(b.unitPrice),
            sortDirections: ["ascend", "descend"],
            render: (text, record) => (_jsx(_Fragment, { children: editMode ? (_jsxs("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: ["$", _jsx(InputNumber, { step: 0.1, value: text, style: { width: "100%", marginLeft: "5px" }, onChange: (newValue) => {
                                handleInputChange(record.unitValue, newValue.toString(), record.inventory);
                            } })] })) : (_jsxs(Space, { children: ["$", text] })) })),
        },
        {
            title: "Total Value",
            dataIndex: "totalValue",
            key: "totalValue",
            sorter: (a, b) => a.totalValue.localeCompare(b.totalValue),
            sortDirections: ["ascend", "descend"],
            render: (text) => _jsx(Space, { children: text }),
        },
    ];
    useEffect(() => {
        setTransformedData(transformData(data));
    }, [data]);
    return (_jsxs("div", { style: { marginTop: "15px" }, children: [_jsxs("div", { style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                }, children: [_jsx("span", { style: { fontSize: "16px", color: "#01B4D2", fontWeight: 700 }, children: "By Location (Collection Point)" }), editMode ? (_jsxs("div", { children: [_jsx(ICCancel, { onClick: handleCancelClick, style: { cursor: "pointer", marginRight: "10px" } }), _jsx(ICSave, { onClick: handleSaveClick, style: { cursor: "pointer" } })] })) : (_jsx(ICEdit, { onClick: handleEditClick, style: { cursor: "pointer" } }))] }), _jsxs(Row, { gutter: [20, 10], children: [_jsxs(Col, { xs: 24, md: 14, children: [_jsxs("div", { style: { fontSize: "16px", marginTop: "20px" }, children: [index, " Location"] }), _jsx("div", { style: { fontSize: "16px" }, children: inventoryData[0].collectionPointName }), _jsx("div", { style: { display: "flex", alignItems: "center", marginTop: "15px" }, children: _jsxs("span", { children: [_jsx(Button, { style: {
                                                fontWeight: "700",
                                                fontSize: "14px",
                                                color: "#00004C",
                                            }, onClick: showModal, children: "SHOW SITE CONTACT" }), _jsxs(Modal, { title: `SITE CONTACT - ${transformedData[0]?.collectionPointName}`, visible: isModalOpen, onCancel: handleCancel, footer: null, children: [_jsx("div", { children: _jsxs(Text, { children: [_jsx("b", { children: "User defined location name:" }), " ", transformedData[0]?.userDefinedName] }) }), _jsx("div", { children: _jsxs(Text, { children: [_jsx("b", { children: "Contact Name:" }), " ", transformedData[0]?.contactName] }) }), _jsx("div", { children: _jsxs(Text, { children: [_jsx("b", { children: "Contact Mobile:" }), " ", transformedData[0]?.contactMobile] }) }), _jsx("div", { children: _jsxs(Text, { children: [_jsx("b", { children: "Email:" }), " ", transformedData[0]?.email] }) }), _jsx("div", { children: _jsxs(Text, { children: [_jsx("b", { children: "Address:" }), " ", transformedData[0]?.address] }) })] })] }) })] }), _jsxs(Col, { xs: 24, md: 10, style: {
                            background: "#E4F6FB",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            padding: "10px",
                        }, children: [editMode ? (_jsx("div", { style: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }, children: _jsxs(_Fragment, { children: [_jsx("b", { children: "User Defined Name:" }), _jsx(Input, { value: transformedData[0].userDefinedName, onChange: handleUserDefinedNameChange })] }) })) : (_jsxs(Text, { children: [_jsx("b", { children: "User Defined Name:" }), " ", inventoryData[0].userDefinedName] })), _jsxs(Text, { children: [_jsx("b", { children: "Address:" }), " ", inventoryData[0].address] }), _jsxs("div", { style: { display: "flex", justifyContent: "space-between", paddingRight: "20px" }, children: [_jsxs(Text, { children: [_jsx("b", { children: "Suburb:" }), " ", inventoryData[0].suburb] }), _jsxs(Text, { children: [_jsx("b", { children: "State:" }), " ", inventoryData[0].state] }), _jsxs(Text, { children: [_jsx("b", { children: "Post Code:" }), " ", inventoryData[0].postcode] })] })] })] }), _jsx(Table, { columns: columns, dataSource: transformedData ? transformedData.map((item) => ({ ...item, key: item.inventory })) : [], pagination: false, scroll: { x: 900, y: 650 }, style: {
                    border: "1px solid #D9D9D9",
                    overflow: "hidden",
                    marginTop: "10px",
                } })] }));
};
