/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { stringify } from "qs";
import { $get } from "src/infra/http";
const getOnSiteSummary = createAsyncThunk("getOnsiteDailyAppointmentSummary", async (_, thunkApi) => {
    const state = thunkApi.getState().homePage;
    const date = state.currentDate;
    const collectionPointId = state.selectedCollectionPoint.id;
    const queryString = stringify({
        date: moment(date).format("YYYY-MM-DD"),
        collectionPointId,
    });
    return await $get(`/collection-manager/home/onsite?${queryString}`);
});
const getInClinicDailyAppointmentSummary = createAsyncThunk("getInClinicDailyAppointmentSummary", async (_, thunkApi) => {
    const state = thunkApi.getState().homePage;
    const date = state.currentDate;
    const collectionPointId = state.selectedCollectionPoint.id;
    const queryString = stringify({
        date: moment(date).format("YYYY-MM-DD"),
        collectionPointId,
    });
    return await $get(`/collection-manager/home/in-clinic?${queryString}`);
});
const getListCollectionPoints = createAsyncThunk("getListCollectionPoints", async () => await $get("/collection-manager/home/collection-points"));
export { getInClinicDailyAppointmentSummary, getListCollectionPoints, getOnSiteSummary };
