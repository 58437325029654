/* eslint-disable @typescript-eslint/no-explicit-any,no-console */
import axios from "axios";
import { Domain } from "src/types";
const MAIN_URL = "/api/main";
const UPLOAD_URL = "/sa/upload";
const AUTH_URL_V1 = "/api/auth/v1";
const mainApiInstance = axios.create({ baseURL: MAIN_URL });
const uploadApiInstance = axios.create({ baseURL: UPLOAD_URL });
const authApiInstance = axios.create({ baseURL: AUTH_URL_V1 });
const getAxiosInstance = (domain) => {
    let axiosInstance = mainApiInstance;
    switch (domain) {
        case Domain.Main: {
            axiosInstance = mainApiInstance;
            break;
        }
        case Domain.Upload: {
            axiosInstance = uploadApiInstance;
            break;
        }
        case Domain.Auth: {
            axiosInstance = authApiInstance;
            break;
        }
    }
    return axiosInstance;
};
export const $get = async (endpoint, domain, option = {}) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        ...option,
    };
    try {
        const axiosInstance = getAxiosInstance(domain);
        const response = await axiosInstance.get(endpoint, config);
        return response.data;
    }
    catch (err) {
        return throwHttpRequestError(err);
    }
};
export const $post = async (endpoint, data, domain, option = {}) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
        ...option,
    };
    try {
        const axiosInstance = getAxiosInstance(domain);
        const response = await axiosInstance.post(endpoint, data, config);
        return response?.data;
    }
    catch (err) {
        return throwHttpRequestError(err);
    }
};
export const $patch = async (endpoint, data, domain, option = {}) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
        ...option,
    };
    try {
        const axiosInstance = getAxiosInstance(domain);
        const response = await axiosInstance.patch(endpoint, data, config);
        return response.data;
    }
    catch (err) {
        return throwHttpRequestError(err);
    }
};
export const $put = async (endpoint, data, domain, option = {}) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
        ...option,
    };
    try {
        const axiosInstance = getAxiosInstance(domain);
        const response = await axiosInstance.put(endpoint, data, config);
        return response.data;
    }
    catch (err) {
        return throwHttpRequestError(err);
    }
};
export const $delete = async (endpoint, domain, option = {}) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
        ...option,
    };
    try {
        const axiosInstance = getAxiosInstance(domain);
        const response = await axiosInstance.delete(endpoint, config);
        return response.data;
    }
    catch (err) {
        return throwHttpRequestError(err);
    }
};
export const throwHttpRequestError = (error) => {
    console.log("Failed to send request", error);
    return {
        data: null,
        error: "Failed to send request",
    };
};
