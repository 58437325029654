const selectNotesList = (state) => state.clientManagementPage.clientNotesPage.listNotes;
const listLocations = (state) => state.clientManagementPage.clientNotesPage.listClientLocationsData;
const selectLocations = (state) => {
    const locations = state.clientManagementPage.clientNotesPage.listClientLocationsData;
    return locations.map((location) => ({
        label: location.siteName,
        value: location.id,
    }));
};
const selectCurrentLocation = (state) => {
    const currentLocation = state.clientManagementPage.clientNotesPage.selectedLocation;
    return {
        label: currentLocation.siteName,
        value: currentLocation.id,
    };
};
const selectedNote = (state) => state.clientManagementPage.clientNotesPage.selectedNote;
const selectNoteHistory = (state) => state.clientManagementPage.clientNotesPage.noteHistory;
const selectNoteFlag = (state) => state.clientManagementPage.clientNotesPage.createNoteFlag;
export { selectNotesList, selectLocations, listLocations, selectCurrentLocation, selectedNote, selectNoteHistory, selectNoteFlag, };
