import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Select, Switch, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { Col, Row } from "src/components/base";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getInClinicDailyAppointmentSummary, getOnSiteSummary } from "../../store/action";
import { getIsOnsiteViewStatus, getSelectedCollectionPoint, getSelectedDate, listCollectionPoints, } from "../../store/selector";
import { setCurrentCollectionPoint, setStateIsOnsiteView } from "../../store/slice";
import { InClinicDashboard } from "./InClinicDashboard";
import { OnsiteDashboard } from "./OnsiteDashboard";
export const Summary = () => {
    const dispatch = useAppDispatch();
    const selectedCollectionPoint = useAppSelector(getSelectedCollectionPoint);
    const collectionPoints = useAppSelector(listCollectionPoints);
    const currentDate = useAppSelector(getSelectedDate);
    const isOnSiteView = useAppSelector(getIsOnsiteViewStatus);
    const changeStateOnSiteView = () => {
        dispatch(setStateIsOnsiteView(!isOnSiteView));
    };
    return (_jsxs(SummaryBoxContainer, { children: [_jsxs(HeaderWrapper, { children: [_jsx(Col, { span: 10, children: _jsxs(Typography.Title, { level: 5, children: [isOnSiteView ? "On-Site" : "In-Clinic", " Daily Dashboard"] }) }), _jsxs(Col, { span: 6, children: [_jsx(SwitchText, { isChecked: !isOnSiteView, children: " In-Clinic " }), _jsx(SwitchButton, { checked: isOnSiteView, onChange: changeStateOnSiteView }), _jsx(SwitchText, { isChecked: isOnSiteView, children: " On-Site " })] }), _jsx(Col, { span: 8, children: _jsx(DropDownMenu, { value: selectedCollectionPoint.name, options: collectionPoints, onChange: (_, option) => {
                                const selectedValue = option;
                                dispatch(setCurrentCollectionPoint({
                                    id: selectedValue.name,
                                    collectionPointName: selectedValue.value,
                                }));
                                if (isOnSiteView) {
                                    dispatch(getOnSiteSummary());
                                }
                                else {
                                    dispatch(getInClinicDailyAppointmentSummary());
                                }
                            } }) })] }), _jsxs(Typography.Text, { children: [" ", currentDate, " "] }), _jsx(ContentWrapper, { children: isOnSiteView ? _jsx(OnsiteDashboard, {}) : _jsx(InClinicDashboard, {}) })] }));
};
const SummaryBoxContainer = styled.div `
  width: 100%;
  height: 100%;
  background-color: #0047b3;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1.125rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h5,
  p,
  span {
    color: #ffffff;
  }
`;
const HeaderWrapper = styled(Row) `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 700;
  }
`;
const SwitchButton = styled(Switch) `
  background-color: #e6f7fa;

  & > div:before {
    background-color: ${(props) => (props.checked ? "#5CD22A" : "#1a8cff")};
  }
`;
const SwitchText = styled(Typography.Text) `
  color: ${(props) => (props.isChecked ? "#01b4d2 !important" : "#ffffff")};
`;
const DropDownMenu = styled(Select) `
  width: 100%;

  div {
    border-radius: 0.813rem !important;
    background-color: #cbf5fc !important;
    border: 0 !important;
  }

  span {
    color: #2f3337 !important;
    font-weight: 400;
  }
`;
const ContentWrapper = styled.div `
  width: 100%;
  height: 100%;
`;
