import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState, useContext, useEffect } from "react";
import { getCollectionManagerSetting } from "src/api/settings/settingService";
const defaultValue = {
    settings: {
        organizationSetting: {
            countryCode: "AU",
            timeFormat: "hh:mm a",
            dateFormat: "",
            timezone: "",
        },
        collectionPointSetting: [],
    },
    updateSettings: () => { },
    reload: false,
    setReload: () => { },
};
const SettingsContext = createContext(defaultValue);
export const SettingsContextProvider = ({ children }) => {
    const [settings, setSettings] = useState(defaultValue.settings);
    const [reload, setReload] = useState(false);
    const fetchData = async () => {
        const fetchedSettings = await getCollectionManagerSetting();
        if (fetchedSettings && fetchedSettings.data) {
            const timeFormat = fetchedSettings.data.organizationSetting.timeFormat === 24 ? "HH:mm" : "hh:mm a";
            const updatedOrganizationSetting = {
                ...fetchedSettings.data.organizationSetting,
                timeFormat: timeFormat,
            };
            const updatedFetchedSettings = {
                ...fetchedSettings,
                data: {
                    ...fetchedSettings.data,
                    organizationSetting: updatedOrganizationSetting,
                },
            };
            setSettings(updatedFetchedSettings.data);
        }
    };
    useEffect(() => {
        fetchData();
    }, [reload]);
    const updateSettings = (newSettings) => {
        setSettings(newSettings);
    };
    return (_jsx(SettingsContext.Provider, { value: { settings, updateSettings, reload, setReload }, children: children }));
};
export const useSettings = () => useContext(SettingsContext);
