import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Input, Select } from "antd";
import { BodyTitle, Button, DatePicker, Divider, Image, Row, Text } from "src/components/base";
import { SAColourScheme } from "src/styles";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { convertMinutesToHoursAndMinutes } from "src/utils/helper";
import { getCollectorWorkRecord, getListCollectors } from "./store/action";
import { setCurrentCollector, setWorkRecordEndDate, setWorkRecordStartDate } from "./store/slice";
import { RecordCard } from "./RecordCard";
import { selectCollectors, selectCurrentCollector, selectListWorkData, selectWorkRecordEndDate, selectWorkRecordStartDate, } from "./store/selector";
const WorkRecord = () => {
    const navigate = useNavigate();
    const isLoading = useAppSelector(selectIsLoading);
    const dispatch = useAppDispatch();
    const collectorsData = useAppSelector(selectCollectors);
    const selectedCollector = useAppSelector(selectCurrentCollector);
    const selectedStartDate = useAppSelector(selectWorkRecordStartDate);
    const selectedEndDate = useAppSelector(selectWorkRecordEndDate);
    const workRecordsData = useAppSelector(selectListWorkData);
    const location = useLocation();
    const collectorId = location.state;
    const [isCollectorsLoaded, setIsCollectorsLoaded] = useState(false);
    const disabledStartDate = (current) => selectedEndDate ? current > selectedEndDate : false;
    const disabledEndDate = (current) => selectedStartDate ? current < selectedStartDate : false;
    const renderBackButton = () => (_jsx(Button, { className: "back-button", icon: _jsx(Image, { className: "back-icon", src: "/assets/svg/ic-back.svg", preview: false, height: 30 }), onClick: () => navigate(-1), children: "Go Back" }));
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getListCollectors());
            setIsCollectorsLoaded(true);
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [dispatch]);
    useEffect(() => {
        const loadCollectorWorkRecord = async () => {
            dispatch(setIsLoading(true));
            if (collectorId !== null && isCollectorsLoaded) {
                const selectingCollector = collectorsData.find((item) => item.value === collectorId);
                if (selectingCollector) {
                    dispatch(setCurrentCollector({ id: selectingCollector.value, name: selectingCollector.label }));
                    await dispatch(getCollectorWorkRecord());
                }
            }
            dispatch(setIsLoading(false));
        };
        loadCollectorWorkRecord();
    }, [isCollectorsLoaded]);
    return (_jsxs(PageWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsx(BodyTitle, { name: "Clock On - Clock Off Records", left: renderBackButton(), className: "body-title" }), _jsx(ContentWrapper, { children: _jsxs(Content, { children: [_jsxs(Row, { justify: "space-between", className: "header-filter", children: [_jsxs(LabelDiv, { children: [_jsx(Text, { name: "Collector", className: "bold-text" }), _jsx(Select, { className: "type-select", value: selectedCollector?.value, size: "small", options: collectorsData.map((collector, index) => ({
                                                key: `${collector.value}-${index}`,
                                                label: collector.label,
                                                value: collector.value,
                                            })), onChange: async (_, option) => {
                                                const selectedValue = option;
                                                dispatch(setIsLoading(true));
                                                dispatch(setCurrentCollector({ id: selectedValue.value, name: selectedValue.label }));
                                                await dispatch(getCollectorWorkRecord());
                                                dispatch(setIsLoading(false));
                                            } })] }), _jsxs(DatesDiv, { children: [_jsxs(LabelDiv, { children: [_jsx(Text, { name: "Start date" }), _jsx(DatePicker, { disabledDate: disabledStartDate, value: selectedStartDate, suffixIcon: _jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-calender-start-date.svg", preview: false, height: 22 }), onChange: async (_, date) => {
                                                        dispatch(setIsLoading(true));
                                                        dispatch(setWorkRecordStartDate(date ? moment(date) : null));
                                                        await dispatch(getCollectorWorkRecord());
                                                        dispatch(setIsLoading(false));
                                                    }, format: "DD MMM YYYY" })] }), _jsxs(LabelDiv, { children: [_jsx(Text, { name: "End date" }), _jsx(DatePicker, { value: selectedEndDate, suffixIcon: _jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-calender-end-date.svg", alt: "Calendar End Date", height: 22 }), disabledDate: disabledEndDate, onChange: async (_, date) => {
                                                        dispatch(setIsLoading(true));
                                                        dispatch(setWorkRecordEndDate(date ? moment(date) : null));
                                                        await dispatch(getCollectorWorkRecord());
                                                        dispatch(setIsLoading(false));
                                                    }, format: "DD MMM YYYY" })] }), _jsxs(LabelDiv, { children: [_jsx(Text, { name: "Total" }), _jsx(Input, { className: "total", disabled: true, value: convertMinutesToHoursAndMinutes(workRecordsData.totalHours) })] }), _jsx(TimerImageDiv, { children: _jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-time-icon.svg", preview: false, height: 20 }) }), _jsx(Button, { icon: _jsx(Image, { src: "/assets/svg/Clock-On-Off/ic-print.svg", preview: false, height: 22 }) })] })] }), _jsx(Divider, {}), _jsx(CardsDiv, { children: workRecordsData?.wordRecords?.length > 0 ? (workRecordsData.wordRecords.map((record, index) => (_jsx(RecordCard, { record: record }, index)))) : (_jsx(Text, { name: "No Records Found" })) }), _jsx(Divider, {})] }) })] }));
};
export default WorkRecord;
const PageWrapper = styled.div `
  .body-title {
    color: #4b5767 !important;
    font-size: 1.3rem !important;
  }

  .content-title {
    padding: 0.5rem;
  }

  .ant-btn.ant-btn-default.back-button {
    color: #4b5767;
    font-size: 1.1rem;
    font-weight: 600;
    background: none;
    border: none;
    padding-left: 5px;
  }

  .ant-image-img.back-icon {
    margin-bottom: 5px;
    margin-right: 8px;
  }
`;
const ContentWrapper = styled.div `
  margin: 2rem 4rem;
  background: #f7f8f8;
`;
const Content = styled.div `
  padding: 1rem 4rem 2rem;

  .ant-divider-horizontal {
    border-top: 3px solid #adb3bc !important;
    margin: 0 !important;
  }

  .header-filter {
    margin: 0.7rem 0;

    .ant-typography {
      font-size: 0.9rem;
      font-weight: 700;
      color: #4b5767 !important;
    }
  }
  .type-select {
    width: 15rem;
  }

  .ant-select-selector {
    border: 1px solid #adb3bc !important;
    border-radius: 4px !important;
    height: 32px !important;
  }

  .ant-select-selection-item {
    line-height: 32px !important;
  }

  .ant-picker-input {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
  }

  .total {
    background: ${SAColourScheme.PLASSMA10} !important;
  }
`;
const CardsDiv = styled.div `
  height: 68vh;
  padding-right: 2rem;
  overflow: hidden;
  overflow-y: scroll;
`;
const DatesDiv = styled.div `
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: end;
  position: relative;

  .ant-btn {
    width: 42px;
  }
`;
const LabelDiv = styled.div `
  display: flex;
  flex-direction: column;

  .ant-input[disabled] {
    max-width: 150px;
    padding-left: 2rem;
    color: ${SAColourScheme.BLACK} !important;
  }
`;
const TimerImageDiv = styled.div `
  position: absolute;
  right: 185px;
  top: 26px;
`;
