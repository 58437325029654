import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Row } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import { HeaderWrapper } from "../Satchels/index.style";
export const HeaderFile = ({ name, icon }) => (_jsx(HeaderWrapper, { children: _jsx("div", { style: {
            background: "#2e94f2",
            marginTop: "30px",
            borderRadius: "5px",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }, children: _jsxs(Row, { align: "middle", style: { padding: "0 13px", height: "auto", minHeight: 48 }, children: [_jsxs(Col, { span: 11, children: [_jsx("img", { src: `/assets/svg/${icon}`, alt: "", style: { marginRight: 8 } }), _jsx(Text, { style: { fontSize: 16, fontWeight: 500, color: "#FFFFFF" }, children: name })] }), _jsx(Col, { span: 12, children: _jsx(Text, { style: {
                            overflow: "hidden",
                            color: "#FFFFFF",
                            display: "flex",
                            justifyContent: "center",
                        }, children: moment().format("dddd DD MMMM YYYY HH:mm") }) }), _jsx(Col, { span: 1, children: _jsx(Row, { justify: "end", align: "middle", children: _jsx(Button, { className: "icon-btn", size: "small", icon: _jsx("img", { src: "/assets/svg/ic-pin.svg" }) }) }) })] }) }) }));
