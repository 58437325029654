import styled from "styled-components";
export const Wrapper = styled.div `
  padding: 20px 50px 28px;
  margin: 0px auto;
  width: 85%;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 3px 6px #0000003d;

  .question-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #e4f6fb;
    height: 45px;
  }
  .question-header.active {
    background: #01b4d2;
    color: #ffffff;
  }
  .question-icon-before {
    width: 10px;
    height: 10px;
    background: #01b4d2;
    margin-left: 15px;
  }
  .question-icon-before.active {
    width: 10px;
    height: 10px;
    background: #ffffff;
    margin-left: 15px;
  }
  .question-icon-after {
    flex: auto;
    text-align: right;
    margin-right: 20px;
  }
  .question-text {
    font-size: 18px;
    font-weight: 500;
    color: #2f3337;
  }
  .question-text.active {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }
  .question-frame {
    margin: 10px 0;
  }
  .sub-faq {
    margin-left: 30px;
  }
  .disclaimer {
    font-size: 13px;
    color: #929fad;
    padding: 18px 41px 31px 43px;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: flex-end;
  }
  .faq-tab {
    color: #b0c8e2;
    background: #ffffff;
    border: 1px solid #b0c8e2;
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 500;
    width: 230px;
    text-align: center;
    border-bottom: 3px solid #b0c8e2;
  }
  .ant-tabs-tab-active .faq-tab {
    color: #ffffff;
    background: #b0c8e2;
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 500;
    width: 230px;
    text-align: center;
  }
  .ant-tabs-tab {
    padding-bottom: 0px;
    padding-top: 9px;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 5px;
  }
  .ant-tabs-ink-bar {
    background: none;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 3px solid #b0c8e2;
  }
`;
