import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { createPin, getAuthorityInformation, updateAuthorityInformation, updatePin, updateSignature, verifyPin, } from "./action";
import { initialAuthorityInformation } from "./types";
const initialState = {
    authorityInformation: {
        data: initialAuthorityInformation,
        status: false,
        error: null,
    },
    isShowMessage: false,
    isLocked: true,
    signatureUrl: "",
};
const authorisingAuthoritySlice = createSlice({
    name: "authorisingAuthority",
    initialState,
    reducers: {
        setSignatureUrl(state, action) {
            state.signatureUrl = action.payload;
        },
        setIsShowMessage(state, action) {
            state.isShowMessage = action.payload;
        },
        setIsLocked(state, action) {
            state.isLocked = action.payload;
        },
        setResetStore() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAuthorityInformation.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.authorityInformation.data = initialAuthorityInformation;
                message.error("Failed to get authorities");
            }
            else {
                state.authorityInformation.data = action.payload.data;
            }
        })
            .addCase(getAuthorityInformation.rejected, (state) => {
            state.authorityInformation.data = initialAuthorityInformation;
            message.error("Failed to get authorities");
        })
            .addCase(updateAuthorityInformation.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to save authorities");
            }
            else {
                message.success("Succeed to save authorities");
            }
        })
            .addCase(updateAuthorityInformation.rejected, () => {
            message.error("Failed to save authorities");
        })
            .addCase(verifyPin.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error(action.payload.message);
            }
            else {
                state.isLocked = false;
                state.signatureUrl = action.payload.data.signature;
                message.success("Pin verified successfully");
            }
        })
            .addCase(verifyPin.rejected, () => {
            message.error("Failed to verify pin");
        })
            .addCase(updateSignature.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to update signature");
            }
            else {
                state.signatureUrl = action.payload.data.signature;
                message.success("Signature updated successfully");
            }
        })
            .addCase(updateSignature.rejected, () => {
            message.error("Failed to update signature");
        })
            .addCase(updatePin.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error(action.payload.message);
            }
            else {
                state.isShowMessage = true;
            }
        })
            .addCase(updatePin.rejected, () => {
            message.error("Failed to update pin");
        })
            .addCase(createPin.fulfilled, (state, action) => {
            if (action.payload.status === "fail") {
                message.error(action.payload.message);
            }
            else {
                state.isShowMessage = true;
            }
        })
            .addCase(createPin.rejected, () => {
            message.error("Failed to create pin");
        });
    },
});
export const { setSignatureUrl, setIsLocked, setIsShowMessage, setResetStore } = authorisingAuthoritySlice.actions;
export default authorisingAuthoritySlice.reducer;
