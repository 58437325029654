import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Button, Image, Loading } from "src/components/base";
import { mainAppColours, SAColourScheme } from "src/constants";
export const CreateAppointmentButton = ({ isLoading }) => (_jsxs(StyledButton, { type: "primary", htmlType: "submit", "$isLoadingState": isLoading, disabled: isLoading, children: [isLoading ? (_jsx(Loading, { iconProps: { style: { color: "#FFFFFF" } } })) : (_jsx(Image, { src: "/assets/svg/ic-appointment-white.svg", preview: false })), !isLoading && `+ Create Appointment`] }));
const StyledButton = styled(Button) `
  text-align: ${(props) => (props.$isLoadingState ? "center" : "right")};
  position: relative;
  width: 120px;
  height: 64px;
  background-color: ${mainAppColours.BLUE};
  color: ${SAColourScheme.WHITE};
  border-radius: 8px;
  border: 2px solid ${SAColourScheme.WHITE};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  white-space: break-spaces;
  text-align: right;
  transition: 0.1s all;

  .ant-image {
    position: absolute;
  }

  &:hover {
    color: ${SAColourScheme.WHITE};
  }

  &[disabled] {
    background-color: "#FD0000";
  }
`;
