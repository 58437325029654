import styled from "styled-components";
export const FormSignInWrapper = styled.div `
  width: 100%;
  padding: 30px 50px 10px 50px;

  #email-input::placeholder,
  #password-input::placeholder {
    color: #1a8cff;
    opacity: 1; /* Firefox */
  }

  #email:-ms-input-placeholder,
  #password-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #1a8cff;
  }

  #email::-ms-input-placeholder,
  #password-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #1a8cff;
  }

  .login-form {
    min-width: 280px;
  }

  .login-form-forgot {
    float: right;
  }

  .login-form-button {
    width: 100%;
    height: 45px;
    background-color: #1a1448;
    border: none;
  }

  .additional-info {
    text-align: center;
    margin: auto;

    & > div {
      display: inline-block;
      line-height: 30px;
      padding: 0 10px;
      font-family: roboto;
      color: #0047b3;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 100%;
    }
  }

  .text-link-info {
    font-family: Roboto !important;
  }
  .email-login:after {
    content: "";
    display: table;
    clear: both;
  }
  .email-icon {
    float: left;
    width: 15%;
    border: 2px solid #0047b3;
    padding: 5px;
    border-radius: 5px 0px 0px 5px;
    height: 45px;
    text-align: center;
  }
  .email-input {
    float: left;
    width: 85%;
    height: 45px;
  }
  .password-login:after {
    content: "";
    display: table;
    clear: both;
  }
  .password-icon {
    float: left;
    width: 15%;
    border: 2px solid #0047b3;
    padding: 8px;
    border-radius: 5px 0px 0px 5px;
    height: 45px;
    text-align: center;
  }
  .password-input {
    float: left;
    width: 70%;
    height: 45px;
  }
  .look-icon {
    float: left;
    width: 15%;
    text-align: center;
    padding: 10px 15px 10px 5px;
    height: 45px;
    border: 2px solid #0047b3;
    border-left: none;
    border-radius: 0px 5px 5px 0px;
  }
`;
