import styled from "styled-components";
import { supportingColours } from "src/constants";
export const SearchInputWrapper = styled.div `
  .search-input input.ant-input::placeholder {
    color: ${supportingColours.TEXT_MID_GRAY};
    opacity: 1; /* Firefox */
  }
  .search-input input.ant-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${supportingColours.TEXT_MID_GRAY};
  }
  .search-input input.ant-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${supportingColours.TEXT_MID_GRAY};
  }
  .search-input input.ant-input {
    font-size: 16px;
  }
  .search-input span.ant-input-suffix {
    padding-right: 10px;
  }
`;
