import styled from "styled-components";
export const RowDataWrapper = styled.div `
  margin-bottom: 3px;

  .arrow {
    height: 30px;
    background: #2e94f2;
    color: #ffffff;
    position: relative;
    width: 100%;
    // text-align:center;
    font-weight: bold;
    font-size: 14px;
    padding: 3px 9px;

    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;

    text-align: left;

    span {
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      line-height: 30px;
    }
  }

  .arrow-end {
    content: "";
    height: 0;
    width: 0;
    left: 100%;
    top: 0;
    border: 15px solid transparent;
    border-left: 15px solid #2e94f2;
  }

  .col-input {
    padding-left: 5px;

    input {
      text-align: end;
    }
  }

  .square {
    padding-right: 26px;
    height: 30px;
    background: #fafafa;
    border: 1px solid #ebedf0;
    border-radius: 4px;

    width: 90%;
    text-align: right;

    span {
      color: #00004c;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
    }
  }
`;
