/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import qs from "qs";
import moment from "moment";
import { $get } from "src/infra/http";
const getTestTypesData = createAsyncThunk("getTestTypeData", async () => await $get("/collection-manager/collection-report/invalid/test-types"));
const getListInvalidResult = createAsyncThunk("getListInvalidResult", async () => await $get(`/collection-manager/collection-report/invalid`));
const getListOnsiteCollectionReports = createAsyncThunk("getListOnsiteCollectionReports", async (_, thunkApi) => {
    const state = thunkApi.getState().collectionReportsPage;
    const date = state.currentDate;
    const collectionPointId = state.selectedCollectionPoint.id;
    const queryString = qs.stringify({
        date: moment(date).format("YYYY-MM-DD"),
        collectionPointId,
    }, { filter: (_, value) => value || undefined });
    return await $get(`/collection-manager/collection-report/onsite/list?${queryString}`);
});
const getCollectionReportDetail = createAsyncThunk("getCollectionReportDetail", async (_, thunkApi) => {
    const state = thunkApi.getState().collectionReportsPage;
    return await $get(`/collection-manager/collection-report/onsite/detail/${state.currentOnsiteClientSetUpId}`);
});
export { getListInvalidResult, getTestTypesData, getListOnsiteCollectionReports, getCollectionReportDetail, };
