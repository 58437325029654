import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, Select, Space, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { appointmentDuration } from "src/constants";
import { DurationSelectWrapper, InputWrapper } from "./DurationSelect.style";
const { Option } = Select;
export const DurationSelect = (props) => {
    const { field } = props;
    const [items, setItems] = useState(appointmentDuration);
    const [minutes, setMinutes] = useState(60);
    const [value, setValue] = useState(props.value);
    const onChangeInputNumber = (valueNum) => {
        setMinutes(valueNum);
    };
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    return (_jsx(DurationSelectWrapper, { className: props.className, children: _jsx(Select, { placeholder: "- Select -", value: value, disabled: props.disabled, dropdownRender: (menu) => (_jsxs(_Fragment, { children: [menu, _jsxs(InputWrapper, { children: [_jsx(Divider, { style: { margin: "8px 0" } }), _jsxs(Space, { align: "center", style: { padding: "0 8px 4px" }, children: [_jsx(InputNumber, { style: { width: "50px" }, min: 0, max: 300, value: minutes, onChange: onChangeInputNumber, onPressEnter: () => {
                                            setItems([...items, { label: `${minutes} min`, value: `${minutes}m` }]);
                                            setTimeout(() => {
                                                setValue(`${minutes}m`);
                                                props.onInputChange({ field, value: `${minutes}m` });
                                            }, 0);
                                        } }), "Min"] })] })] })), onChange: (val) => {
                setValue(val);
                props.onInputChange({ field, value: val });
            }, children: items.map((item) => (_jsx(Option, { children: item?.label }, item?.value))) }) }));
};
