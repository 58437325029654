import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Typography } from "antd";
import { mainAppColours } from "src/constants";
const { Text: AntdText } = Typography;
const Text = ({ className, style, name }) => (_jsx(AntdText, { className: className, style: {
        color: mainAppColours.BLUE,
        ...style,
    }, children: name }));
export default Text;
