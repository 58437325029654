import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Typography } from "antd";
import BlackLogo from "src/assets/svg/collection_manager_black_logo.svg";
import { FooterForm } from "src/pages/TwoFactorAuthPage/FooterForm";
import { fontWeight, SAColourScheme, supportingColours } from "src/constants";
import CancelIcon from "src/assets/svg/ic-cancel-black.svg";
import ProfileGreen from "src/assets/svg/ic_profile_green.svg";
import ProfileWhite from "src/assets/svg/ic_person_white.svg";
import { getCurrentEmail } from "src/pages/TwoFactorAuthPage/store/action";
import { $get } from "src/infra/http";
import { Domain } from "src/types";
import { useAppDispatch } from "src/store/hooks";
export const ChooseAccountForm = (props) => {
    const { onSendCode } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [accounts, setAccounts] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataEmail = await getCurrentEmail();
            if (dataEmail?.error) {
                navigate("/login");
            }
            setAccounts([dataEmail.data.account]);
        };
        fetchData();
    }, []);
    const removeAccount = (accountToRemove) => {
        setAccounts((prevAccounts) => prevAccounts.filter((account) => account.email !== accountToRemove));
    };
    const renderWay = (account, index) => (_jsxs(HoverAccount, { onClick: () => onSendCode(account), children: [_jsx(CenteredFlexBox, { children: _jsx(IconContainer, { children: _jsx(StyledEmptyIcon, {}) }) }), _jsxs(HoverName, { children: [_jsx(UsernameBox, { children: account.userName }), _jsx(EmailDetailedBox, { className: "text", style: { color: "#4B5767" }, children: account.email })] }), _jsx(HoverButton, { className: "hover-button", icon: _jsx(StyledCancelIcon, {}), onClick: (e) => {
                    e.stopPropagation();
                    removeAccount(account.email);
                } })] }, index));
    const NotListed = () => (_jsxs(NotlistedBox, { children: [_jsx(CenteredFlexBox, { children: _jsx(SmallIconContainer, { children: _jsx(StyledProfileWhiteIcon, {}) }) }), _jsx(NotListedLogin, { children: _jsxs(BoldText, { children: ["Not listed,", " ", _jsx("div", { onClick: async () => {
                                await $get("/cm/authenticate/logout", Domain.Auth);
                                dispatch({ type: "RESET" });
                                navigate("/login");
                            }, style: { color: SAColourScheme.MID_BLUE }, children: "please login" })] }) })] }));
    return (_jsxs(BoxWrapper, { children: [_jsx(TopBox, { children: _jsx(StyledBlackLogo, {}) }), _jsx(TypographyTitle, { children: "Choose an Account" }), _jsxs(TypographyText, { children: [" ", "to login ", _jsx("br", {}), "We will send you a ", _jsx("span", { style: { color: "#1A8CFF" }, children: "Verification Code" }), "."] }), accounts.map((account, index) => renderWay(account, index)), NotListed(), _jsx(FooterForm, {})] }));
};
const TopBox = styled.div `
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid ${supportingColours.BOX_GRAY};
  font-size: 24px;
  padding: 30px 30px;
`;
const BoxWrapper = styled.div `
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 3px 6px #ffffff29;
  width: 30rem;
  text-align: center;
`;
const TypographyTitle = styled(Typography) `
  font-size: 28px;
  font-weight: ${fontWeight.REGULAR};
  color: #191345;
  margin-top: 1rem;
`;
const TypographyText = styled(Typography) `
  font-size: 18px;
  color: #00004c;
  margin-bottom: 1rem;
  text-align: center;
`;
const HoverAccount = styled.div `
  width: 100%;
  height: 60px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px;
  position: relative;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 30px;
    right: 30px;
    height: 1px;
    background-color: ${supportingColours.BOX_GRAY}; /* 색상 지정 */
  }

  &:hover {
    background-color: #cfeaf2;
  }
  &:hover .hover-button {
    opacity: 0.5;
  }
`;
const NotlistedBox = styled.div `
  width: 100%;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px;
  margin-bottom: 60px;
  flex-shrink: 1;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 30px;
    right: 30px;
    height: 1px;
    background-color: ${supportingColours.BOX_GRAY}; /* 색상 지정 */
  }
`;
const HoverButton = styled(Button) `
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: none;
  opacity: 0.01;
  transition: opacity 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: contain;
`;
const StyledCancelIcon = styled(CancelIcon) `
  width: 27px;
  height: 27px;
`;
const IconContainer = styled.div `
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 3px;
`;
const SmallIconContainer = styled.div `
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const StyledEmptyIcon = styled(ProfileGreen) `
  width: 32px;
  height: 32px;
  object-fit: contain;
`;
const StyledProfileWhiteIcon = styled(ProfileWhite) `
  width: 20px;
  height: 20px;
  object-fit: contain;
`;
const UsernameBox = styled.div `
  font-weight: ${fontWeight.MEDIUM};
  font-size: 14px;
`;
const EmailDetailedBox = styled.div `
  font-weight: ${fontWeight.LIGHT};
  font-size: 12px;
`;
const CenteredFlexBox = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const HoverName = styled.div `
  text-align: left;
  flex: 60%;
  margin-left: 24px;
`;
const NotListedLogin = styled.div `
  text-align: left;
  flex: 60%;
  justify-content: center;
  margin-left: 24px;
`;
const BoldText = styled.div `
  color: #4b5767;
  font-weight: bold;
`;
const StyledBlackLogo = styled(BlackLogo) `
  width: 219px;
  height: 36px;
`;
