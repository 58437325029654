import styled from "styled-components";
import { supportingColours } from "src/constants";
export const PageWrapper = styled.div `
  font-family:
    Roboto,
    -apple-system;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
export const OptionsDropdownWrapper = styled.div `
  margin: -4px 0;
  padding: 6px 0px;
  border-radius: 4px;
  border: 1px solid ${supportingColours.BOX_GRAY};

  .ant-select-item-option-content {
    font-size: 16px;
    color: ${supportingColours.TEXT_DARK_GRAY};
  }
`;
