/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { $get, $post, $put } from "src/infra/http";
const getUserInformation = createAsyncThunk("getListUsers", async () => await $get("/collection-manager/user/information"));
const updateUserDetail = createAsyncThunk("updateUserDetail", async (data) => await $put(`/collection-manager/user/information`, data));
const updateUserAvatar = createAsyncThunk("updateUserAvatar", async (avatarUrl) => await $put(`/collection-manager/user/avatar`, {
    avatarUrl,
}));
const verifyPassword = createAsyncThunk("verifyPassword", async (password) => await $post(`/collection-manager/account/ask-change-password`, {
    password,
}));
const verifyPasswordDeleteAccount = createAsyncThunk("verifyPasswordDeleteAccount", async (password) => await $post(`/collection-manager/account/verify-password`, {
    password,
}));
const changePassword = createAsyncThunk("changePassword", async (data) => await $put(`/collection-manager/account/change-password`, data));
const askDeleteAccount = createAsyncThunk("askDeleteAccount", async (data) => await $post(`/collection-manager/account/delete-account/ask`, data));
export { askDeleteAccount, changePassword, getUserInformation, updateUserAvatar, updateUserDetail, verifyPassword, verifyPasswordDeleteAccount, };
