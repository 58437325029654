import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Select } from "antd";
import { getCommunicationTemplates } from "src/api/communication/service";
import { Form, Image, InputWithLabel, Loading, Row, SearchInput, Table, Text, } from "src/components/base";
import { SAColourScheme, supportingColours } from "src/styles";
import { columnConfig } from "../config/templateColumn";
export const CommunicationTemplates = () => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [communicationTemplates, setCommunicationTemplates] = useState([]);
    const [filteredCommunicationTemplates, setFilteredCommunicationTemplates] = useState([]);
    const [searchString, setSearchString] = useState("");
    const onSearchString = (value) => {
        setSearchString(value);
    };
    const getData = async () => {
        const result = await getCommunicationTemplates();
        if (result.status === "error") {
            setCommunicationTemplates([]);
        }
        if (result.status === "success") {
            setFilteredCommunicationTemplates(result.data);
            setCommunicationTemplates(result.data);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        filterChange();
    }, [searchString]);
    const filterChange = () => {
        const value = form.getFieldsValue();
        const fromDate = value.fromDate ? moment(value.fromDate).startOf("day") : null;
        const toDate = value.toDate ? moment(value.toDate).endOf("day") : null;
        // If both fromDate and toDate are null, and there's no searchString, reset the filtered communication
        if (fromDate === null && toDate === null && !searchString) {
            setFilteredCommunicationTemplates(communicationTemplates);
            return;
        }
        const filteredData = communicationTemplates.filter((item) => {
            const time = 
            // eslint-disable-next-line no-underscore-dangle
            item.timeSent && moment(item.timeSent._seconds * 1000 + item.timeSent._nanoseconds / 1e6);
            const isWithinDateRange = (!fromDate || moment(time).isSameOrAfter(fromDate)) &&
                (!toDate || moment(time).isSameOrBefore(toDate));
            const matchesSearchString = item.description.toLowerCase().includes(searchString);
            return isWithinDateRange && matchesSearchString;
        });
        setFilteredCommunicationTemplates(filteredData);
    };
    const itemRender = (current, type, originalElement) => {
        if (type === "prev") {
            return (_jsx(PrevIcon, { src: "/assets/images/double-arrow-back-box-gray-icon.png", preview: false, className: "prev-icon" }));
        }
        if (type === "next") {
            return (_jsx(NextIcon, { src: "/assets/images/double-arrow-forth-box-gray-icon.png", preview: false, className: "next-icon" }));
        }
        return originalElement;
    };
    const [pagination, setPagination] = useState({
        defaultPageSize: 5,
        pageSize: 10,
        total: filteredCommunicationTemplates.length,
        itemRender: itemRender,
        pageSizeOptions: [10, 15, 20],
    });
    const [pageSize, setPageSize] = useState(10);
    const select = (size) => {
        setPagination({ ...pagination, pageSize: size });
        setPageSize(size);
    };
    return (_jsx(CommunicationTemplatesWrapper, { children: _jsxs("div", { className: isLoading ? "loading" : "", children: [_jsxs(Form, { form: form, children: [_jsx(StyledTitle, { children: _jsx(Text, { name: "Communication Templates" }) }), _jsxs(Row, { className: "filter", children: [_jsx(Text, { className: "filter-text", name: "Start Date" }), _jsx(InputWithLabel, { className: "filter-input", field: "fromDate", inputProps: {
                                        type: "date",
                                        placeholder: null,
                                    }, onInputChange: filterChange }), _jsx(Text, { className: "filter-text", name: "End Date" }), _jsx(InputWithLabel, { className: "filter-input", field: "toDate", inputProps: {
                                        type: "date",
                                        placeholder: null,
                                    }, onInputChange: filterChange })] }), _jsx(Row, { justify: "end", style: { margin: "15px 0" }, children: _jsx(SearchInput, { onChange: (e) => onSearchString(e.target.value) }) })] }), isLoading && (_jsxs("div", { className: "is-loading", children: [_jsx(Loading, { iconProps: { style: { fontSize: 48, color: "#179AB1" } } }), _jsx("p", { className: "is-loading-text ", children: "Fetching details..." })] })), _jsx(Table, { dataSource: filteredCommunicationTemplates ?? [], columns: columnConfig, pagination: pagination, scroll: { x: 900, y: 650 } }), filteredCommunicationTemplates.length > 0 && (_jsxs(StyledRow, { justify: "start", align: "middle", children: [_jsx(Text, { name: "Show" }), _jsxs(Select, { value: pageSize, onSelect: select, suffixIcon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false }), children: [_jsx(Select.Option, { value: 10, children: "10" }), _jsx(Select.Option, { value: 15, children: "15" }), _jsx(Select.Option, { value: 20, children: "20" })] })] }))] }) }));
};
const CommunicationTemplatesWrapper = styled.div `
  width: 100% !important;
  background: #179ab1;
  padding: 16px;

  .loading {
    opacity: 0.3;
    pointer-events: none;
  }
  .is-loading {
    position: absolute;
    margin: 100px 45%;
    z-index: 10;
  }

  .is-loading-text {
    font-size: 17px;
    color: #179ab1;
  }

  .filter {
    width: 100%;
    justify-content: flex-end;
  }

  .filter-text {
    color: ${SAColourScheme.WHITE} !important;
    margin: auto;
    margin-left: 12px;
    margin-right: 7px;
  }

  .filter-input {
    margin-bottom: 0px;

    input {
      font-size: 14px !important;
    }

    .ant-picker {
      height: 30px !important;
      width: 100px !important;
    }

    .ant-picker-suffix {
      display: none;
    }
  }

  .ant-table table {
    width: 100% !important;
    background-color: #ffffff !important;

    .ant-table-header {
      display: none !important;
    }
    .ant-table-thead th {
      background-color: #179ab1 !important;
      border-top: 1px solid ${SAColourScheme.WHITE} !important;
      color: ${SAColourScheme.WHITE} !important;
      padding: 15px;
    }

    .ant-table-row .ant-table-cell {
      padding: 10px !important;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      background-color: #ffffff !important;
    }
  }

  .ant-table-container {
    width: 100% !important;
  }

  .ant-pagination.mini .ant-pagination-prev {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }
  .ant-pagination.mini .ant-pagination-next {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }
  li.ant-pagination-item {
    border: 1px solid #ebedf0 !important;
  }
  li.ant-pagination-item a {
    color: #367893;
    font-size: 16px;
  }
  li.ant-pagination-item.ant-pagination-item-active {
    background: #adb3bc;
  }
  li.ant-pagination-item.ant-pagination-item-active a {
    color: white;
  }

  .ant-pagination-options {
    display: none !important;
  }
  .ant-pagination-item-ellipsis {
    color: white !important;
  }
  .summary {
    margin-top: 16px;
    background-color: ${SAColourScheme.WHITE};

    .col-center {
      display: flex;
      justify-content: center;
    }

    .col-end {
      display: flex;
      justify-content: flex-end;
      padding-right: 17px;
    }

    .ant-divider,
    .splash {
      margin: 10px 0;
      border-top: 1px solid ${supportingColours.BOX_GRAY};
    }

    .splash {
      border-top-style: dashed;
    }
  }
`;
const PrevIcon = styled(Image) `
  height: 10px;
  width: 10px;
`;
const NextIcon = styled(Image) `
  height: 10px;
  width: 10px;
`;
const StyledRow = styled(Row) `
  margin-top: -50px;
  margin-left: 10px;
  display: flex !important;
  gap: 10px;

  .ant-typography {
    font-size: 1rem;
    color: ${SAColourScheme.WHITE}!important;
  }
  .ant-select {
    max-width: 4.5em;
  }
  .ant-image-img {
    height: 8px;
    width: 14px;
  }
`;
const StyledTitle = styled(Row) `
  position: absolute;
  top: 40px;
  left: 50px;
  .ant-typography {
    color: ${SAColourScheme.WHITE}!important;
    font-size: 2rem;
    font-weight: 700;
  }
`;
