import styled from "styled-components";
import { mainAppColours, SAColourScheme } from "src/constants";
export const PageWrapper = styled.div `
  position: relative;
  background-color: white;

  .submit-button {
    position: relative;
    width: 120px;
    height: 64px;
    background-color: ${mainAppColours.BLUE};
    color: ${SAColourScheme.WHITE};
    border-radius: 8px;
    border: 2px solid ${SAColourScheme.WHITE};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    white-space: break-spaces;
    text-align: right;
    transition: 0.1s all;

    .ant-image {
      position: absolute;
    }

    &:hover {
      color: ${SAColourScheme.WHITE};
    }

    &[disabled] {
      background-color: ${mainAppColours.BLUE} !important;
      opacity: 0.5;
    }
  }

  td.ant-table-cell.last-name-column {
    max-width: 150px;
  }
  td.ant-table-cell.first-name-column {
    max-width: 150px;
  }
  .ant-picker.date-picker {
    padding: 0;
    border: 0;
  }
  .date-picker > .ant-picker-input {
    display: none;
  }
`;
