import { CollectionReportHistoryStatus } from "./store/types";
const CollectionReportStatusDetails = {
    [CollectionReportHistoryStatus.Creating]: {
        color: "#FDBA00",
        message: "Send by Collector",
    },
    [CollectionReportHistoryStatus.Pending]: {
        color: "#FDBA00",
        message: "Send by Collector",
    },
    [CollectionReportHistoryStatus.Success]: {
        color: "#5CD22B",
        message: "Successfully Sent",
    },
    [CollectionReportHistoryStatus.Error]: {
        color: "#FD0000",
        message: "Error Sending Report",
    },
    [CollectionReportHistoryStatus.Nodata]: {
        color: "",
        message: "",
    },
};
export { CollectionReportStatusDetails };
