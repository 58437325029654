import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { useEffect, useState } from "react";
import { getTestResultsData } from "src/api/dashboard/dashboardService";
import { Checkbox, Col, DatePicker, Row, Select, Spin, Typography } from "src/components/base";
import ColumnChart from "src/components/dataView/Charts/Column";
import { TestResultsWrapper } from "src/pages/DashboardPage/components/TestResultsChart/index.style";
import { dashboard_getColorByName, getCurrentDay, getCurrentMonth, getDayListByYearMonth, getMonthList, } from "src/utils/helper";
const { Text } = Typography;
const TestResultsChart = ({ collectionPoints, selectedColorScheme }) => {
    const [dataChart, setDataChart] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [location, setLocation] = useState("All");
    const [selectYear, setSelectYear] = useState(`${moment().year()}`);
    const [selectMonth, setSelectMoth] = useState(getCurrentMonth());
    const [selectDay, setSelectDay] = useState(getCurrentDay());
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [enableRangeDate, setEnableRangeDate] = useState(false);
    const [enableDate, setEnableDate] = useState(true);
    const [enableMonth, setEnableMonth] = useState(true);
    const getData = async () => {
        if (enableRangeDate) {
            if (fromDate !== null && toDate !== null) {
                setIsLoading(true);
                const startDate = moment(fromDate).format("YYYY-MM-DD");
                const endDate = moment(toDate).format("YYYY-MM-DD");
                const result = await getTestResultsData(location, startDate, endDate);
                if (result.status === "success") {
                    setDataChart(result?.data);
                    setIsLoading(false);
                }
            }
        }
        else {
            setIsLoading(true);
            const startDate = moment(`${selectYear}-${selectMonth}-${selectDay}`, "YYYY-MM-DD").format("YYYY-MM-DD");
            const endDate = moment(`${selectYear}-${selectMonth}-${selectDay}`, "YYYY-MM-DD").format("YYYY-MM-DD");
            const result = await getTestResultsData(location, startDate, endDate);
            if (result.status === "success") {
                setDataChart(result?.data);
                setIsLoading(false);
            }
        }
    };
    useEffect(() => {
        getData();
    }, [location, fromDate, toDate, enableDate, enableMonth]);
    const onSelectYear = (year) => {
        setSelectYear(year);
    };
    const onSelectMonth = (month) => {
        setSelectMoth(month);
    };
    const onSelectDay = (day) => {
        setSelectDay(day);
    };
    const onSelectFromDate = (date) => {
        if (date === null && toDate === null) {
            setEnableRangeDate(false);
            setFromDate(null);
        }
        else {
            setEnableRangeDate(true);
            setFromDate(date);
        }
    };
    const onSelectToDate = (date) => {
        if (fromDate === null && date === null) {
            setEnableRangeDate(false);
            setToDate(null);
        }
        else {
            setEnableRangeDate(true);
            setToDate(date);
        }
    };
    const onCheckBoxMonth = ({ target: { checked } }) => {
        setEnableMonth(checked);
    };
    const onCheckBoxDate = ({ target: { checked } }) => {
        setEnableDate(checked);
    };
    return (_jsxs(TestResultsWrapper, { children: [_jsxs("div", { style: { display: "flex", flexDirection: "row", padding: "10px" }, children: [_jsxs("div", { style: {
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            width: "90%",
                            alignItems: "center",
                        }, children: [_jsx(Col, { span: 3, children: _jsx(Text, { strong: true, children: "Test Results" }) }), _jsx(Col, { span: 6, offset: 1, children: _jsxs(Row, { children: [_jsx(Col, { flex: 4, children: _jsx(Text, { children: "Location" }) }), _jsx(Col, { flex: 8, children: _jsxs("select", { defaultValue: location, onChange: (event) => setLocation(event.target.value), style: {
                                                    // minWidth: "200px",
                                                    width: "100%",
                                                    height: "25px",
                                                    justifyContent: "center",
                                                    borderRadius: "5px",
                                                    border: "1px solid #ADB3BC",
                                                    textAlign: "center",
                                                }, children: [_jsx("option", { value: "All", children: "All" }), Object.values(collectionPoints)
                                                        .filter((cp) => cp.name !== "All")
                                                        .map((cp) => (_jsx("option", { value: cp.name, children: cp.name }, `option-${cp.name}`)))] }) })] }) }), _jsx(Col, { span: 3, offset: 1, children: _jsxs(Row, { children: [_jsx(Col, { flex: 5, children: _jsx(Text, { children: "Year" }) }), _jsx(Col, { flex: 7, children: _jsx(Select, { size: "small", defaultValue: selectYear, options: []?.map((year) => ({
                                                    label: year,
                                                    value: year,
                                                })), disabled: enableRangeDate, onChange: onSelectYear }) })] }) }), _jsx(Col, { span: 4, offset: 1, children: _jsxs(Row, { children: [_jsx(Col, { flex: 1, children: _jsx(Checkbox, { disabled: enableRangeDate, checked: enableMonth, onChange: onCheckBoxMonth }) }), _jsx(Col, { flex: 1, children: _jsx(Text, { children: "Month" }) }), _jsx(Col, { flex: 1, children: _jsx(Select, { defaultValue: selectMonth, size: "small", disabled: enableRangeDate || !enableMonth, options: getMonthList().map((month) => ({
                                                    label: month,
                                                    value: month,
                                                })), onChange: onSelectMonth }) })] }) }), _jsx(Col, { span: 3, offset: 1, children: _jsxs(Row, { children: [_jsx(Col, { flex: 3, children: _jsx(Checkbox, { disabled: enableRangeDate || !enableMonth, checked: enableDate, onChange: onCheckBoxDate }) }), _jsx(Col, { flex: 4, children: _jsx(Text, { children: "Day" }) }), _jsx(Col, { flex: 4, children: _jsx(Select, { size: "small", defaultValue: selectDay, disabled: enableRangeDate || !enableMonth || !enableDate, options: getDayListByYearMonth(selectYear, selectMonth).map((day) => ({
                                                    label: day,
                                                    value: day,
                                                })), onChange: onSelectDay }) })] }) })] }), _jsxs("div", { style: {
                            display: "flex",
                            flexDirection: "column",
                            padding: "5px",
                            borderLeft: "solid gray",
                        }, children: [_jsx(DatePicker, { value: fromDate, size: "small", placeholder: "From", name: "from_date", onChange: onSelectFromDate }), _jsx(DatePicker, { value: toDate, size: "small", placeholder: "To", name: "date_to", disabledDate: (current) => current && current < fromDate, onChange: onSelectToDate })] })] }), _jsxs(Row, { style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }, children: [_jsx("div", { className: "circle", style: {
                            background: dashboard_getColorByName(selectedColorScheme, 6),
                            marginRight: "10px",
                        } }), _jsx(Text, { style: { marginRight: "50px" }, children: "Negative" }), _jsx("div", { className: "circle", style: {
                            background: dashboard_getColorByName(selectedColorScheme, 5),
                            marginRight: "10px",
                        } }), _jsx(Text, { style: { marginRight: "50px" }, children: "Non Negative" }), _jsx("div", { className: "circle", style: {
                            background: dashboard_getColorByName(selectedColorScheme, 4),
                            marginRight: "10px",
                        } }), _jsx(Text, { style: { marginRight: "50px" }, children: "Invalid" }), _jsx("div", { className: "circle", style: {
                            background: dashboard_getColorByName(selectedColorScheme, 3),
                            marginRight: "10px",
                        } }), _jsx(Text, { style: { marginRight: "50px" }, children: "Sent to lab" }), _jsx("div", { className: "circle", style: {
                            background: dashboard_getColorByName(selectedColorScheme, 2),
                            marginRight: "10px",
                        } }), _jsx(Text, { style: { marginRight: "50px" }, children: "Refusal" })] }), _jsx(Row, { gutter: 16, children: _jsxs(Col, { className: "gutter-row", span: 24, style: { height: "410px" }, children: [_jsx("div", { style: { position: "relative" }, children: isLoading && (_jsx(Spin, { style: {
                                    position: "absolute",
                                    top: "150px",
                                    left: "30vw",
                                } })) }), _jsx("div", { style: {
                                opacity: isLoading ? 0.3 : 1,
                                display: "flex",
                                overflowX: "scroll",
                            }, children: Object.values(dataChart)?.map((data, index) => (
                            // <div style={{border:"1px solid black"}}>
                            _jsx(ColumnChart, { dataColumnChart: data, selectedColorScheme: selectedColorScheme }, index)
                            // </div>
                            )) })] }) })] }));
};
export default TestResultsChart;
