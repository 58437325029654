import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select, Table, Typography } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
const columns = [
    {
        title: "Date",
        dataIndex: "date",
        width: "20%",
    },
    {
        title: "Edited by",
        dataIndex: "author",
        width: "20%",
    },
    {
        title: "Detail",
        dataIndex: "detail",
    },
];
const AuditLogTable = () => {
    const [data] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30"],
    });
    const handleTableChange = (newPagination) => {
        setPagination(newPagination);
    };
    return (_jsxs(PaperWrapper, { children: [_jsx(Table, { columns: columns, dataSource: data, pagination: {
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                }, onChange: handleTableChange }), _jsxs(SizeChangerContainer, { children: [_jsx(Typography.Text, { children: "Show" }), _jsxs(PaginationSizeChanger, { value: pagination.pageSize, onSelect: (size) => {
                            setPagination({ ...pagination, pageSize: size });
                        }, children: [_jsx(Select.Option, { value: 10, children: "10" }), _jsx(Select.Option, { value: 15, children: "15" }), _jsx(Select.Option, { value: 20, children: "20" })] })] })] }));
};
const PaperWrapper = styled.div `
  width: 100%;

  .ant-table-wrapper {
    width: 100%;
  }
  .ant-table-thead > tr > th {
    background-color: #e6f7fa;
  }
  position: relative;
`;
const SizeChangerContainer = styled.div `
  position: absolute;
  bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  min-width: 5rem;
`;
const PaginationSizeChanger = styled(Select) `
  width: fit-content;
`;
export default AuditLogTable;
