import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Form, Image, Input, Popconfirm, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { SAColourScheme } from "src/styles";
import { selectIsLoading } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { deleteNote, getListNotes, getNoteHistory, updateNote } from "./store/action";
import { NoteLogHistory } from "./NoteLogHistory";
export const NotesContent = ({ selectedNoteDetail, clientId, locationsData, }) => {
    const dispatch = useAppDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const [formDisabled, setFormDisabled] = useState(true);
    const [form] = Form.useForm();
    const isLoading = useAppSelector(selectIsLoading);
    const handleDelete = async () => {
        if (selectedNoteDetail?.id) {
            dispatch(setIsLoading(true));
            await dispatch(deleteNote(selectedNoteDetail.id));
            if (clientId) {
                await dispatch(getListNotes(clientId));
            }
            dispatch(setIsLoading(false));
        }
    };
    const updateClientNote = async () => {
        const values = await form.validateFields();
        if (selectedNoteDetail?.id) {
            const payload = {
                ...values,
                id: selectedNoteDetail.id,
                onsiteClientId: clientId,
            };
            dispatch(setIsLoading(true));
            await dispatch(updateNote(payload));
            if (clientId) {
                await dispatch(getListNotes(clientId));
            }
            await dispatch(getNoteHistory(selectedNoteDetail?.id));
            dispatch(setIsLoading(false));
            setIsEdit(false);
        }
    };
    useEffect(() => {
        setIsEdit(false);
        setFormDisabled(true);
        form.setFieldsValue({
            subject: selectedNoteDetail?.subject,
            noteText: selectedNoteDetail?.noteText,
            onsiteClientLocationId: selectedNoteDetail?.onsiteClientLocationId,
        });
    }, [selectedNoteDetail]);
    return (_jsxs(NotesContentWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(Form, { form: form, children: [!isEdit && (_jsx(Row, { justify: "end", children: _jsxs(EditButton, { onClick: () => {
                                setIsEdit(true);
                                setFormDisabled(false);
                            }, children: [_jsx(Image, { src: "/assets/svg/ic-form-edit.svg", height: 18, preview: false }), "Edit"] }) })), _jsxs(Row, { gutter: [50, 10], children: [_jsx(Col, { span: 24, children: _jsx(Form.Item, { label: "Location", name: "onsiteClientLocationId", rules: [{ required: true, message: "Please select location" }], children: _jsx(Select, { placeholder: "--Select--", className: "status-select", size: "small", options: locationsData, disabled: formDisabled }) }) }), _jsx(Col, { span: 24, children: _jsx(Form.Item, { label: "Subject", name: "subject", rules: [{ required: true, message: "Please input subject" }], children: _jsx(Input, { disabled: formDisabled }) }) }), _jsx(Col, { span: 24, children: _jsx(Form.Item, { label: "", name: "noteText", children: _jsx(TextAreaWrapper, { disabled: formDisabled, placeholder: "Notes" }) }) })] }), isEdit && (_jsxs(Row, { justify: "space-between", children: [_jsx(Popconfirm, { title: "Are you sure to delete this note?", onConfirm: handleDelete, okButtonProps: { loading: isLoading }, okText: "Yes", cancelText: "No", placement: "topRight", children: _jsxs(DeleteButton, { disabled: formDisabled, children: [_jsx(Image, { src: "/assets/svg/ic-delete-ar.svg", height: 18, preview: false }), "Delete"] }) }), _jsxs(ButtonsRowWrapper, { children: [_jsx(CancelButton, { onClick: () => {
                                            setIsEdit(false);
                                            setFormDisabled(true);
                                        }, children: "Cancel" }), _jsx(SaveButton, { disabled: formDisabled, onClick: () => {
                                            form.validateFields().then(() => {
                                                updateClientNote();
                                            });
                                        }, children: "Save" })] })] }))] }), _jsx(NoteLogHistory, { selectedItem: selectedNoteDetail })] }));
};
const NotesContentWrapper = styled.div `
  padding: 2rem 4rem;

  .ant-select-selection-placeholder {
    color: ${SAColourScheme.BLACK} !important;
  }
  .ant-select-disabled .ant-select-selection-item {
    color: ${SAColourScheme.BLACK} !important;
  }

  .ant-input,
  .ant-input::placeholder {
    color: ${SAColourScheme.BLACK} !important;
  }
`;
const TextAreaWrapper = styled(TextArea) `
  height: 18rem !important;
`;
const EditButton = styled(Button) `
  margin-bottom: 1rem;
  border-radius: 5px;
  min-width: 6rem;
  display: flex;
  justify-content: space-between;
`;
const DeleteButton = styled(Button) `
  background: ${SAColourScheme.WHITE};
  color: #fd0000;
  border: 1px solid #adb3bc;
  border-radius: 5px;
  margin-bottom: 1rem;
  min-width: 6rem;

  display: flex;
  justify-content: space-between;
`;
const CancelButton = styled(Button) `
  background: ${SAColourScheme.WHITE};
  color: #fd0000;
  border: 1px solid #adb3bc;
  border-radius: 5px;
  min-width: 8rem;
`;
const SaveButton = styled(Button) `
  background: #1a8cff;
  color: ${SAColourScheme.WHITE};
  border: 1px solid #1a8cff;
  border-radius: 5px;
  border: 1px solid #1a8cff;
  min-width: 8rem;

  &:hover {
    background: #1a8cff;
    color: ${SAColourScheme.WHITE};
  }

  &:disabled {
    border: 1px solid white;
    background: #c5c7d1;
    color: #ffffff;
  }
`;
const ButtonsRowWrapper = styled(Row) `
  gap: 2rem;
`;
