import { jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "styled-components";
import { Button } from "src/components/base";
import { APPOINTMENT_STATUS_NUMBER, MESSAGE_BOX_TYPE, SAColourScheme } from "src/constants";
export const CancelButton = ({ appointmentStatus, cancelAppointment, openMessageBox, }) => (_jsxs(StyledButton, { style: { backgroundColor: SAColourScheme.RED }, type: "primary", onClick: () => {
        openMessageBox(MESSAGE_BOX_TYPE.PROMPT, "Please confirm to cancel this appointment?", () => {
            cancelAppointment();
        });
    }, disabled: appointmentStatus === APPOINTMENT_STATUS_NUMBER.CANCELED, children: [appointmentStatus === APPOINTMENT_STATUS_NUMBER.CANCELED && "Cancelled", appointmentStatus !== APPOINTMENT_STATUS_NUMBER.CANCELED && "Cancel Appointment"] }));
const StyledButton = styled(Button) `
  width: 190px;
  height: 30px;
  color: ${SAColourScheme.WHITE};
  border-radius: 4px;
  border: 1px solid ${SAColourScheme.WHITE};
  font-size: 15px;
  text-align: left;
  padding: 4px 25px;
  line-height: 1;
`;
