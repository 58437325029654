import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Button, Col, Input, Row } from "antd";
import { env } from "src/constants";
import { Text } from "src/components/base";
import { SAColourScheme } from "src/styles";
const MapView = ({ collectionPointsSetting, selectedCollectionPoint, onUpdateRadius, onSaveChanges, zoom = 14, }) => {
    const API_GG_MAP_KEY = env.API_GG_MAP_KEY || "";
    let circle;
    let centerMarker;
    const { latitude, longitude, geofencingMeter } = collectionPointsSetting.find((item) => item.collectionPointId === selectedCollectionPoint.collectionPointId) || {};
    const latLng = {
        lat: latitude ? parseFloat(latitude) : 0,
        lng: longitude ? parseFloat(longitude) : 0,
    };
    const radius = Number(geofencingMeter);
    const mapRef = useRef(null);
    useEffect(() => {
        if (!window.google) {
            // Load Google Maps API script
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=${API_GG_MAP_KEY}&libraries=geometry`;
            script.async = true;
            document.body.appendChild(script);
            script.onload = initializeMap;
        }
        else {
            initializeMap();
        }
    }, [selectedCollectionPoint, collectionPointsSetting]);
    const initializeMap = () => {
        if (!mapRef.current)
            return;
        const map = new window.google.maps.Map(mapRef.current, {
            center: latLng,
            zoom: zoom,
        });
        centerMarker = new window.google.maps.Marker({
            position: latLng,
            map: map,
        });
        circle = new window.google.maps.Circle({
            map: map,
            center: latLng,
            radius: radius,
            fillColor: "#FFFFFF",
            fillOpacity: 0.7,
            strokeColor: "#35B700",
            strokeOpacity: 0.8,
            strokeWeight: 2,
        });
        const customMarkerIcon = {
            url: "/assets/svg/ic-location-pin-blue.svg",
            scaledSize: new google.maps.Size(36, 48),
        };
        centerMarker?.setIcon(customMarkerIcon);
        const handleClick = (event) => {
            const clickedLocation = event.latLng;
            const distance = Math.round(google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(latLng.lat, latLng.lng), clickedLocation) * 100) / 100;
            circle?.setRadius(distance);
            onUpdateRadius(selectedCollectionPoint.collectionPointId, distance.toString());
        };
        map.addListener("click", handleClick);
        circle.addListener("click", handleClick);
    };
    return (_jsxs(MainDiv, { children: [_jsx(MapDiv, { ref: mapRef, id: "map" }), _jsxs(InputContainerDiv, { align: "middle", gutter: [0, 5], children: [_jsxs(InputContainer, { xs: 24, lg: 12, children: [_jsx(Text, { name: "Geo Coordinates" }), _jsx(Text, { name: `${latLng.lat}, ${latLng.lng}` })] }), _jsxs(InputContainer, { xs: 24, lg: 9, children: [_jsx(Text, { name: "Radius (approx.)" }), _jsx(Input, { onChange: (event) => {
                                    const newValue = event.target.value;
                                    const parsedValue = Number(newValue);
                                    if (!isNaN(parsedValue)) {
                                        onUpdateRadius(selectedCollectionPoint.collectionPointId, newValue);
                                    }
                                    else {
                                        onUpdateRadius(selectedCollectionPoint.collectionPointId, "0");
                                    }
                                }, value: isNaN(radius) ? 0 : radius }), _jsx(Text, { name: "meters" })] }), _jsx(Col, { xs: 24, lg: 2, children: _jsx(CustomButton, { onClick: onSaveChanges, children: "Save changes" }) })] })] }));
};
export default MapView;
const MainDiv = styled.div `
  position: relative;
  width: 100%;
  height: 500px;
`;
const MapDiv = styled.div `
  height: 100%;
  width: 100%;
`;
const InputContainer = styled(Col) `
  background-color: white;
  border: #1a8cff 3px solid;
  border-radius: 5px;
  padding: 0.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const InputContainerDiv = styled(Row) `
  position: absolute;
  bottom: 20px;
  right: 120px;
  z-index: 1000;
  height: 40px;
  gap: 10px;

  .ant-input {
    width: 60px;
    border: none;
  }

  .ant-typography {
    color: ${SAColourScheme.BLACK} !important;

    &:nth-child(1) {
      font-weight: 700;
    }
  }

  @media screen and (max-width: 996px) {
    position: absolute;
    bottom: 80px;
  }
`;
const CustomButton = styled(Button) `
  text-transform: none;
  color: white;
  background-color: #1a8cff;
  border-radius: 5px;
`;
