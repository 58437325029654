import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Card, Checkbox, Col, Divider, Row } from "antd";
import styled from "styled-components";
import { SAColourScheme } from "src/styles";
import { Text } from "src/components/base";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectAppConfiguration } from "src/pages/PlatformManagementMenuPage/CollectAssistPage/store/selector";
import { getCollectAssistConfiguration } from "src/pages/PlatformManagementMenuPage/CollectAssistPage/store/action";
import { selectTestTypes } from "./store/selector";
import { nonStandardSubstanceMapping } from "./store/typing";
const IGNORE_FIELDS = ["id", "isValid", "testTypeId", "createdAt", "updatedAt", "clientId"];
export const TestOptions = ({ globalSelectionsNonStandard, setGlobalSelectionsNonStandard, isEdit, }) => {
    const dispatch = useAppDispatch();
    const testTypeSubstances = useAppSelector(selectTestTypes);
    const appConfig = useAppSelector(selectAppConfiguration);
    useEffect(() => {
        const loadData = async () => {
            await dispatch(getCollectAssistConfiguration());
        };
        loadData();
    }, [dispatch]);
    const handleSubstanceChange = (substanceKeys, testType) => {
        const newSelection = {
            ...globalSelectionsNonStandard,
            [testType]: substanceKeys,
        };
        setGlobalSelectionsNonStandard(newSelection);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx(Col, { span: 24, children: _jsx("span", { className: "header-text", children: "Drug tests" }) }) }), _jsx(CustomDivider, {}), _jsx(CustomText, { name: "All selected items will be included in the Collect Assist test process for this client." }), _jsx(TestCards, { children: testTypeSubstances
                    .sort((a, b) => a.index - b.index)
                    .map((testType) => (_jsxs(TestCard, { size: "small", title: testType.name, children: [_jsx(CustomText, { name: "Pre-selected" }), _jsx(CustomGroup, { options: Object.entries(testType.preSelectedSubstance)
                                .filter(([key, value]) => value === 1 && !IGNORE_FIELDS.includes(key))
                                .map(([key]) => ({
                                label: nonStandardSubstanceMapping[key] || key.toUpperCase(),
                                value: key,
                                disabled: true,
                            })), value: Object.entries(testType.preSelectedSubstance)
                                .filter(([, value]) => value === 1)
                                .map(([key]) => key) }), appConfig.enableNonStandardAndSynthetics && (_jsxs(React.Fragment, { children: [_jsx(Text, { name: "Select" }), _jsx(CustomGroup, { options: Object.entries(testType.nonStandardSubstance)
                                        .filter(([key, value]) => value === 1 && !IGNORE_FIELDS.includes(key))
                                        .map(([key]) => ({
                                        label: nonStandardSubstanceMapping[key] || key.toUpperCase(),
                                        value: key,
                                    })), defaultValue: globalSelectionsNonStandard[testType.id], value: globalSelectionsNonStandard[testType.id], onChange: (selectedValues) => {
                                        handleSubstanceChange(selectedValues, testType.id);
                                    }, disabled: !isEdit })] }))] }, testType.id))) })] }));
};
const CustomDivider = styled(Divider) `
  border: 1px solid ${SAColourScheme.PLASSMA};
  margin: 5px 0 1rem 0;
`;
const TestCard = styled(Card) `
  height: 100% !important;
  border-radius: 0.5rem;
  border: 1px solid ${SAColourScheme.PLASSMA};
  font-size: 1rem;

  .ant-card-head {
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    background: ${SAColourScheme.PLASSMA};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${SAColourScheme.WHITE};
    font-size: 0.9rem;
  }

  .ant-card-body {
    padding: 0.8rem;
  }
`;
const TestCards = styled.div `
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  column-gap: 3rem;
  row-gap: 2rem;
  margin: 1rem 0;
`;
const CustomGroup = styled(Checkbox.Group) `
  display: flex;
  flex-direction: column;
  //align-items: space-around;
  padding: 0.5rem 0;

  .ant-checkbox-group-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    padding-bottom: 0.5rem;
    column-gap: 1rem;
  }

  &:last-child {
    background: #f7f8f8;
    border-radius: 0.5rem;
  }
  .ant-checkbox-group-item > span {
    justify-content: flex-start;
    margin-right: auto;
  }

  .ant-checkbox-group-item > .ant-checkbox {
    justify-content: flex-end;
    margin-right: 1rem;
  }

  .ant-checkbox-group-item > span:last-of-type {
    width: 100%;
    border-bottom: 1px dashed #7070705c;
    padding: 2px 4px;
    justify-content: flex-end;
  }
`;
const CustomText = styled(Text) `
  color: ${SAColourScheme.BLACK} !important;
`;
