import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Typography } from "src/components/base";
import ColInput from "src/pages/DashboardPage/components/ColInput";
import { RowDataWrapper } from "./index.style";
const { Text } = Typography;
export const RowTotal = ({ data, title, style }) => (_jsx(RowDataWrapper, { children: _jsxs("div", { style: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            ...style,
        }, children: [_jsx(Col, { span: data?.length === 2 ? 12 : 8, children: _jsx("div", { className: "square", children: _jsx(Text, { children: title }) }) }), data?.map((value, idxx) => (_jsx(ColInput, { style: { background: "#FAFAFA" }, className: "col-input", span: data?.length === 2 ? 6 : 4, value: data?.length === 2 && idxx === 1 ? "$ " + value : value }, idxx)))] }) }));
