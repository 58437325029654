import styled from "styled-components";
import { Button, Space, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { SAColourScheme } from "src/constants";
import { Image, SAButton } from "src/components/base";
export const PageWrapper = styled.div `
  background-image: url("/assets/images/background.png");
  background-color: #cccccc;
  height: 100vh;
  background-size: 100vw 101vh;

  .email-input {
    margin-top: 50px;
  }

  .email-input > .input-wrapper {
    border: 2px solid #1a8cff;
    border-radius: 4px;
  }

  .input-wrapper > input.text-input {
    color: #1a8cff;
    font-size: 16px;
    border-radius: 4px;
    background-color: transparent;
  }

  .pwd-input > .input-wrapper {
    border: 1px solid #1a8cff;
  }

  .img-color > path {
    fill: #adb3bc;
  }
`;
const VerifyCodeFormWrapper = styled.div `
  width: 100%;
`;
const NoticeBox = styled.div `
  background: #1a8cff;
  height: 65px;
  border-radius: 4px 4px 0 0;
  align-items: center;
  width: 30rem;
  display: flex;
  justify-content: space-evenly;
`;
const CloseNoticeBoxBtn = styled(Button) `
  background: #ffffff40;
  border: none;
  width: 26px;
  min-width: 26px;
  height: 26px;
`;
const CustomLink = styled(Typography.Link) `
  fontsize: 14px;
  color: white !important;
  text-decoration: underline !important;
`;
const BoxWrapper = styled.div `
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 3px 6px #ffffff29;
  width: 30rem;
  padding: 8% 10%;
  text-align: center;
`;
const TypographyTitle = styled(Typography) `
  font-size: 28px;
  font-weight: bold;
  color: #191345;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
const TypographyText = styled(Typography) `
  font-size: 18px;
  color: #00004c;
  margin-top: 0.2rem;
  text-align: left;
`;
const TypographyErrorText = styled(Typography) `
  font-size: 14px;
  color: #fd0000;
  margin-top: 3px;
  text-align: left;
`;
const TypographyResendCodeCover = styled.div `
  text-align: right;
  margin-top: 32px;
`;
const TypographyResendCode = styled(Typography.Link) `
  color: #4b5767;
  font-size: 15px;
  font-weight: 400;
`;
const TypographyNoticeTitle = styled(Typography) `
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-align: left;
`;
const CustomSpace = styled(Space) `
  display: flex;
  flex-direction: column;
  gap: 0px !important;
  align-items: start;
`;
const WhiteTypography = styled(Typography) `
  color: white;
`;
const TypographySubText = styled(Typography) `
  font-size: 13px;
  color: #4b5767;
  margin-top: 3px;
`;
const TypographySubTextSpan = styled.span `
  color: #5cd22b;
`;
const CheckBoxCover = styled.div `
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  width: 11rem;
`;
const SAButtonCustom = styled(Button) `
  width: 18px;
  height: 18px;
  border: 1px solid #7f8e9d;
  border-radius: 4px;
`;
const SASendBtn = styled(SAButton) `
  background: ${SAColourScheme.NAVY} !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  width: 100%;
  height: 48px;
  font-size: 20px;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 50px;
`;
const SABackBtn = styled(SAButton) `
  margin-top: 27px;
  border: none;
  color: #4b5767 !important;
  font-size: 18px;
  background-color: transparent !important;
  padding: 1rem;
`;
const FormWrapper = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 280px;
  display: flex;
  justify-content: center;
`;
const ImageCover = styled.div `
  padding: 30px 50px;
  height: 14%;
  width: 100%;
`;
const LeftOutlinedCustom = styled(LeftOutlined) `
  color: #2f3337;
  font-weight: bold;
  border: 2px solid #00004c;
  padding: 2px 1px 1px;
`;
const ICLetterCustom = styled(Image) `
  margin-top: -3px;
  padding-left: 1rem;
`;
export { ICLetterCustom, LeftOutlinedCustom, VerifyCodeFormWrapper, NoticeBox, CloseNoticeBoxBtn, CustomLink, BoxWrapper, TypographyTitle, TypographyText, TypographyErrorText, TypographyResendCodeCover, TypographyResendCode, TypographyNoticeTitle, CustomSpace, WhiteTypography, TypographySubText, TypographySubTextSpan, CheckBoxCover, SAButtonCustom, SASendBtn, FormWrapper, SABackBtn, ImageCover, };
