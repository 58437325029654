import styled from "styled-components";
import { SAColourScheme } from "src/constants";
export const AppointmentDetailWrapper = styled.div `
  .span.ant-typography.input-label {
    color: ${SAColourScheme.WHITE};
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 44px;
    border-radius: 4px;
  }
  span.ant-typography.item-title {
    color: white !important;
    font-size: 16px;
    margin-left: 4px;
  }
  .ant-select .ant-select-selector {
    height: 44px !important;
    background-color: ${SAColourScheme.WHITE} !important;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item-explain-error {
    color: red;
    font-size: 12px;
    text-align: right;
  }
  .resend-button {
    width: 190px;
    height: 30px;
    background-color: #01b4d2;
    color: ${SAColourScheme.WHITE};
    border-radius: 4px;
    border: 1px solid ${SAColourScheme.WHITE};
    font-size: 15px;
    text-align: left;
    padding: 4px 25px;
    line-height: 1;
    img {
      float: right;
      margin-top: 5px;
    }
  }
  .cancel-button {
    width: 190px;
    height: 30px;
    color: ${SAColourScheme.WHITE};
    border-radius: 4px;
    border: 1px solid ${SAColourScheme.WHITE};
    font-size: 15px;
    text-align: left;
    padding: 4px 25px;
    line-height: 1;
  }
  .update-button {
    width: 190px;
    height: 30px;
    color: ${SAColourScheme.WHITE};
    border-radius: 4px;
    border: 1px solid ${SAColourScheme.WHITE};
    font-size: 15px;
    text-align: left;
    padding: 4px 25px;
    line-height: 1;
  }
`;
