import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { useAppSelector } from "src/store/hooks";
import { getInClinicAppointmentSummary, getInClinicTestSummary } from "../../store/selector";
import { CustomBarChart } from "./components/CustomBarChart";
import { CustomPieChart } from "./components/CustomPieChart";
import { TestSummaryBox } from "./components/TestSummaryBox";
export const InClinicDashboard = () => {
    const appointmentSummary = useAppSelector(getInClinicAppointmentSummary);
    const testSummary = useAppSelector(getInClinicTestSummary);
    return (_jsxs(InClinicDashboardContainer, { children: [_jsxs(SummaryAppointmentBox, { children: [_jsx(Typography.Title, { level: 5, children: "Appointments" }), _jsxs(GraphContainer, { children: [_jsx(CustomPieChart, { data: appointmentSummary }), _jsx(CustomBarChart, { data: appointmentSummary })] })] }), _jsx(TestSummaryBoxWrapper, { children: _jsx(TestSummaryBox, { data: testSummary }) })] }));
};
const InClinicDashboardContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 1em;
  flex: 2;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
const SummaryAppointmentBox = styled.div `
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;
const GraphContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;
const TestSummaryBoxWrapper = styled.div `
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 40%;
`;
