import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import AuthorityIcon from "src/assets/svg/authorising-authority/ic-authorising-form.svg";
import CommunicationArchiveIcon from "src/assets/svg/authorising-authority/ic-communication-archive.svg";
import CommunicationTemplateIcon from "src/assets/svg/authorising-authority/ic-communication-template.svg";
import EditIcon from "src/assets/svg/authorising-authority/ic-form-edit.svg";
import SignatureVaultIcon from "src/assets/svg/authorising-authority/ic-signature-vault.svg";
import { Button, Form, Input, message, Radio } from "src/components/base";
import SADivider from "src/components/Form/SADivider";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import PageLayoutWithNoTab from "src/components/layouts/PageLayout/PageLayoutWithNoTab";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import RoundHeaderCard from "src/components/RoundHeaderCard";
import { validateMessage } from "src/constants";
import { textPattern } from "src/patterns/patterns";
import ContentContext from "src/providers/content";
import { selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import MainLayout from "src/components/layouts/MainLayout";
import { SAColourScheme } from "src/styles";
import { CommunicationArchive } from "./components/CommunicationArchive";
import { CommunicationTemplates } from "./components/CommunicationTemplates";
import { SignatureVault } from "./components/SignatureVault";
import { getAuthorityInformation, updateAuthorityInformation } from "./store/action";
import { selectAuthorityInformation } from "./store/selector";
import { setResetStore } from "./store/slice";
import { AuthorityProviderType } from "./store/types";
export const AuthorisingAuthorityPage = () => {
    const [form] = Form.useForm();
    const { openPopupBox } = useContext(ContentContext);
    const isLoading = useAppSelector(selectIsLoading);
    const dispatch = useAppDispatch();
    const authorityInformation = useAppSelector(selectAuthorityInformation);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedType, setSelectedType] = useState(AuthorityProviderType.ProviderNumber);
    const isEditing = searchParams.get("isEditing") === "true";
    const handleValuesChange = (_changedValues, values) => {
        const allValues = values;
        const updatedProviderType = allValues.displayProviderType;
        if (updatedProviderType !== undefined) {
            setSelectedType(Number(updatedProviderType));
        }
    };
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            dispatch(setResetStore());
            await dispatch(getAuthorityInformation());
            dispatch(setIsLoading(false));
        };
        loadData();
    }, []);
    useEffect(() => {
        form.setFieldsValue(authorityInformation.data);
        setSelectedType(authorityInformation.data.displayProviderType);
    }, [authorityInformation]);
    return (_jsx(MainLayout, { children: _jsxs(PageLayoutWithNoTab, { location: location.pathname, heading: "Authorising Authority", children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(AuthorisingAuthorityPageWrapper, { children: [_jsx(MainFormContainer, { icon: _jsx(FormIconContainer, { children: _jsx(AuthorityIcon, {}) }), children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsxs(AuthorityInformationContainer, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, validateMessages: validateMessage, onValuesChange: handleValuesChange, onFinishFailed: () => {
                                                message.error("The form has not been finished yet");
                                            }, onFinish: async () => {
                                                dispatch(setIsLoading(true));
                                                await dispatch(updateAuthorityInformation({
                                                    ...form.getFieldsValue(),
                                                }));
                                                dispatch(setIsLoading(false));
                                                setSearchParams({
                                                    isEditing: "false",
                                                });
                                            }, children: [_jsxs(SARow, { children: [_jsx(SAColumn, { span: 14, align: "flex-end", children: _jsx(Heading, { children: "Authorising Authority" }) }), _jsx(SAColumn, { span: 10, align: "flex-end", children: !isEditing ? (_jsx(EditButton, { onClick: () => setSearchParams({
                                                                    isEditing: "true",
                                                                }), icon: _jsx(EditIcon, {}), children: "Edit" })) : (_jsxs(ButtonContainer, { children: [_jsx(EditButton, { danger: true, onClick: () => setSearchParams({
                                                                            isEditing: "false",
                                                                        }), children: "Cancel" }), _jsx(EditButton, { htmlType: "submit", children: "Save" })] })) })] }), _jsxs(SARow, { children: [_jsx(SADivider, { color: "#ffffff" }), _jsx(SAColumn, { span: 4, children: _jsx(CustomFormItem, { label: "Honorific", name: "honorific", rules: [{ required: true, pattern: textPattern }], children: _jsx(StyledInput, { disabled: !isEditing }) }) }), _jsx(SAColumn, { span: 10, children: _jsx(CustomFormItem, { label: "First Name", name: "firstName", rules: [{ required: true, pattern: textPattern }], children: _jsx(StyledInput, { disabled: !isEditing }) }) }), _jsx(SAColumn, { span: 10, children: _jsx(CustomFormItem, { label: "Last Name", name: "lastName", rules: [{ required: true, pattern: textPattern }], children: _jsx(StyledInput, { disabled: !isEditing }) }) })] }), _jsx(SARow, { children: _jsx(SAColumn, { span: 24, children: _jsx(CustomFormItem, { label: "Position", name: "title", rules: [{ required: true, pattern: textPattern }], children: _jsx(StyledInput, { disabled: !isEditing }) }) }) }), _jsx(CustomFormItem, { name: "displayProviderType", rules: [{ required: true }], children: _jsx(RadioGroup, { disabled: !isEditing, children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 14, align: "flex-start", children: _jsx(Radio, { value: AuthorityProviderType.ProviderNumber, children: "Provider Number" }) }), _jsx(SAColumn, { span: 10, align: "flex-start", children: _jsx(Radio, { value: AuthorityProviderType.ABN, children: "ABN" }) })] }) }) }), _jsxs(SARow, { children: [_jsx(SAColumn, { span: 14, children: _jsx(CustomFormItem, { name: "providerNumber", rules: [
                                                                    {
                                                                        required: selectedType === AuthorityProviderType.ProviderNumber,
                                                                    },
                                                                ], children: _jsx(StyledInput, { disabled: !isEditing || selectedType !== AuthorityProviderType.ProviderNumber }) }) }), _jsx(SAColumn, { span: 10, children: _jsx(CustomFormItem, { name: "abn", rules: [
                                                                    {
                                                                        required: selectedType === AuthorityProviderType.ABN,
                                                                    },
                                                                ], children: _jsx(StyledInput, { disabled: !isEditing || selectedType !== AuthorityProviderType.ABN }) }) })] })] }) }), _jsxs(SAColumn, { span: 12, children: [_jsx(SARow, { children: _jsx(SAColumn, { children: _jsxs(Heading, { children: [_jsx(SignatureVaultIcon, {}), " Signature Vault"] }) }) }), _jsx(SADivider, { color: "#ffffff" }), _jsxs(SignatureVaultContainer, { children: [_jsx(CaptionText, { children: "The Signature Vault securely stores your digital signature." }), _jsx(ExplanationText, { children: "Your signature is attached to all communication issued in your name as the Authorised Issuing Authority. Your PIN allows you to secure your signature." }), _jsx(SignatureVault, {})] })] })] }) }), _jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(RoundHeaderCard, { icon: _jsx(CommunicationIconContainer, { children: _jsx(CommunicationArchiveIcon, {}) }), children: _jsx(CommunicationContainer, { onClick: () => {
                                                openPopupBox(_jsx(CommunicationArchive, {}), null, {
                                                    closeIconStyle: {
                                                        left: 16,
                                                        top: 16,
                                                        right: "unset",
                                                        background: "#ffffffd7",
                                                    },
                                                    popupStyle: {
                                                        width: "80%",
                                                    },
                                                });
                                            }, children: _jsx(ArchiveHeading, { children: "Communication Archive" }) }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(RoundHeaderCard, { icon: _jsx(CommunicationIconContainer, { children: _jsx(CommunicationTemplateIcon, {}) }), children: _jsx(CommunicationContainer, { onClick: () => openPopupBox(_jsx(CommunicationTemplates, {}), null, {
                                                closeIconStyle: {
                                                    left: 16,
                                                    top: 16,
                                                    right: "unset",
                                                    background: "#ffffffd7",
                                                },
                                                popupStyle: {
                                                    width: "80%",
                                                },
                                            }), children: _jsx(TemplateHeading, { children: "Communication Template" }) }) }) })] })] })] }) }));
};
const AuthorisingAuthorityPageWrapper = styled.div `
  padding: 1rem 2rem;
  background-color: white;

  .ant-input[disabled] {
    color: ${SAColourScheme.BLACK} !important;
  }

  .ant-radio-disabled .ant-radio-inner::after {
    background-color: #1a8cff !important;
  }
`;
const AuthorityInformationContainer = styled(Form) `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0 !important;
`;
const SignatureVaultContainer = styled(SARow) `
  width: 100%;
  height: 100%;

  gap: 1rem;
`;
const CommunicationContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7rem;
  border: 0.25rem solid #01b4d2;
  border-radius: 0.5rem;
`;
const CommunicationIconContainer = styled.div `
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.25rem solid #01b4d2;
  border-radius: 3rem;
`;
const MainFormContainer = styled(RoundHeaderCard) `
  width: 100%;
  min-height: 25rem;
  background-color: #156cc9;
  padding: 1rem;
  margin-bottom: 2rem;
`;
const FormIconContainer = styled.div `
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.25rem solid #156cc9;
  border-radius: 3rem;
`;
const StyledInput = styled(Input) `
  width: 100%;
  background-color: ${SAColourScheme.WHITE};
  &:disabled {
    background-color: #e4f6fb !important;
    cursor: not-allowed !important;
  }
`;
const CustomFormItem = styled(SAFormItem) `
  label {
    color: #ffffff !important;
  }
`;
const EditButton = styled(Button) `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
`;
const Heading = styled.h3 `
  color: #ffffff !important;
  font-size: 1.5rem;
  margin: 0 !important;
  font-weight: 700;
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;
const CaptionText = styled.p `
  color: #ffffff !important;
  font-size: 1.2rem;
`;
const ExplanationText = styled.p `
  color: #000000 !important;
  font-size: 1rem;
`;
const ArchiveHeading = styled.h3 `
  color: #0047b3;
  font-size: 2rem;
  margin: 0 !important;
  font-weight: 700;
`;
const TemplateHeading = styled.h3 `
  color: #179ab1;
  font-size: 2rem;
  margin: 0 !important;
  font-weight: 700;
`;
const RadioGroup = styled(Radio.Group) `
  width: 100%;
  label,
  .ant-radio-disabled + span {
    color: #ffffff !important;
  }
`;
