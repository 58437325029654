import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table, Image, Select } from "antd";
import { useState, useEffect } from "react";
import { SelectButton, Text } from "src/components/base";
import { PageWrapper } from "./index.style";
const { Option } = Select;
export const SATable = (props) => {
    const [lastSelectField, setLastSelectField] = useState("");
    const [orderField, setOrderField] = useState("");
    const [isDescending, setIsDescending] = useState(false);
    const datax = props.dataSource;
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const { searchString } = props;
    const { searchColumns } = props;
    const sorting = (field) => {
        const fieldName = field;
        setLastSelectField(fieldName);
        if (fieldName !== lastSelectField) {
            setIsDescending(false);
        }
        else {
            setIsDescending(!isDescending);
        }
        setOrderField(fieldName);
    };
    useEffect(() => {
        setData(datax);
        setOriginalData(datax);
    }, [datax]);
    useEffect(() => {
        const newSortData = data.sort((a, b) => {
            const firstField = a?.[orderField]?.toLowerCase() || "";
            const secondField = b?.[orderField]?.toLowerCase() || "";
            if (isDescending) {
                return firstField > secondField ? -1 : 1;
            }
            else {
                return firstField < secondField ? -1 : 1;
            }
            return 0;
        });
        setData(newSortData.map((obj, index) => ({
            ...obj,
            number: (index + 1),
            key: obj.key,
        })));
    }, [orderField, isDescending]);
    useEffect(() => {
        if (searchColumns && searchColumns.length) {
            const newFilterData = originalData.filter((obj) => {
                let result = false;
                for (const iterator of searchColumns) {
                    if (obj[iterator]?.toUpperCase().includes(searchString.toUpperCase())) {
                        result = true;
                        break;
                    }
                }
                return result;
            });
            setData(newFilterData.map((obj, index) => ({
                ...obj,
                number: index + 1,
                key: obj.key,
            })));
        }
    }, [searchString]);
    const columns = [];
    props.columns.forEach((element) => {
        const { dataIndex } = element;
        const obj = element.titleType == "button"
            ? {
                title: () => (_jsx(SelectButton, { className: "sort-btn", onClick: () => sorting(dataIndex), name: element.title, orderField: orderField, fieldName: dataIndex, isDescending: isDescending })),
                dataIndex: dataIndex,
                key: dataIndex,
                width: element.width,
                className: element.display ? `${dataIndex}-column` : `${dataIndex}-column-none`,
                render: element.render,
            }
            : {
                title: element.title,
                dataIndex: dataIndex,
                key: dataIndex,
                width: element.width,
                className: element.display ? `${dataIndex}-column` : `${dataIndex}-column-none`,
                render: element.render,
            };
        columns.push(obj);
    });
    const itemRender = (current, type, originalElement) => {
        if (type === "prev") {
            return (_jsx("div", { children: _jsx(Image, { src: "/assets/images/double-arrow-back-box-gray-icon.png", preview: false, height: 10, width: 10, className: "prev-icon" }) }));
        }
        if (type === "next") {
            return (_jsx("div", { children: _jsx(Image, { src: "/assets/images/double-arrow-forth-box-gray-icon.png", preview: false, height: 10, width: 10, className: "next-icon" }) }));
        }
        return originalElement;
    };
    const [pagination, setPagination] = useState({
        defaultPageSize: 10,
        pageSize: 10,
        total: data.length,
        itemRender: itemRender,
        pageSizeOptions: [10, 15, 20],
    });
    const [pageSize, setPageSize] = useState(10);
    const select = (size) => {
        setPagination({ ...pagination, pageSize: size });
        setPageSize(size);
    };
    return (_jsxs(PageWrapper, { className: props.className, children: [_jsx(Table, { columns: columns, dataSource: data, 
                // scroll={{ y: 500 }}
                size: "middle", pagination: pagination }), _jsxs("div", { style: {
                    position: "absolute",
                    marginTop: "-30px",
                    marginLeft: "55px",
                    display: data.length < 9 ? "none" : "block",
                }, children: [_jsx(Text, { name: "Show", style: {
                            fontSize: "16px",
                            color: "#367893",
                            marginRight: "7px",
                        } }), _jsxs(Select, { value: pageSize, onSelect: select, style: {
                            border: "1px solid #EBEDF0",
                            borderRadius: "2px",
                            width: "70px",
                            height: "28px",
                            fontSize: "16px",
                            color: "#367893",
                        }, suffixIcon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false }), children: [_jsx(Option, { value: 10, children: "10" }), _jsx(Option, { value: 15, children: "15" }), _jsx(Option, { value: 20, children: "20" })] })] })] }));
};
