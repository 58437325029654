const selectPagingMetaData = (state) => state.jobManagementPage.pagingMetaData;
const selectPagingMetaDataArchive = (state) => state.jobManagementPage.pagingMetaDataArchive;
const selectListActiveJobs = (state) => state.jobManagementPage.listActiveJobs;
const selectListArchiveJobs = (state) => state.jobManagementPage.listArchiveJobs;
const selectIsArchiveStatus = (state) => state.jobManagementPage.isArchived;
const selectListClients = (state) => state.jobManagementPage.listClients;
const selectListCollectionPointDefaultData = (state) => state.jobManagementPage.listCollectionPointDefaultData;
const selectClientDetails = (state) => state.jobManagementPage.selectedClientDetails;
const selectCreatingJobDetails = (state) => state.jobManagementPage.jobSetupDetails;
const selectExistedJobDetail = (state) => state.jobManagementPage.existedJobData;
const selectedStartDate = (state) => state.jobManagementPage.startDate;
const selectedEndDate = (state) => state.jobManagementPage.endDate;
const listStatusTypes = (state) => state.jobManagementPage.listStatusTypes;
const selectedStatusTypes = (state) => state.jobManagementPage.selectedStatusType;
const selectCurrentCollectionPointDefaultData = (state) => {
    const { listCollectionPointDefaultData, jobSetupDetails } = state.jobManagementPage;
    return listCollectionPointDefaultData.find((data) => data.id === jobSetupDetails.data.collectionPointId);
};
const selectCurrentCollectionPoint = (state) => {
    const currentCollectionPoint = state.jobManagementPage.selectedCollectionPoint;
    return {
        name: currentCollectionPoint.collectionPointName,
        value: currentCollectionPoint.id,
    };
};
const selectDropdownClient = (state) => {
    const currentCollectionPoint = state.jobManagementPage.selectedClient;
    return {
        name: currentCollectionPoint.name,
        value: currentCollectionPoint.id,
    };
};
const selectOnSiteJobId = (state) => state.jobManagementPage.selectedOnSiteJobId;
export { selectPagingMetaData, selectListActiveJobs, selectListArchiveJobs, selectIsArchiveStatus, selectListClients, selectListCollectionPointDefaultData, selectClientDetails, selectCreatingJobDetails, selectedStartDate, selectedEndDate, listStatusTypes, selectedStatusTypes, selectCurrentCollectionPointDefaultData, selectExistedJobDetail, selectCurrentCollectionPoint, selectOnSiteJobId, selectDropdownClient, selectPagingMetaDataArchive, };
