import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { Select, Typography } from "antd";
const LegalHeader = (props) => {
    const [language, setLanguage] = React.useState("english");
    const handleChange = (value) => {
        setLanguage(value);
    };
    return (_jsxs(React.Fragment, { children: [_jsxs(TileCover, { children: [props.iconSrc, props.appNameSrc] }), _jsx(LegalTypo, { children: props.title }), _jsx(HorizontalCoverEnd, { children: _jsxs(LanguageCover, { children: [_jsx(Typography, { children: "Language:" }), _jsx(Select, { value: language, onChange: handleChange, defaultValue: "english", options: [
                                {
                                    value: "english",
                                    label: "English",
                                },
                            ] })] }) }), _jsx(InstructionTypo, { children: "Please read before continuing" })] }));
};
export default LegalHeader;
const TileCover = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  height: 140px;
  background-color: #00004c;
  gap: 1rem;

  img {
    margin-right: 1rem;
  }
`;
const HorizontalCoverEnd = styled.div `
  display: flex;
  align-items: center;
  gap: 0.2rem;
  justify-content: flex-end;
`;
const LanguageCover = styled.div `
  display: flex;
  align-items: center;
  width: 60%;
  font-size: 14px;
  justify-content: end;
  margin-right: 0.5rem;
  gap: 0.25em;

  .ant-select {
    padding: 0.5em;
    width: 50% !important;
  }

  @media (min-width: 768px) {
    width: 30%;
  }

  @media (min-width: 1024px) {
    width: 20%;
  }
`;
const LegalTypo = styled(Typography) `
  background-color: #01b4d2;
  color: white;
  font-size: 20px;
  padding: 1rem;
  font-weight: 700;
  text-align: center;
`;
const InstructionTypo = styled(Typography) `
  font-size: 18px;
  font-weight: 700;
  color: #1a8cff;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;
