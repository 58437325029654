import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Radio, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { labelSettings } from "src/constants";
import { mainAppColours, supportingColours } from "src/styles";
const LabelSetting = () => {
    const navigate = useNavigate();
    return (_jsxs(PaperWrapper, { children: [_jsxs(HeadBarWrapper, { children: [_jsxs(ButtonWrapper, { children: [_jsx(BackButton, { onClick: () => navigate(-1), icon: _jsx(LeftOutlined, {}) }), _jsx(Title, { children: "Label Settings" })] }), _jsxs(ButtonWrapper, { children: [_jsx(CancelButton, { danger: true, children: "Cancel" }), _jsx(SaveButton, { type: "primary", children: "Save" })] })] }), _jsx(StyledDivider, {}), _jsx(SettingsWrapper, { children: labelSettings.map((item, index) => (_jsxs(StyledCard, { children: [_jsx(StyledTypography, { children: item.title }), _jsxs(ContentWrapper, { children: [item.criteria.map((crit, idx) => (_jsxs(React.Fragment, { children: [_jsx(Typography, { children: crit.instruction }), _jsx(StyledRadioGroup, { criteria: crit, children: crit.options.map((option, optionIdx) => (_jsx(Radio, { value: option.id, children: _jsx(RadioTypographyCover, { children: option.labels.map((label, labeIdx) => (_jsx(Typography, { children: label.label }, labeIdx))) }) }, optionIdx))) })] }, idx))), _jsx(Radio.Group, {})] })] }, index))) })] }));
};
export default LabelSetting;
const HeadBarWrapper = styled.div `
  display: flex;
  gap: 10px;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
`;
const CancelButton = styled(Button) `
  text-transform: none;
  align-self: flex-end;
  width: 114px;
  border: 1px solid ${supportingColours.BOX_GRAY};
  border-radius: 2px;
  color: #fd0000;
`;
const SaveButton = styled(Button) `
  text-transform: none;
  align-self: flex-end;
  width: 114px;
  border: 1px;
  border-radius: 2px;
  background-color: ${mainAppColours.MID_BLUE};
`;
const PaperWrapper = styled.div `
  background-color: #fafafa;
  padding: 2rem 3rem;
  width: 100%;
  gap: 10px;
  height: 100%;
`;
const SettingsWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const StyledCard = styled(Card) `
  width: 100%;
  border-radius: 10px;
  padding: 1.5rem;

  .ant-card-body {
    display: flex !important;
  }
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const StyledTypography = styled(Typography.Text) `
  width: 30%;
  font-size: 1.2rem;
`;
const StyledRadioGroup = styled(Radio.Group) `
  display: grid;
  grid-auto-flow: ${(props) => (props.criteria.options.length % 2 === 0 ? "row" : "column")};
  grid-template-columns: ${(props) => props.criteria.options.length % 2 === 0 ? "repeat(2, 1fr)" : "auto"};
  gap: 2rem;
  margin-top: 0.8rem;
`;
const ButtonWrapper = styled.div `
  display: flex;
  gap: 10px;
`;
const RadioTypographyCover = styled(Typography) `
  background-color: #f7f8f8;
  padding: 0.5rem;
  border-radius: 10px;
`;
const Title = styled(Typography) `
  color: ${supportingColours.TEXT_MID_GRAY};
  font-weight: 400;
  font-size: 20px;
`;
const BackButton = styled(Button) `
  border: none;
  background-color: transparent;
`;
const StyledDivider = styled(Divider) `
  width: 100%;
  color: #adb3bc;
`;
