import { combineReducers } from "redux";
import clientNotesPageReducer from "src/pages/ClientManagement/components/NewClient/components/Notes/store/slice";
import clientRepresentativePageReducer from "src/pages/ClientManagement/components/NewClient/components/AuthorisedRep/store/slice";
import clientJobHistoryPageReducer from "src/pages/ClientManagement/components/NewClient/components/JobHistory/store/slice";
import clientLocationsPageReducer from "src/pages/ClientManagement/components/NewClient/components/ClientLocations/store/slice";
import clientDetailsPageReducer from "src/pages/ClientManagement/components/NewClient/components/ClientDetails/store/slice";
import clientSpecificationsPageReducer from "src/pages/ClientManagement/components/NewClient/components/ClientSpecifications/store/slice";
const clientManagementPageReducer = combineReducers({
    clientNotesPage: clientNotesPageReducer,
    clientRepresentativePage: clientRepresentativePageReducer,
    clientJobHistoryPage: clientJobHistoryPageReducer,
    clientLocationsPage: clientLocationsPageReducer,
    clientDetailsPage: clientDetailsPageReducer,
    clientSpecificationsPage: clientSpecificationsPageReducer,
});
export default clientManagementPageReducer;
