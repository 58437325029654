import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
const BriefContent = (props) => (_jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, align: "flex-start", justify: "flex-start", children: _jsx(ReportLabel, { children: props.name }) }), _jsx(SAColumn, { span: 12, align: "flex-start", justify: "flex-start", children: _jsx(ReportLabelText, { children: props.content }) })] }));
export default BriefContent;
const ReportLabel = styled.div `
  font-weight: 700;
  font-size: 0.875rem;
  color: #4c859f;
  word-wrap: break-word;
  white-space: normal;
  width: 100%;
`;
const ReportLabelText = styled.p `
  font-size: 0.875rem;
  width: 100%;
  word-wrap: break-word;
  white-space: normal;
`;
