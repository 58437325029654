import styled from "styled-components";
export const Wrapper = styled.div `
  cursor: pointer;
  display: inline-block;

  .button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
  }
`;
