import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "antd";
import * as React from "react";
import styled from "styled-components";
import ICBatchSuccess from "/src/assets/svg/collection-reports/success-icon.svg";
import ICBatchWarning from "/src/assets/svg/collection-reports/send-by-collector-icon.svg";
import ICBatchError from "/src/assets/svg/collection-reports/error-icon.svg";
import { CollectionReportHistoryStatus } from "../store/types";
import { CollectionReportStatusDetails } from "../typing";
const renderText = (status) => {
    switch (status) {
        case CollectionReportHistoryStatus.Creating: {
            return (_jsxs(InfoText, { children: [_jsx(BoldText, { children: "Send by Collector" }), " The Collection Batch Results Report will be sent by Collector at the end of job."] }));
        }
        case CollectionReportHistoryStatus.Pending: {
            return (_jsxs(InfoText, { children: [_jsx(BoldText, { children: "Send by Collector" }), " The Collection Batch Results Report will be sent by Collector at the end of job."] }));
        }
        case CollectionReportHistoryStatus.Success: {
            return _jsx(InfoText, { children: "Successfully sent the Batch Results Report" });
        }
        case CollectionReportHistoryStatus.Error: {
            return (_jsxs(InfoText, { children: [_jsx(BoldText, { children: "ERROR!" }), " If the collector is having trouble sending the report, you can either click", _jsx(CustomLink, { children: _jsx(BoldText, { children: "\u2018Resend Now\u2019" }) }), "or contact our ", _jsx(CustomLink, { children: "Customer Support" }), " for assistance."] }));
        }
        default:
            return _jsx(_Fragment, {});
    }
};
const renderIndicatorIcon = (status) => {
    switch (status) {
        case CollectionReportHistoryStatus.Creating: {
            return _jsx(ICBatchWarning, {});
        }
        case CollectionReportHistoryStatus.Pending: {
            return _jsx(ICBatchWarning, {});
        }
        case CollectionReportHistoryStatus.Success: {
            return _jsx(ICBatchSuccess, {});
        }
        case CollectionReportHistoryStatus.Error: {
            return _jsx(ICBatchError, {});
        }
        default:
            return _jsx(_Fragment, {});
    }
};
const StatusIndicator = ({ status }) => {
    if (!status)
        return _jsx(_Fragment, {});
    return (_jsxs(StatusIndicatorContainer, { children: [_jsx(StatusButton, { color: CollectionReportStatusDetails[status]?.color, children: renderIndicatorIcon(status) }), renderText(status)] }));
};
export default StatusIndicator;
const StatusIndicatorContainer = styled.div `
  background-color: #e6f7fa;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  height: 4rem;
`;
const StatusButton = styled(Button) `
  background-color: ${({ color }) => color ?? "#ffffff"};
  padding: 1rem;
  height: 100%;
  width: 4rem;
`;
const InfoText = styled.div `
  color: #000000;
  font-size: 0.875rem;
  display: inline-flex;
`;
const BoldText = styled.p `
  font-size: 1rem;
  font-weight: 700;
  margin-inline: 1rem;
`;
const CustomLink = styled.a `
  margin-inline: 1rem;
`;
