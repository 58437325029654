/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { $get, $post, $put } from "src/infra/http";
const BASE_URL = "appointment";
const createAppointment = createAsyncThunk(`${BASE_URL}/create`, async (body) => await $post(`/collection-manager/${BASE_URL}/create`, body));
const cancelAppointment = createAsyncThunk(`${BASE_URL}/cancel`, async (appointmentId) => await $get(`/collection-manager/${BASE_URL}/cancel/${appointmentId}`, {}));
const restoreAppointment = createAsyncThunk(`${BASE_URL}/restore`, async (appointmentId) => await $get(`/collection-manager/${BASE_URL}/restore/${appointmentId}`, {}));
const resendEmailAppointment = createAsyncThunk(`${BASE_URL}/resend-email`, async (appointmentId) => await $get(`/collection-manager/${BASE_URL}/resend-email/${appointmentId}`, {}));
const updateAppointment = createAsyncThunk(`${BASE_URL}/update`, async ({ appointmentId, body }) => await $put(`/collection-manager/${BASE_URL}/update/${appointmentId}`, body));
const updateTimeAppointment = createAsyncThunk(`${BASE_URL}/update-time`, async ({ appointmentId, body }) => await $put(`/collection-manager/${BASE_URL}/update-time/${appointmentId}`, body));
const getAppointmentFromTime = createAsyncThunk(`${BASE_URL}/`, async (startAt) => await $get(`/collection-manager/${BASE_URL}/`, {
    startAt,
}));
const getAppointmentById = createAsyncThunk(`${BASE_URL}/id`, async (appointmentId) => await $get(`/collection-manager/${BASE_URL}/${appointmentId}`, {}));
export { cancelAppointment, createAppointment, getAppointmentById, getAppointmentFromTime, resendEmailAppointment, restoreAppointment, updateAppointment, updateTimeAppointment, };
