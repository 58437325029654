/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { $post } from "src/infra/http";
import { CalendarView } from "../typing";
const getListOnsiteJobs = createAsyncThunk("getListOnsiteJobs", async (_, thunkApi) => {
    const { selectedCollectionPoint, currentDate, currentView } = thunkApi.getState().calendarPage;
    let startDay = moment(currentDate);
    let lastDay = moment(currentDate);
    if (currentView !== CalendarView.Day) {
        startDay = startDay.clone().startOf(currentView);
        lastDay = startDay.clone().endOf(currentView);
    }
    const requestData = {
        collectionPointId: selectedCollectionPoint?.id,
        firstDay: startDay.format("YYYY-MM-DD"),
        lastDay: lastDay.format("YYYY-MM-DD"),
    };
    return await $post(`/collection-manager/calendar/onsite`, requestData);
});
export { getListOnsiteJobs };
