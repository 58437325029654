/* eslint-disable no-console */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { $get, $post, $put } from "src/infra/http";
const getListActiveJobs = createAsyncThunk("getListActiveJobs", async ({ newPage = 1, size = 10, field, order } = {}, thunkApi) => {
    const state = thunkApi.getState().jobManagementPage;
    const collectionPointId = state.selectedCollectionPoint.id;
    const clientId = state.selectedClient.id;
    const selectedStatus = state.selectedStatusType;
    const startAt = state.startDate;
    const endAt = state.endDate;
    const queryString = stringify({
        collectionPointId,
        field,
        order,
        page: newPage,
        limit: size,
        clientId,
        selectedStatus,
        startAt: startAt && startAt.toDate(),
        endAt: endAt && endAt.toDate(),
    });
    return await $get(`/collection-manager/job-management/list?${queryString}`);
});
const getListArchiveJobs = createAsyncThunk("getListArchiveJobs", async ({ newPage = 1, size = 10, field, order } = {}, thunkApi) => {
    const state = thunkApi.getState().jobManagementPage;
    const collectionPointId = state.selectedCollectionPoint.id;
    const queryString = stringify({
        collectionPointId,
        isArchived: true,
        field,
        order,
        page: newPage,
        limit: size,
    });
    return await $get(`/collection-manager/job-management/list?${queryString}`);
});
const getCollectionPointDefaultData = createAsyncThunk("getCollectionPointDefaultData", async () => await $get("/collection-manager/job-management/points"));
const getListClients = createAsyncThunk("getListClients", async () => {
    const resposne = await $get(`/collection-manager/job-management/clients`);
    console.log(resposne);
    return resposne;
});
const getClientDetail = createAsyncThunk("job/getClientDetail", async (onsiteClientId) => await $get(`/collection-manager/job-management/client/${onsiteClientId}`));
const getClientDetailInitial = createAsyncThunk("getClientDetailInitial", async (onsiteClientId) => await $get(`/collection-manager/job-management/client/${onsiteClientId}`));
const createOnsiteJob = createAsyncThunk("createOnsiteJob", async (data) => await $post(`/collection-manager/job-management`, data));
const createDraftOnsiteJob = createAsyncThunk("createDraftOnsiteJob", async (data) => await $post(`/collection-manager/job-management/draft`, data));
const updateDraftOnsiteJob = createAsyncThunk("updateDraftOnsiteJob", async (data) => await $put(`/collection-manager/job-management/draft`, data));
const updateOnsiteJob = createAsyncThunk("updateOnsiteJob", async (data) => await $put(`/collection-manager/job-management`, data));
const cancelJob = createAsyncThunk("cancelJob", async (onsiteJobId) => await $get(`/collection-manager/job-management/cancel/${onsiteJobId}`));
const restoreJob = createAsyncThunk("restoreJob", async (onsiteJobId) => await $get(`/collection-manager/job-management/restore/${onsiteJobId}`));
const archiveJob = createAsyncThunk("archiveJob", async (onsiteJobId) => await $get(`/collection-manager/job-management/archive/${onsiteJobId}`));
const getJobDetail = createAsyncThunk("getJobDetail", async (onsiteJobId) => await $get(`/collection-manager/job-management/detail/${onsiteJobId}`));
export { getListActiveJobs, getListArchiveJobs, getListClients, getClientDetail, getCollectionPointDefaultData, createOnsiteJob, archiveJob, getJobDetail, getClientDetailInitial, updateOnsiteJob, createDraftOnsiteJob, updateDraftOnsiteJob, cancelJob, restoreJob, };
