import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ContentContext from "src/providers/content";
import { HeaderFilter } from "./components/HeaderFilter";
import { RecordsTable } from "./components/RecordsTable";
import { getAllOnsiteDonorRecords, getListClients } from "./store/action";
export const OnSiteRecordsPage = () => {
    const { openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const [patientRecordsData, setPatientRecordsData] = useState([]);
    const [clientData, setClientData] = useState();
    const [selectedClient, setSelectedClient] = useState("All");
    const [searchString, setSearchString] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const getData = async () => {
        const result = await getAllOnsiteDonorRecords();
        if (result.status === "success") {
            setPatientRecordsData(result.data);
        }
        else {
            message.error("Failed to fetch Donor Records");
        }
    };
    const getClientsList = async () => {
        const result = await getListClients();
        if (result.status === "success") {
            setClientData(result.data);
        }
        else {
            message.error("Failed to Client list");
        }
    };
    useEffect(() => {
        openLoadingScreen();
        Promise.all([getData(), getClientsList()]).then(() => closeLoadingScreen());
    }, []);
    return (_jsxs(React.Fragment, { children: [_jsx(HeaderFilter, { onSearchString: setSearchString, clientData: clientData, setSelectedClient: setSelectedClient }), _jsx(RecordsTable, { searchString: searchString, patientRecordsData: patientRecordsData, page: 1, selectedClient: selectedClient, pageSize: pageSize, setPageSize: setPageSize })] }));
};
