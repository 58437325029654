import styled from "styled-components";
import { SAColourScheme } from "src/constants";
export const DashboardPageWrapper = styled.div `
  background: #f5f6f8;
  padding: 38px 53px 50px 39px;
  min-height: 79vh;

  button {
    text-transform: none;
    color: ${SAColourScheme.MID_BLUE};
  }

  .category-btn-group {
    display: flex;
    gap: 10px;

    button {
      text-transform: none;
      color: ${SAColourScheme.MID_BLUE};
    }
  }

  .filter-header {
    display: flex;
    justify-content: space-between;
  }

  .action-btn-group {
    display: flex;
    gap: 10px;

    button {
      text-transform: none;
      color: ${SAColourScheme.PLASMA};
    }
  }

  .chart-section {
    display: flex;
    gap: 10px;
    margin-top: 25px;

    .header {
      font-size: 15px;
      font-weight: 700;
      color: #00004c;
      margin-bottom: 5px;
    }

    .layout {
      background-color: white;
      padding: 20px 15px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
    }
  }

  .daily-summary-section {
    width: 30%;

    .ant-progress-inner {
      border-radius: 0px !important;
    }

    .ant-progress-bg {
      height: 18px !important;
    }
  }

  .test-charts-section {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
  }

  .circle {
    border-radius: 50%;
    height: 1em;
    width: 1em;
  }

  .square {
    height: 2rem;
    width: 100%;
  }
`;
