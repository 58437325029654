import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Col, Row } from "src/components/base";
import { organizationRole } from "src/constants";
import { PracticeForm } from "./PracticeForm";
import { PrincipalForm } from "./PrincipalForm";
export const PrincipalAndPracticeWrapper = (props) => {
    const { adminInfo } = props;
    const [managers, setManagers] = useState([]);
    const [owners, setOwners] = useState([]);
    const getData = async () => {
        const service_manager_list = adminInfo
            .flat()
            .filter((item) => item.permission == organizationRole.SERVICE_MANAGER ||
            item.permission == organizationRole.SERVICE_MANAGER_V2);
        const service_owner_list = adminInfo
            .flat()
            .filter((item) => item.permission == organizationRole.SERVICE_OWNER);
        setManagers(service_manager_list);
        setOwners(service_owner_list);
    };
    useEffect(() => {
        getData();
    }, [adminInfo]);
    return (_jsx("div", { style: {
            backgroundColor: "#E4F6FB",
            borderRadius: 8,
            padding: 16,
            paddingTop: 0,
            marginTop: 10,
        }, children: _jsxs(Row, { gutter: [20, 10], children: [_jsx(Col, { xs: 24, lg: 12, children: owners.map((principal, index) => (_jsx(PrincipalForm, { index: index, principal: principal, isLast: index === owners.length - 1 }, index))) }), _jsx(Col, { xs: 24, lg: 12, children: managers.map((practice, index) => (_jsx(PracticeForm, { index: index, practice: practice, isLast: index === managers.length - 1 }, `practice-${index}`))) })] }) }));
};
