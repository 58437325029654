import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dropdown, Row, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Image, Table, Text } from "src/components/base";
import { convertMinutesToHoursAndMinutes } from "src/utils/helper";
import styled from "styled-components";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
const { Option } = Select;
export const TableRecords = ({ workRecordData, selectedClient, page, pageSize, setPageSize, }) => {
    const location = useLocation();
    const pathName = location.pathname;
    if (selectedClient.value === "") {
        // eslint-disable-next-line no-param-reassign, no-self-assign
        workRecordData = workRecordData;
    }
    else {
        // eslint-disable-next-line no-param-reassign
        workRecordData = workRecordData.filter((item) => item.clientId.includes(selectedClient.value));
    }
    const columns = [
        {
            title: "Collector",
            dataIndex: "collectorName",
            key: "collectorName",
            sorter: (a, b) => a.collectorName.localeCompare(b.collectorName),
        },
        {
            title: "Service Office",
            dataIndex: "serviceLocation",
            key: "serviceLocation",
            sorter: (a, b) => a.serviceLocation.localeCompare(b.serviceLocation),
        },
        {
            title: "Client",
            dataIndex: "clientName",
            key: "clientName",
            sorter: (a, b) => a.clientName.localeCompare(b.clientName),
        },
        {
            title: "Job Reference",
            dataIndex: "jobReference",
            key: "jobReference",
            sorter: (a, b) => a.jobReference.localeCompare(b.jobReference),
        },
        {
            title: "Clock On",
            dataIndex: "clockOnAt",
            key: "clockOnAt",
            render: (_, record) => {
                const date = moment(new Date(record.clockOnAt)).format("hh:mm a");
                return _jsx("span", { children: date });
            },
        },
        {
            title: "Clock Off",
            dataIndex: "clockOffAt",
            key: "clockOffAt",
            render: (_, record) => {
                const date = record.clockOffAt !== null
                    ? moment(new Date(record.clockOffAt)).format("hh:mm a")
                    : "Did not clock off";
                return _jsx("span", { children: date });
            },
        },
        {
            title: "Total Hours",
            dataIndex: "totalHour",
            key: "totalHour",
            render: (_, record) => {
                if (record.totalHour === null) {
                    return "Not Available";
                }
                return convertMinutesToHoursAndMinutes(record.totalHour);
            },
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => {
                const menuItems = [
                    {
                        key: "view-work-record",
                        label: (_jsx(Link, { to: `${pathName}/work-records/`, state: record.collectorUserId, children: "Work Records" }, `view-work-record-${record.id}`)),
                    },
                    {
                        key: "view-collector-profile",
                        label: (_jsx(Link, { to: `/platform-management/collect-assist/collectors/${record.collectorId}/profile`, children: "Collector Details" }, `view-collector-${record.collectorId}`)),
                    },
                ];
                return (_jsx(Dropdown, { menu: { items: menuItems }, trigger: ["click"], placement: "bottomRight", children: _jsx(ICMenuOptions, {}) }));
            },
        },
    ];
    const itemRender = (current, type, originalElement) => {
        if (type === "prev") {
            return (_jsx("div", { children: _jsx(Image, { src: "/assets/images/double-arrow-back-box-gray-icon.png", preview: false, height: 10, width: 10, className: "prev-icon" }) }));
        }
        if (type === "next") {
            return (_jsx("div", { children: _jsx(Image, { src: "/assets/images/double-arrow-forth-box-gray-icon.png", preview: false, height: 10, width: 10, className: "next-icon" }) }));
        }
        return originalElement;
    };
    const [pagination, setPagination] = useState({
        defaultPageSize: 10,
        pageSize: 10,
        total: workRecordData.length,
        itemRender: itemRender,
        pageSizeOptions: [10, 15, 20],
        page: page,
    });
    const select = (selectedPageSize) => {
        setPagination({ ...pagination, pageSize: selectedPageSize });
        setPageSize(selectedPageSize);
    };
    return (_jsxs(PageWrapper, { children: [_jsx(Table, { columns: columns, dataSource: workRecordData, 
                // scroll={{x: 900}}
                size: "middle", pagination: pagination }), workRecordData.length > 0 && (_jsxs(Row, { style: { position: "absolute", marginTop: "-50px", marginLeft: "30px" }, children: [_jsx(StyledText, { name: "Show" }), _jsxs(Select, { className: "styled-select", value: pageSize, onSelect: select, suffixIcon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false }), children: [_jsx(Option, { value: 10, children: "10" }), _jsx(Option, { value: 15, children: "15" }), _jsx(Option, { value: 20, children: "20" })] })] }))] }));
};
const PageWrapper = styled.div `
  border-top: 1px solid #ffffffb3;
  overflow: auto;
  position: relative;
  padding: 1rem 0;

  .ant-table table {
    width: 100% !important;
    border-top: 1px solid #adb3bc;
    border-bottom: 1px solid #adb3bc;
  }

  .ant-table-thead > tr > th {
    background: #e6f7fa !important;
  }

  .ant-table-pagination.ant-pagination {
    margin-right: 30px;
  }
  .ant-pagination.mini .ant-pagination-prev {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }
  .ant-pagination.mini .ant-pagination-next {
    border: 1px solid #ebedf0;
    margin-left: 14px !important;
    height: 28px;
    width: 28px;
  }
  li.ant-pagination-item {
    border: 1px solid #ebedf0 !important;
    margin-left: 14px !important;
    margin-right: 14px !important;
    width: 28px !important;
    height: 28px !important;
  }
  li.ant-pagination-item a {
    color: #367893;
    font-size: 16px;
    padding-top: 3px;
  }
  li.ant-pagination-item.ant-pagination-item-active {
    background: #adb3bc;
  }
  li.ant-pagination-item.ant-pagination-item-active a {
    color: white;
  }

  .styled-select {
    border: 1px solid #ebedf0;
    border-radius: 2px;
    width: 70px;
    height: 28px;
    font-size: 16px;
    color: #367893 !important;
  }

  .ant-select-arrow .ant-image-img {
    margin-top: 3px;
    width: 14px !important;
  }
`;
const StyledText = styled(Text) `
  font-size: 16px;
  color: #367893 !important;
  margin-right: 7px;
  margin-top: 4px;
`;
