import styled from "styled-components";
import { fontWeight, mainAppColours, SAColourScheme, supportingColours } from "src/constants";
export const CollectionOrganisationPageWrapper = styled.div `
  padding: 2rem;
  background-color: ${SAColourScheme.WHITE};

  .form-centre-info,
  .form-lab-info {
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    height: fit-content;

    position: relative;
    overflow: hidden;

    .start-panel {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      top: 0;
      left: 0;
      background-color: ${SAColourScheme.WHITE};

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .ant-typography.start-text {
      color: #2a44ff;
      font-size: 20px;
      font-weight: ${fontWeight.MEDIUM};
    }

    .start-button {
      margin-top: 30px;
      width: 88px;
      height: 36px;
      border: 1px solid ${mainAppColours.MID_BLUE};
      border-radius: 8px;
      color: ${mainAppColours.MID_BLUE};
    }

    .input-label {
      color: ${supportingColours.TEXT_LIGHT_GRAY};
      font-size: 15px;
    }

    .ant-input,
    .ant-select-selector,
    .ant-picker,
    .ant-select-selection-search {
      padding-left: 4px;
      height: 28px !important;

      background-color: transparent;
      /* background-color: ${SAColourScheme.WHITE};

      &:disabled {
        background-color: #e4f6fb;
      } */
    }

    .text-bold {
      input {
        font-weight: ${fontWeight.BOLD};
      }
    }

    .ant-picker-focused,
    .ant-select-focused .ant-select-selector {
      box-shadow: none !important;
    }

    .ant-row {
      border-bottom: 1px solid ${supportingColours.BOX_GRAY}80;

      .ant-col {
        border-right: 1px solid ${supportingColours.BOX_GRAY}80;
        margin-top: 2px;
        margin-bottom: 2px;
      }

      .ant-col:last-child {
        border-right: 0;
      }

      .ant-col[class*="ant-col-offset"] {
        box-shadow: -1px 0 0 0 ${supportingColours.BOX_GRAY}80;
      }
    }

    .ant-row:last-child {
      border-bottom: 0;
    }
  }

  .form-centre-point-info,
  .form-principal-info {
    background-color: ${SAColourScheme.PLASMA};
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    height: fit-content;
    margin-top: 16px;

    .input-label {
      color: ${SAColourScheme.WHITE}B3;
      font-size: 15px;
    }

    .ant-input {
      background-color: ${SAColourScheme.WHITE};

      &:disabled {
        background-color: #e4f6fb;
      }
    }

    .check-box-row {
      .input-label {
        margin-right: 10px;
        color: ${SAColourScheme.WHITE}B3 !important;
        font-size: 13px;
      }

      .tick-box {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          path {
            stroke: ${SAColourScheme.GRAY_BLUE};
          }
        }
      }
    }

    .ant-picker-focused,
    .ant-select-focused .ant-select-selector {
      box-shadow: none !important;
    }

    .ant-row {
      .ant-col {
        margin-top: 2px;
        margin-bottom: 2px;
      }

      .ant-col:first-child {
        border-left: 0;
      }
    }

    .ant-row:last-child {
      border-bottom: 0;
    }
  }

  .form-centre-point-info {
    padding-top: 0;
  }

  .form-principal-info {
    margin-top: 0;
    padding: 0;
  }
  .form-centre-point-info-title {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid ${SAColourScheme.WHITE};
    margin-bottom: 10px;
    // margin-top: 10px;

    .form-box-title {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-input {
        flex: 1;
      }

      .title-action {
        max-width: 200px;
        display: flex;
        justify-content: center;

        .ant-btn {
          color: ${mainAppColours.BLUE};

          &:disabled {
            opacity: 0.2;
          }
        }
      }
    }

    .text-bold {
      width: 100% !important;
      input {
        font-weight: ${fontWeight.BOLD};

        &:disabled {
          color: ${SAColourScheme.WHITE};
        }
      }
    }

    .ant-typography {
      padding-bottom: 10px;
      margin-right: 10px;
    }

    .ant-input,
    .ant-select-selector,
    .ant-picker,
    .ant-select-selection-search {
      height: 40px !important;
      text-align: center;

      &:disabled {
        background-color: transparent;
      }
    }
  }

  .lab-form-centre-point-info-title {
    display: flex;
    align-items: flex-end;

    .text-bold {
      input {
        font-weight: ${fontWeight.BOLD};

        &:disabled {
          color: ${SAColourScheme.WHITE};
        }
      }
    }

    .ant-typography {
      padding-bottom: 10px;
      margin-right: 10px;
    }

    .ant-input,
    .ant-select-selector,
    .ant-picker,
    .ant-select-selection-search {
      height: 40px !important;
      text-align: center;

      &:disabled {
        background-color: transparent;
      }
    }

    .collection-point-name {
      flex: 1;
    }
  }

  .form-box-title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .title-input {
      flex: 1;
    }

    .title-action {
      max-width: 200px;
      display: flex;
      justify-content: flex-end;

      .ant-btn {
        color: ${mainAppColours.BLUE};

        &:disabled {
          opacity: 0.2;
        }
      }
    }
  }

  .ant-btn {
    padding: 8px;
    border: none;
    width: 80px;
    height: 40px;
    border-radius: 4px;
    margin-left: 6px;
    background-color: ${SAColourScheme.WHITE};
  }

  .btn-cancel {
    background-color: ${SAColourScheme.WHITE};
    outline: 2px solid ${mainAppColours.MID_BLUE};
    outline-offset: -2px;
    color: ${SAColourScheme.MID_BLUE};
    box-shadow: none;
    text-shadow: none;
  }

  .btn-save {
    background-color: ${mainAppColours.MID_BLUE};
    outline: 2px solid ${SAColourScheme.WHITE};
    outline-offset: -2px;
    color: ${SAColourScheme.WHITE};
    box-shadow: none;
    text-shadow: none;
  }

  .add-collection-point {
    min-width: 150px;
    margin-top: 10px;
    background-color: ${mainAppColours.MID_BLUE};
    outline: 2px solid ${SAColourScheme.WHITE};
    outline-offset: -2px;
    color: ${SAColourScheme.WHITE};
    box-shadow: none;
    text-shadow: none;
    border-radius: 8px;
    margin-left: 8px;
  }

  .add-practice,
  .delete-practice,
  .edit-practice,
  .save-practice,
  .cancel-practice {
    margin-top: 0px;
    background-color: ${SAColourScheme.WHITE};
    outline: 2px solid ${mainAppColours.MID_BLUE};
    outline-offset: -2px;
    color: ${mainAppColours.MID_BLUE};
    box-shadow: none;
    text-shadow: none;
    border-radius: 8px;
    margin-left: 8px;
  }

  .save-practice {
    background-color: ${mainAppColours.MID_BLUE};
    color: ${SAColourScheme.WHITE};
    outline: 2px solid ${SAColourScheme.WHITE};
  }

  .delete-practice,
  .cancel-practice {
    color: ${SAColourScheme.RED};
  }

  .add-principal,
  .delete-principal,
  .edit-principal,
  .save-principal,
  .cancel-principal {
    margin-top: 0px;
    background-color: ${SAColourScheme.WHITE};
    outline: 2px solid ${mainAppColours.MID_BLUE};
    outline-offset: -2px;
    color: ${mainAppColours.MID_BLUE};
    box-shadow: none;
    text-shadow: none;
    border-radius: 8px;
    margin-left: 8px;
  }

  .save-principal {
    background-color: ${mainAppColours.MID_BLUE};
    color: ${SAColourScheme.WHITE};
    outline: 2px solid ${SAColourScheme.WHITE};
  }

  .delete-principal,
  .cancel-principal {
    color: ${SAColourScheme.RED};
  }

  .delete-laboratory-button {
    border: none;
    color: rgb(73, 173, 178);
  }

  .ant-typography {
    font-size: 16px;
    margin-left: 4px;
  }
`;
export const InputWrapper = styled.div `
  .ant-form-item {
    margin-bottom: 0px;

    .ant-select,
    .ant-picker {
      width: 100%;
    }

    .ant-form-item-explain-error {
      color: red;
      font-size: 12px;
      text-align: right;
    }
  }

  .ant-typography {
    font-size: 16px;
    margin-left: 4px;
  }

  .required {
    color: red !important;
  }

  .ant-input {
    border-radius: 4px;
    padding-left: 2px !important;
    overflow: hidden;
    height: 44px !important;

    font-size: 17px;
    color: ${supportingColours.TEXT_DARK_GRAY};

    border: 0 !important;

    &:focus {
      box-shadow: none;
    }
  }

  .ant-select {
    .ant-select-selector {
      border-radius: 4px;
      border: 0;
      box-shadow: none;
      height: 44px !important;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        font-size: 17px;
      }

      .ant-select-selection-placeholder {
        color: ${supportingColours.TEXT_LIGHT_GRAY};
      }

      .ant-select-selection-item {
        color: ${supportingColours.TEXT_DARK_GRAY};
      }
    }
  }

  .ant-picker {
    height: 44px !important;
    border-radius: 4px;
    border: 0;

    .ant-picker-input input {
      font-size: 17px;
      color: ${supportingColours.TEXT_DARK_GRAY};

      ::placeholder {
        color: ${supportingColours.TEXT_LIGHT_GRAY};
      }
    }
  }

  .ant-select-selection-search .ant-select-selection-search-input {
    font-size: 17px;
    color: ${supportingColours.TEXT_DARK_GRAY};

    ::placeholder {
      color: ${supportingColours.TEXT_LIGHT_GRAY};
    }
  }
`;
