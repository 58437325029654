import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import ColInput from "src/pages/DashboardPage/components/ColInput";
import { RowDataWrapper } from "./index.style";
export const MiddleRowData = ({ data, style, cellStyle, startIndex }) => (_jsx(RowDataWrapper, { children: _jsx("div", { style: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            ...style,
        }, children: Array.from([1, 2, 3, 4, 5, 6])?.map((_, index) => (_jsx(ColInput, { style: { ...cellStyle }, className: "col-input", span: 4, value: data[index + startIndex] }, index))) }) }));
