export var TestCombinationValue;
(function (TestCombinationValue) {
    TestCombinationValue[TestCombinationValue["AlcoholOnly"] = 1] = "AlcoholOnly";
    TestCombinationValue[TestCombinationValue["AlcoholDrug"] = 2] = "AlcoholDrug";
    TestCombinationValue[TestCombinationValue["DrugOnly"] = 3] = "DrugOnly";
})(TestCombinationValue || (TestCombinationValue = {}));
export const nonStandardSubstanceMapping = {
    syntheticCannabinoid: "Synthetic Cannabinoid*",
    syntheticCathinone: "Synthetic Cathinone*",
    oxy: "OXY* (Oxycodone)",
    bzo: "BZO (Benzodiazepines)",
};
export const preSelectedSubstanceMapping = {
    amp: "AMP (Amphetamine)",
    oxy: "OXY (Oxycodone)",
    bzo: "BZO (Benzodiazepines)",
    coc: "COC (Cocaine)",
    met: "MET (Methamphetamine)",
    opi: "OPI (Opiates)",
    thc: "THC (Cannabis, Marijuana)",
};
