export const textPattern = /^[a-zA-Z ]{2,30}$/;
export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const mobilePattern = /^(\+?[1-9]\d{1,14}|0\d{9,14})$/;
export const auMobilePattern = /^(0?[2-478]\d{8}|04\d{8})$/;
export const postcodePattern = /^[0-9]{4}$/;
export const preprocessFields = (e) => {
    let value = e.target.value;
    value = value.trim().replace(/ /g, "");
    return value;
};
export const validateFields = (field, value) => {
    let errorMessage = "";
    const strValue = String(value ?? "");
    if (value === "") {
        errorMessage = "This field is required";
    }
    else if (field === "mobile" && !mobilePattern.test(strValue)) {
        errorMessage = "Please enter a valid phone number";
    }
    else if (field === "email" && !emailPattern.test(strValue)) {
        errorMessage = "Please enter a valid email address";
    }
    return errorMessage;
};
