const selectListClockOnClockOff = (state) => state.platformManagementPage.clockOnClockOffPage.clockOnClockOffData;
const selectedToday = (state) => state.platformManagementPage.clockOnClockOffPage.selectedToday;
const selectedDate = (state) => state.platformManagementPage.clockOnClockOffPage.selectedDate;
const listClients = (state) => state.platformManagementPage.clockOnClockOffPage.listClientsData;
const selectClients = (state) => {
    const clients = state.platformManagementPage.clockOnClockOffPage.listClientsData;
    return clients.map((client) => ({
        label: client.name,
        value: client.id,
    }));
};
const selectCurrentClient = (state) => {
    const currentClient = state.platformManagementPage.clockOnClockOffPage.selectedClient;
    return {
        label: currentClient.name,
        value: currentClient.id,
    };
};
const listCollectors = (state) => state.platformManagementPage.clockOnClockOffPage.listCollectorsData;
const selectCollectors = (state) => {
    const collectors = state.platformManagementPage.clockOnClockOffPage.listCollectorsData;
    return collectors.map((collector) => ({
        label: collector.name,
        value: collector.id,
    }));
};
const selectCurrentCollector = (state) => {
    const currentCollector = state.platformManagementPage.clockOnClockOffPage.selectedCollector;
    return {
        label: currentCollector?.name,
        value: currentCollector?.id,
    };
};
const selectWorkRecordStartDate = (state) => state.platformManagementPage.clockOnClockOffPage.workRecordStartDate;
const selectWorkRecordEndDate = (state) => state.platformManagementPage.clockOnClockOffPage.workRecordEndDate;
const selectListWorkData = (state) => state.platformManagementPage.clockOnClockOffPage.workRecordData;
export { selectListClockOnClockOff, selectedToday, selectedDate, listClients, selectClients, selectCurrentClient, listCollectors, selectCollectors, selectCurrentCollector, selectWorkRecordStartDate, selectWorkRecordEndDate, selectListWorkData, };
