import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image } from "antd";
export const SearchBar = (props) => {
    const handleKeyDown = (event) => {
        if (event.key == "Enter") {
            props.onEnter();
        }
    };
    return (_jsxs("div", { style: { ...props.style }, className: "search-wrapper", children: [_jsx("div", { style: { padding: "13px 17px 13px 25px" }, children: _jsx(Image, { src: "/assets/svg/ic-search.svg", preview: false, width: 23, height: 23 }) }), _jsx("input", { className: "search-input", type: "text", placeholder: "Search", value: props.value, onChange: ({ target: { value } }) => props.onChange(value), onKeyDown: handleKeyDown }), _jsx("div", { style: {
                    background: "#FFFFFF",
                    padding: "9px 10px",
                    borderRadius: "0 4px 4px 0",
                }, children: props.value.length > 0 && (_jsx(Image, { src: "/assets/svg/ic-close2.svg", preview: false, width: 30, height: 30, onClick: props.onClear })) })] }));
};
