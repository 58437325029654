import styled from "styled-components";
import { Row } from "src/components/base";
import { mainAppColours, SAColourScheme, supportingColours } from "src/constants";
export const AppointmentPageWrapper = styled(Row) `
  background-color: ${SAColourScheme.MID_BLUE};
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  margin-top: 10px;

  .ant-row {
    width: 100%;
  }

  .appointment-details,
  .patient-details {
    background-color: ${SAColourScheme.WHITE}33;
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    height: fit-content;
  }

  .appointment-details {
    background-color: ${SAColourScheme.WHITE}33;

    .input-label {
      color: ${SAColourScheme.WHITE};
    }

    .column-form {
      padding-right: 10px;
    }
  }

  .patient-details {
    padding-top: 10px;
    padding-bottom: 5px;
    background-color: ${SAColourScheme.WHITE};

    .input-label {
      color: ${supportingColours.TEXT_LIGHT_GRAY};
    }

    .ant-input,
    .ant-select-selector,
    .ant-picker,
    .ant-select-selection-search {
      background-color: ${SAColourScheme.WHITE} !important;
      height: 28px !important;
    }
    .ant-picker-disabled {
      background-color: ${SAColourScheme.WHITE};
    }

    .ant-picker-focused,
    .ant-select-focused .ant-select-selector {
      background-color: ${SAColourScheme.WHITE} !important;
      box-shadow: none !important;
    }
    .ant-input[disabled] {
      background-color: ${SAColourScheme.WHITE} !important;
    }
    .ant-row {
      border-bottom: 1px solid ${supportingColours.BOX_GRAY}80;

      .ant-col {
        border-left: 1px solid ${supportingColours.BOX_GRAY}80;
        margin-top: 2px;
        margin-bottom: 2px;
      }

      .ant-col:first-child {
        border-left: 0;
      }
    }

    .ant-row:last-child {
      border-bottom: 0;
    }
  }

  #center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-button,
  .edit-button {
    position: relative;
    width: 120px;
    height: 64px;
    background-color: ${mainAppColours.BLUE};
    color: ${SAColourScheme.WHITE};
    border-radius: 8px;
    border: 2px solid ${SAColourScheme.WHITE};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    white-space: break-spaces;
    text-align: right;
    transition: 0.1s all;

    .ant-image {
      position: absolute;
    }

    &:hover {
      color: ${SAColourScheme.WHITE};
    }

    &[disabled] {
      background-color: "#FD0000";
    }
  }

  .edit-button {
    width: 100%;
    height: 44px;
    text-align: center;
  }

  .row-button {
    // height: 50%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    justify-content: center;
  }

  .touch-btn button {
    width: 65px;
    height: 22px;
    padding: 0;
    color: #0047b3;
    font-size: 13px;
    background: #ffffff;
    border-radius: 4px;
  }

  .touch-btn img {
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .touch-btn .edit-btn {
    margin-right: 20px;
  }

  .update-btn button {
    width: 65px;
    height: 22px;
    padding: 0;
    font-size: 13px;
    font-weight: 500;
    border-radius: 4px;
  }

  .update-btn .cancel-btn {
    color: #1a8cff;
    background: #ffffff;
    border: 1px solid #1a8cff;
  }

  .update-btn .cancel-btn {
    margin-right: 20px;
  }

  .update-btn .save-btn {
    color: #ffffff;
    background: #1a8cff;
    border: 1px solid #ffffff;
  }

  .autocomplete-results {
    //display: none;
    position: absolute;
    border: 1px solid black;
    background-color: #fff;
    max-height: 200px;
    z-index: 9999;
    border-radius: 10px;
  }

  .autocomplete-results table {
    width: 100%;
    border-collapse: collapse;
  }

  .autocomplete-results th,
  .autocomplete-results td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  .autocomplete-results th {
    background-color: #f2f2f2;
  }

  .autocomplete-results tr:hover {
    background-color: #f2f2f2;
  }

  .custom-table {
    background-color: black;
    padding: 10px;
    border-radius: 5px;
  }

  .custom-table th {
    background-color: #e6f7fa;
    padding: 10px 30px;
  }

  .custom-table td {
    padding: 10px 30px;
  }

  .ant-table-title {
    background-color: black; /* Set your desired background color */
    padding: 8px; /* Optional: Adjust padding as needed */
  }
`;
