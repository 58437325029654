import styled from "styled-components";
export const RequestTestWrapper = styled.div `
  .request-test-btn {
    text-align: left;
    width: 100%;
    height: 44px;
    border-radius: 4px;
    padding: 0 11px;
    color: #2f3337;
    font-size: 17px;
    span {
      color: #7f8e9d;
    }
  }
  .request-test-btn.value.disabled {
    cursor: not-allowed;
  }
  .request-test-btn.value {
    span {
      color: #2f3337;
    }
  }
  .request-test-btn span.anticon.anticon-down {
    float: right;
    margin-top: 7px;
  }
  .ant-btn > .anticon + span {
    margin-left: 0;
    font-size: 17px;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
`;
export const RequestTestContent = styled.div `
  background: #ffffff;
  border-radius: 4px;
  margin: -12px -16px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 12px 12px;

  p {
    margin-bottom: 0;
  }

  .title {
    color: #7f8e9d;
    font-weight: 500;
    font-size: 16px;
    border-bottom: 0.5px solid #ebedf0;
    margin-bottom: 9px;
    margin-top: 3px;
    padding: 5px;
  }
  .value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2f3337;
    font-size: 16px;
    border-bottom: 0.5px solid #ebedf0;
    padding: 5px;
  }
  .value.disabled {
    color: #2f33374d;
  }
  .check-btn {
    width: 22px;
    height: 22px;
    border: 1px solid #156cc9;
    visibility: visible;
    padding: 0;
  }
  .value.disabled .check-btn {
    border: 1px solid #156cc94d;
    padding: 0;
    visibility: visible;
    background: none;
  }
  .ok-btn {
    margin-top: 20px;
    margin-bottom: 5px;
    border: 1px solid #007aff;
    height: 30px;
    width: 100%;
    border-radius: 4px;
    color: #007aff;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
