import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Image, Table, Text } from "src/components/base";
const { Option } = Select;
export const RecordsTable = ({ patientRecordsData, searchString, selectedClient, page, pageSize, setPageSize, }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const columns = [
        {
            title: "No.",
            dataIndex: "key",
            key: "key",
            width: 75,
            className: "number-column",
            render: (_, __, index) => _jsx("span", { children: index + 1 }),
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
            defaultSortOrder: "ascend",
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        },
        {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
            sorter: (a, b) => a.dob.localeCompare(b.dob),
        },
        {
            title: "Age",
            dataIndex: "age",
            key: "age",
            sorter: (a, b) => b.age - a.age,
        },
        {
            title: "Sex",
            dataIndex: "sex",
            key: "sex",
            sorter: (a, b) => a.sex.localeCompare(b.sex),
        },
        {
            title: "Record",
            dataIndex: "record",
            key: "record",
            render: (_, record) => (_jsx(Button, { style: {
                    padding: "0px",
                    border: "none",
                    background: "none",
                }, onClick: () => {
                    navigate("/patient-records/on-site/donor-test-record", { state: record });
                }, children: _jsx(Image, { src: "/assets/svg/ic-view-onsite-record.svg", preview: false, width: 25.44, height: 29 }) })),
        },
    ];
    useEffect(() => {
        if (patientRecordsData && patientRecordsData.length) {
            const tempList = patientRecordsData.map((element, index) => {
                const calculateAge = (dobString) => {
                    const [day, month, year] = dobString.split("/").map(Number);
                    const dob = new Date(year, month - 1, day);
                    const today = new Date();
                    let age = today.getFullYear() - dob.getFullYear();
                    const monthDifference = today.getMonth() - dob.getMonth();
                    const dayDifference = today.getDate() - dob.getDate();
                    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
                        age--;
                    }
                    return age;
                };
                const obj = {
                    key: index + 1,
                    lastName: element.lastName?.toUpperCase(),
                    firstName: element.firstName,
                    dob: element.dob,
                    age: calculateAge(element.dob),
                    sex: element.sex,
                    id: element.id,
                    clientIds: element.clientIds,
                };
                return obj;
            });
            setOriginalData(tempList);
            let filteredData;
            if (selectedClient === "All") {
                filteredData = tempList;
            }
            else {
                filteredData = tempList.filter((item) => item.clientIds.includes(selectedClient));
                // eslint-disable-next-line no-param-reassign
                filteredData.forEach((item, index) => (item.key = index + 1));
            }
            setData(filteredData);
        }
    }, [patientRecordsData, selectedClient]);
    const itemRender = (current, type, originalElement) => {
        if (type === "prev") {
            return (_jsx("div", { children: _jsx(Image, { src: "/assets/images/double-arrow-back-box-gray-icon.png", preview: false, height: 10, width: 10, className: "prev-icon" }) }));
        }
        if (type === "next") {
            return (_jsx("div", { children: _jsx(Image, { src: "/assets/images/double-arrow-forth-box-gray-icon.png", preview: false, height: 10, width: 10, className: "next-icon" }) }));
        }
        return originalElement;
    };
    useEffect(() => {
        const newFilterData = originalData.filter((obj) => obj?.firstName?.toUpperCase().includes(searchString.toUpperCase()) ||
            obj?.lastName?.toUpperCase().includes(searchString.toUpperCase()));
        setData(newFilterData.map((obj, index) => ({
            ...obj,
            number: (index + 1),
        })));
    }, [searchString]);
    const [pagination, setPagination] = useState({
        defaultPageSize: 10,
        pageSize: 10,
        total: data.length,
        itemRender: itemRender,
        pageSizeOptions: [10, 15, 20],
        page: page,
    });
    const select = (selectPageSize) => {
        setPagination({ ...pagination, pageSize: selectPageSize });
        setPageSize(selectPageSize);
    };
    return (_jsxs(PageWrapper, { children: [_jsx(Table, { columns: columns, dataSource: data, 
                // scroll={{x: 900}}
                size: "middle", pagination: pagination }), data.length > 0 && (_jsxs(Row, { style: { position: "absolute", marginTop: "-50px", marginLeft: "30px" }, children: [_jsx(StyledText, { name: "Show" }), _jsxs(Select, { className: "styled-select", value: pageSize, onSelect: select, suffixIcon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false }), children: [_jsx(Option, { value: 10, children: "10" }), _jsx(Option, { value: 15, children: "15" }), _jsx(Option, { value: 20, children: "20" })] })] }))] }));
};
const PageWrapper = styled.div `
  border-top: 1px solid #ffffffb3;
  overflow: auto;
  position: relative;
  padding: 1rem 0;

  .ant-table table {
    width: 100% !important;
  }

  th.ant-table-cell {
    background: #32a1a1;
    color: white;
    font-size: 16px;
    font-weight: normal;

    .ant-btn {
      background: #32a1a1 !important;
    }
  }
  th.ant-table-cell::before {
    background-color: #01b4d2 !important;
  }
  .ant-table-container {
    width: 100% !important;
    overflow: auto;
    border-radius: 8px;
    border-bottom: 15px solid #32a1a1;
  }
  .date-column-none {
    display: none;
  }
  .time-column-none {
    display: none;
  }
  .test-column-none {
    display: none;
  }
  .ref-no-column-none {
    display: none;
  }
  .collection-point-column-none {
    display: none;
  }
  .collector-column-none {
    display: none;
  }
  th.ant-table-cell.number-column {
    text-align: center;
  }
  td.ant-table-cell {
    padding: 9px 8px !important;
    font-size: 16px;
    font-weight: normal;
    color: #1a576f;
  }
  td.ant-table-cell.number-column {
    background: #c9eef8;
    text-align: center;
  }
  td.ant-table-cell-row-hover {
    background: #01b4d2 !important;
    color: white;
  }
  td.ant-table-cell.number-column.ant-table-cell-row-hover {
    background: rgba(1, 180, 210, 0.6) !important;
    color: #1a576f;
  }
  tr.ant-table-row.ant-table-row-level-0 {
    height: 50px;
  }
  tr.ant-table-row.ant-table-row-level-0:nth-child(even) {
    background: #f7fcfd;
  }
  .ant-pagination .ant-pagination-options {
    display: none;
  }
  td.ant-table-cell.request-column {
    padding-left: 20px !important;
  }
  .ant-table-pagination.ant-pagination {
    margin-right: 30px;
  }
  .ant-pagination.mini .ant-pagination-prev {
    border: 1px solid #ebedf0;
    height: 28px;
    width: 28px;
  }
  .ant-pagination.mini .ant-pagination-next {
    border: 1px solid #ebedf0;
    margin-left: 14px !important;
    height: 28px;
    width: 28px;
  }
  li.ant-pagination-item {
    border: 1px solid #ebedf0 !important;
    margin-left: 14px !important;
    width: 28px !important;
    height: 28px !important;
  }
  li.ant-pagination-item a {
    color: #367893;
    font-size: 16px;
    padding-top: 3px;
  }
  li.ant-pagination-item.ant-pagination-item-active {
    background: #adb3bc;
  }
  li.ant-pagination-item.ant-pagination-item-active a {
    color: white;
  }
  td.ant-table-cell.view-column {
    text-align: center;
  }
  .ant-table-cell.ant-table-column-has-sorters:hover {
    background: #1a576f;
  }

  tr.ant-table-row.ant-table-row-level-0.select > td.ant-table-cell {
    background: #1a8cff !important;
    color: white;
    font-weight: bold;
  }
  tr.ant-table-row.ant-table-row-level-0.select > td.ant-table-cell-row-hover {
    background: #1a8cff !important;
    color: white;
  }

  .styled-select {
    border: 1px solid #ebedf0;
    border-radius: 2px;
    width: 70px;
    height: 28px;
    font-size: 16px;
    color: #367893 !important;
  }

  .ant-select-arrow .ant-image-img {
    margin-top: 3px;
    width: 14px !important;
  }
`;
const StyledText = styled(Text) `
  font-size: 16px;
  color: #367893 !important;
  margin-right: 7px;
  margin-top: 4px;
`;
