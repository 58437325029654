import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dropdown, Image, Input, Menu, Space, Table } from "antd";
import { useContext, useState } from "react";
import { MESSAGE_BOX_TYPE, mainAppColours } from "src/constants";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
import ContentContext from "src/providers/content";
import { FileUpload } from "src/pages/InventoryManagement/InventorySections/components/SupplierContactDetails/FileUpload";
export const ContactsDetailsTable = ({ tabledata, setTableData, editMode, setNewData, supplierArchive, supplierDelete, }) => {
    const { openMessageBox, openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const [drawerVisible, setDrawerVisible] = useState(false);
    // Inside ContactsDetailsTable component
    const [currentRecordIndex, setCurrentRecordIndex] = useState(null);
    const showDrawer = () => {
        setDrawerVisible(true);
    };
    const onCloseDrawer = () => {
        setDrawerVisible(false);
    };
    const onDelete = async () => {
        openLoadingScreen();
        closeLoadingScreen();
    };
    const onFileUploaded = (url, recordIndex) => {
        const newData = [...tabledata];
        newData[recordIndex].contractFileUrl = url;
        setTableData(newData);
        setNewData(newData);
    };
    const getMenu = (recordIndex) => (_jsxs(Menu, { style: { padding: "10px 10px" }, children: [_jsx(Menu.Item, { style: {
                    border: "1px solid #ADB3BC",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    margin: "10px 15px",
                }, onClick: () => {
                    setCurrentRecordIndex(recordIndex);
                    showDrawer();
                }, children: "Upload File" }, "contractFileUrl"), _jsx(FileUpload, { onCloseDrawer: onCloseDrawer, onFileUploaded: (url) => onFileUploaded(url, currentRecordIndex), visible: drawerVisible }), _jsx(Menu.Item, { style: {
                    border: "1px solid #ADB3BC",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    margin: "10px 15px",
                }, children: "View File" }, "view"
            // onClick={() => clickedFieldName && handleViewClick(clickedFieldName)}
            ), _jsx(Menu.Item, { style: {
                    border: "1px solid #ADB3BC",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    margin: "10px 15px",
                }, onClick: () => {
                    onDelete();
                }, children: "Delete File" }, "delete")] }));
    const handleInputChange = (value, dataIndex, recordIndex) => {
        const newData = [...tabledata];
        newData[recordIndex][dataIndex] = value;
        setTableData(newData);
        setNewData(newData);
    };
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Supplier Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ["ascend", "descend"],
            render: (text, record, index) => (_jsx(_Fragment, { children: editMode ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => handleInputChange(e.target.value, "name", index) }) })) : (_jsx(Space, { children: text })) })),
        },
        {
            title: "Account Manager",
            dataIndex: "accountManager",
            key: "accountManager",
            sorter: (a, b) => a.accountManager.localeCompare(b.accountManager),
            sortDirections: ["ascend", "descend"],
            render: (text, record, index) => (_jsx(_Fragment, { children: editMode ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => handleInputChange(e.target.value, "accountManager", index) }) })) : (_jsx(Space, { children: text })) })),
        },
        {
            title: "Contact Mobile",
            dataIndex: "contactMobile",
            key: "contactMobile",
            sorter: (a, b) => a.contactMobile.localeCompare(b.contactMobile),
            sortDirections: ["ascend", "descend"],
            render: (text, record, index) => (_jsx(_Fragment, { children: editMode ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => handleInputChange(e.target.value, "contactMobile", index) }) })) : (_jsx(Space, { children: text })) })),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ["ascend", "descend"],
            render: (text, record, index) => (_jsx(_Fragment, { children: editMode ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => handleInputChange(e.target.value, "email", index) }) })) : (_jsx(_Fragment, { children: text })) })),
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            sorter: (a, b) => a.address.localeCompare(b.address),
            sortDirections: ["ascend", "descend"],
            render: (text, record, index) => (_jsx(_Fragment, { children: editMode ? (_jsx("div", { style: {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }, children: _jsx(Input, { value: text, onChange: (e) => handleInputChange(e.target.value, "address", index) }) })) : (_jsx(Space, { children: text })) })),
        },
        {
            title: "Contract",
            dataIndex: "contractFileUrl",
            key: "contractFileUrl",
            render: (record, rowData, index) => (_jsx(_Fragment, { children: editMode ? (_jsx(Dropdown, { overlay: getMenu(index), trigger: ["click"], children: _jsx("div", { style: {
                            padding: "0px",
                            border: "none",
                            background: "none",
                        }, children: record !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/inventory-Management/ic-upload.svg", preview: false, width: 25, height: 25 })) }) })) : (
                // Render something else when editMode is false
                // For example, just display the image without dropdown
                _jsx(_Fragment, { children: record !== "" ? (_jsx(Image, { src: "/assets/svg/Document_Blue.svg", preview: false, width: 35, height: 35 })) : (_jsx(Image, { src: "/assets/svg/Document_Grey.svg", preview: false, width: 35, height: 35 })) })) })),
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(Menu, { style: {
                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                        width: "150px",
                    }, children: [_jsx(Menu.Item, { onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${record.name} - will be archived?`, () => {
                                    supplierArchive(record.id);
                                });
                            }, children: "Archive" }, "1"), _jsx(Menu.Item, { style: {
                                borderTop: "1px solid #ADB3BC",
                                width: "80%",
                                margin: "0 auto",
                            }, onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${record.name} - will be removed permanently?`, () => {
                                    supplierDelete(record.id);
                                });
                            }, children: _jsx("span", { style: { display: "flex", justifyContent: "flex-end" }, children: "Delete" }) }, "2")] }), trigger: ["click"], children: _jsx("div", { className: "menu-options", children: _jsx(ICMenuOptions, {}) }) })),
        },
    ];
    return (_jsx("div", { children: _jsx(Table, { columns: columns, dataSource: tabledata ? tabledata.map((item) => ({ ...item, key: item.id })) : [], pagination: false, scroll: { x: 900, y: 650 }, style: {
                border: "1px solid #D9D9D9",
                overflow: "hidden",
                marginTop: "10px",
            } }) }));
};
