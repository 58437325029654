import styled from "styled-components";
export const LeftDataWrapper = styled.div `
  width: 45%;

  .left-data-header {
    width: 100%;
    height: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #2e94f2;

    padding: 0 21px 0 11px;
  }

  .current-time {
    font-size: 13px;
    color: #00004c;
  }

  .pointer {
    height: 40px;
    padding: 5px;
    position: relative;
    background: #61b1f8;
  }

  .pointer:before {
    content: "";
    position: absolute;
    right: 0px;
    bottom: 0;
    height: 100%;
    border-left: 20px solid #61b1f8;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    background: #fff;
    width: 50px;
  }

  .pointer-head {
    height: 40px;
    padding: 5px;
    position: relative;
    background: #1569c4;
  }

  .pointer-head:before {
    content: "";
    position: absolute;
    right: 0px;
    bottom: 0;
    height: 100%;
    border-left: 20px solid #1569c4;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    background: #fff;
    width: 30px;
  }
`;
