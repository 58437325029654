import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Tooltip from "antd/es/tooltip";
import { Col } from "src/components/base";
import ColInput from "src/pages/DashboardPage/components/ColInput";
import { RowDataWrapper } from "./index.style";
export const LeftRowData = ({ data, title, name }) => {
    // eslint-disable-next-line no-param-reassign
    title =
        title === "inService"
            ? "In Service"
            : title === "outOfService"
                ? "Out of Service"
                : title === "inStock"
                    ? "In Stock"
                    : title;
    return (_jsx(RowDataWrapper, { children: _jsxs("div", { style: {
                width: "100%",
                display: "flex",
                flexDirection: "row",
            }, children: [_jsxs(Col, { span: data?.length === 2 ? 12 : 8, style: { display: "flex" }, children: [_jsx(Tooltip, { title: title, color: "black", children: _jsx("div", { className: "arrow", style: { cursor: "pointer" }, children: title }) }), _jsx("div", { className: "arrow-end" })] }), data?.map((value, idxx) => (_jsx(ColInput, { className: "col-input", span: data?.length === 2 ? 6 : 4, value: name !== "history" && data?.length === 2 && idxx === 1 ? "$ " + value : value }, idxx)))] }) }));
};
