import styled from "styled-components";
export const Wrapper = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 30%);
  z-index: 1000;
`;
