import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "antd";
import { mainAppColours } from "src/constants";
const ButtonX = (props) => {
    let bgrColor = "white";
    if (props.type == "primary") {
        bgrColor = mainAppColours.BLUE;
    }
    return (_jsx(Button, { className: props.className, style: {
            background: bgrColor,
            color: mainAppColours.BLUE,
            borderRadius: "8px",
            padding: "0px",
            ...props.style,
        }, onClick: props.onClick, icon: props.icon, loading: props.loading, htmlType: props.htmlType, disabled: props.disabled, children: props.name }));
};
export default ButtonX;
