import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Card, Divider, Link, Typography } from "@mui/material";
import { message } from "antd";
import { useState } from "react";
import { Button, TextInput } from "src/components/base";
import { UserAction } from "src/pages/UserProfilePage";
import ConfirmToDeletePopUp from "src/pages/UserProfilePage/components/ConfirmToDeletePopup";
import { askDeleteAccount } from "src/pages/UserProfilePage/store/action";
import { getCustomTokenId } from "src/pages/UserProfilePage/store/selector";
import { getUserData } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
const DeleteAccountPage = ({ onStatusUpdate, openPopupBox, closePopupBox, }) => {
    const userInfo = useAppSelector(getUserData);
    const dispatch = useAppDispatch();
    const customTokenId = useAppSelector(getCustomTokenId);
    const [emailAddress, setEmailAddress] = useState("");
    const [accountDeleted, setAccountDeleted] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const handleBreadCrumbClick = () => {
        onStatusUpdate(UserAction.MAIN_PAGE);
    };
    const onTypingNewEmail = (value) => {
        setEmailAddress(value);
    };
    const handleDeleteAccountClick = () => {
        if (emailAddress === userInfo?.email) {
            setErrorMsg("");
            openPopupBox(_jsx(ConfirmToDeletePopUp, { handleDeleteAccount: handleDeleteAccount, email: emailAddress, closePopupBox: closePopupBox }));
        }
        else {
            setErrorMsg("Email does not match.");
        }
    };
    const handleDeleteAccount = async (finalEmail) => {
        if (finalEmail === emailAddress) {
            const requestBody = { email: finalEmail, customTokenId: customTokenId ?? "" };
            const result = await dispatch(askDeleteAccount(requestBody));
            // @ts-expect-error: remove this later
            if (result.payload?.status === "success") {
                setErrorMsg("");
                setAccountDeleted(true);
            }
            else {
                message.error("Failed to delete account. Please try again.");
            }
        }
    };
    const breadcrumbs = [
        _jsx(Link, { underline: "hover", color: "inherit", onClick: handleBreadCrumbClick, children: "User Profile" }, "1"),
        _jsx(Typography, { color: "text.primary", children: "Delete Account" }, "2"),
    ];
    return (_jsxs("div", { style: {
            display: "flex",
            flexDirection: "column",
            gridTemplateColumns: "60% 40%",
            gap: "5vh",
            backgroundColor: "#F5F6F8",
            padding: "2% 6%",
            height: "100%",
        }, children: [_jsx(Breadcrumbs, { separator: _jsx(NavigateNextIcon, { fontSize: "small" }), "aria-label": "breadcrumb", children: breadcrumbs }), _jsxs(Card, { style: { backgroundColor: "white" }, children: [_jsxs("div", { style: {
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            height: "60px",
                            alignItems: "center",
                            gap: "15px",
                            padding: "2%",
                        }, children: [_jsx(NavigateBeforeIcon, {}), _jsx(Typography, { variant: "h5", children: "Delete Account" })] }), _jsx(Divider, {}), !accountDeleted && (_jsxs("div", { style: { padding: "6%", display: "flex", flexDirection: "column", gap: "10px" }, children: [_jsx(Typography, { style: { color: "#6A6E71" }, children: "Deleting your account will:" }), _jsx(Typography, { style: { color: "#6A6E71", textIndent: "5%" }, children: "- Delete your profile and photo" }), _jsx(Typography, { style: { color: "#6A6E71", textIndent: "5%" }, children: "- Remove your Collect Assist Invitation Code." }), _jsx(Typography, { style: { color: "#6A6E71", textIndent: "5%" }, children: "- Warning: you will not be able to recover your account" }), _jsxs(Card, { variant: "outlined", style: {
                                    marginTop: "10%",
                                    padding: "8%",
                                    color: "#156CC9",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }, children: [_jsx("label", { children: "Please enter your registered email address" }), _jsx(TextInput, { value: emailAddress, placeholder: "Email address", type: "text", onChange: onTypingNewEmail }), _jsx("p", { style: { color: "#FD0000" }, children: errorMsg }), _jsx(Button, { onClick: handleDeleteAccountClick, style: {
                                            marginTop: "5%",
                                            alignSelf: "flex-end",
                                            color: "white",
                                            background: "#FD0000",
                                            borderRadius: "8px",
                                            border: "2px solid #FFFFFF",
                                        }, children: "Delete My Account" })] })] })), accountDeleted && (_jsxs(Card, { style: {
                            padding: "6%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            backgroundColor: "#FAFAFA",
                            margin: "10%",
                            alignItems: "center",
                        }, children: [_jsx(Typography, { style: { color: "#2A44FF" }, children: "We are sorry to see you go." }), _jsxs("div", { style: { margin: "5%", color: "#6A6E71" }, children: [_jsx(Typography, { children: "Please check your inbox for " }), _jsx(Typography, { style: { color: "#2A44FF" }, children: emailAddress }), _jsx(Typography, { children: "to finish deleting your account." })] }), _jsx(Typography, { style: { color: "#6A6E71" }, children: "The request will be valid for 24 hours." })] }))] })] }));
};
export default DeleteAccountPage;
