import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Image } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { archiveTransportSatchel, getArchivedTransportSatchels, getTransportSatchels, restoreTransportSatchel, } from "src/api/inventory/inventoryService";
import { MESSAGE_BOX_TYPE, mainAppColours } from "src/constants";
import { HeaderWrapper } from "src/pages/InventoryManagement/TransportSatchels/index.style";
import ContentContext from "src/providers/content";
import { Archieve } from "./Archieve";
import { SatchelsData } from "./SatchelsData";
export const TransportSatchels = () => {
    const [data, setData] = useState([]);
    const [archivedSatchels, setArchivedSatchels] = useState([]);
    const navigate = useNavigate();
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const getSatchelsData = async () => {
        openLoadingScreen();
        const result = await getTransportSatchels();
        if (result.status === "success") {
            setData(result.data);
        }
        else {
            setData([]);
        }
        closeLoadingScreen();
    };
    const getArchivedSatchelsData = async () => {
        openLoadingScreen();
        const result = await getArchivedTransportSatchels();
        if (result.status === "success") {
            setArchivedSatchels(result.data);
        }
        else {
            setArchivedSatchels([]);
        }
        closeLoadingScreen();
    };
    useEffect(() => {
        getSatchelsData();
        getArchivedSatchelsData();
    }, []);
    const archiveSatchel = async (id) => {
        openLoadingScreen();
        const response = await archiveTransportSatchel(id);
        if (response.status === "success") {
            openToast("Archived successfully");
            getSatchelsData();
            getArchivedSatchelsData();
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    const restoreSatchel = async (id) => {
        openLoadingScreen();
        const response = await restoreTransportSatchel(id);
        if (response.status === "success") {
            openToast("Restored successfully");
            getSatchelsData();
            getArchivedSatchelsData();
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    return (_jsxs("div", { children: [_jsx(HeaderWrapper, { children: _jsx("div", { children: _jsxs("div", { style: {
                            display: "flex",
                            justifyContent: "flex-start",
                            marginBottom: "10px",
                            alignItems: "center",
                        }, children: [_jsxs(Col, { span: 16, children: [_jsx(Image, { src: "/assets/svg/ic-satchel.svg", height: 30, width: 30, preview: false }), _jsx("span", { style: {
                                            marginLeft: "10px",
                                            fontSize: "16px",
                                            color: "#000000",
                                        }, children: "Satchel Inventory" })] }), _jsx(Col, { span: 8, style: { display: "flex", justifyContent: "flex-end" }, children: _jsxs(Button, { onClick: () => navigate(`/inventory/add`), style: {
                                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                                        borderRadius: "4px",
                                    }, children: [_jsx("span", { style: { marginRight: 8 }, children: _jsx(Image, { src: "/assets/svg/add-icon.svg", preview: false }) }), _jsx("span", { style: { color: "#1A8CFF" }, children: "Add New" })] }) })] }) }) }), _jsx(SatchelsData, { data: data, archiveSatchel: archiveSatchel }), _jsx(Archieve, { data: archivedSatchels, restoreSatchel: restoreSatchel })] }));
};
