import { CombinationTestType, OnsiteJobAllocationStatus, OnsiteJobServiceType, OnsiteJobStatus, TestType, } from "./store/types";
const OnsiteJobServiceTypeName = {
    [OnsiteJobServiceType.RandomTesting]: "Random Testing",
    [OnsiteJobServiceType.IncidentTesting]: "Incident Testing",
    [OnsiteJobServiceType.PreEmployment]: "Pre Employment",
    [OnsiteJobServiceType.ReturnToWork]: "Return To Work",
};
const ColorSchemeForStatus = {
    Planned: "#1A8CFF",
    InProgress: "#5CD22B",
    Completed: "#005277",
    Archived: "#6C757D",
    Cancelled: "#FD0000",
    Draft: "#FDBA00",
};
const DisplayedJobStatuses = {
    [OnsiteJobStatus.Draft]: {
        label: "Draft",
        color: ColorSchemeForStatus.Draft,
    },
    [OnsiteJobStatus.Planned]: {
        label: "Planned",
        color: ColorSchemeForStatus.Planned,
    },
    [OnsiteJobStatus.InProgress]: {
        label: "In Progress",
        color: ColorSchemeForStatus.InProgress,
    },
    [OnsiteJobStatus.Completed]: {
        label: "Completed",
        color: ColorSchemeForStatus.Completed,
    },
    [OnsiteJobStatus.Archived]: {
        label: "Archived",
        color: ColorSchemeForStatus.Archived,
    },
    [OnsiteJobStatus.Cancelled]: {
        label: "Cancelled",
        color: ColorSchemeForStatus.Cancelled,
    },
};
const DisplayOnsiteJobAllocationStatus = {
    [OnsiteJobAllocationStatus.Allocated]: { label: "Allocated", color: "#1A8CFF" },
    [OnsiteJobAllocationStatus.Accepted]: { label: "Accepted", color: "#35B700" },
    [OnsiteJobAllocationStatus.Rejected]: { label: "Rejected", color: "#FD0000" },
};
const TestCombination = {
    [CombinationTestType.AlcoholOnly]: "Alcohol Test Only",
    [CombinationTestType.AlcoholDrug]: "Alcohol and Drug Test",
    [CombinationTestType.DrugOnly]: "Drug Test Only",
};
const DrugTest = {
    [TestType.RapidUrineDrugScreen]: "Urine Drug Screen",
    [TestType.RapidOralFluidDrugScreen]: "Oral Fluid Drug Screen",
    [TestType.UrineDrugTest]: "Urine Drug Test (Urine Collection)",
    [TestType.OralFluidDrugTest]: "Oral Fluid Drug Test (Oral Fluid Collection)",
    [TestType.HairDrugTest]: "Hair Drug Test (Hair Collection)",
};
const TestTypeValue = {
    PRIMARY: 1,
    SECONDARY: 2,
    TERTIARY: 3,
};
export { DisplayedJobStatuses, DisplayOnsiteJobAllocationStatus, OnsiteJobServiceTypeName, TestCombination, DrugTest, TestTypeValue, };
