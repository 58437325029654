import { auMobilePattern, emailPattern, postcodePattern } from "src/patterns/patterns";
export const CollectionCentreFormConfig = [
    [
        {
            col: 24,
            label: "Organisation Name",
            field: "collectionCentre",
            fieldKey: ["collectionName"],
            // pattern: textPattern
        },
    ],
    [
        {
            col: 12,
            label: "Email Address",
            field: "collectionCentre",
            fieldKey: ["email"],
            pattern: emailPattern,
        },
        {
            col: 6,
            label: "Phone Number",
            field: "collectionCentre",
            fieldKey: ["phoneNumber"],
            pattern: auMobilePattern,
            require: true,
        },
        {
            col: 6,
            label: "Fax",
            field: "collectionCentre",
            fieldKey: ["fax"],
            require: false,
            // pattern: faxPattern,
        },
    ],
    [
        {
            col: 12,
            label: "Address Line 1 (Street address)",
            field: "collectionCentre",
            fieldKey: ["address1"],
            // pattern: textPattern,
        },
        {
            col: 12,
            label: "Address Line 2 (Optional)",
            field: "collectionCentre",
            fieldKey: ["address2"],
            require: false,
            // pattern: textPattern,
        },
    ],
    [
        {
            col: 6,
            label: "City/Suburb",
            field: "collectionCentre",
            fieldKey: ["suburb"],
            // pattern: textPattern,
        },
        {
            col: 6,
            label: "State",
            field: "collectionCentre",
            fieldKey: ["state"],
            // pattern: textPattern,
        },
        {
            col: 6,
            label: "Postcode",
            field: "collectionCentre",
            fieldKey: ["postcode"],
            pattern: postcodePattern,
        },
        {
            col: 6,
            label: "Country",
            field: "collectionCentre",
            fieldKey: ["country"],
            // isSelect: true,
            // pattern: countryPattern,
        },
    ],
];
