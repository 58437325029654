export var Drug_Class_Result;
(function (Drug_Class_Result) {
    Drug_Class_Result[Drug_Class_Result["NEGATIVE"] = 0] = "NEGATIVE";
    Drug_Class_Result[Drug_Class_Result["NON_NEGATIVE"] = 1] = "NON_NEGATIVE";
})(Drug_Class_Result || (Drug_Class_Result = {}));
export var Test_Type;
(function (Test_Type) {
    Test_Type[Test_Type["DRUG_TEST"] = 1] = "DRUG_TEST";
    Test_Type[Test_Type["ALCOHOL_TEST"] = 2] = "ALCOHOL_TEST";
    Test_Type[Test_Type["DRUG_AND_ALCOHOL_TEST"] = 3] = "DRUG_AND_ALCOHOL_TEST";
})(Test_Type || (Test_Type = {}));
export var Test_Type_Label;
(function (Test_Type_Label) {
    Test_Type_Label["DRUG_TEST"] = "Drug Test";
    Test_Type_Label["ALCOHOL_TEST"] = "Alcohol Test";
    Test_Type_Label["DRUG_AND_ALCOHOL_TEST"] = "Alcohol & Drug Test";
})(Test_Type_Label || (Test_Type_Label = {}));
export const DONOR_REASON_TYPE = {
    "1": "Random",
    "2": "Blanket",
    "3": "Incident",
    "4": "Pre Employment",
    "5": "Return to Work",
    "6": "Other",
};
export const SAMPLE_RESULT = {
    "0": "NEGATIVE",
    "1": "NON NEGATIVE",
    "2": "INVALID",
};
export const TEST_RESULT = {
    "0": "IN PROGRESS",
    "1": "NON NEGATIVE",
    "2": "NEGATIVE",
    "3": "INVALID",
    "4": "REFUSAL",
    "5": "SENT_TO_LAB",
};
