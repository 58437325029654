// Generate calendar month based on week view.
// Return 5 arrays of weeks and 7 days in each week array
export const generateCalendarMonth = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const weeks = [[]];
    let currentWeekIndex = 0;
    const daysInPreviousMonth = new Date(year, month, 0).getDate();
    const startDay = daysInPreviousMonth - firstDayOfMonth + 2;
    for (let day = startDay; day <= daysInPreviousMonth; day++) {
        weeks[currentWeekIndex].push(new Date(year, month - 1, day));
    }
    for (let day = 1; day <= daysInMonth; day++) {
        if (weeks[currentWeekIndex].length === 7) {
            currentWeekIndex++;
            weeks[currentWeekIndex] = [];
        }
        weeks[currentWeekIndex].push(new Date(year, month, day));
    }
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay();
    const remainingDays = lastDayOfMonth === 0 ? 6 : 6 - lastDayOfMonth + 1;
    for (let day = 1; day <= remainingDays; day++) {
        if (weeks[currentWeekIndex].length === 7) {
            currentWeekIndex++;
            weeks[currentWeekIndex] = [];
        }
        weeks[currentWeekIndex].push(new Date(year, month + 1, day));
    }
    if (weeks.length > 5)
        weeks.splice(5);
    return weeks;
};
export const getFullName = (data) => {
    const nameParts = [data.firstName, data.lastName];
    return nameParts
        .filter((part) => part && typeof part === "string")
        .join(" ")
        .trim();
};
