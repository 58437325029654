import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePicker, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { OptionsDropdownWrapper, PageWrapper } from "./index.style";
const { Option } = Select;
export const InputBox = (props) => {
    const { type, onEdit: onEditProp, value: valueProp, disabled: disabledProp, style, fieldKey, onChange, valueList, maxLength, max, min, format, className, } = props;
    const [value, setValue] = useState("");
    const [onEdit, setOnEdit] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [dateValue, setDateValue] = useState(moment());
    useEffect(() => {
        setDisabled(disabledProp);
    }, [disabledProp]);
    useEffect(() => {
        setOnEdit(onEditProp);
    }, [onEditProp]);
    useEffect(() => {
        if (type === "date" && valueProp && moment.isMoment(valueProp)) {
            setDateValue(valueProp);
        }
        else {
            setValue(valueProp);
        }
    }, [valueProp, type]);
    const onChangeValue = (key, val) => {
        onChange(key, val);
    };
    const onChangeNumberValue = (key, val) => {
        onChange(key, val);
    };
    const renderOptions = (options) => options.map((option, index) => (_jsx(Option, { value: option.value, children: option.label }, index)));
    const renderTextInfoBox = () => {
        if (type === "text") {
            return (_jsxs("div", { className: "info-element", style: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "6px 14px",
                    cursor: disabled ? "not-allowed" : "auto",
                    ...style,
                }, children: [!onEdit && (_jsx("div", { className: "text-box", style: { height: style?.height }, children: _jsx("p", { className: "text-info", children: value }) })), onEdit && (_jsx("input", { onChange: ({ target: { value: inputValue } }) => onChangeValue(fieldKey, inputValue), className: "text-input", style: {
                            height: style?.height,
                            border: "none",
                            background: "white",
                            outline: "none",
                            cursor: disabled ? "not-allowed" : "auto",
                        }, value: value, disabled: disabled }))] }));
        }
        else if (type === "number") {
            return (_jsxs("div", { className: "info-element", style: {
                    display: "flex",
                    flexDirection: "column",
                    padding: "6px 14px",
                    ...style,
                }, children: [!onEdit && (_jsx("div", { className: "text-box", style: { height: style?.height }, children: _jsx("p", { className: "text-info", children: value }) })), onEdit && (_jsx("input", { onChange: ({ target: { value: inputValue } }) => onChangeNumberValue(fieldKey, parseFloat(inputValue)), className: "text-input", type: "number", style: {
                            height: style?.height,
                            border: "none",
                            background: "white",
                            outline: "none",
                        }, defaultValue: value, disabled: disabled, maxLength: maxLength, max: max, min: min }))] }));
        }
        else if (type === "select") {
            return (_jsxs("div", { className: "info-element", style: {
                    display: "flex",
                    flexDirection: "column",
                    ...style,
                    cursor: disabled ? "not-allowed" : "auto",
                }, children: [!onEdit && (_jsx("div", { className: "text-box", style: {
                            height: style?.height,
                            padding: "6px 14px",
                        }, children: _jsx("p", { className: "text-info", children: value }) })), onEdit && (_jsx(Select, { className: "select-input", value: value, size: "large", onChange: (selectedValue) => onChangeValue(fieldKey, selectedValue), style: {
                            height: "40px",
                            width: "100%",
                            color: "#2F3337",
                            ...style,
                        }, dropdownRender: (originElement) => (_jsx(OptionsDropdownWrapper, { children: originElement })), children: renderOptions(valueList) }))] }));
        }
        else if (type === "date") {
            return (_jsxs("div", { className: "info-element", style: {
                    display: "flex",
                    flexDirection: "column",
                    ...style,
                }, children: [!onEdit && (_jsx("div", { className: "text-box", style: { height: style?.height }, children: _jsx("p", { className: "text-info", children: moment(value).format(format || "DD/MM/YYYY") }) })), onEdit && (_jsx(DatePicker, { className: "select-date", style: {
                            height: "40px",
                            width: "100%",
                            borderRadius: "4px",
                            background: "white",
                            border: "none",
                            outline: "none",
                            ...style,
                        }, defaultValue: dateValue, format: format || "DD/MM/YYYY", onChange: (date, dateString) => {
                            onChangeValue(fieldKey, moment(dateString, format || "DD/MM/YYYY"));
                        } }))] }));
        }
    };
    return _jsx(PageWrapper, { className: className, children: renderTextInfoBox() });
};
