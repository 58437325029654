import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from "react";
import { PageWrapper } from "./index.style";
export const Collapsible = (props) => {
    const [isRerender, setIsRerender] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const parentRef = useRef(null);
    const childRef = useRef(null);
    const [bodyStyle, setBodyStyle] = useState({
        height: "0px",
    });
    const onToggle = () => {
        setIsOpen(!isOpen);
        if (props.onToggle) {
            props.onToggle(!isOpen);
        }
    };
    useEffect(() => {
        if (isOpen) {
            setBodyStyle({
                height: parentRef?.current?.scrollHeight + "px",
            });
        }
        else {
            setBodyStyle({
                height: "0px",
            });
        }
    }, [isOpen]);
    useEffect(() => {
        if (isRerender) {
            setTimeout(() => {
                setBodyStyle({
                    height: `${childRef?.current?.scrollHeight * 1.1}px`,
                });
            }, 300);
        }
        setIsRerender(false);
    }, [isRerender]);
    return (_jsx(PageWrapper, { className: props.className, children: _jsxs("div", { className: "collapsible", children: [_jsxs("div", { className: "toggle", onClick: onToggle, children: [!isOpen && props.header, isOpen && (props.activeHeader || props.header)] }), _jsx("div", { className: "content-parent", ref: parentRef, style: bodyStyle, children: _jsx("div", { className: "content", onClick: () => {
                            setIsRerender(true);
                        }, ref: childRef, children: props.children }) })] }) }));
};
