import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Badge, Col, Row } from "antd";
import moment from "moment";
import { useAppSelector } from "src/store/hooks";
import { getApplicationInformation } from "src/store/app/selector";
import { formatTime } from "src/utils/helper";
export const NotesList = ({ notesData, handleClick, selectedNoteDetail, locationsData, }) => {
    const appInformation = useAppSelector(getApplicationInformation);
    return (_jsx(PageWrapper, { children: notesData.map((note) => (_jsxs(ItemWrapper, { children: [note?.id === selectedNoteDetail?.id ? (_jsx(Badge, { status: "processing" })) : (_jsx(Badge, { status: "default" })), _jsxs(ContentWrapper, { onClick: () => note.id && handleClick(note.id), className: note?.id === selectedNoteDetail?.id ? "selected" : "not-selected", children: [_jsxs(StyledRow, { children: [_jsx(StyledColTitle, { xs: 12, xl: 12, children: note?.subject }), _jsx(StyledColLeft, { xs: 12, xl: 8, children: locationsData &&
                                        locationsData.find((location) => location.value === note?.onsiteClientLocationId)
                                            ?.label }), _jsx(StyledColRight, { xs: 24, xl: 4, children: moment(note?.createdAt).format("DD/MM/YYYY") })] }), _jsxs(StyledRow, { children: [_jsxs(StyledColLeft, { span: 15, children: ["Created by: ", note?.createdBy] }), _jsx(StyledColRight, { span: 9, children: formatTime(appInformation?.collectionOrganization.timeFormat, note?.createdAt) })] })] })] }, note?.id))) }));
};
const PageWrapper = styled.div `
  max-height: 42rem;
  overflow-y: auto;
`;
const ItemWrapper = styled.div `
  display: flex;
  align-items: center;
  margin-left: 2em;
`;
const ContentWrapper = styled.div `
  border-bottom: 1px solid #d3d6db;
  padding: 10px;
  margin-left: 10px;
  width: 100%;

  &.selected {
    background-color: #e6f7fa;
  }
`;
const StyledRow = styled(Row) `
  margin-bottom: 10px;
`;
const StyledColRight = styled(Col) `
  display: flex;
  justify-content: flex-end;
  color: #7f8e9d;
`;
const StyledColLeft = styled(Col) `
  color: #7f8e9d;
`;
const StyledColTitle = styled(Col) `
  color: black;
`;
