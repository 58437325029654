import styled from "styled-components";
export const PageWrapper = styled.div `
  width: 100%;
  height: 98%;
  margin: 0.75rem 0;
  overflow: hidden;

  .email-content {
    border-right: solid 0px #156cc9;
    border-left: solid 1px #156cc9;
    border-bottom: 15px solid #367893;
    border-top: 2px solid #367893;
    border-radius: 0 0 8px 8px;
  }
`;
