import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from "react";
import ContentContext from "src/providers/content";
import { Wrapper } from "./index.style";
const BodyPopupBox = () => {
    const { bodyPopupBox } = useContext(ContentContext);
    const { props } = bodyPopupBox;
    const style = props?.style;
    let offsetWidth = 0;
    if (style && style.left) {
        offsetWidth = style.left + 34;
    }
    return bodyPopupBox?.visible ? (_jsx(Wrapper, { style: { height: props?.bgroundHeight }, children: _jsx("div", { style: {
                position: "absolute",
                ...props?.style,
                width: `calc(100% - ${offsetWidth}px)`,
            }, children: bodyPopupBox.component }) })) : null;
};
export default BodyPopupBox;
