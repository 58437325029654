import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import ComingSoonPage from "../PageErrors/ComingSoonPage";
import { InvalidReport } from "./InvalidReport";
import OnsiteReport from "./OnsiteReport";
const breadcrumbs = [
    {
        path: "/collection-reports",
        name: "Collection Reports",
        level: 1,
    },
    {
        path: "/in-clinic",
        name: "In-clinic Collection Report",
        level: 2,
    },
    {
        path: "/on-site",
        name: "On-site Collection Report",
        level: 2,
    },
    {
        path: "/invalid",
        name: "Invalid Result Report",
        level: 2,
    },
];
const CollectionReportPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === "/collection-reports") {
            navigate("/collection-reports/on-site");
        }
    }, []);
    return (_jsx(MainLayout, { children: _jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/in-clinic", element: _jsx(ComingSoonPage, {}) }), _jsx(Route, { path: "/on-site", element: _jsx(OnsiteReport, {}) }), _jsx(Route, { path: "/invalid", element: _jsx(InvalidReport, {}) })] }) }) }));
};
export default CollectionReportPage;
