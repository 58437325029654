import styled from "styled-components";
export const DropDownWrapper = styled.div `
  .collapsible-div,
  .content-div {
    transition: max-height 0.3s ease-out;
    overflow: hidden;
  }

  .collapsed {
    max-height: 0;
  }
`;
