import styled from "styled-components";
export const FormTitleWrapper = styled.div `
  width: 100%;
  margin-bottom: 5px;

  .ant-typography {
    color: #ffffff;
    font-weight: bold;
    font-size: 22px;
    margin-left: 10px;
  }

  .ant-divider {
    opacity: 0.2;
    border-radius: 8px;
    border-top-width: 5px;
    border-top-color: #ffffff;
    margin: 5px 0;
  }
`;
