import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { Button } from "src/components/base";
import { MonthYearWrapper } from "./index.style";
const MonthSelector = ({ startIndex }) => {
    const [currentMonth, setCurrentMonth] = useState(0);
    useEffect(() => {
        startIndex && startIndex(currentMonth);
    }, [currentMonth]);
    const handlePrevClick = () => {
        if (currentMonth >= 1 && currentMonth <= 6) {
            setCurrentMonth((prevMonth) => prevMonth - 1);
        }
    };
    const handleNextClick = () => {
        if (currentMonth >= 0 && currentMonth <= 5) {
            setCurrentMonth((prevMonth) => prevMonth + 1);
        }
    };
    const MONTHS = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "NOV",
        "OCT",
        "DEC",
    ];
    return (_jsxs(MonthYearWrapper, { children: [_jsx(Button, { size: "small", onClick: handlePrevClick, icon: _jsx("img", { src: "/assets/svg/ic-prev.svg" }) }), Array.from([1, 2, 3, 4, 5, 6]).map((_, index) => (_jsx(Button, { style: { background: "#71b9f8", border: "#71b9f8" }, children: MONTHS[currentMonth + index] }, index))), _jsx(Button, { size: "small", onClick: handleNextClick, icon: _jsx("img", { src: "/assets/svg/ic-next.svg" }) })] }));
};
export default MonthSelector;
