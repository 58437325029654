import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
const RoundHeaderCard = ({ children, icon, ...rest }) => (_jsxs(Container, { ...rest, children: [_jsx(Circle, { children: icon }), _jsx(Content, { children: children })] }));
export default RoundHeaderCard;
const Container = styled.div `
  position: relative;
  width: 100%;
  min-height: 5rem;
  background-color: inherit;
  border-radius: 0.5rem;
  margin-top: 2rem;
  box-shadow: 0px 3px 6px 0px #0000004d;
`;
const Circle = styled.div `
  position: absolute;
  top: -40px;
  left: 20px;
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 3rem;
  border: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
`;
const Content = styled.div `
  height: 100%;
  width: 100%;
`;
