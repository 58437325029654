import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { $get, $post, $put } from "src/infra/http";
const getTestingDevices = createAsyncThunk("getUrineScreenTestingDevices", async ({ testTypeId, isArchived = false }, thunkApi) => {
    const state = thunkApi.getState().deviceManagementPage;
    const collectionPointId = state.urineScreenDevicePage.selectedCollectionPointId;
    const queryString = stringify({
        testTypeId,
        isArchived,
        collectionPointId,
    });
    return await $get(`/collection-manager/testing-devices?${queryString}`);
});
const getTestingDeviceById = createAsyncThunk("getUrineScreenTestingDeviceById", async (testingDeviceId) => await $get(`/collection-manager/testing-devices/view/${testingDeviceId}`));
const createTestingDevice = createAsyncThunk("createUrineScreenTestingDevice", async (newData) => await $put(`/collection-manager/testing-devices/create/`, newData));
const updateTestingDevice = createAsyncThunk("updateUrineScreenTestingDevice", async (newData) => await $post(`/collection-manager/testing-devices/update/${newData.id}`, newData));
export { getTestingDevices, createTestingDevice, getTestingDeviceById, updateTestingDevice };
