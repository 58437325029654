import { $get, $post, $put } from "src/infra/http";
export const getCollectionPoints = async () => await $get(`/collection-manager/testing-devices/collection-points`);
export const getDeviceTypes = async () => await $get(`/collection-manager/testing-devices/device-types`);
export const getTestingDevice = async () => await $get(`/collection-manager/testing-devices/`);
export const getArchivedTestingDevice = async () => await $get(`/collection-manager/testing-devices/archived-devices`);
export const deleteTestingDevice = async (id) => await $get(`/collection-manager/testing-devices/delete/${id}`);
export const archiveTestingDevice = async (id) => await $get(`/collection-manager/testing-devices/archive/${id}`);
export const restoreTestingDevice = async (id) => await $get(`/collection-manager/testing-devices/restore/${id}`);
export const toggleInUseTestingDevice = async (id, updateUsage) => await $post(`/collection-manager/testing-devices/update-inuse/${id}`, updateUsage);
export const updateTestingDevice = async (id, newData) => await $post(`/collection-manager/testing-devices/update/${id}`, newData);
export const createTestingDevice = async (newData) => await $put(`/collection-manager/testing-devices/create/`, newData);
export const createTestingDeviceHistory = async (historyData) => await $put(`/collection-manager/testing-devices/history/create`, historyData);
export const getTestingDeviceHistory = async (id) => await $get(`/collection-manager/testing-devices/history/${id}`);
// import axios from "axios";
//
// import { env } from "src/constants";
// import { throwHttpRequestError } from "src/infra/http";
// const API_URL = env.DOMAIN;
//
// export const getCollectionPoints = async (idToken: string) => {
//   try {
//     return await axios.get(
//       `${API_URL}/collection-manager/testing-devices/collection-points`,
//       {
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getDeviceTypes = async (idToken: string) => {
//   try {
//     return await axios.get(`${API_URL}/collection-manager/testing-devices/device-types`, {
//       headers: { idToken, "Content-Type": "application/json" },
//     });
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getTestingDevice = async (idToken: string) => {
//   try {
//     return await axios.get(`${API_URL}/collection-manager/testing-devices/`, {
//       headers: { idToken, "Content-Type": "application/json" },
//     });
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getArchivedTestingDevice = async (idToken: string) => {
//   try {
//     return await axios.get(
//       `${API_URL}/collection-manager/testing-devices/archived-devices`,
//       {
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const deleteTestingDevice = async (idToken: string, id: string) => {
//   try {
//     return await axios.get(`${API_URL}/collection-manager/testing-devices/delete/${id}`, {
//       headers: { idToken, "Content-Type": "application/json" },
//     });
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const archiveTestingDevice = async (idToken: string, id: string) => {
//   try {
//     return await axios.get(
//       `${API_URL}/collection-manager/testing-devices/archive/${id}`,
//       {
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const restoreTestingDevice = async (idToken: string, id: string) => {
//   try {
//     return await axios.get(
//       `${API_URL}/collection-manager/testing-devices/restore/${id}`,
//       {
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const toggleInUseTestingDevice = async (
//   idToken: string,
//   id: string,
//   updateUsage: unknown,
// ) => {
//   try {
//     return await axios.post(
//       `${API_URL}/collection-manager/testing-devices/update-inuse/${id}`,
//       updateUsage,
//       {
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const updateTestingDevice = async (idToken: string, id: string, newData: unknown) => {
//   try {
//     return await axios.post(
//       `${API_URL}/collection-manager/testing-devices/update/${id}`,
//       newData,
//       {
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const createTestingDevice = async (idToken: string, newData: unknown) => {
//   try {
//     return await axios.put(
//       `${API_URL}/collection-manager/testing-devices/create/`,
//       newData,
//       {
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const createTestingDeviceHistory = async (idToken: string, historyData: unknown) => {
//   try {
//     return await axios.put(
//       `${API_URL}/collection-manager/testing-devices/history/create`,
//       historyData,
//       {
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
//
// export const getTestingDeviceHistory = async (idToken: string, id: string) => {
//   try {
//     return await axios.get(
//       `${API_URL}/collection-manager/testing-devices/history/${id}`,
//       {
//         headers: { idToken, "Content-Type": "application/json" },
//       },
//     );
//
//     return response
//   } catch (error) {
//     throwHttpRequestError(error);
//   }
// };
