// types.ts or typings.d.ts
export const awardOptions = [
    { value: 0, label: "High School" },
    { value: 1, label: "Level 1 - Certificate I" },
    { value: 2, label: "Level 2 - Certificate II" },
    { value: 3, label: "Level 3 - Certificate III" },
    { value: 4, label: "Level 4 - Certificate IV" },
    { value: 5, label: "Level 5 - Diploma" },
    { value: 6, label: "Level 6 - Advanced Diploma/ Associate Degree" },
    { value: 7, label: "Level 7 - Bachelor Degree" },
    { value: 8, label: "Level 8 - Bachelor Honours Degree, Graduate Certificate, Graduate Diploma" },
    { value: 9, label: "Level 9 - Masters Degree" },
    { value: 10, label: "Level 10 - Doctoral Degree" },
];
