import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import styled from "styled-components";
import PageLayoutWithNoTab from "src/components/layouts/PageLayout/PageLayoutWithNoTab";
import { mainAppColours, SAColourScheme } from "src/constants";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { ArchiveData } from "./components/ArchiveData";
import { ClientData } from "./components/ClientData";
import { selectClientList } from "./components/NewClient/components/ClientDetails/store/selector";
import { getListClients } from "./components/NewClient/components/ClientDetails/store/action";
export const ClientManagement = () => {
    const dispatch = useAppDispatch();
    const clientsData = useAppSelector(selectClientList);
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getListClients({}));
            dispatch(setIsLoading(false));
        };
        loadData();
    }, []);
    return (_jsx(PageWrapper, { children: _jsx(PageLayoutWithNoTab, { location: location.pathname, heading: "Client Management", children: _jsxs(ClientDataPageWrapper, { children: [_jsx(ClientData, { clientsData: clientsData }), _jsx(ArchiveData, {})] }) }) }));
};
export const PageWrapper = styled.div `
  min-height: 79vh;
  position: relative;
`;
export const BreadcrumbWrapper = styled.div `
  width: 100%;
  height: 6rem;
  background: rgba(0, 82, 119, 0.8);

  .breadcrumb {
    color: white;
    position: absolute;
    top: 1em;
    left: 2em;
  }
`;
export const ClientDataPageWrapper = styled.div `
  padding: 2rem;

  background-color: ${SAColourScheme.WHITE};

  .menu-options {
    cursor: pointer;
  }

  .options-dropdown {
    border: 1px solid ${mainAppColours.MID_BLUE};
  }

  .menu-options:hover {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${SAColourScheme.PLASMA};
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: ${SAColourScheme.PLASSMA10};
      border-bottom: 1px solid #d9d9d9;
    }
  }
  .ant-table-footer {
    background-color: ${SAColourScheme.PLASSMA10};
    border-top: 1px solid #d9d9d9;
  }
`;
