import styled from "styled-components";
import { Row } from "src/components/base";
import { fontWeight, supportingColours } from "src/constants";
export const UserProfilePageWrapper = styled(Row) `
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  overflow: hidden;
  min-height: 800px;
  background-color: white;

  .button-edit {
    position: absolute;
    top: 15px;
    right: 15px;

    .ant-btn {
      width: 80px;
      margin-left: 10px;
    }
  }

  .button-group {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    margin-bottom: 15px;
    padding: 0 10px;

    .ant-btn {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }

  .user-name {
    background-color: #367893;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-typography {
      color: #fff !important;
      font-size: 20px;
      font-weight: ${fontWeight.BOLD};
      text-transform: capitalize;
    }
  }

  .user-role,
  .user-id-number {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 25px 0;
    background-color: #fff;
    border-radius: 4px;

    .ant-typography {
      color: #243e84 !important;
      font-size: 16px;
      font-weight: ${fontWeight.MEDIUM};
    }
  }

  .user-id-number {
    &.title .ant-typography {
      color: #01b4d2 !important;
      font-size: 16px;
      font-weight: ${fontWeight.MEDIUM};
    }

    &.value .ant-typography {
      color: #000000 !important;
      font-size: 16px;
      font-weight: ${fontWeight.MEDIUM};
    }
  }

  .user-id-number {
    margin: 0px;

    & ~ .user-id-number {
      margin-left: 15px;
    }
  }

  .qr-code {
    display: flex;
    flex: 1;
    margin: 25px 25px 35px;
    justify-content: center;
    align-items: center;
    position: relative;

    canvas {
      width: 100% !important;
      height: 100% !important;
      max-width: 300px !important;
      min-width: 150px !important;
      border-radius: 4px;
      border: 1px solid #156cc9;
    }
  }

  .ant-form {
    width: 100%;
  }

  .basic-info,
  .contact-info,
  .work-info {
    width: 100%;

    .ant-input,
    .ant-select-selector,
    .ant-picker {
      height: 34px !important;
      background-color: white !important;
      border: 1px solid ${supportingColours.BOX_GRAY} !important;
    }

    .ant-picker-disabled,
    .ant-select-disabled .ant-select-selector,
    .ant-input-disabled {
      background-color: #49adb20d !important;
    }
  }

  .contact-info {
    .ant-input {
      &:nth-child(2) {
        height: 32px !important;
        border: none !important;
        background: none !important;
      }
    }
    .ant-input-affix-wrapper {
      border-radius: 4px;
      padding: 0 11px;
      border: 1px solid ${supportingColours.BOX_GRAY} !important;
    }
    .ant-input-affix-wrapper-disabled {
      background-color: #49adb20d !important;
    }
    .ant-input-prefix {
      color: rgb(47, 51, 55) !important;
      font-size: 17px;
    }
  }

  .ant-divider {
    margin: 12px 0px;
  }

  .info-title {
    font-size: 16px;
    font-weight: 900;
    color: #000000 !important;
  }

  .info-title ~ .ant-row {
    margin-top: 10px;
  }
`;
export const UploadPopupWrapper = styled(Row) `
  width: 100%;
  background-color: #e4f6fb;
  padding: 50px 25px;

  .ant-divider {
    margin: 12px 0 26px 0;
  }

  .drag-area {
    width: 50%;
  }

  .ant-upload-drag-container {
    .ant-image {
      margin: 40px 0;
    }
  }

  .upload-btn,
  .ant-upload {
    width: 100%;
  }

  .ant-btn {
    height: 40px !important;
    border-radius: 4px;
    width: 100%;

    .ant-image {
      margin-right: 10px;
    }
  }

  .drag-area-text {
    img {
      object-fit: cover;
      border: 4px solid #80d9e8 !important;
      border-radius: 4px;
    }
  }
`;
