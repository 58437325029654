import { useEffect, useState } from "react";
const useStorageListener = (key) => {
    const [value, setValue] = useState("");
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === key) {
                setValue(event.newValue || "");
            }
        };
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);
    return value;
};
export default useStorageListener;
