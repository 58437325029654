import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, Col, Divider, Dragger, Image, Row, Text, Upload } from "src/components/base";
import { fontWeight, SAColourScheme } from "src/constants";
import { UploadPopupWrapper } from "../index.style";
export const UploadPopup = ({ uploadUserAvatar, accept, }) => {
    const [file, setFile] = useState();
    const [preview, setPreview] = useState(null);
    const beforeUpload = (uploadedFile) => {
        setFile(uploadedFile);
        return false;
    };
    const onChange = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        reader.readAsDataURL(file);
    };
    return (_jsx(UploadPopupWrapper, { children: _jsx(Col, { span: 24, children: _jsxs("div", { style: { display: "flex", flexDirection: "column", alignItems: "center" }, children: [_jsx(Text, { style: {
                            color: SAColourScheme.BLACK,
                            fontWeight: fontWeight.MEDIUM,
                            fontSize: 18,
                        }, name: "Profile picture" }), _jsx(Divider, {}), _jsx("div", { className: "drag-area", children: _jsx(Dragger, { showUploadList: false, onChange: onChange, beforeUpload: beforeUpload, accept: accept, children: _jsxs("div", { className: "drag-area-text", style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }, children: [_jsx(Image, { src: preview ?? "/assets/svg/ic-upload-profile-picture.svg", width: 138, height: 168, preview: false }), _jsx(Text, { style: {
                                            color: "#1A576F",
                                            fontWeight: fontWeight.MEDIUM,
                                            fontSize: 16,
                                        }, name: "Drag photo here" })] }) }) }), _jsx(Row, { style: { margin: "10px 0" }, justify: "center", children: _jsx(Text, { name: "- or -" }) }), _jsx(Row, { children: _jsx(Upload, { className: "upload-btn", showUploadList: false, onChange: onChange, beforeUpload: beforeUpload, accept: accept, children: _jsx(Button, { icon: _jsx(Image, { src: "/assets/svg/ic-upload-fill.svg", preview: false }), children: _jsx(Text, { style: { color: "#1A8CFF", fontSize: 16 }, name: "Upload from your device" }) }) }) }), _jsx(Button, { style: {
                            width: "20%",
                            alignSelf: "flex-end",
                            marginTop: "30px",
                        }, onClick: () => {
                            uploadUserAvatar(file);
                        }, children: "Apply" })] }) }) }));
};
