import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Image } from "src/components/base";
import { BodyTitle } from "src/components/base/BodyTitle";
import { DonorDetails } from "./DonorDetails";
import { TestRecordsTable } from "./TestRecordsTable";
export const DonorRecordPage = () => {
    const [contactInfo, setContactInfo] = useState({ email: "", mobile: "" });
    const navigate = useNavigate();
    const location = useLocation();
    const donorInfo = location.state;
    const renderBackButton = () => (_jsx(Button, { className: "back-button", icon: _jsx(Image, { className: "back-icon", src: "/assets/svg/ic-back.svg", preview: false, height: 30 }), onClick: () => navigate(-1), children: "Go Back" }));
    return (_jsxs(PageWrapper, { children: [_jsx(BodyTitle, { name: "Donor Records", left: renderBackButton() }), _jsx(DonorDetails, { donorInfo: donorInfo, contactInfo: contactInfo }), _jsx(TestRecordsTable, { donorInfo: donorInfo, setContactInfo: setContactInfo })] }));
};
const PageWrapper = styled.div `
  background-color: white;
  min-height: 79vh;

  .body-title {
    color: #4b5767 !important;
    font-size: 1.3rem !important;
  }

  .content-title {
    padding: 0.5rem;
  }
  button.ant-btn.ant-btn-default.back-button {
    color: #4b5767;
    font-size: 1.1rem;
    font-weight: 600;
    background: none;
    border: none;
    padding-left: 5px;
  }

  img.ant-image-img.back-icon {
    margin-bottom: 5px;
    margin-right: 8px;
  }
`;
