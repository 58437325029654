import styled from "styled-components";
export const LoginPageWrapper = styled.div `
  background-image: url("/assets/images/background.png");
  background-color: #cccccc;
  background-size: 100vw 101vh;
  position: relative;
  height: 100vh;
`;
export const SignInWrapper = styled.div `
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
`;
