import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/display-name */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Message } from "../Message";
import { Wrapper } from "./index.style";
// eslint-disable-next-line no-empty-pattern
const SAToast = React.forwardRef(({}, forwardedRef) => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState(null);
    React.useImperativeHandle(forwardedRef, () => ({
        showMessage(msg, options) {
            setMessage(msg);
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
                if (options?.callback) {
                    options?.callback();
                }
            }, options?.timeout || 2000);
        },
    }));
    return (_jsx(Wrapper, { children: _jsx(Message, { isOpen: isOpen, elmParentId: "xxas", children: _jsx("div", { style: {
                    background: "#FFFFFF",
                    color: "#2F3337",
                    fontSize: "18px",
                    height: "100px",
                    width: "400px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    boxShadow: "0px 3px 6px rgb(0 0 0 / 30%)",
                    marginTop: "-20%",
                }, children: message }) }) }));
});
export default SAToast;
