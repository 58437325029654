import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Dropdown, Image, Menu, Space } from "antd";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link } from "@mui/material";
import { MESSAGE_BOX_TYPE, SAColourScheme, mainAppColours } from "src/constants";
import ContentContext from "src/providers/content";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
import { SearchInput } from "src/components/base";
import { DataTable } from "src/components/commonElement/DataTable";
import { useAppDispatch } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { archiveClientLocation, getListArchivedClientLocations, getListClientLocations, } from "./store/action";
import { setDefaultContacts, setIsAddNew, setIsListPage, setLocationId } from "./store/slice";
export const LocationData = ({ locationsData, cloneLocationRef, clientId, }) => {
    const { openMessageBox } = useContext(ContentContext);
    const [searchString, setSearchString] = useState("");
    const dispatch = useAppDispatch();
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Site Name",
            dataIndex: "siteName",
            key: "siteName",
            sorter: (a, b) => a.siteName.localeCompare(b.siteName),
            render: (text, record) => (_jsx("a", { onClick: () => {
                    dispatch(setIsListPage(false));
                    dispatch(setIsAddNew(false));
                    dispatch(setLocationId(record.id));
                }, children: text })),
        },
        {
            title: "Site Contact",
            dataIndex: "contact",
            key: "contact",
            render: (_, record) => {
                const { clientContacts } = record;
                if (!clientContacts || clientContacts.length === 0) {
                    return _jsx(Space, { children: "No contacts" });
                }
                if (clientContacts.length === 1) {
                    return (_jsx(Space, { children: _jsx("b", { children: clientContacts[0].name }) }));
                }
                return (_jsxs(Space, { children: [_jsx("b", { children: clientContacts[0].name }), " + ", clientContacts.length - 1] }));
            },
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
            render: (_, record) => {
                const { clientContacts } = record;
                if (!clientContacts || clientContacts.length === 0) {
                    return _jsx(Space, { children: "No contacts" });
                }
                if (clientContacts.length === 1) {
                    return (_jsx(Space, { children: _jsx("b", { children: clientContacts[0].mobile }) }));
                }
                return (_jsxs(Space, { children: [_jsx("b", { children: clientContacts[0].mobile }), " + ", clientContacts.length - 1] }));
            },
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            sorter: (a, b) => a.address1.localeCompare(b.address2),
            render: (_, record) => (_jsx(Space, { children: record.address1 + (record.address2 ? ", " + record.address2 : "") })),
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(Menu, { style: {
                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                        width: "150px",
                    }, children: [_jsx(Menu.Item, { onClick: () => {
                                dispatch(setIsListPage(false));
                                dispatch(setIsAddNew(false));
                                dispatch(setLocationId(record.id));
                            }, children: "View/Update" }, "1"), _jsxs(Menu.Item, { children: [_jsx(Link, { underline: "hover", color: "text.primary", onClick: () => {
                                        cloneLocationRef(record);
                                    } }, "1"), "Clone"] }, "2"), _jsx(Menu.Item, { style: {
                                borderTop: "1px solid #ADB3BC",
                                width: "80%",
                                margin: "0 auto",
                            }, onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `Client Location - ${record.siteName} will be archived.`, async () => {
                                    dispatch(setIsLoading(true));
                                    await dispatch(archiveClientLocation(record.id));
                                    if (clientId) {
                                        await dispatch(getListClientLocations(clientId));
                                        await dispatch(getListArchivedClientLocations(clientId));
                                    }
                                    dispatch(setIsLoading(false));
                                });
                            }, children: _jsx("span", { style: { display: "flex", justifyContent: "flex-end" }, children: "Archive" }) }, "3")] }), trigger: ["click"], children: _jsx("div", { className: "menu-options", children: _jsx(ICMenuOptions, {}) }) })),
        },
    ];
    const onSearchString = (value) => {
        setSearchString(value);
    };
    const filteredLocationsData = locationsData.length > 0
        ? locationsData
            ?.filter((item) => {
            const matchesSearch = item.siteName.toLowerCase().includes(searchString.toLowerCase());
            return matchesSearch;
        })
            .map((item) => ({ ...item, key: item.id }))
        : [];
    return (_jsxs(React.Fragment, { children: [_jsxs(HeaderFilterWrapper, { children: [_jsxs(Col, { span: 10, children: [_jsx(Image, { src: "/assets/svg/ic-cm-collection-organisation.svg", height: 30, width: 30, preview: false }), _jsx("span", { className: "header-text", children: "Locations" })] }), _jsxs(Col, { span: 14, className: "show-select", children: [_jsx(SearchInput, { onChange: (e) => onSearchString(e.target.value) }), _jsx(Button, { className: "add-new", children: _jsxs(Link, { underline: "none", color: "text.primary", onClick: () => {
                                        dispatch(setIsListPage(false));
                                        dispatch(setIsAddNew(true));
                                        dispatch(setDefaultContacts());
                                    }, children: [_jsx("span", { className: "add-icon", children: _jsx(Image, { src: "/assets/svg/add-icon.svg", preview: false }) }), _jsx("span", { children: "Add New" })] }, "1") })] })] }), _jsx(DataTable, { dataSource: filteredLocationsData, columns: columns })] }));
};
export const TableWrapper = styled.div `
  z-index: 10;
  .menu-options {
    cursor: pointer;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: ${SAColourScheme.PLASSMA10};
      border-bottom: 1px solid #adb3bc;
    }
  }

  .options-dropdown {
    border: 1px solid ${mainAppColours.MID_BLUE};
  }

  .menu-options:hover {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${SAColourScheme.PLASMA};
  }

  .client-data-table {
    border: 1px solid #d9d9d9;
  }
`;
export const HeaderFilterWrapper = styled.div `
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  align-items: center;

  .header-text {
    margin-left: 10px;
    font-size: 16px;
    color: #000000;
  }

  .add-new {
    border: 1px solid ${mainAppColours.MID_BLUE};
    border-radius: 4px;
    color: #1a8cff;

    .add-icon {
      margin-right: 5px;
    }
  }

  .show-select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
  .text {
    font-size: 14;
    color: #4b5767;
  }

  .type-select {
    width: 10rem;
  }
  .ant-select-selector {
    height: 32px !important;
  }

  .ant-select-selection-item {
    line-height: 32px !important;
  }

  .search-input {
    height: 32px !important;

    .ant-input {
      font-size: 14px !important;
    }

    .ant-image-img {
      height: 15px !important;
      width: 15px !important;
      margin-top: -5px !important;
    }
  }
`;
