import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Image, Typography } from "src/components/base";
const ComingSoonPage = () => (_jsxs(PageWrapper, { children: [_jsx(LogoWrapper, { children: _jsx(Image, { src: "/assets/images/logo-text.png", width: 240, height: 60, preview: false }) }), _jsx(Typography.Text, { children: "We are currently working on adding new features to Collection Manager." }), _jsx(MainImage, {}), _jsx(ComingSoonLogo, {}), _jsx(Typography.Title, { level: 4, children: "Feedback" }), _jsxs(TextWrapper, { children: [_jsx(Typography.Text, { children: "In the meantime, keep those awesome suggestions and feedback coming." }), _jsx(Typography.Text, { children: "Drop us an email anytime at support@sampleassist.com" }), _jsx(Typography.Text, { children: "Together, we can make the world a better place." })] })] }));
export default ComingSoonPage;
const PageWrapper = styled.div `
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  font-size: 18px;
  color: #2f3337;
  position: relative;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding-block: 3rem;
  bottom: 0;
`;
const LogoWrapper = styled.div `
  position: absolute;
  top: 2rem;
  left: 2rem;
`;
const MainImage = styled.div `
  height: 70vh;
  width: 100%;
  background: url("/assets/images/coming-soon-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;
const ComingSoonLogo = styled.div `
  position: absolute;
  margin: 0 auto;
  height: 10rem;
  width: 20rem;
  background: url("/assets/images/coming-soon-cat-icon.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;
const TextWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
