var TimeFormat;
(function (TimeFormat) {
    TimeFormat[TimeFormat["TwelveHour"] = 12] = "TwelveHour";
    TimeFormat[TimeFormat["TwentyFourHour"] = 24] = "TwentyFourHour";
})(TimeFormat || (TimeFormat = {}));
export var CollectionOrgStatus;
(function (CollectionOrgStatus) {
    CollectionOrgStatus[CollectionOrgStatus["inacitive"] = 0] = "inacitive";
    CollectionOrgStatus[CollectionOrgStatus["acitive"] = 1] = "acitive";
})(CollectionOrgStatus || (CollectionOrgStatus = {}));
const appInitialState = {
    collectionOrganization: {
        collectionName: "",
        id: "",
        timeFormat: TimeFormat.TwelveHour,
        status: CollectionOrgStatus.inacitive,
    },
    user: {
        firstName: "",
        lastName: "",
        email: "",
        id: "",
    },
    collectionPoints: [],
    testTypes: [],
    testCategories: [],
};
var TestTypeRecordIndex;
(function (TestTypeRecordIndex) {
    TestTypeRecordIndex[TestTypeRecordIndex["NotSelected"] = 0] = "NotSelected";
    TestTypeRecordIndex[TestTypeRecordIndex["UrineDrugTest"] = 1] = "UrineDrugTest";
    TestTypeRecordIndex[TestTypeRecordIndex["BreathAlcoholTest"] = 2] = "BreathAlcoholTest";
    TestTypeRecordIndex[TestTypeRecordIndex["OralFluidDrugTest"] = 3] = "OralFluidDrugTest";
    TestTypeRecordIndex[TestTypeRecordIndex["RapidOralFluidDrugScreen"] = 4] = "RapidOralFluidDrugScreen";
    TestTypeRecordIndex[TestTypeRecordIndex["HairDrugTest"] = 5] = "HairDrugTest";
    TestTypeRecordIndex[TestTypeRecordIndex["RapidUrineDrugScreen"] = 6] = "RapidUrineDrugScreen";
})(TestTypeRecordIndex || (TestTypeRecordIndex = {}));
export { appInitialState, TestTypeRecordIndex, TimeFormat };
