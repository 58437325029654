import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useIdle } from "react-use";
import { useCookies } from "react-cookie";
import { getAppInformation } from "src/store/app/action";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getCollectionOrganisationInformation } from "src/store/app/selector";
import { CollectionOrgStatus } from "src/store/app/types";
import { GoToSettingPopup } from "src/pages/HomePage/components/GoToSetting/GoToSettingPopup";
import { mainAppColours } from "src/constants";
import { Image } from "src/components/base";
import ContentContext from "src/providers/content";
import useTabActive from "src/hooks/useTabActive";
import useStorageListener from "src/hooks/useStorageListener";
const SET_UP_PAGE = "/collection-organisation";
const AUTH_2FA_VERIFY = "/private/verify";
const TAB_COUNT_KEY = "tab_browser";
const PAGE_HEARTBEAT_KEY = "heartbeat";
const IDLE_THRESHOLD = 15 * 60 * 1000; // 15 minutes
sessionStorage.setItem("errorMessage", "");
const PrivatePage = () => {
    const dispatch = useAppDispatch();
    const [cookies] = useCookies();
    const lastTabOpen = useStorageListener(PAGE_HEARTBEAT_KEY);
    const isIdle = useIdle(IDLE_THRESHOLD); // 10 minutes
    const isTabActive = useTabActive();
    const collectionOrg = useAppSelector(getCollectionOrganisationInformation);
    const { openPopupBox, closePopupBox } = useContext(ContentContext);
    const navigate = useNavigate();
    const location = useLocation();
    const fullUrl = `${location.pathname}${location.search}`;
    // check if other tab is open
    const isOtherTabOpen = lastTabOpen && Date.now() - parseInt(lastTabOpen) < IDLE_THRESHOLD;
    const handleNavigate = () => {
        navigate(SET_UP_PAGE);
        closePopupBox();
    };
    useEffect(() => {
        // one in any tab active all tabs are active
        if (isIdle && !location.pathname.startsWith(AUTH_2FA_VERIFY) && !isOtherTabOpen) {
            navigate(`${AUTH_2FA_VERIFY}?u=${encodeURIComponent(fullUrl)}`);
        }
    }, [isIdle]);
    useEffect(() => {
        // if open tab will set new heartbeat
        if (isTabActive && !location.pathname.startsWith(AUTH_2FA_VERIFY)) {
            localStorage.setItem(PAGE_HEARTBEAT_KEY, Date.now().toString());
        }
        if (!cookies.sfa_tk && !location.pathname.startsWith(AUTH_2FA_VERIFY)) {
            navigate(`${AUTH_2FA_VERIFY}?u=${encodeURIComponent(fullUrl)}`);
        }
    }, [isTabActive]);
    useEffect(() => {
        const isFirstTab = localStorage.getItem(TAB_COUNT_KEY) === null;
        if (isFirstTab) {
            const urlRedirect = location.pathname.startsWith(AUTH_2FA_VERIFY)
                ? fullUrl
                : `${AUTH_2FA_VERIFY}?u=${encodeURIComponent(fullUrl)}`;
            navigate(urlRedirect);
        }
        dispatch(getAppInformation());
    }, []);
    useEffect(() => {
        if (collectionOrg.id &&
            collectionOrg.status === CollectionOrgStatus.inacitive &&
            location.pathname !== SET_UP_PAGE) {
            openPopupBox(_jsx(GoToSettingPopup, { handleNavigate: handleNavigate }), null, {
                popupStyle: {
                    marginTop: "40px",
                    width: "30%",
                    borderRadius: "16px",
                    border: `2px solid ${mainAppColours.MID_BLUE}`,
                },
                closeIcon: _jsx(Image, { src: "/assets/svg/ic-close.svg", preview: false }),
            });
        }
    }, [collectionOrg]);
    return _jsx(Outlet, {});
};
export default PrivatePage;
