import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getInClinicDailyAppointmentSummary, getListCollectionPoints, getOnSiteSummary, } from "./action";
export const initialState = {
    onSiteSummary: {
        data: {
            id: "",
            isValid: true,
            collectionOrganizationId: "",
            collectionOrganization: null,
            collectionPoints: [],
            selectedDate: "",
            updatedAt: null,
            createdAt: null,
        },
        status: false,
        error: undefined,
    },
    dailySummary: {
        data: {
            id: "",
            isValid: true,
            collectionOrganizationId: "",
            collectionOrganization: null,
            collectionPoints: [],
            selectedDate: "",
            updatedAt: null,
            createdAt: null,
        },
        status: false,
        error: undefined,
    },
    listCollectionPoints: {
        data: [],
        status: false,
        error: undefined,
    },
    currentDate: new Date().toISOString(),
    selectedCollectionPoint: { id: "", collectionPointName: "Show All" },
    isOnsiteView: false,
};
const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        setDailySummary(state, action) {
            state.dailySummary = action.payload;
        },
        setOnSiteSummary(state, action) {
            state.onSiteSummary = action.payload;
        },
        setCollectionPoints(state, action) {
            state.listCollectionPoints.data = action.payload;
        },
        setCurrentDate(state, action) {
            state.currentDate = action.payload;
        },
        setCurrentCollectionPoint(state, action) {
            state.onSiteSummary.data = initialState.onSiteSummary.data;
            state.dailySummary.data = initialState.dailySummary.data;
            state.selectedCollectionPoint = action.payload;
        },
        setStateIsOnsiteView(state, action) {
            state.isOnsiteView = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInClinicDailyAppointmentSummary.fulfilled, (state, action) => {
            if (!action.payload || action.payload.status === "fail" || !action.payload.data) {
                state.dailySummary.status = false;
                state.dailySummary.error =
                    action.payload?.message || "Failed to fetch new in-clinic summary";
                state.dailySummary.data = initialState.dailySummary.data;
            }
            else {
                state.dailySummary.status = true;
                state.dailySummary.data = action.payload.data;
            }
        })
            .addCase(getInClinicDailyAppointmentSummary.rejected, (state, action) => {
            state.dailySummary.status = false;
            state.dailySummary.error = action.error.message;
            message.error("Failed to fetch new in-clinic summary");
        })
            .addCase(getListCollectionPoints.fulfilled, (state, action) => {
            state.listCollectionPoints.status = true;
            state.listCollectionPoints.data =
                action.payload && action.payload.data
                    ? [{ id: "", collectionPointName: "Show All" }, ...action.payload.data]
                    : [{ id: "", collectionPointName: "Show All" }];
        })
            .addCase(getListCollectionPoints.rejected, (state, action) => {
            state.listCollectionPoints.status = false;
            state.listCollectionPoints.error = action.error.message;
            message.error("Failed to fetch collection points");
        })
            .addCase(getOnSiteSummary.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.onSiteSummary.status = false;
                state.onSiteSummary.error = action.payload.message;
                state.onSiteSummary.data = initialState.onSiteSummary.data;
            }
            else {
                state.onSiteSummary.status = true;
                state.onSiteSummary.data = action.payload.data;
            }
        })
            .addCase(getOnSiteSummary.rejected, (state, action) => {
            state.onSiteSummary.status = false;
            state.onSiteSummary.error = action.error.message;
            message.error("Failed to fetch new onsite summary");
        });
    },
});
export const { setDailySummary, setCollectionPoints, setCurrentDate, setCurrentCollectionPoint, setOnSiteSummary, setStateIsOnsiteView, } = homeSlice.actions;
export default homeSlice.reducer;
