import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useRef, useState } from "react";
import PinInput from "react-pin-input";
import styled from "styled-components";
import { Button, Image, Typography } from "src/components/base";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { mainAppColours, MESSAGE_BOX_TYPE, SAColourScheme } from "src/constants";
import ContentContext from "src/providers/content";
import { supportingColours } from "src/styles";
import DeleteIcon from "src/assets/svg/authorising-authority/ic-signature-vault-delete.svg";
import UploadIcon from "src/assets/svg/authorising-authority/ic-signature-vault-upload.svg";
import CloseIcon from "src/assets/svg/authorising-authority/ic-close.svg";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import FileUploadDrawer from "src/pages/TestingDevicePage/components/FileUploadDrawer";
import { UploadFileType } from "src/pages/TestingDevicePage/typing";
import { UploadPrefix } from "src/infra/file/types";
import { setIsLoading } from "src/store/app/slice";
import { selectAuthorityInformation, selectIsLocked, selectSignatureUrl } from "../store/selector";
import { updateSignature, verifyPin } from "../store/action";
import { setIsLocked } from "../store/slice";
import { SetUpPin } from "./SetUpPin";
const defaultUploadingFile = {
    url: null,
    fileType: UploadFileType.Image,
};
export const SignatureVault = () => {
    const pinRef = useRef(null);
    const isLocked = useAppSelector(selectIsLocked);
    const signatureUrl = useAppSelector(selectSignatureUrl);
    const [isPinDisabled, setIsPinDisabled] = useState(false);
    const [lockTimeout, setLockTimeout] = useState(null);
    const dispatch = useAppDispatch();
    const { openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const { openPopupBox, openMessageBox } = useContext(ContentContext);
    const authorityInformation = useAppSelector(selectAuthorityInformation);
    const { isCreatedPin } = authorityInformation.data;
    const [isOpenUploadPanel, setIsOpenUploadPanel] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(defaultUploadingFile);
    const onDelete = async () => {
        openLoadingScreen();
        await dispatch(updateSignature({ signature: "" }));
        closeLoadingScreen();
    };
    useEffect(() => {
        if (lockTimeout) {
            clearTimeout(lockTimeout);
        }
        const timeoutId = setTimeout(() => {
            dispatch(setIsLocked(true));
        }, 1000 * 30 * 5);
        setLockTimeout(timeoutId);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isLocked]);
    const openSetUpPin = (isCreatingPin) => {
        openPopupBox(_jsx(SetUpPin, { isCreatedPin: isCreatingPin }), null, {
            popupStyle: {
                width: "50%",
                borderRadius: "16px",
                border: `2px solid ${mainAppColours.MID_BLUE}`,
            },
            closeIcon: _jsx(Image, { src: "/assets/svg/ic-close.svg", preview: false }),
        });
    };
    const checkPin = async (pin) => {
        await dispatch(verifyPin({ pin: pin }));
        setIsPinDisabled(true);
        const timeout = setTimeout(() => {
            setIsPinDisabled(false);
            clearTimeout(timeout);
        }, 1000);
    };
    return (_jsx(Container, { children: isLocked ? (_jsx(LockedContainer, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 4, children: _jsx(BackgroundImage, {}) }), _jsx(SAColumn, { span: 20, children: isCreatedPin ? (_jsxs(PinFormContainer, { children: [_jsxs(TextContainer, { children: [_jsx(GuideText, { children: "Please enter your PIN" }), _jsx(CaptionGuideText, { children: "or" }), _jsx(StyledButton, { onClick: () => openSetUpPin(isCreatedPin), children: "Update" })] }), _jsx(PinInputStyled, { ref: pinRef, disabled: isPinDisabled, length: 6, initialValue: "", type: "numeric", inputMode: "number", autoSelect: true, regexCriteria: /^[ A-Za-z0-9_@./#&+-]*$/, onComplete: (value) => checkPin(value), inputStyle: {
                                        color: `${SAColourScheme.BLACK}`,
                                        backgroundColor: isPinDisabled
                                            ? `${supportingColours.BOX_GRAY}`
                                            : `${SAColourScheme.WHITE}`,
                                        width: "2rem",
                                        height: "2rem",
                                        borderRadius: "4px",
                                        border: `none`,
                                        boxShadow: `inset 0px 2px 3px rgba(0, 0, 0, 0.38)`,
                                    }, inputFocusStyle: {
                                        boxShadow: `inset 0px 2px 3px rgba(0, 0, 0, 0.38)`,
                                    } })] })) : (_jsx(PinFormContainer, { children: _jsxs(TextContainer, { children: [_jsx(GuideText, { children: "Please create your PIN" }), _jsx(StyledButton, { onClick: () => openSetUpPin(isCreatedPin), children: "Create" })] }) })) })] }) })) : (_jsx(UploadContainer, { children: _jsxs(SARow, { gutter: [0, 0], children: [_jsx(SAColumn, { span: 4, children: _jsxs(UploadButtonWrapper, { children: [_jsx(DeleteButton, { onClick: () => {
                                        if (!signatureUrl) {
                                            return;
                                        }
                                        openMessageBox(MESSAGE_BOX_TYPE.PROMPT, "Do you want to delete signature?", onDelete);
                                    }, icon: _jsx(DeleteIcon, {}), children: "Delete" }), _jsxs(DeleteButton, { children: [_jsxs(UploadArea, { onClick: () => setIsOpenUploadPanel(true), children: [_jsx(UploadIcon, {}), _jsx(Typography.Text, { children: "Upload" })] }), _jsx(FileUploadDrawer, { isOpen: isOpenUploadPanel, setOpen: setIsOpenUploadPanel, uploadPrefix: UploadPrefix.AuthorisedAuthoritySignatures, uploadUrl: uploadingFile.url, type: uploadingFile.fileType, setUploadUrl: async (uploadedUrl) => {
                                                setUploadingFile((preState) => ({
                                                    ...preState,
                                                    url: uploadedUrl,
                                                }));
                                            }, onSaveFile: async () => {
                                                dispatch(setIsLoading(true));
                                                dispatch(updateSignature({ signature: uploadingFile.url ?? "" }));
                                                setIsOpenUploadPanel(false);
                                                setUploadingFile(defaultUploadingFile);
                                                dispatch(setIsLoading(false));
                                            } })] })] }) }), _jsx(SAColumn, { span: 18, children: _jsx(SignatureImageContainer, { children: _jsx(SignatureImage, { src: signatureUrl ?? "", preview: false, alt: "Signature", fallback: "/assets/images/signature-placeholder.png" }) }) }), _jsx(SAColumn, { span: 2, children: _jsx(CloseButton, { onClick: () => dispatch(setIsLocked(true)), icon: _jsx(CloseIcon, {}) }) })] }) })) }));
};
const Container = styled.div `
  display: flex;
  background-color: ${SAColourScheme.WHITE};
  border-radius: 0.25rem;
  height: 100%;
  width: 100%;
  height: 10rem;
`;
const LockedContainer = styled.div `
  display: flex;
  flex-direction: row;
  background-color: #243e84;
  border-radius: 0.25rem;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  margin: 0.3125rem;
  width: 100%;
  padding: 1rem;
`;
const BackgroundImage = styled.div `
  background-image: url("/assets/svg/img-signature-vault.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;
const PinFormContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;
const TextContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-bottom: 0.0625rem solid ${SAColourScheme.WHITE};
`;
const GuideText = styled.p `
  font-size: 1rem;
  color: #ffffff;
`;
const CaptionGuideText = styled.p `
  font-size: 0.875rem;
  color: #ffffff;
`;
const UploadContainer = styled.div `
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const StyledButton = styled(Button) `
  margin-bottom: 0.625rem;
  border: 0.0625rem solid #bdc5da;
  background-color: transparent;
  font-size: 0.75rem;
  color: #bdc5da;
`;
const PinInputStyled = styled(PinInput) `
  padding-top: 0.5rem;
`;
const CloseButton = styled(Button) `
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  cursor: pointer;
  border-radius: 1rem;
  border: 0;
`;
const DeleteButton = styled(Button) `
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  width: 100%;
  border-bottom: 0.1rem solid #ebedf0;
`;
const UploadArea = styled.div `
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const UploadButtonWrapper = styled.div `
  height: 100%;
  width: 100%;
  border-right: 0.1rem solid #ebedf0;
`;
const SignatureImageContainer = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SignatureImage = styled(Image) `
  max-height: 10rem;
  max-width: 15rem;
  object-fit: cover;
  margin: 0 auto;
  padding: 2rem;
`;
