import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "styled-components";
import { Button } from "src/components/base";
import { APPOINTMENT_STATUS_NUMBER, SAColourScheme } from "src/constants";
export const ResendButton = ({ appointmentStatus, onResendEmail }) => (_jsx(StyledButton, { className: "resend-button", style: { backgroundColor: "#01B4D2" }, type: "primary", icon: _jsx("img", { src: "/assets/svg/ic-letter3.svg", alt: "ic-letter" }), onClick: onResendEmail, disabled: appointmentStatus === APPOINTMENT_STATUS_NUMBER.CANCELED, children: "Resend Email" }));
const StyledButton = styled(Button) `
  width: 190px;
  height: 30px;
  background-color: #01b4d2;
  color: ${SAColourScheme.WHITE};
  border-radius: 4px;
  border: 1px solid ${SAColourScheme.WHITE};
  font-size: 15px;
  text-align: left;
  padding: 4px 25px;
  line-height: 1;

  img {
    float: right;
    margin-top: 5px;
  }
`;
