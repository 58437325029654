import { useNavigate, useLocation } from "react-router-dom";
const useGoBackToPreviousPath = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const goBackToPath = (targetPath) => {
        const currentPath = location.pathname;
        const pathSegments = currentPath.split("/").filter(Boolean);
        const targetIndex = pathSegments.lastIndexOf(targetPath);
        if (targetIndex !== -1) {
            const newPath = "/" + pathSegments.slice(0, targetIndex + 1).join("/");
            navigate(newPath);
        }
        else {
            console.warn(`Path '${targetPath}' not found in the current path.`);
        }
    };
    return goBackToPath;
};
export default useGoBackToPreviousPath;
