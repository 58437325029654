const initialOralCollectionTestingDeviceDetails = {
    deviceName: "",
    isInUse: false,
    isArchived: false,
    lotNumber: "",
    expireAt: null,
    isReminderExpire: false,
    reminderAt: null,
    collectionPointIds: [],
    sponsor: "",
    testTypeId: "",
    fontImageUrl: "",
    sideImageUrls: [],
    laboratorySheetUrl: "",
    specificationSheetUrl: "",
};
export { initialOralCollectionTestingDeviceDetails };
