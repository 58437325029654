/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { $delete, $get, $post, $put } from "src/infra/http";
const getListNotes = createAsyncThunk("getListNotes", async (onsiteClientId) => await $get(`/collection-manager/client-management/note/${onsiteClientId}`));
const getListLocations = createAsyncThunk("getListLocations", async (onsiteClientId) => await $get(`/collection-manager/client-management/location/${onsiteClientId}`));
const createNote = createAsyncThunk("createNote", async (data) => await $post(`/collection-manager/client-management/note/create`, data));
const updateNote = createAsyncThunk("updateNote", async (data) => await $put(`/collection-manager/client-management/note/update`, data));
const deleteNote = createAsyncThunk("deleteNote", async (noteId) => await $delete(`/collection-manager/client-management/note/${noteId}`));
const getNoteHistory = createAsyncThunk("getNoteHistory", async (noteId) => await $get(`/collection-manager/client-management/note/logs/${noteId}`));
export { getListNotes, getListLocations, createNote, updateNote, deleteNote, getNoteHistory };
