import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Select, Typography } from "antd";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import OralScreenIcon from "src/assets/svg/device-management/ic-menu-testing-oral-screen.svg";
import CreateIcon from "src/assets/svg/device-management/create.svg";
import { FlexEndContainer, FlexStartContainer } from "src/components/Container/Flex";
import SADivider from "src/components/Form/SADivider";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { mainAppColours } from "src/constants";
import useExtendPath from "src/hooks/useExtendPath";
import { selectTestTypeByRecordIndex } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { TestTypeRecordIndex } from "src/store/app/types";
import { DevicesViewOption } from "../typing";
import { DeviceCard } from "./components/DeviceCard";
import { getTestingDevices } from "./store/action";
import { selectArchivedTestingDevices, selectCollectionPoint, selectListCollectionPoints, selectTestingDevices, } from "./store/selector";
import { setCollectionPointId, setResetStore } from "./store/slice";
import ICColumnViewSelected from "/src/assets/svg/device-management/column-view.svg";
import ICListViewSelected from "/src/assets/svg/device-management/list-view.svg";
import { DeviceListView } from "./components/DeviceListView";
const ListOralScreenDevices = () => {
    const dispatch = useAppDispatch();
    const appendPath = useExtendPath();
    const [searchParams, setSearchParams] = useSearchParams();
    const testingDevices = useAppSelector(selectTestingDevices);
    const archivedTestingDevices = useAppSelector(selectArchivedTestingDevices);
    const testTypes = useAppSelector(selectTestTypeByRecordIndex);
    const collectionPoints = useAppSelector(selectListCollectionPoints);
    const currentCollectionPoint = useAppSelector(selectCollectionPoint);
    const testType = testTypes[TestTypeRecordIndex.RapidOralFluidDrugScreen];
    const paramsObject = Object.fromEntries([...searchParams.entries()]);
    const testTypeIdParam = searchParams.get("testTypeId");
    useEffect(() => {
        if (testType && !testTypeIdParam) {
            setSearchParams({
                ...searchParams,
                testTypeId: testType,
                isArchiveShown: "false",
            });
        }
        const loadData = async () => {
            dispatch(setResetStore());
            dispatch(setIsLoading(true));
            await dispatch(getTestingDevices({ testTypeId: String(testTypeIdParam) }));
            dispatch(setIsLoading(false));
        };
        if (testTypeIdParam)
            loadData();
    }, [testTypeIdParam]);
    const getDevicesByView = () => {
        const viewType = searchParams.get("view");
        switch (viewType) {
            case DevicesViewOption.Card: {
                return (_jsx(React.Fragment, { children: testingDevices.map((device) => (_jsx(SAColumn, { md: 24, lg: 12, children: _jsx(DeviceCard, { device: device }) }, device.id))) }));
            }
            case DevicesViewOption.List: {
                return (_jsx(React.Fragment, { children: _jsx(DeviceListView, { testingDevices: testingDevices }) }));
            }
            default: {
                setSearchParams({
                    ...paramsObject,
                    view: DevicesViewOption.Card,
                });
                return (_jsx(React.Fragment, { children: testingDevices.map((device) => (_jsx(SAColumn, { span: 12, children: _jsx(DeviceCard, { device: device }) }, device.id))) }));
            }
        }
    };
    return (_jsxs(OralScreenDeviceContainer, { children: [_jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsxs(FlexStartContainer, { children: [_jsx(OralScreenIcon, {}), _jsx(Typography.Title, { level: 5, children: "Oral Fluid Drug Screen Devices" })] }) }), _jsx(SAColumn, { span: 12, children: _jsxs(FlexEndContainer, { children: [_jsx(ViewButton, { onClick: () => {
                                        setSearchParams({
                                            ...paramsObject,
                                            view: DevicesViewOption.Card,
                                        });
                                    }, "$isSelected": searchParams.get("view") === DevicesViewOption.Card, children: _jsx(ICColumnViewSelected, {}) }), _jsx(ViewButton, { onClick: () => {
                                        setSearchParams({
                                            ...paramsObject,
                                            view: DevicesViewOption.List,
                                        });
                                    }, "$isSelected": searchParams.get("view") === DevicesViewOption.List, children: _jsx(ICListViewSelected, {}) }), _jsx(SelectTitle, { children: "Show" }), _jsx(CustomSelect, { placeholder: "Please select collection points", options: collectionPoints, value: currentCollectionPoint, onChange: (_, option) => {
                                        const selectedValue = option;
                                        dispatch(setCollectionPointId(selectedValue.name));
                                        setSearchParams({
                                            ...paramsObject,
                                            collectionPointId: selectedValue.name,
                                        });
                                    } }), _jsx(CreateButton, { icon: _jsx(CreateIcon, {}), onClick: () => appendPath("create"), children: "Add New" })] }) })] }), _jsx(SARow, { children: _jsx(CustomDivider, {}) }), _jsx(SARow, { children: getDevicesByView() }), _jsxs(ArchiveDeviceWrapper, { children: [_jsx(ArchiveButton, { onClick: async () => {
                            setIsLoading(true);
                            setSearchParams({
                                ...paramsObject,
                                isArchiveShown: paramsObject.isArchiveShown === "true" ? "false" : "true",
                            });
                            await dispatch(getTestingDevices({
                                testTypeId: String(testTypeIdParam),
                                isArchived: true,
                            }));
                            setIsLoading(false);
                        }, children: paramsObject.isArchiveShown === "true" ? (_jsxs(React.Fragment, { children: ["Hide Archive ", _jsx(MinusOutlined, {})] })) : (_jsxs(React.Fragment, { children: ["Show Archive ", _jsx(PlusOutlined, {})] })) }), _jsx(CustomDivider, {}), paramsObject.isArchiveShown === "true" && (_jsxs(ArchivedTextBox, { children: [_jsxs(FlexStartContainer, { children: [_jsx(OralScreenIcon, {}), _jsx(Typography.Title, { level: 5, children: "Archived Oral Fluid Drug Screen Devices" })] }), _jsx(DeviceListView, { testingDevices: archivedTestingDevices })] }))] })] }));
};
export default ListOralScreenDevices;
const OralScreenDeviceContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
`;
const CustomDivider = styled(SADivider) `
  border-color: #adb3bc !important;
`;
const CreateButton = styled(Button) `
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.063px solid ${mainAppColours.MID_BLUE};
  border-radius: 0.25rem;
  color: ${mainAppColours.MID_BLUE};
  width: 12rem;
`;
const CustomSelect = styled(Select) `
  width: 15rem !important;
`;
const SelectTitle = styled.p `
  font-size: 0.875rem;
`;
const ViewButton = styled(Button) `
  color: ${(props) => (props.$isSelected ? "#156CC9" : "rgba(21, 108, 201, 0.5)")};
  border: 0;
  padding: 0;
  padding-top: 0.4rem;
`;
const ArchiveDeviceWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100% !important;
  margin-top: auto;
`;
const ArchiveButton = styled(Button) `
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${mainAppColours.MID_BLUE} !important;
  background-color: #e6f7fa !important;
  border: 0;
`;
const ArchivedTextBox = styled.div `
  padding-block: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
