import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Divider, Spin } from "src/components/base";
import { LeftRowData, LeftRowTitle, MiddleRowData, RightRowData, } from "src/pages/DashboardPage/components/Common";
import HeaderTable from "src/pages/DashboardPage/components/HeaderTable";
import LeftData from "src/pages/DashboardPage/components/LeftData";
import MiddleData from "src/pages/DashboardPage/components/MiddleData";
import RightData from "src/pages/DashboardPage/components/RightData";
import { TestsProcessedWrapper } from "src/pages/DashboardPage/components/TestsProcessed/index.style";
import { getCollectorPerformanceLeftRowData, getCollectorPerformanceMiddleRowData, getCollectorPerformanceRightRowData, } from "src/api/dashboard/dashboardService";
export const CollectorPerformance = ({ collectionPoints, collectorsData }) => {
    const [location, setLocation] = useState("All");
    const [selectYear, setSelectYear] = useState(`${moment().year()}`);
    const [startIndex, setStartIndex] = useState(0);
    const [isMonthView, setIsMonthView] = useState(true);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    // Left Data
    const [leftPerformanceData, setLeftPerformanceData] = useState({});
    // Middle Data
    const [middlePerformanceData, setMiddlePerformanceData] = useState({});
    // Right Data
    const [rightPerformanceData, setRightPerformanceData] = useState({});
    // Left protocalData
    const [leftStatsData, setLeftStatsData] = useState({});
    // Middle protocalData
    const [middleStatsData, setMiddleStatsData] = useState({});
    // Right protocalData
    const [rightStatsData, setRightStatsData] = useState({});
    const getLeftRowData = async () => {
        const startDate = moment().format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        const result = await getCollectorPerformanceLeftRowData(location, startDate, endDate);
        if (result.status === "success") {
            setLeftPerformanceData(result?.data?.results);
            setLeftStatsData(result?.data?.otherData);
        }
    };
    const getMiddleRowData = async () => {
        const result = await getCollectorPerformanceMiddleRowData(location, isMonthView, selectYear);
        if (result.status === "success") {
            setMiddlePerformanceData(result?.data?.collectorsPerformance);
            setMiddleStatsData(result?.data?.otherData);
        }
    };
    const getRightRowData = async () => {
        const startDate = moment(fromDate).format("YYYY-MM-DD");
        const endDate = moment(toDate).format("YYYY-MM-DD");
        const result = await getCollectorPerformanceRightRowData(location, startDate, endDate);
        if (result.status === "success") {
            setRightPerformanceData(result?.data?.results);
            setRightStatsData(result?.data?.otherData);
        }
    };
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            Promise.all([getLeftRowData(), getMiddleRowData(), getRightRowData()]).then(() => setIsLoading(false));
        }
    }, [isVisible, location]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getRightRowData().then(() => setIsLoading(false));
        }
    }, [fromDate, toDate]);
    useEffect(() => {
        if (isVisible) {
            setIsLoading(true);
            getMiddleRowData().then(() => setIsLoading(false));
        }
    }, [isMonthView, selectYear]);
    return (_jsx(TestsProcessedWrapper, { children: _jsxs(HeaderTable, { title: "Collector Performance", collectionPoints: collectionPoints, onChangeLocation: setLocation, isVisible: isVisible, setIsVisible: setIsVisible, icon: "ic-collector-ranking.svg", children: [_jsx("div", { style: { position: "relative" }, children: isLoading && (_jsx(Spin, { style: {
                            position: "absolute",
                            top: "50%",
                            left: "40vw",
                        } })) }), _jsxs("div", { style: { opacity: isLoading ? 0.3 : 1, display: "flex" }, children: [_jsxs(LeftData, { title: "Processed Tests", children: [_jsx(LeftRowData, { title: "Average", data: leftStatsData["Average"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "Minimum", data: leftStatsData["Minimum"] || [0, 0, 0, 0] }), _jsx(LeftRowData, { title: "Maximum", data: leftStatsData["Maximum"] || [0, 0, 0, 0] }), _jsx(Divider, {}), _jsx(LeftRowTitle, { title: "Collectors", highlight: true }), collectorsData?.map((collector) => (_jsx(LeftRowData, { title: collector.name, data: leftPerformanceData[collector.name] || [0, 0, 0, 0] }, collector.name)))] }), _jsxs(MiddleData, { onSelectYear: setSelectYear, onMonthView: setIsMonthView, startIndex: setStartIndex, children: [Object.entries(middleStatsData)?.map(([month, data]) => (_jsx(React.Fragment, { children: _jsx(MiddleRowData, { startIndex: startIndex, data: Object.values(data) }, month) }, month))), _jsx(Divider, {}), _jsx("div", { style: { height: 33 } }), Object.entries(middlePerformanceData)?.map(([collcetor, monthlyCounts]) => (_jsx(MiddleRowData, { startIndex: startIndex, data: Object.values(monthlyCounts) }, collcetor)))] }), _jsxs(RightData, { onFromDate: setFromDate, onToDate: setToDate, children: [_jsx(RightRowData, { value: rightStatsData["Average"] || 0 }), _jsx(RightRowData, { value: rightStatsData["Minimum"] || 0 }), _jsx(RightRowData, { value: rightStatsData["Maximum"] || 0 }), _jsx(Divider, {}), _jsx("div", { style: { height: 33 } }), collectorsData?.map((collector) => (_jsx(RightRowData, { value: rightPerformanceData?.[collector.name] || 0 }, collector.name)))] })] })] }) }));
};
