import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image, message } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { ChooseAccountForm } from "src/pages/TwoFactorAuthPage/ChooseAccountForm";
import { EnterCodeForm } from "src/pages/TwoFactorAuthPage/EnterCodeForm";
import { sendCodeVerify } from "src/pages/TwoFactorAuthPage/store/action";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { selectIsLoading } from "src/store/app/selector";
const initialStateAccount = {
    email: "",
    userName: "",
};
const TwoFactorAuthPage = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const [step, setStep] = useState(1);
    const [account, setAccount] = useState(initialStateAccount);
    const onSend = async (accountDetail) => {
        dispatch(setIsLoading(true));
        const data = await sendCodeVerify();
        dispatch(setIsLoading(false));
        if (data.error) {
            message.error("Failed to send code");
        }
        else {
            setAccount(accountDetail);
            setStep(2);
        }
    };
    return (_jsxs(PageWrapper, { children: [_jsx("div", { style: { padding: "30px 50px", height: "15%", width: "100%" }, children: _jsx(Image, { src: "/assets/images/logo-text-login.png", alt: "logo and text", preview: false, height: "100%" }) }), isLoading && _jsx(OverlayLoading, {}), _jsxs(FormWrapper, { children: [step == 1 && _jsx(ChooseAccountForm, { onSendCode: onSend }), step == 2 && _jsx(EnterCodeForm, { onBack: () => setStep(1), account: account })] })] }));
};
export default TwoFactorAuthPage;
export const PageWrapper = styled.div `
  background-image: url("/assets/images/background.png");
  background-color: #cccccc;
  height: 100vh;
  background-size: 100vw 101vh;

  .email-input {
    margin-top: 50px;
  }

  .email-input > .input-wrapper {
    border: 2px solid #1a8cff;
    border-radius: 4px;
  }

  .input-wrapper > input.text-input {
    color: #1a8cff;
    font-size: 16px;
    border-radius: 4px;
    background-color: transparent;
  }

  .pwd-input > .input-wrapper {
    border: 1px solid #1a8cff;
  }

  .img-color > path {
    fill: #adb3bc;
  }
`;
const FormWrapper = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 280px;
  display: flex;
  justify-content: center;
`;
