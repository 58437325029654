import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import Text from "antd/lib/typography/Text";
import { Col } from "src/components/base";
import { SAColourScheme } from "src/constants";
import { LeftDataWrapper } from "src/pages/DashboardPage/components/LeftData/index.style";
const RightData = ({ children, title }) => (_jsxs(LeftDataWrapper, { style: { width: "50%" }, children: [_jsx("div", { style: {
                background: `${SAColourScheme.PLASSMA10}`,
                minHeight: "30px",
                padding: "15px 20px",
                border: "1px solid #1A8CFF",
                borderLeft: "none",
                borderRight: "none",
            }, children: _jsx(Text, { style: { fontWeight: "700", fontSize: "15px" }, children: "History" }) }), _jsxs("div", { className: "left-data-header", style: { border: "none" }, children: [_jsx(Col, { span: 12, style: { textAlign: "center" }, children: _jsx(Text, { style: { fontSize: 18, fontWeight: "500", color: "#00004C" }, children: title }) }), _jsx(Col, { span: 6, style: { textAlign: "center" }, children: _jsx(Text, { className: "current-time", children: "Days" }) }), _jsx(Col, { span: 6, style: { textAlign: "center" }, children: _jsx(Text, { className: "current-time", children: "Uses" }) })] }), _jsx("div", { style: { padding: "0px 21px 21px 11px" }, children: children })] }));
export default RightData;
