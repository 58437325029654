import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select } from "antd";
import { Wrapper } from "./index.style";
const { Option } = Select;
export const ButtonSelection = (props) => {
    const renderOption = () => {
        const jsx = props.valueList.map((element, index) => (_jsx(Option, { value: element.value, children: element.label }, index)));
        return jsx;
    };
    return (_jsx(Wrapper, { className: props.className, children: _jsx(Select, { defaultValue: props.defaultValue, value: props.view, suffixIcon: _jsxs("svg", { id: "Component_198_104", "data-name": "Component 198 \u2013 104", xmlns: "http://www.w3.org/2000/svg", width: "25", height: "25", viewBox: "0 0 25 25", children: [_jsx("rect", { id: "Rectangle_119", "data-name": "Rectangle 119", width: "25", height: "25", transform: "translate(25) rotate(90)", fill: "none" }), _jsx("path", { id: "Path_219", "data-name": "Path 219", d: "M351.092,300.388l-7-7-7,7", transform: "translate(356.592 311) rotate(180)", fill: "none", stroke: props.icLogoColor || "#fff", strokeWidth: "1.5" })] }), onChange: props.onChange, children: renderOption() }) }));
};
