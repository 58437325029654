import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Divider, Image, Input, Popconfirm, Row } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { DataTable } from "src/components/commonElement/DataTable";
import { SAColourScheme } from "src/styles";
import { Text } from "src/components/base";
import DeleteContract from "src/assets/svg/ic-delete-contract.svg";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getUserData, selectIsLoading } from "src/store/app/selector";
import FileUploadDrawer from "src/pages/TestingDevicePage/components/FileUploadDrawer";
import { UploadFileType } from "src/pages/TestingDevicePage/typing";
import { setIsLoading } from "src/store/app/slice";
import { UploadPrefix } from "src/infra/file/types";
import { selectClientContracts, selectIsArchiveContractsTableVisible } from "./store/selector";
import { setArchiveContractsTableVisible, setCurrentContract, setResetContractState, } from "./store/slice";
import { deleteClientContract } from "./store/action";
const defaultUploadingFile = {
    url: null,
    fileName: null,
    uploadedByName: null,
    fileType: UploadFileType.Pdf,
    note: null,
    id: undefined,
    uploadedAt: null,
};
const ContractUpload = ({ isEdit, isAddNew = false }) => {
    const tableVisible = useAppSelector(selectIsArchiveContractsTableVisible);
    const userData = useAppSelector(getUserData);
    const clientContracts = useAppSelector(selectClientContracts);
    const isLoading = useAppSelector(selectIsLoading);
    const dispatch = useAppDispatch();
    const [isOpenUploadPanel, setIsOpenUploadPanel] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(defaultUploadingFile);
    useEffect(() => {
        if (isAddNew) {
            dispatch(setResetContractState());
        }
    }, []);
    const columns = [
        {
            title: "Date",
            dataIndex: "uploadedAt",
            key: "uploadedAt",
            defaultSortOrder: "ascend",
            render: (value) => _jsx("span", { children: moment(value).format("DD MMM YYYY hh:mm a") }),
        },
        {
            title: "File Name",
            dataIndex: "fileName",
            key: "fileName",
        },
        {
            title: "File Name",
            dataIndex: "fileName",
            key: "fileName",
            render: (_, record) => (_jsxs(NoteIconDiv, { children: [_jsx(Image, { src: "/assets/svg/Document_Blue.svg", height: 30, width: 30, preview: false, onClick: () => {
                            if (!record.url)
                                return;
                            window.open(record.url, "_blank");
                            return;
                        } }), isEdit && record.isArchived && (_jsx(Popconfirm, { title: `Are you sure? ${record.fileName} will be deleted`, onConfirm: async () => {
                            dispatch(setIsLoading(true));
                            await dispatch(deleteClientContract(String(record.id)));
                            dispatch(setIsLoading(false));
                        }, okButtonProps: { loading: isLoading }, okText: "Yes", cancelText: "No", placement: "topRight", disabled: !isEdit, children: _jsx(DeleteContract, {}) }))] })),
        },
        {
            title: "Uploaded by",
            dataIndex: "uploadedByName",
            key: "uploadedByName",
        },
        {
            title: "Note",
            dataIndex: "note",
            key: "note",
            sortDirections: ["ascend", "descend"],
            render: (text, record) => (_jsx(React.Fragment, { children: isEdit && !record.isArchived ? (_jsx(Input, { value: record.note ?? "", onChange: (e) => dispatch(setCurrentContract({
                        note: e.target.value,
                        id: clientContracts.currentContract?.id ?? undefined,
                        url: clientContracts.currentContract?.url ?? null,
                        fileName: clientContracts.currentContract?.fileName ?? "",
                        uploadedAt: clientContracts.currentContract?.uploadedAt ?? moment(),
                        uploadedByName: clientContracts.currentContract?.uploadedByName ?? "",
                    })) })) : (_jsx("span", { children: text })) })),
        },
    ];
    return (_jsx(ContractUploadWrapper, { children: _jsxs(Row, { gutter: [20, 10], children: [_jsxs(CustomCol, { xs: 24, lg: 3, isEdit: isEdit, children: [_jsx(Text, { name: "Upload New" }), _jsx(Image, { src: "/assets/svg/ic-upload-2.svg", height: 18, width: 18, preview: false, onClick: () => isEdit && setIsOpenUploadPanel(true) }), _jsx(FileUploadDrawer, { isOpen: isOpenUploadPanel, setOpen: setIsOpenUploadPanel, uploadPrefix: UploadPrefix.Contract, uploadUrl: uploadingFile.url, type: uploadingFile.fileType, setUploadUrl: async (uploadedUrl) => {
                                setUploadingFile((preState) => ({
                                    ...preState,
                                    url: uploadedUrl,
                                }));
                            }, setFileName: async (fileName) => {
                                setUploadingFile((preState) => ({
                                    ...preState,
                                    uploadedByName: userData.firstName ?? null,
                                    fileName: fileName,
                                }));
                            }, onSaveFile: async () => {
                                dispatch(setIsLoading(true));
                                dispatch(setCurrentContract({
                                    note: uploadingFile?.note ?? null,
                                    id: uploadingFile?.id ?? undefined,
                                    url: uploadingFile?.url ?? null,
                                    fileName: uploadingFile?.fileName ?? "",
                                    uploadedAt: uploadingFile?.uploadedAt ?? moment(),
                                    uploadedByName: uploadingFile?.uploadedByName ?? "",
                                }));
                                setIsOpenUploadPanel(false);
                                setUploadingFile(defaultUploadingFile);
                                dispatch(setIsLoading(false));
                            } })] }), _jsxs(Col, { xs: 24, lg: 21, children: [_jsx(DataTable, { columns: columns, dataSource: clientContracts?.currentContract ? [clientContracts.currentContract] : [], showPagination: false, tableSize: "small" }), !isAddNew && (_jsxs(React.Fragment, { children: [_jsxs(Button, { onClick: () => {
                                        dispatch(setArchiveContractsTableVisible(!tableVisible));
                                    }, className: "client-archive", children: [_jsx("span", { children: "Contract Archive" }), _jsx("span", { className: "icon", children: tableVisible ? (_jsx(Image, { src: "/assets/images/arrow-up-icon.png", preview: false, height: 10, width: 15 })) : (_jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false, height: 10, width: 15 })) })] }), _jsx(CustomDivider, {}), tableVisible && (_jsx(DataTable, { columns: columns, dataSource: clientContracts?.archivedContracts, tableSize: "small" }))] }))] })] }) }));
};
export default ContractUpload;
const ContractUploadWrapper = styled.div `
  .ant-btn {
    font-weight: 700;
    font-size: 0.8rem;
    margin: 1rem 0 0;
    background: ${SAColourScheme.PLASSMA10};

    .ant-image {
      margin-left: 0.5rem;
    }
  }
`;
const CustomDivider = styled(Divider) `
  margin: 0;
  border-bottom: 2px solid ${SAColourScheme.PLASSMA} !important;
`;
const CustomCol = styled(Col) `
  .ant-upload {
    display: flex;
    flex-direction: column;
  }

  .ant-image {
    width: 4.5rem !important;
    cursor: ${(props) => (props.isEdit ? "pointer" : "not-allowed")};
  }
  @media screen and (max-width: 992px) {
    .ant-upload {
      display: flex;
      flex-direction: row;
    }
    .ant-image {
      width: 2rem !important;
    }
  }
`;
const NoteIconDiv = styled.div `
  display: flex;
  align-items: center;
  gap: 1rem;

  > svg,
  .ant-image {
    cursor: pointer;
  }
`;
