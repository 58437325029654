import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input } from "antd";
import { TextInput } from "./TextInput";
import InputWithLabel from "./InputWithLabel";
import { InputBox } from "./InputBox";
import { SearchInput } from "./SearchInput";
import { ButtonSelection } from "./ButtonSelection";
import { CheckBox } from "./CheckBox";
import { ImageFrame } from "./ImageFrame";
const AppInput = ({ size = "large", ...props }) => _jsx(Input, { size: size, ...props });
export default {
    AppInput,
    TextInput,
    InputWithLabel,
    SearchInput,
    ButtonSelection,
    InputBox,
    CheckBox,
    ImageFrame,
};
