import styled from "styled-components";
export const RightDataWrapper = styled.div `
  width: 10%;

  // padding: 5px;
  border-left: 1px solid #2e94f2;

  .right-data-header {
    height: 72px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #2e94f2;
    justify-content: center;
  }
`;
