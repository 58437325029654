import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { TestCombinationValue, } from "./typing";
import { deleteCategory, deletePreferredTest, getClientSpecification, getTestTypeData, updateClientSpecifications, } from "./action";
export const initialState = {
    listTestTypes: [],
    clientSpecifications: null,
    categoriesData: [],
    devicePreferenceData: [],
    isEdit: false,
    selectedTestCombination: TestCombinationValue.DrugOnly,
};
const ClientSpecificationsSlice = createSlice({
    name: "ClientSpecificationsSlice",
    initialState,
    reducers: {
        setCategories(state, action) {
            state.categoriesData = action.payload;
        },
        setIsEdit(state, action) {
            state.isEdit = action.payload;
        },
        setSelectedTestCombination(state, action) {
            state.selectedTestCombination = action.payload;
        },
        setResetStore() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTestTypeData.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.listTestTypes = initialState.listTestTypes;
                message.error("Failed to fetch list of tests list");
            }
            else {
                state.listTestTypes = action.payload.data;
            }
        })
            .addCase(getTestTypeData.rejected, () => {
            message.error("Failed to fetch list of client locations");
        })
            .addCase(getClientSpecification.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                state.clientSpecifications = initialState.clientSpecifications;
                state.categoriesData = initialState.categoriesData;
                state.devicePreferenceData = initialState.devicePreferenceData;
                message.error("Failed to fetch client specifications");
            }
            else {
                state.clientSpecifications = action.payload.data.clientData[0];
                state.selectedTestCombination = +action.payload.data.clientData[0].combinationTestType;
                state.categoriesData = action.payload.data.categoriesData;
                state.devicePreferenceData = action.payload.data.devicePreferenceData;
            }
        })
            .addCase(getClientSpecification.rejected, () => {
            message.error("Failed to fetch client specifications");
        })
            .addCase(deleteCategory.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to delete category");
            }
            else {
                message.success("Category deleted successfully");
            }
        })
            .addCase(deleteCategory.rejected, () => {
            message.error("Failed to delete category");
        })
            .addCase(updateClientSpecifications.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to update specifications");
            }
            else {
                message.success("Specifications updated successfully");
            }
        })
            .addCase(updateClientSpecifications.rejected, () => {
            message.error("Failed to update specifications");
        })
            .addCase(deletePreferredTest.fulfilled, (_, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to delete preferred test");
            }
            else {
                message.success("Preferred test deleted successfully");
            }
        })
            .addCase(deletePreferredTest.rejected, () => {
            message.error("Failed to delete preferred test");
        });
    },
});
export const { setIsEdit, setSelectedTestCombination, setResetStore, setCategories } = ClientSpecificationsSlice.actions;
export default ClientSpecificationsSlice.reducer;
