import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/no-deprecated */
/* eslint-disable @typescript-eslint/no-var-requires */
import ElementQueries from "css-element-queries/src/ElementQueries";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
ElementQueries.listen();
const rootElement = document.getElementById("root");
if (rootElement) {
    const renderApp = () => {
        ReactDOM.render(_jsx(App, {}), rootElement);
    };
    renderApp();
    if (module.hot) {
        module.hot.accept("./App", () => {
            const NextApp = require("./App").default;
            ReactDOM.render(_jsx(NextApp, {}), rootElement);
        });
    }
}
