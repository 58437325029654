import { keyBy } from "lodash";
import moment from "moment";
import { AppointmentMainTypeName } from "./types";
const getInClinicDailySummary = (state) => {
    const dailySummary = state.homePage.dailySummary.data;
    const data = !state.homePage.selectedCollectionPoint.id
        ? dailySummary.collectionOrganization
        : (dailySummary.collectionPoints[0] ?? []);
    return data;
};
const getInClinicAppointmentSummary = (state) => {
    const dailySummary = state.homePage.dailySummary.data;
    const dailySummaryData = !state.homePage.selectedCollectionPoint.id
        ? dailySummary.collectionOrganization
        : dailySummary.collectionPoints[0];
    const appointmentSummary = dailySummaryData?.appointments;
    const responseData = [
        {
            name: AppointmentMainTypeName.AlcoholOnly,
            value: appointmentSummary?.alcoholOnly ?? 0,
        },
        {
            name: AppointmentMainTypeName.DrugOnly,
            value: appointmentSummary?.drugOnly ?? 0,
        },
        {
            name: AppointmentMainTypeName.AlcoholAndDrug,
            value: appointmentSummary?.alcoholAndDrug ?? 0,
        },
    ];
    return responseData;
};
const getInClinicTestSummary = (state) => {
    const dailySummary = state.homePage.dailySummary.data;
    const dailySummaryData = !state.homePage.selectedCollectionPoint.id
        ? dailySummary.collectionOrganization
        : (dailySummary.collectionPoints[0] ?? []);
    const summaryData = dailySummaryData?.summary;
    const responseData = {
        bookedTests: {
            totalTests: {
                alcohol: summaryData?.bookedTests.totalTests.alcohol ?? 0,
                drug: summaryData?.bookedTests.totalTests.drug ?? 0,
            },
            testType: keyBy(summaryData?.bookedTests.testType, "recordIndex"),
        },
        processedTests: {
            totalTests: {
                alcohol: summaryData?.processedTests.totalTests.alcohol ?? 0,
                drug: summaryData?.processedTests.totalTests.drug ?? 0,
            },
            testType: keyBy(summaryData?.processedTests.testType, "recordIndex"),
        },
    };
    return responseData;
};
const listCollectionPoints = (state) => {
    const collectionPoints = state.homePage.listCollectionPoints.data;
    return collectionPoints.map((point) => ({
        name: point.id,
        value: point.collectionPointName,
    }));
};
const getSelectedCollectionPoint = (state) => {
    const currentCollectionPoint = state.homePage.selectedCollectionPoint;
    return {
        name: currentCollectionPoint.collectionPointName,
        value: currentCollectionPoint.id,
    };
};
const getSelectedDate = (state) => state.homePage.currentDate
    ? moment(state.homePage.currentDate).format("DD MMM YYYY").toString()
    : moment().format("DD MMM YYYY").toString();
const getIsOnsiteViewStatus = (state) => state.homePage.isOnsiteView;
export { getInClinicAppointmentSummary, getInClinicDailySummary, getInClinicTestSummary, getIsOnsiteViewStatus, getSelectedCollectionPoint, getSelectedDate, listCollectionPoints, };
const getOnSiteTestSummary = (state) => {
    const onSiteSummary = state.homePage.onSiteSummary.data;
    const onSiteSummaryData = !state.homePage.selectedCollectionPoint.id
        ? onSiteSummary.collectionOrganization
        : (onSiteSummary.collectionPoints[0] ?? []);
    const summaryData = onSiteSummaryData?.summary;
    const responseData = {
        bookedTests: {
            totalTests: {
                alcohol: summaryData?.bookedTests.totalTests.alcohol ?? 0,
                drug: summaryData?.bookedTests.totalTests.drug ?? 0,
            },
            testType: keyBy(summaryData?.bookedTests.testType, "recordIndex"),
        },
        processedTests: {
            totalTests: {
                alcohol: summaryData?.processedTests.totalTests.alcohol ?? 0,
                drug: summaryData?.processedTests.totalTests.drug ?? 0,
            },
            testType: keyBy(summaryData?.processedTests.testType, "recordIndex"),
        },
    };
    return responseData;
};
const getOnSiteCallOutSummary = (state) => {
    const onSiteSummary = state.homePage.onSiteSummary.data;
    const onSiteSummaryData = !state.homePage.selectedCollectionPoint.id
        ? onSiteSummary.collectionOrganization
        : (onSiteSummary.collectionPoints[0] ?? []);
    const callOutSummaryData = onSiteSummaryData?.callOutJob;
    return callOutSummaryData;
};
const getOnSiteJobSummary = (state) => {
    const onSiteSummary = state.homePage.onSiteSummary.data;
    const onSiteSummaryData = !state.homePage.selectedCollectionPoint.id
        ? onSiteSummary.collectionOrganization
        : (onSiteSummary.collectionPoints[0] ?? []);
    return onSiteSummaryData;
};
export { getOnSiteCallOutSummary, getOnSiteJobSummary, getOnSiteTestSummary };
