var Environment;
(function (Environment) {
    Environment["sandbox"] = "sandbox";
    Environment["local"] = "develop";
})(Environment || (Environment = {}));
var Domain;
(function (Domain) {
    Domain["Main"] = "main";
    Domain["Upload"] = "upload";
    Domain["Auth"] = "auth";
})(Domain || (Domain = {}));
export { Environment, Domain };
