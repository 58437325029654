/* eslint-disable @typescript-eslint/no-explicit-any */
import { $get, $post, $put } from "src/infra/http";
export const getInventoryDetails = async () => await $get(`/collection-manager/inventory/`);
export const getSupplierContactDetails = async () => await $get(`/collection-manager/inventory/suppliers`);
export const getArchivedSupplier = async () => await $get(`/collection-manager/inventory/archived-suppliers`);
export const deleteSupplier = async (id) => await $get(`/collection-manager/inventory/supplier/delete/${id}`);
export const archiveSupplier = async (id) => await $get(`/collection-manager/inventory/supplier/archive/${id}`);
export const restoreSupplier = async (id) => await $get(`/collection-manager/inventory/supplier/restore/${id}`);
export const updateInventory = async (id, newData) => await $post(`/collection-manager/inventory/update/${id}`, newData);
export const createInventory = async (newData) => await $put(`/collection-manager/inventory/create/`, newData);
export const updateSupplier = async (newData) => await $post(`/collection-manager/inventory/supplier/update/`, newData);
export const createQREntry = async () => await $get(`/collection-manager/inventory/qr/create/`);
export const verifyQrPin = async (pin, encodedUrl) => await $put(`/ScanSatchelCodes/verify/${encodedUrl}`, { pin });
export const setBarcodeData = async (id, scannedBarcode) => await $put(`/ScanSatchelCodes/update/${id}`, { scannedBarcode });
export const createTransportSatchel = async (newData) => await $put(`/collection-manager/inventory/satchel/create`, newData);
export const updateTransportSatchel = async (id, newData) => await $post(`/collection-manager/inventory/satchel/update/${id}`, newData);
export const getTransportSatchels = async () => await $get(`/collection-manager/inventory/satchels/`);
export const getArchivedTransportSatchels = async () => await $get(`/collection-manager/inventory/satchels/archived`);
export const getCollectorsData = async () => await $get(`/collection-manager/inventory/satchels/collectors/`);
export const archiveTransportSatchel = async (id) => await $get(`/collection-manager/inventory/satchel/archive/${id}`);
export const restoreTransportSatchel = async (id) => await $get(`/collection-manager/inventory/satchel/restore/${id}`);
