import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image } from "antd";
import { useState, useEffect } from "react";
import { PageWrapper } from "./index.style";
export const ImageFrame = (props) => {
    const currentSrc = props.src;
    const style = props.style || {};
    const currentPreview = props.preview;
    const [src, setSrc] = useState("");
    const [preview, setPreview] = useState(true);
    useEffect(() => {
        setSrc(currentSrc);
    }, [currentSrc]);
    useEffect(() => {
        setPreview(currentPreview);
    }, [currentPreview]);
    return (_jsx(PageWrapper, { children: _jsxs("div", { className: "id-image", style: style, children: [!src && _jsx(Image, { src: "/assets/svg/ic-image.svg", width: 40, height: 34.29, preview: false }), src && (_jsx(Image, { src: src, style: {
                        maxHeight: style?.height,
                        width: "100%",
                    }, wrapperStyle: {
                        width: "100%",
                    }, preview: preview }))] }) }));
};
