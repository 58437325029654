import { $get, $post } from "src/infra/http";
export const getCollectionOrganizationDetails = async () => {
    const response = await $get(`/collection-manager/collection-organization/detail`);
    return response.data;
};
export const UpdateOrganizationDetails = async (organizationDetail, addedCollectionPoint, existedCollectionPoint) => await $post(`/collection-manager/collection-organization/update`, {
    organizationDetail,
    newCollectionPoints: addedCollectionPoint,
    oldCollectionPoints: existedCollectionPoint,
});
export const UpdateLaboratoryDetails = async (addedLaboratories, existedLaboratories) => {
    const response = await $post(`/collection-manager/collection-organization/laboratory`, {
        newLaboratories: addedLaboratories,
        oldLaboratories: existedLaboratories,
    });
    return response.data;
};
export const deleteOrganizationCollectionPoint = async (collectionPointId) => {
    const response = await $get(`/collection-manager/collection-organization/collection-point/delete/${collectionPointId}`);
    return response.data;
};
export const deleteLab = async (labId) => {
    const response = await $get(`/collection-manager/collection-organization/laboratory/delete/${labId}`);
    return response.data;
};
