import styled from "styled-components";
export const MiddleDataWrapper = styled.div `
  width: 45%;
  border-left: 1px solid #2e94f2;

  .middle-data-header {
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #2e94f2;
  }
  .tool-bar {
    width: 100%;
    height: 40px;
    display: flex;
    height: 40px;
    align-items: center;
    padding: 0 27px;
  }
  .cal-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #71b9f8;
    align-items: center;
  }
`;
export const MonthYearWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: #71b9f8;
  align-items: center;

  button {
    border: none;
    background: #71b9f8;
    margin: 0;
    font-size: 13px;
    font-weight: medium;
    color: #ffffff;
  }

  .ant-btn:hover {
    background: none;
  }
`;
